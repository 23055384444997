import React, { useState, useEffect } from 'react'
import {
  Layout,
  Empty,
} from 'antd'
import dayjs from 'dayjs'
import { filterEmailFromSearchParams } from '@core/helpers/searchParams'
import LeftSider from '../../components/Logs/LogsLeftSider'
import LogsTab from '../../components/Logs/LogsTab'

const { Content } = Layout

const initialFilterValues = { channel: 'GOOGLE_CHAT', datasource: 'production' }

dayjs.extend(require('dayjs/plugin/timezone'))
dayjs.extend(require('dayjs/plugin/utc'))

const LogsPage = () => {
  const [filter, setFilter] = useState(null)

  const setInitialFilterFromSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filterAsString = searchParams.get('filter')
    if (!filterAsString) {
      setFilter(initialFilterValues)
    } else {
      try {
        const parsedFilter = JSON.parse(filterAsString)
        setFilter(parsedFilter)
      } catch (error) {
        setFilter(initialFilterValues)
      }
    }
  }

  const updateSearchParamsByFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filteredParams = filterEmailFromSearchParams(filter)
    searchParams.set('filter', JSON.stringify(filteredParams))
    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState(null, '', newRelativePathQuery)
  }

  useEffect(setInitialFilterFromSearchParams, [])

  useEffect(updateSearchParamsByFilter, [filter])

  const search = (newFilter) => {
    setFilter({ ...newFilter, _: Math.random() })
  }

  return (
    <>
      {filter && <LeftSider initialFilterValues={filter} onSearch={search} />}
      <Content>
        {filter ? (
          <LogsTab
            filter={filter}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='Please make a search from the left side'
          />
        )}
      </Content>
    </>
  )
}

export default LogsPage
