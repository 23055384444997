import React from 'react'
import {
  Skeleton,
  Spin,
} from 'antd'
import PropTypes from 'prop-types'

export const LoadingSkeleton = ({ tipMessage, active }) => {
  return (
    <Spin tip={tipMessage}>
      <Skeleton active={active} />
    </Spin>
  )
}

LoadingSkeleton.propTypes = {
  tipMessage: PropTypes.string,
  active: PropTypes.bool,
}

LoadingSkeleton.defaultProps = {
  tipMessage: 'Please wait',
  active: true,
}

export default LoadingSkeleton
