import React, { useContext, useState } from 'react'
import { Row, Spin, Result, Button, Col, Tabs } from 'antd'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'
import JsonPreview from '@core/components/JsonPreview'
import Api from '../../../../api'
import { EmailAutomationsContext } from '../../../../context'
import MetricTable from './MetricTable'

const EvaluationExpandedRow = ({ id }) => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [
    { loading, data, hasError },
    { makeRequest },
  ] = useRequest(Api.DataExtractionModels.getModelEvaluation, { modelId: id, version: currentVersion, datasource })
  const [fullTable, setFullTable] = useState(false)

  const retry = async () => {
    await makeRequest()
  }

  if (hasError) {
    return (
      <Result
        status='warning'
        title='We&#39;ve encountered some problems, please try again in few minutes'
        extra={(
          <Button type='primary' key='retry' onClick={retry}>
            Retry
          </Button>
        )}
      />
    )
  }

  if (loading || !data) return <Spin />

  return (
    <Row>
      <Col span={24}>
        {data && !data.length && <JsonPreview content={JSON.stringify(data, null, 2)} autoSize />}
        {!!data?.length && (
          <Tabs>
            {data.map((item) => {
              return (
                <Tabs.TabPane tab={item.label} key={item.label}>
                  <MetricTable taggerWithEvaluation={item} fullTable={fullTable} setFullTable={setFullTable} />
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        )}
      </Col>
    </Row>
  )
}

EvaluationExpandedRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default EvaluationExpandedRow
