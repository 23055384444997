import React, { useMemo } from 'react'
import DOMPurify from 'dompurify'
import {
  Typography,
  Space,
  Badge,
  Tooltip,
  Button,
  Popover,
} from 'antd'
import {
  Link,
  useRouteMatch,
} from 'react-router-dom'
// FIXME: Cross module
import SentenceAnalysis from '../../../../SentenceAnalysis/store/actions/sentenceAnalysis'
import SentenceActions from '../../../store/actions/sentences'
import { highlightTextAsHtml } from '../../../helpers'
import {
  sentencePropType,
  searchingTermsPropType,
  sentenceArrayindexPropType,
  bulkModePermittedPropType,
  onHighlightedFilterClickPropType,
  isBulkModePropType,
  showGeneralizedPropType,
  showStopwordsRemovedPropType,
} from '../../../types/sentence'
import GeneralizedSentence from '../../GeneralizedSentence'

import style from './style.module.scss'

const Sentence = ({
  sentence,
  searchingTerms,
  sentenceArrayindex,
  bulkModePermitted,
  onHighlightedFilterClick,
  isBulkMode,
  showGeneralized,
  showStopwordsRemoved,
}) => {
  const { url } = useRouteMatch()
  const openConversation = async (event) => {
    event.stopPropagation()
    await SentenceActions.select(sentence, 'Conversation')
  }

  const openSentenceAnalysis = async (event) => {
    event.stopPropagation()
    const { extractedDynamicEntities, ...rest } = sentence.data
    await SentenceAnalysis.openModal(sentence.text, { ...rest })
  }

  const shouldMarkText = searchingTerms.length > 0
  const highlightedTextHtml = useMemo(
    () => {
      const sanitizedText = DOMPurify.sanitize(sentence.text)
      return highlightTextAsHtml(sanitizedText, searchingTerms)
    },
    [sentence, searchingTerms],
  )

  // FIXME: this should be component
  const listItemContent = (
    <Space className={style.SentenceText} size='small' align='start' direction='vertical'>
      <Popover
        content={(
          <>
            <Button type='text' onClick={openConversation}>
              Look up the conversation
            </Button>
            <Button type='text' onClick={openSentenceAnalysis}>
              Analyze this sentence
            </Button>
          </>
        )}
      >
        {shouldMarkText
        // eslint-disable-next-line react/no-danger
        && <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightedTextHtml) }} />}
        {!shouldMarkText && <span>{sentence.text}</span>}
      </Popover>
      {
        (showGeneralized || showStopwordsRemoved)
          ? (
            <Space size='small' align='start' direction='horizontal'>
              {showGeneralized && <Typography.Text type='success' strong>G:</Typography.Text>}
              {showStopwordsRemoved && <Typography.Text type='warning' strong>S:</Typography.Text>}
              <GeneralizedSentence
                generalizedVer={(() => {
                  if (showGeneralized) {
                    return sentence.generalized ? sentence.generalized.generalized : ''
                  }

                  if (showStopwordsRemoved) {
                    return sentence.stopwordsRemoved ? sentence.stopwordsRemoved : ''
                  }

                  return ''
                })()}
                onHighlightedFilterClick={onHighlightedFilterClick}
                tags={sentence.generalized.tags}
              />
            </Space>
          )
          : null
      }
    </Space>
  )

  if (bulkModePermitted && isBulkMode) {
    return (
      <>
        {sentence.changed_timestamp && (
          <Tooltip title={`Recently changed on: ${sentence.changed_timestamp}`}>
            <Badge status='warning' />
          </Tooltip>
        )}
        {listItemContent}
      </>
    )
  }

  return (
    <Link
      to={{
        pathname: `${url}/${sentence.id}`,
        state: {
          sentenceArrayindex,
        },
      }}
      tabIndex={-1}
    >
      {listItemContent}
    </Link>
  )
}

Sentence.propTypes = {
  sentence: sentencePropType.isRequired,
  searchingTerms: searchingTermsPropType.isRequired,
  sentenceArrayindex: sentenceArrayindexPropType.isRequired,
  bulkModePermitted: bulkModePermittedPropType.isRequired,
  onHighlightedFilterClick: onHighlightedFilterClickPropType.isRequired,
  isBulkMode: isBulkModePropType.isRequired,
  showGeneralized: showGeneralizedPropType.isRequired,
  showStopwordsRemoved: showStopwordsRemovedPropType.isRequired,
}

export default Sentence
