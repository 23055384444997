import StoreUtils from '@core/utils/store'

const TRAIN_TAGGERS = StoreUtils.createActionStatus('TRAIN_TAGGERS')
const ANALYZE_TAGGERS = StoreUtils.createActionStatus('ANALYZE_TAGGERS')
const DISPLAY_TAGGER_TRAININGS = StoreUtils.createActionStatus('DISPLAY_TAGGER_TRAININGS')

export {
  TRAIN_TAGGERS,
  ANALYZE_TAGGERS,
  DISPLAY_TAGGER_TRAININGS,
}
