import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, NavLink } from 'react-router-dom'
import { Menu, Button } from 'antd'
import { useRoutesToBuildTopMenu } from '../../hooks/usePermissions'
import style from './style.module.scss'

export const DefaultMenu = ({ selectedKeys, onLogoutWrap }) => {
  const history = useHistory()
  const routes = useRoutesToBuildTopMenu() // FIXME: Currently this is re-render everytime

  if (!routes || !routes.length) return null

  return (
    <Menu className={style.MainMenu} mode='horizontal' selectedKeys={selectedKeys}>
      {routes.map((menuItem, index) => {
        if (menuItem.children) {
          return (
            <Menu.SubMenu title={menuItem.title} key={index}>
              {menuItem.children.map((child) => {
                return (
                  <Menu.Item key={child.to}>
                    <NavLink activeClassName='active' to={child.to}>
                      {child.title}
                    </NavLink>
                  </Menu.Item>
                )
              })}
            </Menu.SubMenu>
          )
        }

        return (
          <Menu.Item key={menuItem.to}>
            <NavLink activeClassName='active' to={menuItem.to}>
              {menuItem.title}
            </NavLink>
          </Menu.Item>
        )
      })}

      <Menu.Item key='menu_logout' danger>
        <Button type='link' danger onClick={onLogoutWrap(history)}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  )
}

DefaultMenu.propTypes = {
  selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onLogoutWrap: PropTypes.func.isRequired,
}

export default DefaultMenu
