import React, { useState, useContext } from 'react'
import {
  Layout,
  Typography,
  Spin,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import { callWithWorkerPod } from '@core/utils/workerPod'

import { EmailAutomationsContext } from '../../context'
import Api from '../../api'
import LeftSider from './LeftSider'
import JobsTable from './JobsTable'

const { Content } = Layout
const { Text } = Typography

const EmailPreprocessedItems = () => {
  const { datasource, versions: { currentVersion } } = useContext(EmailAutomationsContext)
  const [spinTip, setSpinTip] = useState('')
  const [
    {
      loading,
      data: jobs,
      hasError,
    },
    { makeRequest },
  ] = useRequest(Api.Jobs.getAllJobs, {
    path: 'providers.EmailItems.preprocessAllEmailData',
  }, currentVersion)

  const onSubmit = async ({ steps }) => {
    await callWithWorkerPod(async () => {
      setSpinTip('')
      await Api.Emails.preprocessAllEmailData({ steps, datasource, version: currentVersion })
    }, ({
      retryCount,
      data: { unavailableReplicas },
    }) => {
      const estimatedRetryCount = 8
      const percent = ((retryCount + 1) * 100) / estimatedRetryCount

      if (unavailableReplicas) {
        setSpinTip(`The worker pod is starting (${percent}%).`)
      } else {
        setSpinTip(`Please wait for the worker pod (${percent}%).`)
      }
    })

    await makeRequest()
  }

  return (
    <Spin spinning={!!spinTip} tip={spinTip}>
      <div style={{ display: 'flex' }}>
        <LeftSider onSubmit={onSubmit} />
        <Content>
          {loading && <Spin />}
          {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
          {jobs && <JobsTable jobs={jobs} />}
        </Content>
      </div>
    </Spin>
  )
}

export default EmailPreprocessedItems
