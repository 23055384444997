import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { RIGHT_PANEL } from '../constants'

export default wrapActionCreatorWithDispatch({
  close: () => {
    return async (dispatch) => {
      dispatch({ type: RIGHT_PANEL.CLOSE })
    }
  },
})
