import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Space, Button } from 'antd'

import { EmailAutomationsContext } from '../../../../../context'

const EmailActions = ({
  analyzeEmail,
  viewIntentTrainings,
  viewDataExtraction,
  viewEmailChecking,
}) => {
  const { permissions } = useContext(EmailAutomationsContext)

  const { emails } = permissions ?? {}

  return (
    <Space direction='vertical'>
      {
        emails?.actionViewAnalysisEmailContent && (
          <Button
            type='link'
            size='small'
            onClick={analyzeEmail}
          >
            View Analysis Email Content
          </Button>
        )
      }
      {
        emails?.actionViewIntentTraining && (
          <Button
            type='link'
            size='small'
            onClick={viewIntentTrainings}
          >
            View Intent Training
          </Button>
        )
      }
      {
        emails?.actionViewDataExtraction && (
          <Button
            type='link'
            size='small'
            onClick={viewDataExtraction}
          >
            View Data Extraction
          </Button>
        )
      }
      {
        emails?.actionViewEmailChecking && (
          <Button
            type='link'
            size='small'
            onClick={viewEmailChecking}
          >
            View Email Checking
          </Button>
        )
      }
    </Space>
  )
}

EmailActions.propTypes = {
  analyzeEmail: PropTypes.func.isRequired,
  viewIntentTrainings: PropTypes.func.isRequired,
  viewDataExtraction: PropTypes.func.isRequired,
  viewEmailChecking: PropTypes.func.isRequired,
}

export default EmailActions
