import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Typography, Spin } from 'antd'
import ReactJsonView from 'react-json-view'
import Api from '../../../api'

const PNRModal = ({ pnrList, selectedPnr, selectedPnrType, setModalVisibility, modalVisibility, datasource }) => {
  const closeModal = () => {
    setModalVisibility(false)
  }

  const [searchResult, setSearchResult] = useState(null)
  const [travelerName, setTravelerName] = useState(null)
  const [travelerEmail, setTravelerEmail] = useState(null)

  useEffect(() => {
    const findTripByPnr = async (pnr) => {
      setTravelerName(null)
      setTravelerEmail(null)
      setSearchResult(null)

      let searchPnr = pnr

      if (selectedPnrType === 'Supplier') {
        const gbtPnr = pnrList.find((item) => { return item.type === 'GBT' })

        searchPnr = gbtPnr ? gbtPnr.pnr : searchPnr
      }

      const { data } = await Api.getTripByPnr(datasource, searchPnr)

      const matchedTrip = (data?.results ?? []).find((result) => {
        return result.pnr === searchPnr
      })

      setTravelerName(matchedTrip?.travelerName ?? '-')
      setTravelerEmail(matchedTrip?.travelerEmail ?? '-')
      setSearchResult(data)
    }

    if (selectedPnr) {
      findTripByPnr(selectedPnr)
    }
  }, [datasource, pnrList, selectedPnr, selectedPnrType])

  return (
    <Modal
      title='PNR Lookup'
      visible={modalVisibility}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
      keyboard={false}
    >
      <Typography.Title level={4}>Details</Typography.Title>
      <Typography.Paragraph>
        Traveler Name:
        {' '}
        {travelerName}
      </Typography.Paragraph>
      <Typography.Paragraph>
        Traveler Email:
        {' '}
        {travelerEmail}
      </Typography.Paragraph>
      <Typography.Title level={4}>JSON Response</Typography.Title>
      {searchResult ? (
        <ReactJsonView
          src={searchResult}
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
          name={false}
          theme='hopscotch'
          collapsed={3}
        />
      ) : <Spin size='small' />}
    </Modal>
  )
}

PNRModal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  pnrList: PropTypes.arrayOf(PropTypes.object),
  selectedPnr: PropTypes.string,
  selectedPnrType: PropTypes.string,
  datasource: PropTypes.string.isRequired,
}

PNRModal.defaultProps = {
  pnrList: [],
  selectedPnr: null,
  selectedPnrType: null,
}

export default PNRModal
