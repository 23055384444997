import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Layout,
  Typography,
  Input,
  Button,
  Row,
  Col,
  PageHeader,
  Form,
  Checkbox,
  Spin,
} from 'antd'
import { useVersionDetail } from '@core/hooks/useVersions'

import VersionsActions from '../../../store/actions/Versions'

import FaqVersions from './FaqVersions'
import I18nVersions from './I18nVersions'

const { Content } = Layout
const { Title, Text } = Typography

const EditVersion = () => {
  const { version_id } = useParams()
  const [version, setVersion] = useState(null) // Just using for check if response is back

  useEffect(() => {
    const fetchVersion = async () => {
      const versions = await VersionsActions.get() // NOTE: duplicate, now we have both redux and useState
      const selectedVersion = versions.find(({ id }) => { return id === version_id })
      setVersion(() => { return (selectedVersion) })
    }

    fetchVersion()
  }, [version_id])

  if (!version) return <Spin />

  return (
    <EditVersionForm />
  )
}

const EditVersionForm = () => {
  const [form] = Form.useForm()

  const { version_id } = useParams()
  const history = useHistory()
  const version = useVersionDetail(version_id)

  const [hasError, setHasError] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([version?.i18n_version])
  const [selectedFaqRowKeys, setSelectedFaqRowKeys] = useState([version?.faq_version_id])
  const [faqVersionUnlinked, setFaqVersionUnlinked] = useState(!version?.faq_version_id)

  const onBack = () => { history.replace('/versions') }

  const onUnlinkFaqVersion = (event) => {
    const { target: { checked } } = event
    setFaqVersionUnlinked(checked)
  }

  const onSelectedRowChange = (fieldName, setter) => {
    return (selectedKeys, selectedRows) => {
      form.setFieldsValue({ [fieldName]: selectedRows[0].id })
      setter(selectedKeys)
    }
  }

  const onFinish = async () => {
    setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        if (faqVersionUnlinked) values.faq_version_id = null

        response = await VersionsActions.update({ id: version_id, ...values })

        if (response) {
          form.resetFields()
          // navigate back to the Version page
          onBack()
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  useEffect(() => {
    if (version && version.id) {
      form.setFieldsValue({
        note: version.note,
      })
    }

    if (version && (version.i18n_version || version.faq_version_id)) {
      const versionFieldsValue = {}

      if (version.i18n_version) { versionFieldsValue.i18n_version = version.i18n_version }
      if (version.faq_version_id) { versionFieldsValue.faq_version_id = version.faq_version_id }

      form.setFieldsValue(versionFieldsValue)

      setSelectedRowKeys([version.i18n_version])
      setSelectedFaqRowKeys([version.faq_version_id])
    }
  }, [form, version])

  return (
    <Content className='EditVersion'>
      <PageHeader onBack={onBack} subTitle='Back to versions'>
        <Title>{`Edit Version ${version_id}`}</Title>
      </PageHeader>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          note: version && version.note ? version.note : undefined,
          i18n_version: version && version.i18n_version ? version.i18n_version : undefined,
          faq_version_id: version && version.faq_version_id ? version.faq_version_id : undefined,
        }}
      >
        <Row>
          <Col span='24'>
            <Form.Item
              name='note'
              label='Version note'
              rules={[
                {
                  required: true,
                  message: 'Please add version note',
                },
              ]}
            >
              <Input placeholder='Enter version note' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              hidden='true'
              name='i18n_version'
              label='i18n Version'
              rules={[
                {
                  required: true,
                  message: 'Please select an i18n version',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              hidden='true'
              name='faq_version_id'
              label='Faq version'
              rules={[
                {
                  required: !faqVersionUnlinked,
                  message: 'Please select an faq version',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Typography.Title level={4}>i18n version</Typography.Title>
            <I18nVersions
              onSelectI18nVersion={onSelectedRowChange('i18n_version', setSelectedRowKeys)}
              selectedRowKeys={selectedRowKeys}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={4}>FAQs version</Typography.Title>
            <Checkbox onChange={onUnlinkFaqVersion} checked={faqVersionUnlinked}>Unlink Faq version</Checkbox>
            <FaqVersions
              onSelectFaqVersion={onSelectedRowChange('faq_version_id', setSelectedFaqRowKeys)}
              selectedRowKeys={selectedFaqRowKeys}
              disabled={faqVersionUnlinked}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Button type='primary' htmlType='submit'>Submit</Button>
              {hasError && (<Text type='danger'>&thinsp;&thinsp;&thinsp;We&#39;ve encountered some problems, please try again in few minutes</Text>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  )
}

export default EditVersion
