import React, { useEffect, useState } from 'react'
import { Layout, Button, Divider, Form, Input, Checkbox, Select } from 'antd'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { filterEmailFromSearchParams } from '@core/helpers/searchParams'
import { DB_CHANNEL_MAP } from '@core/constants/channels'
import { useUser } from '../../../hooks/useUser'
import style from './index.module.scss'

const { Sider } = Layout

const useQuery = () => {
  const { search } = useLocation()
  return new URLSearchParams(search)
}

const UserListSider = () => {
  const query = useQuery()
  const { environment, setEnvironment, loadInitialDataSource, form, latestFilter } = useUser()

  const [collapsed, setCollapsed] = useState(false)
  const [isUnknownUsers, setIsUnknownUsers] = useState(false)

  useEffect(() => {
    const defaultValue = Object.fromEntries(query.entries())
    if (window.history.state?.email) {
      defaultValue.email = window.history.state.email
    }

    ['steps', 'channels', 'agentChannels'].forEach((key) => {
      if (defaultValue[key]) {
        defaultValue[key] = defaultValue[key].split(',')
      } else {
        defaultValue[key] = undefined
      }
    })

    const unknownUsers = query.get('unknownUsers')

    if (unknownUsers && unknownUsers === 'true') { setIsUnknownUsers(true) }
    form.setFieldsValue(defaultValue)
    const env = query.get('environment')
    if (env) {
      setEnvironment(env)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = async (values) => {
    const { unknownUsers, email, metricsUserEmail, metricsUserId, steps, channels, agentChannels } = values
    const filter = {
      ...(unknownUsers
        ? { unknownUsers: !!unknownUsers }
        : {
          unknownUsers: false,
          ...(email?.length && { email }),
          ...(metricsUserEmail?.length && { metricsUserEmail }),
          ...(metricsUserId?.length && { metricsUserId }),
        }
      ),
      ...(steps?.length && { steps }),
      ...(channels?.length && { channels }),
      ...(agentChannels?.length && { agentChannels }),
    }
    latestFilter.current = filter
    await loadInitialDataSource(filter)

    const newQuery = new URLSearchParams()
    newQuery.set('environment', environment)

    const filteredParams = filterEmailFromSearchParams(values)
    Object.entries(filteredParams).forEach(([key, value]) => {
      if (value) newQuery.set(key, value)
    })
    if (newQuery.has('email')) {
      newQuery.delete('email')
    }
    const newRelativePathQuery = `${window.location.pathname}?${newQuery.toString()}`
    window.history.pushState({ email }, '', newRelativePathQuery)
  }

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>User Query</strong>
        </Divider>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
        >
          <Divider>
            <small><strong>By Users&rsquo; Information</strong></small>
          </Divider>
          <Form.Item
            name='unknownUsers'
            style={{ marginBottom: 10 }}
            valuePropName='checked'
          >
            <Checkbox onChange={(e) => { return setIsUnknownUsers(e.target.checked) }}>Unknown Users</Checkbox>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='email'
            label='Email'
            tooltip={'User\'s email address (will be ignored, if "Unknown Users" is selected)'}
          >
            <Input disabled={isUnknownUsers} placeholder={'User\'s email'} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='metricsUserEmail'
            label='Email Metric ID'
            tooltip={'User\'s email metrics ID found on Grafana (will be ignored, if "Unknown Users" is selected)'}
          >
            <Input disabled={isUnknownUsers} placeholder={'User\'s Email Metric ID on Grafana'} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='metricsUserId'
            label='Metric ID'
            tooltip={'User\'s metrics ID found on Amplitude (will be ignored, if "Unknown Users" is selected)'}
          >
            <Input disabled={isUnknownUsers} placeholder={'User\'s Metric on Amplitude'} />
          </Form.Item>

          <Divider>
            <small><strong>By Pending Steps</strong></small>
          </Divider>
          <Form.Item style={{ marginBottom: 10 }} name='steps'>
            <Select
              placeholder='Select pending steps'
              allowClear
              defaultValue={undefined}
              mode='multiple'
              options={['Authentication', 'Conversation', 'PTC', 'CSAT'].map((channel) => {
                return {
                  label: channel,
                  value: channel,
                }
              })}
            />
          </Form.Item>

          <Divider>
            <small><strong>By Channels</strong></small>
          </Divider>
          <Form.Item style={{ marginBottom: 10 }} name='channels' label='Channels'>
            <Select
              placeholder='Select channels'
              allowClear
              defaultValue={undefined}
              mode='multiple'
              options={Object.entries(DB_CHANNEL_MAP).map(([DBChannel, channel]) => {
                return {
                  label: channel,
                  value: DBChannel,
                }
              })}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 10 }} name='agentChannels' label='Agent Channels'>
            <Select
              placeholder='Select agent channels'
              allowClear
              defaultValue={undefined}
              mode='multiple'
              options={['SALESFORCE', 'LIVE_ENGAGE'].map((channel) => {
                return {
                  label: channel,
                  value: channel,
                }
              })}
            />
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
          >
            Search
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

export default UserListSider
