import React from 'react'
import PropTypes from 'prop-types'
import { Card, Popover } from 'antd'
import { Handle, Position } from 'react-flow-renderer'

const customNodeStyles = {
  background: '#DC143C',
  color: '#fff',
  maxWidth: '250px',
  height: 'auto',
  maxHeight: '500px',
}

const { Meta } = Card

const SystemNode = ({ data, isConnectable }) => {
  return (
    <>
      <Card style={customNodeStyles}>
        <Meta description='ERROR' />
        <Popover title={data.original.split('##')[1]} style={{ width: 'auto', maxWidth: '500px' }}>
          <p>{data.original.split('##')[0]}</p>
        </Popover>
      </Card>
      <Handle type='target' position={Position.Left} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
      <Handle type='source' position={Position.Bottom} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
    </>
  )
}

SystemNode.propTypes = {
  data: PropTypes.shape({
    original: PropTypes.string.isRequired,
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
}

export default SystemNode
