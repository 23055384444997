import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, Typography, Button, Descriptions } from 'antd'
import { visibleType, closeModalType } from '../../../types/noteModal'
import NotesActions from '../../../store/actions/sentenceNotes'

const { Text } = Typography

const DeleteNoteModal = ({ visible, closeModal }) => {
  const { selectedSentence, selectedNote } = useSelector(({ data }) => {
    return {
      selectedSentence: data.sentences.selectedSentence,
      selectedNote: data.sentenceNotes.selectedNote,
    }
  })

  const onClose = () => { return closeModal() }

  const onClick = async () => {
    await NotesActions.delete(selectedSentence.id, selectedNote.id)
    onClose()
  }

  return (
    <Modal
      title='Delete note'
      visible={visible}
      onCancel={closeModal}
      closable={false}
      centered
      width={1000}
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button type='danger' onClick={onClick}>Delete</Button>,
      ]}
    >
      <Text type='secondary'>{`Sentence ID: ${selectedSentence.id}`}</Text>
      <Descriptions size='small' bordered column={5}>
        <Descriptions.Item label='Text' span={5}>{selectedSentence.text}</Descriptions.Item>
        <Descriptions.Item label='Generalized' span={5}>{selectedSentence.generalized.generalized}</Descriptions.Item>
        <Descriptions.Item label='Note to delete' span={5}><Text type='danger'>{selectedNote.note}</Text></Descriptions.Item>
        <Descriptions.Item label='Type' span={5}><Text type='danger'>{selectedNote.type}</Text></Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}

DeleteNoteModal.propTypes = {
  visible: visibleType.isRequired,
  closeModal: closeModalType.isRequired,
}

export default DeleteNoteModal
