import React from 'react'
import LogDisplay from './LogDisplay'
import LogDisplayProvider from './LogDisplayProvider'
import { UserPropTypes } from '../../../UserPropTypes'

const LogPanel = ({ user }) => {
  return (
    <LogDisplayProvider user={user}><LogDisplay /></LogDisplayProvider>
  )
}

LogPanel.propTypes = {
  user: UserPropTypes.isRequired,
}

export default LogPanel
