import PropTypes from 'prop-types'

const { shape, string, bool } = PropTypes

export const sentenceIdType = string
export const generalizedType = shape({
  generalized: string,
  original: string,
})
export const hasErrorType = shape({
  hasError: shape({
    extraction: bool,
    cases: bool,
  }),
})
export const dataType = shape({
  id: string,
  source: string,
  text: string,
})
