import React from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom'

import ConversationQuery from './pages/Conversations'
import ConversationDebug from './pages/Debug'
import ConversationResponseText from './pages/ResponseText'
import ConversationSimulator from './pages/Simulation'
import ConversationsTags from './pages/Tags'
import { PermissionRoute } from '../../pages/Routing'

const ConversationsModule = () => {
  const { path } = useRouteMatch() // path of this module would be '/conversation'

  return (
    <Switch>
      {/* NOTE: Later on we might need to limit visibility using PermissionRoute, but it would be rely on backend */}
      <Redirect from={`${path}`} to={`${path}/query`} exact />
      <Route path={`${path}/query`} exact>
        <ConversationQuery />
      </Route>
      <Route path={`${path}/query/:conversation_id`}>
        <ConversationDebug />
      </Route>

      {/*
        Keep these 'pattern' if we want the same level but different page
        without put a route inside the component...

        https://reactrouter.com/web/example/nesting

        /conversations/__some_other_page_name__
      */}
      <Route path={`${path}/response-text/version/:id`} exact>
        <ConversationResponseText />
      </Route>
      <Route path={`${path}/simulation`} exact>
        <ConversationSimulator />
      </Route>
      <PermissionRoute featurePath='conversations.tags.viewTab'>
        <Route
          path={`${path}/tags`}
          component={ConversationsTags}
          exact
        />
      </PermissionRoute>
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/conversations',
    component: ConversationsModule,
  },
  name: 'Conversations',
}

export default ConversationsModule
