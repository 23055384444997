import { createContext, useContext } from 'react'

export const UserContext = createContext({
  environment: 'PRODUCTION',
  setEnvironment: () => { },
  dataSource: [],
  setDataSource: () => { },
  loadDataSource: async () => { },
  isLoading: false,
  form: undefined,
  getFields: () => { },
  isInit: true,
  isPagination: false,
  page: 1,
  onPageChange: () => {},
  pageSize: 20,
  latestFilter: { current: {} },
  isPaginationLoading: false,
  nextPage: false,
  isSortLoading: false,
  setSorter: () => {},
})

export const useUser = () => { return useContext(UserContext) }
