import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import style from './style.module.scss'

const CopyPresetButton = ({ url, onClick }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
    }
    // backward compatible for old browsers
    document.execCommand('copy', true, text)
  }

  const handleCopyClick = async () => {
    try {
      await copyToClipboard(url)
      setIsCopied(true)

      onClick()

      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Button
      type='text'
      size='small'
      className={style.CopyPreset}
      title={url}
      icon={<CopyOutlined />}
      onClick={handleCopyClick}
      disabled={isCopied}
    />
  )
}

CopyPresetButton.propTypes = {
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default CopyPresetButton
