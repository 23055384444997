import { useState, useEffect, useRef } from 'react'
import Api from '../api'

const useAnalysis = (sentence) => {
  const [analysis, setAnalysis] = useState({})
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    const fetchAnalysis = async () => {
      setLoading(true)
      setIsError(false)
      try {
        const response = await Api.Analysis.extractInformation({ text: sentence })

        if (mounted.current) {
          setAnalysis(response.data)
          setLoading(false)
        }
      } catch (error) {
        if (mounted.current) {
          setIsError(error)
          setLoading(false)
        }
      }
    }

    fetchAnalysis()
    return () => {
      mounted.current = false
    }
  }, [sentence])

  return [{ loading, isError }, analysis]
}

export default useAnalysis
