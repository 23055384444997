import React from 'react'
import PropTypes from 'prop-types'
import { shallowCleanFalsyObject } from '@core/helpers'
import {
  Button,
  Form,
  Typography,
  Input,
  Select,
} from 'antd'
import style from './index.module.scss'

const QueryFilters = ({ loading, search, setCurrentSearch }) => {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    if (loading) return
    const filter = shallowCleanFalsyObject(values)
    setCurrentSearch(filter)
    search(filter)
  }

  return (
    <div className={style.QueryFilters}>
      <Typography.Title level={4}>Connector Response Text Lookup</Typography.Title>
      <Typography.Paragraph>
        <Typography.Text>
          <strong>hello</strong>
          : for exact match
        </Typography.Text>
        <Typography.Text>&thinsp;,&thinsp;</Typography.Text>
        <Typography.Text>
          <strong>hello*</strong>
          : for sentence ended with &lsquo;hello&rsquo;
        </Typography.Text>
        <Typography.Text>&thinsp;,&thinsp;</Typography.Text>
        <Typography.Text>
          <strong>*hello</strong>
          : for sentence started with &lsquo;hello&rsquo;
        </Typography.Text>
        <Typography.Text>&thinsp;,&thinsp;</Typography.Text>
        <Typography.Text>
          <strong>*hello*</strong>
          : for sentence contains &lsquo;hello&rsquo;
        </Typography.Text>
      </Typography.Paragraph>
      <Form
        form={form}
        onFinish={onFinish}
        layout='inline'
        aria-disabled={loading}
        initialValues={{
          key: '',
          value: null,
          version: null,
        }}
      >
        <Form.Item name='key' label='Key (wildcard match)'>
          <Input placeholder='Speech Key Path' />
        </Form.Item>
        <Form.Item name='value' label='Specific terms (wildcard match)'>
          <Input placeholder='' />
        </Form.Item>
        <Form.Item name='lang_code' label='Language Code'>
          <Select
            placeholder='Language Code'
            allowClear
          >
            <Select.Option value='en_US'>en-US</Select.Option>
            <Select.Option value='fr_FR'>fr-FR</Select.Option>
            <Select.Option value='de_DE'>de-DE</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' disabled={loading}>Submit</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

QueryFilters.propTypes = {
  loading: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  setCurrentSearch: PropTypes.func.isRequired,
}

export default QueryFilters
