/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { EntryVersionsModuleConfig } from '../../../modules/Versions'
import PrivateLayout from '../_layouts'

/*
  Regarding Entryway

  When the user signed in, they need to select the 'version' to working on first
  we do not let the user navigate to deeper route
  until version exists in the sessionStorage

*/

export default () => {
  return (
    <PrivateLayout>
      <Route {...EntryVersionsModuleConfig.routeProps} />
      <Redirect to='/versions' />
      <Redirect from='/' to='/versions' />
    </PrivateLayout>
  )
}
