import React, { useState, useEffect, useContext } from 'react'
import {
  Row,
  Col,
  Result,
  Divider,
} from 'antd'
import PropTypes from 'prop-types'
import useTimeoutAlert from '@core/hooks/useTimeoutAlert'
import Api from '../../../../api'
import { EmailAutomationsContext } from '../../../../context'
import { EmailItemsUpdaterContext } from '../context'

import EmailContentPreview from './EmailContentPreview'
import AnalysisStatus from './AnalysisStatus'
import IntentsList from './IntentsList'

const IntentTrainingV2 = ({ selectedEmail }) => {
  const { updateSpecificEmailStatus } = useContext(EmailItemsUpdaterContext)
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)

  // need to be sit inside status...
  const [statusIsLoading, setStatusIsLoading] = useState(false)
  const [emailStatus, setEmailStatus] = useState(selectedEmail.status)
  const [showStatusUpdateAlert, setShowStatusUpdateAlert] = useTimeoutAlert(null)

  const updateStatus = async (newStatus) => {
    setStatusIsLoading(true)

    try {
      const response = await Api.Emails.upsert({
        id: selectedEmail.id,
        status: newStatus,
        datasource,
        version: currentVersion,
      })

      if (response?.status === 'success') {
        updateSpecificEmailStatus(selectedEmail.id, newStatus)
        setEmailStatus(newStatus)
      }
    } catch (error) {
      console.error('IntentTraining#updateStatus - error - ', error)
      setShowStatusUpdateAlert({
        message: error.message,
        description: `${error.status} ${error.data?.status}`,
      })
    }

    setStatusIsLoading(false)
  }

  useEffect(() => {
    setEmailStatus(selectedEmail.status)
  }, [selectedEmail.status])

  return (
    <Row gutter={16}>
      <Col span={14}>
        <EmailContentPreview selectedEmail={selectedEmail} />
      </Col>
      <Col span={10}>
        {
          !selectedEmail
            ? (
              <Result
                status='warning'
                title='Email items malformed or not a correct data type'
              />
            )
            : (
              <>
                <AnalysisStatus
                  emailStatus={emailStatus}
                  updateStatus={updateStatus}
                  statusIsLoading={statusIsLoading}
                  showStatusUpdateAlert={showStatusUpdateAlert}
                />
                <Divider />
                <IntentsList
                  emailId={selectedEmail.id}
                  emailBody={selectedEmail.body}
                />
              </>
            )
        }
      </Col>
    </Row>
  )
}

IntentTrainingV2.defaultProps = {
  selectedEmail: null,
}

IntentTrainingV2.propTypes = {
  selectedEmail: PropTypes.shape({
    id: PropTypes.string,
    from_address: PropTypes.string,
    to_address: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    source: PropTypes.string,
    language: PropTypes.string,
    email_sent_at: PropTypes.string,
    record_created_at: PropTypes.string,
    status: PropTypes.string,
    feedback: PropTypes.string,
  }),
}

export default IntentTrainingV2
