import React, { useState } from 'react'
import { Tabs } from 'antd'

import AnalyzeDuplicates from '../AnalyzeDuplicates'
import NGramsOccurrence from '../NgramsOccurrence'
import NGramsFrequency from '../NgramsFrequency'

const { TabPane } = Tabs

const AnalysisOverview = () => {
  const [activeKey, setActiveKey] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : 'analyzeDistribution',
  )

  const handleActiveKeyChange = (newActiveKey) => {
    window.location.hash = `#${newActiveKey}`
    setActiveKey(newActiveKey)
  }

  return (
    <Tabs activeKey={activeKey} onChange={handleActiveKeyChange}>
      <TabPane key='analyzeDuplicates' tab='Analyze Duplicates'>
        <AnalyzeDuplicates />
      </TabPane>
      <TabPane key='ngramsOccurrence' tab='NGrams Occurrences'>
        <NGramsOccurrence />
      </TabPane>
      <TabPane key='ngramsFrequency' tab='NGrams Frequency'>
        <NGramsFrequency />
      </TabPane>
    </Tabs>
  )
}

export default AnalysisOverview
