import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Form, Typography } from 'antd'
import {
  idType,
  indexType,
  testDomainIdType,
  testIntentIdType,
  taggerDataType,
  extractorDataType,
  noteType,
  testCaseTypeType,
  modalVisibilityType,
  setModalVisibilityType,
  setSelectedItemType,
} from '../../types/updateCaseModal'
import TestSetsActions from '../../store/actions/TestSets'
import Modal from '../FormSubmissionModal'
import DomainForm from './Domain'
import IntentForm from './Intent'
import TaggerForm from './Tagger'
import ExtractorForm from './Extractor'

const { Text } = Typography
const handleFormError = ({ setConfirmLoading, setHasError, error }) => {
  console.info('Validate Failed:', error)
  setConfirmLoading(false)

  // NOTE: Must not be the validation error
  if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
}

// NOTE: We dont' have an update for the generalizer yet
const UpdateTestCase = ({
  id,
  index,

  test_domain_id,
  test_intent_id,
  tagger_data,
  extractor_data,
  note,
  testCaseType,

  modalVisibility,
  setModalVisibility,
  setSelectedItem,
}) => {
  const {
    intentTree,
    domainList,
    generalized,
  } = useSelector(({ core, data }) => {
    return {
      intentTree: core.domainIntent.intentTree,
      domainList: core.domainIntent.domainList,
      generalized: data.selectedSentence.generalized,
    }
  })
  const [form] = Form.useForm()
  const [intentList, setIntentList] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  useEffect(() => {
    const domainObj = intentTree.find((o) => { return `${o.domainId}` === `${test_domain_id}` })
    if (test_domain_id) setIntentList(domainObj.children)
  }, [test_domain_id, intentTree])

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasError(false)
    setSelectedItem({})
    form.resetFields()
  }

  const onDomainChange = (domain) => {
    if (domain === -1) {
      form.setFieldsValue({ test_intent_id: null })
    } else if (domain) {
      const domainObj = intentTree.find((o) => { return `${o.domainId}` === `${domain}` })
      setIntentList(domainObj.children)
      form.setFieldsValue({ test_intent_id: null })
    }
  }

  const handleFormSubmission = (handler) => {
    return async () => {
      if (hasError) setHasError(false)
      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields()
        if (values) {
          response = await handler(id, values, index)
          if (response) {
            form.resetFields()
            setConfirmLoading(false)
            closeModal() // or we could show the success instead
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    }
  }

  const formSubmission = {
    nullDomain: handleFormSubmission(TestSetsActions.updateDomainTestSetById),
    domain: handleFormSubmission(TestSetsActions.updateDomainTestSetById),
    intent: handleFormSubmission(TestSetsActions.updateIntentTestSetById),
    tagger: handleFormSubmission(TestSetsActions.updateTaggerTestSetById),
    extractor: handleFormSubmission(TestSetsActions.updateExtractorTestSetById),
  }

  if (testCaseType === 'nullIntent') testCaseType = 'intent'

  return (
    <Modal
      title={`Edit Test Case for ${testCaseType}`}
      visible={modalVisibility}
      onOk={formSubmission[testCaseType]}
      onCancel={closeModal}
      confirmLoading={confirmLoading}
      closable
      maskClosable={false}
    >
      {(testCaseType === 'domain' || testCaseType === 'nullDomain') && (
        <DomainForm
          form={form}
          test_domain_id={test_domain_id}
          note={note}
          domainList={domainList}
          onDomainChange={onDomainChange}
          generalized={generalized}
        />
      )}
      {testCaseType === 'intent' && (
        <IntentForm
          form={form}
          test_domain_id={test_domain_id}
          test_intent_id={test_intent_id}
          note={note}
          domainList={domainList}
          intentList={intentList}
          onDomainChange={onDomainChange}
          generalized={generalized}
        />
      )}
      {testCaseType === 'tagger' && (
        <TaggerForm
          form={form}
          taggerData={tagger_data}
          sentence={generalized.original}
        />
      )}
      {testCaseType === 'extractor' && (
        <ExtractorForm
          form={form}
          intentList={intentList}
          domainList={domainList}
          onDomainChange={onDomainChange}
          extractorData={extractor_data}
          sentence={generalized.original}
        />
      )}
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Modal>
  )
}

UpdateTestCase.propTypes = {
  id: idType.isRequired,
  index: indexType.isRequired,
  test_domain_id: testDomainIdType.isRequired,
  test_intent_id: testIntentIdType.isRequired,
  tagger_data: taggerDataType.isRequired,
  extractor_data: extractorDataType.isRequired,
  note: noteType.isRequired,
  testCaseType: testCaseTypeType.isRequired,
  modalVisibility: modalVisibilityType.isRequired,
  setModalVisibility: setModalVisibilityType.isRequired,
  setSelectedItem: setSelectedItemType.isRequired,
}

export default UpdateTestCase
