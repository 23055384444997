import StoreUtils from '@core/utils/store'

const CONVERSATION_ANALYSIS = {
  SET_ENVIRONMENT: 'CONVERSATION_ANALYSIS/SET_ENVIRONMENT',
  SET_DATE_RANGE: 'CONVERSATION_ANALYSIS/SET_DATE_RANGE',
  SET_PAGE_SIZE: 'CONVERSATION_ANALYSIS/SET_PAGE_SIZE',
  SELECT_FOR_ANALYZE: StoreUtils.createActionStatus('CONVERSATION_ANALYSIS/SELECT_FOR_ANALYZE'),
  FETCH_ANALYTIC: StoreUtils.createActionStatus('CONVERSATION_ANALYSIS/FETCH_ANALYTIC'),
  GET_GRAPH_CONVERSATIONS_IDS: StoreUtils.createActionStatus('CONVERSATION_ANALYSIS/GET_GRAPH_CONVERSATIONS_IDS'),
  GET_GRAPH_CONVERSATION: StoreUtils.createActionStatus('CONVERSATION_ANALYSIS/GET_GRAPH_CONVERSATION'),
  UPDATE_STATUS: StoreUtils.createActionStatus('CONVERSATION_ANALYSIS/UPDATE_STATUS'),
  SET_EXTRA_FILTERS: 'CONVERSATION_ANALYSIS/SET_EXTRA_FILTERS',
  FETCH_RESPONSE_KEY_LIST: StoreUtils.createActionStatus('CONVERSATION_ANALYSIS/FETCH_RESPONSE_KEY_LIST'),
  SET_VIEW_MODE: 'CONVERSATION_ANALYSIS/SET_VIEW_MODE',
}

export { CONVERSATION_ANALYSIS }
