import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
import JsonPreview from '@core/components/JsonPreview'
import DisplayFullConversations from './DisplayFullConversations'
import DisplayStateHierarchy from './DisplayStateHierarchy'
import DisplayNullInteractions from './DisplayNullInteractions'
import DisplayIntentInteractions from './DisplayIntentInteractions'
import DisplayIntentStructures from './DisplayIntentStructures'
import DisplayVocabularyIntent from './DisplayVocabularyIntent'
import ConversationAnalysisActions from '../../store/actions/analytics'
import { getCurrentI18nVersionId } from '../../helpers/context'

const renderAnalytics = {
  'null-Intent-Interactions.1': () => { return <DisplayNullInteractions before /> },
  'null-Intent-Interactions.2': () => { return <DisplayNullInteractions after /> },
  'intent-Interactions.1': () => { return <DisplayIntentInteractions before /> },
  'intent-Interactions.2': () => { return <DisplayIntentInteractions after /> },
  'conversation.1': () => { return <DisplayFullConversations /> },
  'conversation.2': () => { return <DisplayStateHierarchy /> },
  'intents.1': () => { return <DisplayIntentStructures /> },
  'vocabulary.1': () => { return <DisplayVocabularyIntent /> },
}

const ResultDisplay = () => {
  const i18nVersionId = getCurrentI18nVersionId()
  const { results, analysisKey, isPending } = useSelector((state) => { return state.conversationAnalysis.analytics })

  useEffect(() => {
    ConversationAnalysisActions.fetchResponseKeyList(i18nVersionId)
  }, [i18nVersionId])

  return (
    <>
      {isPending && <Spin />}
      {!isPending && analysisKey && renderAnalytics[analysisKey] && (results) && renderAnalytics[analysisKey]()}
      {!isPending && analysisKey && !renderAnalytics[analysisKey] && results
        && <JsonPreview content={JSON.stringify(results, null, 2)} autoSize />}
    </>
  )
}

export default ResultDisplay
