import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Button,
  Divider,
  Form,
  Radio,
  InputNumber,
  Select,
} from 'antd'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import useRequest from '@core/hooks/useRequest'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import WorkerMenu from './WorkerMenu'
import Api from '../../../api'
import style from './index.module.scss'

const { Sider } = Layout

const types = [
  { value: 'domain', text: 'Domain' },
  { value: 'intent', text: 'Intent' },
]

const trainingModes = [
  { value: 'new', text: 'New' },
  { value: 'retrain', text: 'Retrain' },
]

const LeftSider = ({ onSubmit }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()
  const { domainList, intentList } = useDomainIntentSelector()
  const [{ data: domainModels }] = useRequest(Api.getModels, { type: 'domain' })
  const [{ data: intentModels }] = useRequest(Api.getModels, { type: 'intent' })

  const onFinish = (values) => {
    onSubmit(values)
  }

  if (!domainModels || !intentModels) return null

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={480}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Training</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={{
            type: 'domain',
            trainingMode: 'new',
            epochs: 100,
            windowSize: 0,
            patternWindowSize: 0,
            trainingDataId: '',
            selectedKeys: [],
          }}
        >
          <Form.Item
            style={{ marginBottom: 10 }}
            name='type'
          >
            <Radio.Group size='small'>
              {types.map(({ value, text }) => { return <Radio.Button key={value} value={value}>{text}</Radio.Button> })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='trainingMode'
          >
            <Radio.Group size='small'>
              {trainingModes.map(({ value, text }) => { return <Radio.Button key={value} value={value}>{text}</Radio.Button> })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              if (prevValues.trainingMode !== curValues.trainingMode) return true
              return prevValues.type !== curValues.type
            }}
          >
            {({ getFieldValue }) => {
              const type = getFieldValue('type')
              const trainingMode = getFieldValue('trainingMode')
              const domainModelRadioChildren = domainModels.map(({ id, name }) => {
                return (
                  <Radio.Button key={id} value={id}>
                    {name || id}
                  </Radio.Button>
                )
              })
              const intentModelRadioChildren = intentModels.map(({ id, name }) => {
                return (
                  <Radio.Button key={id} value={id}>
                    {name || id}
                  </Radio.Button>
                )
              })
              const domainRadioChildren = domainList.map(({ id, name }) => {
                return (
                  <Radio.Button key={id} value={name}>
                    {name || id}
                  </Radio.Button>
                )
              })
              const intentRadioChildren = intentList.map(({ id, name }) => {
                return (
                  <Radio.Button key={id} value={name}>
                    {name || id}
                  </Radio.Button>
                )
              })

              if (trainingMode === 'new') {
                return (
                  <>
                    <Form.Item
                      style={{ marginBottom: 10 }}
                      label='Epochs'
                      name='epochs'
                      rules={[{ required: true, message: 'Please input your epochs!' }]}
                    >
                      <InputNumber placeholder='Default = 100' />
                    </Form.Item>
                    <Form.Item
                      style={{ marginBottom: 10 }}
                      label='Window Size'
                      name='windowSize'
                      rules={[{ required: true, message: 'Please input your window size!' }]}
                    >
                      <InputNumber placeholder='Default = 15' />
                    </Form.Item>
                    <Form.Item
                      style={{ marginBottom: 10 }}
                      label='Pattern Window Size'
                      name='patternWindowSize'
                      rules={[{ required: true, message: 'Please input your pattern window size!' }]}
                    >
                      <InputNumber placeholder='Default = 6' />
                    </Form.Item>
                  </>
                )
              }
              return (
                <>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label='Model'
                    name='trainingDataId'
                    rules={[{ required: true, message: 'Please select your model!' }]}
                  >
                    <Select
                      placeholder='Please select model'
                    >
                      {type === 'domain' ? domainModelRadioChildren : intentModelRadioChildren}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label={type === 'domain' ? 'Domains' : 'Intents'}
                    name='selectedKeys'
                    rules={[{ required: true, message: `Please select your ${type === 'domain' ? 'domains' : 'intents'}!` }]}
                  >
                    <Select
                      mode='multiple'
                      placeholder={type === 'domain' ? 'Please select domains' : 'Please select intents'}
                    >
                      {type === 'domain' ? domainRadioChildren : intentRadioChildren}
                    </Select>
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
          >
            Train (Run)
          </Button>
        </Form>
        <div style={{ paddingTop: 20 }}>
          <WorkerMenu />
        </div>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LeftSider
