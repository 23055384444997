import React from 'react'
import { Select, Form } from 'antd'
import PropTypes from 'prop-types'

const SentenceSelector = ({
  sentences,
  onSelectSentence,
}) => {
  return (
    <Form.Item
      name='sentenceIndex'
      label='Select sentence'
      rules={[{ required: true, message: 'Please select sentence' }]}
    >
      <Select placeholder='Sentence' onSelect={onSelectSentence}>
        {!sentences.length && <Select.Option value='' disabled selected>Unexpected empty sentences</Select.Option>}
        {sentences.length && sentences.map(({ text }, index) => {
          return (
            <Select.Option key={index} value={index}>{`[${index}]: ${text}`}</Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

SentenceSelector.propTypes = {
  sentences: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      tokens: PropTypes.arrayOf(
        PropTypes.shape({
          occurrenceIndex: PropTypes.number.isRequired,
          original: PropTypes.string.isRequired,
          sources: PropTypes.arrayOf(PropTypes.string),
          predictedSource: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  onSelectSentence: PropTypes.func.isRequired,
}

export default SentenceSelector
