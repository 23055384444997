import { generalizerClass } from '../constant/generalizer'

export const searchToArrayByComma = (search) => {
  const str = search.toLowerCase().trim()
  const terms = []
  let raw = false
  let firstCharacter = 0
  for (let i = 0; i < str.length; i++) {
    const character = str.charAt(i)
    if (character === '`') {
      raw = !raw
    }
    if (character === ',' && !raw) {
      terms.push(str.substring(firstCharacter, i).trim().replace(/`/gi, ''))
      firstCharacter = i + 1
    } else if (i === str.length - 1) {
      terms.push(str.substring(firstCharacter, i + 1).trim().replace(/`/gi, ''))
    }
  }
  return terms
}

// memo?
export const markTextDangerouslyAsHtml = (text, markingTextArray) => {
  if (!text) return { __html: '' }
  if (!Array.isArray(markingTextArray)) throw new Error('markTextDangerouslyAsHtml::You must pass text/word to mark as an array!')

  return {
    __html: (text.match(new RegExp(`\\b${markingTextArray.join('\\b|\\b')}\\b`, 'gi')) || [])
    .reduce((acc, value) => {
      const regexMatch = value.replace(/[.*+?^${}():;|[\]\\]/g, '\\$&')
      return acc.replace(new RegExp(`\\b${regexMatch}\\b`), `<###>${value}</###>`)
    }, text)
    .replace(/###/g, 'mark'),
  }
}

export const highlightTextAsHtml = (text, markingTextArray) => {
  if (!text) return ''
  if (!Array.isArray(markingTextArray)) throw new Error('highlightTextAsHtml::You must pass text/word to mark as an array!')

  return (text.match(new RegExp(`\\b${markingTextArray.join('\\b|\\b')}\\b`, 'gi')) || [])
  .reduce((acc, value) => {
    const regexMatch = value.replace(/[.*+?^${}():;|[\]\\]/g, '\\$&')
    return acc.replace(new RegExp(`\\b${regexMatch}\\b`), `<###>${value}</###>`)
  }, text)
  .replace(/###/g, 'mark')
}

export const markGeneralizedWord = (sentence) => {
  if (!sentence) return ''
  // https://stackoverflow.com/a/34199675
  // issue: if there is a same generalized word twice, it double. If triple, it triples the <mark> html
  return (sentence.match(new RegExp(/\b(Gs|Gx|Gc)[a-zA-z]*/g)) || [])
  .reduce((acc, value) => { return acc.replace(new RegExp(`\\b${value}\\b`, 'g'), `<###>${value}</###>`) }, sentence)
  .replace(/###/g, 'mark')
}

// NOTE: This is deprecated
export const markGeneralizedWordMemo = (memoDictionary) => {
  let memo = {}
  if (memoDictionary) memo = memoDictionary

  return (generalizedSentence) => {
    if (!generalizedSentence) return ''

    // https://stackoverflow.com/a/34199675
    // check regex: new RegExp(/\b(?!(<###>|<mark>))(Gs|Gx)[a-zA-z]*/g))
    return (generalizedSentence.match(new RegExp(/\b(Gs|Gx)[a-zA-z]*/g)) || [])
    .reduce((initialGeneralizedlArray, generalizedNextWord) => {
      if (initialGeneralizedlArray.indexOf(generalizedNextWord) === -1) {
        initialGeneralizedlArray.push(generalizedNextWord)
      }

      return initialGeneralizedlArray
    }, []) // remove duplicate
    .reduce((sentence, generalizedWord) => {
      if (memo[generalizedWord]) {
        return sentence.replaceAll(generalizedWord, memo[generalizedWord])
      }
      memo[generalizedWord] = `<### style='background: ${generalizerClass[generalizedWord]}'>${generalizedWord}</###>`

      return sentence
      .replace(new RegExp(`\\b${generalizedWord}\\b`, 'g'), `<### style='background: ${generalizerClass[generalizedWord]}'>${generalizedWord}</###>`)
    }, generalizedSentence) // replace with mark
    .replace(/###/g, 'mark')
  }
}

export const buildDynamicEntityValidator = (text) => {
  return ({ getFieldValue }) => {
    return {
      validator(_, value) {
        if (getFieldValue('type') !== 'PNRs') return Promise.resolve()
        let errors = 0
        value.split(',').forEach((word) => {
          const regex = new RegExp(`\\b${word.trim()}\\b`, 'gi')
          if (!text.match(regex)) errors++
        })
        // eslint-disable-next-line prefer-promise-reject-errors
        if (errors > 0) { return Promise.reject('All the synonyms should exist in the sentence') }
        return Promise.resolve()
      },
    }
  }
}
