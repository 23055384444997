/* eslint-disable no-bitwise */
import React from 'react'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
/*
                  Positive    ||    Negative
            || -------------------------------- ||
  Positive  || True Positive  || False Negative ||
  --------  || -------------------------------- ||
  Negative  || False Positive || True Negative  ||
            || -------------------------------- ||
*/

const ConfusionMatrix = ({ intentName, classification, handleClickChart }) => {
  // classification has only four value passed (for now)
  const {
    true_positives,
    true_negatives,
    false_positives,
    false_negatives,
  } = classification
  const xAxisTooltipLabel = ['Positive', 'Negative']
  const yAxisTooltipLabel = ['False', 'True']
  const visualMapMaxRange = Math.max(
    true_positives.value,
    true_negatives.value,
    false_positives.value,
    false_negatives.value,
  )
  const getClassificationTooltipLabel = (xor) => {
    return xor ? `${intentName}` : `Non-${intentName}`
  }

  const options = {
    tooltip: {
      position: 'top',
      formatter: (param) => {
        return `
          ${getClassificationTooltipLabel(param.data[0] ^ param.data[1])}
          <br/>
          ${yAxisTooltipLabel[param.data[0] ^ param.data[1]]} ${xAxisTooltipLabel[param.data[0]]}: <strong>${param.data[2]}</strong>
        `
      },
    },
    grid: {
      containLabel: true,
      height: '50%',
      top: '20%',
    },
    xAxis: {
      type: 'category',
      name: 'Predicted Classification',
      nameLocation: 'middle',
      nameTextStyle: {
        fontWeight: 'bold',
        color: '#000',
      },
      nameGap: 30,
      position: 'top',
      data: ['Positive', 'Negative'],
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: 'category',
      name: 'Actual Classification',
      nameLocation: 'middle',
      nameTextStyle: {
        fontWeight: 'bold',
        color: '#000',
      },
      nameGap: 75,
      data: ['Negative', 'Positive'],
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: visualMapMaxRange,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '10%',
    },
    series: [
      {
        type: 'heatmap',
        label: {
          show: true,
          fontSize: 16,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        data: [
          [0, 0, false_positives.value],
          [1, 0, true_negatives.value],
          [0, 1, true_positives.value],
          [1, 1, false_negatives.value],
        ],
      },
    ],
  }
  const onEvents = {
    click: (event) => {
      let emailIds = []

      const [x, y] = event.value
      if (x === 0 && y === 0) emailIds = false_positives.email_ids
      else if (x === 0 && y === 1) emailIds = true_positives.email_ids
      else if (x === 1 && y === 0) emailIds = true_negatives.email_ids
      else if (x === 1 && y === 1) emailIds = false_negatives.email_ids

      handleClickChart({ emailIds, type: `${yAxisTooltipLabel[x ^ y]} ${xAxisTooltipLabel[x]}`, intentName })
    },
  }
  return (
    <ReactECharts option={options} onEvents={onEvents} />

  )
}

ConfusionMatrix.defaultProps = {
  handleClickChart: () => { },
}

ConfusionMatrix.propTypes = {
  intentName: PropTypes.string.isRequired,
  classification: PropTypes.shape({
    false_positives: PropTypes.shape({
      value: PropTypes.number,
      email_ids: PropTypes.arrayOf(PropTypes.string),
    }),
    true_negatives: PropTypes.shape({
      value: PropTypes.number,
      email_ids: PropTypes.arrayOf(PropTypes.string),
    }),
    true_positives: PropTypes.shape({
      value: PropTypes.number,
      email_ids: PropTypes.arrayOf(PropTypes.string),
    }),
    false_negatives: PropTypes.shape({
      value: PropTypes.number,
      email_ids: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  handleClickChart: PropTypes.func,
}

export default ConfusionMatrix
