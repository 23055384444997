import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Typography, Button, Form, Input, Select, Row, Col, Divider, Descriptions } from 'antd'
import { visibleType, closeModalType } from '../../../types/noteModal'
import NotesActions from '../../../store/actions/sentenceNotes'

const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

const EditNoteModal = ({ visible, closeModal }) => {
  const { selectedNote, noteTypes, noteStatus, selectedSentence } = useSelector(({ data }) => {
    return {
      noteTypes: data.sentenceNotes.noteTypes,
      noteStatus: data.sentenceNotes.noteStatus,
      selectedSentence: data.sentences.selectedSentence,
      selectedNote: data.sentenceNotes.selectedNote,
    }
  })

  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      note: selectedNote.note,
      type: selectedNote.type,
      status: selectedNote.status,
    })
  }, [selectedNote, form])

  const noteTypeChildren = noteTypes.map((type) => { return <Option key={type}>{type}</Option> })
  const noteStatusChildren = noteStatus.map((status) => { return <Option key={status}>{status}</Option> })

  const onClose = async () => {
    await NotesActions.reset()
    closeModal()
  }
  const onSubmit = async (value) => {
    await NotesActions.edit({ id: selectedNote.id, ...value })
    await closeModal()
  }

  return (
    <Modal
      title='Edit note'
      visible={visible}
      onCancel={closeModal}
      closable={false}
      centered
      width={1000}
      footer={null}
    >
      <Text type='secondary'>{`Sentence ID: ${selectedSentence.id}`}</Text>
      <Descriptions size='small' bordered column={5}>
        <Descriptions.Item label='Text' span={5}>{selectedSentence.text}</Descriptions.Item>
        <Descriptions.Item label='Generalized' span={5}>{selectedSentence.generalized.generalized}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Form
        form={form}
        layout='horizontal'
        name='form__batch_add_test_case'
        onFinish={onSubmit}
        initialValues={{
          note: selectedNote.note,
          type: selectedNote.type,
          status: selectedNote.status,
        }}
      >
        <Form.Item name='note' label='Note' rules={[{ required: true, message: 'Please add a note' }]}><TextArea /></Form.Item>

        <Form.Item name='type' label='Type' rules={[{ required: true, message: 'Please select a type' }]}>
          <Select
            size='small'
            placeholder='Select type'
            style={{ width: '100%' }}
          >
            {noteTypeChildren}
          </Select>
        </Form.Item>

        <Form.Item name='status' label='Status' rules={[{ required: true, message: 'Please select a status' }]}>
          <Select
            size='small'
            placeholder='Select status'
            style={{ width: '100%' }}
          >
            {noteStatusChildren}
          </Select>
        </Form.Item>

        <Row>
          <Col>
            <Form.Item>
              <Button onClick={onClose}>Cancel</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type='primary' htmlType='submit'>Save</Button>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  )
}

EditNoteModal.propTypes = {
  visible: visibleType.isRequired,
  closeModal: closeModalType.isRequired,
}

export default EditNoteModal
