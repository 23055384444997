import callApi from '@core/utils/http-client'
import { getBaseUrlCanary, getBaseUrlStaging, getBaseUrlProduction } from '@core/helpers/gbtCloudVariableSwitcher'

const getEnvBaseUrl = {
  production: getBaseUrlProduction('https://hermes.messaging.gbtconnect.com'),
  staging: getBaseUrlStaging('https://hermes.staging.messaging.gbtconnect.com'),
  canary: getBaseUrlCanary('https://hermes.canary.messaging.gbtconnect.com'),
}

export default {
  getInformationCurrent: (params) => {
    return callApi({
      url: '/api/v1/information/current',
      method: 'get',
      params,
    })
  },
  getTeaProfileTraveler: (environment, params) => {
    return callApi({
      baseURL: environment ? getEnvBaseUrl[environment] : undefined,
      url: '/api/v1/travelers',
      method: 'get',
      params,
    })
  },
  getEmailUUIDConversion: (data) => {
    return callApi({
      url: '/api/v1/information/hash',
      method: 'put',
      data,
    })
  },
  getListAllChannelsEnabledByCapIdAndCountry: (environment, params) => {
    return callApi({
      baseURL: environment ? getEnvBaseUrl[environment] : undefined,
      url: '/api/v1/list-channel-deployments',
      method: 'get',
      params,
    })
  },
}
