export const UTC = 'UTC'
export const BKK = 'BKK'

export const CHANNEL = {
  DB: {
    MS_TEAMS: 'msteams',
    GOOGLE_CHAT: 'gchat',
    SMS: 'sms',
    WHATSAPP: 'whatsapp',
    SLACK: 'slack',
    ZOOM: 'zoom',
    GBT: 'gbt',
  },
}
