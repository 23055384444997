import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Layout,
  PageHeader,
  Select,
  Typography,
  Radio,
  Form,
  Button,
  Input,
} from 'antd'
import {
  useInternationalizeVersionSelector,
  useInternationalizeVersionNewMinVersion,
} from '@core/hooks/useInternationalize'
import InternationalActions from '@core/store/actions/Internationalize'

const CloneVersion = () => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)
  const [hasSentRequest, setHasSentRequest] = useState(false)
  const { version_id } = useParams()
  const history = useHistory()
  const i18nVersions = useInternationalizeVersionSelector().map((v) => { return { ...v, key: v.id } })
  const { minNewVersionNumber } = useInternationalizeVersionNewMinVersion()
  const requiredFieldRules = [{ required: true, message: 'Required field' }]

  const onBack = () => { history.replace('/versions/i18n') }
  const onFinish = async () => {
    const payload = form.getFieldsValue()
    payload.from_version = parseInt(version_id)
    if (payload.to_version_type === 'new') payload.to_version = minNewVersionNumber
    delete payload.to_version_type
    try {
      const response = await InternationalActions.clone(payload)
      if (response && response.id) {
        setHasSentRequest(true)
      }
    } catch (error) {
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Layout.Content className='InternationalizeVersion__Clone'>
      <PageHeader
        title={`Cloning i18n Response Text Version #${version_id}`}
        onBack={onBack}
      />
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        name='form__clone_response_text'
        initialValues={{
          app: null,
          from_version: null,
          to_version_type: null,
          to_version: null,
        }}
      >
        <Form.Item
          label='Select Message from App to clone'
          name='app'
          rules={requiredFieldRules}
        >
          <Select placeholder='App' style={{ width: 220 }}>
            <Select.Option value='lynx'>lynx (Bot)</Select.Option>
            <Select.Option value='pan'>pan (Connector)</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='To:'
          name='to_version_type'
          rules={requiredFieldRules}
        >
          <Radio.Group>
            <Radio value='exist'>Existing version</Radio>
            <Radio value='new'>{`New version (Version# ${minNewVersionNumber})`}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          dependencies={['to_version_type']}
        >
          {({ getFieldValue }) => {
            return (getFieldValue('to_version_type') === 'exist' ? (
              <Form.Item preserve={false} name='to_version' label='Select Existing version to clone to:' rules={requiredFieldRules}>
                <Select style={{ width: 320 }}>
                  {i18nVersions.map((version, index) => {
                    return ((version.id !== parseInt(version_id)) && (
                      <Select.Option value={version.id} key={index}>
                        <Typography.Text strong>{version.id}</Typography.Text>
                        {' / '}
                        <Typography.Text>{version.note}</Typography.Text>
                      </Select.Option>
                    ))
                  })}
                </Select>
              </Form.Item>
            ) : null)
          }}
        </Form.Item>
        <Form.Item
          label='Note'
          name='note'
          rules={[
            { required: false },
          ]}
        >
          <Input.TextArea
            placeholder='New-line response text'
            showCount={{ formatter: ({ count }) => { return `${count} / 160` } }}
            style={{ resize: 'vertical' }}
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>Submit</Button>
          {hasError && (<Typography.Text type='danger'>&thinsp;&thinsp;&thinsp;We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>)}
          {(hasSentRequest && !hasError) && (<Typography.Text type='danger'>Successfully cloned</Typography.Text>)}
        </Form.Item>
      </Form>
    </Layout.Content>
  )
}

export default CloneVersion
