import React from 'react'
import {
  Row,
  Col,
  Divider,
  Tabs,
} from 'antd'
import JsonPreview from '@core/components/JsonPreview'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import DomainOperation from '../DomainOperation'
import IntentOperation from '../IntentOperation'

const { TabPane } = Tabs

const DomainIntentOverview = () => {
  const {
    domainsIntentsTree,
    intentCascader,
    domainList,
    intentList,
    intentTree,
  } = useDomainIntentSelector()

  return (
    <Row gutter={[24, 16]}>
      <Col span={12}>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Simplified' key='1'>
            <JsonPreview content={JSON.stringify(domainsIntentsTree, null, 2)} autoSize />
          </TabPane>
          <TabPane tab='Domains' key='2'>
            <JsonPreview content={JSON.stringify(domainList, null, 2)} autoSize />
          </TabPane>
          <TabPane tab='Intents' key='3'>
            <JsonPreview content={JSON.stringify(intentList, null, 2)} autoSize />
          </TabPane>
        </Tabs>
      </Col>
      <Col span={12}>
        <DomainOperation domainList={domainList} />
        <Divider />
        <IntentOperation
          domainList={domainList}
          intentCascader={intentCascader}
          intentTree={intentTree}
        />
      </Col>
    </Row>
  )
}

export default DomainIntentOverview
