import dayjs from 'dayjs'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Button,
  Divider,
  Form,
  Radio,
  Input,
  DatePicker,
  Space,
} from 'antd'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import TagsSelect from '../../../components/TagsSelect'
import { searchToArrayByComma } from '../../../../Data/helpers/index'
import { conversationsDataFilterType } from '../../../types'

import style from './index.module.scss'

const { Sider } = Layout

const messageTypes = ['ANY', 'CUSTOMER', 'AGENT', 'BOT']
const datasources = ['staging', 'production', 'canary']
const botRelevanceTypes = ['ANY', 'BOT', 'NO_BOT']

const toInitialValues = (initialFilterValues) => {
  const initialValues = {
    ...initialFilterValues,
    texts: initialFilterValues.texts?.join(', ') ?? '',
    exclude_texts: initialFilterValues.exclude_texts?.join(', ') ?? '',
    regexes: initialFilterValues.regexes?.join(', ') ?? '',
    exclude_regexes: initialFilterValues.exclude_regexes?.join(', ') ?? '',
    conversation_ids: initialFilterValues.conversation_ids?.join(', ') ?? '',
    le_conversation_ids: initialFilterValues.le_conversation_ids?.join(', ') ?? '',
    emails: initialFilterValues.emails?.join(', ') ?? '',
  }

  if (initialFilterValues.date_range) {
    initialValues.date_range = [
      dayjs(initialFilterValues.date_range[0]),
      dayjs(initialFilterValues.date_range[1]),
    ]
  }

  return initialValues
}

const searchToArrayByCustomDelimiter = (text) => {
  if (!text || typeof text !== 'string') return []

  const regex = /[,\s\\n]/g
  const splitted = text.trim().replace(regex, ' ').split(/\s+/)

  return splitted.filter((item) => {
    return item
  })
}

const LeftSider = ({ initialFilterValues, onSearch }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()
  const onFinish = (values) => {
    const filter = {
      texts: searchToArrayByComma(values.texts),
      exclude_texts: searchToArrayByComma(values.exclude_texts),
      regexes: searchToArrayByComma(values.regexes),
      exclude_regexes: searchToArrayByComma(values.exclude_regexes),
      message_type: values.message_type,
      exclude_from_training: values.exclude_from_training,
      tags: values.tags,
      datasource: values.datasource,
      bot_relevance_type: values.bot_relevance_type,
      conversation_ids: searchToArrayByCustomDelimiter(values.conversation_ids),
      le_conversation_ids: searchToArrayByCustomDelimiter(values.le_conversation_ids),
      emails: searchToArrayByComma(values.emails),
    }

    if (values.date_range) {
      filter.date_range = [
        values.date_range[0].format('YYYY-MM-DD'),
        values.date_range[1].format('YYYY-MM-DD'),
      ]
    }
    onSearch(filter)
  }

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Conversation Query</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={toInitialValues(initialFilterValues)}
        >
          <Form.Item
            style={{ marginBottom: 10 }}
            name='texts'
          >
            <Input placeholder='Search by specific terms' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='exclude_texts'
          >
            <Input placeholder='Specific terms to be excluded' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='regexes'
          >
            <Input placeholder='Search by regex' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='exclude_regexes'
          >
            <Input placeholder='Regex to be excluded' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='conversation_ids'
          >
            <Input placeholder='Search by 30STF convo ID' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='le_conversation_ids'
          >
            <Input placeholder='Search by Amplitude convo ID' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='emails'
          >
            <Input placeholder='Search by email address' />
          </Form.Item>
          <Divider>
            <small><strong>By Sender</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='message_type'
          >
            <Radio.Group size='small'>
              {messageTypes.map((item, index) => { return <Radio.Button key={index} value={item}>{item}</Radio.Button> })}
            </Radio.Group>
          </Form.Item>
          <Divider>
            <small><strong>By Date Range</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='date_range'
          >
            <DatePicker.RangePicker format='DD/MM/YYYY' />
          </Form.Item>
          <Divider>
            <small><strong>By Bot Relevance</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='bot_relevance_type'
          >
            <Radio.Group size='small'>
              {botRelevanceTypes.map((botRelevanceType, index) => {
                return (
                  <Radio.Button key={index} value={botRelevanceType}>
                    {botRelevanceType.charAt(0).toUpperCase() + botRelevanceType.substring(1, botRelevanceType.length)}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Divider>
            <small><strong>By Training flag</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='exclude_from_training'
          >
            <Radio.Group>
              <Space direction='vertical'>
                <Radio value={-1}>All</Radio>
                <Radio value={0}>In Training</Radio>
                <Radio value={1}>Excluded</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Divider>
            <small><strong>By Tags</strong></small>
          </Divider>
          <TagsSelect multiple />
          <Divider>
            <small><strong>By Source</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='datasource'
          >
            <Radio.Group size='small'>
              {datasources.map((datasource, index) => {
                return (
                  <Radio.Button key={index} value={datasource}>
                    {datasource.charAt(0).toUpperCase() + datasource.substring(1, datasource.length)}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
          >
            Search
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSearch: PropTypes.func.isRequired,
  initialFilterValues: conversationsDataFilterType.isRequired,
}

export default LeftSider
