import React from 'react'
import { Button, List } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import DynamicEntitiesActions from '../../../store/actions/dynamicEntities'
import {
  entityType,
  setEditModalVisibleType,
  setDeleteModalVisibleType,
} from '../../../types/dynamicEntitiesInnerList'

const DynamicEntitiesInnerList = ({ entity, setEditModalVisible, setDeleteModalVisible }) => {
  const onClick = (ent, index, func) => {
    return async () => {
      await DynamicEntitiesActions.select({ ...ent, name: entity.name, index })
      func(true)
    }
  }

  return (
    <List
      size='small'
      dataSource={entity.entities}
      renderItem={(ent, index) => {
        return (
          <List.Item key={index}>
            <List.Item.Meta title={`Value: ${ent.value}`} description={`Synonyms: ${ent.synonyms.join(', ')}`} />
            <PermittedFeatureCheck featurePath='utterances.data.actionEditDynamicEntities'>
              <Button
                title='Edit Dynamic Entities'
                shape='circle'
                icon={<EditOutlined />}
                onClick={onClick(ent, index, setEditModalVisible)}
              />
            </PermittedFeatureCheck>
            <PermittedFeatureCheck featurePath='utterances.data.actionDeleteDynamicEntities'>
              <Button
                title='Delete Dynamic Entities'
                shape='circle'
                icon={<DeleteOutlined />}
                onClick={onClick(ent, index, setDeleteModalVisible)}
              />
            </PermittedFeatureCheck>
          </List.Item>
        )
      }}
    />
  )
}

DynamicEntitiesInnerList.propTypes = {
  entity: entityType.isRequired,
  setEditModalVisible: setEditModalVisibleType.isRequired,
  setDeleteModalVisible: setDeleteModalVisibleType.isRequired,
}

export default DynamicEntitiesInnerList
