import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import style from './index.module.scss'

const TabPaneLabel = ({ title, isActive, icon }) => {
  return (
    <Typography.Text
      className={style.tabPaneLabel}
      strong={isActive}
    >
      {icon}
      {title}
    </Typography.Text>
  )
}

TabPaneLabel.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
}

export default TabPaneLabel
