import axios from 'axios'
import StorageUtils from '@core/utils/storage'
import { Auth } from 'aws-amplify'
import store from './store'

const { getState } = store

export const setupApiClient = async () => {
  console.log('### setupApiClient ###')
  axios.interceptors.request.use(
    async (config) => {
      const coreReducers = getState().core
      const { versions } = coreReducers
      const headers = {
        ...config.headers,
        'x-version': versions.current?.id || 'DEFAULT',
      }
      /**
       * Auth.currentSession will get new accessToken when It's expired
       * Please test with login provider that you are going to use.
       * Work well with .amazoncognito authentication
       */
      const session = await Auth.currentSession()
      headers['x-access-token'] = session?.getAccessToken()?.getJwtToken() ?? ''

      console.groupCollapsed('::axios.interceptors.request::', config.url)
      console.log(config)
      console.trace('%cCalled by', 'font-weight: bold;')
      console.groupEnd()

      return {
        ...config,
        headers,
      }
    },
    (error) => { return Promise.reject(error) },
  )

  axios.interceptors.response.use(
    (response) => {
      console.groupCollapsed('::axios.interceptors.response::', response.config.url)
      console.log(response)
      console.groupEnd()

      return response
    },
    (rejected) => {
      if (rejected.response.status === 401 || rejected.response.status === '401') {
        // Fastest bailed out when 401 triggered, remove token to make the app take user out of system
        StorageUtils.clearAll()
      }

      return Promise.reject(rejected)
    },
  )
}
