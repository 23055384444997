const filterEmailFromSearchParams = (filter) => {
  const emailRegex = new RegExp(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)
  const filteredParams = Object.keys(filter ?? []).reduce((filtered, key) => {
    if (Array.isArray(filter[key])) {
      filtered[key] = filter[key]
      filter[key].forEach((value) => {
        if (emailRegex.test(value)) delete filtered[key]
      })
    } else if (!emailRegex.test(filter[key]) && filter[key]) filtered[key] = filter[key]

    return filtered
  }, {})

  return filteredParams
}

export { filterEmailFromSearchParams }
