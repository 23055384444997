import callApi from '@core/utils/http-client'

export default {
  ClientGroups: {
    findMany: ({ _, ...rest }) => {
      const {
        nameOrDescription,
        clientGroupType: type,
        environment,
        capIds,
        countryOfEmployment,
        page,
        perPage,
        showAll,
      } = rest
      return callApi({
        url: '/api/v1/client-groups/dashboard-search',
        method: 'post',
        data: {
          nameOrDescription,
          type,
          environment,
          capIds,
          countryOfEmployment,
          page,
          perPage,
          showAll,
        },
      })
    },
    insertMany: (data) => {
      return callApi({
        url: '/api/v1/client-groups',
        method: 'post',
        data,
      })
    },
    updateById: (id, data) => {
      return callApi({
        url: `/api/v1/client-groups/${id}`,
        method: 'put',
        data,
      })
    },
    updateManyAssignedFeatures: (data) => {
      return callApi({
        url: '/api/v1/client-groups/assign',
        method: 'post',
        data,
      })
    },
    updateManyAssignedChannels: (data) => {
      return callApi({
        url: '/api/v1/client-groups/channels',
        method: 'post',
        data,
      })
    },
    deleteById: (id) => {
      return callApi({
        url: `/api/v1/client-groups/${id}`,
        method: 'delete',
      })
    },
    findAvailableCapIdsAndCountryOfEmployment: () => {
      return callApi({
        url: '/api/v1/client-groups/cap-country',
        method: 'get',
      })
    },
    searchAvailableChannels: (data = { channels: [] }) => {
      return callApi({
        url: '/api/v1/client-groups/channels-search',
        method: 'post',
        data,
      })
    },
  },
  FeatureFlags: {
    insertOne: ({ name, key, type }) => {
      return callApi({
        url: '/api/v1/feature-flags',
        method: 'post',
        data: { name, key, type },
      })
    },
    findMany: ({ _, ...rest }) => {
      const {
        featureFlagType: type,
        environment,
        page,
        perPage,
        nameOrKey,
        showAll,
      } = rest
      return callApi({
        url: '/api/v1/feature-flags/search',
        method: 'post',
        data: {
          type,
          environment,
          page,
          perPage,
          nameOrKey,
          showAll,
        },
      })
    },
    updateById: (id, payload) => {
      return callApi({
        url: `/api/v1/feature-flags/${id}`,
        method: 'patch',
        data: payload,
      })
    },
    deleteById: (id) => {
      return callApi({
        url: `/api/v1/feature-flags/${id}`,
        method: 'delete',
      })
    },
  },
}
