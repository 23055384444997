import React from 'react'
import PropTypes from 'prop-types'
import { Space, Typography } from 'antd'
import ReactJsonView from 'react-json-view'

import style from './style.module.scss'

const EmailAnalysisResponseLog = ({ analysisResponse }) => {
  return (
    <div className={style.EmailAnalysisResponseLog}>
      <Space direction='vertical'>
        <Typography.Text strong>Email Analysis Response Log</Typography.Text>
        <ReactJsonView
          src={analysisResponse}
          name={false}
          theme='monokai'
          displayDataTypes={false}
        />
      </Space>
    </div>
  )
}

EmailAnalysisResponseLog.defaultProps = {
  analysisResponse: undefined,
}

EmailAnalysisResponseLog.propTypes = {
  analysisResponse: PropTypes.shape({
    data: PropTypes.shape({
      lang: PropTypes.string,
      status: PropTypes.string,
      intents: PropTypes.arrayOf(PropTypes.string),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          intent: PropTypes.string,
          specificIntent: PropTypes.string,
          details: PropTypes.objectOf(PropTypes.string),
        }),
      ),
    }),
    status: PropTypes.string,
    responseTime: PropTypes.string,
    transactionToken: PropTypes.string,
  }),
}

export default EmailAnalysisResponseLog
