import React, { useState } from 'react'
import {
  Row,
  Col,
  Typography,
  Form,
  Button,
  Select,
  Spin,
} from 'antd'
import JsonPreview from '@core/components/JsonPreview'
import generalizer from '../../../Data/constant/generalizer'
import Api from '../../api'

const entities = Object.keys(generalizer)

const AnalyzeDuplicates = () => {
  const [form] = Form.useForm()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onFinish = async () => {
    setLoading(true)
    setHasError(false)
    try {
      const values = await form.validateFields()
      const response = await Api.postDuplicates(values)
      setData(response.data)
    } catch (error) {
      setHasError(true)
    }
    setLoading(false)
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={8}>
        <Typography.Title level={4}>Check the duplicates</Typography.Title>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout='horizontal'
          onFinish={onFinish}
        >
          <Form.Item
            name='entities'
            label='Entities'
            rules={[{ required: true, message: 'Please select your entities!' }]}
          >
            <Select mode='multiple' placeholder='Select Entities'>
              {entities.map((entity) => { return <Select.Option key={entity} value={entity}>{entity}</Select.Option> })}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button type='primary' htmlType='submit' disabled={loading}>
              Analyze
            </Button>
          </Form.Item>
          {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
        </Form>
      </Col>
      <Col span={16}>
        {loading && <Spin />}
        {data && <JsonPreview content={JSON.stringify(data, null, 2)} autoSize />}
      </Col>
    </Row>
  )
}

export default AnalyzeDuplicates
