import PropTypes from 'prop-types'

const {
  string,
  objectOf,
  array,
  func,
} = PropTypes

export const caseType = string
export const dataSourceType = objectOf(array)
export const renderAddNewCaseFormType = func
