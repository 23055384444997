import { useReducer } from 'react'
import clonedeep from 'lodash.clonedeep'
import StoreUtils from '@core/utils/store'
import { reHashIndex } from '@core/helpers'
import { v4 as uuidv4 } from 'uuid'
import Api from '../api'

// oops
const peek = (array) => { return array[array.length - 1] }

const INITIAL_STATE = {
  loading: false,
  hasError: false,
  error: null,
  conversationId: uuidv4(),
  data: {
    pendingMessage: {},
    pendingExchange: [],
    // NOTE: probably just look direct via id instead of new location...
    conversationData: {
      fromExchangeId: '',
      dynamicEntities: [],
      state: {},
      profile: {},
    },
    allowToUpdateStats: true,
    latestExchangeId: '',
    exchanges: [],
    exchangesHashTable: {},
    rawExchanges: [],
  },
}

export const CHAT_SIMULATION = {
  SIMULATE: {
    ...StoreUtils.createActionStatus('CHAT_SIMULATION/SIMULATE'),
    RESET_WITH_DATA: 'CHAT_SIMULATION/SIMULATE/RESET_WITH_DATA',
  },
  RESET_SIMULATION_IN_ANALYTICS: StoreUtils.createActionStatus('CHAT_SIMULATION/RESET_SIMULATION_IN_ANALYTICS'),
  UPDATE_DATA: 'CHAT_SIMULATION/UPDATE_DATA',
  VIEW: 'CHAT_SIMULATION/VIEW',
  REVERT: 'CHAT_SIMULATION/REVERT',
  LOAD: 'CHAT_SIMULATION/LOAD',
  REFRESH_CONVERSATION_ID: 'CHAT_SIMULATION/REFRESH_CONVERSATION_ID',
}

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHAT_SIMULATION.SIMULATE.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
        data: {
          ...state.data,
          pendingMessage: { ...action.payload },
          pendingExchange: [{ text: action.payload.text }],
        },
      }
    }
    case CHAT_SIMULATION.SIMULATE.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        data: action.payload.data,
      }
    }
    case CHAT_SIMULATION.SIMULATE.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
        data: {
          ...state.data,
          pendingMessage: {},
          pendingExchange: [],
        },
      }
    }
    case CHAT_SIMULATION.RESET_SIMULATION_IN_ANALYTICS.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
      }
    }
    case CHAT_SIMULATION.RESET_SIMULATION_IN_ANALYTICS.SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case CHAT_SIMULATION.RESET_SIMULATION_IN_ANALYTICS.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      }
    }
    case CHAT_SIMULATION.SIMULATE.RESET: {
      return { ...INITIAL_STATE }
    }
    case CHAT_SIMULATION.SIMULATE.RESET_WITH_DATA: {
      return {
        ...INITIAL_STATE,
        data: {
          ...INITIAL_STATE.data,
          conversationData: {
            ...INITIAL_STATE.data.conversationData,
            ...action.payload,
          },
        },
      }
    }
    case CHAT_SIMULATION.UPDATE_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          conversationData: action.payload.conversationData,
          exchanges: action.payload.exchanges,
        },
      }
    }
    case CHAT_SIMULATION.VIEW: {
      return {
        ...state,
        data: {
          ...state.data,
          allowToUpdateStats: peek(state.data.exchanges).id === action.payload.fromExchangeId,
          conversationData: {
            fromExchangeId: action.payload.fromExchangeId,
            ...action.payload.conversationData,
          },
        },
      }
    }
    case CHAT_SIMULATION.REVERT: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      }
    }
    case CHAT_SIMULATION.LOAD: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case CHAT_SIMULATION.REFRESH_CONVERSATION_ID: {
      return {
        ...state,
        conversationId: uuidv4(),
      }
    }
    default: return state
  }
}

const uniqueDynamicEntities = (dynamicEntities) => {
  const occurances = {}

  return dynamicEntities.filter((dynamicEntityObject) => {
    if (occurances[dynamicEntityObject.name]) return false

    occurances[dynamicEntityObject.name] = true
    return true
  })
}

const ChatSimulatorActions = (dispatch, state) => {
  return {
    simulate: async (payload, env) => {
      const {
        data: {
          conversationData,
          exchanges,
        },
        conversationId,
      } = state

      payload.tripCacheId = conversationData.tripCacheId
      payload.data = conversationData

      payload.conversationId = conversationId
      delete payload.data.tripCacheId
      delete payload.data.fromExchangeId

      // chatDate should not be send if there is even one exchange
      // meaning state machine already records the chat date
      if (!exchanges.length) payload.data.chatDate = payload.chatDate

      dispatch({
        type: CHAT_SIMULATION.SIMULATE.REQUEST,
        payload,
      })

      try {
        const response = await Api.Simulation.simulate(payload, env)

        if (response.status === 'success') {
          const newExchanges = clonedeep(state.data.exchanges)
          const newRawExchanges = clonedeep(state.data.rawExchanges)
          newExchanges.push({
            id: `ex${newExchanges.length + 1}`,
            responseTime: response.responseTime,
            texts: [
              {
                speech: response.data.incomingText,
                type: 'user',
              },
              ...response.data.responseTexts.map((responseText) => {
                return {
                  ...responseText,
                  type: 'bot',
                }
              }),
            ],
            exchangeData: {
              state: response.data.state,
              profile: response.data.profile,
              dynamicEntities: uniqueDynamicEntities(response.data.dynamicEntities),
            },
          })

          newRawExchanges.push({ ...response.data, responseTime: response.responseTime })

          dispatch({
            type: CHAT_SIMULATION.SIMULATE.SUCCESS,
            payload: {
              data: {
                pendingMessage: {},
                pendingExchange: [],
                conversationData: {
                  tripCacheId: response.data.tripCacheId,
                  fromExchangeId: peek(newExchanges).id,
                  ...newExchanges[newExchanges.length - 1].exchangeData,
                },
                allowToUpdateStats: true,
                latestExchangeId: peek(newExchanges).id,
                exchanges: newExchanges,
                exchangesHashTable: reHashIndex(newExchanges),
                rawExchanges: newRawExchanges,
              },
            },
          })

          return response.data
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: CHAT_SIMULATION.SIMULATE.FAILED,
          error,
        })
        throw error
      }
    },
    reset: async (existingMockDateAndUser, trackConversationAnalytics = false, selectedSimulationTarget) => {
      const { conversationId } = state
      if (trackConversationAnalytics) {
        try {
          dispatch({ type: CHAT_SIMULATION.RESET_SIMULATION_IN_ANALYTICS.REQUEST })
          await Api.Simulation.closeSimulation(conversationId, selectedSimulationTarget)
          dispatch({ type: CHAT_SIMULATION.RESET_SIMULATION_IN_ANALYTICS.SUCCESS })
        } catch (error) {
          dispatch({ type: CHAT_SIMULATION.RESET_SIMULATION_IN_ANALYTICS.FAILED, error })
        }
      }
      if (!existingMockDateAndUser.user || !existingMockDateAndUser.user.email) {
        dispatch({ type: CHAT_SIMULATION.SIMULATE.RESET })
      } else {
        dispatch({
          type: CHAT_SIMULATION.SIMULATE.RESET_WITH_DATA,
          payload: {
            profile: existingMockDateAndUser.user,
          },
        })
      }
      dispatch({ type: CHAT_SIMULATION.REFRESH_CONVERSATION_ID })
    },
    updateConversationData: (newConversationData) => {
    // NOTE: The only one that we can update is 'ALWAYS' the last one
    // so we can assumed we can use a peek of the last one

      const updatedConversationData = {
        ...state.data.conversationData,
        ...newConversationData,
      }

      let newExchanges = []
      if (state.data.exchanges.length) {
      // NOTE: updated, another case came in
      // if it is the initial state, meaning exchanges size is 0
      // the code block here will not work, need to set base flag
        newExchanges = clonedeep(state.data.exchanges)
        newExchanges[newExchanges.length - 1].exchangeData = {
          ...newExchanges[newExchanges.length - 1].exchangeData,
          ...updatedConversationData,
        }
      }

      dispatch({
        type: CHAT_SIMULATION.UPDATE_DATA,
        payload: {
          conversationData: updatedConversationData,
          exchanges: newExchanges,
        },
      })
    },
    view: (exchangeHashId) => {
      const { data } = state
      const conversationData = data.exchanges[data.exchangesHashTable[exchangeHashId]].exchangeData
      dispatch({
        type: CHAT_SIMULATION.VIEW,
        payload: {
          fromExchangeId: exchangeHashId,
          conversationData,
        },
      })
    },
    revert: (exchangeHashId) => {
      const { data } = state
      const indexSliceMax = data.exchangesHashTable[exchangeHashId] + 1
      const revertedExchanges = data.exchanges.slice(0, indexSliceMax)

      dispatch({
        type: CHAT_SIMULATION.REVERT,
        payload: {
          data: {
            conversationData: {
              fromExchangeId: revertedExchanges[revertedExchanges.length - 1].id,
              dynamicEntities: revertedExchanges[revertedExchanges.length - 1].exchangeData.dynamicEntities,
              state: revertedExchanges[revertedExchanges.length - 1].exchangeData.state,
              profile: revertedExchanges[revertedExchanges.length - 1].exchangeData.profile,
            },
            allowToUpdateStats: true,
            latestExchangeId: peek(revertedExchanges).id,
            exchanges: revertedExchanges,
            exchangesHashTable: reHashIndex(revertedExchanges),
          },

        },
      })
    },
    load: (saveData) => {
      const { exchanges } = saveData
      const latestExchange = exchanges[exchanges.length - 1]
      const transformedExchange = exchanges.map((exchange, index) => {
        return {
          id: `ex${index + 1}`,
          texts: [
            { speech: exchange.user_text, type: 'user' },
            ...exchange.bot_responses.map((botResponse) => {
              const [variation, stage] = botResponse.i18n_key.split('.')
              return {
                variation,
                stage,
                type: 'bot',
                replaced: [botResponse.text],
              }
            }),
          ],
          exchangeData: {
            dynamicEntities: uniqueDynamicEntities(exchange.dynamic_entities),
            state: exchange.state,
            profile: exchange.profile,
          },
        }
      })

      const payload = {
        pendingMessage: {},
        pendingExchange: [],
        conversationData: {
          fromExchangeId: `ex${exchanges.length}`,
          dynamicEntities: uniqueDynamicEntities(latestExchange.dynamic_entities),
          state: latestExchange.state,
          profile: latestExchange.profile,
        },
        allowToUpdateStats: true,
        latestExchangeId: `ex${exchanges.length}`,
        exchanges: transformedExchange,
        exchangesHashTable: reHashIndex(transformedExchange),
        rawExchanges: exchanges,
      }

      dispatch({ type: CHAT_SIMULATION.LOAD, payload })

      return payload // make it return something
    },
  }
}

export const useChatSimulator = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return [state, ChatSimulatorActions(dispatch, state)]
}
