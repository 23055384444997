import React from 'react'
import PropTypes from 'prop-types'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

const TestExpandIcon = ({ renderExpandIconProps }) => {
  const { expanded, onExpand, record } = renderExpandIconProps
  if (record.status === 'RUNNING' || record.evaluationResults.length < 1) return null

  return expanded
    ? <MinusOutlined onClick={(e) => { return onExpand(record, e) }} />
    : <PlusOutlined onClick={(e) => { return onExpand(record, e) }} />
}

TestExpandIcon.propTypes = {
  renderExpandIconProps: PropTypes.shape({
    expanded: PropTypes.bool.isRequired,
    onExpand: PropTypes.func.isRequired,
    record: PropTypes.shape({
      status: PropTypes.string,
      evaluationResults: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
}

export default TestExpandIcon
