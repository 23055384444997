import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

const Status = ({ emailStatus, statusList, onSelect }) => {
  const [status, setStatus] = useState(emailStatus || 'NEW')
  const [isChanging, setIsChanging] = useState(false)

  useEffect(() => {
    setStatus(emailStatus)
  }, [emailStatus])

  const onSelectNewStatus = async (newStatus) => {
    setIsChanging(true)
    setStatus(newStatus)
    await onSelect(newStatus)
    setIsChanging(false)
  }

  return (
    <Select
      loading={isChanging}
      disabled={isChanging}
      value={status}
      style={{ width: 120 }}
      onSelect={onSelectNewStatus}
    >
      <Select.Option value='NEW' disabled>NEW</Select.Option>
      {statusList.map((statusOption) => { return (<Select.Option key={statusOption} value={statusOption}>{statusOption}</Select.Option>) })}
    </Select>
  )
}

Status.propTypes = {
  emailStatus: PropTypes.string.isRequired,
  statusList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default Status
