import React, { useContext, useEffect, useState } from 'react'
import { Form, Select, Spin } from 'antd'
import { FaqVersionContext, FaqChoiceCategoriesContext } from '../../../../helpers/context'
import { useFetchFaqChoiceCategoriesFilter } from '../../../../hooks/useManageFaqs'
import ChoicesResponse from './ChoicesResponse'
import DirectChoiceResponseVariations from './DirectResponse'

const Answers = () => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [selectedChoiceCategoryId, setSelectedChoiceCategoryId] = useState(undefined)
  const [selectedChoiceCategory, setSelectedChoiceCategory] = useState(undefined)
  const [{ loading }, choiceCategories, choiceCategoriesHash] = useFetchFaqChoiceCategoriesFilter(faqVersionId)

  useEffect(() => {
    setSelectedChoiceCategory(() => {
      return {
        ...choiceCategories[choiceCategoriesHash[selectedChoiceCategoryId]],
      }
    })
  }, [choiceCategories, choiceCategoriesHash, selectedChoiceCategoryId])

  return (
    <>
      <Form.Item
        label='Choice Category'
        name='choice_category_id'
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        {loading
          ? <Spin />
          : (
            <Select
              placeholder='Choice Category'
              loading={loading}
              value={selectedChoiceCategoryId}
              onChange={setSelectedChoiceCategoryId}
              allowClear
            >
              {choiceCategories.map(
                ({ id, name }, index) => {
                  return (
                    <Select.Option
                      key={index}
                      value={id}
                    >
                      {name}
                    </Select.Option>
                  )
                },
              )}
            </Select>
          )}
      </Form.Item>
      {selectedChoiceCategoryId ? (
        <FaqChoiceCategoriesContext.Provider value={selectedChoiceCategory}>
          <ChoicesResponse
            choiceCategoryId={selectedChoiceCategoryId}
            faqVersionId={faqVersionId}
          />
        </FaqChoiceCategoriesContext.Provider>
      ) : <DirectChoiceResponseVariations />}
    </>
  )
}

export default Answers
