import React from 'react'
import { Form, Select, Divider } from 'antd'

const containsCreditCardOptions = [
  { label: 'Masked', value: 'masked' },
  { label: 'Not Masked', value: 'not_masked' },
  { label: 'Unknown', value: 'unknown' },
]

const ByCreditCardMasking = () => {
  return (
    <>
      <Divider>
        <small><strong> By Credit Card Masking </strong></small>
      </Divider>
      <Form.Item name='containsCreditCard'>
        <Select
          mode='multiple'
          placeholder='Select Statuses'
          allowClear
          options={containsCreditCardOptions}
        />
      </Form.Item>
    </>
  )
}

export default ByCreditCardMasking
