import httpClient from '@core/utils/http-client'

// using with useRequest
// --- previously
// const [{ loading, data, hasError }] = useRequest(Api.getIntents, { version: currentVersion })
// --- instead
// const [{ loading, data, hasError }] = useRequest(Api.getIntents({ version: currentVersion }))

// others
// --- previously
// const [{ loading, data, hasError }, { makeRequest }] = useRequest(Api.getIntents, { version: currentVersion })
// --- instead
// const [{ loading, data, hasError }, { makeRequest }] = useRequest(Api.getIntents({ version: currentVersion }), ...any_other_params)

// otherwise call axios directly

const callApi = (request) => {
  const {
    version,
    datasource,
    preprocessingMethod,
  } = request
  const headers = { ...request.headers }
  if (version) headers['x-email-version'] = version
  if (datasource) headers['x-datasource'] = datasource
  if (preprocessingMethod) headers['x-preprocessing-method'] = preprocessingMethod

  const appRequestId = request['x-app-request-id']
  if (appRequestId) {
    headers['x-app-request-id'] = appRequestId
    delete request['x-app-request-id']
  }

  delete request.version
  delete request.datasource

  return httpClient({
    ...request,
    headers,
  })
}

// NOTE: We can come back to this one, I was just unabler to use it

// const callApi = ({ version }) => {
//   return (...params) => {
//     return httpClient({
//       headers: {
//         'x-email-version': version,
//       },
//       ...params,
//     })
//   }
// }

export default callApi
