import { useSelector } from 'react-redux'

export const useVersionSelector = () => {
  const versionsReducer = useSelector(({ core }) => { return core.versions })

  return versionsReducer
}

export const useVersionCurrentlySelected = () => {
  return useSelector(({ core }) => {
    const { versions: { current } } = core
    return current
  })
}

export const useVersionDetail = (id) => {
  return useSelector(({ core }) => {
    if (!id) {
      return undefined
    }

    const { versions: { hashmap, listed } } = core
    const versionHashCursor = hashmap[id]

    return listed[versionHashCursor]
  })
}
