import React from 'react'
import ReactDOM from 'react-dom'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Provider from './ReactReduxProvider'

import * as serviceWorker from './serviceWorker'
import store from './setup/store'
import setup from './setup'
// NOTE: KEEP THIS ORDER OF IMPORT
// OTHERWISE NORMALIZER WOULD NOT WORK CORRECTLY
import './index.css' // create-react-app's normalizer.css
import './antd.less'
import App from './AppRoot'

dayjs.extend(localizedFormat);
(async () => {
  await setup()

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
