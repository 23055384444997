import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import ClassificationModel from './ClassificationModal'

const getOption = ({
  trueClasses,
  predictedClasses,
  items,
}) => {
  const xAxisData = trueClasses
  const classToX = {}
  xAxisData.forEach((c, index) => {
    classToX[c] = index
  })

  const yAxisData = predictedClasses
  const classToY = {}
  yAxisData.forEach((c, index) => {
    classToY[c] = index
  })

  const preparedData = {}

  items.forEach(({ trueClass, predictedClass }) => {
    const predicted = predictedClass || 'null'
    const [x, y] = [classToX[trueClass], classToY[predicted]]

    if (!preparedData[`${x}-${y}`]) {
      preparedData[`${x}-${y}`] = 1
    } else {
      preparedData[`${x}-${y}`]++
    }
  })

  const data = []

  let max = 10

  Object.keys(preparedData).forEach((key) => {
    const [x, y] = key.split('-')
    const value = preparedData[key]

    if (value) {
      max = Math.max(max, value)
    }

    data.push([parseInt(x), parseInt(y), value])
  })

  return {
    tooltip: {
      position: 'top',
    },
    grid: {
      height: '50%',
      top: '10%',
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '15%',
    },
    series: [{
      type: 'heatmap',
      data,
      label: {
        show: true,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    }],
  }
}

const ConfusionMatrix = ({ classes, items, withNotRecognized }) => {
  const [selected, setSelected] = useState({
    visible: false,
    trueClass: '',
    predictedClass: '',
    items: [],
  })

  const trueClasses = [...classes]
  const predictedClasses = withNotRecognized ? [...classes, 'null'] : [...classes]
  predictedClasses.reverse()

  const handleClick = ({ data: [trueClassIndex, predictedClassIndex] }) => {
    const trueClass = trueClasses[trueClassIndex] === 'null' ? null : trueClasses[trueClassIndex]
    const predictedClass = predictedClasses[predictedClassIndex] === 'null' ? null : predictedClasses[predictedClassIndex]

    setSelected({
      visible: true,
      trueClass,
      predictedClass,
      items: items.filter((item) => {
        if (item.predictedClass) return item.trueClass === trueClass && item.predictedClass === predictedClass
        if (!item.trueClass) return predictedClass === null && trueClass === null
        return item.trueClass === trueClass && !predictedClass
      }),
    })
  }

  const handleModalCancel = () => {
    setSelected({
      visible: false,
      trueClass: '',
      predictedClass: '',
      items: [],
    })
  }

  return (
    <>
      <ReactECharts
        option={getOption({
          trueClasses,
          predictedClasses,
          items,
        })}
        onEvents={{
          click: handleClick,
        }}
      />
      <ClassificationModel
        visible={selected.visible}
        onCancel={handleModalCancel}
        trueClass={selected.trueClass}
        predictedClass={selected.predictedClass}
        items={selected.items}
      />
    </>
  )
}

ConfusionMatrix.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      generalized: PropTypes.string.isRequired,
      trueClass: PropTypes.string.isRequired,
      predictedClass: PropTypes.string,
      confidence: PropTypes.number,
    }),
  ).isRequired,
  withNotRecognized: PropTypes.bool,
}

ConfusionMatrix.defaultProps = {
  withNotRecognized: false,
}

export default ConfusionMatrix
