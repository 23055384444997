const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const getOption = (allTags, data) => {
  const years = []
  const countByYear = {}
  const options = {}

  data.forEach(({
    year,
    month,
    tags,
  }) => {
    if (!options[year]) {
      years.push(year)
      countByYear[year] = 0
      options[year] = {
        title: {
          text: 'Top Tags',
        },
        series: {},
      }
      allTags.forEach((tag) => {
        options[year].series[tag] = {
          name: tag,
          type: 'line',
          data: months.map((name) => {
            return {
              name,
              value: 0,
            }
          }),
        }
      })
    }

    Object.keys(tags).forEach((tag) => {
      const value = tags[tag]

      for (let i = 0; i < options[year].series[tag].data.length; i++) {
        const dataItem = options[year].series[tag].data[i]

        if (dataItem.name === month) {
          dataItem.value = value
          break
        }
      }
    })
  })

  years.forEach((year) => {
    options[year].series = Object.values(options[year].series)
  })

  return {
    baseOption: {
      timeline: {
        axisType: 'category',
        data: years,
        currentIndex: years.length - 1,
        label: {},
      },
      tooltip: {},
      legend: {
        data: allTags,
      },
      calculable: true,
      grid: {
        top: 80,
        bottom: 100,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true,
            },
          },
        },
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            interval: 0,
          },
          data: months,
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Count',
        },
      ],
      series: [
        {
          name: 'Conv. Count',
          type: 'bar',
        },
      ],
    },
    options: Object.values(options),
  }
}

export default getOption
