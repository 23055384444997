import React from 'react'
import PropTypes from 'prop-types'
import { Form, Radio } from 'antd'

import style from './style.module.scss'

const RadioGroupOptions = ({ label, name, disabled, validationMessage, options }) => {
  if (!options || !options.length) return null

  return (
    <Form.Item
      className={style.RadioGroupOptions}
      label={label}
      name={name}
      rules={[{ required: true, message: validationMessage }]}
      labelCol={{ xxl: 6, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
      wrapperCol={{ xxl: 18, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
      initialValue='NORMAL'
    >
      <Radio.Group size='small' disabled={disabled} options={options} />
    </Form.Item>
  )
}

RadioGroupOptions.defaultProps = {
  validationMessage: 'Required',
}

RadioGroupOptions.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  validationMessage: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
}

export default RadioGroupOptions
