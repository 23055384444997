import React from 'react'
import {
  Row,
  Col,
  Typography,
  Form,
  Radio,
  Button,
  Input,
  Spin,
} from 'antd'
import JsonPreview from '@core/components/JsonPreview'
import Api from '../../api'
import useInformationCurrentReducer from './useInformationCurrentReducer'

const InformationCurrent = () => {
  const [form] = Form.useForm()
  const [state, dispatch] = useInformationCurrentReducer()

  const onFinish = async ({ type, search }) => {
    dispatch({ type: 'request' })
    try {
      const response = await Api.getInformationCurrent({
        [type]: search,
      })
      dispatch({ type: 'success', payload: { data: response.data } })
    } catch (error) {
      dispatch({ type: 'failed' })
    }
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={6}>
        <Typography.Title level={4}>Check current information</Typography.Title>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout='horizontal'
          onFinish={onFinish}
          initialValues={{
            type: 'airline',
          }}
        >
          <Form.Item
            label='Type'
            name='type'
          >
            <Radio.Group>
              <Radio.Button value='airline'>AIRLINE</Radio.Button>
              <Radio.Button value='airport'>AIRPORT</Radio.Button>
              <Radio.Button value='country'>COUNTRY</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <div className='ant-row'>
            <div className='ant-col ant-col-6 ant-form-item-label' />
            <Typography.Text>* Use IATA code to search *</Typography.Text>
          </div>
          <Form.Item
            label='Search'
            name='search'
            rules={[{ required: true, message: 'Please input your search!' }]}
          >
            <Input placeholder='Search' />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button type='primary' htmlType='submit' disabled={state.loading}>
              Submit
            </Button>
          </Form.Item>
          {state.hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes.</Typography.Text>}
        </Form>
      </Col>
      <Col span={18}>
        {state.loading && <Spin />}
        {state.data && <JsonPreview content={JSON.stringify(state.data, null, 2)} autoSize />}
      </Col>
    </Row>
  )
}

export default InformationCurrent
