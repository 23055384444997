/* eslint-disable no-useless-escape */
/* eslint-disable no-bitwise */
import languageCodeHash from '../constants/languageCodeHash'

const DELIMITER = '##_SP_MSG_##'
const DEGRADED_DELIMITER = '##_DG_MSG_##'

// With degraded delimiter introduce, we have a flow like this
// split degraded responses -> split each degraded response to newline with delimiter
// we would probably need to check either its array or text too

// same thing for the payload, reverse the process with merge

export const mergeNewLineFieldWithDelimiter = (textArray) => { return textArray.join(DELIMITER) }
export const splitNewLineFieldWithDelimiter = (text) => { return text.split(DELIMITER) }
export const splitDegradedResponsesWithDelimiter = (text) => { return text.split(DEGRADED_DELIMITER) }

const getPlaceholderCountPerVariations = (fields) => {
  return Object.keys(fields).reduce((obj, fieldName) => {
    if (fieldName.indexOf('values.') !== -1) {
      const placeholderCount = mergeNewLineFieldWithDelimiter(fields[fieldName]).match(/{{\w+}}/g) || 0 // use 'g', find all matches
      obj[fieldName] = Array.isArray(placeholderCount) ? placeholderCount.length : placeholderCount
    }

    return obj
  }, {})
}

export const prepareDegradedMessagesVariationsString = (fields) => {
  return (
    Object.entries(getPlaceholderCountPerVariations(fields))
    .sort(([, a], [, b]) => { return b - a }) // NOTE: This is reversed because backend will look at variations that has most placeholder first
    .reduce((str, [field], index) => {
      return (
        index === 0
          ? mergeNewLineFieldWithDelimiter(fields[field])
          : str.concat(DEGRADED_DELIMITER, mergeNewLineFieldWithDelimiter(fields[field]))
      )
    }, '')
  )
}

export const splitDegradedResponseVariationsString = (degradedString) => {
  return (
    splitDegradedResponsesWithDelimiter(degradedString)
    .reduce((obj, variation, index) => {
      obj[`values.${index}`] = splitNewLineFieldWithDelimiter(variation)
      return obj
    }, {})
  )
}

// NOTE: this should only do the check match and assumed all fields has correct format
export const checkForFallbackDegradedMessageVariation = (fields) => {
  const degradationMessageVariations = getPlaceholderCountPerVariations(fields)
  const degradationMessageVariationsCurlyArrayCounts = Object.values(degradationMessageVariations)
  const hasMoreThanOneFallbackMessage = degradationMessageVariationsCurlyArrayCounts
  .filter((curlyCount) => { return curlyCount === 0 })
  .length > 1

  if (!degradationMessageVariationsCurlyArrayCounts.includes(0)) {
    const error = {
      error: true,
      degradationError: new Error('There is no fallback message (no placeholder), required at least one variation'),
      message: 'There is no fallback message (no placeholder), required at least one variation',
    }

    throw error
  }

  if (hasMoreThanOneFallbackMessage) {
    const error = {
      error: true,
      degradationError: new Error('There should be only one fallback message (if there are no dynamic placeholders in the text), please remove one of the variations for this response key.'),
      message: 'There should be only one fallback message (if there are no dynamic placeholders in the text), please remove one of the variations for this response key.',
    }

    throw error
  }
}

const REGEX_bracketMatches = /({)|(})/g
const REGEX_noSpacingMatches = /([a-zA-Z0-9]\{)|(\}[a-zA-Z0-9])/g
const REGEX_bracketDoublesMatches = /({{)|(}})/g
const REGEX_matchingExpressions = /{{[a-zA-Z0-9\-\_]+}}/g

export const validateCurlyBracketsPlaceholder = (_, string) => {
  if (!string) {
    return Promise.reject()
  }
  const errorPlaceholderMismatchMessage = `
    Mismatch/Incorrect placeholder found,
    Check if curly brackets is open/closed with two curly brackets each side.
    Space is not allowed inside placeholder.
    Correct usage: {{PLACEHOLDER}}
  `

  const bracketMatches = string.match(REGEX_bracketMatches) || []

  if (bracketMatches.length % 4 > 0) {
    return Promise.reject(new Error(errorPlaceholderMismatchMessage))
  } // if there aren't pairs

  const order = ['{', '{', '}', '}']

  // the divider for mod is the 'length' of the order of the curly brackets placeholder pattern (exclude text inside) with open/close
  //
  // if we want to loop check single curly brackets to be correct pair, each placeholder will have four curly brackets
  // if we split per characters will be like this ['{', '{', '}', '}']
  // it will goes on by 4 length for each placeholder
  // modding will give the index pattern of [0,1,2,3] corresponding the order array
  // bracket matches index must be equal to the index that mod by 4
  //
  // because mod 2 would give the odd/even (0,1,...,n) on number sequence based on 'index % 2',
  // in the case of mod 2 (use the: ['{{', '}}'] would give the odd/even (0,1,...,n) on number sequence based on 'index % 2',
  // thus giving correct order index on the extracted .match pattern
  for (let i = 0; i < bracketMatches.length; i++) {
    if (order[i % 4] !== bracketMatches[i]) {
      return Promise.reject(new Error(errorPlaceholderMismatchMessage))
    }
  }

  // At this point in time, we know that the brackets are in the right order and the number of brackets are correct

  const noSpacingMatches = string.match(REGEX_noSpacingMatches) || []
  if (noSpacingMatches.length > 0) {
    return Promise.reject(new Error(errorPlaceholderMismatchMessage))
  }

  const bracketDoublesMatches = string.match(REGEX_bracketDoublesMatches) || []
  if (bracketMatches.length !== bracketDoublesMatches.length << 1) {
    return Promise.reject(new Error(errorPlaceholderMismatchMessage))
  }

  const matchingExpressions = string.match(REGEX_matchingExpressions) || []
  if (bracketDoublesMatches.length !== matchingExpressions.length << 1) {
    return Promise.reject(new Error(errorPlaceholderMismatchMessage))
  }

  return Promise.resolve()
}

const REGEX_squareBracketMatches = /(\[)|(\])/g
const REGEX_noSpacingSquareMatches = /([a-zA-Z0-9]\[)|(\][a-zA-Z0-9])/g
const REGEX_squareBracketDoublesMatches = /(\[\[)|(\]\])/g
const REGEX_matchingSquareBracketsExpressions = /\[\[[a-zA-Z0-9\-\_\.]+\]\]/g

export const validateSquareBracketsPlaceholder = (_, string) => {
  if (!string) {
    return Promise.reject()
  }
  const errorPlaceholderMismatchMessage = `
    Mismatch/Incorrect Square placeholder found,
    Check if square brackets is open/closed with two square brackets each side.
    Space is not allowed in the Square placeholder.
    Correct usage: [[key.to.message]]
  `

  const bracketMatches = string.match(REGEX_squareBracketMatches) || []

  if (bracketMatches.length % 4 > 0) {
    return Promise.reject(new Error(errorPlaceholderMismatchMessage))
  } // if there aren't pairs

  const order = ['[', '[', ']', ']']
  for (let i = 0; i < bracketMatches.length; i++) {
    if (order[i % 4] !== bracketMatches[i]) {
      return Promise.reject(new Error(errorPlaceholderMismatchMessage))
    }
  }

  // At this point in time, we know that the brackets are in the right order and the number of brackets are correct

  const noSpacingMatches = string.match(REGEX_noSpacingSquareMatches) || []
  if (noSpacingMatches.length > 0) {
    return Promise.reject(new Error(errorPlaceholderMismatchMessage))
  }

  const bracketDoublesMatches = string.match(REGEX_squareBracketDoublesMatches) || []
  if (bracketMatches.length !== bracketDoublesMatches.length << 1) {
    return Promise.reject(new Error(errorPlaceholderMismatchMessage))
  }

  const matchingExpressions = string.match(REGEX_matchingSquareBracketsExpressions) || []
  if (bracketDoublesMatches.length !== matchingExpressions.length << 1) {
    return Promise.reject(new Error(errorPlaceholderMismatchMessage))
  }

  return Promise.resolve()
}

export const uniqueDynamicEntities = (dynamicEntities) => {
  const occurances = {}

  return dynamicEntities.filter((dynamicEntityObject) => {
    if (occurances[dynamicEntityObject.name]) return false

    occurances[dynamicEntityObject.name] = true
    return true
  })
}

export const getLanguageCode = (langCode) => { return languageCodeHash[langCode.toLowerCase()] }

export const getResponseTextFirstInputErrorMessage = (isFallbackTab) => {
  return (
    isFallbackTab
      ? 'Enter fallback message/fallback error message without any curly brackets placeholder'
      : 'Enter a response message'
  )
}

export const transformExchangeToRenderUI = (rawExchanges = []) => {
  return rawExchanges.map((exchange, index) => {
    return {
      id: exchange.id,
      rowId: `ex${index + 1}`,
      texts: [
        { speech: exchange.user_text, type: 'user' },
        ...exchange.bot_responses.map((botResponse) => {
          const [variation, stage] = botResponse.i18n_key.split('.')
          return {
            variation,
            stage,
            type: 'bot',
            replaced: [botResponse.text],
          }
        }),
      ],
      exchangeData: {
        dynamicEntities: uniqueDynamicEntities(exchange.dynamic_entities),
        state: exchange.state,
        profile: exchange.profile,
      },
    }
  })
}
