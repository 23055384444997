import React, { useContext } from 'react'
import { Row, Spin, Result, Button } from 'antd'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../../api'
import { EmailAutomationsContext } from '../../../../context'
import IntentEvaluation from './IntentEvaluation'

const IntentEvaluationExpandedDetails = ({ id, handleClickChart }) => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [{ loading, data, hasError }, { makeRequest }] = useRequest(Api.Emails.getModelEvaluation, { id, version: currentVersion, datasource })

  const retry = async () => {
    await makeRequest()
  }

  if (hasError) {
    return (
      <Result
        status='warning'
        title='We&#39;ve encountered some problems, please try again in few minutes'
        extra={(
          <Button type='primary' key='retry' onClick={retry}>
            Retry
          </Button>
        )}
      />
    )
  }

  if (loading || !data) return <Spin />

  return (
    <Row>
      {data.map(({ intent, evaluation }) => {
        if (!evaluation) {
          return (
            <Result
              status='warning'
              title='We&#39;ve encountered some problems.'
            />
          )
        }
        return (<IntentEvaluation key={intent} intent={intent} evaluation={evaluation} handleClickChart={handleClickChart} />)
      })}
    </Row>
  )
}

IntentEvaluationExpandedDetails.defaultProps = {
  handleClickChart: () => { },
}

IntentEvaluationExpandedDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleClickChart: PropTypes.func,
}

export default IntentEvaluationExpandedDetails
