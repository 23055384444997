import React, { useEffect, useState, useContext } from 'react'
import { Layout } from 'antd'
import { useResponseTextModal } from '@core/hooks/useResponseTextModal'
import { useResponseText } from '@core/hooks/useResponseText'

import QueryFilters from './QueryFilters'
import QueryResult from './QueryResult'

import AddResponseTextModal from './AddResponseTextModal'
import EditResponseTextModal from './EditResponseTextModal'

import { I18nVersionContext } from '../../helpers/context'

const ResponseText = () => {
  const { i18nVersionId } = useContext(I18nVersionContext)
  const [currentSearch, setCurrentSearch] = useState({})
  const [{
    loading,
    data: items,
    selectedResponseText,
    meta,
    hasError,
    newResponseText,
    editResponseText,
    deleteResponseText,
  }, ResponseTextActions] = useResponseText('lynx', i18nVersionId)

  const [{ visibility, selectedOperation }, ModalActions] = useResponseTextModal()

  const filteredSearch = async (values) => {
    await ResponseTextActions.fetch(values)
  }

  const onCreateNewResponseText = async (values) => {
    const response = await ResponseTextActions.add(values)
    return response // pass for notify flag
  }

  const onEditResponseText = async (values) => {
    const response = await ResponseTextActions.edit(values)
    return response // pass for notify flag
  }

  const onDeleteResponseText = async (values) => {
    const response = await ResponseTextActions.delete(values)
    return response // pass for notify flag
  }

  const onSelectSentence = (sentence) => {
    ResponseTextActions.selectSentence(sentence)
  }

  const onCloseEditSentenceModal = () => {
    ModalActions.close()
    ResponseTextActions.deselectSentence()
  }

  const openModal = (type) => {
    return () => {
      ModalActions.open(type)
    }
  }

  const fetchOnTableChange = (pagination, filters, sorter) => {
    const sortParams = {
      ascend: 'asc',
      descend: 'desc',
    }
    const currentParams = {
      ...currentSearch,
      page: pagination.current,
      per_page: pagination.pageSize || pagination.defaultPageSize,
    }

    if (sorter.order) currentParams.sort = `${sorter.field}.${sortParams[sorter.order]}`

    ResponseTextActions.syncTableUiState(pagination, filters, sorter)
    filteredSearch(currentParams)
  }

  useEffect(() => {
    const fetch = async () => {
      await ResponseTextActions.fetch({})
    }

    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18nVersionId]) // Force no re-runs

  return (
    <Layout>
      <QueryFilters loading={loading} search={filteredSearch} setCurrentSearch={setCurrentSearch} />
      <QueryResult
        loading={loading}
        data={{
          items,
          meta,
        }}
        hasError={hasError}
        fetchOnTableChange={fetchOnTableChange}
        openAddSentenceModal={openModal('add')}
        openEditSentenceModal={openModal('edit')}
        onSelectSentence={onSelectSentence}
        onDeleteResponseText={onDeleteResponseText}
        deleteResponseText={deleteResponseText}
      />
      {selectedOperation === 'add' && (
        <AddResponseTextModal
          loading={newResponseText.loading}
          hasError={newResponseText.hasError}
          open={visibility}
          onClose={ModalActions.close}
          onCreateNewResponseText={onCreateNewResponseText}
        />
      )}
      {selectedOperation === 'edit' && (
        <EditResponseTextModal
          loading={editResponseText.loading}
          hasError={editResponseText.hasError}
          open={visibility}
          onClose={onCloseEditSentenceModal}
          onEditResponseText={onEditResponseText}
          selectedResponseText={selectedResponseText}
        />
      )}
    </Layout>
  )
}

export default ResponseText
