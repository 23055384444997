import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  useRouteMatch,
} from 'react-router-dom'
import {
  List,
  Tooltip,
  Button,
  Avatar,
  Typography,
  Popconfirm,
} from 'antd'
import { ReadOutlined, StopOutlined, BugOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import {
  conversationType,
  onClickType,
  onToggleExcludeFromTrainingType,
  querySourceType,
} from '../../../../Conversations/types'
import ConversationTags from './ConversationTags'
import Statistics from './Statistics'

import style from './index.module.scss'

const dayjsWithUtc = dayjs.extend(utc)

const ConversationListItem = ({
  selectedConversationId,
  conversation,
  onClick,
  onToggleExcludeFromTraining,
  querySource,
  showStats,
  showActions,
}) => {
  const isToggleTrainingAllowed = usePermittedFeaturesLookup('conversations.query.actionExcludeFromTraining')
  const isDebugAllowed = usePermittedFeaturesLookup('conversations.query.actionDebug')
  const { url } = useRouteMatch() // NOTE: the correct url should be /conversations/query
  const handleClick = (event) => {
    event.stopPropagation()
    onClick(conversation)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick(conversation)
    }
  }

  const toggleExclusionTraining = (event) => {
    event.stopPropagation()
    onToggleExcludeFromTraining(conversation)
  }

  const excludeFromTrainingTitleLabel = conversation.exclude_from_training ? 'Excluded from training' : 'Included for training'

  return (
    <List.Item
      className={`${style.list_item}${conversation.id === selectedConversationId ? ` ${style.selected}` : ''}`}
      onClick={onClick}
      actions={!showActions ? [] : [
        isToggleTrainingAllowed && (
          <Popconfirm
            title={conversation.exclude_from_training ? 'Include this sentence for training?' : 'Exclude this sentence from training?'}
            onConfirm={toggleExclusionTraining}
            okText='Yes'
            cancelText='No'
          >
            <Tooltip title={excludeFromTrainingTitleLabel} placement='bottom'>
              <Button
                title={excludeFromTrainingTitleLabel}
                shape='circle'
                style={{
                  color: conversation.exclude_from_training ? '#faad14' : 'lightgray',
                  borderColor: conversation.exclude_from_training ? '#faad14' : undefined,
                }}
                icon={<StopOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        ),
        <Tooltip title='View the conversation'>
          <Button
            title='View the conversation'
            shape='circle'
            icon={<ReadOutlined />}
            onClick={handleClick}
          />
        </Tooltip>,
        isDebugAllowed && (
          <Tooltip title='Debug conversation'>
            <Link
              to={{
                pathname: querySource ? `${url}/${conversation.id}?datasource=${querySource}` : `${url}/${conversation.id}`,
                state: {},
              }}
              tabIndex={-1}
            >
              <Button
                title='Debug Conversation'
                shape='circle'
                style={{ color: 'lightgray' }}
                icon={<BugOutlined />}
              />
            </Link>
          </Tooltip>
        ),
      ]}
    >
      <List.Item.Meta
        avatar={(
          <Avatar className={`${style.avatar} ${style[conversation.message_type.toLowerCase()]}`}>
            {conversation.message_type.substr(0, 1)}
          </Avatar>
        )}
      />
      <div className={style.message__container}>
        <div className={style.message__content}>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={style.message__title}
              role='button'
              tabIndex={0}
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              title={conversation.message_original}
            >
              {conversation.message_original}

            </a>
            <Tooltip title={conversation.message_created_at} placement='right'>
              <Typography.Text type='secondary'>
                {`${dayjsWithUtc(conversation.message_created_at).utc().format('MMM D, YYYY HH:mm:ssZ')}`}
              </Typography.Text>
            </Tooltip>
          </div>
          <ConversationTags tags={conversation.tags} />
          {showStats && (
            <div>
              <Statistics stats={conversation.stats} />
            </div>
          )}
        </div>
      </div>
    </List.Item>
  )
}

ConversationListItem.propTypes = {
  conversation: conversationType.isRequired,
  onClick: onClickType.isRequired,
  onToggleExcludeFromTraining: onToggleExcludeFromTrainingType.isRequired,
  querySource: querySourceType,
  showStats: PropTypes.bool,
  showActions: PropTypes.bool,
  selectedConversationId: PropTypes.string,
}

ConversationListItem.defaultProps = {
  querySource: undefined,
  showStats: true,
  showActions: true,
  selectedConversationId: '',
}

export default ConversationListItem
