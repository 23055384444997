import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Row,
  Col,
  Select,
  Alert,
  Spin,
} from 'antd'
import { EmailAutomationsContext } from '../../../../../context'

import style from './style.module.scss'

const AnalysisStatus = ({
  emailStatus,
  updateStatus,
  statusIsLoading,
  showStatusUpdateAlert,
}) => {
  const { emailSearchResults: [emailSearchResultsState] } = useContext(EmailAutomationsContext)
  const { statusList } = emailSearchResultsState

  return (
    <Spin spinning={statusIsLoading}>
      <Typography.Title level={5}>Email Analysis status</Typography.Title>
      <Row className={style.EmailAnalysisStatus} gutter={[0, 16]}>
        <Col span={24}>
          <Typography.Text>Status</Typography.Text>
          {showStatusUpdateAlert
            ? (
              <Alert
                message={showStatusUpdateAlert.message}
                description={showStatusUpdateAlert.description}
                type='error'
                closable
              />
            )
            : null}
          <Select
            placeholder='Select a Status'
            loading={statusIsLoading}
            disabled={statusIsLoading}
            value={emailStatus || undefined}
            onSelect={updateStatus}
          >
            <Select.Option value='NEW' disabled>NEW</Select.Option>
            {statusList.map((status) => { return (<Select.Option key={status} value={status}>{status}</Select.Option>) })}
          </Select>
        </Col>
      </Row>
    </Spin>
  )
}

AnalysisStatus.defaultProps = {
  emailStatus: null,
  showStatusUpdateAlert: null,
}

AnalysisStatus.propTypes = {
  emailStatus: PropTypes.string,
  updateStatus: PropTypes.func.isRequired,
  statusIsLoading: PropTypes.bool.isRequired,
  showStatusUpdateAlert: PropTypes.shape({
    message: PropTypes.string,
    description: PropTypes.string,
  }),
}

export default AnalysisStatus
