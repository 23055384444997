import PropTypes from 'prop-types'

const {
  func,
  string,
  bool,
  node,
} = PropTypes

export const onOkType = func
export const onCancelType = func
export const titleType = string
export const loadingType = bool
export const visibleType = bool
export const childrenType = node
export const closableType = bool
export const hasErrorType = bool
