import React from 'react'
import PropTypes from 'prop-types'
import {
  Spin,
  Typography,
  Card,
  Result,
  Divider,
} from 'antd'
import useAnalysis from '@core/hooks/useAnalysis'
import JSONPreview from '@core/components/JsonPreview'

const CustomerMessageAnalyzed = ({ id, text }) => {
  const [{ loading, isError }, analysis] = useAnalysis(text)

  if (loading) return (<Spin size='small' />)

  if (isError) {
    return (
      <Card className='conversation_message_analyzed' id={`message_id--${id}`}>
        <Typography.Text type='danger'>Analyze Error</Typography.Text>
      </Card>
    )
  }

  return (
    <Card className='conversation_message_analyzed' id={`message_id--${id}`}>
      <Typography.Text>{text}</Typography.Text>
      <Divider />
      <JSONPreview content={JSON.stringify(analysis, null, 2)} autoSize />
    </Card>
  )
}

CustomerMessageAnalyzed.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const AnalyzedCustomerMessages = ({ messages }) => {
  if (!messages || !messages.length) {
    return (<Result status='error' title='There are some problems with your operation.' />)
  }

  return (
    <>
      <div>
        <Typography.Text strong>Analyzed customer message</Typography.Text>
      </div>
      <Divider />
      <div className='analyzed_customer_messages'>
        {
          messages.filter(({ messageType }) => { return (messageType === 'CUSTOMER') })
          .map((message, index) => { return (<CustomerMessageAnalyzed id={message.id} text={message.text} key={index} />) })
        }
      </div>
    </>
  )
}

AnalyzedCustomerMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AnalyzedCustomerMessages

