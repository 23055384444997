/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, AutoComplete } from 'antd'

const halfFullMonth = '(jan(uary)?|feb(ruary)?|mar(ch)?|apr(il)?|may|jun(e)?|jul(y)?|aug(ust)?|sep(tember)?|oct(ober)?|nov(ember)?|dec(ember)?)'
const halfFullDay = '(mon(day)?|tue(sday)?|wed(nesday)?|thu(rsday)?|fri(day)?|sat(urday)?|sun(day)?)'
const numberDate = '([0-9]{1,2})'
const numberYear = '([0-9]{4})'
const spaceBar = '(\\s)'
const slash = '(\\\/)'
const ordinalText = '((th|rd|nd|st)?)'

const renderTitle = (title, link) => {
  return (
    <span>
      {title}
      {link && (
        <a
          style={{
            float: 'right',
          }}
          href={link}
          target='_blank'
          rel='noopener noreferrer'
        >
          Reg-ex playground
        </a>
      )}
    </span>
  )
}
const renderItem = ({ label, comment, value }) => {
  return {
    value,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {label}
        <span>
          {comment}
        </span>
      </div>
    ),
  }
}
const options = [
  {
    label: renderTitle('Full date format', 'https://regexr.com/76mf4'),
    options: [
      renderItem({ label: '12 Jan or january 1998?', comment: 'DD MMM YYYY', value: `.*(${numberDate}\\s${halfFullMonth}\\s(${numberYear})?).*` }),
      renderItem({ label: '12/mar or march/1998?', comment: 'DD/MMM/YYYY', value: `.*(${numberDate}\\\/${halfFullMonth}\\\/(${numberYear})?).*` }),
      renderItem({ label: '12-mar or march-1998?', comment: 'DD-MMM-YYYY', value: `.*(${numberDate}-${halfFullMonth}-(${numberYear})?).*` }),
      renderItem({ label: '12nd? January 1998?', comment: 'DD[rd|st|nd|th]-MMM-YYYY', value: `.*(${numberDate}${ordinalText}${spaceBar}${halfFullMonth}${spaceBar}${numberYear}?).*` })],
  },
  {
    label: renderTitle('Number date format'),
    options: [
      renderItem({ label: '23/10/1998 or 2/8/2032', comment: 'DD/MM/YYYY', value: `.*(${numberDate}\\\/${numberDate}\\\/${numberYear}).*` }),
      renderItem({ label: '18.06.1998 or 02.18.2032', comment: 'DD.MM.YYYY', value: `.*(${numberDate}\\.${numberDate}\\.${numberYear}).*` }),
      renderItem({ label: '2 23 1998 or 22 8 2032', comment: 'DD MM YYYY', value: `.*(${numberDate}\\s${numberDate}\\s${numberYear}).*` }),
      renderItem({ label: '12-23-1998 or 08-1-2019', comment: 'DD-MM-YYYY', value: `.*(${numberDate}-${numberDate}-${numberYear}).*` }),
      renderItem({ label: '2023/1/21', comment: 'YYYY/MM/DD', value: `(${numberYear}${slash}${numberDate}${slash}${numberDate}).*` })],
  },
  {
    label: renderTitle('time format'),
    options: [
      renderItem({ label: '8:18 or 21:38', comment: 'HH:MM', value: `.*(${spaceBar}?${numberDate}:${numberDate}).*` }),
      renderItem({ label: '02:18 [PM|AM]?', comment: 'HH:MM XM', value: `.*(${spaceBar}?${numberDate}:${numberDate}${spaceBar}(PM|AM)).*` }),
    ],
  },
  {
    label: renderTitle('Partial Reg-ex'),
    options: [
      renderItem({ label: 'jan or january', comment: 'MMM', value: `${halfFullMonth}` }),
      renderItem({ label: 'Mon or monday', comment: 'DDD', value: `${halfFullDay}` }),
      renderItem({ label: 'date/month number', comment: 'MM', value: numberDate }),
      renderItem({ label: 'year number', comment: 'YYYY', value: numberYear }),
      renderItem({ label: 'space', comment: '[space|tab|newline]', value: spaceBar }),
      renderItem({ label: 'slash', comment: '/ (but for . - can put it in text directly)', value: slash }),
      renderItem({ label: '2nd or 3rd', comment: 'ordinal text without number', value: ordinalText }),
    ],
  },
]

const ByRegularExpression = ({ form }) => {
  const [value, setValue] = useState({})

  const onSelect = (key, selected) => {
    const newToSet = (value[key] || '') + selected
    setValue({ ...value, [key]: newToSet })
    form.setFieldsValue({ [key]: newToSet })
  }
  const onChangeInput = (key, event) => {
    const inputValue = event.target.value
    setValue({ ...value, [key]: inputValue })
    form.setFieldsValue({ [key]: inputValue })
  }
  return (
    <>
      <Form.Item name='regex' label='Including Regexes'>
        <AutoComplete
          dropdownMatchSelectWidth={400}
          options={options}
          onSelect={(selected) => { return onSelect('regex', selected) }}
        >

          <Input
            placeholder='Using (`) to enclose text and use (,) for multiple text'
            onChange={(event) => { return onChangeInput('regex', event) }}
            value={value.regex}
          />
        </AutoComplete>
      </Form.Item>
      <Form.Item name='excludeRegex' label='Excluding Regexes'>
        <AutoComplete
          dropdownMatchSelectWidth={400}
          options={options}
          onSelect={(selected) => { return onSelect('excludeRegex', selected) }}
        >
          <Input
            placeholder='Using (`) to enclose text and use (,) for multiple text'
            onChange={(event) => { return onChangeInput('excludeRegex', event) }}
            value={value.excludeRegex}
          />
        </AutoComplete>
      </Form.Item>
    </>
  )
}

ByRegularExpression.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
}

export default ByRegularExpression
