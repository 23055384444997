import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ConversationAnalysisPage from './pages/ConversationAnalysisPage'

const ConversationAnalysisModule = () => {
  return (
    <Switch>
      <Route path='/conversation-analysis' component={ConversationAnalysisPage} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/conversation-analysis',
    component: ConversationAnalysisModule,
  },
  name: 'Conversation Analysis',
}

export default ConversationAnalysisModule
