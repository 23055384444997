import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Typography,
  Spin,
  Progress,
  Statistic,
  Alert,
  Space,
  Divider,
} from 'antd'
import {
  useRequestReducer,
  ACTION_REQUEST,
  ACTION_SUCCESS,
  ACTION_FAILED,
} from '@core/hooks/useRequest'
import Api from '@core/api'

const { Text } = Typography

const JobsTableExpandedRow = ({ id }) => {
  const [
    {
      loading,
      data: job,
      hasError,
    },
    dispatch,
  ] = useRequestReducer()

  useEffect(() => {
    let isMounted = true
    const maxRetry = 3
    let retryCount = 0

    const getData = async (noRequestDispatch = false) => {
      if (!noRequestDispatch) {
        dispatch({ type: ACTION_REQUEST })
      }

      try {
        const { data } = await Api.getJobStatus({ id })
        if (isMounted) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: {
              data,
            },
          })
          if (data.status === 'RUNNING') {
            retryCount = 0
            setTimeout(() => {
              getData(true)
            }, 5000)
          }
        }
      } catch (error) {
        if (isMounted) {
          if (retryCount >= maxRetry) {
            dispatch({ type: ACTION_FAILED })
          } else {
            retryCount++
            setTimeout(() => {
              getData(true)
            }, 1000)
          }
        }
      }
    }
    getData()

    return () => {
      isMounted = false
    }
  }, [dispatch, id])

  const renderError = () => { return <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text> }

  const renderContent = () => {
    if (job.status === 'RUNNING') {
      if (!job.result) {
        return <Spin />
      }
      return (
        <Col span={24}>
          <Text>{job.result.step}</Text>
          {job.result.progress < 100 && <Spin style={{ paddingLeft: 10 }} />}
          {job.result.progress && <Progress strokeLinecap='square' percent={Math.round(job.result.progress * 100) / 100} />}
        </Col>
      )
    }

    if (!job.result?.generalizedAt) {
      return renderError()
    }

    return (
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <Alert
            showIcon
            message={(<Text strong>Time results (ms)</Text>)}
            description={(
              <Space size='large' split={<Divider type='vertical' />}>
                <Statistic title='Evaluate Time' value={job.result.evaluateTime} />
              </Space>
            )}
            type='info'
          />
        </Col>
        <Col span={12}>
          <Alert
            showIcon
            message={(<Text strong>Generalization results</Text>)}
            description={(
              <Space size='large' split={<Divider type='vertical' />}>
                <Statistic title='Total' value={job.result.sentenceCount} />
                <Statistic title='Success' value={job.result.successCount} />
                <Statistic title='Error' value={job.result.errorCount} />
              </Space>
            )}
            type='success'
          />
        </Col>
        {job.result.errorSentences.length > 0 && (
          <Col span={24}>
            <h3>Error Sentences</h3>
            <ol style={{ maxHeight: 400, overflowY: 'scroll' }}>
              {job.result.errorSentences.map((sentence) => { return <li>{sentence}</li> })}
            </ol>
          </Col>
        )}
      </Row>
    )
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        {hasError && renderError()}
        {loading && <Spin />}
        {job && renderContent()}
      </Col>
    </Row>
  )
}

JobsTableExpandedRow.propTypes = {
  id: PropTypes.string.isRequired,
}

export default JobsTableExpandedRow
