import React from 'react'
import { Form, Checkbox } from 'antd'

const DataExtractionCasesFilter = () => {
  return (
    <Form.Item
      valuePropName='checked'
      name='has_extractor_tests_sets'
    >
      <Checkbox>Data extraction test set</Checkbox>
    </Form.Item>
  )
}

export default DataExtractionCasesFilter
