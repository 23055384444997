import { AUTH } from '../constants'

// Still in use to handle jwt in case Auth.currentSession() in src/setup/api.js doesn't work
const INITIAL_STATE = {
  isLoggingIn: false,
  isLoggingOut: false,
  refresh_token: null,
  access_token: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH.LOGIN.REQUEST: {
      return {
        ...state,
        isLoggingIn: true,
      }
    }
    case AUTH.LOGOUT.REQUEST: {
      return {
        ...state,
        isLoggingOut: true,
      }
    }
    case AUTH.LOGOUT.FAILED: {
      return {
        ...state,
        isLoggingOut: false,
      }
    }
    case AUTH.LOGIN.SUCCESS: {
      return {
        ...INITIAL_STATE,
        ...action.payload,
      }
    }
    case AUTH.SYNCHRONIZE_TOKEN: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case AUTH.LOGOUT.SUCCESS: {
      return {
        ...INITIAL_STATE,
      }
    }
    default: return state
  }
}
