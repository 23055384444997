/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import {
  Row,
  Col,
  Divider,
  notification,
} from 'antd'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import SelectedSentenceActionCreators from '../../store/actions/selectedSentence'

import style from './index.module.scss'

import TestCaseList from '../TestCaseList'
import TrainingCaseList from '../TrainingCaseList'
import { RenderAddNewTestCaseForm, RenderAddNewTrainingCaseForm } from '../AddNewCaseForm'
import SentenceDetails from './SentenceDetails'
import AnalyzedSentence from './Analyzed'

const SpecificSentencePage = () => {
  const { sentence_id: sentenceId } = useParams()

  const {
    testCases: testCasesDataSource,
    trainingCases: trainingCasesDataSource,
    data,
    isPending,
    generalized,
    hasError,
  } = useSelector((state) => {
    const { testCases, trainingCases } = state.data.selectedSentence
    return {
      ...state.data.selectedSentence,
      testCases: {
        domain: testCases.domain_test_sets,
        intent: testCases.intent_test_sets,
        tagger: testCases.tagger_test_sets,
        extractor: testCases.extractor_test_sets,
        generalizer: testCases.generalizer_test_sets,
      },
      trainingCases: {
        domain: trainingCases.domain_training_sets,
        intent: trainingCases.intent_training_sets,
        tagger: trainingCases.tagger_training_sets,
      },
    }
  })

  useEffect(() => {
    const fetchData = async () => {
      if (sentenceId) {
        await SelectedSentenceActionCreators.fetchById(sentenceId)
        await SelectedSentenceActionCreators.fetchTestAndTrainingSets(sentenceId)
      }
    }

    fetchData()
  }, [sentenceId])

  const onUnrecognizedToggle = async (checked) => {
    try {
      await SelectedSentenceActionCreators.edit(sentenceId, { unrecognized: checked })
    } catch (error) {
      notification.error({
        message: 'Unable to uncheck unrecognized sentence',
        description: 'Encounter unexpected error, please try again',
        duration: 5,
      })
    }
  }

  if (isPending) {
    return (
      <div className={style.metadata}>
        {isPending && <LoadingSkeleton />}
      </div>
    )
  }

  return (
    <div className={style.metadata}>
      {data && generalized && (
        <SentenceDetails
          sentenceId={sentenceId}
          generalized={generalized}
          hasError={hasError}
          data={data}
        />
      )}
      <Divider />
      {data && (
        <AnalyzedSentence
          sentence={data?.text}
          unrecognized={data?.unrecognized}
          onUnrecognizedToggle={onUnrecognizedToggle}
          unrecognizedValueChanging={isPending}
        />
      )}
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={12}>
          {isPending && <LoadingSkeleton />}
          {!isPending && (
            <PermittedFeatureCheck featurePath='utterances.data.viewSentenceDetailsTestCaseSection'>
              <TestCaseList
                dataSource={testCasesDataSource}
                renderAddNewCaseForm={RenderAddNewTestCaseForm}
              />
            </PermittedFeatureCheck>
          )}
        </Col>
        <Col span={12}>
          {isPending && <LoadingSkeleton /> }
          {!isPending && (
            <PermittedFeatureCheck featurePath='utterances.data.viewSentenceDetailsTrainingCaseSection'>
              <TrainingCaseList
                dataSource={trainingCasesDataSource}
                renderAddNewCaseForm={RenderAddNewTrainingCaseForm}
              />
            </PermittedFeatureCheck>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default SpecificSentencePage
