import { useReducer } from 'react'
import StoreUtils from '@core/utils/store'
import { reHashIndex } from '@core/helpers'
import cloneDeep from 'lodash.clonedeep'
import { uniqueDynamicEntities } from '../helpers'
import Api from '../api'

const EndToEndApi = {
  findOneById: (id) => { return Api.EndToEnd.findOneTestCaseById(id) },
  findTestCasesByExchangeSimulationId: (id) => { return Api.EndToEnd.findTestCasesByExchangeSimulationId(id) },
  insert: (data) => { return Api.EndToEnd.insert(data) },
  updateTestCaseById: (id, data) => { return Api.EndToEnd.updateTestCaseById(id, data) },
  evaluate: (data) => { return Api.EndToEnd.evaluate(data) },
}

const INITIAL_STATE = {
  evaluationPending: false,
  loading: false,
  hasError: false,
  error: null,
  data: {
    testCases: [],
    testCasesHashTable: {},
    exchangesHashPairedWithTestCases: {},
    exchanges: [],
    exchangesHashTable: {},
    simulationInfo: {},
    rawExchanges: [],
  },
}

export const END_TO_END = {
  TEST_CASE: {
    FIND_ONE: StoreUtils.createActionStatus('END_TO_END/TEST_CASE/FIND_ONE'),
    FIND_BY_EXCHANGE_ID: StoreUtils.createActionStatus('END_TO_END/TEST_CASE/FIND_BY_EXCHANGE_ID'),
    ADD: StoreUtils.createActionStatus('END_TO_END/TEST_CASE/ADD'),
    UPDATE: StoreUtils.createActionStatus('END_TO_END/TEST_CASE/UPDATE'),
    EVALUATE: StoreUtils.createActionStatus('END_TO_END/TEST_CASE/EVALUATE'),
  },
  LOAD_CONVERSATION: 'END_TO_END/LOAD_CONVERSATION',
}

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case END_TO_END.LOAD_CONVERSATION: {
      return {
        ...state,
        data: action.payload,
      }
    }
    case END_TO_END.TEST_CASE.FIND_BY_EXCHANGE_ID.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
        data: {
          ...state.data,
        },
      }
    }
    case END_TO_END.TEST_CASE.FIND_BY_EXCHANGE_ID.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: {
          ...state.data,
          testCases: action.payload.testCases,
          testCasesHashTable: action.payload.testCasesHashTable,
          exchangesHashPairedWithTestCases: action.payload.exchangesHashPairedWithTestCases,
        },
      }
    }
    case END_TO_END.TEST_CASE.FIND_BY_EXCHANGE_ID.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
        data: {
          ...state.data,
          testCases: [],
          testCasesHashTable: {},
          exchangesHashPairedWithTestCases: {},
        },
      }
    }
    case END_TO_END.TEST_CASE.FIND_ONE.REQUEST:
    case END_TO_END.TEST_CASE.ADD.REQUEST:
    case END_TO_END.TEST_CASE.UPDATE.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
      }
    }
    case END_TO_END.TEST_CASE.EVALUATE.REQUEST: {
      return {
        ...state,
        evaluationPending: true,
        loading: true,
        hasError: false,
        error: null,
      }
    }
    case END_TO_END.TEST_CASE.FIND_ONE.FAILED:
    case END_TO_END.TEST_CASE.ADD.FAILED:
    case END_TO_END.TEST_CASE.UPDATE.FAILED:
    case END_TO_END.TEST_CASE.EVALUATE.FAILED: {
      return {
        ...state,
        evaluationPending: false,
        loading: false,
        hasError: true,
        error: action.error,
      }
    }
    case END_TO_END.TEST_CASE.UPDATE.SUCCESS: {
      return {
        ...state,
        evaluationPending: false,
        loading: false,
        hasError: false,
        error: null,
        data: {
          ...state.data,
          testCases: action.payload.testCases,
          exchangesHashPairedWithTestCases: action.payload.exchangesHashPairedWithTestCases,
        },
      }
    }
    case END_TO_END.TEST_CASE.ADD.SUCCESS: {
      const {
        testCases,
        testCasesHashTable,
        exchangesHashPairedWithTestCases,
      } = action.payload

      return {
        ...state,
        evaluationPending: false,
        loading: false,
        hasError: false,
        error: null,
        data: {
          ...state.data,
          testCases,
          testCasesHashTable,
          exchangesHashPairedWithTestCases,
        },
      }
    }
    case END_TO_END.TEST_CASE.EVALUATE.SUCCESS: {
      return {
        ...state,
        evaluationPending: false,
        loading: false,
        hasError: false,
        error: null,
      }
    }
    default: return state
  }
}

const useEndToEndTests = () => { return useReducer(reducer, INITIAL_STATE) }

export const loadToEndToEndReducer = (dispatch, saveData) => {
  const {
    created_at,
    created_by,
    exchanges,
    id,
    note,
    updated_at,
  } = saveData
  const transformedExchange = exchanges.map((exchange) => {
    return {
      id: exchange.id,
      texts: [
        { speech: exchange.user_text, type: 'user' },
        ...exchange.bot_responses.map((botResponse) => {
          const [variation, stage] = botResponse.i18n_key.split('.')
          return {
            variation,
            stage,
            type: 'bot',
            replaced: [botResponse.text],
          }
        }),
      ],
      exchangeData: {
        dynamicEntities: uniqueDynamicEntities(exchange.dynamic_entities),
        state: exchange.state,
        profile: exchange.profile,
      },
    }
  })

  const payload = {
    exchanges: transformedExchange,
    exchangesHashTable: reHashIndex(transformedExchange),
    testCases: [],
    simulationInfo: {
      id,
      note,
      created_at,
      created_by,
      updated_at,
    },
    rawExchanges: exchanges,
  }

  dispatch({ type: END_TO_END.LOAD_CONVERSATION, payload })

  return payload // make it return something
}

export const findTestCasesFromExchangeSimulationId = async (dispatch, exchangeIds) => {
  dispatch({ type: END_TO_END.TEST_CASE.FIND_BY_EXCHANGE_ID.REQUEST })

  try {
    const response = await EndToEndApi.findTestCasesByExchangeSimulationId(exchangeIds)

    if (response && response.status === 'success') {
      const exchangesHash = exchangeIds.reduce((obj, exchangeId) => {
        obj[exchangeId] = []
        return obj
      }, {})

      // wtf
      const exchangesHashPairedWithTestCases = response.data.reduce((obj, testCase) => {
        obj[testCase.exchange_simulation_id].push(testCase)
        return obj
      }, exchangesHash)

      dispatch({
        type: END_TO_END.TEST_CASE.FIND_BY_EXCHANGE_ID.SUCCESS,
        payload: {
          testCases: response.data,
          testCasesHashTable: reHashIndex(response.data),
          exchangesHashPairedWithTestCases,
        },
      })

      return response
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: END_TO_END.TEST_CASE.FIND_BY_EXCHANGE_ID.FAILED,
      error,
    })

    throw error
  }
}

export const findOneById = async (dispatch, id) => {
  dispatch({ type: END_TO_END.TEST_CASE.FIND_ONE.REQUEST })

  try {
    const response = await EndToEndApi.findOneById(id)

    if (response && response.status === 'success') {
      dispatch({
        type: END_TO_END.TEST_CASE.FIND_ONE.SUCCESS,
        payload: response.data,
      })

      return response.data
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: END_TO_END.TEST_CASE.FIND_ONE.FAILED,
      error,
    })
    throw error
  }
}

export const insert = async (dispatch, state, data) => {
  dispatch({ type: END_TO_END.TEST_CASE.ADD.REQUEST })

  try {
    const response = await EndToEndApi.insert(data)

    if (response && response.status === 'success') {
      const testCases = [].concat(state.data.testCases, [response.data])
      const testCasesHashTable = reHashIndex(testCases)
      const exchangesHashPairedWithTestCases = cloneDeep(state.data.exchangesHashPairedWithTestCases)
      exchangesHashPairedWithTestCases[response.data?.exchange_simulation_id] = [].concat(
        exchangesHashPairedWithTestCases[response.data?.exchange_simulation_id],
        [response.data],
      )
      dispatch({
        type: END_TO_END.TEST_CASE.ADD.SUCCESS,
        payload: {
          testCases,
          testCasesHashTable,
          exchangesHashPairedWithTestCases,
        },
      })

      return response
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: END_TO_END.TEST_CASE.ADD.FAILED,
      error,
    })
    throw error
  }
}

export const updateTestCaseById = async (dispatch, state, data) => {
  dispatch({ type: END_TO_END.TEST_CASE.UPDATE.REQUEST })

  try {
    const { id } = data
    const payload = { ...data }
    delete payload.id

    const response = await EndToEndApi.updateTestCaseById(id, payload)

    if (response && response.status === 'success') {
      const { testCasesHashTable } = state.data
      const testCases = [].concat(state.data.testCases)
      const exchangesHashPairedWithTestCases = cloneDeep(state.data.exchangesHashPairedWithTestCases)
      const exchangeWithUpdatedTestCase = exchangesHashPairedWithTestCases[response.data?.exchange_simulation_id]

      testCases[testCasesHashTable[response.data?.id]] = response.data
      for (let index = 0; index < exchangeWithUpdatedTestCase.length; index++) {
        if (exchangeWithUpdatedTestCase[index].id === response.data?.id) {
          exchangeWithUpdatedTestCase[index] = response.data
          break
        }
      }

      dispatch({
        type: END_TO_END.TEST_CASE.UPDATE.SUCCESS,
        payload: {
          testCases,
          exchangesHashPairedWithTestCases,
        },
      })

      return response.data
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: END_TO_END.TEST_CASE.UPDATE.FAILED,
      error,
    })
    throw error
  }
}

export const evaluate = async (dispatch, testCaseIds) => {
  dispatch({ type: END_TO_END.TEST_CASE.EVALUATE.REQUEST })

  try {
    const response = await EndToEndApi.evaluate({ testCaseIds })

    if (response && response.status === 'success') {
      dispatch({
        type: END_TO_END.TEST_CASE.EVALUATE.SUCCESS,
        payload: response.data,
      })

      return response
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: END_TO_END.TEST_CASE.EVALUATE.FAILED,
      error,
    })

    throw error
  }
}

export default useEndToEndTests
