import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Modal,
  Button,
  List,
  Row,
  Col,
  Typography,
  Collapse,
  Alert,
  Tag,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import JsonPreview from '@core/components/JsonPreview'

const { Title, Text } = Typography
const { Panel } = Collapse
const DataEvaluationDetailModal = ({ intent, items }) => {
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const classifications = useMemo(
    () => {
      return items.reduce((data, item) => {
        if (item.intent !== intent) return data
        if (item.skip) data.skipped.push(item)
        else if (item.error) data.errored.push(item)
        else if (item.testPassed) data.passed.push(item)
        else data.failed.push(item)
        return data
      },
      {
        passed: [],
        failed: [],
        skipped: [],
        errored: [],
      })
    },
    [intent, items],
  )

  return (
    <>
      <Button onClick={showModal} size='small'>
        Show Details
      </Button>
      <Modal
        title={intent}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1600}
      >
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <List
              header={<Title level={5}>Passed</Title>}
              bordered
              itemLayout='vertical'
              dataSource={classifications.passed}
              renderItem={(item) => {
                return (
                  <List.Item
                    extra={(
                      <Button
                        onClick={() => {
                          if (item.sentence_id) history.push(`/utterances/sentences/${item.sentence_id}`)
                        }}
                        icon={<EditOutlined />}
                      >
                        Edit
                      </Button>
                    )}
                  >
                    <List.Item.Meta
                      title={item.text}
                      description={item.note}
                      avatar={item.incomplete && <Tag color='processing'>Incomplete</Tag>}
                    />
                    { item.incomplete
                  && (
                    <Collapse>
                      <Panel
                        header='Results'
                      >
                        <Text>Expected</Text>
                        <JsonPreview
                          autosize
                          content={JSON.stringify({ [intent]: item.expected_data }, null, 2)}
                        />
                        <Text>Extracted</Text>
                        <JsonPreview
                          autosize
                          content={JSON.stringify({ [intent]: item.extracted_data }, null, 2)}
                        />
                      </Panel>
                    </Collapse>
                  )}
                  </List.Item>
                )
              }}
            />
          </Col>
          <Col span={12}>
            <List
              header={<Title level={5}>Failed</Title>}
              bordered
              itemLayout='vertical'
              dataSource={classifications.failed}
              renderItem={(item) => {
                return (
                  <List.Item
                    extra={(
                      <Button
                        onClick={() => {
                          if (item.sentence_id) history.push(`/utterances/sentences/${item.sentence_id}`)
                        }}
                        icon={<EditOutlined />}
                      >
                        Edit
                      </Button>
                    )}
                  >
                    <List.Item.Meta
                      title={item.text}
                      description={item.note}
                    />
                    <Collapse>
                      <Panel
                        header='Results'
                      >
                        <Text>Expected</Text>
                        <JsonPreview
                          autosize
                          content={JSON.stringify({ [intent]: item.expected_data }, null, 2)}
                        />
                        <Text>Extracted</Text>
                        <JsonPreview
                          autosize
                          content={JSON.stringify({ [intent]: item.extracted_data }, null, 2)}
                        />
                      </Panel>
                    </Collapse>
                  </List.Item>
                )
              }}
            />
          </Col>
          { classifications.skipped.length > 0 ? (
            <Col span={12}>
              <List
                header={<Title level={5}>Skipped</Title>}
                bordered
                itemLayout='vertical'
                dataSource={classifications.skipped}
                renderItem={(item) => {
                  return (
                    <List.Item
                      extra={(
                        <Button
                          onClick={() => {
                            if (item.sentence_id) history.push(`/utterances/sentences/${item.sentence_id}`)
                          }}
                          icon={<EditOutlined />}
                        >
                          Edit
                        </Button>
                      )}
                    >
                      <List.Item.Meta
                        title={item.text}
                        description={item.note}
                      />
                    </List.Item>
                  )
                }}
              />
            </Col>
          ) : null}
          { classifications.errored.length > 0 ? (
            <Col span={12}>
              <List
                header={<Title level={5}>Errors</Title>}
                bordered
                itemLayout='vertical'
                dataSource={classifications.errored}
                renderItem={(item) => {
                  return (
                    <List.Item
                      extra={(
                        <Button
                          onClick={() => {
                            if (item.sentence_id) history.push(`/utterances/sentences/${item.sentence_id}`)
                          }}
                          icon={<EditOutlined />}
                        >
                          Edit
                        </Button>
                      )}
                    >
                      <List.Item.Meta
                        title={item.text}
                        description={item.note}
                      />
                      { item.error.response && item.error.response.message ? <Alert showIcon type='error' message={item.error.response?.message} /> : null }
                    </List.Item>
                  )
                }}
              />
            </Col>
          ) : null}
        </Row>
      </Modal>
    </>
  )
}

DataEvaluationDetailModal.propTypes = {
  intent: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default DataEvaluationDetailModal
