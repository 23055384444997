import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Space } from 'antd'
import { e2eTestCaseShape, evaluationResultsType } from '../../../../../types'
import ResultsIcon, { DomainIntentIntermediateResult } from '../ResultsIcon'
import style from './index.module.scss'

const DescribeExpectation = ({ expectedResults, actual, field }) => {
  return (
    <Space className={style.DescribeExpectation} direction='vertical' size={2}>
      <Typography.Text type='success'>{`Expected: ${expectedResults[field]}`}</Typography.Text>
      <Typography.Text type='danger'>{`Actual: ${actual[field]}`}</Typography.Text>
    </Space>
  )
}

DescribeExpectation.defaultProps = {
  expectedResults: null,
  actual: null,
}

DescribeExpectation.propTypes = {
  expectedResults: PropTypes.shape({}),
  actual: PropTypes.shape({}),
  field: PropTypes.string.isRequired,
}

const DescribeTestCase = ({ testCase, results }) => {
  const { skip, description, expected } = testCase
  const { actual, expected: expectedResults, evaluation } = results
  const expectedResponseKeys = expected?.responseKeys
  const expectedTextTerms = expected?.textTerms
  const expectedDomain = expected?.domain
  const expectedIntent = expected?.intent

  return (
    <>
      <p>{description}</p>
      <Space direction='vertical'>
        {skip ? <Typography.Text type='warning' strong>Marked as skip</Typography.Text> : null}
        {Object.values(expected).length > 0
          ? (
            <>
              {expectedResponseKeys ? (
                <Space direction='vertical' size={4}>
                  <Space size={2}>
                    <ResultsIcon evaluationResults={evaluation} fieldToCheck='responseKeys' />
                    <Typography.Text strong>&thinsp;Matching Response Key</Typography.Text>
                  </Space>
                  {actual && !evaluation.responseKeys ? (
                    <DescribeExpectation expectedResults={expectedResults} actual={actual} field='responseKeys' />
                  ) : null}
                </Space>
              ) : null}
              {expectedTextTerms ? (
                <Space direction='vertical' size={4}>
                  <Space size={2}>
                    <ResultsIcon evaluationResults={evaluation} fieldToCheck='textTerms' />
                    <Typography.Text strong>&thinsp;Matching Text Terms</Typography.Text>
                  </Space>
                  {actual && !evaluation.textTerms ? (
                    <DescribeExpectation expectedResults={expectedResults} actual={actual} field='textTerms' />
                  ) : null}
                </Space>
              ) : null}
              {(expectedDomain || expectedIntent) ? (
                <Space direction='vertical' size={4}>
                  <Space size={2}>
                    <DomainIntentIntermediateResult evaluationResults={evaluation} />
                    <Typography.Text strong>&thinsp;Matching Domain / Intent</Typography.Text>
                  </Space>
                  {evaluation ? (
                    <Space className={style.DescribeExpectation} direction='vertical'>
                      <Space direction='vertical' size={4}>
                        <Space size={2}>
                          <ResultsIcon evaluationResults={evaluation} fieldToCheck='domain' />
                          <Typography.Text>Domain</Typography.Text>
                        </Space>
                        {actual && !evaluation.domain ? (
                          <DescribeExpectation expectedResults={expectedResults} actual={actual} field='domain' />
                        ) : null}
                      </Space>
                      <Space direction='vertical' size={4}>
                        <Space size={2}>
                          <ResultsIcon evaluationResults={evaluation} fieldToCheck='intent' />
                          <Typography.Text>Intent</Typography.Text>
                        </Space>
                        {actual && !evaluation.intent ? (
                          <DescribeExpectation expectedResults={expectedResults} actual={actual} field='intent' />
                        ) : null}
                      </Space>
                    </Space>
                  ) : null}
                </Space>
              ) : null}
            </>
          ) : <Typography.Text type='warning' strong>NO TEST CASE</Typography.Text>}
      </Space>
    </>
  )
}

DescribeTestCase.defaultProps = {
  testCase: null,
  results: {
    actual: {},
    expected: {},
  },
}

DescribeTestCase.propTypes = {
  testCase: e2eTestCaseShape,
  results: PropTypes.shape({
    actual: PropTypes.shape(),
    evaluation: evaluationResultsType,
    expected: e2eTestCaseShape.expected,
    isAllPassed: PropTypes.bool,
  }),
}

export default DescribeTestCase

