import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Layout,
  Typography,
  Input,
  Button,
  Row,
  Col,
  PageHeader,
  Form,
} from 'antd'
import VersionsActions from '../../../store/actions/Versions'

const { Content } = Layout
const { Title, Text } = Typography

const AddNewVersion = () => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)
  const history = useHistory()
  const onBack = () => { history.push('/versions/datasets') }
  const onSuccessAddNewVersion = onBack
  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        response = await VersionsActions.create(values)

        if (response) {
          form.resetFields()
          // navigate back to the Version page
          onSuccessAddNewVersion()
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Content className='AddNewVersion'>
      <PageHeader onBack={onBack} subTitle='Back to versions'>
        <Title>Add New Version</Title>
      </PageHeader>
      <Row>
        <Col span='24'>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
          >
            <Form.Item
              name='note'
              label='Version note'
              rules={[
                {
                  required: true,
                  message: 'Please add version note',
                },
              ]}
            >
              <Input placeholder='Enter version note' />
            </Form.Item>
            {/* <Form.Item
              name='description'
              label='Description'
            >
              <Input.TextArea
                showCount
                maxLength={255}
                style={{ resize: 'vertical' }}
                placeholder="Version's Description"
                rules={[
                  {
                    required: false,
                  },
                ]}
              />
            </Form.Item> */}
            <Form.Item>
              <Button type='primary' htmlType='submit'>Submit</Button>
              {hasError && (<Text type='danger'>&thinsp;&thinsp;&thinsp;We&#39;ve encountered some problems, please try again in few minutes</Text>)}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Content>
  )
}

export default AddNewVersion
