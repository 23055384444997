/**
 * Transform camel case to snake case
 * Example: `lastMessageSentAt` => `last_message_sent_at`
 * @param {string} camel
 */
export function stringCamelToSnake(camel) {
  return camel.replace(/([A-Z])/g, (match) => {
    return `_${match.toLowerCase()}`
  })
}
