import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { DRAWER } from '../constants'

export default wrapActionCreatorWithDispatch({
  toggle: () => {
    return async (dispatch, getState) => {
      dispatch({
        type: DRAWER.TOGGLE_VISIBILITY,
        payload: {
          visible: !getState().data.drawer.visible,
        },
      })
    }
  },
})
