import React from 'react'
import DomainTestForm from './Test/DomainForm'
import IntentTestForm from './Test/IntentForm'
import TaggerTestForm from './Test/TaggerTestForm'
import ExtractorTestForm from './Test/ExtractorTestForm'
import DomainTrainingForm from './Training/DomainForm'
import IntentTrainingForm from './Training/IntentForm'
import TaggerTrainingForm from './Training/TaggerTrainingForm'

export const RenderAddNewTestCaseForm = (selectedCase, form, sentence, generalized = '') => {
  return (
    <>
      {selectedCase === 'domain' && <DomainTestForm form={form} sentence={sentence} generalized={generalized} />}
      {selectedCase === 'intent' && <IntentTestForm form={form} sentence={sentence} generalized={generalized} />}
      {selectedCase === 'tagger' && <TaggerTestForm form={form} sentence={sentence} generalized={generalized} />}
      {selectedCase === 'extractor' && <ExtractorTestForm form={form} sentence={sentence} />}
    </>
  )
}

export const RenderAddNewTrainingCaseForm = (selectedCase, form, sentence, generalized = '') => {
  return (
    <>
      {selectedCase === 'domain' && <DomainTrainingForm form={form} sentence={sentence} generalized={generalized} />}
      {selectedCase === 'intent' && <IntentTrainingForm form={form} sentence={sentence} generalized={generalized} />}
      {selectedCase === 'tagger' && <TaggerTrainingForm form={form} sentence={sentence} generalized={generalized} />}
    </>
  )
}
