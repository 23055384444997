import React from 'react'
import { useSelector } from 'react-redux'
import { Select, Form } from 'antd'
import { boolType, rulesType } from '../../../types/formItems'

const TaggerSelect = ({ multiple, rules, onlyEntityTaggers }) => {
  const { entityTaggers, extractorTaggers } = useSelector((state) => { return state.experiments.taggers })
  return (
    <Form.Item name='tagger_type' label='Target tagger type' rules={rules}>
      <Select
        mode={multiple ? 'multiple' : ''}
        placeholder='Select an Target tagger type'
        style={{ width: '100%' }}
        allowClear
      >
        <Select.OptGroup label='Entity Taggers'>
          {entityTaggers.map((tag) => { return <Select.Option value={tag}>{tag}</Select.Option> })}
        </Select.OptGroup>
        {!onlyEntityTaggers && (
          <Select.OptGroup label='Extractor Taggers'>
            {extractorTaggers.map((tag) => { return <Select.Option value={tag}>{tag}</Select.Option> })}
          </Select.OptGroup>
        )}
      </Select>
    </Form.Item>
  )
}

TaggerSelect.propTypes = {
  multiple: boolType,
  onlyEntityTaggers: boolType,
  rules: rulesType,
}

TaggerSelect.defaultProps = {
  multiple: false,
  onlyEntityTaggers: false,
  rules: [],
}

export default TaggerSelect
