import React from 'react'
import { useSelector } from 'react-redux'
import { Select, Space, Typography } from 'antd'
import PropTypes from 'prop-types'

import ConversationAnalysisActions from '../../../store/actions/analytics'

const SelectStateKey = ({ defaultStateKeyCategories, stateKeyCategoriesOpts }) => {
  const { extraFilters } = useSelector((state) => { return state.conversationAnalysis.analytics })
  const { stateKeyCategories } = extraFilters

  return (
    <Space>
      <Typography.Text strong>Please select a State Key Category: </Typography.Text>
      <Select
        mode='multiple'
        style={{ minWidth: '600px' }}
        defaultValue={stateKeyCategories || defaultStateKeyCategories}
        onChange={(values) => {
          ConversationAnalysisActions.setExtraFilters({ stateKeyCategories: values })
        }}
      >
        <Select.OptGroup label='State Keys Categories'>
          {stateKeyCategoriesOpts.map((stateKey, index) => {
            return (<Select.Option value={stateKey} key={[stateKey, index].join('-')}>{stateKey}</Select.Option>)
          })}
        </Select.OptGroup>
        <Select.OptGroup label='Default State Keys'>
          {defaultStateKeyCategories.map((stateKey, index) => {
            return (<Select.Option value={stateKey} key={[stateKey, index].join('-')} disabled>{stateKey}</Select.Option>)
          })}
        </Select.OptGroup>
      </Select>
    </Space>
  )
}

SelectStateKey.propTypes = {
  defaultStateKeyCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  stateKeyCategoriesOpts: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default SelectStateKey
