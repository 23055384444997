import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { VERSIONS } from '@core/store/constants'
import CoreApi from '@core/api'
import StorageUtils from '@core/utils/storage'

export default wrapActionCreatorWithDispatch({
  set: (version) => {
    return async (dispatch, getState) => {
      let incomingVersion = version
      if (typeof incomingVersion === 'string') {
        incomingVersion = JSON.parse(incomingVersion)
      }

      const currentVersion = getState().core.versions.current?.id

      if (incomingVersion.id === currentVersion) {
        throw new Error(`Version "${incomingVersion.id}" is already selected`)
      }

      StorageUtils.setItemIntoSession('version', JSON.stringify(incomingVersion))

      return dispatch({
        type: VERSIONS.SET,
        payload: { version: incomingVersion },
      })
    }
  },
  get: () => {
    return async (dispatch) => {
      dispatch({ type: VERSIONS.GET.REQUEST })

      try {
        const response = await CoreApi.Versions.get()

        if (response && response.status === 'success') {
          dispatch({
            type: VERSIONS.GET.SUCCESS,
            payload: {
              listed: response.data,
              hashmap: response.data.reduce((initObj, version, index) => {
                initObj[version.id] = index
                return initObj
              }, {}),
            },
          })

          return response.data
        }
        dispatch({
          type: VERSIONS.GET.FAILED,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: VERSIONS.GET.FAILED,
          error,
        })

        throw error
      }
    }
  },
  create: (formData) => {
    return async (dispatch) => {
      const payload = {
        ...formData,
      }

      dispatch({ type: VERSIONS.CREATE.REQUEST })

      try {
        const response = await CoreApi.Versions.create(payload)

        if (response && response.status === 'success') {
          dispatch({
            type: VERSIONS.CREATE.SUCCESS,
            payload: response.data,
          })

          return response
        }

        dispatch({
          type: VERSIONS.CREATE.FAILED,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: VERSIONS.CREATE.FAILED,
          error,
        })

        throw error
      }
    }
  },
  update: (version) => {
    return async (dispatch) => {
      dispatch({ type: VERSIONS.UPDATE.REQUEST })

      try {
        const payload = {
          i18n_version: version.i18n_version,
          faq_version_id: version.faq_version_id,
        }

        if (version.note) payload.note = version.note

        const response = await CoreApi.Versions.update(version.id, payload)

        if (response && response.status === 'success') {
        // NOTE: We did have this StorageUtils.setItemIntoSession set for every version change for a long time here
        // it was not supposed to change the one inside session storage to the current one
        // so it was unintentional behavior
        // what it should do is if the current one in the sessionStorage is the same one, update it

          const versionStorage = StorageUtils.getItemFromSession('version')
          if (JSON.parse(versionStorage).id === response.data.id) {
            StorageUtils.setItemIntoSession('version', JSON.stringify({
              id: response.data.id,
              note: response.data.note,
              status: response.data.status,
              i18n_version: response.data.i18n_version,
              faq_version_id: response.data.faq_version_id,
            }))
          }

          dispatch({
            type: VERSIONS.UPDATE.SUCCESS,
            payload: {
              version: response.data,
            },
          })

          return response
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: VERSIONS.UPDATE.FAILED,
          error,
        })

        throw error
      }
    }
  },
  clone: (version) => {
    return async (dispatch) => {
      dispatch({ type: VERSIONS.CLONE.REQUEST })

      try {
        const response = await CoreApi.Versions.clone(version.id)

        if (response && response.status === 'success') {
          dispatch({
            type: VERSIONS.CLONE.SUCCESS,
            payload: response.data,
          })

          return response
        }

        dispatch({
          type: VERSIONS.CLONE.FAILED,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: VERSIONS.CLONE.FAILED,
          error,
        })

        throw error
      }
    }
  },
  deploy: (id, deployEnvs) => {
    return async (dispatch) => {
      dispatch({ type: VERSIONS.DEPLOY.REQUEST })

      try {
        const response = await CoreApi.Versions.deploy(id, deployEnvs)

        if (response && response.status === 'success') {
          dispatch({
            type: VERSIONS.DEPLOY.SUCCESS,
            payload: response.data,
          })

          return response
        }

        dispatch({
          type: VERSIONS.DEPLOY.FAILED,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: VERSIONS.DEPLOY.FAILED,
          error,
        })

        throw error
      }
    }
  },
  delete: (ids) => {
    return async (dispatch) => {
      dispatch({ type: VERSIONS.DELETE.REQUEST })

      try {
        const response = await CoreApi.Versions.deleteMany({ ids })

        if (response && response.status === 'success') {
          dispatch({
            type: VERSIONS.DELETE.SUCCESS,
            payload: ids,
          })

          return response
        }

        dispatch({
          type: VERSIONS.DELETE.FAILED,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: VERSIONS.DELETE.FAILED,
          error,
        })

        throw error
      }
    }
  },
  // attachTags: (tagIds) => async (dispatch) => {},
  // detachTags: (tagIds) => async (dispatch) => {},
  // getTrainingSetsByVersionId: (id) => async (dispatch) => {},
})
