import React from 'react'
import PropTypes from 'prop-types'
import style from './index.module.scss'

const ChoiceColumn = ({ choices }) => {
  return (
    <ul className={style.ul__Choice}>
      {choices.map((choice) => {
        return (
          <li key={choice.id}>
            <div>
              <span>{choice.name}</span>
            </div>
            <div>
              <span className={style.span__Synonym}>
                <span>Synonyms: </span>
                {choice.synonyms.join(', ') || '-'}
              </span>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

ChoiceColumn.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    synonyms: PropTypes.arrayOf(PropTypes.string).isRequired,
    created_at: PropTypes.string.isRequired,
  })).isRequired,
}

export default ChoiceColumn
