import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  Popconfirm,
  Space,
} from 'antd'
import style from './index.module.scss'

// NOTE: currently there is only two types: string and array
// with possibility of bot is usually a array

// Use this as a single view
const ChatMessage = ({ text, speaker }) => {
  if (speaker === 'user' || typeof text === 'string') {
    return (
      <div className={style[`chat_message__${speaker}`]}>
        {text}
      </div>
    )
  }

  if (speaker === 'bot' || Array.isArray(text)) {
    return (
      text.map((innerText) => {
        return (
          innerText.split('##_SP_MSG_##').map((splitLineMessage, index) => {
            return (
              <div className={style.chat_message__bot} key={index}>
                {splitLineMessage}
              </div>
            )
          })
        )
      })
    )
  }

  // text is array of string that has '/n' indicating new line
  return (
    text.map((innerText) => {
      return (
        innerText.split('\n').map((splitLineMessage, index) => {
          return (
            <div className={style.chat_message__bot} key={index}>
              {splitLineMessage}
            </div>
          )
        })
      )
    })
  )
}

ChatMessage.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  speaker: PropTypes.string.isRequired,
}

export const SingleChatExchange = ({ exchange }) => {
  return (
    <div className={style.chat_messages}>
      {exchange.texts.map(({ speech, replaced, type }, index) => {
        return (
          <ChatMessage key={index} text={speech || replaced} speaker={type} />
        )
      })}
    </div>
  )
}

SingleChatExchange.propTypes = {
  exchange: PropTypes.shape({
    texts: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

export const ExchangeOptions = ({
  exchange,
  viewSelectedExchangeData,
  loading,
  latestExchangeId,
  revertToSelectedExchange,
}) => {
  return (
    <div className='converse_options'>
      <div className='converse_options__label'>
        <Space direction='vertical'>
          <Typography.Text strong>{`Exchange ID: ${exchange.id}`}</Typography.Text>
          <Typography.Text strong>{`Response: ${exchange.responseTime ? `${exchange.responseTime}ms` : 'N/A'}`}</Typography.Text>
        </Space>
      </div>
      <div className='converse_options__actions'>
        <Button block type='primary' onClick={viewSelectedExchangeData} disabled={loading}>
          {`View${latestExchangeId === exchange.id ? '/Edit' : ''}`}
        </Button>
        {latestExchangeId !== exchange.id && (
          <Popconfirm
            title='This is irreversible, confirm?'
            onConfirm={revertToSelectedExchange}
            okText='Yes'
            cancelText='No'
          >
            <Button block type='primary' disabled={loading}>Revert</Button>
          </Popconfirm>
        )}
      </div>
    </div>
  )
}

ExchangeOptions.propTypes = {
  exchange: PropTypes.shape({
    id: PropTypes.string,
    responseTime: PropTypes.string,
  }).isRequired,
  viewSelectedExchangeData: PropTypes.func.isRequired,
  revertToSelectedExchange: PropTypes.func.isRequired,
  latestExchangeId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

const ChatExchanges = ({
  exchanges,
  latestExchangeId,
  viewData,
  revert,
  loading,
  showExchangeOptions,
  endToEndMode,
  onExchangeClick,
  activeCurrentViewExchange,
  disableAutoScroll,
}) => {
  const chatExchangesContainer = useRef(null)

  useEffect(() => {
    let isMounted = true
    if (isMounted && chatExchangesContainer.current && !disableAutoScroll) {
      chatExchangesContainer.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    return () => {
      isMounted = false
    }
  }, [exchanges, disableAutoScroll])

  return (
    <div className='ChatExchanges' ref={chatExchangesContainer}>
      {exchanges.map((exchange, exchangeIndex) => {
        const viewSelectedExchangeData = () => { viewData(exchange.id) }
        const revertToSelectedExchange = () => { revert(exchange.id) }
        const selectCurrentExchange = () => { onExchangeClick(exchange.id) }

        return (
          <div
            className={classnames(
              style.chat_exchange,
              activeCurrentViewExchange === exchange.id ? style.end_to_end_selected : null,
            )}
            key={exchangeIndex}
          >
            {!endToEndMode && <SingleChatExchange exchange={exchange} />}
            {endToEndMode && (
              <div
                className={style.chat_exchange_end_to_end}
                tabIndex={-1}
                role='button'
                onKeyPress={() => { return (null) }}
                onClick={selectCurrentExchange}
              >
                <div className={style.exchange_badge}>
                  <div className={style.exchange_label}>
                    <span className={style.exchange_label_text}>
                      Exchange ID:&thinsp;
                      <strong>{exchange.id}</strong>
                    </span>
                  </div>
                  {activeCurrentViewExchange === exchange.id && (
                    <div className={style.exchange_label_selected}>
                      <span className={style.exchange_label_selected_text}>
                        <strong>Selected</strong>
                      </span>
                    </div>
                  )}
                </div>
                <SingleChatExchange exchange={exchange} />
              </div>
            )}
            {showExchangeOptions && (
              <ExchangeOptions
                exchange={exchange}
                viewSelectedExchangeData={viewSelectedExchangeData}
                loading={loading}
                latestExchangeId={latestExchangeId}
                revertToSelectedExchange={revertToSelectedExchange}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

ChatExchanges.defaultProps = {
  showExchangeOptions: true,
  latestExchangeId: '',
  loading: false,
  endToEndMode: false,
  viewData: () => { return (null) },
  revert: () => { return (null) },
  onExchangeClick: () => { return (null) },
  activeCurrentViewExchange: '',
  disableAutoScroll: false,
}

ChatExchanges.propTypes = {
  exchanges: PropTypes.arrayOf(PropTypes.object).isRequired,
  latestExchangeId: PropTypes.string,
  viewData: PropTypes.func,
  revert: PropTypes.func,
  loading: PropTypes.bool,
  endToEndMode: PropTypes.bool,
  showExchangeOptions: PropTypes.bool,
  onExchangeClick: PropTypes.func,
  activeCurrentViewExchange: PropTypes.string,
  disableAutoScroll: PropTypes.bool,
}

export default ChatExchanges
