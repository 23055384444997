import React from 'react'
import { childrenType, featurePathType } from '../../types/permittedFeature'
import { usePermittedFeaturesLookup } from '../../hooks/usePermissions'

const PermittedFeatureCheck = ({ children, featurePath }) => {
  if (!featurePath) throw new Error('PermittedFeatureCheck:: missing featurePath lookup')
  const featureAllowed = usePermittedFeaturesLookup(featurePath)

  if (featureAllowed) {
    // NOTE: Cannot use expression { children } because it was meant for 'one' React element
    // The only way to render it as a children without using Fragment and old array trick ([<elem.../>])
    // is React.Children.toArray
    return React.Children.toArray(children)
  }

  return null
}

PermittedFeatureCheck.propTypes = {
  children: childrenType.isRequired,
  featurePath: featurePathType.isRequired,
}

export default PermittedFeatureCheck
