import React from 'react'
import { Descriptions, Typography, Alert } from 'antd'
import { humanizeDuration } from '@core/helpers'
import { statsType, featuredType, customStatsColonType } from '../../../Conversations/types'
import { fields, featuredFields } from '../../../Conversations/constants/conversationStats'

import style from './index.module.scss'

// This will show all stats from the conversation,
// e.g. time to agent/waiting time etc.
const ConversationStatistics = ({ stats, featured, column }) => {
  const selectedFields = featured ? featuredFields : fields

  if (!Object.entries(stats).length) {
    return (
      <div className={style.Conversation_statistics__container}>
        <Alert message='Stat(s) Unavailable' type='error' />
      </div>
    )
  }

  return (
    <div className={style.Conversation_statistics__container}>
      <Descriptions
        bordered
        size='small'
        column={column}
      >
        {selectedFields.map((field) => {
          return stats[field.name] !== undefined && (
            <Descriptions.Item
              label={(
                <Typography.Text title={field.title}>{field.title}</Typography.Text>
              )}
              key={field.name}
            >
              {field.template.replace(
                '{{data}}',
                field.type === 'duration'
                  ? humanizeDuration(stats[field.name], true)
                  : stats[field.name],
              )}
            </Descriptions.Item>
          )
        })}
      </Descriptions>
    </div>
  )
}

ConversationStatistics.propTypes = {
  stats: statsType.isRequired,
  featured: featuredType,
  column: customStatsColonType,
}

ConversationStatistics.defaultProps = {
  featured: false,
  column: 2,
}

export default ConversationStatistics
