import React from 'react'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import getOption from './getOption'

const ConversationsChartsTags = ({ data }) => {
  return (
    <ReactECharts
      option={getOption(data)}
    />
  )
}

ConversationsChartsTags.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default ConversationsChartsTags
