import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Collapse, Switch, Form, Input, Button, Space, Select } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import SentenceAnalysisActions from '../../store/actions/sentenceAnalysis'

const { Panel } = Collapse

const { Option } = Select

const DynamicDataFieldSet = () => {
  const [form] = Form.useForm()
  const { dynamicEntityTypes } = useSelector(({ data }) => {
    return { dynamicEntityTypes: data.dynamicEntities.dynamicEntityTypes }
  })
  const dataTypesChildren = dynamicEntityTypes.map((type) => { return <Option key={type}>{type}</Option> })

  const [useDynamicData, setUseDynamicData] = useState(false)
  const onFinish = async ({ dynamic_entities, has_dynamic_data }) => {
    if (has_dynamic_data) {
      const data = { dynamicEntities: {} }
      dynamic_entities.forEach((item) => {
        if (!data.dynamicEntities[item.type]) data.dynamicEntities[item.type] = { name: item.type, entities: [] }
        data.dynamicEntities[item.type].entities.push({ value: item.value.toLowerCase(), synonyms: item.synonyms.toLowerCase().split(',') })
      })
      await SentenceAnalysisActions.setData(data)
    }
  }

  const clearDynamicEntities = async () => {
    form.resetFields()
    await SentenceAnalysisActions.setData(null)
  }

  return (
    <Form onFinish={onFinish}>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Form.Item name='has_dynamic_data' label='Show Dynamic Data' valuePropName='checked'>
          <Switch
            onChange={() => { return setUseDynamicData(!useDynamicData) }}
          />
        </Form.Item>
        {useDynamicData
        && (
          <>
            <Collapse accordion defaultActiveKey={['dynamicEntities']}>
              <Panel header='Dynamic Entities' showArrow={false} key='dynamicEntities'>
                <Form.List name='dynamic_entities' span={8}>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map((field, index) => {
                          return (
                            <Space key={field.key} align='baseline'>
                              <Form.Item
                                {...field}
                                label='Type'
                                placeholder='Type'
                                name={[field.name, 'type']}
                                fieldKey={[field.fieldKey, 'type']}
                                style={{ width: 200 }}
                                rules={[{ required: true, message: 'Missing type' }]}
                              >
                                <Select>
                                  {dataTypesChildren}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Value'
                                name={[field.name, 'value']}
                                fieldKey={[field.fieldKey, 'value']}
                                rules={[
                                  { required: true, message: 'Missing value' },
                                  ({ getFieldsValue }) => {
                                    return {
                                      validator() {
                                        const { type } = getFieldsValue(true).dynamic_entities[index]
                                        if (type) return Promise.resolve()
                                        return Promise.reject(new Error('You need to select a dynamic entities type'))
                                      },
                                    }
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Synonyms'
                                name={[field.name, 'synonyms']}
                                fieldKey={[field.fieldKey, 'synonyms']}
                                rules={[
                                  { required: true, message: 'Missing synonym' },
                                  ({ getFieldsValue }) => {
                                    return {
                                      validator() {
                                        const { type, value } = getFieldsValue(true).dynamic_entities[index]
                                        if (!type) return Promise.reject(new Error('You need to select a dynamic entities type'))
                                        if (!value) return Promise.reject(new Error('You need to add a value'))
                                        return Promise.resolve()
                                      },
                                    }
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => { return remove(field.name) }} />
                            </Space>
                          )
                        })}

                        <Form.Item>
                          <Button type='dashed' onClick={() => { return add() }} block icon={<PlusOutlined />}>
                            Add Dynamic Entity
                          </Button>
                        </Form.Item>
                      </>
                    )
                  }}
                </Form.List>
              </Panel>
            </Collapse>
            <Space direction='horizontal'>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Set Values
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type='danger' onClick={clearDynamicEntities}>
                  Disable
                </Button>
              </Form.Item>
            </Space>
          </>
        )}
      </Space>
    </Form>
  )
}

export default DynamicDataFieldSet
