import React from 'react'
import PropTypes from 'prop-types'
import { CheckOutlined, CloseOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { evaluationResultsType } from '../../../../../types'

export const DomainIntentIntermediateResult = ({ evaluationResults }) => {
  if (!Object.entries(evaluationResults).length) return null

  return (
    <>
      {(evaluationResults?.domain && evaluationResults?.intent) ? <CheckOutlined style={{ color: '#00825F' }} /> : null}
      {(evaluationResults?.domain || evaluationResults?.intent) ? <MinusCircleOutlined style={{ color: '#FFBA00' }} /> : null}
      {(!evaluationResults?.domain || !evaluationResults?.intent) ? <CloseOutlined style={{ color: '#CF0000' }} /> : null}
    </>
  )
}

DomainIntentIntermediateResult.defaultProps = {
  evaluationResults: {},
}

DomainIntentIntermediateResult.propTypes = {
  evaluationResults: PropTypes.shape({
    domain: PropTypes.bool,
    intent: PropTypes.bool,
  }),
}

const ResultsIcon = ({ evaluationResults, fieldToCheck }) => {
  if (!Object.entries(evaluationResults).length) return null
  return evaluationResults[fieldToCheck] ? <CheckOutlined style={{ color: '#00825F' }} /> : <CloseOutlined style={{ color: '#CF0000' }} />
}

ResultsIcon.defaultProps = {
  evaluationResults: {},
}

ResultsIcon.propTypes = {
  evaluationResults: evaluationResultsType,
  fieldToCheck: PropTypes.string.isRequired,
}

export default ResultsIcon
