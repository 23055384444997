import React from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom'
import { PermissionRoute } from '../../pages/Routing'

// Pages
import SelectedSentence from './pages/SelectedSentence'
import SentenceQuery from './pages/SentencesQuery'

const DataModule = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Redirect from={`${path}`} to={`${path}/sentences`} exact />
      <Route path={`${path}/sentences`} component={SentenceQuery} exact />
      <PermissionRoute featurePath='utterances.data.viewSentenceDetails'>
        <Route
          path={`${path}/sentences/:sentence_id`}
          component={SelectedSentence}
          exact
        />
      </PermissionRoute>
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/utterances',
    component: DataModule,
  },
  name: 'Data',
}

export default DataModule
