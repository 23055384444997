import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Spin } from 'antd'

import StorageUtils from '@core/utils/storage'
import { useVersionSelector } from '@core/hooks/useVersions'
import DomainIntentCollection from '@core/store/actions/DomainIntentCollection'
import Generalizers from '@core/store/actions/Generalizers'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import VersionLabel from '@core/components/VersionLabel'

import Layouts from '../_layouts'
import { PermissionRoute } from '../../Routing'
import VersionsActions from '../../../modules/Versions/store/actions/Versions'
import { ModuleConfig as DomainIntentModuleConfig } from '../../../modules/DomainsIntents'
import { ModuleConfig as AnalysisModuleConfig } from '../../../modules/Analysis'
import { ModuleConfig as DataModuleConfig } from '../../../modules/Data'
import { ModuleConfig as OverviewModuleConfig } from '../../../modules/Overview'
import { ModuleConfig as ClientGroupsModuleConfig } from '../../../modules/ClientGroups'
import { ModuleConfig as InformationModuleConfig } from '../../../modules/Information'
import { ModuleConfig as VersionsModuleConfig } from '../../../modules/Versions'
import { ModuleConfig as ConversationsConfig } from '../../../modules/Conversations'
import { ModuleConfig as ExperimentsConfig } from '../../../modules/Experiments'
import { ModuleConfig as FaqsConfig } from '../../../modules/Faqs'
import { ModuleConfig as ConversationAnalysisConfig } from '../../../modules/ConversationAnalysis'
import { ModuleConfig as EmailAutomationConfig } from '../../../modules/EmailAutomations'
import { ModuleConfig as ConversationManagementConfig } from '../../../modules/ConversationManagement'
import SentenceAnalysisModalModule from '../../../modules/SentenceAnalysis'

import style from './index.module.scss'

export default () => {
  const { current } = useVersionSelector()
  const versionStorage = StorageUtils.getItemFromSession('version')

  useEffect(() => {
    const checkVersion = async () => {
      if (!current) {
        if (versionStorage) await VersionsActions.set(versionStorage)
      }
    }

    checkVersion()
  }, [current, versionStorage])

  useEffect(() => {
    if (current) {
      DomainIntentCollection.fetch()
      Generalizers.fetch()
    }
  }, [current])

  if (!current) {
    return (
      <div className={style.Permission__pending}>
        <Spin size='large' />
      </div>
    )
  }

  return (
    <Layouts>
      <VersionLabel version={current} />
      {/* NOTE: This one is only redirect, so should be fine */}
      <Route {...OverviewModuleConfig.routeProps} />
      <PermissionRoute
        moduleProps={AnalysisModuleConfig.routeProps}
        featurePath='utterances.analysis.viewTab'
      />
      <PermissionRoute
        moduleProps={DomainIntentModuleConfig.routeProps}
        featurePath='utterances.hierarchies.viewTab'
      />
      <PermissionRoute
        moduleProps={DataModuleConfig.routeProps}
        featurePath='utterances.data.viewTab'
      />
      <PermissionRoute
        moduleProps={VersionsModuleConfig.routeProps}
        featurePath='versions.viewTab'
      />
      <PermissionRoute
        moduleProps={InformationModuleConfig.routeProps}
        featurePath='information.viewTab'
      />
      <PermissionRoute
        moduleProps={ConversationsConfig.routeProps}
        featurePath='conversations.viewTab'
      />
      <PermissionRoute
        moduleProps={ExperimentsConfig.routeProps}
        featurePath='experiments.viewTab'
      />
      <PermissionRoute
        moduleProps={FaqsConfig.routeProps}
        featurePath='faqs.viewTab'
      />
      <PermissionRoute
        moduleProps={ConversationAnalysisConfig.routeProps}
        featurePath='conversationanalysis.viewTab'
      />
      <PermissionRoute
        moduleProps={ClientGroupsModuleConfig.routeProps}
        featurePath='clientgroups.viewTab'
      />
      <PermissionRoute
        moduleProps={EmailAutomationConfig.routeProps}
        featurePath='emailautomations.viewTab'
      />
      <PermissionRoute
        moduleProps={ConversationManagementConfig.routeProps}
        featurePath='conversationmanagement.viewTab'
      />
      <PermittedFeatureCheck featurePath='sentences.actionAnalysis'>
        <SentenceAnalysisModalModule />
      </PermittedFeatureCheck>
    </Layouts>
  )
}
