import React from 'react'
import { Form, Checkbox } from 'antd'
import IntentTreeInput from '@core/components/IntentTreeInput'
import { setIntentType } from '../../../types/trainingCaseFilter'

const TrainingCasesFilter = ({ setIntent }) => {
  return (
    <>
      <Form.Item name='training_domain_intents'>
        <IntentTreeInput
          setIntent={setIntent}
          placeholder='Specify domain/intent'
        />
      </Form.Item>
      <Form.Item
        valuePropName='checked'
        name='no_domain_training_set'
      >
        <Checkbox>Does not have a domain case</Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName='checked'
        name='no_intent_training_set'
      >
        <Checkbox>Does not have an intent case</Checkbox>
      </Form.Item>
    </>
  )
}

TrainingCasesFilter.propTypes = {
  setIntent: setIntentType.isRequired,
}

export default TrainingCasesFilter
