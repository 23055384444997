import React from 'react'
import { Layout, Radio, Tabs } from 'antd'
import UserListSider from '../../components/User/UserListSider'
import UserListContent from '../../components/User/UserListContent'
import { useUser } from '../../hooks/useUser'
import { UserProvider } from '../../components/User/UserProvider'

const { Content } = Layout

const ENVIRONMENT_OPTIONS = ['CANARY', 'STAGING', 'PRODUCTION']

const EnvironmentRadio = () => {
  const { environment, setEnvironment } = useUser()

  return (
    <Radio.Group size='small' value={environment} onChange={(e) => { return setEnvironment(e.target.value) }}>
      {ENVIRONMENT_OPTIONS.map((env, index) => {
        return (
          <Radio.Button key={index} value={env}>
            {env}
          </Radio.Button>
        )
      })}
    </Radio.Group>
  )
}

const UserList = () => {
  return (
    <>
      <UserListSider />
      <Content style={{ position: 'relative' }}>
        <Tabs
          activeKey='Data'
          tabBarExtraContent={(
            <EnvironmentRadio />
          )}
        >
          <Tabs.TabPane key='Data' tab='Data'><UserListContent /></Tabs.TabPane>
        </Tabs>
      </Content>
    </>
  )
}

const DebugDashboardUserList = () => {
  return <UserProvider><UserList /></UserProvider>
}

export default DebugDashboardUserList
