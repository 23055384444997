import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
// NOTE: DO NOT CHANGE ORDER OF THE IMPORT
// OTHERWISE MOVE IT TO THE index.jsx
import './stylesheets/app.global.scss'
import Entryway from './pages'

const AppRoot = () => {
  return (
    <div className='App louise'>
      <Router>
        <Switch>
          <Entryway />
        </Switch>
      </Router>
    </div>
  )
}

export default AppRoot
