import React from 'react'
import { Form } from 'antd'
import TermsInput from '../../TermsInput'
import {
  getTermsType,
  getExcludeTermsType,
  getRegexesType,
  getExcludeRegexesType,
} from '../../../types/termsFilter'

const TermsFilter = ({
  getTerms,
  getExcludeTerms,
  getRegexes,
  getExcludeRegexes,
}) => {
  return (
    <>
      <Form.Item name='terms'>
        <TermsInput getTerms={getTerms} placeholder='Lookup by specific terms' />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }}>
        <TermsInput getTerms={getExcludeTerms} placeholder='Exclude specific terms' />
      </Form.Item>
      <Form.Item name='regex' style={{ marginTop: 10 }}>
        <TermsInput getTerms={getRegexes} placeholder='Lookup by regex' />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }}>
        <TermsInput getTerms={getExcludeRegexes} placeholder='Exclude by regex' />
      </Form.Item>
    </>
  )
}

TermsFilter.propTypes = {
  getTerms: getTermsType.isRequired,
  getExcludeTerms: getExcludeTermsType.isRequired,
  getRegexes: getRegexesType.isRequired,
  getExcludeRegexes: getExcludeRegexesType.isRequired,
}

export default TermsFilter

