import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  Alert,
  Tabs,
  Form,
  Select,
  notification,
} from 'antd'
import { QuestionCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import TabPaneLabel from '@core/components/TabPaneLabel'
import ExpandableErrorMessage from '@core/components/ExpandableErrorMessage'
import { FaqVersionContext } from '../../../helpers/context'
import { processFaqEntityPayload } from '../../../helpers'
import { createFaqEntity } from '../../../hooks/useManageFaqs'
import Questions from './Questions'
import Answers from './Answers'
import style from './index.module.scss'

const CreateNewFaqModal = ({
  visible,
  loading,
  closeModal,
  dispatcher,
  refreshResult,
}) => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [hasFormError, setHasFormError] = useState(false)
  const [defaultMessageError, setDefaultMessageError] = useState(false)
  const [directChoiceMessageError, setDirectChoiceMessageError] = useState(false)
  const [activeKey, setActiveKey] = useState('questions')

  const onSave = async (values) => {
    setHasError(false)
    setHasFormError(false)
    setDefaultMessageError(false)
    setDirectChoiceMessageError(false)

    const payload = processFaqEntityPayload(values, faqVersionId)

    try {
      const response = await createFaqEntity(dispatcher, payload)

      if (response) {
        await refreshResult() // Fetch again
        closeModal()

        notification.success({ message: 'Add new Faq Entity Success' })
      }
    } catch (error) {
      setErrorMessage(error)
      setHasError(true)
    }
  }

  const handleActiveKeyChange = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const onFormError = ({ values }) => {
    const { main_question, fallback_response, choice_category_id } = values
    if (choice_category_id) {
      if (!main_question || !fallback_response) setDefaultMessageError(true)
    } else {
      setDirectChoiceMessageError(true)
    }
    setHasFormError(true)
  }

  useEffect(() => {
    setActiveKey('questions')
    if (!visible) {
      form.resetFields()
    }
  }, [visible, form])

  if (!visible) return null

  return (
    <Modal
      title='Create new FAQ'
      className='CreateNewFaqModal'
      maskClosable={false}
      visible={visible}
      centered
      onCancel={closeModal}
      destroyOnClose
      width={1024}
      footer={[
        <Button key='back' onClick={closeModal} disabled={loading}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' disabled={loading} loading={loading} onClick={form.submit}>
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onSave}
        onFinishFailed={onFormError}
        initialValues={{
          faq_questions: [''],
        }}
      >
        <Form.Item
          name='language'
          label='Language'
          rules={[{ required: true, message: 'Required field' }]}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Select placeholder='Language' allowClear>
            <Select.Option value='en'>English (en)</Select.Option>
          </Select>
        </Form.Item>
        <Tabs defaultActiveKey='questions' activeKey={activeKey} onChange={handleActiveKeyChange}>
          <Tabs.TabPane
            tab={(
              <TabPaneLabel
                title='Questions'
                icon={<QuestionCircleOutlined />}
                isActive={activeKey === 'questions'}
              />
            )}
            key='questions'
            forceRender
          >
            <Questions form={form} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <TabPaneLabel
                title='Answers'
                icon={<CheckCircleOutlined />}
                isActive={activeKey === 'answers'}
              />
            )}
            key='answers'
            forceRender
          >
            <Answers />
          </Tabs.TabPane>
        </Tabs>
      </Form>
      {(hasError || hasFormError || defaultMessageError) ? (
        <div className={style.Errors}>
          {hasError && <Alert className={style.Error__response_with_content} showIcon type='error' message={<ExpandableErrorMessage error={errorMessage.data} />} />}
          {hasFormError && <Alert className={style.Error__response} showIcon type='error' message='Please check all the required fields, or FAQ Sentence(s)' />}
          {defaultMessageError && <Alert className={style.Error__response} showIcon type='error' message='There is no fallback answer message, required at least one variation' />}
          {directChoiceMessageError && <Alert className={style.Error__response} showIcon type='error' message='Please check FAQ answer. Answer without choices required at least one response variation' />}
        </div>
      ) : null}
    </Modal>
  )
}

CreateNewFaqModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  dispatcher: PropTypes.func.isRequired,
  refreshResult: PropTypes.func.isRequired,
}

export default CreateNewFaqModal
