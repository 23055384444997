import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Space,
  Modal,
  Button,
  List,
  Row,
  Col,
  Typography,
  Descriptions,
} from 'antd'
import { EditOutlined, UnorderedListOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import TaggerVectorDisplay from '../VectorDisplay'

const { Title } = Typography

const TaggerEvaluationDetailModal = ({ tagger, items }) => {
  const [visible, setVisible] = useState(false)
  const [currentVector, setCurrentVector] = useState(null)

  const showDrawer = (vector) => {
    setCurrentVector(vector)
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const classifications = useMemo(
    () => {
      return items.reduce((data, item) => {
        if (item.tagger_type === tagger) {
          if (item.testPassed) {
            data.passed.push(item)
          } else {
            data.failed.push(item)
          }
        }
        return data
      },
      {
        passed: [],
        failed: [],
      })
    },
    [tagger, items],
  )
  return (
    <>
      {currentVector && <TaggerVectorDisplay visible={visible} onClose={onClose} vector={currentVector} />}
      <Button onClick={showModal} size='small'>
        Show Details
      </Button>
      <Modal
        title={tagger}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1600}
      >
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <List
              size='small'
              header={<Title level={5}>Passed</Title>}
              bordered
              dataSource={classifications.passed}
              renderItem={(item) => {
                return (
                  <List.Item
                    extra={(
                      <Space direction='vertical'>
                        <Button
                          onClick={() => {
                            if (item.sentence_id) history.push(`/utterances/sentences/${item.sentence_id}`)
                          }}
                          icon={<EditOutlined />}
                        >
                          Edit
                        </Button>
                        <Button
                          icon={<UnorderedListOutlined />}
                          onClick={() => { return showDrawer(item.result.vector) }}
                        >
                          See vector
                        </Button>
                      </Space>
                    )}
                  >
                    <Descriptions bordered span={12}>
                      <Descriptions.Item label='text' span={6}>{item.text}</Descriptions.Item>
                      <Descriptions.Item label='generalized' span={6}>{item.generalized}</Descriptions.Item>
                      <Descriptions.Item label='phrase' span={6}>{item.phrase}</Descriptions.Item>
                      <Descriptions.Item label='phrase index' span={6}>{item.phrase_index}</Descriptions.Item>
                      <Descriptions.Item label='tag' span={6}>{item.result.tag}</Descriptions.Item>
                      <Descriptions.Item label='result' span={6}>{item.result.value}</Descriptions.Item>
                      <Descriptions.Item label='expected result' span={6}>{item.trueClass}</Descriptions.Item>
                    </Descriptions>
                  </List.Item>
                )
              }}
            />
          </Col>
          <Col span={12}>
            <List
              size='small'
              header={<Title level={5}>Failed</Title>}
              bordered
              dataSource={classifications.failed}
              renderItem={(item) => {
                return (
                  <List.Item
                    extra={(
                      <Space direction='vertical'>
                        <Button
                          onClick={() => {
                            if (item.sentence_id) history.push(`/utterances/sentences/${item.sentence_id}`)
                          }}
                          icon={<EditOutlined />}
                        >
                          Edit
                        </Button>
                        <Button
                          icon={<UnorderedListOutlined />}
                          onClick={() => { return showDrawer(item.result.vector) }}
                        >
                          See vector
                        </Button>
                      </Space>
                    )}
                  >
                    <Descriptions bordered span={12}>
                      <Descriptions.Item label='text' span={6}>{item.text}</Descriptions.Item>
                      <Descriptions.Item label='generalized' span={6}>{item.generalized}</Descriptions.Item>
                      <Descriptions.Item label='phrase' span={6}>{item.phrase}</Descriptions.Item>
                      <Descriptions.Item label='phrase index' span={6}>{item.phrase_index}</Descriptions.Item>
                      <Descriptions.Item label='tag' span={6}>{item.result.tag}</Descriptions.Item>
                      <Descriptions.Item label='result' span={6}>{item.result.value}</Descriptions.Item>
                      <Descriptions.Item label='expected result' span={6}>{item.trueClass}</Descriptions.Item>
                    </Descriptions>
                  </List.Item>
                )
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

TaggerEvaluationDetailModal.propTypes = {
  tagger: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      generalized: PropTypes.string.isRequired,
      trueClass: PropTypes.string.isRequired,
      testPassed: PropTypes.bool.isRequired,
      phrase: PropTypes.string,
      phrase_index: PropTypes.number,
      result: PropTypes.shape({
        value: PropTypes.number,
        tag: PropTypes.string,
        vector: PropTypes.shape(),
      }),
    }),
  ).isRequired,
}

export default TaggerEvaluationDetailModal
