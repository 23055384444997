import React from 'react'
import {
  Form,
  Cascader,
  // Select,
  Typography,
} from 'antd'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import { formInstanceType, sentenceType } from '../../../types/form'

// const { Option } = Select
const { Title } = Typography

const IntentForm = ({ form, sentence }) => {
  const { intentCascader } = useDomainIntentSelector()
  const onSelectOptions = () => { }

  // With cascader it can transform data before send to backend
  return (
    <Form
      form={form}
      layout='vertical'
      name='form__training_intent'
    >
      <Title level={4}>{sentence}</Title>
      <Form.Item
        name='domain_intent'
        label='Select Domain/Intent'
      >
        <Cascader
          options={intentCascader}
          onChange={onSelectOptions}
          placeholder='Select Domain/Intent'
        />
      </Form.Item>

      {/* For the split select box */}
      {/* <Form.Item
        name='test_domain'
        label='Test Domain'
        rules={[
          {
            required: true,
            message: 'Please selected test domain',
          },
        ]}
      >
        <Select
          placeholder='Select a test domain'
          onChange={onDomainChange}
          allowClear
        >
          {domainList.map((domain, index) => (
            <Option value={domain} key={index}>{domain}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='test_intent'
        label='Test Intent'
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Select
          placeholder='Select a test intent'
          allowClear
        >
          {intentList.map((intent, index) => (
            <Option value={intent} key={index}>{intent}</Option>
          ))}
        </Select>
      </Form.Item> */}
    </Form>
  )
}

IntentForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
}

export default IntentForm
