import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Input,
  Button,
  Tabs,
  Typography,
  Space,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  validateCurlyBracketsPlaceholder,
  validateSquareBracketsPlaceholder,
  getResponseTextFirstInputErrorMessage,
} from '../../../helpers'
import openDegradedTextVariationInformationModal from '../../DegradedTextVariationsInfoModal'
import style from './index.module.scss'

const MultilineResponseFormInput = ({ name, isFallbackTab }) => {
  return (
    <Form.List
      name={name}
      initialValue={['']}
      rules={[
        {
        // global validator
          validator: async (_, values) => {
            if (!values) {
              return Promise.reject(new Error('enter the field or remove it'))
            }

            return values
          },
        },
      ]}
    >
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => {
              return (
                <Form.Item
                  required={false}
                  key={field.key}
                >
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      validateTrigger={['onSubmit']}
                      {...field}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: index === 0 ? getResponseTextFirstInputErrorMessage(isFallbackTab) : 'Enter a message or remove this field',
                        },
                        {
                          required: true,
                          validator: validateCurlyBracketsPlaceholder,
                        },
                        {
                          required: true,
                          validator: validateSquareBracketsPlaceholder,
                        },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input.TextArea
                        placeholder='New-line response text'
                        showCount
                        maxLength={160}
                        style={{ resize: 'vertical' }}
                        rows={5}
                      />
                    </Form.Item>
                    {index !== 0 && (
                      <Button
                        type='link'
                        size='small'
                        onClick={() => { return remove(field.name) }}
                        danger
                        style={{ paddingRight: 0 }}
                      >
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                        />
                      </Button>
                    )}
                  </div>
                </Form.Item>
              )
            })}
            <Button
              className={style.degradedMessages}
              type='dashed'
              onClick={() => {
                if (fields.length <= 5) add()
              }}
              style={{ width: '60%' }}
              icon={<PlusOutlined />}
              disabled={fields.length === 5}
            >
              {fields.length === 5 ? 'Maximum split line reached' : 'Add split line message'}
            </Button>
          </>
        )
      }}
    </Form.List>
  )
}

MultilineResponseFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  isFallbackTab: PropTypes.bool.isRequired,
}

const initialPanes = [
  {
    title: '1',
    content: <MultilineResponseFormInput name='values.0' isFallbackTab />,
    key: '0',
    closable: false,
  },
]

// This is for Add
export default class AddDegradedMessagesVariations extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      activeKey: initialPanes[0].key,
      panes: initialPanes,
    }
  }

  onChange = (activeKey) => {
    this.setState({ activeKey })
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }

  renderTabContent = (activeKey) => {
    return (
      <MultilineResponseFormInput
        activeKey={activeKey}
        name={`values.${activeKey}`}
        isFallbackTab={false}
      />
    )
  }

  add = () => {
    const { panes } = this.state
    const activeKey = `${this.newTabIndex++}` // this makes a force focus upon add new tab
    const newPanes = [...panes]
    newPanes.push({
      title: `${parseInt(activeKey) + 1}`, // im sorry... :(
      content: this.renderTabContent(activeKey),
      key: activeKey,
    })

    this.setState({
      panes: newPanes,
      activeKey,
    })
  }

  remove = (targetKey) => {
    // Tab will always at least has 1 length
    const { panes, activeKey } = this.state
    let newActiveKey = activeKey
    let lastIndex
    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1
      }
    })

    const newPanes = panes.filter((pane) => { return pane.key !== targetKey })
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key
      } else {
        // this seemes like will never occur...
        newActiveKey = newPanes[0].key
      }
    }

    this.setState({
      panes: newPanes,
      activeKey: newActiveKey,
    })
  }

  render() {
    // the required props will receive
    // values
    // we need to make a tab based on the values degraded delimiter
    // the 'Add' will have one default
    const { panes, activeKey } = this.state
    return (
      <>
        <Space align='baseline'>
          <Typography.Text className={style.variations_label}>Degraded Text Variations</Typography.Text>
          <Button type='link' icon={<QuestionCircleOutlined />} onClick={openDegradedTextVariationInformationModal} />
        </Space>
        <Tabs
          type='editable-card'
          onChange={this.onChange}
          activeKey={activeKey}
          onEdit={this.onEdit}
        >
          {panes.map((pane) => {
            return (
              <Tabs.TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
                {pane.content}
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </>
    )
  }
}
