import React, { useState, useContext, useEffect } from 'react'
import { TreeSelect, Divider, Form } from 'antd'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../../api'
import style from './style.module.scss'
import { EmailAutomationsContext } from '../../../../context'

const { SHOW_PARENT } = TreeSelect

const ByDataExtractionLabels = () => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const dataSourceQueryParams = { version: currentVersion, datasource }
  const [
    { loading: extractorTypesLoading, data: extractorsTypes },
  ] = useRequest(Api.DataExtractions.getExtractorTypes, dataSourceQueryParams)

  const [value, setValue] = useState([])
  const [treeData, setTreeData] = useState([])

  useEffect(() => {
    // get extractorType and transform to tree
    const extractorTypeLookup = Object.entries(extractorsTypes || {}).reduce((acc, [extractorType, desiredValues]) => {
      if (!acc[extractorType]) {
        acc[extractorType] = {
          title: extractorType,
          key: extractorType,
          value: extractorType,
          children: desiredValues.map((desiredLabel) => {
            return { title: `${desiredLabel}`, key: `${extractorType}:${desiredLabel}`, value: `${extractorType}:${desiredLabel}` }
          }),
        }
      }
      return acc
    }, {})

    setTreeData(Object.values(extractorTypeLookup))
  }, [extractorsTypes, setTreeData])

  const onChange = (newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Divider>
        <small><strong> By Taggers </strong></small>
      </Divider>
      {!extractorTypesLoading && (
        <Form.Item name='extractionLabelsTree'>
          <TreeSelect
            className={style.ExtractionFilterTree}
            treeData={treeData}
            value={value}
            onChange={onChange}
            treeCheckable
            allowClear
            showCheckedStrategy={SHOW_PARENT}
            placeholder='Select Taggers'
          />
        </Form.Item>
      )}
    </>
  )
}

export default ByDataExtractionLabels
