import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Modal,
  notification,
  Spin,
  Typography,
  Radio,
  List,
  Space,
  Divider,
} from 'antd'
import { useSelectedSentences } from '../../../hooks/useSelectedSentences'
import BulkOperationModalActions from '../../../store/actions/BulkOperationModal'
import SentenceActions from '../../../store/actions/sentences'

const BulkUnrecognizedModal = () => {
  const visibility = useSelector(({ data }) => { return (data.bulkOperationModal.visibility) })
  const selectedSentences = useSelectedSentences()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [unrecognized, setUnrecognized] = useState(1)

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    if (hasError) setHasError(false)
    setConfirmLoading(true)
    try {
      let response = null
      response = await SentenceActions.bulkToggleUnrecognized(Boolean(unrecognized))

      if (response && response.status === 'success') {
        setConfirmLoading(false)
        BulkOperationModalActions.close()

        notification.success({
          message: `Successfully marked ${response.data.length} sentence${response.data.length > 1 ? 's' : ''} as ${response.data[0].unrecognized ? 'unrecognized' : 'recognized'}`,
          duration: 10,
        })
        return response
      }
    } catch (error) {
      setConfirmLoading(false)
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  const closeModal = () => {
    setConfirmLoading(false)
    setHasError(false)

    BulkOperationModalActions.close()
  }

  const onUnrecognizedChange = (event) => {
    const { target: { value } } = event
    setUnrecognized(value)
  }

  if (!visibility) return null

  return (
    <Modal
      title='Mark/Unmark Recognized Sentence(s)'
      visible={visibility}
      maskClosable={false}
      centered
      closable={false}
      onOk={onSubmit}
      onCancel={closeModal}
      confirmLoading={confirmLoading}
      footer={[
        <Button key='back' disabled={confirmLoading} onClick={closeModal}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={confirmLoading} onClick={onSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Spin spinning={confirmLoading}>
        {selectedSentences.length && (
          <List
            size='small'
            footer={(
              <div>
                <Typography.Text strong>
                  {`Total ${selectedSentences.length} sentence(s)`}
                </Typography.Text>
              </div>
            )}
            bordered
            dataSource={selectedSentences}
            renderItem={(item) => {
              return (
                <List.Item>
                  <Space size='small' align='start' direction='vertical'>
                    <span>{item.text}</span>
                  </Space>
                </List.Item>
              )
            }}
          />
        )}
        <Divider />
        <Typography.Title level={5}>Actions</Typography.Title>
        <Radio.Group onChange={onUnrecognizedChange} value={unrecognized}>
          <Radio value={1}>
            <span>
              Mark sentence(s)&thinsp;
              <strong>&lsquo;unrecognized&rsquo;</strong>
            </span>
          </Radio>
          <Radio value={0}>
            <span>
              Mark sentence(s)&thinsp;
              <strong>&lsquo;recognized&rsquo;</strong>
            </span>
          </Radio>
        </Radio.Group>
        {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      </Spin>
    </Modal>
  )
}

export default BulkUnrecognizedModal
