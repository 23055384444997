import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { INTERNATIONALIZE } from '@core/store/constants'
import cloneDeep from 'lodash.clonedeep'
import CoreApi from '@core/api'
import { reHashIndex } from '@core/helpers'

const wrapped = wrapActionCreatorWithDispatch({
  getAll: () => {
    return async (dispatch) => {
      dispatch({ type: INTERNATIONALIZE.VERSION.GET_ALL.REQUEST })

      try {
        const response = await CoreApi.Internationalize.Version.getAll()

        if (response && response.status === 'success') {
          dispatch({
            type: INTERNATIONALIZE.VERSION.GET_ALL.SUCCESS,
            payload: {
              listed: response.data,
              hashedIndex: reHashIndex(response.data),
              maxVersion: Math.max(...response.data.map((version) => { return version.id })),
              minNewVersionNumber: Math.max(...response.data.map((version) => { return version.id })) + 1,
            },
          })

          return response.data
        }
        dispatch({
          type: INTERNATIONALIZE.VERSION.GET_ALL.FAILED,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: INTERNATIONALIZE.VERSION.GET_ALL.FAILED,
          error,
        })

        throw error
      }
    }
  },
  update: (version) => {
    return async (dispatch, getState) => {
      dispatch({ type: INTERNATIONALIZE.VERSION.UPDATE.REQUEST })

      try {
        const response = await CoreApi.Internationalize.Version.editVersion(
          version.id,
          {
            note: version.note,
          },
        )

        if (response && response.status === 'success') {
          const { hashedIndex, listed } = getState().core.internationalize
          const newListed = cloneDeep(listed)
          const updatedVersionIndex = hashedIndex[response.data.id]
          newListed[updatedVersionIndex] = {
            ...newListed[updatedVersionIndex],
            ...response.data,
          }

          dispatch({
            type: INTERNATIONALIZE.VERSION.UPDATE.SUCCESS,
            payload: {
              listed: newListed,
            },
          })

          return response
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: INTERNATIONALIZE.VERSION.UPDATE.FAILED,
          error,
        })

        throw error
      }
    }
  },
  clone: (version) => {
    return async (dispatch) => {
      dispatch({ type: INTERNATIONALIZE.VERSION.CLONE.REQUEST })

      try {
        const response = await CoreApi.Internationalize.Version.cloneVersion(version)

        if (response && response.status === 'success') {
          dispatch({
            type: INTERNATIONALIZE.VERSION.CLONE.SUCCESS,
            payload: response.data,
          })

          return wrapped.getAll()
        }

        dispatch({
          type: INTERNATIONALIZE.VERSION.CLONE.FAILED,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: INTERNATIONALIZE.VERSION.CLONE.FAILED,
          error,
        })

        throw error
      }
    }
  },
  delete: (id) => {
    return async (dispatch) => {
      dispatch({ type: INTERNATIONALIZE.VERSION.DELETE.REQUEST })

      try {
        const response = await CoreApi.Internationalize.Version.deleteVersion(id)

        if (response && response.status === 'success') {
          dispatch({
            type: INTERNATIONALIZE.VERSION.DELETE.SUCCESS,
            payload: response.data,
          })

          return wrapped.getAll()
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: INTERNATIONALIZE.VERSION.DELETE.FAILED,
          error,
        })

        throw error
      }
    }
  },
})

export default wrapped
