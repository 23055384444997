/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Menu, Button, Dropdown } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import {
  setModalVisibilityType,
  setModalTitleType,
  setSelectedCaseType,
} from '../../types/addCaseModal'
import {
  domainCaseExistType,
  intentCaseExistType,
  extractorCaseExistType,
  caseType as casePropType,
} from '../../types/addCaseDropdown'

const AddNewCaseDropdownButton = ({
  setModalVisibility,
  setModalTitle,
  setSelectedCase,
  domainCaseExist,
  intentCaseExist,
  extractorCaseExist,
  caseType,
}) => {
  const onClick = (event) => {
    event.preventDefault()
  }

  const openAddDomainModal = () => {
    setModalTitle(`Add new ${caseType} case for Domain`)
    setModalVisibility(true)
    setSelectedCase('domain')
  }

  const openAddIntentModal = () => {
    setModalTitle(`Add new ${caseType} case for Intent`)
    setModalVisibility(true)
    setSelectedCase('intent')
  }

  const openAddTaggerModal = () => {
    setModalTitle(`Add new ${caseType} case for Tagger`)
    setModalVisibility(true)
    setSelectedCase('tagger')
  }

  const openAddExtractorModal = () => {
    setModalTitle(`Add new ${caseType} case for Extractor`)
    setModalVisibility(true)
    setSelectedCase('extractor')
  }

  // const openAddGeneralizerModal = () => {
  //   setModalTitle(`Add new ${caseType} case for Generalizer`)
  //   setModalVisibility(true)
  //   setSelectedCase('generalizer')
  // }

  const addCaseMenu = (
    <Menu>
      <Menu.Item>
        <Button
          type='link'
          onClick={openAddDomainModal}
          disabled={domainCaseExist}
        >
          Domain
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          type='link'
          onClick={openAddIntentModal}
          disabled={intentCaseExist}
        >
          Intent
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          type='link'
          onClick={openAddTaggerModal}
        >
          Tagger
        </Button>
      </Menu.Item>
      {caseType === 'Test'
      && (
        <Menu.Item>
          <Button
            type='link'
            onClick={openAddExtractorModal}
            disabled={extractorCaseExist}
          >
            Data Extraction
          </Button>
        </Menu.Item>
      )}
      {/* <Menu.Item>
        <Button
          type='link'
          onClick={openAddGeneralizerModal}
          disabled
        >
          Generalizer
        </Button>
      </Menu.Item> */}
    </Menu>
  )

  return (
    <Dropdown overlay={addCaseMenu} trigger={['click']}>
      <a
        className='ant-dropdown-link'
        onClick={onClick}
        target='_blank'
        rel='noopener noreferrer'
      >
        <strong>
          Add&nbsp;
          {'   '}
        </strong>
        <PlusOutlined />
      </a>
    </Dropdown>
  )
}

AddNewCaseDropdownButton.defaultProps = {
  domainCaseExist: false,
  intentCaseExist: false,
  extractorCaseExist: false,
}

AddNewCaseDropdownButton.propTypes = {
  setModalVisibility: setModalVisibilityType.isRequired,
  setModalTitle: setModalTitleType.isRequired,
  setSelectedCase: setSelectedCaseType.isRequired,
  domainCaseExist: domainCaseExistType,
  intentCaseExist: intentCaseExistType,
  extractorCaseExist: extractorCaseExistType,
  caseType: casePropType.isRequired,
}

export default AddNewCaseDropdownButton
