import React, { useState, useEffect } from 'react'
import {
  Layout,
  PageHeader,
  Tabs,
  message,
  Drawer,
  Button,
  Space,
  Typography,
  Tag,
} from 'antd'
import { SettingFilled } from '@ant-design/icons'
import InternationalActions from '@core/store/actions/Internationalize'
import { useInternationalizeVersionSelector } from '@core/hooks/useInternationalize'

import BotTextQuery from '../../components/BotTextQuery'
import ConnectorTextQuery from '../../components/ConnectorTextQuery'
import { I18nVersionCollapsedPanel } from '../../components/I18nVersionPanel'

import { I18nVersionContext, getCurrentI18nVersionId } from '../../helpers/context'

const handleClone = async (id) => {
  try {
    await InternationalActions.clone({ from_version: id })
    message.success('Clone succeeded')
  } catch (error) {
    message.error('Clone failed')
  }
}

const handleDelete = async (id) => {
  try {
    await InternationalActions.delete(id)
    message.success('Delete succeeded')
  } catch (error) {
    message.error('Delete failed')
  }
}

const ResponseText = () => {
  const [i18nVersionId, setI18nVersionId] = useState(getCurrentI18nVersionId())
  const [i18nVersionDrawerOpen, setI18nVersionDrawerOpen] = useState(false)
  const i18nVersions = useInternationalizeVersionSelector().map((v) => { return { ...v, key: v.id } })
  const currentSelectedVersion = i18nVersions.find((item) => { return item.id === i18nVersionId })

  // Use the version selector and get the i18n_version field
  const [activeKey, setActiveKey] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : 'tab__botText',
  )

  const handleActiveKeyChange = (newActiveKey) => {
    window.location.hash = `#${newActiveKey}`
    setActiveKey(newActiveKey)
  }

  const onI18nVersionSettingClicked = () => {
    setI18nVersionDrawerOpen((prev) => { return !prev })
  }

  const closeI18nVersionDrawer = () => { return setI18nVersionDrawerOpen(false) }

  useEffect(() => {
    const fetchVersion = async () => {
      await InternationalActions.getAll()
    }

    fetchVersion()
  }, [])

  return (
    <Layout.Content className='ConversationResponseText'>
      {/* TODO: Figure this out for using it as context, because for some reason using context component would cause wrong context value (stale?) */}
      <I18nVersionContext.Provider value={{ i18nVersionId, setI18nVersionId }}>
        <PageHeader
          title={(
            <Space>
              <Typography.Title level={4}>Response Text</Typography.Title>
              <Tag color='green'>{`Current i18n Version: ${i18nVersionId}${currentSelectedVersion ? `, ${currentSelectedVersion?.note}` : ''}`}</Tag>
            </Space>
          )}
        />
        <Tabs
          activeKey={activeKey}
          onChange={handleActiveKeyChange}
          tabBarExtraContent={(
            <Button
              type='link'
              icon={<SettingFilled />}
              onClick={onI18nVersionSettingClicked}
            >
              Select i18n Version
            </Button>
          )}
        >
          <Tabs.TabPane key='tab__botText' tab='Bot Text'>
            <BotTextQuery />
          </Tabs.TabPane>
          <Tabs.TabPane key='tab__connectorText' tab='Connector Text'>
            <ConnectorTextQuery />
          </Tabs.TabPane>
        </Tabs>
        <Drawer
          title='Select i18n Version'
          placement='right'
          onClose={closeI18nVersionDrawer}
          visible={i18nVersionDrawerOpen}
          destroyOnClose
          width='auto' // NOTE: antd 'forced' width on the style attribute
        >
          <I18nVersionCollapsedPanel
            onClone={handleClone}
            onDelete={handleDelete}
          />
        </Drawer>
      </I18nVersionContext.Provider>
    </Layout.Content>
  )
}

export default ResponseText
