import {
  SENTENCE,
  SENTENCE_LIST_UI,
  SENTENCE_LIST_CHECK,
  BULK_OPERATION_MODAL_DATA,
} from '../constants'

const INITIAL_STATE = {}

// TODO: UtteranceTableRefactor - remove this reducer, we don't probably need a check anymore

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SENTENCE.FETCH.RESET:
    case SENTENCE.FETCH.FAILED:
    case SENTENCE.FETCH.REQUEST:
    case SENTENCE.DELETE.SUCCESS:
    case SENTENCE.BULK_MODE.APPLY_TEST.SUCCESS:
    case SENTENCE.BULK_MODE.TOGGLE_UNRECOGNIZED.SUCCESS:
    case SENTENCE.BULK_MODE.APPLY_TRAINING.SUCCESS:
    case SENTENCE_LIST_UI.TOGGLE_BULK_MODE: {
      return {}
    }
    // TODO: UtteranceTableRefactor - one time use, remove this
    case BULK_OPERATION_MODAL_DATA.SELECT: {
      return { ...action.payload.sentencesListCheck }
    }
    case SENTENCE.FETCH.SUCCESS: {
      return { ...action.payload.listedCheck }
    }
    // TODO: UtteranceTableRefactor - remove this
    case SENTENCE_LIST_CHECK.TOGGLE: {
      return {
        ...state,
        [action.payload.id]: action.payload.checked,
      }
    }
    default: return state
  }
}
