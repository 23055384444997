import { GENERALIZER } from '../constants'

const INITIAL_STATE = {
  categories: {},
  colors: {},
  grouped: {},
  lookup: {},
  types: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERALIZER.FETCH.SUCCESS: {
      return {
        ...action.payload,
      }
    }
    case GENERALIZER.FETCH.REQUEST:
    case GENERALIZER.FETCH.FAILED:
    default: return state
  }
}
