import React from 'react'
import { Link } from 'react-router-dom'
import { CodeOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import style from './index.module.scss'
import { useUser } from '../../../hooks/useUser'

const ViewLogAction = ({ channel }) => {
  const { environment, getFilterFormFieldsValue } = useUser()
  const { channel: filterChannel } = getFilterFormFieldsValue()

  const logPageSearchParams = new URLSearchParams()
  const filter = {
    datasource: environment.toLowerCase(),
    channel: channel ?? filterChannel ?? 'GOOGLE_CHAT',
  }
  logPageSearchParams.set('filter', JSON.stringify(filter))
  const logPageUrl = `/conversation-management/logs?${logPageSearchParams.toString()}`

  return (
    <Tooltip title='View log'>
      <Link to={logPageUrl}>
        <CodeOutlined className={style['active-action']} />
      </Link>
    </Tooltip>
  )
}

ViewLogAction.propTypes = {
  channel: PropTypes.string.isRequired,
}

export default ViewLogAction
