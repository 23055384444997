import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Typography,
  Input,
  Alert,
  Divider,
  Spin,
} from 'antd'
import JsonPreview from '@core/components/JsonPreview'
import {
  useRequestReducer,
  ACTION_RESET,
  ACTION_REQUEST,
  ACTION_SUCCESS,
  ACTION_FAILED,
} from '@core/hooks/useRequest'
import style from './index.module.scss'
import api from '../../../api'

const TestFaqModal = ({
  visible,
  loading,
  closeModal,
}) => {
  const [state, dispatch] = useRequestReducer()

  const onSubmitTestSentence = async (text) => {
    dispatch({ type: ACTION_REQUEST })
    try {
      const { data } = await api.test({ text, scoreThreshold: 0.2, searchSize: 10 })
      dispatch({ type: ACTION_SUCCESS, payload: { data } })
    } catch (error) {
      dispatch({ type: ACTION_FAILED })
    }
  }

  useEffect(() => {
    dispatch({ type: ACTION_RESET })
  }, [visible, dispatch])

  if (!visible) return null

  return (
    <Modal
      title='Testing FAQ'
      maskClosable={false}
      visible={visible}
      centered
      onCancel={closeModal}
      destroyOnClose
      width={1024}
      footer={null}
      confirmLoading={loading}
    >
      <Input.Search placeholder='input search text' onSearch={onSubmitTestSentence} enterButton />
      {state.hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      {state.loading && <Spin />}
      {state.data && (
        <>
          <Divider />
          {state.data.domain && (
            <Alert
              className={style.evaluationResult}
              message={`Your input is matched "${state.data.domain}" with score ${state.data.domainConfidence.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
              type='warning'
              showIcon
            />
          )}
          <JsonPreview content={JSON.stringify(state.data, null, 2)} autoSize />
        </>
      )}
    </Modal>
  )
}

TestFaqModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default TestFaqModal
