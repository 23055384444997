import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { buildDomainIntentFetchPayload } from '@core/helpers'
import { INTENT_TREE } from '@core/store/constants'
import CoreApi from '@core/api'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  add: ({ name }) => {
    return async (dispatch) => {
      dispatch({ type: INTENT_TREE.DOMAIN.ADD.REQUEST })
      try {
        const response = await Api.addNewDomain({ name })
        if (response && response.status === 'success') {
          const updatedDomainsIntents = await CoreApi.getDomainsIntents()
          return dispatch({
            type: INTENT_TREE.DOMAIN.ADD.SUCCESS,
            payload: buildDomainIntentFetchPayload(updatedDomainsIntents.data),
          })
        }

        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({ type: INTENT_TREE.DOMAIN.ADD.FAILED })

        throw error
      }
    }
  },
  rename: (domain_id, { name }) => {
    return async (dispatch) => {
      dispatch({ type: INTENT_TREE.DOMAIN.RENAME.REQUEST })
      try {
        const response = await Api.renameDomain(domain_id, { name })

        if (response && response.status === 'success') {
          const updatedDomainsIntents = await CoreApi.getDomainsIntents()
          return dispatch({
            type: INTENT_TREE.DOMAIN.RENAME.SUCCESS,
            payload: buildDomainIntentFetchPayload(updatedDomainsIntents.data),
          })
        }

        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({ type: INTENT_TREE.DOMAIN.RENAME.FAILED })

        throw error
      }
    }
  },
  delete: (ids) => {
    return async (dispatch) => {
      dispatch({ type: INTENT_TREE.DOMAIN.DELETE.REQUEST })
      try {
        const response = await Api.deleteDomain({ ids })

        if (response && response.status === 'success') {
          const updatedDomainsIntents = await CoreApi.getDomainsIntents()
          return dispatch({
            type: INTENT_TREE.DOMAIN.DELETE.SUCCESS,
            payload: buildDomainIntentFetchPayload(updatedDomainsIntents.data),
          })
        }

        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({ type: INTENT_TREE.DOMAIN.DELETE.FAILED })

        throw error
      }
    }
  },
})
