import React, { useEffect } from 'react'
import {
  Form,
  Input,
  Button,
} from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import style from './index.module.scss'

const KeywordPhrasesForm = ({
  form,
  combinations,
  loading,
  onFinish,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { return form.resetFields() }, [combinations])
  const handleFinish = (values) => { return onFinish({ ...values }) }

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout='horizontal'
      initialValues={combinations}
    >
      <Form.Item
        label='Language'
        name='language'
        rules={[{ required: true, message: 'Please enter language' }]}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label='Keyword'
        name='keyword'
        rules={[{ required: true, message: 'Please input keyword!' }]}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Input disabled={loading} />
      </Form.Item>
      <h4>Phrase</h4>
      <Form.List name='phrases'>
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div style={{ display: 'flex' }} key={field.key}>
                    <div style={{ width: '5%' }}>
                      {`${index + 1}.`}
                    </div>
                    <div style={{ width: '85%' }}>
                      <Form.Item {...field}>
                        <Input placeholder='Phrase' readOnly={loading} />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined
                      className={style.btn__delete}
                      onClick={() => { return remove(field.name) }}
                    />
                  </div>
                )
              })}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => { return add() }}
                  style={{ width: '100%' }}
                  icon={<PlusOutlined />}
                  disabled={loading}
                >
                  Add phrase
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}

KeywordPhrasesForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any.isRequired,
  combinations: PropTypes.shape({
    id: PropTypes.string,
    keyword: PropTypes.string,
    phrases: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default KeywordPhrasesForm
