import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Modal,
  Button,
  Typography,
  Checkbox,
  Radio,
  notification,
} from 'antd'

import PropTypes from 'prop-types'
import { trimCsv } from '../../../helpers'

import Api from '../../../api'

const environmentOptions = ['DEVELOPMENT', 'CANARY', 'STAGING', 'PRODUCTION']
const clientGroupTypes = ['DEPLOYMENTS', 'MESSAGES', 'METRICS']

const rules = {
  name: [{ required: true, message: 'Name is required' }],
  type: [{ required: true, message: 'Type is required' }],
  environments: [{ required: true, message: 'Please select at least one' }],
}

const calculateInitialSelectMode = (value) => {
  if (value && value !== '*') return 'selected'
  return '*'
}

const AddNewClientGroupModal = ({ modalVisibility, setModalVisibility, initialValues = {}, triggerSearch }) => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasSentRequest, setHasSentRequest] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [capIdMode, setCapIdMode] = useState(calculateInitialSelectMode(initialValues?.capIds))
  const [countryOfEmploymentMode, setCountryOfEmploymentMode] = useState(
    calculateInitialSelectMode(initialValues?.countryOfEmployment),
  )

  const shouldDisableCapIdInput = capIdMode === '*'
  const shouldDisableCountryOfEmploymentInput = countryOfEmploymentMode === '*'

  useEffect(() => {
    if (modalVisibility === true) {
      setHasSentRequest(false)
      setHasError(false)
    }
  }, [modalVisibility])

  const capIdModeChangeHandler = ({ target: { value } }) => {
    setCapIdMode(value)
    if (value === '*') form.setFieldsValue({ capIds: '*' })
    else if (value === 'selected') form.setFieldsValue({ capIds: initialValues.capIds })
  }

  const countryOfEmploymentModeChangeHandler = ({ target: { value } }) => {
    setCountryOfEmploymentMode(value)
    if (value === '*') form.setFieldsValue({ countryOfEmployment: '*' })
    else if (value === 'selected') form.setFieldsValue({ countryOfEmployment: initialValues.countryOfEmployment })
  }

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasSentRequest(false)
    setHasError(false)
    form.resetFields()
  }

  const CapIdLabel = () => {
    return (
      <>
        <Typography.Text>CAP IDs</Typography.Text>
      &nbsp;&nbsp;&nbsp;
        <Radio.Group
          options={[{ label: 'Any', value: '*' }, { label: 'Selected', value: 'selected' }]}
          onChange={capIdModeChangeHandler}
          value={capIdMode}
          optionType='button'
          size='small'
        />
      &nbsp;&nbsp;&nbsp;
        {shouldDisableCapIdInput
          ? <Typography.Text type='secondary'>(will be saved as *)</Typography.Text>
          : (
            <>
              <Typography.Text type='secondary'>(separated by commas)</Typography.Text>
            &nbsp;&nbsp;&nbsp;
              <Typography.Text type='secondary'>(e.g., ABCD,AMEX)</Typography.Text>
            </>
          )}
      </>
    )
  }

  const CountryOfEmploymentLabel = () => {
    return (
      <>
        <Typography.Text>Country of Employment</Typography.Text>
      &nbsp;&nbsp;&nbsp;
        <Radio.Group
          options={[{ label: 'Any', value: '*' }, { label: 'Selected', value: 'selected' }]}
          onChange={countryOfEmploymentModeChangeHandler}
          value={countryOfEmploymentMode}
          optionType='button'
          size='small'
        />
      &nbsp;&nbsp;&nbsp;
        {shouldDisableCountryOfEmploymentInput
          ? <Typography.Text type='secondary'>(will be saved as *)</Typography.Text>
          : (
            <>
              <Typography.Text type='secondary'>(2-letter separated by commas)</Typography.Text>
            &nbsp;&nbsp;&nbsp;
              <Typography.Text type='secondary'>(e.g., th,fr)</Typography.Text>
            </>
          )}
      </>
    )
  }

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    if (hasError) setHasError(false)

    setConfirmLoading(true)
    let response = null
    try {
      const values = await form.validateFields()
      const { environments, capIds, countryOfEmployment, ...rest } = values

      const data = values.environments.map((environment) => {
        return {
          ...rest,
          capIds: trimCsv(capIds),
          countryOfEmployment: trimCsv(countryOfEmployment),
          environment,
        }
      })

      if (values) {
        response = await Api.ClientGroups.insertMany(data)
        if (response?.data?.errorMessage?.toUpperCase() === 'CONFLICT') {
          const conflictError = Error()
          conflictError.status = 409
          throw conflictError
        }
        if (response) {
          form.resetFields()
          setConfirmLoading(false)
          setHasSentRequest(true)
          closeModal()
          triggerSearch()
          // return response
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)
      setConfirmLoading(false)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404].indexOf(error.status) !== -1) {
        setHasError(true)
        notification.error({
          duration: null,
          message: 'Error',
          description: 'Something went wrong',
        })
      } else if (error.status && [400].indexOf(error.status) !== -1) {
        setHasError(true)
        notification.error({
          duration: null,
          message: 'Error',
          description: 'Make sure CAP IDs, and Countries of Employment are in correct format',
        })
      } else if (error.status && [409].indexOf(error.status) !== -1) {
        setHasError(true)
        notification.error({
          duration: null,
          message: 'Error',
          description: (
            <>
              <Typography.Text>
                Record(s) with the same Type, CAP IDs, and Countries of Employment already exist(s) under the name(s)
              </Typography.Text>
              <br />
              {(response?.data?.items || []).map((item) => {
                return (
                  <>
                    <Typography.Text>-&nbsp;</Typography.Text>
                    <Typography.Text strong>
                      {`${item.name}`}
                    &nbsp;
                    </Typography.Text>
                    <Typography.Text>on&nbsp;</Typography.Text>
                    <Typography.Text strong>{`${item.environment}`}</Typography.Text>
                    <br />
                  </>
                )
              })}
              <br />
              <Typography.Text>
                The client group was not added to any environments,
                please uncheck the invalid environment before trying to create a client group again.
              </Typography.Text>
            </>
          ),
        })
      }
    }
  }

  return (
    <Modal
      title='Add new client group'
      visible={modalVisibility}
      onOk={onSubmit}
      okText='Submit'
      confirmLoading={confirmLoading}
      maskClosable={false}
      onCancel={closeModal}
      footer={hasSentRequest ? null : [
        <Button key='back' onClick={closeModal}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={confirmLoading} onClick={onSubmit}>
          Submit
        </Button>,
      ]}
      centered
      width='75vw'
      bodyStyle={{
        height: '75vh',
      }}
      style={{
        transition: 'none',
        animationDuration: '0s',
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form__new_client_group'
        initialValues={initialValues}
      >
        <Form.Item
          name='name'
          label='Name'
          rules={rules.name}
        >
          <Input
            placeholder='Add new client group'
          />
        </Form.Item>
        <Form.Item
          label='Description'
          name='description'
        >
          <Input.TextArea
            name='description'
            placeholder='Additional data/information'
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item
          name='type'
          label='Type'
          rules={rules.type}
        >
          <Radio.Group size='small'>
            {clientGroupTypes.map((item) => {
              return (
                <Radio.Button key={item} value={item}>
                  {item}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name='capIds'
          label={<CapIdLabel shouldDisableCapIdInput={shouldDisableCapIdInput} />}
        >
          <Input placeholder={capIdMode === '*' ? '*' : 'Separated by commas'} disabled={shouldDisableCapIdInput} />
        </Form.Item>
        <Form.Item name='countryOfEmployment' label={<CountryOfEmploymentLabel />}>
          <Input placeholder={countryOfEmploymentMode === '*' ? '*' : 'Separated by commas'} disabled={shouldDisableCountryOfEmploymentInput} />
        </Form.Item>
        <Form.Item
          label='Environment(s)'
          name='environments'
          rules={rules.environments}
        >
          <Checkbox.Group options={environmentOptions} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

AddNewClientGroupModal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf({}),
}

AddNewClientGroupModal.defaultProps = {
  initialValues: {},
}

export default AddNewClientGroupModal
