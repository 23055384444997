import { useSelector } from 'react-redux'

export const useDomainLookup = (domainId) => {
  return useSelector(({ core }) => {
    if (!domainId) {
      return {
        domain: null,
        intents: null,
      }
    }

    const { domainIntent: { rawLookup } } = core

    return {
      domains: rawLookup.domains[domainId],
      intents: rawLookup.lookup[domainId],
    }
  })
}

export const useIntentLookup = (intentId) => {
  return useSelector(({ core }) => {
    if (!intentId) {
      return {
        parentDomain: null,
        intent: null,
        siblingIntents: null,
      }
    }

    const { domainIntent: { rawLookup } } = core

    const {
      domains,
      intents,
      lookup,
    } = rawLookup

    const intent = intents[intentId]
    if (!intent) {
      return {
        parentDomain: null,
        intent: null,
        siblingIntents: null,
      }
    }
    return {
      parentDomain: domains[intent.domain_id],
      intent,
      siblingIntents: lookup[intent.domain_id].map((lookupIntentId) => { return intents[lookupIntentId] }),
    }
  })
}
