import React from 'react'
import { Descriptions } from 'antd'
import {
  sentenceIdType,
  generalizedType,
  hasErrorType,
  dataType,
} from '../../../types/SentenceDetails'

const SentenceDetails = ({
  sentenceId,
  generalized,
  hasError,
  data,
}) => {
  return (
    <Descriptions bordered column={1}>
      <Descriptions.Item label='UUID'>{sentenceId || 'N/A'}</Descriptions.Item>
      <Descriptions.Item label='Original'>{generalized?.original || 'N/A'}</Descriptions.Item>
      <Descriptions.Item label='Generalized'>{hasError.extraction ? generalized.generalized : (generalized?.generalized || 'N/A')}</Descriptions.Item>
      <Descriptions.Item label='Source'>{data?.source || 'N/A'}</Descriptions.Item>
    </Descriptions>
  )
}

SentenceDetails.propTypes = {
  sentenceId: sentenceIdType.isRequired,
  generalized: generalizedType.isRequired,
  hasError: hasErrorType.isRequired,
  data: dataType.isRequired,
}

export default SentenceDetails
