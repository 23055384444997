import callApi from '@core/utils/http-client'

const getMany = (url, params) => {
  return callApi({
    url,
    method: 'get',
    params,
  })
}

const getOne = (url, id) => {
  return callApi({
    url: `${url}/${id}`,
    method: 'get',
  })
}

const addOne = (url, data) => {
  return callApi({
    url,
    method: 'post',
    data,
  })
}

const updateOne = (url, id, data) => {
  return callApi({
    url: `${url}/${id}`,
    method: 'put',
    data,
  })
}

const deleteOne = (url, id) => {
  return callApi({
    url: `${url}/${id}`,
    method: 'delete',
  })
}

export default {
  // FAQs
  getFaqs: (params) => { return getMany('/api/v1/faqs', params) },
  getFaq: (id) => { return getOne('/api/v1/faqs', id) },
  addFaq: (data) => { return addOne('/api/v1/faqs', data) },
  updateFaq: (id, data) => { return updateOne('/api/v1/faqs', id, data) },
  deleteFaq: (id) => { return deleteOne('/api/v1/faqs', id) },
  // FAQs' Categories
  getCategories: (params) => { return getMany('/api/v1/faqs-categories', params) },
  getCategory: (id) => { return getOne('/api/v1/faqs-categories', id) },
  addCategory: (data) => { return addOne('/api/v1/faqs-categories', data) },
  updateCategory: (id, data) => { return updateOne('/api/v1/faqs-categories', id, data) },
  deleteCategory: (id) => { return deleteOne('/api/v1/faqs-categories', id) },
  // Choice categories
  getChoiceCategories: (params) => { return getMany('/api/v1/faqs-choice-categories', params) },
  getChoiceCategory: (id) => { return getOne('/api/v1/faqs-choice-categories', id) },
  addChoiceCategory: (data) => { return addOne('/api/v1/faqs-choice-categories', data) },
  updateChoiceCategory: (id, data) => { return updateOne('/api/v1/faqs-choice-categories', id, data) },
  deleteChoiceCategory: (id) => { return deleteOne('/api/v1/faqs-choice-categories', id) },
  // Choices
  getChoices: (params) => { return getMany('/api/v1/faqs-choices', params) },
  getChoice: (id) => { return getOne('/api/v1/faqs-choices', id) },
  addChoice: (data) => { return addOne('/api/v1/faqs-choices', data) },
  updateChoice: (id, data) => { return updateOne('/api/v1/faqs-choices', id, data) },
  deleteChoice: (id) => { return deleteOne('/api/v1/faqs-choices', id) },
  // Versions
  getVersions: (params) => { return getMany('/api/v1/faqs-versions', params) },
  getVersion: (id) => { return getOne('/api/v1/faqs-versions', id) },
  addVersion: (data) => { return addOne('/api/v1/faqs-versions', data) },
  updateVersion: (id, data) => { return updateOne('/api/v1/faqs-versions', id, data) },
  deleteVersion: (id) => { return deleteOne('/api/v1/faqs-versions', id) },
  duplicateVersion: (id) => {
    return callApi({
      url: `/api/v1/faqs-versions/${id}/duplicate`,
      method: 'post',
    })
  },
  reindexVersion: (id) => {
    return callApi({
      url: `/api/v1/faqs-versions/${id}/reindex`,
      method: 'post',
    })
  },
  selectVersion: (id) => {
    return callApi({
      url: `/api/v1/faqs-versions/${id}/select`,
      method: 'post',
    })
  },
  similaritySearch: ({ text, scoreThreshold, searchSize }) => {
    return callApi({
      url: '/api/v1/faqs/similarity-search',
      method: 'post',
      data: {
        text,
        scoreThreshold,
        searchSize,
      },
    })
  },
  test: ({ text, scoreThreshold, searchSize }) => {
    return callApi({
      url: '/api/v1/faqs/test',
      method: 'post',
      data: {
        text,
        scoreThreshold,
        searchSize,
      },
    })
  },
  // Combinations
  getCombinations: (params) => { return getMany('/api/v1/faq-combinations', params) },
  getCombination: (id) => { return getOne('/api/v1/faq-combinations', id) },
  addCombination: (data) => { return addOne('/api/v1/faq-combinations', data) },
  updateConbination: (id, data) => { return updateOne('/api/v1/faq-combinations', id, data) },
  deleteCombination: (id) => { return deleteOne('/api/v1/faq-combinations', id) },
}
