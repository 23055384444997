import React, { useContext } from 'react'
import { Form, Select, Divider } from 'antd'
import { EmailAutomationsContext } from '../../../../context'

const ENVIRONMENT_OPTIONS = ['none', 'DEVELOPMENT', 'CANARY', 'STAGING', 'PRODUCTION']

const ByEmailEnvironment = () => {
  const { datasource } = useContext(EmailAutomationsContext)
  return (
    <>
      <Divider>
        <small><strong>By Data Source ENV</strong></small>
      </Divider>
      <Form.Item
        name='emailEnvironment'
        initialValue={datasource}
        required={false}
        rules={[
          {
            required: true,
            message: 'Please Select Data Source ENV!',
          },
        ]}
        label='Select Data Source environment to be display'
      >
        <Select
          mode='single'
          placeholder='Select Data Source ENV'
          allowClear
          showSearch
          options={ENVIRONMENT_OPTIONS.map((env) => { return { label: env, value: env.toLowerCase() } })}
        />
      </Form.Item>
    </>
  )
}

export default ByEmailEnvironment
