import React, { useState, useEffect } from 'react'
import {
  Layout,
  Tabs, Radio, Empty,
} from 'antd'
import dayjs from 'dayjs'
import { filterEmailFromSearchParams } from '@core/helpers/searchParams'
import { getEnvironmentFromSearchParams, getFromSearchParamsByKey } from '../../helpers'
import Api from '../../api'
import LeftSider from './LeftSider'
import DataTab from './DataTab'
import { BKK, UTC } from '../../constants'

const { Content } = Layout

const { TabPane } = Tabs

const DATA_TAB_KEY = 'data'
const ENVIRONMENTS = ['DEVELOPMENT', 'CANARY', 'STAGING', 'PRODUCTION']
const TIMEZONES = {
  UTC: {
    value: UTC,
    formatter: (value) => { return dayjs(value).utc().format('DD/MM/YYYY HH:mm:ss') },
  },
  BKK: {
    value: BKK,
    formatter: (value) => { return dayjs(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss') },
  },
}

const defaultEnvironmentFilter = 'PRODUCTION'
const initialFilterValues = { environment: defaultEnvironmentFilter }

const Component = () => {
  const [availableCapIdsAndCountryOfEmployment, setAvailableCapIdsAndCountryOfEmployment] = useState({
    availableCapIds: [],
    availableCountryOfEmployment: [],
  })

  const findAvailableCapIdsAndCountryOfEmployment = async () => {
    const result = await Api.ClientGroups.findAvailableCapIdsAndCountryOfEmployment()
    setAvailableCapIdsAndCountryOfEmployment(result.data)
  }

  const [
    timeZone,
    // setTimeZone
  ] = useState(TIMEZONES.UTC.value)
  const [tab, setTab] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : DATA_TAB_KEY,
  )
  const [filter, setFilter] = useState(getFromSearchParamsByKey('filter'))
  const [environment, setEnvironment] = useState(getEnvironmentFromSearchParams() || defaultEnvironmentFilter)

  const setInitialFilterFromSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filterAsString = searchParams.get('filter')
    if (!filterAsString) {
      setFilter(initialFilterValues)
    } else {
      try {
        const parsedFilter = JSON.parse(filterAsString)
        if (typeof parsedFilter === 'object' && parsedFilter !== null) {
          setFilter(Object.keys(parsedFilter).length ? parsedFilter : initialFilterValues)
        } else {
          throw new Error('parsedFilter is not an object, throw')
        }
      } catch (error) {
        setFilter(initialFilterValues)
      }
    }
  }

  const updateSearchParamsByFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filteredParams = filterEmailFromSearchParams(filter)
    searchParams.set('filter', JSON.stringify(filteredParams))
    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState(null, '', newRelativePathQuery)
  }

  useEffect(setInitialFilterFromSearchParams, [])

  useEffect(updateSearchParamsByFilter, [filter])

  useEffect(() => {
    findAvailableCapIdsAndCountryOfEmployment()
  }, [])

  const search = (newFilter) => {
    newFilter.page = 1
    setFilter({ ...newFilter, _: Math.random(), environment })
  }

  const triggerSearch = () => {
    search(filter)
    findAvailableCapIdsAndCountryOfEmployment()
  }

  const handleTabChange = (newTab) => {
    window.location.hash = `#${newTab}`
    setTab(newTab)
  }

  const handleEnvironmentChange = (e) => {
    setEnvironment(e.target.value)
  }

  useEffect(() => {
    search({ ...filter })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment])

  const handlePaginationChange = (page, perPage) => {
    if (filter.perPage !== perPage) {
      setFilter((prevFilter) => { return { ...prevFilter, page: 1, perPage } })
    } else {
      setFilter((prevFilter) => { return { ...prevFilter, page, perPage } })
    }
  }

  return (
    <>
      {filter && (
        <LeftSider
          initialFilterValues={filter}
          onSearch={search}
          triggerSearch={triggerSearch}
          availableCapIdsAndCountryOfEmployment={availableCapIdsAndCountryOfEmployment}
        />
      )}
      <Content>
        <Tabs
          activeKey={tab}
          onChange={handleTabChange}
          tabBarExtraContent={(
            <Radio.Group size='small' onChange={handleEnvironmentChange} defaultValue={environment}>
              {ENVIRONMENTS.map((env, index) => {
                return (
                  <Radio.Button key={index} value={env}>
                    {env.charAt(0).toUpperCase() + env.substring(1, env.length)}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          )}
        >
          <TabPane key={DATA_TAB_KEY} tab='Data'>
            {filter ? (
              <DataTab
                filter={filter}
                timeFormatter={TIMEZONES[timeZone]?.formatter}
                handlePaginationChange={handlePaginationChange}
                triggerSearch={triggerSearch}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
        </Tabs>
      </Content>
    </>
  )
}

export default Component
