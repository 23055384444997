import React from 'react'
import { useSelector } from 'react-redux'
import { Form, Divider, Space, Radio } from 'antd'
import { setFilterDateRangeType, dateRangeType } from '../../../types/dateRangeFilter'

const { Item } = Form

const DateRangeFilter = ({ dateRange, setFilterDateRange }) => {
  const { dateRanges } = useSelector(({ data }) => { return { ...data.searches } })

  const handleSizeChange = (value) => { return setFilterDateRange(value.target.value) }

  return (
    <>
      <Divider>
        <small><strong>By Date Range</strong></small>
      </Divider>
      <Space direction='vertical'>
        <Item>
          <Radio.Group value={dateRange} onChange={handleSizeChange} size='small'>
            {dateRanges.map((item, index) => { return <Radio.Button key={index} value={item.value}>{item.title}</Radio.Button> })}
          </Radio.Group>
        </Item>
      </Space>
    </>
  )
}

DateRangeFilter.propTypes = {
  setFilterDateRange: setFilterDateRangeType.isRequired,
  dateRange: dateRangeType.isRequired,
}

export default DateRangeFilter
