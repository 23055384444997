import { useReducer, useRef, useEffect } from 'react'
import StoreUtils from '@core/utils/store'
import { reHashIndex } from '@core/helpers'
import Api from '../api'

const INITIAL_STATE = {
  loading: false,
  hasError: false,
  error: null,
  data: {
    items: [],
    hash: {},
  },
  meta: {
    perPage: 50,
    page: 1,
  },
}

export const FAQ_VERSIONS = {
  FETCH: StoreUtils.createActionStatus('FAQ_VERSIONS/FETCH'),
  FETCH_ONE: StoreUtils.createActionStatus('FAQ_VERSIONS/FETCH_ONE'),
}

export const reducer = (state, action) => {
  switch (action.type) {
    case FAQ_VERSIONS.FETCH.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
      }
    }
    case FAQ_VERSIONS.FETCH.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: {
          items: action.payload.items,
          hash: action.payload.hash,
        },
        meta: action.payload.meta,
      }
    }
    case FAQ_VERSIONS.FETCH.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      }
    }
    default: throw new Error('MISSING REDUCER EVENT')
  }
}

export const useFaqVersionsReducer = () => { return useReducer(reducer, INITIAL_STATE) }

export const useFetchFaqVersionsOnLoad = (dispatch) => {
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    const fetchFaqs = async () => {
      dispatch({ type: FAQ_VERSIONS.FETCH.REQUEST })
      try {
        const response = await Api.getVersions()

        if (response && response.status === 'success') {
          dispatch({
            type: FAQ_VERSIONS.FETCH.SUCCESS,
            payload: {
              items: response.data.items,
              hash: reHashIndex(response.data.items),
              meta: response.data.meta,
            },
          })

          return response.data
        }
        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({
          type: FAQ_VERSIONS.FETCH.FAILED,
          error,
        })

        throw error
      }
    }

    fetchFaqs()

    return () => {
      mounted.current = false
    }
  }, [dispatch])
}
