import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Form,
  Input,
  notification,
} from 'antd'

import Api from '../../../../../api'

const { evaluateByTestCollectionIds } = Api.EndToEndTestCollection

const formRules = {
  jobName: [
    {
      required: true,
      message: 'Job Name is required',
    },
  ],
}

const RunSelectedTestCollectionButton = ({
  buttonProps,
  afterSuccessHandler,
  initialValues,
  modalTitle,
  selectedTestCollectionIds,
  noTestCasesHandler,
}) => {
  const { size, type, text, disabled } = buttonProps
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const submitHandlerWrapper = (testCollectionIds) => {
    return async () => {
      try {
        const values = await form.validateFields()
        await evaluateByTestCollectionIds({ jobName: values.jobName, ids: testCollectionIds })
        setIsModalVisible(false)
        if (afterSuccessHandler) {
          await afterSuccessHandler()
        }
      } catch (error) {
        if (error.data?.status === 'Forbidden') {
          noTestCasesHandler()
          return
        }

        notification.error({
          message: 'Error',
          description: error.message || 'Something went wrong',
        })
      }
    }
  }

  const openModalHandler = () => {
    setIsModalVisible(true)
  }

  const closeModalHandler = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button size={size} type={type} disabled={disabled} onClick={openModalHandler}>{text}</Button>
      {isModalVisible && (
        <Modal
          visible
          okText='Save'
          title={modalTitle}
          onOk={submitHandlerWrapper(selectedTestCollectionIds)}
          onCancel={closeModalHandler}
        >
          <Form
            layout='vertical'
            form={form}
            initialValues={initialValues}
          >
            <Form.Item
              label='Job Name'
              name='jobName'
              rules={formRules.jobName}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}

RunSelectedTestCollectionButton.propTypes = {
  buttonProps: PropTypes.shape({
    size: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  afterSuccessHandler: PropTypes.func,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
  }),
  modalTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  selectedTestCollectionIds: PropTypes.arrayOf(PropTypes.string),
  noTestCasesHandler: PropTypes.func,
}

RunSelectedTestCollectionButton.defaultProps = {
  buttonProps: {
    size: 'small',
    type: 'secondary',
    text: 'Run Selected',
    disabled: false,
  },
  afterSuccessHandler: () => { },
  noTestCasesHandler: () => { },
  initialValues: {
    description: null,
  },
  modalTitle: 'Run Selected Collection',
  selectedTestCollectionIds: [],
}

export default RunSelectedTestCollectionButton
