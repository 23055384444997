import PropTypes from 'prop-types'

const {
  shape,
  string,
  arrayOf,
} = PropTypes

export const versionDetailsType = shape({
  id: string,
  note: string,
  status: arrayOf(string),
  created_at: string,
})

