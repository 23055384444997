import { Amplify } from 'aws-amplify'

export const setupAwsAmplify = () => {
  console.log('### setupAwsAmplify ###')
  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || 'us-east-1_hvaTMpTe0',
      userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID || '3hbm3vrua5uoaf6j2ia46mphpn',
      storage: window.sessionStorage,
      oauth: {
        domain: process.env.REACT_APP_AWS_AMPLIFY_OAUTH_DOMAIN || '30stf-louise.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: `${process.env.REACT_APP_OKTA_REDIRECT}/okta-signin-success`,
        redirectSignOut: `${process.env.REACT_APP_OKTA_REDIRECT}/`,
        responseType: 'code',
      },
    },
  })
}

export default setupAwsAmplify
