import React from 'react'
import { Form, DatePicker } from 'antd'

const ExtractorRelativeDateInput = () => {
  return (
    <Form.Item name='relative_date' label='Relative Date'>
      <DatePicker format=' MM/DD/YYYY' />
    </Form.Item>
  )
}

export default ExtractorRelativeDateInput
