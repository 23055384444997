import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography, Spin, Alert } from 'antd'

import Api from '../../../../../api'
import { EmailAutomationsContext } from '../../../../../context'
import { ViewEmailModalContext, FormDirtyContext } from '../../context'

import { InitialAccuracyCheckForm, ExistingAccuracyCheckForm } from './Form'

const LinguisticAccuracyCheck = ({ initialValues, makeRequest }) => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const { setIsFormDirty } = useContext(FormDirtyContext)
  const { emailId: id } = useContext(ViewEmailModalContext)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  // NOTE: CHT-3019
  // to check if this email already has accuracy data
  // we check the intent_correctness field
  // if it returns null meaning there is no accuracy data, we need to let user enter data
  // otherwise, it means we need to disable form, because it already had data
  // *this logic 'might' be changed
  const { intent_correctness } = initialValues
  const isThisFirstAccuracyCheck = intent_correctness === null // doesn't really need tbh

  const onFinish = async (values) => {
    setLoading(true)
    setHasError(false)

    const data = {
      emailContent: values.email_content,
      emailStyle: values.email_style,
      arrangerEmail: values.arranger_email,
      attachment: values.attachment,
      userError: values.user_error,
      preprocessingCorrectness: values.preprocessing_correctness,
      intentCorrectness: values.intent_correctness,
      specificIntentCorrectness: values.specific_intent_correctness,
      detailsCorrectness: values.details_correctness,
    }

    try {
      const response = await Api.EmailAnalysisAccuracyCheck.insert({
        version: currentVersion,
        datasource,
        id,
        data,
      })

      if (response.status === 'success') {
        setIsFormDirty(false)
        await makeRequest()
      }
    } catch (error) {
      setHasError(true)
    }

    setLoading(false)
  }

  return (
    // NOTES: Needs to have div, make it as single block child
    <div>
      <Typography.Text strong>Linguistic Accuracy Check</Typography.Text>
      {hasError && (
        <Alert
          showIcon
          message='Cannot create accuracy check'
          type='error'
          closable
        />
      )}
      <Spin spinning={loading}>
        {
          isThisFirstAccuracyCheck
            ? (<InitialAccuracyCheckForm onFinish={onFinish} />)
            : (<ExistingAccuracyCheckForm initialValues={initialValues} />)
        }
      </Spin>
    </div>
  )
}

LinguisticAccuracyCheck.defaultProps = {
  initialValues: {
    email_content: 'NORMAL',
    email_style: null,
    arranger_email: null,
    attachment: null,
    user_error: null,
    preprocessing_correctness: null,
    intent_correctness: null,
    specific_intent_correctness: null,
    details_correctness: null,
  },
}

LinguisticAccuracyCheck.propTypes = {
  initialValues: PropTypes.shape({
    email_content: PropTypes.string,
    email_style: PropTypes.string,
    arranger_email: PropTypes.string,
    attachment: PropTypes.string,
    user_error: PropTypes.string,
    preprocessing_correctness: PropTypes.string,
    intent_correctness: PropTypes.string,
    specific_intent_correctness: PropTypes.string,
    details_correctness: PropTypes.string,
  }),
  makeRequest: PropTypes.func.isRequired,
}

export default LinguisticAccuracyCheck
