// FOR YARS
import { CLUSTER } from '../constants'

const INITIAL_STATE = {
  status: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLUSTER.SCALE_DOWN_WORKER.SUCCESS:
    case CLUSTER.SCALE_UP_WORKER.SUCCESS: {
      return state
    }

    case CLUSTER.GET_WORKER_HOST_STATS.SUCCESS: {
      return {
        ...state,
        status: { ...action.payload },
      }
    }

    case CLUSTER.SCALE_DOWN_WORKER.REQUEST:
    case CLUSTER.SCALE_DOWN_WORKER.FAILED:
    case CLUSTER.SCALE_UP_WORKER.REQUEST:
    case CLUSTER.SCALE_UP_WORKER.FAILED:
    case CLUSTER.GET_WORKER_HOST_STATS.REQUEST:
    case CLUSTER.GET_WORKER_HOST_STATS.FAILED:
    default: return state
  }
}
