import React from 'react'
import { Typography, notification } from 'antd'
import PropTypes from 'prop-types'
import style from './index.module.scss'
import { OnConversationPropTypes } from '../../../UserPropTypes'
import { useUser } from '../../../../../hooks/useUser'
import api from '../../../../../api'
import { transformInputChannel } from '../../../../../utils/transformInputChannel'
import getJobStatus from './getJobStatus'
import ConfirmModal from './ConfirmModal'

const ConversationTab = ({ onConversation, channel }) => {
  const { environment } = useUser()

  const closeConversation = async () => {
    const { data } = await api.User.closeConversation(
      {
        conversationId: onConversation.data?.conversationId,
        channel: transformInputChannel(channel),
      },
      { datasource: environment.toLowerCase() },
    )

    const jobStatus = await getJobStatus(data.jobId, environment.toLowerCase())

    if (jobStatus === 'SUCCESS') {
      notification.success({ message: '[Close Conversation] Success!' })
    } else {
      notification.error({ message: `[Close Conversation] ${jobStatus}` })
    }
  }

  return (
    <>
      <div className={style.border}>
        {onConversation?.status && (
          <>
            <div>
              Conversation ID:
              {' '}
              <Typography.Text copyable>{onConversation?.data?.conversationId}</Typography.Text>
            </div>
            <div>Status: OPEN</div>
          </>
        )}
        {!onConversation?.status && (
          <div>
            No open conversations.
          </div>
        )}
      </div>
      <div className={style.pt16}>
        <ConfirmModal
          modalTitle='Confirm close conversation?'
          buttonTitle='Close conversation'
          onConfirm={closeConversation}
          disabled={!onConversation?.status}
        />
      </div>
    </>
  )
}

ConversationTab.propTypes = {
  onConversation: OnConversationPropTypes.isRequired,
  channel: PropTypes.string.isRequired,
}

export default ConversationTab
