export const trimCsv = (csv) => { return csv?.split(',')?.map((o) => { return o.trim() })?.join(',') }

export const getFromSearchParamsByKey = (key) => {
  const searchParams = new URLSearchParams(window.location.search)
  const filterAsString = searchParams.get(key)
  try {
    const parsedFilter = JSON.parse(filterAsString)
    return parsedFilter
  } catch (error) {
    return undefined
  }
}

export const getEnvironmentFromSearchParams = () => {
  return getFromSearchParamsByKey('filter')?.environment
}
