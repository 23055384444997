import React from 'react'
import PropTypes from 'prop-types'
import { Table, PageHeader, Space, Typography, Button, Popconfirm } from 'antd'
import { sortTextOrNull } from '@core/helpers'
import { CHANNEL } from '../../../../constants'

const ACTION_DELETE = 'delete'
const ACTION_RELEASE = 'release'

const IssueTable = ({ onProcessQueue, onCloseConversation, pendingNotificationsData, activeNotificationsData, timeFormatter }) => {
  const pendingColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => {
        return (
          <Space size={10}>
            <Typography.Text copyable>{email}</Typography.Text>
          </Space>
        )
      },
      sorter: (a, b) => { return sortTextOrNull(a.email, b.email) },
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 250,
      sorter: (a, b) => { return new Date(a.created_at).getTime() - new Date(b.created_at).getTime() },
      render: (createdAt) => {
        if (timeFormatter) {
          return <Typography.Text>{timeFormatter(createdAt)}</Typography.Text>
        }
        return <Typography.Text>{createdAt}</Typography.Text>
      },
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'email',
      width: 120,
      sorter: (a, b) => { return sortTextOrNull(a.email, b.email) },
    },
    {
      title: 'Actions',
      key: 'email',
      width: 300,
      render: (item) => {
        return (
          <Space size={5}>
            <Popconfirm
              placement='topRight'
              title={(
                <>
                  <div>
                    <Typography.Text>Release pending notifications in queue?</Typography.Text>
                  </div>
                  <Typography.Text>Confirm to release all of the pending notifications on &nbsp;</Typography.Text>
                  <Typography.Text strong>{`${item.email}`}</Typography.Text>
                </>
              )}
              onConfirm={() => { return onProcessQueue(ACTION_RELEASE, item.email) }}
              okText='Yes'
              cancelText='No'
            >
              <Button>Send</Button>
            </Popconfirm>
            <Popconfirm
              placement='topRight'
              title={(
                <>
                  <div>
                    <Typography.Text>Delete pending notifications in queue?</Typography.Text>
                  </div>
                  <Typography.Text>Confirm to delete all of the pending notifications on &nbsp;</Typography.Text>
                  <Typography.Text strong>{`${item.email}`}</Typography.Text>
                </>
              )}
              onConfirm={() => { return onProcessQueue(ACTION_DELETE, item.email) }}
              okText='Yes'
              cancelText='No'
            >
              <Button>Delete</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const activeColumns = [
    {
      title: 'Conversation ID',
      dataIndex: 'conversation_id',
      key: 'conversation_id',
      width: 400,
      render: (id) => {
        return (
          <Space size={10}>
            <Typography.Text copyable>{id}</Typography.Text>
          </Space>
        )
      },
      sorter: (a, b) => { return sortTextOrNull(a.conversation_id, b.conversation_id) },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => {
        return (
          <Space size={10}>
            <Typography.Text copyable>{email}</Typography.Text>
          </Space>
        )
      },
      sorter: (a, b) => { return sortTextOrNull(a.email, b.email) },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: (a, b) => { return sortTextOrNull(a.status, b.status) },
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      width: 150,
      sorter: (a, b) => { return sortTextOrNull(a.channel, b.channel) },
    },
    {
      title: 'Actived At',
      dataIndex: 'active',
      key: 'active',
      width: 250,
      sorter: (a, b) => { return new Date(a.active).getTime() - new Date(b.active).getTime() },
      render: (activedAt) => {
        if (timeFormatter) {
          return <Typography.Text>{timeFormatter(activedAt)}</Typography.Text>
        }
        return <Typography.Text>{activedAt}</Typography.Text>
      },
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'conversation_id',
      width: 120,
      sorter: (a, b) => { return sortTextOrNull(a.hours, b.hours) },
    },
    {
      title: 'Actions',
      key: 'conversation_id',
      width: 300,
      render: (item) => {
        return (
          <Space size={5}>
            <Popconfirm
              placement='topRight'
              title={(
                <>
                  <div>
                    <Typography.Text>Close PTC conversation?</Typography.Text>
                  </div>
                  <Typography.Text>Confirm to close PTC conversation&nbsp;</Typography.Text>
                  <Typography.Text strong>{`${item.conversation_id}`}</Typography.Text>
                </>
              )}
              onConfirm={() => { return onCloseConversation(CHANNEL.DB[item.channel], item.conversation_id) }}
              okText='Yes'
              cancelText='No'
            >
              <Button>Close</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Active Case'
        subTitle='List of users who have active notification longer than expected and incorrectly close by the autoclose timer'
      />
      <Table
        rowKey={(row) => { return row.conversation_id }}
        columns={activeColumns}
        dataSource={activeNotificationsData}
        pagination={false}
      />
      <PageHeader
        className='site-page-header'
        title='Pending Case'
        subTitle='List of users who have pending notification(s) stuck in the queue and have not deliver to the user'
      />
      <Table
        rowKey={(row) => { return row.email }}
        columns={pendingColumns}
        dataSource={pendingNotificationsData}
        pagination={false}
      />
    </>
  )
}

IssueTable.defaultProps = {
  pendingNotificationsData: [],
  activeNotificationsData: [],
}

IssueTable.propTypes = {
  onProcessQueue: PropTypes.func.isRequired,
  onCloseConversation: PropTypes.func.isRequired,
  pendingNotificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.string,
      created_at: PropTypes.string,
      email: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
  activeNotificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.string,
      channel: PropTypes.string,
      conversation_id: PropTypes.string,
      email: PropTypes.string,
      hours: PropTypes.number,
      minutes: PropTypes.number,
      status: PropTypes.string,
    }),
  ),
  timeFormatter: PropTypes.func.isRequired,
}

export default IssueTable
