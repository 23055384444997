import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Button,
  Divider,
  Form,
} from 'antd'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import style from './index.module.scss'

const { Sider } = Layout

const LeftSider = ({ onSubmit }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values) => {
    onSubmit(values)
  }

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={480}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Training</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
        >
          <Button type='primary' htmlType='submit' block>
            Train (Run)
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LeftSider
