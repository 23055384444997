import React from 'react'
import PropTypes from 'prop-types'
import style from './index.module.scss'

const PhrasesColumn = ({ phrases }) => {
  return (
    <ul className={style.ul__Phrases}>
      {phrases.map((phrase, index) => {
        return (
          <li key={index}>
            <div>
              <span>{phrase}</span>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

PhrasesColumn.propTypes = {
  phrases: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PhrasesColumn
