import React, { useEffect } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom'

import InternationalActions from '@core/store/actions/Internationalize'
import VersionsActions from './store/actions/Versions'

import Versions from './pages/DataSets/Versions'
import AddNewVersion from './pages/DataSets/AddNewVersion'
import ShowVersion from './pages/DataSets/ShowVersion'
import EditVersion from './pages/DataSets/EditVersion'

import I18nVersions from './pages/InternationalizeVersions'
import ViewI18nVersion from './pages/InternationalizeVersions/ViewVersion'
import EditI18nVersion from './pages/InternationalizeVersions/EditVersion'
import CloneVersion from './pages/InternationalizeVersions/CloneVersion'

import FaqVersions from './pages/FaqVersions'
import ViewFaqVersion from './pages/FaqVersions/ViewVersion'
import EditFaqVersion from './pages/FaqVersions/EditVersion'

const DatasetVersion = () => {
  const { path } = useRouteMatch()

  useEffect(() => {
    const fetchVersion = async () => {
      await InternationalActions.getAll() // This needs to be here because in the edit version it needs i18n
    }

    fetchVersion()
  }, [])

  return (
    <Switch>
      {/*
        NOTE: Make this 'exact' otherwise if we want to go to /new it won't.
        Because path was matched before /new
      */}
      <Route path={`${path}`} exact>
        <Versions />
      </Route>
      <Route path={`${path}/new`}>
        <AddNewVersion />
      </Route>
      {/* NOTE: Also this one, otherwise it will not match the /edit */}
      <Route path={`${path}/:version_id`} exact>
        <ShowVersion />
      </Route>
      <Route path={`${path}/:version_id/edit`}>
        <EditVersion />
      </Route>
    </Switch>
  )
}

const I18nVersion = () => {
  const { path } = useRouteMatch()

  useEffect(() => {
    const fetchVersion = async () => {
      await InternationalActions.getAll()
    }

    fetchVersion()
  }, [])

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <I18nVersions />
      </Route>
      {/* <Route path={`${path}/new`}>
        i18n AddNewVersion
      </Route> */}
      <Route path={`${path}/:version_id`} exact>
        <ViewI18nVersion />
      </Route>
      <Route path={`${path}/:version_id/edit`}>
        <EditI18nVersion />
      </Route>
      <Route path={`${path}/:version_id/clone`}>
        <CloneVersion />
      </Route>
    </Switch>
  )
}

const FaqVersion = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <FaqVersions />
      </Route>
      <Route path={`${path}/:version_id`} exact>
        <ViewFaqVersion />
      </Route>
      <Route path={`${path}/:version_id/edit`}>
        <EditFaqVersion />
      </Route>
    </Switch>
  )
}

const EntrywayVersioningModule = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/versions' />
      </Route>
      <Route path='/versions' component={Versions} exact />
      <Redirect to='/versions' />
    </Switch>
  )
}

export const EntryVersionsModuleConfig = {
  routeProps: {
    path: '/versions',
    component: EntrywayVersioningModule,
  },
  name: 'Versions',
}

// Version is a special case, if it's an entryway, user needs to select version first
// to see another menu
const VersioningModule = () => {
  const { path } = useRouteMatch()

  useEffect(() => {
    const fetchVersion = async () => {
      await VersionsActions.get()
    }

    fetchVersion()
  }, [])

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/datasets`} />
      </Route>
      <Route path={`${path}/datasets`}>
        <DatasetVersion />
      </Route>
      <Route path={`${path}/i18n`}>
        <I18nVersion />
      </Route>
      <Route path={`${path}/faqs`}>
        <FaqVersion />
      </Route>
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/versions',
    component: VersioningModule,
  },
  name: 'Versions',
}

export default VersioningModule
