import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography, Table } from 'antd'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../../../api'
import { EmailAutomationsContext } from '../../../../../context'

const PredictedIntentClassifications = ({ emailBody }) => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const dataSourceQueryParams = { version: currentVersion, datasource }
  const [
    { loading: recognizedIntentsLoading, data: recognizedIntents },
  ] = useRequest(Api.Intents.getRecognizedIntentsByText, emailBody, dataSourceQueryParams)

  const columns = [
    {
      title: 'Intent name',
      dataIndex: 'name',
    },
    {
      title: 'Confidence',
      dataIndex: 'score',
      render: (score) => { return (<Typography.Text>{score}</Typography.Text>) },
    },
  ]

  return (
    <>
      <Typography.Title level={5}>Analyze/Classification</Typography.Title>
      <Table
        loading={recognizedIntentsLoading}
        dataSource={(recognizedIntents?.intents || []).map((intent, index) => {
          return {
            ...intent,
            key: index,
          }
        })}
        bordered
        size='small'
        columns={columns}
        pagination={false}
      />
    </>
  )
}

PredictedIntentClassifications.propTypes = {
  emailBody: PropTypes.string.isRequired,
}

export default PredictedIntentClassifications
