import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Button,
  Tag,
  notification,
  Collapse,
} from 'antd'

import style from './index.module.scss'

const ConversationDataStats = ({
  allowToUpdateStats,
  statName,
  stats,
  loading,
  onUpdateConversationStat,
  collapsable,
}) => {
  const [value, setValue] = useState(stats)
  const [hasFormatError, setHasFormatError] = useState(false)

  const onChange = (event) => {
    if (hasFormatError) setHasFormatError(false)
    setValue(event.target.value)
  }

  const updateConversationStat = () => {
    setHasFormatError(false)
    try {
      if (JSON.parse(value)) onUpdateConversationStat(value)
    } catch (error) {
      setHasFormatError(true)
      notification.error({
        message: `${statName} stat update error`,
        description: 'Check the JSON formatting',
      })
    }
  }

  useEffect(() => {
    setValue(stats)
  }, [stats])

  const renderInputArea = (
    <>
      <Input.TextArea
        className={hasFormatError ? style.stats_error : style.stats}
        value={value}
        onChange={onChange}
        autoSize={false}
        readOnly={!allowToUpdateStats}
        disabled={loading}
      />
      {allowToUpdateStats && (
        <Button
          className={style.synchronize_stats}
          type='default'
          size='small'
          disabled={loading}
          onClick={updateConversationStat}
        >
          Update
        </Button>
      )}
    </>
  )

  if (collapsable) {
    return (
      <Collapse ghost bordered={false} defaultActiveKey='1' className='DataStats__Collapsable'>
        <Collapse.Panel
          key='1'
          header={<Tag className={style.title}>{statName}</Tag>}
        >
          <div className={style.SimulatedConversationStats}>
            {renderInputArea}
          </div>
        </Collapse.Panel>
      </Collapse>
    )
  }

  return (
    <div className={style.SimulatedConversationStats}>
      <Tag className={style.title}>{statName}</Tag>
      {renderInputArea}
    </div>
  )
}

ConversationDataStats.propTypes = {
  allowToUpdateStats: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  statName: PropTypes.string,
  stats: PropTypes.string.isRequired,
  onUpdateConversationStat: PropTypes.func.isRequired,
  collapsable: PropTypes.bool,
}

ConversationDataStats.defaultProps = {
  statName: 'Conversation Stat Name',
  collapsable: false,
}

export default ConversationDataStats
