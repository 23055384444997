import cloneDeep from 'lodash.clonedeep'
import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { reHashIndex } from '@core/helpers'
import { SELECTED_SENTENCE, SENTENCE } from '../constants'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  bulkAddIntentTrainingSets: (sentenceIds, trainDomainIntent) => {
    return async (dispatch, getState) => {
      dispatch({ type: SENTENCE.BULK_MODE.APPLY_TRAINING.REQUEST })
      try {
        const response = await Promise.allSettled(sentenceIds.map(async (sentenceId) => {
          const { train_domain_id, train_intent_id } = trainDomainIntent

          // holyshit...
          const [trainDomain, trainIntent] = await Promise.allSettled([
            Api.addDomainTrainingSet({ sentence_id: sentenceId, train_domain_id }),
            Api.addIntentTrainingSet({ sentence_id: sentenceId, train_intent_id }),
          ])

          // previously, the allSettled on L#13 is not destructed and return as the const value

          // transform the allSettled return value
          // NOTE: this is SEMI happy path
          return {
            sentenceId,
            // actually we might not need to send the response of these...
            trainDomainResponse: trainDomain.value.data || trainDomain.reason,
            trainIntentResponse: trainIntent.value.data || trainIntent.reason,
            failed: !!(trainDomain.reason || trainIntent.reason),
          }
        }))

        // response is "array" which each element represents single sentence
        // as a object of allSettled return value that the actual value is in the 'value' key of each element
        // that contains a results of add domain/intent training case
        // if one of them failed, the failed will marked as true and the value of response will
        // shown as the result instead

        if (response && response.length >= 1) {
          const { listed, sentencesHash } = getState().data.sentences

          // Optional, we might need to show the warning message if there is some of them error...
          const newSentencesSet = cloneDeep(listed)
          response.forEach((newUpdatedSentences) => {
            const timeStamp = Date.now()
            const index = sentencesHash[newUpdatedSentences.value.sentenceId]

            newSentencesSet[index] = {
              ...newSentencesSet[index],
              changed_timestamp: `${new Date(timeStamp).toLocaleDateString('en-US')} ${new Date(timeStamp).toLocaleTimeString('en-US')}`,
            }
          })

          return dispatch({
            type: SENTENCE.BULK_MODE.APPLY_TRAINING.SUCCESS,
            payload: {
              sentencesChanged: response.map((sentence) => { return sentence.value.sentenceId }),
              newSentencesSet,
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SENTENCE.BULK_MODE.APPLY_TRAINING.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  addDomainTrainingSet: (sentence_id, formData) => {
    return async (dispatch) => {
      const { train_domain_id } = formData
      const payload = {
        sentence_id,
        train_domain_id,
      }

      // if (train_intent) payload.train_intent = train_intent

      dispatch({
        type: SELECTED_SENTENCE.TRAINING_CASES.ADD_DOMAIN.REQUEST,
      })

      try {
        const response = await Api.addDomainTrainingSet(payload)
        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.ADD_DOMAIN.SUCCESS,
            payload: {
              result: response.data,
              type: 'domain',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.ADD_DOMAIN.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  addIntentTrainingSet: (sentence_id, formData) => {
    return async (dispatch) => {
      const { domain_intent } = formData
      const [/* train_domain_id */, train_intent_id] = domain_intent
      const payload = {
        sentence_id,
      }

      if (train_intent_id) payload.train_intent_id = train_intent_id

      dispatch({
        type: SELECTED_SENTENCE.TRAINING_CASES.ADD_INTENT.REQUEST,
      })

      try {
        const response = await Api.addIntentTrainingSet(payload)
        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.ADD_INTENT.SUCCESS,
            payload: {
              result: response.data,
              type: 'intent',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.ADD_INTENT.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  addTaggerTrainingSet: (sentence_id, formData) => {
    return async (dispatch) => {
      dispatch({
        type: SELECTED_SENTENCE.TRAINING_CASES.ADD_TAGGER.REQUEST,
      })
      const { desired_result, phrase: tempPhrase, tagger_type } = formData
      const { value: phrase, phraseIndex: phrase_index } = JSON.parse(tempPhrase)
      const payload = {
        sentence_id,
        desired_result,
        tagger_type,
        phrase,
        phrase_index,
      }
      try {
        const response = await Api.addTaggerTrainingSet(payload)

        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.ADD_TAGGER.SUCCESS,
            payload: {
              result: response.data,
              type: 'tagger',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.ADD_TAGGER.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  updateDomainTrainingSetById: (sentence_id, values, index) => {
    return async (dispatch) => {
      const { train_domain_id } = values
      const payload = { train_domain_id }
      dispatch({
        type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_DOMAIN.REQUEST,
      })

      try {
        const response = await Api.updateDomainTrainingSetById(sentence_id, payload)

        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_DOMAIN.SUCCESS,
            payload: {
              result: response.data,
              type: 'domain',
              index,
            },
          })
        }
        throw new Error('Response incompartible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_DOMAIN.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  updateIntentTrainingSetById: (sentence_id, values, index) => {
    return async (dispatch) => {
      const [/* train_domain_id */, train_intent_id] = values.domain_intent
      const payload = { train_intent_id }
      dispatch({
        type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_INTENT.REQUEST,
      })

      try {
        const response = await Api.updateIntentTrainingSetById(sentence_id, payload)

        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_INTENT.SUCCESS,
            payload: {
              result: response.data,
              type: 'intent',
              index,
            },
          })
        }
        throw new Error('Response incompartible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_INTENT.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  updateTaggerTrainingSetById: (sentence_id, values, index) => {
    return async (dispatch) => {
      const { desired_result, phrase: tempPhrase, tagger_type } = values
      let validatedPhrase
      try {
        validatedPhrase = JSON.parse(tempPhrase)
      } catch (e) {
        const temp = tempPhrase.split(':').map((item) => { return item.trim() })
        validatedPhrase = { phraseIndex: temp[0].replace(/(\[|\])/gi, ''), value: temp[1] }
      }
      const { value: phrase, phraseIndex: phrase_index } = validatedPhrase
      const payload = {
        tagger_type,
        phrase_index,
        phrase,
        desired_result,
      }
      dispatch({
        type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_TAGGER.REQUEST,
      })

      try {
        const response = await Api.updateTaggerTrainingSetById(sentence_id, payload)

        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_TAGGER.SUCCESS,
            payload: {
              result: response.data,
              type: 'tagger',
              index,
            },
          })
        }
        throw new Error('Response incompartible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.UPDATE_TAGGER.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  deleteDomainTrainingCase: (caseId) => {
    return async (dispatch, getState) => {
      dispatch({ type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_DOMAIN.REQUEST })
      try {
        const response = await Api.deleteDomainTrainingSetByIds({ ids: [caseId] })
        if (response && response.data) {
          const {
            hashedIndex_domain_training_sets: hashedList,
            domain_training_sets,
          } = getState().data.selectedSentence.trainingCases
          const newDomainTrainingSets = cloneDeep(domain_training_sets)
          newDomainTrainingSets.splice(hashedList[caseId], 1)

          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_DOMAIN.SUCCESS,
            payload: {
              type: 'domain',
              domain_training_sets: newDomainTrainingSets,
              hashedIndex_domain_training_sets: reHashIndex(newDomainTrainingSets),
            },
          })
        }
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_DOMAIN.FAILED,
        })
        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_DOMAIN.FAILED,
          error,
        })

        throw error
      }
    }
  },
  deleteIntentTrainingCase: (caseId) => {
    return async (dispatch, getState) => {
      dispatch({ type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_INTENT.REQUEST })
      try {
        const response = await Api.deleteIntentTrainingSetByIds({ ids: [caseId] })
        if (response && response.data) {
          const {
            hashedIndex_intent_training_sets: hashedList,
            intent_training_sets,
          } = getState().data.selectedSentence.trainingCases
          const newIntentTrainingets = cloneDeep(intent_training_sets)
          newIntentTrainingets.splice(hashedList[caseId], 1)

          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_INTENT.SUCCESS,
            payload: {
              type: 'intent',
              intent_training_sets: newIntentTrainingets,
              hashedIndex_intent_training_sets: reHashIndex(newIntentTrainingets),
            },
          })
        }
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_INTENT.FAILED,
        })
        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_INTENT.FAILED,
          error,
        })

        throw error
      }
    }
  },
  deleteTaggerTrainingCase: (caseIds) => {
    return async (dispatch, getState) => {
      dispatch({ type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_TAGGER.REQUEST })
      try {
        const response = await Api.deleteTaggerTrainingSetByIds({ ids: [caseIds] })
        if (response && response.data) {
          const {
            hashedIndex_tagger_training_sets: hashedList,
            tagger_training_sets,
          } = getState().data.selectedSentence.trainingCases
          const newTaggerTrainingSets = cloneDeep(tagger_training_sets)
          newTaggerTrainingSets.splice(hashedList[caseIds], 1)

          return dispatch({
            type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_TAGGER.SUCCESS,
            payload: {
              type: 'tagger',
              tagger_training_sets: newTaggerTrainingSets,
              hashedIndex_tagger_training_sets: reHashIndex(newTaggerTrainingSets),
            },
          })
        }
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_TAGGER.FAILED,
        })
        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TRAINING_CASES.DELETE_TAGGER.FAILED,
          error,
        })

        throw error
      }
    }
  },
})
