import React, { useContext } from 'react'
import { Form, Select, Divider } from 'antd'
import { EmailAutomationsContext } from '../../../../context'

const ByFeedback = () => {
  const { emailSearchResults: [emailSearchResultsState] } = useContext(EmailAutomationsContext)

  return (
    <>
      <Divider>
        <small><strong> By Feedback </strong></small>
      </Divider>
      <Form.Item name='feedbackSentiments'>
        <Select mode='multiple' placeholder='Select Feedback Types' allowClear>
          <Select.Option value='none'>none</Select.Option>
          {emailSearchResultsState.feedbackSentiments.map((feedback) => {
            return (<Select.Option key={feedback} value={feedback}>{feedback}</Select.Option>)
          })}
        </Select>
      </Form.Item>
    </>
  )
}

export default ByFeedback
