import { useReducer } from 'react'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

const INITIAL_STATE = {
  visibility: false,
  selectedOperation: null,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        visibility: true,
        selectedOperation: action.payload.type,
      }
    }
    case CLOSE_MODAL: {
      return {
        ...INITIAL_STATE,
      }
    }
    default: throw new Error()
  }
}

const ModalActions = (dispatch) => {
  return {
    open: (type) => {
      dispatch({
        type: OPEN_MODAL,
        payload: {
          type,
        },
      })
    },
    close: () => {
      dispatch({
        type: CLOSE_MODAL,
      })
    },
  }
}

export const useResponseTextModal = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return [state, ModalActions(dispatch)]
}
