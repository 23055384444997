import React, { useContext, useState, useEffect } from 'react'
import { Form, Divider, Select, Spin, Typography, InputNumber, Space } from 'antd'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'
import { EmailAutomationsContext } from '../../../../context'
import Api from '../../../../api'

const ByTrainAndTestIntents = ({ form, onlyPreprocessedSearch }) => {
  const [hasPredictedEntity, setHasPredictedEntity] = useState(false)
  const [hasPredictedNone, setHasPredictedNone] = useState(false)
  const [hasTrainIntentNone, setHasTrainIntentNone] = useState(false)

  useEffect(() => {
    setHasPredictedNone(false)
  }, [onlyPreprocessedSearch])

  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [{
    loading,
    data,
    hasError,
  }] = useRequest(Api.Intents.getIntents, { version: currentVersion, datasource })
  if (!data) return null
  const { intents } = data

  const onSelectIntent = (values) => {
    if (values.includes('none')) form.setFieldsValue({ predictedIntentNames: ['none'] })
    setHasPredictedNone(values.includes('none'))
    setHasPredictedEntity(values.length > 0 && !values.includes('none'))
  }

  const onSelectTrainIntent = (values) => {
    const hasNoneInValues = values.includes('none')
    if (hasNoneInValues) {
      form.setFieldsValue({ trainIntentIds: ['none'] })
      form.setFieldsValue({ excludeTrainIntentIds: undefined })
    }
    setHasTrainIntentNone(hasNoneInValues)
  }

  const filterOptionByLabel = (input, option) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }

  return (
    <>
      {hasError && <Typography.Text>Error loading intent</Typography.Text>}
      {
        (!intents || loading)
          ? (<Spin />)
          : (
            <>
              <>
                <Divider>
                  <small><strong>By Intent Cases</strong></small>
                </Divider>
                <Form.Item name='trainIntentIds' label='Include Train Intents'>
                  <Select
                    mode='multiple'
                    allowClear
                    placeholder='Select intents'
                    onChange={onSelectTrainIntent}
                    filterOption={filterOptionByLabel}
                    options={[
                      {
                        value: 'none',
                        label: 'none',
                      },
                      {
                        label: 'Intents',
                        options: intents.map((intent) => {
                          return {
                            value: intent.id,
                            label: intent.name,
                            disabled: hasTrainIntentNone,
                          }
                        }),
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item name='excludeTrainIntentIds' label='Exclude Train Intents'>
                  <Select
                    mode='multiple'
                    allowClear
                    placeholder='Select intents'
                    disabled={hasTrainIntentNone}
                    filterOption={filterOptionByLabel}
                    options={intents.map((intent) => {
                      return {
                        value: intent.id,
                        label: intent.name,
                      }
                    })}
                  />
                </Form.Item>
              </>
              <>
                <Divider>
                  <small><strong>By Prediction</strong></small>
                </Divider>
                <Form.Item name='predictedIntentNames' label='Predicted Intents'>
                  <Select
                    mode='multiple'
                    allowClear
                    placeholder='Select intents'
                    onChange={onSelectIntent}
                    filterOption={filterOptionByLabel}
                    options={[
                      {
                        value: 'none',
                        label: 'none',
                        disabled: !onlyPreprocessedSearch,
                      },
                      {
                        label: 'Intents',
                        options: intents.reduce((prev, intent) => {
                          if (intent.name.startsWith('not-')) {
                            return prev
                          }
                          prev.push({
                            value: intent.name,
                            label: intent.name,
                            disabled: hasPredictedNone,
                          })
                          return prev
                        }, []),
                      },
                    ]}
                  />
                </Form.Item>
                {
                  hasPredictedEntity && !hasPredictedNone && (
                    <Space direction='vertical' size='small'>
                      <Typography.Text>Predicted Intent Score Range</Typography.Text>
                      <Space size='small'>
                        <Form.Item name='predictedIntentScoreMin' label='Min'>
                          <InputNumber min={0} max={1} />
                        </Form.Item>
                        <Form.Item name='predictedIntentScoreMax' label='Max'>
                          <InputNumber min={0} max={1} />
                        </Form.Item>
                      </Space>
                    </Space>
                  )
                }
              </>
            </>
          )
      }
    </>
  )
}

ByTrainAndTestIntents.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  onlyPreprocessedSearch: PropTypes.bool.isRequired,
}

export default ByTrainAndTestIntents
