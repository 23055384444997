import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Space, Button, Modal, notification, Popconfirm, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import StorageUtils from '@core/utils/storage'
import Api from '../../../../api'
import { EmailAutomationsContext } from '../../../../context'
import style from './index.module.scss'

const Actions = ({ version, setViewingVersion, onCloneFinished }) => {
  const {
    selectedWorkingVersion,
    setSelectedWorkingVersion,
    datasource,
    permissions,
  } = useContext(EmailAutomationsContext)

  const { versions } = permissions ?? {}

  const selectVersion = () => {
    Modal.confirm({
      title: (
        <Typography.Text>
          This will change the current version to:
          {' '}
          <strong>{`${version.note} / ${version.id}`}</strong>
        </Typography.Text>
      ),
      icon: <ExclamationCircleOutlined />,
      content: 'This will discard all unsaved changes',
      onOk: async () => {
        try {
          setSelectedWorkingVersion(version.id)

          // Save to sessionStorage
          StorageUtils.setItemIntoSession('email_datasource_version', JSON.stringify({ datasource, version: version.id }))
        } catch (error) {
          notification.error({
            message: 'Error',
            description: error.message,
            duration: 5,
          })
        }
      },
      onCancel: () => { },
    })
  }

  const cloneVersion = async () => {
    try {
      await Api.Versions.cloneVersion({ datasource, id: version.id })
      onCloneFinished()
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Cloning version failed',
        duration: 5,
      })
    }
  }

  const viewOrEditVersion = () => {
    setViewingVersion(version)
  }

  /*
  const deleteVersion = async () => {
    console.warn('TODO: Implement deletion, and use the Modal.confirm ')
    showConfirmModal(version.id, 'delete')
  }
  */

  return (
    <Space className='VersionActionsButtons' size={0} wrap>
      <Button
        disabled={version.isCurrentVersion}
        type='link'
        onClick={selectVersion}
        className={style.Strong_Link}
        title={version.isCurrentVersion ? 'This is current version' : undefined}
      >
        Select
      </Button>
      {selectedWorkingVersion && (
        <>
          {versions?.actionViewEdit && (
            <Button type='link' onClick={viewOrEditVersion}>
              View/Edit
            </Button>
          )}
          {versions?.actionDuplicate && (
            <Popconfirm
              placement='top'
              title={(
                <>
                  <Typography.Text>Cloning</Typography.Text>
                  <Typography.Text strong>{` "${version.id}"; `}</Typography.Text>
                  <Typography.Text>{`${version.note}?`}</Typography.Text>
                </>
              )}
              icon={<ExclamationCircleOutlined />}
              onConfirm={cloneVersion}
              okText='Yes'
              cancelText='No'
            >
              <Button type='link'>
                Duplicate
              </Button>
            </Popconfirm>
          )}
        </>
      )}
      {/* <Popconfirm
        placement='top'
        title={(
          <>
            <Typography.Text>Deleting</Typography.Text>
            <Typography.Text strong>{` "${version.id}"; `}</Typography.Text>
            <Typography.Text>{`${version.note}?`}</Typography.Text>
          </>
        )}
        icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
        onConfirm={deleteVersion}
        okText='Yes'
        cancelText='No'
      >
        <Button type='link'>
          Delete
        </Button>
      </Popconfirm> */}
    </Space>
  )
}

Actions.propTypes = {
  version: PropTypes.shape({
    id: PropTypes.string,
    isCurrentVersion: PropTypes.bool,
    note: PropTypes.string,
  }).isRequired,
  onCloneFinished: PropTypes.func.isRequired,
  setViewingVersion: PropTypes.func.isRequired,
}

export default Actions
