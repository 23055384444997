import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Result, Typography, Button, Space, Table } from 'antd'
import dayjs from 'dayjs'

import useRequest from '@core/hooks/useRequest'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import { shallowCleanFalsyObject, sortTextOrNull } from '@core/helpers'

import AssignChannelToClientGroupsModal from '../../../components/AssignChannelToClientGroupsModal'
import DataTable from '../../../components/DataTable'
import Api from '../../../api'

dayjs.extend(require('dayjs/plugin/minMax'))

const fixedDeploymentGroupsFilter = { clientGroupType: ['DEPLOYMENTS'] }

const expandedRowRenderWrapper = (allDeploymentGroups) => {
  return (record, /* index */ _, /* indent */__, expanded) => {
    if (!expanded) return null

    const allDeploymentGroupsNameToFalseLookup = allDeploymentGroups?.items?.reduce((acc, curr) => {
      acc[curr.name] = curr.latestChannels?.[record.key]?.value === true
      return acc
    }, {}) || {}
    const assignedCount = Object.values(allDeploymentGroupsNameToFalseLookup).filter((value) => { return value }).length

    const columns = [
      {
        title: (
          <>
            <Typography.Text>Deployment Group Name</Typography.Text>
            <Typography.Text type='secondary'>{` (assigned to ${assignedCount}/${allDeploymentGroups?.items?.length} deployment groups)`}</Typography.Text>
          </>
        ),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => { return sortTextOrNull(a.name, b.name) },
      },
      {
        title: 'Status',
        dataIndex: 'value',
        key: 'value',
        sorter: (a, b) => { return sortTextOrNull(a.value, b.value) },
        render: (value) => {
          if (value === true) return <Typography.Text type='success'>Enabled</Typography.Text>
          if (value === false) return <Typography.Text type='danger'>Disabled</Typography.Text>
          return <Typography.Text>N/A</Typography.Text>
        },
      },
    ]

    const dataSource = Object
    .entries(allDeploymentGroupsNameToFalseLookup)
    .map(([name, value]) => { return { name, value } })
    .sort((a, b) => { return sortTextOrNull(a.name, b.name) })
    return <Table columns={columns} dataSource={dataSource} pagination={false} />
  }
}

const DataTab = ({
  timeFormatter,
  filter,
  handlePaginationChange,
  triggerSearch,
  environment,
}) => {
  const INITIAL_VALUES = { id: undefined, name: undefined }
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editModalInitialValues, setEditModalInitialValues] = useState(INITIAL_VALUES)
  const query = { environment, ...filter }
  const [{ loading, data, hasError }] = useRequest(Api.ClientGroups.searchAvailableChannels, shallowCleanFalsyObject(query))
  const deploymentGroupQuery = { environment, showAll: true, ...fixedDeploymentGroupsFilter }
  const [{ data: allDeploymentGroups }] = useRequest(Api.ClientGroups.findMany, deploymentGroupQuery)
  const onEditModalClicked = (record) => {
    setEditModalInitialValues(record)
    setEditModalVisible(true)
  }

  const onSetEditModalVisibility = (visible) => {
    setEditModalVisible(visible)
    setEditModalInitialValues(INITIAL_VALUES)
  }

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }

  const columnsTable = [
    {
      title: (<strong>Channel Name</strong>),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return sortTextOrNull(a.name, b.name) },
    },
    {
      title: (<strong>Updated At</strong>),
      key: 'updatedAt',
      width: 190,
      sorter: (a, b) => { return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime() },
      render: (record) => {
        const { updatedAt } = record

        const dates = []
        if (updatedAt) {
          dates.push(dayjs(updatedAt))
        }
        const mostRecentDate = dayjs.max(dates)

        if (!mostRecentDate) return <Typography.Text>-</Typography.Text>
        if (timeFormatter) {
          return <Typography.Text>{timeFormatter(mostRecentDate.toISOString())}</Typography.Text>
        }
        return <Typography.Text>{mostRecentDate.toISOString()}</Typography.Text>
      },
    },
    {
      title: (<strong>Actions</strong>),
      key: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Button type='default' size='small' onClick={() => { return onEditModalClicked(record) }} disabled>Assign</Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <DataTable
        columns={columnsTable}
        dataSource={data}
        filter={filter}
        onPaginationChange={handlePaginationChange}
        expandable={{
          expandedRowRender: expandedRowRenderWrapper(allDeploymentGroups),
        }}
      />
      {editModalVisible && (
        <AssignChannelToClientGroupsModal
          key={editModalInitialValues.id}
          modalVisibility={editModalVisible}
          setModalVisibility={onSetEditModalVisibility}
          initialValues={editModalInitialValues}
          triggerSearch={triggerSearch}
          environment={environment}
        />
      )}
    </>
  )
}

DataTab.propTypes = {
  environment: PropTypes.string.isRequired,
  timeFormatter: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
}

export default DataTab
