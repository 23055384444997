import PropTypes from 'prop-types'

const {
  func,
  string,
  shape,
  number,
} = PropTypes

export const itemType = shape({
  test_intent_id: number,
  test_domain: string,
  test_intent: string,
  tagger_type: string,
  id: string,
  phrase: string,
  desired_result: string,
  expected_generalized_type: string,
})

export const onEditTestCaseType = func
export const onConfirmDeleteType = func
