import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Layout,
  PageHeader,
  Descriptions,
  Typography,
  Spin,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../../Faqs/api'

const ResponseText = () => {
  const { version_id } = useParams()
  const history = useHistory()

  const onBack = () => { history.replace('/versions/faqs') }

  const [{ loading, data: version, hasError }] = useRequest(Api.getVersion, version_id)

  return (
    <Layout.Content className='FaqVersion__View'>
      <PageHeader
        title={`FAQ Response Text Version #${version_id}`}
        onBack={onBack}
      />
      {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      {loading && <Spin />}
      {version && (
        <Descriptions column={1} bordered size='small'>
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='ID'>{version.id || 'N/A'}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Note'>{version.note || 'N/A'}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Created at'>{version.created_at || 'N/A'}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Updated at'>{version.updated_at || 'N/A'}</Descriptions.Item>
        </Descriptions>
      )}
    </Layout.Content>
  )
}

export default ResponseText
