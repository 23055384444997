import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions, Button, Typography, Divider, List, Tag, Spin } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import style from './index.module.scss'
import AddNoteModal from './AddNoteModal'
import DeleteNoteModal from './DeleteNoteModal'
import EditNoteModal from './EditNoteModal'
import NotesActions from '../../store/actions/sentenceNotes'

const { Paragraph } = Typography

const SentenceNotes = () => {
  const { selectedNote, notes, isPending, selectedSentence } = useSelector(({ data }) => {
    return {
      notes: data.sentenceNotes.notes,
      selectedNote: data.sentenceNotes.selectedNote,
      isPending: data.sentenceNotes.isPending,
      selectedSentence: data.sentences.selectedSentence,
    }
  })
  const [addNoteVisible, setAddNoteVisible] = useState(false)
  const [deleteNoteVisible, setDeleteNoteVisible] = useState(false)
  const [editNoteVisible, setEditNoteVisible] = useState(false)

  const getStatusTag = (note) => {
    switch (note.status) {
      case 'PENDING':
        return <Tag color='orange'>[PENDING] </Tag>
      case 'DONE':
        return <Tag color='green'>[DONE] </Tag>
      case 'INFO':
      default:
        return <Tag color='blue'>[INFO] </Tag>
    }
  }

  return (
    <div className={style.right_panel__sentences_notes}>
      {
        selectedNote
          && deleteNoteVisible
          && (
            <DeleteNoteModal
              visible={deleteNoteVisible}
              closeModal={() => { return setDeleteNoteVisible(false) }}
            />
          )
      }
      {
        selectedNote
          && editNoteVisible
          && (
            <EditNoteModal
              visible={editNoteVisible}
              closeModal={() => { return setEditNoteVisible(false) }}
            />
          )
      }
      {isPending && <Spin />}
      {
        !isPending && (
          <>
            <AddNoteModal
              visible={addNoteVisible}
              closeModal={() => { return setAddNoteVisible(false) }}
            />

            <Descriptions size='small' bordered column={5}>
              <Descriptions.Item label='Text' span={5}>{selectedSentence.text}</Descriptions.Item>
              <Descriptions.Item label='Generalized' span={5}>{selectedSentence.generalized.generalized}</Descriptions.Item>
            </Descriptions>
            <Divider>Notes</Divider>
            <div className={style.right_panel__sentences_notes_content}>
              <List
                size='small'
                itemLayout='horizontal'
                dataSource={notes}
                renderItem={(note, index) => {
                  return (
                    <List.Item
                      actions={[
                        <PermittedFeatureCheck featurePath='utterances.data.actionEditSentenceNotes'>
                          <Button
                            onClick={async () => {
                              await NotesActions.select(notes[index])
                              setEditNoteVisible(true)
                            }}
                            icon={<EditOutlined />}
                          />
                        </PermittedFeatureCheck>,
                        <PermittedFeatureCheck featurePath='utterances.data.actionDeleteSentenceNotes'>
                          <Button
                            onClick={async () => {
                              await NotesActions.select(notes[index])
                              setDeleteNoteVisible(true)
                            }}
                            icon={<DeleteOutlined />}
                          />
                        </PermittedFeatureCheck>,
                      ]}
                    >
                      <List.Item.Meta
                        title={<Tag>{note.type}</Tag>}
                        description={(
                          <Paragraph>
                            {getStatusTag(note)}
                            {note.note}
                          </Paragraph>
                        )}
                      />
                    </List.Item>
                  )
                }}
              />
            </div>
            <PermittedFeatureCheck featurePath='utterances.data.actionInsertSentenceNotes'>
              <Button
                type='primary'
                className={style.right_panel__sentences_notes_add}
                onClick={() => { return setAddNoteVisible(true) }}
              >
                Add new note
              </Button>
            </PermittedFeatureCheck>
          </>
        )
      }
    </div>
  )
}

export default SentenceNotes
