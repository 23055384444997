import {
  EXTRACT_INFORMATION,
  ADD_TEST_SET,
  OPEN_MODAL,
  DISMISS_MODAL,
  SET_SENTENCE_ANALYSIS_DATA,
} from '../constants'

const INITIAL_STATE = {
  modalVisibility: false,
  initialSentence: '',
  data: null,
  hasData: false,

  extractInformationIsPending: false,
  extractInformationHasError: false,
  extractInformationResults: {},
  extractInformationHeaders: {},

  addToTestSetIsPending: false,
  addToTestSetHasError: false,
  addToTestSetSuccess: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXTRACT_INFORMATION.REQUEST: {
      return {
        ...state,
        extractInformationIsPending: true,
        extractInformationHasError: false,
        extractInformationResults: {},
        extractInformationHeaders: {},
        addToTestSetHasError: false,
        addToTestSetSuccess: false,
      }
    }
    case EXTRACT_INFORMATION.SUCCESS: {
      return {
        ...state,
        extractInformationIsPending: false,
        extractInformationHeaders: {
          transactionToken: action.payload.transactionToken,
          responseTime: action.payload.responseTime,
        },
        extractInformationResults: action.payload.data,
      }
    }
    case EXTRACT_INFORMATION.FAILED: {
      return {
        ...state,
        extractInformationIsPending: false,
        extractInformationHasError: true,
      }
    }
    case ADD_TEST_SET.REQUEST: {
      return {
        ...state,
        addToTestSetIsPending: true,
        addToTestSetHasError: false,
        addToTestSetSuccess: false,
      }
    }
    case ADD_TEST_SET.SUCCESS: {
      return {
        ...state,
        addToTestSetIsPending: false,
        addToTestSetSuccess: true,
      }
    }
    case ADD_TEST_SET.FAILED: {
      return {
        ...state,
        addToTestSetIsPending: false,
        addToTestSetHasError: true,
      }
    }
    case OPEN_MODAL: {
      return {
        ...state,
        modalVisibility: true,
        initialSentence: action.payload.initialSentence,
        data: action.payload.data,
        hasData: action.payload.hasData,
      }
    }
    case SET_SENTENCE_ANALYSIS_DATA: {
      return {
        ...state,
        data: action.payload.data,
        hasData: action.payload.hasData,
      }
    }
    case DISMISS_MODAL: {
      return {
        ...INITIAL_STATE,
      }
    }
    default: return state
  }
}
