import { TRAIN_TAGGERS, ANALYZE_TAGGERS, DISPLAY_TAGGER_TRAININGS } from '../constants'

const INITIAL_STATE = {
  analysis: {
    isAnalyzing: false,
    result: null,
    error: null,
  },
  training: {
    isTraining: false,
    result: null,
    error: null,
  },
  taggerTrainings: {
    trainingSets: [],
    isLoading: false,
    error: null,
  },
  entityTaggers: [
    'AirlineTagger',
    'AirportTagger',
    'CityTagger',
    'CountryTagger',
    // 'HotelAmenityTagger',
    'StateTagger',
    'RailwayStationTagger',
  ],
  extractorTaggers: [
    'FlightTagger',
    'HotelTagger',
    'RailTagger',
  ],
  taggersExpectedResults: {
    AirlineTagger: ['airline', 'not-airline'],
    AirportTagger: ['airport', 'not-airport'],
    CityTagger: ['city', 'not-city'],
    CountryTagger: ['country', 'not-country'],
    // HotelAmenityTagger: ['hotel-amenity', 'not-hotel-amenity'],
    StateTagger: ['state', 'not-state'],
    RailwayStationTagger: ['railway-station', 'not-railway-station'],
    FlightTagger: [
      'origin',
      'destination',
      'departure-date',
      'return-date',
      'path',
      'cabin-class',
      'airline',
      'negative-airline',
    ],
    HotelTagger: [
      'checkin-date',
      'checkout-date',
      'checkin-time',
      'location',
      'hotel-chain',
    ],
    RailTagger: [
      'destination',
      'origin',
      'departure-date',
      'return-date',
      'cabin-class',
      'rail-company',
      'boarding-time',
    ],
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRAIN_TAGGERS.REQUEST: {
      return {
        ...state,
        training: {
          isTraining: true,
          result: null,
          error: null,
        },
      }
    }
    case TRAIN_TAGGERS.SUCCESS: {
      return {
        ...state,
        training: {
          isTraining: false,
          result: action.payload,
          error: null,
        },
      }
    }
    case TRAIN_TAGGERS.FAILED: {
      return {
        ...state,
        training: {
          isTraining: false,
          result: null,
          error: action.payload.error,
        },
      }
    }
    case ANALYZE_TAGGERS.REQUEST: {
      return {
        ...state,
        analysis: {
          isAnalyzing: true,
          result: null,
          error: null,
        },
      }
    }
    case ANALYZE_TAGGERS.SUCCESS: {
      return {
        ...state,
        analysis: {
          isAnalyzing: false,
          result: action.payload,
          error: null,
        },
      }
    }
    case ANALYZE_TAGGERS.FAILED: {
      return {
        ...state,
        analysis: {
          isAnalyzing: false,
          result: null,
          error: action.payload.error,
        },
      }
    }
    case DISPLAY_TAGGER_TRAININGS.REQUEST: {
      return {
        ...state,
        taggerTrainings: {
          trainingSets: [],
          isLoading: true,
          error: null,
        },
      }
    }
    case DISPLAY_TAGGER_TRAININGS.SUCCESS: {
      return {
        ...state,
        taggerTrainings: {
          trainingSets: action.payload,
          isLoading: false,
          error: null,
        },
      }
    }
    case DISPLAY_TAGGER_TRAININGS.FAILED: {
      return {
        ...state,
        taggerTrainings: {
          isLoading: false,
          error: action.payload.error,
        },
      }
    }
    default: return state
  }
}
