import React from 'react'
import { Input, Form } from 'antd'
import { rulesType } from '@core/types/formItems'

const TaggerNote = ({ rules }) => {
  return (
    <Form.Item name='note' label='Note' rules={rules}>
      <Input.TextArea
        showCount
        maxLength={255}
        placeholder='Add a description of why you are doing/skipping this Tagger evaluation'
        style={{ resize: 'vertical' }}
      />
    </Form.Item>
  )
}

TaggerNote.propTypes = {
  rules: rulesType,
}

TaggerNote.defaultProps = {
  rules: [],
}

export default TaggerNote
