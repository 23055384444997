import React from 'react'
import { notification } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import style from './index.module.scss'
import { UserPropTypes } from '../../../UserPropTypes'
import api from '../../../../../api'
import { useUser } from '../../../../../hooks/useUser'
import { transformInputChannel } from '../../../../../utils/transformInputChannel'
import getJobStatus from './getJobStatus'
import ConfirmModal from './ConfirmModal'

const dayjsWithUtc = dayjs.extend(utc)

const AuthenticateTab = ({ user: { onAuthentication, channelUserId, fromChannel } }) => {
  const { environment } = useUser()

  const sendPairingLink = async () => {
    const { data } = await api.User.sentPairingLink(
      {
        channelUserId,
        channel: transformInputChannel(fromChannel),
      },
      { datasource: environment.toLowerCase() },
    )

    const jobStatus = await getJobStatus(data.jobId, environment.toLowerCase())

    if (jobStatus === 'SUCCESS') {
      notification.success({ message: '[Pairing Link] Sent!' })
    } else {
      notification.error({ message: `[Pairing Link] ${jobStatus}` })
    }
  }

  const unpair = async () => {
    const { data } = await api.User.unpair(
      {
        channelUserId,
        channel: transformInputChannel(fromChannel),
      },
      { datasource: environment.toLowerCase() },
    )

    const jobStatus = await getJobStatus(data.jobId, environment.toLowerCase())

    if (jobStatus === 'SUCCESS') {
      notification.success({ message: '[Unpair] Success!' })
    } else {
      notification.error({ message: `[Unpair] ${jobStatus}` })
    }
  }

  return (
    <>
      <div className={style.border}>
        <div>
          Authentication check:
          {' '}
          {onAuthentication?.status ? 'NOT PASSED' : 'PASSED'}
        </div>
        {(onAuthentication?.data?.sentAt && !onAuthentication?.data?.pairedAt) && <div>Pairing link status: SENT</div>}
        {(onAuthentication?.data?.sentAt) && (
          <div>
            Sent at:
            {' '}
            {dayjsWithUtc(onAuthentication?.data?.sentAt).utc().format('MMM D, YYYY HH:mm:ssZ')}
          </div>
        )}
        {(onAuthentication?.data?.pairedAt) && (
          <div>
            Paired at:
            {' '}
            {dayjsWithUtc(onAuthentication?.data?.pairedAt).utc().format('MMM D, YYYY HH:mm:ssZ')}
          </div>
        )}
      </div>
      <div className={style.pt16}>
        <ConfirmModal
          modalTitle='Confirm send pairing link?'
          buttonTitle='Send pairing link'
          onConfirm={sendPairingLink}
        />
        <ConfirmModal
          modalTitle='Confirm unpair?'
          buttonTitle='Unpair'
          onConfirm={unpair}
        />
      </div>
    </>
  )
}

AuthenticateTab.propTypes = {
  user: UserPropTypes.isRequired,
}

export default AuthenticateTab
