import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Tag,
  Table,
} from 'antd'
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import JobsTableExpandedRow from './JobsTableExpandedRow'

const JobsTable = ({ jobs }) => {
  const renderTable = () => {
    const columns = [
      { title: 'Job ID', dataIndex: 'id', key: 'id' },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          switch (status) {
            case 'SUCCESS': return (
              <Tag icon={<CheckCircleOutlined />} color='success'>
                SUCCESS
              </Tag>
            )
            case 'FAIL': return (
              <Tag icon={<ExclamationCircleOutlined />} color='warning'>
                FAIL
              </Tag>
            )
            case 'ERROR': return (
              <Tag icon={<CloseCircleOutlined />} color='error'>
                ERROR
              </Tag>
            )
            case 'RUNNING': return (
              <Tag icon={<SyncOutlined spin />} color='processing'>
                RUNNING
              </Tag>
            )
            default: return 'N/A'
          }
        },
      },
      { title: 'Created Date', dataIndex: 'created_at', key: 'created_at' },
      { title: 'Recently updated', dataIndex: 'updated_at', key: 'updated_at' },
    ]

    const dataSource = [...jobs].sort((a, b) => { return (a.created_at < b.created_at ? 1 : -1) })

    const defaultExpandedRowKeys = dataSource.length > 0 && (dataSource[0].status === 'SUCCESS' || dataSource[0].status === 'RUNNING')
      ? [dataSource[0].id]
      : []

    return (
      <Col span={24}>
        <Table
          rowKey='id'
          dataSource={dataSource}
          columns={columns}
          size='small'
          pagination={false}
          expandable={{
            defaultExpandedRowKeys,
            expandedRowRender: (job) => {
              return (
                <JobsTableExpandedRow id={job.id} />
              )
            },
          }}
        />
      </Col>
    )
  }

  return (
    <Row gutter={[24, 16]}>
      {jobs && renderTable()}
    </Row>
  )
}

JobsTable.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string,
  })).isRequired,
}

export default JobsTable
