import React from 'react'
import { Switch, Route } from 'react-router-dom'
import InformationPage from './pages/InformationPage'

const InformationModule = () => {
  return (
    <Switch>
      <Route path='/information' component={InformationPage} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/information',
    component: InformationModule,
  },
  name: 'Information',
}

export default InformationModule
