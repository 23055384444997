import React, { useCallback, useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import useRequest from '@core/hooks/useRequest'

import { Button, Result, Space, Typography, Table, Spin, Switch } from 'antd'
import Api from '../../../api'
import {
  selectEmail,
  deselectEmail,
  selectEmails,
  setSelectAll,
  clearSelection,
  // toggleBulk,
} from '../../../hooks/useEmailSearchResults'
import { EmailAutomationsContext } from '../../../context'
import ViewEmailModal from '../ViewEmailModal'
import BulkEmailModal from '../BulkEmailModal'
// import BulkEmailHistoryModal from '../BulkEmailHistoryModal'
import EmailContent from './Columns/EmailContent'
import Status from './Columns/Status'
import EmailActions from './Columns/Actions'
import Feedback from './Columns/Feedback'

import style from './style.module.scss'

// TODO: do the useMemo when performance hit
// TODO: Pull some components out
const SearchResult = ({
  filter,
  openBulkHistoryEmailModal,
  onPaginationChange,
  clickToAnalyzeEmail,
}) => {
  const {
    emailSearchResults: [emailSearchResultsState, emailResultsDispatch],
    versions: { currentVersion } = {},
    datasource,
    permissions,
  } = useContext(EmailAutomationsContext)
  // view type toggle between raw/preprocessed
  const [showRawEmail, setShowRawEmail] = useState(false)

  const [selectedEmail, setSelectedEmail] = useState(null)

  const [bulkModalVisible, setBulkModalVisible] = useState(false)
  const [viewEmailModalVisible, setViewEmailModalVisible] = useState(false)
  const [initiallySelectedTab, setInitiallySelectedTab] = useState('intents') // 'intents' or 'dataExtraction'

  const [selectedEmailIndexTableRow, setSelectedEmailIndexTableRow] = useState(null)

  const { emails: emailsPermission } = permissions ?? {}

  // antd rows identifier for selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const { page, perPage, ...searchFilterParams } = filter
  const metaParams = {
    version: currentVersion,
    datasource,
    page,
    perPage,
  }
  // using random number to re-call api with the same query
  const [apiParams, setApiParams] = useState([metaParams, searchFilterParams, Math.random()])
  useEffect(() => {
    setApiParams([metaParams, searchFilterParams, Math.random()])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  // using makeRequest will make whole state flushed out and would also effect the modal visibility
  const [
    { hasError, loading, data },
    { silentMakeRequest, setData },
  ] = useRequest(Api.Emails.getEmails, ...apiParams)

  const updateMultipleEmailIntentSamples = (ids, newIntentSamples) => {
    const newItems = data?.items ? data?.items.map((email) => {
      if (ids.indexOf(email.id) !== -1) {
        const prevIntentSamplesIdsList = email.intentSamples.map((sample) => { return sample.id })
        const newIntentSampleList = [...email.intentSamples]
        newIntentSamples.forEach((intent) => {
          if (!prevIntentSamplesIdsList.includes(intent.id)) newIntentSampleList.push(intent)
        })

        return {
          ...email,
          status: 'IN_PROGRESS',
          intentSamples: newIntentSampleList,
        }
      }

      return email
    }) : []

    setData({
      items: newItems,
      meta: data?.meta,
    })
  }

  // TODO: Probably make one update method read as obj???
  // when intent samples were added, the status will be changed to IN_PROGRESS
  // because it's call batch-update beneath, which changed status to IN_PROGRESS
  const updateSpecificEmailIntentSamples = (id, newIntentSamples) => {
    const emails = [...data.items]
    const changedEmailIndexItem = emails.findIndex((email) => {
      return email.id === id
    })

    if (changedEmailIndexItem !== -1) {
      emails[changedEmailIndexItem] = {
        ...emails[changedEmailIndexItem],
        status: 'IN_PROGRESS',
        intentSamples: newIntentSamples,
      }

      setSelectedEmail((prevSelectedEmailState) => {
        return {
          ...prevSelectedEmailState,
          status: 'IN_PROGRESS',
          intentSamples: newIntentSamples,
        }
      })

      setData({
        items: emails,
        meta: data?.meta,
      })
    }
  }

  // Basic loop
  const updateMultipleEmailStatus = (ids, newStatus) => {
    setData({
      items: data?.items ? data?.items.map((email) => {
        if (ids.indexOf(email.id) !== -1) {
          return {
            ...email,
            status: newStatus,
          }
        }

        return email
      }) : [],
      meta: data?.meta,
    })
  }

  const updateSpecificEmailStatus = (id, newStatus) => {
    const emails = [...data.items]
    const changedEmailIndexItem = emails.findIndex((email) => {
      return email.id === id
    })

    if (changedEmailIndexItem !== -1) {
      emails[changedEmailIndexItem] = {
        ...emails[changedEmailIndexItem],
        status: newStatus,
      }

      setSelectedEmail((prevSelectedEmailState) => {
        return { ...prevSelectedEmailState, status: newStatus }
      })

      setData({
        items: emails,
        meta: data?.meta,
      })
    }
  }

  const updateSpecificEmailDatasetType = (id, newEmail) => {
    const emails = [...data.items]
    const changedEmailIndexItem = emails.findIndex((email) => {
      return email.id === id
    })

    if (changedEmailIndexItem !== -1) {
      emails[changedEmailIndexItem] = {
        ...emails[changedEmailIndexItem],
        datasetType: newEmail.dataset_type,
        status: newEmail.status,
      }

      setSelectedEmail((prevSelectedEmailState) => {
        return {
          ...prevSelectedEmailState,
          datasetType: newEmail.dataset_type,
          status: newEmail.status,
        }
      })

      setData({
        items: emails,
        meta: data?.meta,
      })
    }
  }

  const clearEmailSelection = useCallback(() => {
    setSelectedRowKeys([])
    clearSelection(emailResultsDispatch)
  }, [emailResultsDispatch])

  const openBulkEmailModal = () => {
    setBulkModalVisible(true)
  }

  const closeBulkEmailModal = () => {
    setBulkModalVisible(false)
  }

  const openViewEmailModal = (email) => {
    setSelectedEmail(email)
    setViewEmailModalVisible(true)
  }

  const closeViewEmailModal = () => {
    setSelectedEmail(null)
    setViewEmailModalVisible(false)
    setSelectedEmailIndexTableRow(null)
  }

  const viewEmail = (email, index, insideModalTabKeyName) => {
    return (
      () => {
        setInitiallySelectedTab(insideModalTabKeyName) // no need to reset when closed, it was set when opened anyway
        setSelectedEmailIndexTableRow(index)
        openViewEmailModal(email)
      }
    )
  }

  const backwardOneResult = () => {
    if (selectedEmailIndexTableRow - 1 >= 0) {
      setSelectedEmail(data?.items[selectedEmailIndexTableRow - 1])
      setSelectedEmailIndexTableRow(selectedEmailIndexTableRow - 1)
    } else {
      console.error('SearchResult#backwardOneResult - can\'t go beyond 0')
    }
  }

  const forwardOneResult = () => {
    if (selectedEmailIndexTableRow + 1 < data?.items.length) {
      setSelectedEmail(data?.items[selectedEmailIndexTableRow + 1])
      setSelectedEmailIndexTableRow(selectedEmailIndexTableRow + 1)
    } else {
      console.error('SearchResult#forwardOneResult - exceed length of', data?.items.length)
    }
  }

  const selectToBulkMode = (email, selected) => {
    if (selected) {
      setSelectedRowKeys((prevSelectedRowKeys) => {
        return [
          ...prevSelectedRowKeys,
          email.id,
        ]
      })
      selectEmail(emailResultsDispatch, email)
    } else {
      setSelectedRowKeys((prevSelectedRowKeys) => {
        const newSelectedRowKeys = prevSelectedRowKeys.filter((selectedRowKey) => {
          return selectedRowKey !== email.id
        })

        return newSelectedRowKeys
      })
      deselectEmail(emailResultsDispatch, email)
    }
  }

  useEffect(() => {
    clearEmailSelection()
  }, [loading, clearEmailSelection])

  if (hasError) {
    return (
      <Result
        status='error'
        title={'We\'ve encountered some query issues, please check your query'}
      />
    )
  }

  if (loading) return <Spin />

  const renderAsTable = () => {
    const analyzeEmail = (item) => {
      return () => {
        clickToAnalyzeEmail({ body: item.body, countryOfEmployment: item.country_of_employment, capId: item.cap_id })
      }
    }

    const onSelectChange = (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys)
    }

    const onSelectRow = (record, selected) => {
      selectEmail(emailResultsDispatch, record)

      if (!selected) deselectEmail(emailResultsDispatch, record)
    }

    const onSelectAll = (selected, selectedRows) => {
      setSelectAll(emailResultsDispatch, selected, selectedRows)
    }

    const onSelectMultiple = (selected, selectedRows) => {
      // selected: showing what operation has 'done'
      // selectedRows: final remaining row after changed
      // changeRows (third params): row(s) that has been selected

      selectEmails(emailResultsDispatch, selectedRows)
    }

    const columns = [
      {
        title: () => {
          return (
            <Typography.Text strong>
              {`Content${selectedRowKeys.length > 0 ? ` [Selected ${selectedRowKeys.length} item${selectedRowKeys.length > 1 ? 's' : ''}]` : ''}`}
            </Typography.Text>
          )
        },
        render: (item) => {
          return (
            <EmailContent
              analyzeEmail={analyzeEmail(item)}
              showingMode={showRawEmail ? 'raw' : 'cleaned'}
              isNewEmailOrMissingStatus={(!item.status || item.status === 'NEW')}
              content={{
                subject: item.subject,
                body: item.body,
                cleanedEmailSubject: item.cleaned_email_subject,
                cleanedEmailBody: item.cleaned_email_body,
              }}
            />
          )
        },
      },
      // {
      //    We don't need it for now, it would be later
      //   title: () => <Typography.Text strong>Source</Typography.Text>,
      //   key: 'source',
      //   dataIndex: 'source',
      // },
      {
        title: () => { return <Typography.Text strong>Status</Typography.Text> },
        key: 'status',
        className: style.AlignTop,
        render: (item) => {
          return (
            <Status
              emailStatus={item.status}
              statusList={emailSearchResultsState.statusList}
              onSelect={async (newStatus) => {
                await Api.Emails.upsert({
                  id: item.id,
                  status: newStatus,
                  datasource,
                  version: currentVersion,
                })

                updateSpecificEmailStatus(item.id, newStatus)
              }}
            />
          )
        },
      },
      {
        title: () => { return <Typography.Text strong>Dataset Type</Typography.Text> },
        key: 'datasetType',
        className: style.AlignTop,
        render: (item) => {
          return `${item?.datasetType || 'ANY'}`
        },
      },
      {
        title: () => { return <Typography.Text strong>Actions</Typography.Text> },
        key: 'action',
        className: style.AlignTop,
        width: 140,
        render: (item, _, index) => {
          return (
            <EmailActions
              analyzeEmail={analyzeEmail(item)}
              viewIntentTrainings={viewEmail(item, index, 'intents')}
              viewDataExtraction={viewEmail(item, index, 'dataExtraction')}
              viewEmailChecking={viewEmail(item, index, 'emailChecking')}
            />
          )
        },
      },
      {
        title: () => { return <Typography.Text strong>Feedback</Typography.Text> },
        key: 'feedback',
        className: style.AlignTop,
        width: 140,
        render: (item) => {
          return (<Feedback sentiment={item.feedback?.sentiment} />)
        },
      },
    ]

    return (
      <Table
        bordered
        dataSource={data?.items ?? []}
        rowKey='id'
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
          onSelect: onSelectRow,
          onSelectAll,
          onSelectMultiple,
        }}
        columns={columns}
        size='small'
        pagination={{
          showQuickJumper: true,
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          pageSize: data?.meta.perPage || 10,
          showTotal: (total, range) => { return (`${range[0]} - ${range[1]} of ${total} items`) },
          current: data?.meta.page,
          defaultPageSize: data?.meta.perPage,
          total: data?.meta.totalCount,
          onChange: onPaginationChange,
        }}
        onChange={clearEmailSelection}
      />
    )
  }

  return (
    <div className={style.SearchResults}>
      <header>
        <Typography.Title level={5}>{`Total: ${data?.meta?.totalCount.toLocaleString()} email${data?.meta?.totalCount === 1 ? '' : 's'}`}</Typography.Title>
        <Space>
          <Typography.Text>Show Raw Email Subject/Body</Typography.Text>
          <Switch
            defaultChecked={showRawEmail}
            onChange={() => { setShowRawEmail((prev) => { return (!prev) }) }}
          />
        </Space>
        {(emailsPermission?.actionBulkMode || emailsPermission?.actionViewBulkHistory) && (
          <Space>
            {emailsPermission?.actionBulkMode && (
              <Button
                type='primary'
                disabled={!emailSearchResultsState?.data?.length}
                onClick={openBulkEmailModal}
              >
                View selected
              </Button>
            )}
            {emailsPermission?.actionViewBulkHistory && (
              <Button
                type='primary'
                onClick={openBulkHistoryEmailModal}
              >
                View bulk history
              </Button>
            )}
          </Space>
        )}
      </header>
      {renderAsTable()}
      {emailsPermission?.actionBulkMode && (
        <footer style={{ textAlign: 'right' }}>
          <Button
            type='primary'
            disabled={!emailSearchResultsState?.data?.length}
            onClick={openBulkEmailModal}
          >
            View selected
          </Button>
        </footer>
      )}
      {
        viewEmailModalVisible && (
          <ViewEmailModal
            initiallySelectedTab={initiallySelectedTab}
            hasBeenSelectedForBulkMode={selectedRowKeys.includes(selectedEmail.id)}
            statusList={emailSearchResultsState.statusList}
            currentPaginationLength={data?.items.length}
            selectedEmailIndexTableRow={selectedEmailIndexTableRow + 1}
            paginationThreshold={{
              isCurrentlyFirstItem: selectedEmailIndexTableRow === 0,
              isCurrentlyLastItem: selectedEmailIndexTableRow === data?.items.length - 1,
            }}
            selectedEmail={selectedEmail}
            visible={viewEmailModalVisible}
            closeViewEmailModal={closeViewEmailModal}
            // Hotkeys
            backwardOneResult={backwardOneResult}
            forwardOneResult={forwardOneResult}
            // select item from inside
            selectToBulkMode={selectToBulkMode}
            updateSpecificEmailStatus={updateSpecificEmailStatus}
            updateSpecificEmailIntentSamples={updateSpecificEmailIntentSamples}
            updateSpecificEmailDatasetType={updateSpecificEmailDatasetType}
          />
        )
      }
      {
        emailsPermission?.actionBulkMode && (
          bulkModalVisible && (
            <BulkEmailModal
              visible={bulkModalVisible}
              clearEmailSelection={clearEmailSelection}
              closeBulkEmailModal={closeBulkEmailModal}
              responseEmails={data}
              statusList={emailSearchResultsState.statusList}
              refreshSearchResult={silentMakeRequest}
              updateSpecificEmailIntentSamples={updateSpecificEmailIntentSamples}
              updateMultipleEmailIntentSamples={updateMultipleEmailIntentSamples}
              updateMultipleEmailStatus={updateMultipleEmailStatus}
            />
          )
        )
      }
    </div>
  )
}

SearchResult.defaultProps = {
  filter: {},
  clickToAnalyzeEmail: () => { },
}

SearchResult.propTypes = {
  filter: PropTypes.shape({
    // pagination
    page: PropTypes.number,
    perPage: PropTypes.number,
    // actual email filters
    ids: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    regex: PropTypes.string,
    emailIds: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    trainIntentIds: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
    predictedIntentNames: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
  }),
  openBulkHistoryEmailModal: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  clickToAnalyzeEmail: PropTypes.func,
}

export default SearchResult
