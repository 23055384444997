import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Typography,
  Button,
  Popover,
  Empty,
  Space,
  notification,
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { reHashIndex } from '@core/helpers'
import { transformExchangeToRenderUI } from '../../../helpers'
import ChatExchanges from '../ChatExchanges'
import ExchangeTestCase from './ExchangeTestCase'
import EvaluateTests from './EvaluateTests'
import style from './index.module.scss'
import { evaluate } from '../../../hooks/useEndToEndTest'
import { e2eStateType, simulationInfoPropShape } from '../../../types'

const NoSimulationSelected = ({ switchToLoadSimulationTab }) => {
  return (
    <Empty
      description={(
        <span>
          {'Please select '}
          <button
            type='button'
            onClick={switchToLoadSimulationTab}
            className={style.redirectToLoadSimulation}
          >
            existing simulation
          </button>
          {' to start End to End testing'}
        </span>
      )}
    />
  )
}

NoSimulationSelected.propTypes = {
  switchToLoadSimulationTab: PropTypes.func.isRequired,
}

const SelectedSimulationInformation = ({
  id,
  note,
  created_at,
  updated_at,
  created_by,
}) => {
  return (
    <Popover
      placement='right'
      content={(
        <dl>
          <dt><Typography.Text strong>ID</Typography.Text></dt>
          <dd>{id}</dd>
          <dt><Typography.Text strong>Note</Typography.Text></dt>
          <dd>{note}</dd>
          <dt><Typography.Text strong>Created at</Typography.Text></dt>
          <dd>{created_at}</dd>
          <dt><Typography.Text strong>Updated at</Typography.Text></dt>
          <dd>{updated_at}</dd>
          <dt><Typography.Text strong>Created by</Typography.Text></dt>
          <dd>{created_by}</dd>
        </dl>
      )}
    >
      <Button type='link' icon={<QuestionCircleOutlined />} size='small'>
        Saved Simulation Details (Hover)
      </Button>
    </Popover>
  )
}

SelectedSimulationInformation.defaultProps = {
  id: 'N/A',
  note: 'N/A',
  created_at: 'N/A',
  updated_at: 'N/A',
  created_by: 'N/A',
}

SelectedSimulationInformation.propTypes = simulationInfoPropShape

const EndToEndTest = ({ state, dispatch, switchToLoadSimulationTab, updateExchangeTestCase, addExchangeTestCase }) => {
  const [currentViewExchange, setCurrentViewExchange] = useState(null)
  const {
    loading,
    data: {
      simulationInfo,
      testCases,
      rawExchanges,
      exchangesHashPairedWithTestCases,
    },
  } = state

  if (!Object.entries(simulationInfo).length) {
    return <NoSimulationSelected switchToLoadSimulationTab={switchToLoadSimulationTab} />
  }

  const {
    id,
    note,
    created_at,
    updated_at,
    created_by,
  } = simulationInfo

  const uiExchageRenderData = transformExchangeToRenderUI(rawExchanges)
  const hashUiExchageRenderData = reHashIndex(uiExchageRenderData)

  const setActiveViewExchange = (exchangeId) => {
    setCurrentViewExchange(exchangeId)
  }

  const evaluateTestCases = async () => {
    try {
      const testCasesToEvaluate = testCases.filter(({ skip }) => { return (skip === false) }).map((testCase) => { return (testCase.id) })
      const response = await evaluate(dispatch, testCasesToEvaluate)

      if (response.data && response.data.length) {
        const [evaluated] = response.data
        const { exchangeSimulations } = evaluated

        return exchangeSimulations.reduce((evaluatedExchangeSimulationsHash, exchange) => {
          evaluatedExchangeSimulationsHash[exchange.id] = {
            id: exchange.id,
            conversation_simulation_id: exchange.conversation_simulation_id,
            testCaseSimulationsHash: exchange.testCaseSimulations.reduce((testCaseSimulationsHashObj, testCase) => {
              testCaseSimulationsHashObj[testCase.id] = testCase

              return testCaseSimulationsHashObj
            }, {}),
          }

          return evaluatedExchangeSimulationsHash
        }, {})
      }

      return {}
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'We\'ve run into the issue from evaluating test case',
        duration: 5,
      })

      throw error
    }
  }

  return (
    <div className={style.EndToEnd}>
      {/* NOTE: End-to-End headers part */}
      <Space align='baseline'>
        <Typography.Title level={4}>End to End Test</Typography.Title>
        <SelectedSimulationInformation
          id={id}
          note={note}
          created_at={created_at}
          updated_at={updated_at}
          created_by={created_by}
        />
      </Space>
      {/* NOTE: End-to-End contents */}
      <Row gutter={[36, 0]}>
        <Col span={8}>
          <Typography.Title level={5}>Conversation</Typography.Title>
          <div className={style.ChatExchanges}>
            <ChatExchanges
              dispatcher={dispatch}
              exchanges={uiExchageRenderData}
              showExchangeOptions={false}
              onExchangeClick={setActiveViewExchange}
              activeCurrentViewExchange={currentViewExchange}
              endToEndMode
            />
          </div>
        </Col>
        <Col span={8}>
          <Typography.Title level={5}>Apply Test Case</Typography.Title>
          <ExchangeTestCase
            addExchangeTestCase={addExchangeTestCase}
            updateExchangeTestCase={updateExchangeTestCase}
            activeCurrentViewExchange={currentViewExchange}
            exchange={uiExchageRenderData[hashUiExchageRenderData[currentViewExchange]]}
            exchangesHashPairedWithTestCases={exchangesHashPairedWithTestCases}
            loading={loading} // block any request during evaluating
          />
        </Col>
        <Col span={8}>
          {/* This one needs to give full exchanges list */}
          {/* But then it needs to have the test case for each one also */}
          <Typography.Title level={5}>Running Test(s)/Results</Typography.Title>
          <EvaluateTests
            loading={loading}
            exchanges={uiExchageRenderData}
            evaluateTestCases={evaluateTestCases}
            exchangesHashPairedWithTestCases={exchangesHashPairedWithTestCases}
          />
        </Col>
      </Row>
    </div>
  )
}

EndToEndTest.propTypes = {
  state: e2eStateType.isRequired,
  dispatch: PropTypes.func.isRequired,
  switchToLoadSimulationTab: PropTypes.func.isRequired,
  updateExchangeTestCase: PropTypes.func.isRequired,
  addExchangeTestCase: PropTypes.func.isRequired,
}

export default EndToEndTest
