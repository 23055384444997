import PropTypes from 'prop-types'

const {
  bool,
  oneOf,
} = PropTypes

export const domainCaseExistType = bool
export const intentCaseExistType = bool
export const taggerCaseExistType = bool
export const extractorCaseExistType = bool

export const caseType = oneOf(['Test', 'Training'])
