import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Result, Typography, Button, Space, Popconfirm, notification } from 'antd'

import useRequest from '@core/hooks/useRequest'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import { shallowCleanFalsyObject, sortTextOrNull } from '@core/helpers'

import EditClientGroupModal from '../../../components/EditClientGroupModal'
import DataTable from '../../../components/DataTable'
import Api from '../../../api'

const DataTab = ({
  timeFormatter,
  filter,
  handlePaginationChange,
  triggerSearch,
}) => {
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editModalInitialValues, setEditModalInitialValues] = useState({})
  const [{ loading, data, hasError }] = useRequest(Api.ClientGroups.findMany, shallowCleanFalsyObject(filter))

  const onEditModalClicked = (record) => {
    setEditModalInitialValues(record)
    setEditModalVisible(true)
  }

  const onSetEditModalVisibility = (visible) => {
    setEditModalVisible(visible)
    setEditModalInitialValues({})
  }

  const onDelete = async (id) => {
    try {
      await Api.ClientGroups.deleteById(id)
      notification.success({
        message: 'Success',
        description: 'Deleted',
      })
      triggerSearch()
    } catch (error) {
      console.log(error)
    }
  }

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }

  const columnsTable = [
    {
      title: (<strong>ID</strong>),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: (<strong>Name</strong>),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return sortTextOrNull(a.name, b.name) },
    },
    {
      title: (<strong>Description</strong>),
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => { return sortTextOrNull(a.description, b.description) },
      render: (description) => {
        return (
          <Typography.Text
            style={{ width: 150 }}
            ellipsis={{ tooltip: description }}
          >
            {description}
          </Typography.Text>
        )
      },
    },
    {
      title: (<strong>Type</strong>),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => { return sortTextOrNull(a.type, b.type) },
    },
    {
      title: (<strong>CAP ID</strong>),
      dataIndex: 'capIds',
      sorter: (a, b) => { return a.capIds.join().localeCompare(b.capIds.join()) },
      render: (capIds) => { return <Typography.Text>{capIds.join(',')}</Typography.Text> },
    },
    {
      title: (<strong>Country of Employment</strong>),
      dataIndex: 'countryOfEmployment',
      sorter: (a, b) => { return a.countryOfEmployment.join().localeCompare(b.countryOfEmployment.join()) },
      render: (countryOfEmployment) => { return <Typography.Text>{countryOfEmployment.join(',')}</Typography.Text> },
    },
    {
      title: (<strong>Created At</strong>),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 190,
      sorter: (a, b) => { return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime() },
      render: (createdAt) => {
        if (timeFormatter) {
          return <Typography.Text>{timeFormatter(createdAt)}</Typography.Text>
        }
        return <Typography.Text>{createdAt}</Typography.Text>
      },
    },
    {
      title: (<strong>Updated At</strong>),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 190,
      sorter: (a, b) => { return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime() },
      render: (updatedAt) => {
        if (!updatedAt) return <Typography.Text>-</Typography.Text>
        if (timeFormatter) {
          return <Typography.Text>{timeFormatter(updatedAt)}</Typography.Text>
        }
        return <Typography.Text>{updatedAt}</Typography.Text>
      },
    },
    {
      title: (<strong>Actions</strong>),
      key: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Button type='secondary' size='small' onClick={() => { return onEditModalClicked(record) }}>Edit</Button>
            <Popconfirm
              title={(
                <>
                  <div>
                    <Typography.Text>Delete?</Typography.Text>
                  </div>
                  <Typography.Text>Are you sure you want to delete&nbsp;</Typography.Text>
                  <Typography.Text strong>{`'${record.name}'`}</Typography.Text>
                  <br />
                  <Typography.Text>This will affect only&nbsp;</Typography.Text>
                  <Typography.Text strong>{`'${record.environment}'`}</Typography.Text>
                  <br />
                  <Typography.Text>other environment won&apos;t be affected.</Typography.Text>
                </>
              )}
              onConfirm={() => { return onDelete(record.id) }}
              okText='Yes'
              cancelText='No'
            >
              <Button danger type='primary' size='small'>Delete</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const { items, meta } = data

  return (
    <>
      <DataTable
        columns={columnsTable}
        dataSource={items}
        meta={meta}
        filter={filter}
        onPaginationChange={handlePaginationChange}
      />
      {editModalInitialValues && (
        <EditClientGroupModal
          key={editModalInitialValues.id}
          modalVisibility={editModalVisible}
          setModalVisibility={onSetEditModalVisibility}
          initialValues={editModalInitialValues}
          triggerSearch={triggerSearch}
        />
      )}
    </>
  )
}

DataTab.propTypes = {
  timeFormatter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
}

export default DataTab
