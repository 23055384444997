import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as Sentry from '@sentry/react'

// NOTE: For some reason, alias does not work here even with jsconfig.json and craco.config.js
import { AUTH } from '@core/store/constants'

import { middlewareEnhancers } from './middlewares'
import { versionizedAppReducer } from './reducers'

export const configureStore = (initialState) => {
  const composeWithDevToolsArgs = [middlewareEnhancers]

  if (process.env.NODE_ENV !== 'development') {
    // Rel to docs: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#redux-enhancer-options
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
      // Optionally pass options listed below
      actionTransformer: (action) => {
        if (action.type === AUTH.LOGIN.SUCCESS) {
          // Return null to not log the action to Sentry
          return {
            ...action,
            payload: null,
          }
        }

        return action
      },
    })

    composeWithDevToolsArgs.push(sentryReduxEnhancer)
  }

  return createStore(
    versionizedAppReducer,
    initialState,
    composeWithDevTools(...composeWithDevToolsArgs),
  )
}

const store = configureStore({})

export default store
