import PropTypes from 'prop-types'

const { shape, string, arrayOf, func } = PropTypes

export const entityType = shape({
  entities: arrayOf(shape({
    value: string,
    synonyms: arrayOf(string),
  })),
  name: string,
})
export const setEditModalVisibleType = func
export const setDeleteModalVisibleType = func
