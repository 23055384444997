import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { capitalizeWord } from '@core/helpers'
import { Table, Typography, Space, Button, Alert } from 'antd'

import { sortIntentTrainingCaseApprovalStatus } from '../../../../../../helpers'

import ApprovalActions from './ApprovalActions'

import style from './style.module.scss'

const approvalTypePastTense = {
  approve: 'approved',
  reject: 'rejected',
}

const IntentsList = ({
  loading,
  intents,
  intentApprovalSuccess,
  intentApprovalError,
  deleteIntentTagging,
  approveIntentTagging,
  rejectIntentTagging,
  approveAllIntentTagging,
  rejectAllIntentTagging,
}) => {
  const sortedByStatus = sortIntentTrainingCaseApprovalStatus(intents)
  const shouldDisableAllApprovalButtonAction = sortedByStatus.every((intent) => { return intent.approval_status !== 'SUBMITTED' })
  const shouldShowActionsColumn = useSelector(({ core }) => {
    // If a user does not have permission to Delete/Approve/Reject, the whole column should not displayed.
    // well, it means...if some of them are true then show
    const { permittedFeatures: { authorization } } = core
    const { transformed: { emailautomations } } = authorization

    return [
      emailautomations.intentTrainingData.actionApprove,
      emailautomations.intentTrainingData.actionDelete,
      emailautomations.intentTrainingData.actionReject,
    ].some((item) => { return item === true })
  })

  const deleteTraining = (intent) => {
    return () => {
      deleteIntentTagging(intent)
    }
  }

  const approveTraining = (intent) => {
    return () => {
      approveIntentTagging(intent)
    }
  }

  const rejectTraining = (intent) => {
    return () => {
      rejectIntentTagging(intent)
    }
  }

  const generatingColumns = () => {
    const columns = [
      {
        title: () => { return <Typography.Text strong>Training Case</Typography.Text> },
        fixed: 'left',
        key: 'name',
        dataIndex: 'name',
      },
      /* NOTE: We're not using 'sorter' here The intention here is make it forced-sort, not sort 'by user' */
      {
        title: () => { return <Typography.Text strong>Status</Typography.Text> },
        key: 'approval_status',
        dataIndex: 'approval_status',
        fixed: 'left',
        width: 86,
        render: (status) => {
          return (
            <Typography.Text className={classnames(style.IntentTrainingApprovalStatus, style[status])}>{capitalizeWord(status) ?? 'N/A'}</Typography.Text>
          )
        },
      },
    ]

    if (shouldShowActionsColumn) {
      columns.push({
        title: () => { return <Typography.Text strong>Actions</Typography.Text> },
        key: 'action',
        fixed: true,
        width: 100,
        render: (intentTrainingCase) => {
          // - if somehow approval_status field is missing don't allow to do anything
          if (!intentTrainingCase.approval_status) return (<Typography.Text>N/A</Typography.Text>)

          // - when it is approved/rejected, those two buttons should be disabled
          const alreadyChecked = ['APPROVED', 'REJECTED'].indexOf(intentTrainingCase.approval_status) !== -1

          // - if the status is 'UNAVAILABLE' DO NOT show buttons, this is EDGE CASE! should not ever happen
          if (intentTrainingCase.approval_status === 'UNAVAILABLE') return null

          return (
            <ApprovalActions
              deleteTraining={deleteTraining(intentTrainingCase)}
              approveTraining={alreadyChecked ? () => {} : approveTraining(intentTrainingCase)}
              rejectTraining={alreadyChecked ? () => {} : rejectTraining(intentTrainingCase)}
              alreadyChecked={alreadyChecked}
              isLoading={loading}
            />
          )
        },
      })
    }

    return columns
  }

  return (
    <>
      <div className={style.IntentTrainingCaseTitle}>
        <Typography.Title level={5}>Training Cases</Typography.Title>
        <Space>
          <Button
            disabled={loading || shouldDisableAllApprovalButtonAction}
            className={style.RejectAll}
            size='small'
            onClick={rejectAllIntentTagging}
          >
            Reject All
          </Button>
          <Button
            disabled={loading || shouldDisableAllApprovalButtonAction}
            className={style.ApproveAll}
            size='small'
            onClick={approveAllIntentTagging}
          >
            Approve All
          </Button>
        </Space>
      </div>
      {intentApprovalError && (
        <Alert
          className={style.AlertFeedback}
          closable
          message={`Error: Cannot ${intentApprovalError.type ?? 'approve/reject'} intent(s)`}
          type='error'
        />
      )}
      {intentApprovalSuccess && (
        <Alert
          className={style.AlertFeedback}
          closable
          message={`Success: Intent(s) has been ${approvalTypePastTense[intentApprovalSuccess.type] ?? 'approved/rejected'}`}
          type='success'
        />
      )}
      <Table
        bordered
        rowKey='id'
        size='small'
        loading={loading}
        columns={generatingColumns()}
        dataSource={sortedByStatus}
      />
    </>
  )
}

IntentsList.defaultProps = {
  intents: [],
}

IntentsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  intents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  intentApprovalSuccess: PropTypes.bool.isRequired,
  intentApprovalError: PropTypes.bool.isRequired,
  deleteIntentTagging: PropTypes.func.isRequired,
  approveIntentTagging: PropTypes.func.isRequired,
  rejectIntentTagging: PropTypes.func.isRequired,
  approveAllIntentTagging: PropTypes.func.isRequired,
  rejectAllIntentTagging: PropTypes.func.isRequired,
}

export default IntentsList
