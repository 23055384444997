const Storage = {
  getRaw() {
    return window.sessionStorage
  },
  setSession() {
    window.localStorage.removeItem('LOUISE--remember')
  },
  setItem(key, value) {
    if (typeof value !== 'undefined' && !Number.isNaN(value)) {
      return Storage.getRaw().setItem(`LOUISE--${key}`, value)
    }

    return undefined
  },
  getLength() {
    return Storage.getRaw().length
  },
  getItem(key) {
    return Storage.getRaw().getItem(`LOUISE--${key}`)
  },
  removeItem(key) {
    window.sessionStorage.removeItem(`LOUISE--${key}`)
  },
  clearAll() {
    Storage.getRaw().clear() // Safe for both local/session
  },
  purge() {
    window.sessionStorage.clear()
    window.localStorage.clear()
  },
  getItemFromSession(key) {
    return window.sessionStorage.getItem(`LOUISE--${key}`)
  },
  setItemIntoSession(key, value) {
    if (typeof value !== 'undefined' && !Number.isNaN(value)) {
      return window.sessionStorage.setItem(`LOUISE--${key}`, value)
    }
    return undefined
  },
  getMultipleItems() {
    const rawStorage = Storage.getRaw()
    const sessionProperties = {}

    Object.keys(rawStorage).forEach((propertyName) => {
      // strip LOUISE-- first
      const keyName = propertyName.replace('LOUISE--', '')
      // assign the striped name as keyname,
      // use propertyName (original name) to get from the rawStorage
      sessionProperties[keyName] = rawStorage[propertyName]
    })
    return sessionProperties
  },
  setMultipleItems(items) {
    if (typeof items !== 'object') {
      throw new Error(
        'StorageUtils#setMultipleItems: Expected object of query parameters',
      )
    }

    // Might need to change to for-loop, or a synchronous iterative
    Object.keys(items).forEach((property) => {
      Storage.setItem(property, items[property])
    })
  },
}

export default Storage
