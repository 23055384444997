import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactJsonView from 'react-json-view'
import { Radio, Row } from 'antd'
import style from './index.module.scss'
import autoSizeStyle from './autoSize.module.scss'

const ExpandableJsonPreview = ({
  content,
  autoSize,
  mode,
  collapsedLevel,
  collapseStringsAfterLength,
}) => {
  const [displayMode, setDisplayMode] = useState('text')
  const [parseJsonError, setParseJsonError] = useState(false)
  const [parseJson, setParseJson] = useState({})

  useEffect(() => {
    setDisplayMode(mode)
  }, [mode])

  useEffect(() => {
    try {
      const jsonData = JSON.parse(content)
      if (typeof jsonData !== 'object') throw new Error()
      setParseJson(jsonData)
      setParseJsonError(false)
    } catch (error) {
      setParseJsonError(true)
      setDisplayMode('text')
    }
  }, [content])

  let selectedStyle
  if (autoSize) selectedStyle = autoSizeStyle.JsonPreview
  else selectedStyle = style.JsonPreview

  return (
    <>
      <Row align='end'>
        <Radio.Group
          buttonStyle='solid'
          value={displayMode}
          onChange={(event) => {
            setDisplayMode(event.target.value)
          }}
        >
          <Radio.Button value='text'>Text</Radio.Button>
          <Radio.Button value='tree' disabled={parseJsonError}>Tree</Radio.Button>
        </Radio.Group>
      </Row>
      {displayMode === 'tree' && (
        <ReactJsonView
          src={parseJson}
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
          name={false}
          collapsed={collapsedLevel}
          collapseStringsAfterLength={collapseStringsAfterLength}
        />
      )}
      {displayMode === 'text' && (
        <pre className={selectedStyle}>
          {content}
        </pre>
      )}
    </>

  )
}

ExpandableJsonPreview.defaultProps = {
  content: '',
  autoSize: false,
  mode: 'text',
  collapsedLevel: false,
  collapseStringsAfterLength: false,
}

ExpandableJsonPreview.propTypes = {
  content: PropTypes.string,
  autoSize: PropTypes.bool,
  mode: PropTypes.string,
  collapsedLevel: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  collapseStringsAfterLength: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}

export default ExpandableJsonPreview
