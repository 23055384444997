import store from '../../../setup/store'

// we might need to save this to store
// this runs on every mode change
export const splitGeneralizedSentenceByHighlighted = (generalizedSentence) => {
  return generalizedSentence.split(/((?:Gs|Gx|Gc)[a-zA-Z]*)/)
  .filter((text) => { return text })
  .map((text) => {
    const color = store.getState().core.generalizers.colors
    const backgroundColor = color[text]
    const isTag = Boolean(backgroundColor)
    const item = { text: text.trim(), isTag }
    if (isTag) {
      const [hue, saturation, lightness] = backgroundColor
      item.backgroundColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`
      item.color = ((hue > 200 && hue < 300) || (hue > 320 || hue < 20) ? 'white' : 'black')
    }
    return item
  })
  .filter((item) => { return item.text !== '' })
}
