import { shallowEqual, useSelector } from 'react-redux'

export const useSelectedSentences = () => {
  return useSelector(({ data: { bulkOperationData, sentences } }) => {
    const selectedSentencesIds = bulkOperationData.selectedSentences
    const { listed, sentencesHash } = sentences

    if (!selectedSentencesIds) return []

    return selectedSentencesIds.map((sentenceId) => {
      const {
        id,
        source,
        text,
        data,
        generalized,
        stopwordsRemoved,
        generalizedHtml,
        cursor,
      } = listed[sentencesHash[sentenceId]]

      return {
        id,
        source,
        text,
        data,
        generalized,
        stopwordsRemoved,
        generalizedHtml,
        cursor,
      }
    })
  }, shallowEqual)
}
