import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Button,
  Space,
  Modal,
  notification,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import Api from '../../../api'

/*
  Columns - Category, Subcategory, Main Sentence

  unless rendering multiple value in the same field, use array of string as path on dataIndex is possible
*/
const QueryResult = ({
  loading,
  data,
  refreshResult,
  openEditFaqModal,
  fetchOnTableChange,
  setTablePagination,
}) => {
  const confirmDeleteFaqEntity = (id) => {
    return () => {
      Modal.confirm({
        title: 'Do you want to delete these faq entities?',
        className: 'faq__entity--delete_confirmation',
        icon: <ExclamationCircleOutlined />,
        content: 'When you confirmed, wait for the dialog box to close itself, do not manually close',
        keyboard: false,
        okButtonProps: {
          danger: true,
        },
        onOk: async () => {
          try {
            const response = await Api.deleteFaq(id)

            if (response && response.status === 'success') {
              await refreshResult()
            }
          } catch (error) {
            notification.error({
              message: 'Error',
              description: error.message,
              duration: 5,
            })
          }
        },
        onCancel: () => {},
      })
    }
  }

  const columns = [
    {
      title: 'Category',
      dataIndex: ['category', 'name'],
    },
    {
      title: 'Subcategory',
      dataIndex: ['subcategory', 'name'],
    },
    {
      title: 'Main Question Text',
      key: 'main_question',
      dataIndex: ['main_question', 'text'],
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => { return <span>{dayjs(value).format('DD MMM YYYY@HH:mm')}</span> },
    },
    {
      title: 'Action',
      key: 'action',

      render: (faqEntity) => {
        return (
          <Space>
            <Button
              type='link'
              onClick={() => {
                openEditFaqModal(faqEntity)
              }}
            >
              Edit
            </Button>
            <Button
              type='link'
              danger
              onClick={confirmDeleteFaqEntity(faqEntity.id)}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const {
    items,
    meta,
  } = data

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={items}
      rowKey='id'
      rowClassName='faq__entity'
      onChange={fetchOnTableChange}
      pagination={{
        position: ['topRight', 'bottomRight'],
        showSizeChanger: true,
        showTotal: (total, range) => { return `${range[0]} - ${range[1]} of ${total} items` },
        current: meta.page,
        defaultPageSize: meta.perPage,
        total: meta.totalCount,
        onChange: (page, pageSize) => {
          setTablePagination({ page, pageSize })
        },
      }}
      expandable={{
        expandedRowRender: (record) => { return <pre style={{ margin: 0 }}>{JSON.stringify(record, null, 2)}</pre> },
        rowExpandable: (record) => { return record.id !== 'Not Expandable' },
      }}
    />
  )
}

QueryResult.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.objectOf(PropTypes.number),
  }).isRequired,
  refreshResult: PropTypes.func.isRequired,
  openEditFaqModal: PropTypes.func.isRequired,
  fetchOnTableChange: PropTypes.func.isRequired,
  setTablePagination: PropTypes.func.isRequired,
}

export default QueryResult
