// import store from '../../../setup/store' // Died, import order screwed up

// cannot put a wrapActionCreatorWithDispatch to here because it will cause
// dependency instantiation issue

export default {
  createActionStatus: (actionName) => {
    return {
      REQUEST: `${actionName}/REQUEST`,
      SUCCESS: `${actionName}/SUCCESS`,
      FAILED: `${actionName}/FAILED`,
      RESET: `${actionName}/RESET`,
    }
  },
  createPostActionStatus: (actionName) => {
    return {
      SUBMIT: `${actionName}/REQUEST`,
      SUCCESS: `${actionName}/SUCCESS`,
      FAILED: `${actionName}/FAILED`,
      RESET: `${actionName}/RESET`,
    }
  },
}
