import React, { useState, useContext } from 'react'
import { Button, Table, Typography, Tag, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import useRequest from '@core/hooks/useRequest'
import { EmailAutomationsContext } from '../../../context'
import Api from '../../../api'
import style from './style.module.scss'
import IntentEvaluationExpandedDetails from '../IntentEvaluationsMetric/IntentEvaluationExpandedDetails'

const PUBLISHED = 'PUBLISHED'
const initialPagination = {
  page: 1,
  perPage: 20,
}

const TrainedIntentModels = () => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [emailSampleIds, setSampleEmailIds] = useState([])
  const [emailSamples, setEmailSamples] = useState({})
  const [emailsLoading, setEmailsLoading] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [intentNameModal, setIntentNameModal] = useState('')
  const [emailPagination, setEmailPagination] = useState(initialPagination)
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)

  const [
    { loading, data: models, hasError },
    { makeRequest },
  ] = useRequest(Api.Models.getModels, { version: currentVersion, datasource })

  const getEmails = async ({ page, perPage }, emailIds) => {
    setEmailsLoading(true)
    const response = await Api.Emails.getEmails({
      version: currentVersion,
      datasource,
      page,
      perPage,
    }, {
      ids: emailIds?.length >= 1
        ? emailIds
        : ['initial'],
    })
    setEmailsLoading(false)
    setEmailSamples(response?.data ?? {})
  }

  const handleClickChart = async ({ emailIds = [], type, intentName }) => {
    setIsEmailModalOpen(true)
    setGroupName(type)
    setIntentNameModal(intentName)
    setEmailPagination(initialPagination)
    setSampleEmailIds(emailIds)
    await getEmails(initialPagination, emailIds)
  }

  const [isPublishing] = useState(false)

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => { return created_at },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return (
          <Tag color={status === PUBLISHED ? 'success' : 'blue'}>
            {status || 'DRAFTED'}
          </Tag>
        )
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: (model) => {
        const handlePublishClick = async () => {
          Modal.confirm({
            title: 'Publish',
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            content: `Assigning intent {${model.id}} to ${currentVersion}`,
            onOk: async () => {
              await Api.Models.publishModel({
                version: currentVersion, datasource, modelId: model.id,
              })
              await makeRequest()
            },

            onCancel: () => { },
          })
        }

        const isPublished = model.status === PUBLISHED

        return (
          <Button
            type='link'
            disabled={isPublished}
            loading={loading}
            onClick={handlePublishClick}
          >
            Publish
          </Button>
        )
      },
    },
  ]

  const onPaginationChange = async (page, perPage) => {
    setEmailPagination({ page, perPage })
    await getEmails({ page, perPage }, emailSampleIds)
  }

  const emailColumns = [
    { title: 'id', dataIndex: 'id', className: style.AlignTop, width: '200px' },
    { title: 'subject', dataIndex: 'subject', className: style.AlignTop, width: '300px' },
    { title: 'body', dataIndex: 'body', fixed: true },
  ]

  return (
    <>
      {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      <Table
        rowKey='id'
        className='components-table-demo-nested'
        loading={isPublishing || loading}
        columns={columns}
        dataSource={models || []}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <IntentEvaluationExpandedDetails key={record.id} id={record.id} handleClickChart={handleClickChart} />
            )
          },
        }}
      />

      <Modal
        title={(
          <span>
            {`${groupName} emails of `}
            <b>{intentNameModal}</b>
          </span>
        )}
        visible={isEmailModalOpen}
        onCancel={() => { setIsEmailModalOpen(!isEmailModalOpen) }}
        width='auto'
        footer={null}
      >
        <Table
          rowKey='id'
          dataSource={emailSamples?.items || []}
          columns={emailColumns}
          loading={emailsLoading}
          pagination={{
            pageSize: emailPagination.perPage,
            current: emailPagination.page,
            defaultPageSize: emailPagination.perPage,
            total: emailSamples?.meta?.totalCount,
            onChange: onPaginationChange,
          }}
          scroll={{ y: '600px' }}
        />
      </Modal>
    </>
  )
}

export default TrainedIntentModels
