import React from 'react'
import PropTypes from 'prop-types'
import { Result } from 'antd'

import ExpandableJsonPreview from '@core/components/ExpandableJsonPreview'
import SpinnerFillWidth from '@core/components/SpinnerFillWidth'

const ApiResponse = ({ loading, data, hasError }) => {
  if (loading) return <SpinnerFillWidth />
  if (hasError) return <Result title='Something went wrong, please try again' status='error' />

  return (
    <ExpandableJsonPreview
      content={JSON.stringify(data, null, 2)}
      mode='tree'
      collapsedLevel={1}
      autoSize
    />
  )
}

ApiResponse.defaultProps = {
  data: null,
}

ApiResponse.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasError: PropTypes.bool.isRequired,
}

export default ApiResponse
