import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Button,
  Space,
  Table,
  Popconfirm,
  Typography,
  Collapse,
} from 'antd'
import dayjs from 'dayjs'

import { useInternationalizeVersionSelector } from '@core/hooks/useInternationalize'
import InternationalActions from '@core/store/actions/Internationalize'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'

import { I18nVersionContext, getCurrentI18nVersionId } from '../../helpers/context'

import style from './index.module.scss'

const { Panel } = Collapse

const RenderI18nVersionList = ({
  data = [],
  selectedId,
  onSelect,
  onClone,
  onDelete,
}) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: {
        compare: (a, b) => { return a.id - b.id },
        multiple: 1,
      },
    },
    {
      title: 'Note',
      key: 'note',
      render: (item) => {
        const { id, note } = item

        return <Link to={`/versions/i18n/${id}/edit`}>{note}</Link>
      },
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: {
        compare: (a, b) => { return new Date(a.created_at) - new Date(b.created_at) },
        multiple: 1,
      },
      render: (value) => { return <span>{dayjs(value).format('DD MMM YYYY @ HH:mm')}</span> },
    },
    {
      title: 'Action',
      key: 'action',
      render: (item) => {
        const { id } = item

        return (
          <Space>
            {onSelect && <Button type='primary' onClick={() => { onSelect(id) }}>SELECT</Button>}
            {onClone && <Button onClick={() => { onClone(id) }}>CLONE</Button>}
            {onDelete && (
              <Popconfirm
                disabled={id === selectedId}
                title='Confirm deletion'
                onConfirm={() => { onDelete(id) }}
                okText='Yes'
                cancelText='No'
              >
                <Button danger disabled={id === selectedId} type='primary'>DELETE</Button>
              </Popconfirm>
            )}
          </Space>
        )
      },
    },
  ]

  return (
    <Table
      bordered
      size='small'
      dataSource={data}
      columns={columns}
      rowClassName={(record) => { return (record.id === selectedId ? style['table__row--selected'] : undefined) }}
    />
  )
}

RenderI18nVersionList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  selectedId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const I18nVersionPanel = ({ onClone, onDelete }) => {
  const isI18nVersionAllowed = usePermittedFeaturesLookup('conversations.simulation.actionSelectI18nVersion')
  const { i18nVersionId, setI18nVersionId } = useContext(I18nVersionContext)
  const i18nVersions = useInternationalizeVersionSelector().map((v) => { return { ...v, key: v.id } })

  useEffect(() => {
    const fetchVersion = async () => {
      await InternationalActions.getAll()
    }

    if (!i18nVersions.length) fetchVersion()
  }, [i18nVersions])

  if (!isI18nVersionAllowed) {
    return null
  }

  return (
    <RenderI18nVersionList
      data={i18nVersions}
      selectedId={i18nVersionId}
      onSelect={setI18nVersionId}
      onClone={onClone}
      onDelete={onDelete}
    />
  )
}

I18nVersionPanel.propTypes = {
  onClone: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export const I18nVersionCollapsedPanel = ({ headerText, onClone, onDelete }) => {
  const isI18nVersionAllowed = usePermittedFeaturesLookup('conversations.simulation.actionSelectI18nVersion')
  const { i18nVersionId, setI18nVersionId } = useContext(I18nVersionContext)
  const i18nVersions = useInternationalizeVersionSelector().map((v) => { return { ...v, key: v.id } })
  const currentSelectedVersion = i18nVersions.find((item) => { return item.id === i18nVersionId })

  const header = (
    <Typography.Text>
      {headerText}
      <Typography.Text code mark>
        {`[[Currently selected]] => ${currentSelectedVersion ? currentSelectedVersion?.note : ''}`}
      </Typography.Text>
    </Typography.Text>
  )

  useEffect(() => {
    const fetchVersion = async () => {
      await InternationalActions.getAll()
    }

    if (!i18nVersions.length) fetchVersion()
  }, [i18nVersions])

  if (!isI18nVersionAllowed) {
    return null
  }

  return (
    // NOTE: regarding no defaultActiveKey={['1']}
    // found on local, if there are a lot of i18n version, it will push the box up,
    // thus it cannot collapse itself, only way to make it collapse is:
    // switch to second page, and the box will recalculate top location, make it able to close
    // On Response text does not have this problem because the page itself was made to be able to scroll whole page
    <Collapse>
      <Panel header={header} key='1'>
        <RenderI18nVersionList
          data={i18nVersions}
          selectedId={i18nVersionId}
          onSelect={setI18nVersionId}
          onClone={onClone}
          onDelete={onDelete}
        />
      </Panel>
    </Collapse>
  )
}

I18nVersionCollapsedPanel.propTypes = {
  headerText: PropTypes.string,
  onClone: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

I18nVersionCollapsedPanel.defaultProps = {
  headerText: 'Select your i18n version to work with........',
}

export const I18nVersionPanelDataProvider = ({ children }) => {
  const [i18nVersionId, setI18nVersionId] = useState(getCurrentI18nVersionId())

  useEffect(() => {
    const fetchVersion = async () => {
      await InternationalActions.getAll() // no need to set, this is redux
    }

    fetchVersion()
  }, [])

  return (
    <I18nVersionContext.Provider value={{ i18nVersionId, setI18nVersionId }}>
      {children}
    </I18nVersionContext.Provider>
  )
}

I18nVersionPanelDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default I18nVersionPanel
