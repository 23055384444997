import React, { useState, useEffect, useRef } from 'react'
import { Form, notification } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { LogDisplayContext } from './useLogDisplay'
import { useUser } from '../../../../../hooks/useUser'
import api from '../../../../../api'
import { UserPropTypes } from '../../../UserPropTypes'
import { transformInputChannel } from '../../../../../utils/transformInputChannel'

const omitFieldsDefault = ['level', 'time', 'pid', 'env', 'name']

const LogDisplayProvider = ({ children, user }) => {
  const [logs, setLogs] = useState()
  const { environment } = useUser()
  const [isLoading, setIsLoading] = useState(true)
  const [loadingText, setLoadingText] = useState('')
  const [omitFields, setOmitFields] = useState(omitFieldsDefault)
  const [form] = Form.useForm()
  const abortControllerRef = useRef()

  useEffect(() => {
    form.setFieldsValue({ omitFields: omitFieldsDefault })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const textsToQuery = [
    user.metricsUserEmail,
    user.metricsUserId,
    user.metricsUserEmail,
  ]

  const loadLog = async () => {
    abortControllerRef.current?.abort()
    abortControllerRef.current = new AbortController()
    setIsLoading(true)
    const channel = transformInputChannel(user.fromChannel)
    const params = []
    const { dateRange } = form.getFieldsValue()

    if (dateRange) {
      const start = Date.parse(dateRange[0]) / 1000
      const end = Date.parse(dateRange[1]) / 1000
      const range = end - start
      const rangeIn5Day = (range / 60 / 60 / 24 / 5)
      if (rangeIn5Day > 1) {
        let curEnd = end
        let curStart = curEnd - 60 * 60 * 24 * 5
        while ((curStart - start) > 0) {
          params.push({ start: curStart, end: curEnd })
          curEnd = curStart
          curStart = curEnd - 60 * 60 * 24 * 5
        }
        params.push({ start, end: curEnd })
      } else {
        params.push({
          start, end,
        })
      }
    } else {
      params.push({})
    }
    if (abortControllerRef.current?.signal.aborted) {
      return
    }
    setLoadingText('')
    let tempLogs = []
    const hardLimit = 100
    for (let i = 0; i < params.length; i++) {
      const param = params[i]
      try {
        if (abortControllerRef.current?.signal.aborted) {
          return
        }

        const labelFilter = {
          component: 'connectors',
          namespace: environment.toLowerCase(),
          app: channel,
        }

        const lineFilter = textsToQuery.join('|')
        // eslint-disable-next-line no-await-in-loop
        const { data } = await api.User.getLokiLog(
          { labelFilter, lineFilter, limit: hardLimit, ...param },
          abortControllerRef.current.signal,
        )
        const logLists = data.result?.map(({ values, stream }) => {
          return values.map((value) => {
            return [...value, stream]
          })
        })
        const newLogs = logLists.reduce((prev, cur) => { return [...prev, ...cur] }, [])
        const updateLog = [...tempLogs, ...newLogs]

        setLogs(updateLog.sort((a, b) => { return parseInt(b[0]) - parseInt(a[0]) }))

        if (param.start) {
          setLoadingText(`Newest ${updateLog.length ?? hardLimit} Logs - ${dayjs(param.start * 1000).utc().format('DD/MM')} ⬅ ${dayjs(params[0].end * 1000).utc().format('DD/MM')}`)
        } else {
          setLoadingText(`Newest ${updateLog.length ?? hardLimit} Logs`)
        }
        if (tempLogs.length >= hardLimit) {
          break
        }
        tempLogs = updateLog
      } catch (error) {
        notification.error({
          message: `[Investigate - Log] ${error.message}`,
          description: error.data?.status,
        })
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    loadLog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFormChange = (changes) => {
    const { dateRange, omitFields: updatedOmitFields } = changes
    if (dateRange) {
      loadLog()
    }

    if (updatedOmitFields) {
      setOmitFields(updatedOmitFields)
    }
  }

  return (
    <LogDisplayContext.Provider
      value={{ logs, setLogs, environment, isLoading, setIsLoading, omitFields, form, onFormChange, loadingText, textsToQuery }}
    >
      {children}
    </LogDisplayContext.Provider>
  )
}

LogDisplayProvider.propTypes = {
  user: UserPropTypes.isRequired,
  children: PropTypes.node.isRequired,
}

export default LogDisplayProvider
