import React from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { sortTextOrNull } from '@core/helpers'
import style from './index.module.scss'
import TestCase from './TestCase'

const { Panel } = Collapse

const ExpandedTestGroup = ({ conversationWithTestCases }) => {
  if (conversationWithTestCases.length === 1) {
    return <div className={style.expandTestReport}><TestCase exchanges={conversationWithTestCases[0].exchanges} /></div>
  }

  return (
    conversationWithTestCases.sort((a, b) => { return sortTextOrNull(a?.note, b?.note) }).map((conversation) => {
      return (
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => { return isActive ? <MinusOutlined /> : <PlusOutlined /> }}
          className={style.expandTestReport}
        >
          <Panel
            key={conversation.id}
            header={(
              <span className={conversation.failCount ? style.testFailed : style.testPassed}>
                {`${conversation.note || 'N/A'} (${conversation.failCount ? 'FAILED' : 'PASSED'})`}
              </span>
            )}
            className={style.collapsePanel}
          >
            <TestCase exchanges={conversation.exchanges} />
          </Panel>
        </Collapse>
      )
    })

  )
}

ExpandedTestGroup.propTypes = {
  conversationWithTestCases: PropTypes.shape({
    conversationId: PropTypes.string,
    note: PropTypes.string,
    failCount: PropTypes.number,
    passCount: PropTypes.number,
    exchanges: [{
      exchangeId: PropTypes.string,
      testCases: PropTypes.arrayOf(PropTypes.object),
    }],
  }).isRequired,
}

ExpandedTestGroup.defaultProps = {
}

export default ExpandedTestGroup
