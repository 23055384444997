/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Modal,
  Select,
  Input,
  Button,
  Alert,
  notification,
} from 'antd'
import {
  responseTextModalOpenType,
  responseTextModalLoadingType,
  responseTextModalHasErrorType,
  responseTextModalOnCloseType,
} from '@core/types/resposeTextModal'
import {
  checkForFallbackDegradedMessageVariation,
  prepareDegradedMessagesVariationsString,
} from '../../../helpers'
import AddDegradedMessagesVariations from '../AddDegradedMessagesVariations'

const AddResponseTextModal = ({
  open,
  onClose,
  onCreateNewResponseText,

  loading,
  hasError,
}) => {
  const [form] = Form.useForm()
  const [hasFormError, setHasFormError] = useState(false)
  const [degradedValidationError, setDegradedValidationError] = useState(false)
  const [degradedValidationErrorMessage, setDegradedValidationErrorMessage] = useState(false)

  const onFinish = async () => {
    setHasFormError(false)
    setDegradedValidationError(false)
    setDegradedValidationErrorMessage(false)

    try {
      const formFields = await form.validateFields()
      checkForFallbackDegradedMessageVariation(formFields)

      const payload = {
        key: formFields.key,
        value: prepareDegradedMessagesVariationsString(formFields),
      }

      if (formFields.lang_code) payload.lang_code = formFields.lang_code

      delete payload.values
      const response = await onCreateNewResponseText(payload)
      if (response && response.id) {
        notification.success({
          message: 'New Connector Text Response has been added',
          duration: 10,
        })

        onClose()
      }
    } catch (error) {
      if (error.hasOwnProperty.call(error, 'errorFields')) {
        setHasFormError(true)
        setDegradedValidationErrorMessage(error.message)
      }

      if (error.hasOwnProperty.call(error, 'degradationError')) {
        setDegradedValidationError(true)
        setDegradedValidationErrorMessage(error.message)
      }
    }
  }

  const requiredFieldRules = [{ required: true, message: 'Required field' }]

  if (!open) return null

  return (
    <Modal
      title='Add new response text'
      visible={open}
      maskClosable={false}
      closable={loading}
      centered
      onOk={onFinish}
      onCancel={onClose}
      destroyOnClose
      width={1024}
      footer={[
        <Button key='back' onClick={onClose} disabled={loading}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={onFinish}>
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        name='form__add_new_response_sentence'
      >
        <Form.Item
          label='Enter where this message will handle (key)'
          name='key'
          rules={requiredFieldRules}
        >
          <Input placeholder='Key path' />
        </Form.Item>
        <AddDegradedMessagesVariations />
        <Form.Item label='Language' name='lang_code'>
          <Select
            placeholder='Language Code'
            allowClear
          >
            <Select.Option value='en_US'>en-US</Select.Option>
            <Select.Option value='fr_FR'>fr-FR</Select.Option>
            <Select.Option value='de_DE'>de-DE</Select.Option>
          </Select>
        </Form.Item>
        {hasError && <Alert showIcon type='error' message='We&#39;ve encountered some problems, please try again in few minutes' />}
        {hasFormError && <Alert showIcon type='error' message='Please check all the required fields, or response variations' />}
        {degradedValidationError && <Alert showIcon type='error' message={degradedValidationErrorMessage} />}
      </Form>
    </Modal>
  )
}

AddResponseTextModal.propTypes = {
  open: responseTextModalOpenType.isRequired,
  loading: responseTextModalLoadingType.isRequired,
  hasError: responseTextModalHasErrorType.isRequired,
  onClose: responseTextModalOnCloseType.isRequired,
  onCreateNewResponseText: PropTypes.func.isRequired,
}

export default AddResponseTextModal
