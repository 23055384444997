import React from 'react'
import PropTypes from 'prop-types'
import { Card, Popover, List } from 'antd'
import { Handle, Position } from 'react-flow-renderer'

const customNodeStyles = {
  background: '#0AA674',
  color: '#fff',
  maxWidth: '250px',
  height: 'auto',
  maxHeight: '500px',
}

const { Meta } = Card
const BotNode = ({ data, isConnectable }) => {
  return (
    <Popover
      title='Bot Node'
      color='white'
      content={() => {
        return (
          <List size='small'>
            <List.Item>{data.bot_response_key}</List.Item>
            <List.Item>{`i18n: ${data.i18n_version}`}</List.Item>
          </List>
        )
      }}
    >
      <Card style={customNodeStyles}>
        <Meta
          style={{ marginBottom: '8px', lineHeight: '1.2' }}
          description={<b>{data.bot_response_key}</b>}
        />
        <p>{data.bot_response_text}</p>
      </Card>
      <Handle type='target' position={Position.Left} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
      <Handle type='source' position={data.stateExists ? Position.Bottom : Position.Right} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
    </Popover>
  )
}

BotNode.propTypes = {
  data: PropTypes.shape({
    i18n_version: PropTypes.number.isRequired,
    bot_response_key: PropTypes.string.isRequired,
    bot_response_text: PropTypes.string.isRequired,
    stateExists: PropTypes.bool.isRequired,
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
}

export default BotNode
