import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import { Handle, Position } from 'react-flow-renderer'

const customNodeStyles = {
  background: '#F85D22',
  color: '#fff',
  maxWidth: '250px',
  height: 'auto',
  maxHeight: '500px',
}

const { Meta } = Card

const SystemNode = ({ data, isConnectable }) => {
  return (
    <>
      <Card style={customNodeStyles}>
        <Meta description='System' />
        <p>{data.state_key}</p>
      </Card>
      <Handle type='target' position={Position.Left} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
      <Handle type='source' position={Position.Right} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
    </>
  )
}

SystemNode.propTypes = {
  data: PropTypes.shape({
    original: PropTypes.string.isRequired,
    state_key: PropTypes.string.isRequired,
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
}

export default SystemNode
