import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Result, Typography, Button, Space, Table, Modal, Form, Input, Select, notification, Popconfirm } from 'antd'
import dayjs from 'dayjs'

import useRequest from '@core/hooks/useRequest'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import { shallowCleanFalsyObject, sortTextOrNull } from '@core/helpers'

import AssignFeatureFlagToClientGroupsModal from '../../../components/AssignFeatureFlagToClientGroupsModal'
import DataTable from '../../../components/DataTable'
import Api from '../../../api'
import style from './index.module.scss'

dayjs.extend(require('dayjs/plugin/minMax'))

const { Option } = Select
const fixedDeploymentGroupsFilter = { clientGroupType: ['DEPLOYMENTS'] }

const expandedRowRenderWrapper = (allDeploymentGroups) => {
  return (record, /* index */ _, /* indent */__, expanded) => {
    if (!expanded) return null

    const allDeploymentGroupsNameToFalseLookup = allDeploymentGroups?.items?.reduce((acc, curr) => {
      acc[curr.name] = curr.latestFeatures?.[record.key]?.value === true
      return acc
    }, {}) || {}
    const assignedCount = Object.values(allDeploymentGroupsNameToFalseLookup).filter((value) => { return value }).length

    const columns = [
      {
        title: (
          <>
            <Typography.Text>Deployment Group Name</Typography.Text>
            <Typography.Text type='secondary'>{` (assigned to ${assignedCount}/${allDeploymentGroups?.items?.length} deployment groups)`}</Typography.Text>
          </>
        ),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => { return sortTextOrNull(a.name, b.name) },
      },
      {
        title: 'Status',
        dataIndex: 'value',
        key: 'value',
        sorter: (a, b) => { return sortTextOrNull(a.value, b.value) },
        render: (value) => {
          if (value === true) return <Typography.Text type='success'>Enabled</Typography.Text>
          if (value === false) return <Typography.Text type='danger'>Disabled</Typography.Text>
          return <Typography.Text>N/A</Typography.Text>
        },
      },
    ]

    const dataSource = Object
    .entries(allDeploymentGroupsNameToFalseLookup)
    .map(([name, value]) => { return { name, value } })
    .sort((a, b) => { return sortTextOrNull(a.name, b.name) })
    return <Table columns={columns} dataSource={dataSource} pagination={false} />
  }
}

const DataTab = ({
  timeFormatter,
  filter,
  handlePaginationChange,
  triggerSearch,
  environment,
}) => {
  const INITIAL_VALUES = { id: undefined, name: undefined }
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editModalInitialValues, setEditModalInitialValues] = useState(INITIAL_VALUES)
  const [{ loading, data, hasError }, { silentMakeRequest }] = useRequest(Api.FeatureFlags.findMany, shallowCleanFalsyObject({ ...filter }))
  const deploymentGroupQuery = { environment, showAll: true, ...fixedDeploymentGroupsFilter }
  const [{ data: allDeploymentGroups }] = useRequest(Api.ClientGroups.findMany, deploymentGroupQuery)
  const [addEditDetailModalVisible, setAddEditDetailModalVisible] = useState(false)
  const [form] = Form.useForm()
  const [modalType, setModalType] = useState('')
  const MODAL_TYPE = {
    edit: 'edit',
    add: 'add',
  }
  const onEditModalClicked = (record) => {
    setEditModalInitialValues(record)
    setEditModalVisible(true)
  }

  const onSetEditModalVisibility = (visible) => {
    setEditModalVisible(visible)
    setEditModalInitialValues(INITIAL_VALUES)
  }

  const onDelete = async (id) => {
    try {
      await Api.FeatureFlags.deleteById(id)
      notification.success({
        message: 'Success',
        description: 'Deleted',
        duration: 4,
      })
      await silentMakeRequest()
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Fail to Delete. Please try again!',
        duration: 5,
      })
    }
  }

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }

  const columnsTable = [
    {
      title: (<strong>Feature Name</strong>),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return sortTextOrNull(a.name, b.name) },
    },
    {
      title: (<strong>Feature Key</strong>),
      dataIndex: 'key',
      key: 'key',
      sorter: (a, b) => { return sortTextOrNull(a.key, b.key) },
    },
    {
      title: (<strong>Feature Type</strong>),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => { return sortTextOrNull(a.type, b.type) },
    },
    {
      title: (<strong>Updated At</strong>),
      key: 'updatedAt',
      width: 190,
      sorter: (a, b) => { return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime() },
      render: (record) => {
        const { updatedAt, assignedAt } = record

        const dates = []
        if (updatedAt) {
          dates.push(dayjs(updatedAt))
        }
        if (assignedAt) {
          dates.push(dayjs(assignedAt))
        }

        const mostRecentDate = dayjs.max(dates)

        if (!mostRecentDate) return <Typography.Text>-</Typography.Text>
        if (timeFormatter) {
          return <Typography.Text>{timeFormatter(mostRecentDate.toISOString())}</Typography.Text>
        }
        return <Typography.Text>{mostRecentDate.toISOString()}</Typography.Text>
      },
    },
    {
      title: (<strong>Actions</strong>),
      key: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Button type='default' size='small' onClick={() => { return onEditModalClicked(record) }}>Assign</Button>
            <Button
              type='primary'
              size='small'
              onClick={() => {
                setModalType(MODAL_TYPE.edit)
                setEditModalInitialValues(record)
                setAddEditDetailModalVisible(true)
                form.setFieldsValue({ name: record.name, key: record.key, type: record.type })
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title={(
                <>
                  <div>
                    <Typography.Text>Delete?</Typography.Text>
                  </div>
                  <Typography.Text>Are you sure you want to delete feature&nbsp;</Typography.Text>
                  <Typography.Text strong>{`'${record.name}'`}</Typography.Text>
                  <br />
                </>
              )}
              onConfirm={() => { return onDelete(record.id) }}
              okText='Yes'
              cancelText='No'
            >
              <Button danger type='primary' size='small'>Delete</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const { items, meta } = data

  const onFinish = async ({ key, name, type }) => {
    try {
      const payload = {
        key: key.trim(),
        name: name.trim(),
        type: type.trim(),
      }
      if (modalType === MODAL_TYPE.add) {
        await Api.FeatureFlags.insertOne(payload)
      } else if (modalType === MODAL_TYPE.edit) {
        await Api.FeatureFlags.updateById(editModalInitialValues.id, payload)
      }
      await silentMakeRequest()
      setAddEditDetailModalVisible(false)
    } catch (error) {
      //  handle duplicate key error (ResourceDuplicateError)
      if (error.status === 409) {
        form.setFieldsValue({ duplicateKey: key.trim() })
        form.validateFields()
      } else {
        notification.error({
          duration: 5,
          message: 'Error',
          description: error.message,
        })
      }
    }
  }

  const FEATURE_OPTIONS = [
    { name: 'Boolean', value: 'BOOLEAN' },
    { name: 'String', value: 'STRING' },
    { name: 'Number', value: 'NUMBER' },
    { name: 'Configuration(JSON)', value: 'JSON' },
  ]

  return (
    <>
      <Modal
        title={modalType === MODAL_TYPE.edit ? (
          <Space>
            Edit
            <span className={style.featureNameModalTitle}>{`(${editModalInitialValues.name})`}</span>
          </Space>
        ) : 'Add new feature'}
        visible={addEditDetailModalVisible}
        onCancel={() => { setAddEditDetailModalVisible(false) }}
        onOk={() => {
          form.submit()
        }}
        okText={modalType === MODAL_TYPE.edit ? 'Save' : 'Submit'}
        destroyOnClose
      >
        <Form
          layout='vertical'
          preserve={false}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label='Feature Name'
            required
            name='name'
            rules={[

              {
                whitespace: true,
                required: true,
                message: 'Please input the Feature Name!',
              },
            ]}
          >
            <Input placeholder='Add Feature Name' />
          </Form.Item>
          <Form.Item
            label='Feature Key'
            required
            name='key'
            rules={[
              {
                whitespace: true,
                required: true,
                message: 'Please input the Feature Key!',
              },
              {
                validator: async (_, value) => {
                  if (value && form.getFieldValue('duplicateKey') === value.trim()) { throw new Error(`Feature key "${value}" already exists.`) }
                },
              },
            ]}
          >
            <Input placeholder='Add Feature Key' />
          </Form.Item>
          <Form.Item name='duplicateKey' noStyle />
          <Form.Item
            name='type'
            label='Feature Type'
            rules={[
              {
                required: true,
                message: 'Feature type is required.',
              },
            ]}
          >
            <Select
              placeholder='Select Feature Type'
            >
              {FEATURE_OPTIONS.map((featureType, index) => {
                return <Option key={index} value={featureType.value}>{featureType.name}</Option>
              })}

            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Button type='primary' onClick={() => { setModalType(MODAL_TYPE.add); setAddEditDetailModalVisible(true) }} className={style.addFeatureButton}> Add new feature</Button>
      <DataTable
        columns={columnsTable}
        dataSource={items}
        meta={meta}
        filter={filter}
        onPaginationChange={handlePaginationChange}
        expandable={{
          expandedRowRender: expandedRowRenderWrapper(allDeploymentGroups),
        }}
      />
      {editModalVisible && (
        <AssignFeatureFlagToClientGroupsModal
          key={editModalInitialValues.id}
          modalVisibility={editModalVisible}
          setModalVisibility={onSetEditModalVisibility}
          initialValues={editModalInitialValues}
          triggerSearch={triggerSearch}
          environment={environment}
        />
      )}
    </>
  )
}

DataTab.propTypes = {
  environment: PropTypes.string.isRequired,
  timeFormatter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
}

export default DataTab
