import React from 'react'
import PropTypes from 'prop-types'
import useSymbolizer from '@core/hooks/useSymbolizer'
import { Typography } from 'antd'
import { splitGeneralizedSentenceByHighlighted } from '@core/helpers/sentence'

import CopySentenceButton from './CopySentenceButton'
import GeneralizedSentence from './GeneralizedSentence'
import RawSentence from './RawSentence'

const { Text } = Typography

const AnalysisSentence = React.memo(({ generalized, original, hermesVersion, viewMode, copyable }) => {
  // this symbolizer call requires sending hermesVersion which saved alongside each processed sentence from db, not the running hermes (default).
  const [{ loading }, analysis] = useSymbolizer(original, hermesVersion)
  const sentencePhrases = splitGeneralizedSentenceByHighlighted(generalized)
  const generalizedTagList = sentencePhrases.filter((phrase) => { return phrase.isTag })

  if (!loading && Array.isArray(analysis?.desymbolizedSplitted)) {
    const sentence = []
    let i = 0
    let j = 0
    while (i < analysis.desymbolizedSplitted.length) {
      const token = analysis.desymbolizedSplitted[i]
      if (token.source && j < generalizedTagList.length) {
        const { text: generalizedText, backgroundColor, color } = generalizedTagList[j]
        sentence.push({ ...token, generalizedText, backgroundColor, color })
        j++
      } else {
        sentence.push(token)
      }
      i++
    }

    if (viewMode === 'generalized') {
      return (
        <>
          <GeneralizedSentence sentence={sentence} />
          {copyable && <CopySentenceButton raw={original} />}
        </>
      )
    }

    return (
      <>
        <RawSentence sentence={sentence} />
        {copyable && <CopySentenceButton raw={original} />}
      </>
    )
  }

  return (
    // display original as a placeholder text while processing
    <>
      <Text>{viewMode === 'raw' ? original : generalized}</Text>
      {copyable && <CopySentenceButton raw={original} />}
    </>
  )
})

AnalysisSentence.propTypes = {
  generalized: PropTypes.string.isRequired,
  original: PropTypes.string.isRequired,
  hermesVersion: PropTypes.string,
  viewMode: PropTypes.oneOf(['generalized', 'raw']),
  copyable: PropTypes.bool,
}

AnalysisSentence.defaultProps = {
  viewMode: 'raw',
  hermesVersion: undefined,
  copyable: false,
}

export default AnalysisSentence
