import React, { useState } from 'react'
import {
  Layout,
  Tabs,
  Empty,
  Spin,
} from 'antd'
import { callWithWorkerPod } from '@core/utils/workerPod'
import RightSider from '@core/components/RightSider'
import TagsLeftSider from './TagsLeftSider'
import TagsDataTab from './TagsDataTab'
import TagsJobTab from './TagsJobTab'
import Api from '../../api'

const { Content } = Layout
const { TabPane } = Tabs

const DATA_TAB_KEY = 'data'
const JOB_TAB_KEY = 'job'

const TagsPage = () => {
  const [tab, setTab] = useState(DATA_TAB_KEY)
  const [job, setJob] = useState(null)
  const [filter, setFilter] = useState(null)
  const [selectedConversation, setSelectedConversation] = useState(null)
  const [spinTip, setSpinTip] = useState('')

  const search = (newFilter) => {
    setTab(DATA_TAB_KEY)
    newFilter.page = 1
    setFilter(newFilter)
  }

  const handleTabChange = (newTab) => {
    setTab(newTab)
  }

  const onItemClick = (conversation) => {
    setSelectedConversation(conversation !== selectedConversation ? conversation : null)
  }

  const handlePaginationChange = (page, perPage) => {
    if (filter.perPage !== perPage) {
      setFilter((prevFilter) => { return { ...prevFilter, page: 1, perPage } })
    } else {
      setFilter((prevFilter) => { return { ...prevFilter, page, perPage } })
    }
  }

  const onRunTaggingJobClick = async (newFilter) => {
    setTab(JOB_TAB_KEY)
    await callWithWorkerPod(async () => {
      setSpinTip('')
      const {
        page,
        perPage,
        datasource,
        ...query
      } = newFilter
      const newJob = await Api.runTaggingJob(query, { datasource })
      setJob(newJob)
    }, ({
      retryCount,
      data: { unavailableReplicas },
    }) => {
      const estimatedRetryCount = 8
      const percent = ((retryCount + 1) * 100) / estimatedRetryCount

      if (unavailableReplicas) {
        setSpinTip(`The worker pod is starting (${percent}%).`)
      } else {
        setSpinTip(`Please wait for the worker pod (${percent}%).`)
      }
    })
  }

  return (
    <>
      <TagsLeftSider onSearch={search} onRunTaggingJobClick={onRunTaggingJobClick} />
      <Content>
        <Tabs activeKey={tab} onChange={handleTabChange}>
          <TabPane key={DATA_TAB_KEY} tab='Data'>
            {filter ? (
              <TagsDataTab
                filter={filter}
                onItemClick={onItemClick}
                handlePaginationChange={handlePaginationChange}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
          <TabPane key={JOB_TAB_KEY} tab='Job'>
            <Spin spinning={!!spinTip} tip={spinTip}>
              <TagsJobTab job={job} />
            </Spin>
          </TabPane>
        </Tabs>
      </Content>
      <RightSider
        selectedConversationId={selectedConversation ? selectedConversation.id : null}
        querySource={filter && filter.datasource ? filter.datasource : null}
      />
    </>
  )
}

export default TagsPage
