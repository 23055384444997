import cloneDeep from 'lodash.clonedeep'
import { SENTENCE } from '../constants'

const INITIAL_STATE = {
  recent: [],
  current: {},
  sources: [
    {
      title: 'Any',
      value: 'ANY',
    },
    {
      title: 'Dashboard',
      value: 'NLP_DASHBOARD',
    },
    {
      title: 'Live Person',
      value: 'LIVE_PERSON',
    },
  ],
  dateRanges: [
    {
      title: 'Any',
      value: 'ANYTIME',
    },
    {
      title: 'Today',
      value: 'TODAY',
    },
    {
      title: 'Last week',
      value: 'LAST_WEEK',
    },
    {
      title: 'Last month',
      value: 'LAST_MONTH',
    },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SENTENCE.FETCH.REQUEST: {
      if (!state.recent.length) {
        return {
          sources: state.sources,
          dateRanges: state.dateRanges,
          recent: [action.payload.filter],
          current: action.payload.filter,
        }
      }

      const newState = cloneDeep(state)

      return {
        sources: state.sources,
        dateRanges: state.dateRanges,
        current: action.payload.filter,
        recent: [...newState.recent, action.payload.filter],
      }
    }
    default: return state
  }
}
