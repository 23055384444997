import React from 'react'
import PropTypes from 'prop-types'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import { Tooltip, Space, Button } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteTwoTone } from '@ant-design/icons'

import style from './style.module.scss'

const actionsDisableColor = '#d6d6d6'

const ApprovalActions = ({
  deleteTraining,
  approveTraining,
  rejectTraining,
  alreadyChecked,
  isLoading,
}) => {
  const isLoadingOrAlreadyChecked = alreadyChecked || isLoading

  return (
    <Space className={style.IntentTrainingApprovalActions} size={0}>
      <PermittedFeatureCheck featurePath='emailautomations.intentTrainingData.actionDelete'>
        <Tooltip title='Delete'>
          <Button
            type='link'
            icon={<DeleteTwoTone twoToneColor={isLoading ? actionsDisableColor : '#9214e0'} />}
            onClick={deleteTraining}
            disabled={isLoading}
          />
        </Tooltip>
      </PermittedFeatureCheck>
      <PermittedFeatureCheck featurePath='emailautomations.intentTrainingData.actionApprove'>
        <Tooltip title='Approve'>
          <Button
            type='link'
            icon={<CheckCircleTwoTone twoToneColor={isLoadingOrAlreadyChecked ? actionsDisableColor : '#52c41a'} />}
            onClick={approveTraining}
            disabled={isLoading}
          />
        </Tooltip>
      </PermittedFeatureCheck>
      <PermittedFeatureCheck featurePath='emailautomations.intentTrainingData.actionReject'>
        <Tooltip title='Reject'>
          <Button
            type='link'
            icon={<CloseCircleTwoTone twoToneColor={isLoadingOrAlreadyChecked ? actionsDisableColor : '#f22c46'} />}
            onClick={rejectTraining}
            disabled={isLoading}
          />
        </Tooltip>
      </PermittedFeatureCheck>
    </Space>
  )
}

ApprovalActions.propTypes = {
  deleteTraining: PropTypes.func.isRequired,
  approveTraining: PropTypes.func.isRequired,
  rejectTraining: PropTypes.func.isRequired,
  alreadyChecked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default ApprovalActions
