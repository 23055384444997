import React from 'react'
import { Spin } from 'antd'

const Redirect = () => {
  return (
    <Spin tip='Redirecting' size='large' />
  )
}

export default Redirect
