import React from 'react'
import { Checkbox, Form } from 'antd'

const ExtractorSkipTest = () => {
  return (
    <Form.Item name='skip' label='Skip' valuePropName='checked'>
      <Checkbox />
    </Form.Item>
  )
}

export default ExtractorSkipTest
