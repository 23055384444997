import React from 'react'
import { Form, Input, Divider, Typography } from 'antd'

const ByOriginalSourceEmailId = () => {
  return (
    <>
      <Divider>
        <small><strong>By Email IDs</strong></small>
      </Divider>
      <Form.Item
        name='emailIds'
        label={(
          <div>
            <Typography.Text>
              *&thinsp;Equal to salesforce caseID (18 digits)
            </Typography.Text>
            <br />
            <Typography.Text italic>
              Example: 5001U000013QjSAQ00
            </Typography.Text>
          </div>
        )}
      >
        <Input placeholder='Use comma (,) or space for multiple ids' />
      </Form.Item>
    </>
  )
}

export default ByOriginalSourceEmailId
