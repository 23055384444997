import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Typography, Badge } from 'antd'
import { capitalizeWord } from '@core/helpers'

import style from './style.module.scss'

const ClickableIntentCellContent = ({
  intentName,
  onClick,
  selected,
  polarity,
  wasSubmitted,
  matchedPrediction,
  oppositeSelected,
}) => {
  const [showFullPrediction, setShowFullPrediction] = useState(false)

  const intentClassnames = classnames(
    style.Intent,
    style[capitalizeWord(polarity)],
    { [`${style.Selected}`]: (selected || wasSubmitted) },
  )

  const toggleSelection = (event) => {
    event.stopPropagation()
    if (wasSubmitted || oppositeSelected) return
    onClick(event)
  }

  const togglePredictionDigits = () => {
    if (matchedPrediction.rawscore !== 100) setShowFullPrediction(!showFullPrediction)
  }

  // if there is no prediction then just a text
  if (!matchedPrediction) {
    return (
      <Typography.Text onClick={toggleSelection} className={intentClassnames}>
        {`${intentName}`}
      </Typography.Text>
    )
  }

  // There are some 'weird stuff' going on with the eslint
  // with this string template for showing percentage
  //
  // `${(predictedIntent.score * 100).toFixed(2)}%`
  //
  // it dies from eslint and said:
  //
  // Cannot read property value of undefined
  // Occurred while linting
  //
  // but that is "valid" string template, tested on chrome and it works
  // the workaround for this is just put the percentage here

  return (
    <Badge
      count={`${matchedPrediction[showFullPrediction ? 'fourdigit' : 'twodigit']}%`}
      className={style.PredictionBadge}
      onClick={togglePredictionDigits}
      overflowCount={100}
    >
      <div className={classnames(style.PredictionHighlights, style[capitalizeWord(polarity)])}>
        <Typography.Text onClick={toggleSelection} className={intentClassnames}>
          {`${intentName}`}
        </Typography.Text>
      </div>
    </Badge>
  )
}

ClickableIntentCellContent.defaultProps = {
  matchedPrediction: null,
}

ClickableIntentCellContent.propTypes = {
  intentName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  polarity: PropTypes.oneOf(['positive', 'negative']).isRequired,
  matchedPrediction: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    rawscore: PropTypes.number,
    fourdigit: PropTypes.string,
    twodigit: PropTypes.string,
  }),
  wasSubmitted: PropTypes.bool.isRequired,
  oppositeSelected: PropTypes.bool.isRequired,
}

export default ClickableIntentCellContent
