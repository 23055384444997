const getOption = (data) => {
  return {
    title: {
      text: 'Top Tags',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{c} ({d}%)',
    },
    legend: {
      bottom: 10,
      left: 'center',
      data: data.map((name) => { return name }),
    },
    series: [
      {
        type: 'pie',
        radius: '65%',
        center: ['50%', '50%'],
        selectedMode: 'single',
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  }
}

export default getOption
