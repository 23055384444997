import React from 'react'
import { Space, Typography, Divider } from 'antd'

const AppLogoTitle = () => {
  return (
    <Space className='Louise--LogoTitle' align='center' size='large'>
      <img className='amexgbt-logo' src={`${process.env.PUBLIC_URL}/AmexGbtLogo.svg`} alt='amexgbt-logo' />
      <Typography.Text level={4} strong>LOUISE</Typography.Text>
      <Divider type='vertical' />
    </Space>
  )
}

export default AppLogoTitle
