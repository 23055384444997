import React from 'react'
import PropTypes from 'prop-types'
import { Tag, Popconfirm } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const AssignedIntentSampleTag = ({ id, name, onConfirmDelete }) => {
  return (
    <Tag
      key={id}
      closable
      onClose={(event) => { event.preventDefault() }} // This is to prevent removing the UI tag from the list, not the be call
      closeIcon={(
        <Popconfirm
          title={(
            <>
              DELETE
              <strong>{` {${name}} `}</strong>
              sample from this email?
            </>
          )}
          onConfirm={onConfirmDelete}
          okText='Yes'
          cancelText='No'
        >
          <CloseOutlined />
        </Popconfirm>
      )}
    >
      {name}
    </Tag>
  )
}

AssignedIntentSampleTag.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
}

export default AssignedIntentSampleTag
