import React, { useContext } from 'react'
import { Button, Divider, Form } from 'antd'
import PropTypes from 'prop-types'
import { EmailAutomationsContext } from '../../../../context'

const AutoFillSearchForEmailAnalysisButton = ({ form }) => {
  const { datasource } = useContext(EmailAutomationsContext)

  const onClickSearchForEmailAnalysis = () => {
    const email_analysis_autofill = {
      emailEnvironment: datasource,
      analysisResponseStatuses: ['processed'],
      e2eCorrectnesses: ['none'],
      onlyPreprocessedSearch: false, // state's default value is true
      language: ['en'],
    }
    form.resetFields()
    form.setFieldsValue(email_analysis_autofill)
    form.submit()
  }

  return (
    <>
      <Divider />
      <Button
        type='primary'
        htmlType='button'
        onClick={onClickSearchForEmailAnalysis}
        block
      >
        Search for Email Analysis
      </Button>
    </>
  )
}

AutoFillSearchForEmailAnalysisButton.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
}
export default AutoFillSearchForEmailAnalysisButton
