import React from 'react'
import { Typography, Descriptions, Row, Col, Tooltip, List } from 'antd'
import PropTypes from 'prop-types'
import style from './style.module.scss'

const DesymbolizedSentence = ({
  symbols,
  selectedPhrase,
  onSelectPhrase,
}) => {
  const getClickableSymbolizedPhraseItem = (item) => {
    const shouldMark = selectedPhrase && selectedPhrase.original === item.original && selectedPhrase.occurrenceIndex === item.occurrenceIndex
    const onSelectSymbol = () => { return onSelectPhrase(item) }

    if (item.sources && item.predictedSource) {
      return (
        <Tooltip
          placement='bottom'
          arrowPointAtCenter
          color='white'
          title={(
            <>
              <Descriptions bordered size='small'>
                <Descriptions.Item label='Occurrence idx' span={3}>{item.occurrenceIndex}</Descriptions.Item>
                <Descriptions.Item label='Entities' span={3}>{item.sources.join('|')}</Descriptions.Item>
                {item.predictedSource && <Descriptions.Item label='Predicted' span={3}>{item.predictedSource}</Descriptions.Item>}
              </Descriptions>
              {item.originalSynonyms && (
                <List
                  header={<b>Original Synonyms</b>}
                  bordered
                  size='small'
                  dataSource={Object.entries(item.originalSynonyms)}
                  renderItem={([entity, original]) => {
                    return (
                      <List.Item key={`${entity}-${original}`}>{`${entity}: ${original}`}</List.Item>
                    )
                  }}
                />
              )}
            </>
          )}
        >
          <Typography.Link
            className={style.Symbol}
            onClick={onSelectSymbol}
            mark={shouldMark}
            code
          >
            {item.original}
          </Typography.Link>
        </Tooltip>
      )
    }

    return (
      <Typography.Link
        className={style.Symbol}
        onClick={onSelectSymbol}
        mark={shouldMark}
      >
        {item.original}
      </Typography.Link>
    )
  }

  if (!Array.isArray(symbols) || !symbols.length) return null

  return (
    <Row
      gutter={[8, 0]}
      className={style.DesymbolizedSentence}
    >
      {symbols.map((item) => {
        return (
          <Col key={JSON.stringify(item)}>
            {getClickableSymbolizedPhraseItem(item)}
          </Col>
        )
      })}
    </Row>
  )
}

DesymbolizedSentence.defaultProps = {
  symbols: [],
  selectedPhrase: null,
}

DesymbolizedSentence.propTypes = {
  symbols: PropTypes.arrayOf(PropTypes.shape({
    occurrenceIndex: PropTypes.number.isRequired,
    original: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(PropTypes.string),
    predictedSource: PropTypes.string,
  })),
  selectedPhrase: PropTypes.shape({
    occurrenceIndex: PropTypes.number.isRequired,
    original: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(PropTypes.string),
    predictedSource: PropTypes.string,
  }),
  onSelectPhrase: PropTypes.func.isRequired,
}

export default DesymbolizedSentence
