import { useReducer } from 'react'
import StoreUtils from '@core/utils/store'
import Api from '../api'

export const SimulationApi = {
  findUser: (params) => { return Api.Simulation.findUser(params) },
}

const INITIAL_STATE = {
  loading: false,
  hasError: false,
  error: null,
  data: {
    user: {
      email: '',
    },
    date: '',
  },
}

export const PERSISTED_SIMULATOR_DATA = {
  SET_USER: {
    ...StoreUtils.createActionStatus('PERSISTED_SIMULATOR_DATA/SET_USER'),
    NOT_FOUND: 'PERSISTED_SIMULATOR_DATA/SET_USER/NOT_FOUND',
  },
  CLEAR_USER: 'PERSISTED_SIMULATOR_DATA/CLEAR_USER',
  DATE: {
    SET: 'PERSISTED_SIMULATOR_DATA/DATE/SET',
    UNSET: 'PERSISTED_SIMULATOR_DATA/DATE/UNSET',
  },
}

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PERSISTED_SIMULATOR_DATA.SET_USER.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
      }
    }
    case PERSISTED_SIMULATOR_DATA.SET_USER.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: {
          user: action.payload.user,
          date: state.data.date,
        },
      }
    }
    case PERSISTED_SIMULATOR_DATA.SET_USER.NOT_FOUND: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: {
          user: state.data.user,
          date: state.data.date,
        },
      }
    }
    case PERSISTED_SIMULATOR_DATA.SET_USER.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      }
    }
    case PERSISTED_SIMULATOR_DATA.CLEAR_USER: {
      return {
        ...state,
        data: {
          user: {
            email: '',
          },
          date: state.data.date,
        },
      }
    }
    case PERSISTED_SIMULATOR_DATA.DATE.SET: {
      return {
        ...state,
        data: {
          user: state.data.user,
          date: action.payload.date,
        },
      }
    }
    case PERSISTED_SIMULATOR_DATA.DATE.UNSET: {
      return {
        ...state,
        data: {
          user: state.data.user,
          date: '',
        },
      }
    }
    default: return state
  }
}

export const setDate = (dispatch, dateString) => {
  const payload = {
    date: dateString,
  }
  dispatch({
    type: PERSISTED_SIMULATOR_DATA.DATE.SET,
    payload,
  })

  return payload
}

export const unsetDate = (dispatch) => {
  dispatch({
    type: PERSISTED_SIMULATOR_DATA.DATE.UNSET,
  })
}

export const impersonateUser = async (dispatch, email) => {
  if (!email) return dispatch({ type: PERSISTED_SIMULATOR_DATA.CLEAR_USER })

  dispatch({ type: PERSISTED_SIMULATOR_DATA.SET_USER.REQUEST })

  try {
    const response = await SimulationApi.findUser({ email })

    if (response && response.status === 'success') {
      if (Object.entries(response.data).length) {
        const { firstName, lastName, email: travelerEmail } = response.data
        const payload = {
          user: {
            firstName,
            lastName,
            email: travelerEmail,
          },
        }
        dispatch({
          type: PERSISTED_SIMULATOR_DATA.SET_USER.SUCCESS,
          payload,
        })
        return payload.user
      }

      dispatch({
        type: PERSISTED_SIMULATOR_DATA.SET_USER.NOT_FOUND,
      })

      return null
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: PERSISTED_SIMULATOR_DATA.SET_USER.FAILED,
      error,
    })
    throw error
  }
}

const usePersistentDataSimulator = () => { return useReducer(reducer, INITIAL_STATE) }

export default usePersistentDataSimulator
