import React, { useState } from 'react'
import { Tabs, Typography } from 'antd'
import TrainedDataExtractionModels from './TrainedDataExtractionModels'
import Training from './Training'

const Models = () => {
  const [activeKey, setActiveKey] = useState('models')

  return (
    <>
      <Typography.Title level={4}>Email Automation Models</Typography.Title>
      <Tabs activeKey={activeKey} onChange={setActiveKey} destroyInactiveTabPane>
        <Tabs.TabPane key='models' tab='Models'>
          <TrainedDataExtractionModels />
        </Tabs.TabPane>
        <Tabs.TabPane key='training' tab='Training'>
          <Training />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default Models
