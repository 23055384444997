import React from 'react'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'
import { Result, Empty } from 'antd'
import JsonPreview from '@core/components/JsonPreview'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import { shallowCleanFalsyObject } from '@core/helpers'
import Api from '../../../api'
import { CHANNEL } from '../../../constants'

const PTCLogsTab = ({ filter }) => {
  const {
    text,
    limit,
    channel,
    start,
    end,
    datasource,
  } = filter

  const params = {
    container: CHANNEL.DB[channel],
    namespace: datasource,
    text,
    start,
    end,
    limit,
  }

  const [{ loading, data, hasError }] = useRequest(Api.getLogs, shallowCleanFalsyObject(params))

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }
  if (!data.result.length) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )
  }

  const logs = []
  data.result[0]?.values?.map((timestamp) => {
    let logText
    try {
      logText = JSON.parse(timestamp[1])
    } catch (error) {
      logText = timestamp?.[1]
    }

    return logs.push({ [new Date(timestamp[0] / 1000000).toLocaleString()]: logText })
  })

  return (
    <JsonPreview content={JSON.stringify(logs, null, 2)} autoSize />
  )
}

PTCLogsTab.propTypes = {
  filter: PropTypes.shape({
    text: PropTypes.string,
    limit: PropTypes.number,
    start: PropTypes.number,
    end: PropTypes.number,
    channel: PropTypes.string,
    datasource: PropTypes.string,
  }).isRequired,
}

export default PTCLogsTab
