import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { SENTENCE_LIST_UI } from '../constants'

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  toggleBulkMode: () => {
    return async (dispatch, getState) => {
      dispatch({
        type: SENTENCE_LIST_UI.TOGGLE_BULK_MODE,
        payload: {
          bulkMode: !getState().data.sentencesListUI.bulkMode,
        },
      })
    }
  },
  setSentenceMode: (mode) => {
    return async (dispatch) => {
      dispatch({
        type: SENTENCE_LIST_UI.SET_SENTENCE_MODE,
        payload: {
          sentenceMode: mode,
        },
      })
    }
  },
  setNoGeneralizedMode: (checked) => {
    return async (dispatch) => {
      dispatch({
        type: SENTENCE_LIST_UI.TOGGLE_NO_GENERALIZED,
        payload: {
          noGeneralizedMode: checked,
        },
      })
    }
  },
})
