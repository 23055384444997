import React from 'react'
import PropTypes from 'prop-types'
import { Table, Row, Pagination } from 'antd'

const Component = ({ columns, dataSource, meta, expandable, onChange, filter }) => {
  return (
    <>
      <Table
        columns={columns}
        expandable={expandable}
        dataSource={dataSource}
        size='small'
        pagination={false}
        rowKey={(row) => { return row.conversation_id }}
        scroll={{
          scrollToFirstRowOnChange: true,
          y: 675,
        }}
      />
      <Row align='end'>
        <Pagination
          total={meta.totalCount}
          pageSize={filter.perPage || meta.pageSize}
          current={filter.page || meta.page}
          showSizeChanger
          onChange={onChange}
        />
      </Row>
    </>
  )
}

Component.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      width: PropTypes.number,
      render: PropTypes.func,
      sorter: PropTypes.func,
    }),
  ).isRequired,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      conversation_id: PropTypes.string,
      count: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          channel: PropTypes.string,
          channel_user_id: PropTypes.string,
          chronos_id: PropTypes.shape({}),
          created_at: PropTypes.string,
          updated_at: PropTypes.string,
          deleted_at: PropTypes.string,
          extra: PropTypes.shape({}),
          id: PropTypes.string,
          notification_id: PropTypes.string,
          raw: PropTypes.shape({}),
          state: PropTypes.string,
          status: PropTypes.string,
        }),
      ),
      email: PropTypes.string,
    }),
  ).isRequired,
  expandable: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
}

export default Component
