import React, { useState } from 'react'
import {
  Layout,
  Typography,
  Spin,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import CoreApi from '@core/api'
import { callWithWorkerPod } from '@core/utils/workerPod'
import Api from '../../api'
import LeftSider from './LeftSider'
import JobsTable from './JobsTable'

const { Content } = Layout
const { Text } = Typography

const EvaluationTab = () => {
  const [spinTip, setSpinTip] = useState('')
  const [
    {
      loading,
      data: jobs,
      hasError,
    },
    { makeRequest },
  ] = useRequest(CoreApi.getAllJobs, {
    path: 'providers.Evaluations.train',
  })

  const onSubmit = async ({
    type,
    ...payload
  }) => {
    await callWithWorkerPod(async () => {
      setSpinTip('')
      if (type === 'domain') {
        await Api.trainDomains(payload)
      } else if (type === 'intent') {
        await Api.trainIntents(payload)
      }
    }, ({
      retryCount,
      data: { unavailableReplicas },
    }) => {
      const estimatedRetryCount = 8
      const percent = ((retryCount + 1) * 100) / estimatedRetryCount

      if (unavailableReplicas) {
        setSpinTip(`The worker pod is starting (${percent}%).`)
      } else {
        setSpinTip(`Please wait for the worker pod (${percent}%).`)
      }
    })

    await makeRequest()
  }

  return (
    <Spin spinning={!!spinTip} tip={spinTip}>
      <div style={{ display: 'flex' }}>
        <LeftSider onSubmit={onSubmit} />
        <Content>
          {loading && <Spin />}
          {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
          {jobs && <JobsTable jobs={jobs} />}
        </Content>
      </div>
    </Spin>
  )
}

export default EvaluationTab
