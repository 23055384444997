import React from 'react'
import {
  Form,
  Select,
  Typography,
} from 'antd'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import { formInstanceType, sentenceType } from '../../../types/form'
import { trainDomainIdType } from '../../../types/formInput'

const { Option } = Select
const { Title } = Typography

const DomainForm = ({ form, sentence, train_domain_id }) => {
  const { domainList } = useDomainIntentSelector()

  return (
    <Form
      form={form}
      layout='vertical'
      name='form__training_domain'
      initialValues={{
        train_domain_id,
      }}
    >
      <Title level={4}>{sentence}</Title>
      <Form.Item
        name='train_domain_id'
        label='Train Domain'
        rules={[
          {
            required: true,
            message: 'Please selected train domain',
          },
        ]}
      >
        <Select
          placeholder='Select a train domain'
          allowClear
        >
          {domainList.map((domain, index) => {
            return (
              <Option value={domain.id} key={index}>{domain.name}</Option>
            )
          })}
        </Select>
      </Form.Item>
    </Form>
  )
}

DomainForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
  train_domain_id: trainDomainIdType.isRequired,
}

export default DomainForm
