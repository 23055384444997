import React from 'react'
import { Layout, Typography } from 'antd'

import DomainIntentOverview from '../../components/DomainIntentOverview'

const { Content } = Layout
const { Title } = Typography

export default () => {
  return (
    <Content>
      <Title level={4}>Hierarchies: Manage Domain/Intent(s)</Title>
      <DomainIntentOverview />
    </Content>
  )
}

