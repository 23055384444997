import React, { useState } from 'react'
import {
  Layout,
  Tabs,
  Typography,
} from 'antd'
import DatasetTab from '../../components/DatasetTab'
import GeneralizationTab from '../../components/GeneralizationTab'
import TrainingTab from '../../components/TrainingTab'
import ModelsTab from '../../components/ModelsTab'
import TestingTab from '../../components/TestingTab'
import TaggersTab from '../../components/TaggersTab'

const { Content } = Layout
const { Title } = Typography
const { TabPane } = Tabs

const ExperimentsIndex = () => {
  const [activeKey, setActiveKey] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : 'training',
  )

  const handleActiveKeyChange = (newActiveKey) => {
    window.location.hash = `#${newActiveKey}`
    setActiveKey(newActiveKey)
  }

  return (
    <Content>
      <Title level={4}>Model Management</Title>
      <Tabs activeKey={activeKey} onChange={handleActiveKeyChange}>
        <TabPane key='dataset' tab='Dataset'>
          <DatasetTab />
        </TabPane>
        <TabPane key='training' tab='Training'>
          <TrainingTab />
        </TabPane>
        <TabPane key='models' tab='Models'>
          <ModelsTab />
        </TabPane>
        <TabPane key='testing' tab='Testing'>
          <TestingTab />
        </TabPane>
        <TabPane key='generalization' tab='Generalization'>
          <GeneralizationTab />
        </TabPane>
        <TabPane key='taggers' tab='Taggers'>
          <TaggersTab />
        </TabPane>
      </Tabs>
    </Content>
  )
}

export default ExperimentsIndex
