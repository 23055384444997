import React, { useState } from 'react'
import { Button } from 'antd'
import { PlusCircleTwoTone } from '@ant-design/icons'
import PropTypes from 'prop-types'

import AddNewSentenceModal from './AddNewClientGroupModal'

import style from './style.module.scss'

const AddNewClientGroupButton = ({ triggerSearch }) => {
  const [addNewSentenceModalVisibility, setAddNewSentenceModalVisibility] = useState(false)

  const handleButtonClick = () => {
    setAddNewSentenceModalVisibility(true)
  }

  return (
    <>
      <Button
        className={style.AddANewClientGroup}
        type='primary'
        onClick={handleButtonClick}
        icon={<PlusCircleTwoTone />}
      >
        Add a new client group
      </Button>

      {addNewSentenceModalVisibility && (
        <AddNewSentenceModal
          modalVisibility={addNewSentenceModalVisibility}
          setModalVisibility={setAddNewSentenceModalVisibility}
          triggerSearch={triggerSearch}
        />
      )}
    </>
  )
}

AddNewClientGroupButton.propTypes = {
  triggerSearch: PropTypes.func.isRequired,
}

export default AddNewClientGroupButton
