import { useSelector } from 'react-redux' // FOR YARS

export const useClusterSelector = () => {
  const clusterReducer = useSelector(({ core }) => { return core.cluster })

  return clusterReducer
}

export const useClusterStatusSelector = () => {
  return useSelector(({ core }) => {
    const { cluster: { status } } = core

    return status
  })
}
