import React, { useContext } from 'react'
import { Form, Select, Divider } from 'antd'
import { EmailAutomationsContext } from '../../../../context'

const ByStatuses = () => {
  const { emailSearchResults: [emailSearchResultsState] } = useContext(EmailAutomationsContext)
  return (
    <>
      <Divider>
        <small><strong>By Analysis Statuses</strong></small>
      </Divider>
      <Form.Item name='statuses'>
        <Select mode='multiple' placeholder='Select statuses' allowClear>
          <Select.Option key='NEW'>NEW</Select.Option>
          {emailSearchResultsState.statusList.map((status) => { return (<Select.Option key={status}>{status}</Select.Option>) })}
        </Select>
      </Form.Item>
    </>
  )
}

export default ByStatuses
