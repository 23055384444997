import dayjs from 'dayjs'

export const toInitialValues = (initialFilterValues) => {
  const initialValues = {
    ...initialFilterValues,
    onlyPreprocessedSearch: true,
    ids: initialFilterValues.ids?.join(', ') ?? '', // requires to parse (.join(', ')) to pass to input<string>
    emailIds: initialFilterValues.emailIds?.join(', ') ?? '',
  }

  // convert to dayjs format so that it doesn't crash on parsing value
  if (initialValues?.emailSentAt) {
    initialValues.emailSentAt = [
      dayjs(initialFilterValues.emailSentAt[0]),
      dayjs(initialFilterValues.emailSentAt[1]),
    ]
  }
  if (initialValues?.recordCreatedAt) {
    initialValues.recordCreatedAt = [
      dayjs(initialFilterValues.recordCreatedAt[0]),
      dayjs(initialFilterValues.recordCreatedAt[1]),
    ]
  }

  return initialValues
}

export const updateFilter = (filters) => {
  const initialValues = {
    ...filters,
    ids: filters.ids?.join(', ') ?? '', // requires to parse (.join(', ')) to pass to input<string>
    emailIds: filters.emailIds?.join(', ') ?? '',
  }

  initialValues.onlyPreprocessedSearch = Boolean(filters.onlyPreprocessedSearch)

  // convert to dayjs format so that it doesn't crash on parsing value
  if (initialValues?.emailSentAt) {
    initialValues.emailSentAt = [
      dayjs(filters.emailSentAt[0]),
      dayjs(filters.emailSentAt[1]),
    ]
  }
  if (initialValues?.recordCreatedAt) {
    initialValues.recordCreatedAt = [
      dayjs(filters.recordCreatedAt[0]),
      dayjs(filters.recordCreatedAt[1]),
    ]
  }

  return initialValues
}

export const setDateRangeValue = (dateRange) => {
  return ([
    `${dayjs(dateRange[0]).format('YYYY-MM-DD')}T00:00:00Z`,
    `${dayjs(dateRange[1]).format('YYYY-MM-DD')}T23:59:59Z`,
  ])
}
