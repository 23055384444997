import React from 'react'
import {
  Row,
  Col,
  List,
  Typography,
  Button,
  Popconfirm,
  Badge,
  Tooltip,
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import { useIntentLookup } from '@core/hooks/useDomainIntentLookup'
import { itemType, onEditTestCaseType, onConfirmDeleteType } from '../../../types/testCaseListItem'
import style from './index.module.scss'

const ListItem = ({ item, onEditTestCase, onConfirmDelete }) => {
  const { intentCascader } = useDomainIntentSelector()
  const { parentDomain } = useIntentLookup(item.test_intent_id)

  let testCaseType
  if (item.test_domain) testCaseType = 'domain'
  else if (item.test_intent) testCaseType = 'intent'
  else if (item.tagger_type) testCaseType = 'tagger'
  else if (item.expected_data) testCaseType = 'extractor'
  else if ('test_intent_id' in item) testCaseType = 'nullIntent'
  else testCaseType = 'nullDomain'

  const onConfirm = (event) => {
    onConfirmDelete(testCaseType, event)
  }

  if ('expected_data' in item) {
    let intentName
    intentCascader.forEach((domain) => {
      return domain.children.forEach((intent) => {
        if (intent.value === item.test_intent_id) intentName = intent.label
      })
    })

    return (
      <List.Item key={item.id}>
        <Row className={style.row} align='middle'>
          <Col flex='1'>
            {intentName}
          </Col>
          <Col flex='1'>
            {Object.keys(item.expected_data).join(', ')}
          </Col>
          <Col flex='1' className={style.col}>
            <Button type='link' onClick={onEditTestCase(item, testCaseType)}>Edit</Button>
            <Popconfirm title='Are you sure？' onConfirm={onConfirm} icon={<QuestionCircleOutlined className={style.confirm_delete} />}>
              <Button type='link' danger>Delete</Button>
            </Popconfirm>
          </Col>
        </Row>
      </List.Item>
    )
  }

  if ('test_domain_id' in item || 'test_intent_id' in item) {
    return (
      <List.Item key={item.id}>
        <Row className={style.row} align='middle'>
          <Col flex='1'>
            { ['nullDomain', 'nullIntent'].includes(testCaseType) && 'NULL'}
            {item.test_domain && item.test_domain}
            {item.test_intent && parentDomain && `${parentDomain.name} / ${item.test_intent}`}
            {item.expected_generalized_type}
          </Col>
          <Col flex='1' className={style.col}>
            <Button type='link' onClick={onEditTestCase(item, testCaseType)}>Edit</Button>
            <Popconfirm title='Are you sure？' onConfirm={onConfirm} icon={<QuestionCircleOutlined className={style.confirm_delete} />}>
              <Button type='link' danger>Delete</Button>
            </Popconfirm>
          </Col>
        </Row>
      </List.Item>
    )
  }
  return (
    <List.Item key={item.id}>
      <Row className={style.row} align='middle' gutter={10}>
        <Col span='1'>
          <Tooltip
            title={(
              <>
                <Typography.Text>{`Skip: ${item.skip}`}</Typography.Text>
                {item.note && <Typography.Paragraph>{`Note: ${item.note}`}</Typography.Paragraph>}
              </>
            )}
            color='white'
          >
            <Badge status={item.skip ? 'processing' : 'default'} />
          </Tooltip>
        </Col>
        <Col flex='1'>
          <Typography.Text mark>
            {item.tagger_type}
          </Typography.Text>
        </Col>
        <Col flex='1'>{item.phrase}</Col>
        <Col flex='1'>{`[${item.phrase_index}]`}</Col>
        <Col flex='1'>{item.expected_result}</Col>
        <Col flex='1' className={style.col}><Button type='link' onClick={onEditTestCase(item, testCaseType)}>Edit</Button></Col>
        <Popconfirm title='Are you sure？' onConfirm={onConfirm} icon={<QuestionCircleOutlined className={style.confirm_delete} />}>
          <Button type='link' danger>Delete</Button>
        </Popconfirm>
      </Row>
    </List.Item>
  )
}

ListItem.propTypes = {
  item: itemType.isRequired,
  onEditTestCase: onEditTestCaseType.isRequired,
  onConfirmDelete: onConfirmDeleteType.isRequired,
}

export default ListItem
