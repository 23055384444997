import { setupApiClient } from './api'
import { setupSentry } from './sentry'
import { setupAwsAmplify } from './amplify'

const setup = async () => {
  console.log('### SETUP ###')
  await setupApiClient()
  await setupAwsAmplify()
  if (process.env.NODE_ENV !== 'development') await setupSentry()
}

export default setup
