import { DYNAMIC_ENTITIES } from '../constants'

const INITIAL_STATE = {
  isPending: false,
  hasError: false,
  selectedDynamicEntity: null,
  dynamicEntityAction: null,
  dynamicEntityTypes: ['PNRs'],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DYNAMIC_ENTITIES.RESET:
      return {
        ...state, isPending: false, hasError: false, selectedDynamicEntity: null, dynamicEntityAction: null,
      }
    case DYNAMIC_ENTITIES.SELECTED:
      return {
        ...state,
        isPending: false,
        hasError: false,
        selectedDynamicEntity: action.payload.dynamicEntity,
        dynamicEntityAction: action.payload.dynamicEntityAction,
      }
    case DYNAMIC_ENTITIES.EDIT.REQUEST:
      return {
        ...state, isPending: true, hasError: false,
      }
    case DYNAMIC_ENTITIES.EDIT.SUCCESS:
      return {
        ...state, isPending: false, hasError: false,
      }
    case DYNAMIC_ENTITIES.EDIT.FAILED:
      return {
        ...state, isPending: false, hasError: true,
      }
    default:
      return {
        ...state, isPending: false, hasError: false,
      }
  }
}
