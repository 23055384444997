import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Modal,
  Button,
  Typography,
  Radio,
  notification,
} from 'antd'

import PropTypes from 'prop-types'
import { removeUndefinedOrNullFromObject } from '@core/helpers'
import { trimCsv } from '../../helpers'

import Api from '../../api'

const clientGroupTypes = ['DEPLOYMENTS', 'MESSAGES', 'METRICS']

const rules = {
  name: [{ required: true, message: 'Name is required' }],
  type: [{ required: true, message: 'Type is required' }],
}

const calculateInitialSelectMode = (value) => {
  if (value && value[0] !== '*') return 'selected'
  return '*'
}

const EditClientGroupModal = ({ modalVisibility, setModalVisibility, initialValues = {}, triggerSearch }) => {
  const [form] = Form.useForm()
  const [formValuesMemo, setFormValuesMemo] = useState(initialValues)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasSentRequest, setHasSentRequest] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [capIdMode, setCapIdMode] = useState(calculateInitialSelectMode(formValuesMemo?.capIds))
  const [countryOfEmploymentMode, setCountryOfEmploymentMode] = useState(
    calculateInitialSelectMode(formValuesMemo?.countryOfEmployment),
  )

  const shouldDisableCapIdInput = capIdMode === '*'
  const shouldDisableCountryOfEmploymentInput = countryOfEmploymentMode === '*'

  useEffect(() => {
    if (modalVisibility === true) {
      setHasSentRequest(false)
      setHasError(false)
    }
  }, [modalVisibility])

  const capIdModeChangeHandler = ({ target: { value } }) => {
    setCapIdMode(value)
    if (value === '*') form.setFieldsValue({ capIds: '*' })
    else if (value === 'selected') form.setFieldsValue({ capIds: formValuesMemo.capIds })
  }

  const countryOfEmploymentModeChangeHandler = ({ target: { value } }) => {
    setCountryOfEmploymentMode(value)
    if (value === '*') form.setFieldsValue({ countryOfEmployment: '*' })
    else if (value === 'selected') form.setFieldsValue({ countryOfEmployment: formValuesMemo.countryOfEmployment })
  }

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasSentRequest(false)
    setHasError(false)
    form.resetFields()
  }

  const CapIdLabel = () => {
    return (
      <>
        <Typography.Text>CAP IDs</Typography.Text>
      &nbsp;&nbsp;&nbsp;
        <Radio.Group
          options={[{ label: 'Any', value: '*' }, { label: 'Selected', value: 'selected' }]}
          onChange={capIdModeChangeHandler}
          value={capIdMode}
          optionType='button'
          size='small'
        />
      &nbsp;&nbsp;&nbsp;
        {shouldDisableCapIdInput
          ? <Typography.Text type='secondary'>(will be saved as *)</Typography.Text>
          : (
            <>
              <Typography.Text type='secondary'>(separated by commas)</Typography.Text>
            &nbsp;&nbsp;&nbsp;
              <Typography.Text type='secondary'>(e.g., ABCD,AMEX)</Typography.Text>
            </>
          )}
      </>
    )
  }

  const CountryOfEmploymentLabel = () => {
    return (
      <>
        <Typography.Text>Country of Employment</Typography.Text>
      &nbsp;&nbsp;&nbsp;
        <Radio.Group
          options={[{ label: 'Any', value: '*' }, { label: 'Selected', value: 'selected' }]}
          onChange={countryOfEmploymentModeChangeHandler}
          value={countryOfEmploymentMode}
          optionType='button'
          size='small'
        />
      &nbsp;&nbsp;&nbsp;
        {shouldDisableCountryOfEmploymentInput
          ? <Typography.Text type='secondary'>(will be saved as *)</Typography.Text>
          : (
            <>
              <Typography.Text type='secondary'>(2-letter separated by commas)</Typography.Text>
            &nbsp;&nbsp;&nbsp;
              <Typography.Text type='secondary'>(e.g., th,fr)</Typography.Text>
            </>
          )}
      </>
    )
  }

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    if (hasError) setHasError(false)

    setConfirmLoading(true)
    let response = null
    try {
      const values = await form.validateFields()
      setFormValuesMemo({
        id: initialValues.id,
        ...form.getFieldsValue(),
      })
      const { capIds, countryOfEmployment, ...rest } = values

      const data = {
        ...rest,
        capIds: Array.isArray(capIds) ? capIds?.map((o) => { return o.trim() })?.join(',') : trimCsv(capIds),
        countryOfEmployment: Array.isArray(countryOfEmployment) ? countryOfEmployment?.map((o) => { return o.trim() })?.join(',') : trimCsv(countryOfEmployment),
      }
      const strippedFalsy = removeUndefinedOrNullFromObject({
        ...data,
        capIds: data.capIds || null, // Cast to null when the key is an empty string
        countryOfEmployment: data.countryOfEmployment || null, // Cast to null when the key is an empty string
      })
      if (values) {
        response = await Api.ClientGroups.updateById(formValuesMemo.id, strippedFalsy)
        if (response?.data?.errorMessage?.toUpperCase() === 'CONFLICT') {
          const conflictError = Error()
          conflictError.status = 409
          throw conflictError
        }
        if (response) {
          form.resetFields()
          setConfirmLoading(false)
          setHasSentRequest(true)
          notification.success({
            message: 'Success',
            description: (
              <>
                <Typography.Text>Only</Typography.Text>
                <Typography.Text strong>
                  &nbsp;
                  {`'${initialValues.environment}'`}
                  &nbsp;
                </Typography.Text>
                <Typography.Text>will be affected by this edit.</Typography.Text>
              </>
            ),
          })
          closeModal()
          triggerSearch()
          // return response
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)
      setConfirmLoading(false)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404].indexOf(error.status) !== -1) {
        setHasError(true)
        notification.error({
          duration: null,
          message: 'Error',
          description: 'Something went wrong',
        })
      } else if (error.status && [400].indexOf(error.status) !== -1) {
        setHasError(true)
        notification.error({
          duration: null,
          message: 'Error',
          description: 'Make sure CAP IDs, and Country of Employment are in correct format',
        })
      } else if (error.status && [409].indexOf(error.status) !== -1) {
        setHasError(true)

        notification.error({
          duration: null,
          message: 'Error',
          description: (
            <>
              <Typography.Text>
                The changes can&apos;t be saved as there is a client gorup with the same Type, CAP IDs,
                and Country of Employment already exist under the name
              </Typography.Text>
              &nbsp;
              <Typography.Text strong>
                {`${response?.data?.items[0]?.name}`}
                .
              </Typography.Text>
            </>
          ),
        })
      }
    }
  }

  const onBack = () => {
    closeModal()
    if (hasSentRequest) {
      triggerSearch()
    }
  }

  return (
    <Modal
      forceRender
      title={`Edit client group ${initialValues.name}`}
      visible={modalVisibility}
      onOk={onSubmit}
      okText='Submit'
      confirmLoading={confirmLoading}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button
          key='back'
          onClick={onBack}
        >
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={confirmLoading} onClick={onSubmit}>
          Submit
        </Button>,
      ]}
      centered
      width='75vw'
      bodyStyle={{
        height: '75vh',
      }}
      style={{
        transition: 'none',
        animationDuration: '0s',
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form__new_client_group'
        initialValues={formValuesMemo}
      >
        <Form.Item
          name='name'
          label='Name'
          rules={rules.name}
        >
          <Input
            placeholder='Add new client group'
          />
        </Form.Item>
        <Form.Item
          label='Description'
          name='description'
        >
          <Input.TextArea
            name='description'
            placeholder='Additional data/information'
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item
          name='type'
          label='Type'
          rules={rules.type}
        >
          <Radio.Group size='small'>
            {clientGroupTypes.map((item) => {
              return (
                <Radio.Button key={item} value={item}>
                  {item}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name='capIds'
          label={<CapIdLabel shouldDisableCapIdInput={shouldDisableCapIdInput} />}
        >
          <Input placeholder={capIdMode === '*' ? '*' : 'Separated by commas'} disabled={shouldDisableCapIdInput} />
        </Form.Item>
        <Form.Item name='countryOfEmployment' label={<CountryOfEmploymentLabel />}>
          <Input placeholder={countryOfEmploymentMode === '*' ? '*' : 'Separated by commas'} disabled={shouldDisableCountryOfEmploymentInput} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

EditClientGroupModal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf({}).isRequired,
}

export default EditClientGroupModal
