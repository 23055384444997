import { useReducer } from 'react'
import StoreUtils from '@core/utils/store'
import Api from '../api'

const INITIAL_STATE = {
  loading: false,
  hasError: false,
  error: null,
  data: null,
  channel: null,
  meta: {},
}

export const TEA_PROFILE_LOOKUP = {
  FETCH: StoreUtils.createActionStatus('TEA_PROFILE_LOOKUP/FETCH'),
  USER_NOT_FOUND: 'TEA_PROFILE_LOOKUP/USER_NOT_FOUND',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case TEA_PROFILE_LOOKUP.FETCH.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
      }
    }
    case TEA_PROFILE_LOOKUP.FETCH.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: action.payload.data,
        channel: action.payload.channel,
      }
    }
    case TEA_PROFILE_LOOKUP.FETCH.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      }
    }
    case TEA_PROFILE_LOOKUP.USER_NOT_FOUND: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: null,
      }
    }
    default: throw new Error('MISSING REDUCER EVENT')
  }
}

export const useTeaProfileLookupReducer = () => { return useReducer(reducer, INITIAL_STATE) }

export const lookupTeaTravelerProfile = async (dispatch, environment, params) => {
  dispatch({ type: TEA_PROFILE_LOOKUP.FETCH.REQUEST })

  try {
    const response = await Api.getTeaProfileTraveler(environment, params)
    if (response.status === 'success') {
      const paramsChannelList = { capId: response.data.capId, countryOfEmployment: response.data.countryOfEmployment }
      const channelList = await Api.getListAllChannelsEnabledByCapIdAndCountry(environment, paramsChannelList)
      if (channelList.status === 'success') {
        dispatch({
          type: TEA_PROFILE_LOOKUP.FETCH.SUCCESS,
          payload: {
            data: response.data,
            channel: channelList.data,
          },
        })
      }

      return response.data
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    if (error.status === 404) {
      dispatch({
        type: TEA_PROFILE_LOOKUP.USER_NOT_FOUND,
      })
    } else {
      dispatch({
        type: TEA_PROFILE_LOOKUP.FETCH.FAILED,
        error,
      })
    }

    throw error
  }
}
