import React from 'react'
import { Switch, Route } from 'react-router-dom'

import EmailAutomationHome from './pages'

const EmailAutomationModule = () => {
  return (
    <Switch>
      <Route path='/email-automations' component={EmailAutomationHome} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/email-automations',
    component: EmailAutomationModule,
  },
  name: 'EmailAutomation',
}

export default EmailAutomationModule

/*
  Email Automation
  |-- Email Query (searching and Managing email data sets in the data set)
  |-- Version
  |-- Models
  |-- Hierarchy (Like Domain/intent)
*/
