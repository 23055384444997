import React, { useState } from 'react'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Modal,
  List,
  Typography,
  Divider,
  Spin,
  notification,
  Cascader,
  Space,
  Checkbox,
} from 'antd'
import { useSelector } from 'react-redux'
import { highlightTextAsHtml } from '../../../helpers'
import { useSelectedSentences } from '../../../hooks/useSelectedSentences'
import TrainingSetsActions from '../../../store/actions/TrainingSets'
import BulkOperationModalActions from '../../../store/actions/BulkOperationModal'
import GeneralizedSentence from '../../GeneralizedSentence'

const { Text, Title } = Typography

const SelectedSentence = ({
  shouldMarkText,
  showGeneralized,
  generalized,
  highlightedText,
  text,
}) => {
  return (
    <List.Item>
      <Space size='small' align='start' direction='vertical'>
        {shouldMarkText ? (
        // eslint-disable-next-line react/no-danger
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightedText) }} />)
          : <span>{text}</span>}
        {showGeneralized && generalized.generalized && (
          <Space size='small' align='start' direction='horizontal'>
            <Text type='success' strong>G:</Text>
            {generalized.generalized ? <GeneralizedSentence generalizedVer={generalized.generalized} /> : <Text type='warning'>N/A</Text>}
          </Space>
        )}
      </Space>
    </List.Item>
  )
}

SelectedSentence.propTypes = {
  shouldMarkText: PropTypes.bool.isRequired,
  showGeneralized: PropTypes.bool.isRequired,
  generalized: PropTypes.shape({
    generalized: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  highlightedText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const AddBulkTrainingCaseModal = () => {
  const { intentCascader, currentTextsSearch } = useSelector(({ core, data }) => {
    return {
      intentCascader: core.domainIntent.intentCascader,
      currentTextsSearch: data.searches.current.texts,
    }
  })
  const visibility = useSelector(({ data }) => { return (data.bulkOperationModal.visibility) })
  const selectedSentences = useSelectedSentences()
  const [form] = Form.useForm()
  // TODO: Probably pull out to store
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [showGeneralized, setShowGeneralized] = useState(true)
  const onToggleShowGeneralized = (event) => {
    const { target: { checked } } = event
    setShowGeneralized(checked)
  }
  const onSelectOptions = () => { }

  const closeModal = () => {
    form.resetFields()
    setConfirmLoading(false)
    setHasError(false)

    BulkOperationModalActions.close()
  }

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    if (hasError) setHasError(false)
    setConfirmLoading(true)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        const [train_domain_id, train_intent_id] = values.domain_intent
        const sentenceIds = selectedSentences.map((sentence) => { return sentence.id })
        response = await TrainingSetsActions.bulkAddIntentTrainingSets(sentenceIds, {
          train_domain_id,
          train_intent_id,
        })

        if (response) {
          form.resetFields()
          setConfirmLoading(false)
          notification.success({
            message: 'Successfully added bulk test case(s)',
            duration: 10,
          })

          return response
        }
      }
    } catch (info) {
      console.info('Error:', info)
      setConfirmLoading(false)

      // NOTE: Must not be the validation error
      if (info.status && [500, 404, 400].indexOf(info.status) !== -1) setHasError(true)
    }
  }

  const shouldMarkText = currentTextsSearch.length > 0

  if (!visibility) return null

  return (
    <Modal
      title='Apply Test Domain/Intent Training case'
      visible={visibility}
      maskClosable={false}
      centered
      closable={false}
      onOk={onSubmit}
      onCancel={closeModal}
      confirmLoading={confirmLoading}
      footer={[
        <Button key='back' onClick={closeModal} disabled={confirmLoading}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={confirmLoading} onClick={onSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Spin spinning={confirmLoading}>
        <Title level={5}>These are sentences to be applied</Title>
        <Checkbox
          onChange={onToggleShowGeneralized}
          checked={showGeneralized}
        >
          Show Generalized version
        </Checkbox>
        <br />
        {selectedSentences.length && (
          <List
            size='small'
            footer={(
              <div>
                <Text strong>
                  {`Total ${selectedSentences.length} sentence(s)`}
                </Text>
              </div>
            )}
            bordered
            dataSource={selectedSentences}
            renderItem={(item) => {
              const { text, generalized } = item
              const sanitizedText = DOMPurify.sanitize(text)
              const highlightedText = highlightTextAsHtml(sanitizedText, currentTextsSearch)

              return (
                <SelectedSentence
                  shouldMarkText={shouldMarkText}
                  showGeneralized={showGeneralized}
                  generalized={generalized}
                  highlightedText={highlightedText}
                  text={text}
                />
              )
            }}
          />
        )}
        <Divider />
        <Form
          form={form}
          layout='vertical'
          name='form__batch_add_training_case'
        >
          <Form.Item
            name='domain_intent'
            label='Select Train Domain/Intent'
            rules={[
              {
                required: true,
                message: 'Please selected desired Domain/Intent',
              },
            ]}
          >
            <Cascader
              options={intentCascader}
              onChange={onSelectOptions}
              placeholder='Select Train Domain/Intent'
            />
          </Form.Item>
          {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
        </Form>
      </Spin>
    </Modal>
  )
}

AddBulkTrainingCaseModal.propTypes = {}

export default AddBulkTrainingCaseModal
