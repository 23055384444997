import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { shallowCleanFalsyObject } from '@core/helpers'
import {
  Button,
  Form,
  Select,
  Input,
} from 'antd'
import { FaqVersionContext } from '../../../helpers/context'
import { getFaqCategories } from '../../../query'
import GetSubCategories from './GetSubCategories'

const QueryFilters = ({ loading, search }) => {
  const [form] = Form.useForm()
  const { faqVersionId } = useContext(FaqVersionContext)
  const [faqCategories, setFaqCategories] = useState([])
  const [selectedFaqCategories, setSelectedFaqCategories] = useState(undefined)
  const [faqCategoriesLoading, setFaqCategoriesLoading] = useState(false)

  const onFinish = (values) => {
    if (loading) return
    const filter = shallowCleanFalsyObject(values)
    search(filter)
  }

  // Get ALL FAQ categories for the filter, but picks only the one "WITHOUT parents_id"
  useEffect(() => {
    let mounted = true

    const fetchFaqCategories = async () => {
      setFaqCategoriesLoading(true)
      if (mounted) {
        const categories = await getFaqCategories(faqVersionId)

        if (categories) {
          setFaqCategories(() => { return (categories.filter((category) => { return !category.parent_id })) })
          setFaqCategoriesLoading(() => { return false })
        }
      }
    }

    fetchFaqCategories()

    return () => {
      mounted = false
    }
  }, [faqVersionId]) // should react only when faqVersionId changed globally

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='inline'
      aria-disabled={loading}
    >
      <Form.Item label='Category' name='category_id'>
        <Select
          placeholder='Category'
          loading={faqCategoriesLoading}
          value={selectedFaqCategories}
          onChange={setSelectedFaqCategories}
          allowClear
        >
          {faqCategories.map(({ id, name }, index) => { return (<Select.Option key={index} value={id}>{name}</Select.Option>) })}
        </Select>
      </Form.Item>
      {selectedFaqCategories && (
        <GetSubCategories key={selectedFaqCategories} faqParentCategoryId={selectedFaqCategories} />
      )}
      <Form.Item label='Search' name='question_text'>
        <Input placeholder='Searching Term' />
      </Form.Item>
      <Form.Item name='language' label='Language'>
        <Select placeholder='Language' allowClear>
          <Select.Option value='en'>English (en)</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>Submit</Button>
      </Form.Item>
    </Form>
  )
}

QueryFilters.propTypes = {
  loading: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
}

export default QueryFilters
