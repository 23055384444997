import Api from '../api'

export const getFaqCategories = async (faqVersion, parent_id) => {
  try {
    const response = await Api.getCategories({
      faq_version_id: faqVersion,
      parent_id,
      perPage: 200, // Max page size
    })

    if (response && response.status === 'success') {
      return response.data.items
    }

    throw new Error('Cannot fetch getCategories')
  } catch (error) {
    console.warn('getFaqCategories error')

    return []
  }
}

export const getChoiceCategories = async (faqVersion) => {
  try {
    const response = await Api.getChoiceCategories({
      faq_version_id: faqVersion,
      perPage: 200, // Max page size
    })

    if (response && response.status === 'success') {
      return response.data.items
    }

    throw new Error('Cannot fetch getChoiceCategories')
  } catch (error) {
    console.warn('getChoiceCategories error')

    throw error
  }
}

export const getFaqAnswerChoices = async (faq_version_id, choice_category_id) => {
  try {
    const response = await Api.getChoices({ faq_version_id, choice_category_id })

    if (response && response.status === 'success') {
      return response.data.items
    }

    throw new Error('Cannot fetch getChoices')
  } catch (error) {
    console.warn('getFaqAnswerChoices error')

    throw error
  }
}
