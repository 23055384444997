import { BULK_OPERATION_MODAL_UI } from '../constants'

const INITIAL_STATE = {
  visibility: false,
  selectedOperation: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BULK_OPERATION_MODAL_UI.OPEN: {
      return {
        visibility: true,
        selectedOperation: action.payload.selectedOperation,
      }
    }
    case BULK_OPERATION_MODAL_UI.CLOSE: {
      return {
        ...INITIAL_STATE,
      }
    }
    default: return state
  }
}
