import PropTypes from 'prop-types'

const {
  shape,
  objectOf,
  string,
  func,
  object,
} = PropTypes

export const formInstanceType = shape(objectOf(func))
export const sentenceType = string
export const selectedCaseType = shape(objectOf(func))
export const taggerDataType = shape(object)
export const extractorDataType = shape(object)
