import React from 'react'
import { Input, Form } from 'antd'
import { rulesType } from '@core/types/formItems'

const { TextArea } = Input

const ExtractorExpectedData = ({ rules }) => {
  return (
    <Form.Item
      name='expected_data'
      label='Expected data'
      rules={rules}
    >
      <TextArea showCount autoSize />
    </Form.Item>
  )
}

ExtractorExpectedData.propTypes = {
  rules: rulesType,
}

ExtractorExpectedData.defaultProps = {
  rules: [],
}

export default ExtractorExpectedData
