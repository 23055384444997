import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Space,
  Table,
  Popconfirm,
} from 'antd'
import dayjs from 'dayjs'
import { useFaqVersionsReducer, useFetchFaqVersionsOnLoad } from '../../hooks/useFaqVersions'
import style from './index.module.scss'

const SelectVersion = ({
  onSelectVersion,
  selectedVersion,
}) => {
  const [{
    loading,
    data: { items },
  }, faqVersionsDispatcher] = useFaqVersionsReducer()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: {
        compare: (a, b) => { return a.id - b.id },
        multiple: 1,
      },
    },
    {
      title: 'Note',
      key: 'note',
      dataIndex: 'note',
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: {
        compare: (a, b) => { return new Date(a.created_at) - new Date(b.created_at) },
        multiple: 1,
      },
      render: (value) => { return <span>{dayjs(value).format('DD MMM YYYY@HH:mm')}</span> },
    },
    {
      title: 'Action',
      key: 'action',
      render: (faqVersion) => {
        const { id } = faqVersion
        const onSelect = () => { onSelectVersion(id) }

        return (
          <Space>
            <Popconfirm
              title='Select this version?'
              onConfirm={onSelect}
              okText='Yes'
              cancelText='No'
            >
              {onSelectVersion && <Button type='primary' disabled={id === selectedVersion}>SELECT</Button>}
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  useFetchFaqVersionsOnLoad(faqVersionsDispatcher)

  return (
    <Table
      bordered
      loading={loading}
      size='small'
      dataSource={items}
      columns={columns}
      rowKey='id'
      rowClassName={(version) => { return (version.id === selectedVersion ? style['table__row--selected'] : undefined) }}
    />
  )
}

SelectVersion.propTypes = {
  selectedVersion: PropTypes.number.isRequired,
  onSelectVersion: PropTypes.func.isRequired,
}

export default SelectVersion
