import React from 'react'
import { Space, Button } from 'antd'
import PropTypes from 'prop-types'

import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'

import style from './style.module.scss'

const ApprovalBulkActions = ({
  isBatchApprovalButtonDisabled,
  approveAllIntentTagging,
  rejectAllIntentTagging,
}) => {
  return (
    <Space>
      <PermittedFeatureCheck featurePath='emailautomations.intentTrainingData.actionApprove'>
        <Button
          disabled={isBatchApprovalButtonDisabled}
          className={style.RejectAll}
          size='small'
          onClick={rejectAllIntentTagging}
        >
          Reject All
        </Button>
      </PermittedFeatureCheck>
      <PermittedFeatureCheck featurePath='emailautomations.intentTrainingData.actionApprove'>
        <Button
          disabled={isBatchApprovalButtonDisabled}
          className={style.ApproveAll}
          size='small'
          onClick={approveAllIntentTagging}
        >
          Approve All
        </Button>
      </PermittedFeatureCheck>
    </Space>
  )
}

ApprovalBulkActions.propTypes = {
  isBatchApprovalButtonDisabled: PropTypes.bool.isRequired,
  approveAllIntentTagging: PropTypes.func.isRequired,
  rejectAllIntentTagging: PropTypes.func.isRequired,
}

export default ApprovalBulkActions
