import React from 'react'
import { Form, DatePicker, Divider, Tooltip } from 'antd'

const ByDateRange = () => {
  return (
    <>
      <Divider>
        <Tooltip title='Start/End date time will be set to T00:00:00Z/T23:59:59Z, respectively'>
          <small><strong>By Date Email Sent/Date Created</strong></small>
        </Tooltip>
      </Divider>
      <Form.Item name='emailSentAt' label='Email Sent'>
        <DatePicker.RangePicker format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item name='recordCreatedAt' label='Date Created'>
        <DatePicker.RangePicker format='DD/MM/YYYY' />
      </Form.Item>
    </>
  )
}

export default ByDateRange
