import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Switch,
  Result,
  Space,
  Typography,
} from 'antd'
import ConversationMessagesPanel from '@core/components/ConversationPage'

const ConversationMessage = ({ messages }) => {
  const [onlyCustomerMessages, setOnlyCustomerMessages] = useState(false)
  const onToggleShowOnlyCustomerMessages = (checked) => {
    setOnlyCustomerMessages(checked)
  }

  if (!messages) {
    return (<Result status='error' title='There are some problems with your operation.' />)
  }

  return (
    <>
      <div>
        <Space direction='horizontal'>
          <Switch onChange={onToggleShowOnlyCustomerMessages} />
          <Typography.Text strong>Show only Customer message</Typography.Text>
        </Space>
      </div>
      <ConversationMessagesPanel messages={messages} showOnlyCustomerMessage={onlyCustomerMessages} />
    </>
  )
}

ConversationMessage.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ConversationMessage
