import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import {
  Modal,
  Button,
  Alert,
  Tabs,
  Form,
  Select,
  notification,
} from 'antd'
import { QuestionCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import TabPaneLabel from '@core/components/TabPaneLabel'
import ExpandableErrorMessage from '@core/components/ExpandableErrorMessage'
import { FaqVersionContext } from '../../../helpers/context'
import { processFaqEntityPayload } from '../../../helpers'
import { updateFaqEntity } from '../../../hooks/useManageFaqs'
import Questions from './Questions'
import Answers from './Answers'
import style from './index.module.scss'

const EditFaqModal = ({
  selectedEditingFaq,
  visible,
  loading,
  closeModal,
  dispatcher,
  refreshResult,
}) => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [hasFormError, setHasFormError] = useState(false)
  const [defaultMessageError, setDefaultMessageError] = useState(false)
  const [activeKey, setActiveKey] = useState('questions')

  const onSave = async (values) => {
    setHasError(false)
    setHasFormError(false)
    setDefaultMessageError(false)

    const payload = processFaqEntityPayload(values, faqVersionId)
    payload.main_question = {
      ...payload.main_question,
      id: selectedEditingFaq.main_question_id,
    }

    try {
      const response = await updateFaqEntity(dispatcher, selectedEditingFaq.id, payload)

      if (response) {
        await refreshResult() // Fetch again, techically on the update eveny we don't need update state anymore
        closeModal()

        notification.success({ message: 'Edit Entity Success' })
      }
    } catch (error) {
      setErrorMessage(error)
      setHasError(true)
    }
  }

  const handleActiveKeyChange = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const onFormError = ({ values }) => {
    const { main_question, fallback_response } = values
    if (!main_question || !fallback_response) setDefaultMessageError(true)
    setHasFormError(true)
  }

  useEffect(() => {
    setActiveKey('questions')
    // For edit, do not put it in the condition
    // otherwise it will get state state
    // seems like antd form use memo+context together
    form.resetFields()
  }, [form, selectedEditingFaq])

  if (!visible || !form || !selectedEditingFaq) return null

  return (
    <Modal
      title='Edit FAQ'
      className='EditFaqModal'
      maskClosable={false}
      visible={visible}
      centered
      onCancel={closeModal}
      destroyOnClose
      width={1024}
      footer={[
        <Button key='back' onClick={closeModal} disabled={loading}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' disabled={loading} loading={loading} onClick={form.submit}>
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onSave}
        onFinishFailed={onFormError}
        initialValues={{
          language: selectedEditingFaq.language,
          category_id: selectedEditingFaq.category_id,
          subcategory_id: selectedEditingFaq.subcategory_id,
          choice_category_id: selectedEditingFaq.choice_category_id,
          faq_questions: [
            selectedEditingFaq.main_question,
            ...selectedEditingFaq.similar_questions,
          ],
          fallback_response: get(selectedEditingFaq, 'fallback_response.lines', ['']),
          response: selectedEditingFaq.responses,
        }}
      >
        <Form.Item
          name='language'
          label='Language'
          rules={[{ required: true, message: 'Required field' }]}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Select placeholder='Language' allowClear>
            <Select.Option value='en'>English (en)</Select.Option>
          </Select>
        </Form.Item>
        <Tabs defaultActiveKey='questions' activeKey={activeKey} onChange={handleActiveKeyChange}>
          <Tabs.TabPane
            tab={(
              <TabPaneLabel
                title='Questions'
                icon={<QuestionCircleOutlined />}
                isActive={activeKey === 'questions'}
              />
            )}
            key='questions'
            forceRender
          >
            {form && <Questions form={form} />}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <TabPaneLabel
                title='Answers'
                icon={<CheckCircleOutlined />}
                isActive={activeKey === 'answers'}
              />
            )}
            key='answers'
            forceRender
          >
            {/* choiceCategoryId passed to set initial state for useState to mitigate wasted render */}
            {form && (
              <Answers
                form={form}
                choiceCategoryId={selectedEditingFaq.choice_category_id}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Form>
      {(hasError || hasFormError || defaultMessageError) ? (
        <div className={style.Errors}>
          {hasError && <Alert className={style.Error__response_with_content} showIcon type='error' message={<ExpandableErrorMessage error={errorMessage.data} />} />}
          {hasFormError && <Alert className={style.Error__response} showIcon type='error' message='Please check all the required fields, or FAQ Sentence(s)' />}
          {defaultMessageError && <Alert className={style.Error__response} showIcon type='error' message='There is no fallback answer message, required at least one variation' />}
        </div>
      ) : null}
    </Modal>
  )
}

EditFaqModal.propTypes = {
  selectedEditingFaq: PropTypes.shape({
    id: PropTypes.string,
    language: PropTypes.string,
    category_id: PropTypes.string,
    subcategory_id: PropTypes.string,
    category: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    subcategory: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    choice_category_id: PropTypes.string,
    choice_category: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    main_question_id: PropTypes.string,
    main_question: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    similar_questions: PropTypes.arrayOf(PropTypes.object),
    responses: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object),
    }),
    fallback_response: PropTypes.shape({
      lines: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  dispatcher: PropTypes.func.isRequired,
  refreshResult: PropTypes.func.isRequired,
}

export default EditFaqModal
