import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Popconfirm, Space, Table, Col, Row, Modal, Form, Input, notification, Select, Typography } from 'antd'
import {
  formatDateTime,
  sortTextOrNull,
  sortDateTimeOrNull,
  reHashIndex,
} from '@core/helpers'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import useRequest from '@core/hooks/useRequest'
import { useSavedSimulation, SavedSimulationApi, useGetAllSavedSimulationEffect, SAVED_SIMULATION } from '../../../../hooks/useSavedSimulation'
import Api from '../../../../api'
import style from './index.module.scss'

const TestCases = ({
  selectedTestCase,
  setSelectedTestCase,
  loadToEndToEnd,
  activeKey,
  setActiveKey,
  onRunTestCasesSuccess,
  shouldUpdate,
  shouldUpdateDispatcher,
  parentTab,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [isSelectedDeleteModalOpen, setIsSelectedDeleteModalOpen] = useState(false)
  const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRunTestModalOpen, setRunTestModalOpen] = useState(false)
  const [testJobNameForm] = Form.useForm()
  const [editDescriptionForm] = Form.useForm()
  const [addToCollectionForm] = Form.useForm()
  const hasSelected = selectedRows.length > 0

  const [
    {
      loading: savedSimulatorLoading,
      data: { conversationSimulations },
    },
    dispatch,
  ] = useSavedSimulation()
  const [
    { loading: loadingTestCollection, data: testCollections },
    { silentMakeRequest: requestTestCollectionChoices },
  ] = useRequest(Api.EndToEnd.getAllTestCaseCollection, { showAll: true })

  const fetchData = useCallback(async () => {
    dispatch({ type: SAVED_SIMULATION.FETCH.REQUEST })
    const response = await SavedSimulationApi.findAll()
    if (response.status === 'success') {
      dispatch({
        type: SAVED_SIMULATION.FETCH.SUCCESS,
        payload: {
          conversationSimulations: response.data,
          hashTable: reHashIndex(response.data),
        },
      })
    }
  }, [dispatch])

  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedRows(newSelectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  useEffect(() => {
    if (activeKey === 'testCase') {
      if (shouldUpdate.collectionChoices) {
        requestTestCollectionChoices()
        shouldUpdateDispatcher({ type: 'collectionChoices', payload: { status: false } })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  useEffect(() => {
    if (shouldUpdate.testCases && activeKey === 'testCase' && parentTab === 'tab__testCaseManagement') {
      const fetchDataInUseEffect = async () => {
        await fetchData()
      }
      fetchDataInUseEffect()
      shouldUpdateDispatcher({ type: 'testCases', payload: { status: false } })
    }
  }, [parentTab, fetchData, shouldUpdate.testCases, shouldUpdateDispatcher, activeKey])

  useGetAllSavedSimulationEffect(dispatch)

  const successNotiContentOfAddToCollection = ({ countAddNew, countAll, collectionName, alreadyAddDescriptions = [] }) => {
    return (
      <Row>
        <Col>
          {`${countAddNew}/${countAll} test cases added to `}
          <span className={style.E2ENotiSuccessCollectionName}>{`'${collectionName}'.`}</span>
          {alreadyAddDescriptions.length > 0 && <Row>The followings were already in the collection</Row>}
          {alreadyAddDescriptions.map((alreadyTestCase, index) => {
            return (
              <Row key={index}>
                {`- ${alreadyTestCase}`}
              </Row>
            )
          })}
        </Col>
      </Row>
    )
  }

  const showModal = (testCase, submitForm) => {
    setSelectedTestCase(testCase)
    submitForm.setFieldsValue({ note: testCase.note })
    setIsModalOpen(true)
  }

  const handleEditDescriptionCancel = (submitForm) => {
    submitForm.resetFields()
    setIsModalOpen(false)
  }

  const onFinishEditDescriptionForm = async (values) => {
    const { id, note } = selectedTestCase
    const updatedDescription = values.note.trim()
    if (updatedDescription === note) { setIsModalOpen(false); return }

    try {
      await Api.Simulation.updateSimulation(
        id,
        { note: updatedDescription, skipAudit: true },
      )
      shouldUpdateDispatcher({ type: 'testCollections', payload: { status: true } })
      setIsModalOpen(false)
      await fetchData()
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `We've run into the issue from edit description test cases id: ${id}`,
        duration: 5,
      })
    }
  }

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: 'Error',
      description: `${errorInfo.errorFields[0].errors[0]}`,
      duration: 5,
    })
  }

  const deleteTestCases = async (ids) => {
    try {
      await Api.EndToEndTestCases.deleteTestCasesByIds(ids)
      notification.success({
        message: 'Success',
        description: 'Deleted',
        duration: 3,
      })
      shouldUpdateDispatcher({ type: 'testCollections', payload: { status: true } })
      await fetchData()
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'We\'ve run into the issue from deleting test cases',
        duration: 5,
      })
    }
  }

  const handleConfirmDeleteModal = async (ids) => {
    await deleteTestCases(ids)
    setIsSelectedDeleteModalOpen(false)
  }

  const handleSaveToCollection = async (selectedIds, form, rows) => {
    try {
      const { value, label } = form.getFieldValue('collection')
      const { data } = await Api.EndToEndTestCases.addTestCasesToCollection(value, selectedIds)
      shouldUpdateDispatcher({ type: 'testCollections', payload: { status: true } })
      const alreadyAddDescriptions = rows.filter(({ id: rowId }) => {
        const isReturnDataExistedInRows = data.some(({ testCaseId }) => {
          return rowId === testCaseId
        })
        return !isReturnDataExistedInRows
      }).map(({ note }) => { return `${note}\n` })

      notification.success({
        style: { width: '450px' },
        message: 'Success',
        description: successNotiContentOfAddToCollection({
          countAll: selectedIds.length, countAddNew: data.length, collectionName: label, alreadyAddDescriptions,
        }),
        duration: 5,
      })

      form.resetFields()
      setIsAddCollectionModalOpen(false)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'We\'ve run into the issue from add test cases to test the collections.',
        duration: 5,
      })
    }
  }

  const handleCancelToCollection = (selectedForm) => {
    selectedForm.resetFields()
    setIsAddCollectionModalOpen(false)
  }

  const extraButtonDetails = [{
    buttonText: '+ Add Selected to a Collection',
    onClick: () => { setIsAddCollectionModalOpen(true) },
    type: 'primary',
  }, {
    buttonText: 'Run Selected',
    onClick: () => {
      setRunTestModalOpen(true)
    },
    type: 'primary',
  }, {
    buttonText: 'Delete Selected',
    onClick: () => { setIsSelectedDeleteModalOpen(true) },
    type: 'danger',
  }]

  // eslint-disable-next-line no-unused-vars
  const onTableChange = async (pagination, filter, sorter) => {
    // TODO: implement pagination and sort from api to improve performance.
  }

  const columns = [
    {
      title: 'Description',
      dataIndex: 'note',
      sorter: (a, b) => { return sortTextOrNull(b.note, a.note) },
      ellipsis: true,
      width: '40%',
      defaultSortOrder: 'descend',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: (a, b) => { return sortDateTimeOrNull(a.createdAt, b.createdAt) },
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      sorter: (a, b) => { return sortDateTimeOrNull(a.updatedAt, b.updatedAt) },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 320,
      render: (simulation) => {
        const { note, id } = simulation
        return (
          <Space>
            <Popconfirm
              title={`Confirm to run test case "${note}"?`}
              placement='topRight'
              onConfirm={async () => {
                try {
                  await Api.EndToEnd.evaluateBySimulationIdsWithTestReport({ simulationIds: [id], jobName: note })
                  onRunTestCasesSuccess()
                } catch (error) {
                  notification.error({
                    message: 'Error',
                    description: `Failed to evaluated test case ${note}.`,
                    duration: 5,
                  })
                }
              }}
              okText='Yes'
              cancelText='No'
            >
              <Button type='primary' size='small'>
                Run
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Confirm to modify test case "${note}" ?`}
              placement='topRight'
              onConfirm={async () => {
                setSelectedTestCase(simulation)
                await loadToEndToEnd(id, false)()
                setActiveKey('modification')
              }}
              okText='Yes'
              cancelText='No'
            >
              <Button type='default' size='small'>
                Modify Testcase
              </Button>
            </Popconfirm>
            <Button type='default' size='small' onClick={() => { showModal(simulation, editDescriptionForm) }}>
              Edit
            </Button>
            <Popconfirm
              title={`Confirm to delete test case "${note}" ?`}
              placement='topRight'
              onConfirm={() => { deleteTestCases([id]) }}
              okButtonProps={{ danger: true }}
              okText='Delete'
              cancelText='No'
            >
              <Button type='primary' size='small' danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const TableTitle = () => {
    return (
      <Row>
        <Col span={24}>
          <Row justify='space-between' align='middle'>
            <Col span={12}>
              <Typography.Title level={5}>
                Test Cases
                <span className={style.testCaseSelectedCount}>
                  {hasSelected ? `Selected ${selectedRows.length} items` : ''}
                </span>

              </Typography.Title>
            </Col>
            <Col span={12}>
              <Row justify='end'>
                {extraButtonDetails.map(
                  (buttonDetail, index) => {
                    return (
                      <Button
                        size='small'
                        disabled={!hasSelected}
                        key={index}
                        onClick={() => { buttonDetail.onClick() }}
                        type={buttonDetail.type}
                        className={style.extraButton}
                      >
                        {buttonDetail.buttonText}
                      </Button>
                    )
                  },
                )}
              </Row>
              {/* run selected modal */}
              <Modal
                title='Run selected test cases'
                visible={isRunTestModalOpen}
                onCancel={() => {
                  setRunTestModalOpen(false)
                }}
                onOk={() => {
                  testJobNameForm.submit()
                }}
              >
                <Form
                  onFinish={async (values) => {
                    const { name } = values
                    try {
                      await Api.EndToEnd.evaluateBySimulationIdsWithTestReport({
                        simulationIds: selectedRows.map((simulation) => { return simulation.id }),
                        jobName: name.trim(),
                      })
                      setRunTestModalOpen(false)
                      onRunTestCasesSuccess()
                    } catch (error) {
                      notification.error({
                        message: 'Error',
                        description: 'Failed to evaluated test cases.',
                        duration: 5,
                      })
                    }
                  }}
                  form={testJobNameForm}
                  layout='vertical'
                >
                  <Form.Item
                    name='name'
                    label='Job name'
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: 'Please input the job name to run test cases!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>

              {/* selected delete modal */}
              <Modal
                title={`Delete selected ( ${selectedRows.length} )`}
                visible={isSelectedDeleteModalOpen}
                onOk={() => { handleConfirmDeleteModal(selectedRows.map((test) => { return test.id })) }}
                onCancel={() => { setIsSelectedDeleteModalOpen(false) }}
                okButtonProps={{ danger: true }}
                okText='Delete'
                cancelText='No'
              >
                <Row>
                  <ExclamationCircleOutlined className={style.E2ESelectedDeleteModalIcon} />
                  <div>Are you sure you want to delete these test cases?</div>
                </Row>
              </Modal>

              {/* add to collection modal */}
              <Modal
                title='Add Selected to a Collection'
                visible={isAddCollectionModalOpen}
                onOk={addToCollectionForm.submit}
                onCancel={() => { handleCancelToCollection(addToCollectionForm) }}
                okText='Add'
              >
                <Row>
                  <Form
                    layout='vertical'
                    form={addToCollectionForm}
                    name='control-hooks'
                    onFinish={() => { handleSaveToCollection(selectedRows.map((test) => { return test.id }), addToCollectionForm, selectedRows) }}
                    style={{ width: '100%' }}
                  >
                    <Form.Item
                      name='collection'
                      label='Select a collection'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        labelInValue
                        loading={loadingTestCollection}
                        placeholder='Select a option and change input text above'
                        allowClear
                        dropdownMatchSelectWidth
                        size='large'
                        listHeight={250}
                        options={testCollections?.items?.map(
                          (collection) => { return { label: collection.description, value: collection.id } },
                        ).sort((option1, option2) => { return option1.label.localeCompare(option2.label) })}
                      // sort a -> z
                      />
                    </Form.Item>
                  </Form>
                </Row>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <>
      {conversationSimulations && (
        <Table
          title={TableTitle}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            defaultPageSize: 50,
          }}
          size='small'
          onChange={onTableChange}
          rowSelection={rowSelection}
          columns={columns}
          loading={savedSimulatorLoading}
          rowKey={(simulation) => { return simulation.id }}
          dataSource={conversationSimulations.map((simulation) => {
            return {
              ...simulation,
              createdAt: formatDateTime(simulation.created_at, 'YYYY-MM-DDTHH:mm:ss'),
              updatedAt: formatDateTime(simulation.updated_at ?? simulation.created_at, 'YYYY-MM-DDTHH:mm:ss'),
            }
          })}
          scroll={{
            scrollToFirstRowOnChange: true,
            y: 375,
          }}
        />
      )}
      <Modal
        title={(
          <Row>
            <span>Edit</span>
            <span className={style.E2EModalTitleDescription}>{`(${selectedTestCase?.note})`}</span>
          </Row>
        )}
        visible={isModalOpen}
        onOk={editDescriptionForm.submit}
        okText='Save'
        onCancel={() => { handleEditDescriptionCancel(editDescriptionForm) }}
      >
        <Form
          form={editDescriptionForm}
          name='basic'
          layout='vertical'
          onFinish={onFinishEditDescriptionForm}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label='Descriptions'
            name='note'
            rules={[{ required: true, whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

TestCases.propTypes = {
  simulationTestCasesState: PropTypes.shape({
    loading: PropTypes.bool,
    hasError: PropTypes.bool,
    error: PropTypes.bool,
    data: PropTypes.shape({
      testCases: PropTypes.arrayOf(PropTypes.object),
      pagination: PropTypes.shape({
        current: PropTypes.number,
        pageSize: PropTypes.number,
        perPage: PropTypes.number,
        page: PropTypes.number,
        totalCount: PropTypes.number,
      }),
      field: PropTypes.string,
      order: PropTypes.string,
    }),
  }).isRequired,
  loadToEndToEnd: PropTypes.func.isRequired,
  selectedTestCase: PropTypes.shape({
    createdAt: PropTypes.string,
    note: PropTypes.string,
    id: PropTypes.string,
  }),
  setSelectedTestCase: PropTypes.func,
  activeKey: PropTypes.string.isRequired,
  setActiveKey: PropTypes.func.isRequired,
  shouldUpdate: PropTypes.shape({
    testCases: PropTypes.bool,
    collectionChoices: PropTypes.bool,
    testCollections: PropTypes.bool,
  }).isRequired,
  shouldUpdateDispatcher: PropTypes.func.isRequired,
  onRunTestCasesSuccess: PropTypes.func,
  parentTab: PropTypes.string,
}

TestCases.defaultProps = {
  selectedTestCase: null,
  setSelectedTestCase: () => { },
  onRunTestCasesSuccess: () => { },
  parentTab: '',
}

export default TestCases
