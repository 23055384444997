import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Modal,
  Space,
  Descriptions,
  Tag,
  notification,
  Skeleton,
  Alert,
  Select,
  Button,
  Divider,
  Input,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import { EmailAutomationsContext } from '../../../context'
import Api from '../../../api'

const tagColors = {
  DEFAULT: 'geekblue',
  DRAFT: 'cyan',
  DEVELOPMENT: 'blue',
  STAGING: 'volcano',
  PRODUCTION: 'gold',
  ARCHIVE: 'magenta',
}

const deployableEnvs = ['DEVELOPMENT', 'CANARY', 'STAGING', 'PRODUCTION']

const deployConfirmation = (version, deployEnvs, deployToEnvironmentFn) => {
  Modal.confirm({
    title: <Typography.Text strong>Deploying version</Typography.Text>,
    width: '768px',
    content: (
      <Space direction='vertical'>
        <Space>
          <Typography.Text>This action will deploy the following version to the </Typography.Text>
          {deployEnvs.map((env) => { return <Tag key={env} color={tagColors[env]}>{env}</Tag> })}
          <Typography.Text> environment:</Typography.Text>
        </Space>
        <Descriptions column={1} bordered size='small'>
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='ID'>{version.id || '>> version.id <<'}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Note'>{version.note || '>> version.note <<'}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Old Status'>
            {version.status.map((item, index) => { return <Tag key={index} color={tagColors[item]}>{item}</Tag> || '>> version.status <<' })}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Created at'>{dayjs(version.created_at).format('DD MMMM YYYY HH:mm z') || '>> version.created_at <<'}</Descriptions.Item>
        </Descriptions>
        <Typography.Text strong>Are you sure?</Typography.Text>
      </Space>
    ),
    cancelText: 'No, Cancel',
    okText: 'Yes, confirm',
    onOk: async () => {
      try {
        // const response = await VersionsActions.deploy(version.id, deployEnvs)
        // const response = await Promise.resolve({ success: true })
        const response = await deployToEnvironmentFn(version.id, deployEnvs)

        if (response.status === 'success') {
          notification.success({
            message: 'Successfully Deployed',
            description: `Version: ${version.id} has been deployed`,
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: error.message,
          duration: 5,
        })
      }
    },
  })
}

const ViewEditModal = ({
  version,
  visible,
  onUpdated,
  onDismissed,
  deployToEnvironmentFn,
}) => {
  const [form] = Form.useForm()
  const [deployEnvs, setDeployEnvs] = useState([])
  const [updating, setUpdating] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)

  const { datasource } = useContext(EmailAutomationsContext)

  useEffect(() => {
    const setData = async () => {
      const filtered = [].concat(version?.status || []).filter((env) => { return deployableEnvs.includes(env) })
      setDeployEnvs(filtered)
    }

    setData()
  }, [version])

  useEffect(() => {
    if (!visible) setFlashMessage(null)
  }, [visible])

  if (!visible) return null

  const handleDeployEnvChange = (value) => {
    setDeployEnvs(value)
  }

  const deployCurrentVersion = () => {
    deployConfirmation(version, deployEnvs, deployToEnvironmentFn)
  }

  const onCancel = async () => {
    setUpdating(false)
    onDismissed()
  }

  const onFinish = async (values) => {
    setFlashMessage(null)
    setUpdating(true)
    try {
      const { note } = values
      await Api.Versions.updateById({ id: version.id, datasource, note })
      setUpdating(false)
      onUpdated()
    } catch (error) {
      setFlashMessage({ type: 'error', message: error.message })
      setUpdating(false)
    }
  }

  const handleFlashMessageClose = () => {
    setFlashMessage(null)
  }

  return (
    <Modal
      title='View/Edit Email Automation version'
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width='60%'
      destroyOnClose
    >
      {flashMessage && (
        <Alert
          message={flashMessage.message}
          type={flashMessage.type}
          showIcon
          closable
          onClose={handleFlashMessageClose}
          style={{ marginBottom: 8 }}
        />
      )}
      {
        version
          ? (
            <>
              <div>
                <Typography.Text>Deployed to:</Typography.Text>
              </div>
              <Space direction='vertical'>
                <Select
                  mode='multiple'
                  value={deployEnvs}
                  placeholder='Select environment'
                  allowClear
                  onChange={handleDeployEnvChange}
                  disabled={updating}
                >
                  {deployableEnvs.map((env, index) => {
                    return (
                      <Select.Option value={env} key={index}>{env}</Select.Option>
                    )
                  })}
                </Select>
                <Button
                  key='1'
                  type='primary'
                  onClick={deployCurrentVersion}
                  disabled={!deployEnvs.length || updating}
                >
                  DEPLOY
                </Button>
              </Space>
              <Divider />
              <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
              >
                <Form.Item
                  name='note'
                  label='Version note'
                  rules={[
                    {
                      required: true,
                      message: 'Please add version note',
                    },
                  ]}
                >
                  <Input placeholder='Enter version note' disabled={updating} />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' htmlType='submit' disabled={updating}>Update</Button>
                </Form.Item>
              </Form>
            </>
          )
          : <Skeleton active />
      }
    </Modal>
  )
}

ViewEditModal.defaultProps = {
  version: null,
}

ViewEditModal.propTypes = {
  version: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
    note: PropTypes.string,
  }),
  visible: PropTypes.bool.isRequired,
  onUpdated: PropTypes.func.isRequired,
  onDismissed: PropTypes.func.isRequired,
  deployToEnvironmentFn: PropTypes.func.isRequired,
}

export default ViewEditModal
