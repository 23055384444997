import domainIntent from './DomainIntentCollection'
import permittedFeatures from './PermittedFeatures'
import versions from './Versions'
import generalizers from './Generalizers'
import auth from './Auth'
import cluster from './Cluster' // FOR YARS
import internationalize from './Internationalize'

export default {
  auth,
  domainIntent,
  generalizers,
  versions,
  permittedFeatures,
  cluster, // FOR YARS
  internationalize,
}
