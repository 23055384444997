import React from 'react'
import { Form, Cascader, Typography } from 'antd'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import { formInstanceType, sentenceType } from '../../../types/form'

const { Title } = Typography

const IntentForm = ({ form, sentence }) => {
  const { intentCascader } = useDomainIntentSelector()

  return (
    <Form
      form={form}
      layout='vertical'
      name='form__training_intent'
    >
      <Title level={4}>{sentence}</Title>
      <Form.Item
        name='domain_intent'
        label='Select Domain/Intent'
      >
        <Cascader
          options={intentCascader}
          placeholder='Select new train intent for training'
        />
      </Form.Item>
    </Form>
  )
}

IntentForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
}

export default IntentForm
