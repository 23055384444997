import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Form,
  Input,
  notification,
} from 'antd'

const formRules = {
  description: [
    {
      required: true,
      message: 'Collection Name is required',
    },
  ],
}

const BaseAddOrEditTestCollectionModal = ({
  submitHandler,
  cancelHandler,
  initialValues,
  modalTitle,
}) => {
  const [form] = Form.useForm()

  const wrappedSubmitHandler = async () => {
    try {
      const values = await form.validateFields()
      return submitHandler(values)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <Modal
      visible
      okText='Save'
      title={modalTitle}
      onOk={wrappedSubmitHandler}
      onCancel={cancelHandler}
    >
      <Form
        layout='vertical'
        form={form}
        initialValues={initialValues}
      >
        <Form.Item
          label='Collection Name'
          name='description'
          rules={formRules.description}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

BaseAddOrEditTestCollectionModal.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
  }),
  modalTitle: PropTypes.string.isRequired,
}

BaseAddOrEditTestCollectionModal.defaultProps = {
  initialValues: {
    description: null,
  },
}

const BaseAddOrEditTestCollectionButton = ({
  buttonProps,
  submitHandler,
  afterSuccessHandler,
  initialValues,
  modalTitle,
}) => {
  const { size, type, text } = buttonProps
  const [isBaseAddOrEditTestCollectionModalVisible, setIsBaseAddOrEditTestCollectionModalVisible] = useState(false)

  const wrappedSubmitHandler = async (data) => {
    try {
      await submitHandler(data)
      setIsBaseAddOrEditTestCollectionModalVisible(false)
      if (afterSuccessHandler) {
        await afterSuccessHandler()
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong',
      })
    }
  }

  const openBaseAddOrEditTestCollectionModalHandler = () => {
    setIsBaseAddOrEditTestCollectionModalVisible(true)
  }

  const closeBaseAddOrEditTestCollectionModalHandler = () => {
    setIsBaseAddOrEditTestCollectionModalVisible(false)
  }

  return (
    <>
      <Button size={size} type={type} onClick={openBaseAddOrEditTestCollectionModalHandler}>{text}</Button>
      {isBaseAddOrEditTestCollectionModalVisible && (
        <BaseAddOrEditTestCollectionModal
          submitHandler={wrappedSubmitHandler}
          cancelHandler={closeBaseAddOrEditTestCollectionModalHandler}
          initialValues={initialValues}
          modalTitle={modalTitle}
        />
      )}
    </>
  )
}

BaseAddOrEditTestCollectionButton.propTypes = {
  buttonProps: PropTypes.shape({
    size: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  submitHandler: PropTypes.func.isRequired,
  afterSuccessHandler: PropTypes.func,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
  }),
  modalTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
}

BaseAddOrEditTestCollectionButton.defaultProps = {
  buttonProps: {},
  afterSuccessHandler: () => {},
  initialValues: {
    description: null,
  },
}

export default BaseAddOrEditTestCollectionButton
