import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Row, Col, Divider, Space } from 'antd'
import { CopyOutlined, CheckOutlined } from '@ant-design/icons'

import style from './style.module.scss'

const EmailContentPreview = ({ selectedEmail }) => {
  return (
    <div className={style.EmailContentPreview}>
      <Row>
        <Col span={8}>
          <Space>
            <span className={style.EmailId}> Email ID:</span>
            <Typography.Text
              strong
              copyable={{
                icon: [<CopyOutlined className={style.CopyableIcon} />, <CheckOutlined className={style.CopyableIcon} />],
              }}
            >
              {selectedEmail.email_id}
            </Typography.Text>
          </Space>
        </Col>
        <Space>
          <Typography.Text strong class={style.EmailTitle}>Email Title:</Typography.Text>
          {selectedEmail.subject}
        </Space>
      </Row>
      <Row className={style.Header} gutter={[8, 0]}>
        <Col span={12}>
          <Typography.Text strong>Raw</Typography.Text>
          <Divider />
        </Col>
        <Col span={12}>
          <Typography.Text strong>Pre-processed</Typography.Text>
          <Divider />
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Typography.Paragraph className={style.EmailContent}>
            {selectedEmail?.body}
          </Typography.Paragraph>
        </Col>
        <Col span={12}>
          <Typography.Paragraph className={style.EmailContent}>
            {selectedEmail?.cleaned_email_body}
          </Typography.Paragraph>
        </Col>
      </Row>
    </div>
  )
}

EmailContentPreview.propTypes = {
  selectedEmail: PropTypes.shape({
    id: PropTypes.string,
    email_id: PropTypes.string,
    from_address: PropTypes.string,
    to_address: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    cleaned_email_subject: PropTypes.string,
    cleaned_email_body: PropTypes.string,
    source: PropTypes.string,
    language: PropTypes.string,
    email_sent_at: PropTypes.string,
    record_created_at: PropTypes.string,
    status: PropTypes.string,
    datasetType: PropTypes.string,
    feedback: PropTypes.string,
    intentSamples: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  }).isRequired,
}

export default EmailContentPreview
