import React from 'react'
import PropTypes from 'prop-types'
import { Space, Button } from 'antd'

import style from './style.module.scss'

const QueryPresetButtons = ({ collapsed, openSaveQueryModal, openQueriesListModal }) => {
  if (collapsed) return null

  return (
    <Space className={style.QueryPresetButtons}>
      <Button size='small' onClick={openSaveQueryModal}>Save</Button>
      <Button size='small' onClick={openQueriesListModal}>View</Button>
    </Space>
  )
}

QueryPresetButtons.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  openSaveQueryModal: PropTypes.func.isRequired,
  openQueriesListModal: PropTypes.func.isRequired,
}

export default QueryPresetButtons
