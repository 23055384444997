import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Space, Table, Tabs, Col, Row, notification, Divider, Empty, Typography, DatePicker } from 'antd'
import { reHashIndex } from '@core/helpers'
import useRequest from '@core/hooks/useRequest'
import * as dayjs from 'dayjs'
import { transformExchangeToRenderUI } from '../../../helpers'
import style from './index.module.scss'
import TestCollection from './TestCollection'
import Api from '../../../api'
import ChatExchanges from '../ChatExchanges'
import ExchangeTestCase from '../EndToEndTest/ExchangeTestCase'
import { e2eStateType } from '../../../types'
import ExpandedTestGroup from './ExpandedTestGroup'
import TestExpandIcon from './TestExpandIcon'
import TestCases from './TestCases'

const TestCaseManagment = (
  { loadToEndToEnd, e2eState, updateExchangeTestCase, addExchangeTestCase, parentTab, shouldUpdate, shouldUpdateDispatcher },
) => {
  const [selectedTestCase, setSelectedTestCase] = useState(null)
  const [fromDate, setFromDate] = useState(dayjs().subtract(90, 'day').startOf('day'))
  const [reportPagination, setReportPagination] = useState({
    page: 1,
    pageCount: 1,
    perPage: 10,
    totalCount: null,
  })
  const [currentViewExchange, setCurrentViewExchange] = useState(null)
  const [activeKey, setActiveKey] = useState('testCase')
  const { data: { rawExchanges, exchangesHashPairedWithTestCases }, loading: isLoadingE2E } = e2eState
  const uiExchageRenderData = transformExchangeToRenderUI(rawExchanges)
  const hashUiExchageRenderData = reHashIndex(uiExchageRenderData)

  const [
    { loading: loadingTestReports, data: testReports },
    { silentMakeRequest: silentRequestTestReport, makeRequest: makeRequestTestReport },
  ] = useRequest(Api.EndToEndTestReports.findTestReports, {
    page: reportPagination.page,
    perPage: reportPagination.perPage,
    fromCreatedAt: fromDate,
  })

  const updateExchangeTestCaseAndRename = (data) => {
    try {
      updateExchangeTestCase(data)
      setSelectedTestCase({ ...selectedTestCase, description: data.description })
      notification.success({ description: 'Modify testcase successfully.' })
    } catch (error) {
      console.error('Error on testcase modification.')
    }
  }

  useEffect(() => {
    let interval
    if (activeKey === 'report' && parentTab === 'tab__testCaseManagement' && testReports?.items?.some((job) => { return job.status === 'RUNNING' })) {
      interval = setInterval(() => {
        silentRequestTestReport()
      }, 5000)
    } else {
      clearInterval(interval)
    }

    return () => { clearInterval(interval) }
  }, [silentRequestTestReport, activeKey, parentTab, testReports])

  const setActiveViewExchange = (exchangeId) => {
    setCurrentViewExchange(exchangeId)
  }

  const onRunTestSuccess = async () => {
    setActiveKey('report')
    setTimeout(silentRequestTestReport, 1500)
  }

  const onTabChange = async (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const onReportPageChange = (page, perPage) => {
    setReportPagination({
      page,
      perPage,
    })
  }

  const terminateJob = async (id) => {
    try {
      await Api.EndToEndTestReports.terminateJob(id)
      notification.success({ description: 'Terminate job successfully.' })
    } catch (error) {
      notification.error({ description: 'Somethings went wrong when terminate job.' })
    }
  }

  const rerunJob = async (reportId) => {
    try {
      await Api.EndToEndTestReports.rerunJob(reportId)
      notification.success({ description: 'Start to rerun job successfully.' })
      await makeRequestTestReport()
    } catch (error) {
      notification.error({ description: 'Somethings went wrong when re-run job.' })
    }
  }

  const testReportTableTitle = () => {
    return (
      <Row justify='space-between'>
        <Typography.Title level={5}>Test Reports</Typography.Title>
        <Space>
          From date:
          <DatePicker
            mode='date'
            format='YYYY-MM-DD'
            disabledDate={(current) => { return (current && current < dayjs().subtract(90, 'day').startOf('day')) || current > dayjs() }}
            showTime={{
              defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
            }}
            value={fromDate}
            onChange={(date) => {
              setReportPagination({ ...reportPagination, page: 1 })
              setFromDate(date)
            }}
          />
        </Space>
      </Row>
    )
  }

  const testReportColumns = [
    {
      title: 'Job Name',
      dataIndex: 'jobName',
      key: 'jobName',
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Durations',
      render: (test) => {
        if (test.status === 'RUNNING') {
          const runningTimeMinute = dayjs().diff(dayjs(test.createdAt), 'minute')
          return runningTimeMinute ? `${runningTimeMinute} mins` : `${dayjs().diff(dayjs(test.createdAt), 's')} secs`
        }
        const timeDiffMinute = dayjs(test.updatedAt).diff(test.createdAt, 'minute')
        return <span>{timeDiffMinute ? `${timeDiffMinute} mins` : `${dayjs(test.updatedAt).diff(test.createdAt, 'seconds')} secs`}</span>
      },
    },
    {
      title: 'Status',
      render: (test) => {
        if (test.status === 'RUNNING') return <Typography.Text strong>{`${test.progress}%`}</Typography.Text>
        if (['TERMINATED', 'ERROR'].includes(test.status)) return <span className={`${style.testStatus} ${style.testFailed}`}>{test.status}</span>
        return <span className={`${style.testStatus} ${test.failCount === 0 && test.status !== 'FAIL' ? style.testPassed : style.testFailed}`}>{test.failCount === 0 && test.status !== 'FAIL' ? 'PASSED' : 'FAILED'}</span>
      },
    },
    {
      title: 'Result',
      width: '160px',
      render: (test) => {
        if (['TERMINATED', 'RUNNING', 'ERROR'].includes(test.status)) return null
        const total = test.passCount + test.failCount
        const widthFailed = Math.floor((test.failCount / total) * 24)
        return (
          <>
            <span className={test.failCount === 0 && test.status !== 'FAIL' ? style.testPassed : style.testFailed}>
              {test.failCount === 0 && test.status !== 'FAIL' ? `${test.passCount} test(s) passed` : `${test.failCount}/${total} tests failed`}
            </span>
            <Row>
              <Col span={widthFailed}>
                <div className={`${style.line} ${style.red}`} />
              </Col>
              <Col span={24 - widthFailed}>
                <div className={`${style.line} ${style.green}`} />
              </Col>
            </Row>
          </>
        )
      },
    },
    {
      title: 'Actions',
      width: 200,
      render: (test) => {
        if (test.status === 'RUNNING') return <Button danger type='primary' size='small' onClick={() => { terminateJob(test.jobId) }}>Terminate</Button>
        return (
          <Button type='primary' size='small' onClick={() => { rerunJob(test.jobId) }}>
            Re-run
          </Button>
        )
      },
    },
  ]

  return (
    <Col className={style.TestCaseManagement}>
      <Tabs
        activeKey={activeKey}
        className={style.testCaseMangementTab}
        onChange={onTabChange}
      >
        <Tabs.TabPane tab='Test Collections' key='testCollection' className={style.tabPanel}>
          <TestCollection
            onRunTestCollectionSuccess={onRunTestSuccess}
            shouldUpdate={shouldUpdate}
            shouldUpdateDispatcher={shouldUpdateDispatcher}
            activeKey={activeKey}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Test Cases' key='testCase' className={style.tabPanel}>
          <TestCases
            selectedTestCase={selectedTestCase}
            setSelectedTestCase={setSelectedTestCase}
            loadToEndToEnd={loadToEndToEnd}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            onRunTestCasesSuccess={onRunTestSuccess}
            shouldUpdate={shouldUpdate}
            shouldUpdateDispatcher={shouldUpdateDispatcher}
            parentTab={parentTab}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Test Report' key='report' className={style.tabPanel}>

          <Table
            title={testReportTableTitle}
            size='small'
            loading={loadingTestReports}
            className={style.testReportTable}
            columns={testReportColumns}
            dataSource={testReports?.items || []}
            pagination={{
              onChange: onReportPageChange,
              current: reportPagination.page,
              pageSize: reportPagination.perPage,
              total: testReports?.meta.totalCount,
            }}
            rowKey='jobId'
            scroll={{
              y: 375,
            }}
            expandable={{
              expandIcon: (renderExpandIconProps) => { return <TestExpandIcon renderExpandIconProps={renderExpandIconProps} /> },
              expandedRowRender: (reports) => {
                const { evaluationResults } = reports
                return (
                  <ExpandedTestGroup
                    conversationWithTestCases={evaluationResults}
                  />
                )
              },
            }}
          />

        </Tabs.TabPane>
        <Tabs.TabPane tab='Test Case Modification' key='modification' className={`${style.tabPanel} ${style.testModifySpace}`}>
          {selectedTestCase ? (
            <Space direction='vertical' className={style.testModifySpace}>
              <Typography.Title level={5} className={style.tabTableTitle}>{selectedTestCase.note}</Typography.Title>
              <Row gutter={[36, 0]} justify='center' className={style.height100}>
                <Col span={8} className={`${style.height100}  ${style.scrollAble}`}>
                  <Space direction='vertical'>
                    <span className={style.headerColumnFont}>Conversation</span>
                    <ChatExchanges
                      exchanges={uiExchageRenderData}
                      showExchangeOptions={false}
                      activeCurrentViewExchange={currentViewExchange}
                      endToEndMode
                      onExchangeClick={setActiveViewExchange}
                      disableAutoScroll
                    />
                  </Space>
                </Col>
                <Col>
                  <Divider type='vertical' style={{ height: '100%' }} />
                </Col>
                <Col span={8}>
                  <Space direction='vertical'>
                    <span className={style.headerColumnFont}>Apply Test Case</span>
                    <ExchangeTestCase
                      addExchangeTestCase={addExchangeTestCase}
                      updateExchangeTestCase={updateExchangeTestCaseAndRename}
                      activeCurrentViewExchange={currentViewExchange}
                      exchange={uiExchageRenderData[hashUiExchageRenderData[currentViewExchange]]}
                      exchangesHashPairedWithTestCases={exchangesHashPairedWithTestCases}
                      loading={isLoadingE2E}
                    />
                  </Space>
                </Col>
              </Row>
            </Space>
          )
            : <Empty description='No test case selected' />}
        </Tabs.TabPane>
      </Tabs>
    </Col>
  )
}

TestCaseManagment.propTypes = {
  loadToEndToEnd: PropTypes.func.isRequired,
  e2eState: e2eStateType.isRequired,
  updateExchangeTestCase: PropTypes.func.isRequired,
  addExchangeTestCase: PropTypes.func.isRequired,
  parentTab: PropTypes.string.isRequired,
  shouldUpdate: PropTypes.shape({
    testCases: PropTypes.bool,
    collectionChoices: PropTypes.bool,
    testCollections: PropTypes.bool,
  }).isRequired,
  shouldUpdateDispatcher: PropTypes.func.isRequired,
}

export default TestCaseManagment
