import React from 'react'
import { Row, Col, Typography } from 'antd'
import ModelsTable from './ModelsTable'

const { Title } = Typography

const ModelsTab = () => {
  return (
    <Row gutter={[24, 16]}>
      <Col span={12}>
        <Title level={4}>Domain Models</Title>
        <ModelsTable type='domain' />
      </Col>
      <Col span={12}>
        <Title level={4}>Intent Models</Title>
        <ModelsTable type='intent' />
      </Col>
    </Row>
  )
}

export default ModelsTab
