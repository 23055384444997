import React from 'react'
import { Modal, Button, Typography } from 'antd'
import {
  onOkType,
  onCancelType,
  titleType,
  loadingType,
  visibleType,
  childrenType,
  closableType,
  hasErrorType,
} from '../../types/formModal'

const { Text } = Typography

const FormSubmissionModal = ({
  onOk,
  onCancel,
  loading = false,
  visible = false,
  title,
  children,
  closable,
  hasError,
}) => {
  return (
    <Modal
      width='50%'
      visible={visible}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={false}
      closable={closable}
      footer={[
        <Button danger key='back' onClick={onCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={onOk}>
          Submit
        </Button>,
      ]}
    >
      {children}
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Modal>
  )
}

FormSubmissionModal.defaultProps = {
  closable: true,
  hasError: false,
  onOk: () => {},
}

FormSubmissionModal.propTypes = {
  onOk: onOkType,
  onCancel: onCancelType.isRequired,
  title: titleType.isRequired,
  loading: loadingType.isRequired,
  visible: visibleType.isRequired,
  children: childrenType.isRequired,
  closable: closableType,
  hasError: hasErrorType,
}

export default FormSubmissionModal
