import React from 'react'
import { Table, Space, Typography, Tooltip, Tag, Spin, Pagination } from 'antd'
import { InfoCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'

import { DB_CHANNEL_MAP } from '@core/constants/channels'
import dayjs from 'dayjs'
import { useUser } from '../../../hooks/useUser'
import InvestigateModal from './InvestigateModal'
import ProfileModal from './ProfileModal'
import ViewPTCAction from './ViewPTCAction'
import ViewConversationAction from './ViewConversationAction'
import ViewLogAction from './ViewLogAction'
import RawModal from './RawModal'
import './antdOverride.css'
import style from './index.module.scss'

function formatDate(date) {
  return dayjs(date).format('YYYY-MM-DD@HH:mm')
}

const UserListContent = () => {
  const {
    isLoading, dataSource, isInit, page, pageSize, onPageChange,
    isPaginationLoading, nextPage, isSortLoading, setSorter,
  } = useUser()
  const columns = [
    {
      title: (
        <Typography.Text strong>
          Email
          {' '}
          <Tooltip title='Email address or Channel User ID when email address is missing.'><InfoCircleOutlined /></Tooltip>
        </Typography.Text>
      ),
      dataIndex: 'email',
      width: 250,
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (_, { email, channelUserId }) => {
        const displayEmail = email ?? channelUserId
        let start = displayEmail
        let suffix = ''
        if (displayEmail.length > 40) {
          start = displayEmail.slice(0, 22)
          suffix = `...${displayEmail.slice(-18)}`
        }

        return (
          <Space size={10}>
            <Tooltip title={displayEmail}>
              <Typography.Text copyable={{ text: displayEmail }} ellipsis={{ suffix }}>
                {!email && '(!) '}
                {start}
              </Typography.Text>
            </Tooltip>
          </Space>
        )
      },
    },
    {
      title: (<Typography.Text strong>Channel</Typography.Text>),
      dataIndex: 'fromChannel',
      key: 'fromChannel',
      sorter: true,
      width: 100,
      render: (fromChannel) => {
        return DB_CHANNEL_MAP[fromChannel] ?? fromChannel
      },
    },
    {
      title: (<Typography.Text strong>Pending Steps</Typography.Text>),
      dataIndex: 'pendingSteps',
      width: 200,
      render: (_, { onAuthentication, onConversation, onCSAT, onPTC }) => {
        const pendingSteps = []
        if (onAuthentication?.status) {
          pendingSteps.push('Authenticate')
        }
        if (onConversation?.status) {
          pendingSteps.push('Conversation')
        }
        if (onCSAT?.status) {
          pendingSteps.push('CSAT')
        }
        if (onPTC?.status) {
          pendingSteps.push('PTC')
        }

        return <>{pendingSteps.map((step) => { return <Tag key={step}>{step}</Tag> })}</>
      },
    },
    {
      title: (<Typography.Text strong>Last Message Sent</Typography.Text>),
      dataIndex: 'lastMessageSentAt',
      key: 'lastMessageSentAt',
      sorter: true,
      width: 195,
      render: (lastMessageSentAt) => {
        if (!lastMessageSentAt) {
          return ''
        }
        const sentAt = new Date(lastMessageSentAt)
        const numberOfDay = (new Date() - sentAt) / (24 * 60 * 60 * 1000)
        return (
          <Typography.Text copyable={{ text: sentAt.toISOString() }}>
            [
            {numberOfDay < 1 ? '<1' : Math.floor(numberOfDay)}
            ]
            {' '}
            {formatDate(sentAt)}
          </Typography.Text>
        )
      },
    },
    {
      title: (<Typography.Text strong>Actions</Typography.Text>),
      width: 180,
      render: (_, user) => {
        return (
          <Space size='middle'>
            <InvestigateModal user={user} />
            <ProfileModal user={user} />
            <ViewPTCAction conversationId={user?.onConversation?.data?.conversationId} channel={user?.fromChannel} />
            <ViewConversationAction conversationId={user?.onConversation?.data?.conversationId} />
            <ViewLogAction channel={user?.fromChannel} />
            <RawModal user={user} />
          </Space>
        )
      },
    },
  ]

  if (isInit) {
    return (
      <div style={{ textAlign: 'center', marginTop: '200px' }}>
        <Typography.Text type='secondary'>
          Please use the filters in the left to query users.
        </Typography.Text>
      </div>
    )
  }

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      const isEnabled = page > 1
      return (

        <LeftOutlined className={`${style['pagination-next-button']} ${isEnabled ? style['pagination-next-button-enable'] : ''}`} />
      )
    }
    if (type === 'next') {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>
    }
    return originalElement
  }

  const totalPages = Math.ceil(dataSource.length / pageSize)
  const isNextEnabled = (page < totalPages) || nextPage
  const paginationButtonClassName = `${style['pagination-next-button']} ${!isPaginationLoading && isNextEnabled ? style['pagination-next-button-enable'] : ''}`

  return isLoading
    ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}><Spin /></div>
    : (
      <div style={{ position: 'relative' }}>
        <Table
          columns={columns}
          dataSource={dataSource.slice(Math.max(0, (page - 1) * pageSize), (page) * pageSize)}
          size='small'
          pagination={false}
          rowKey='userId'
          scroll={{
            scrollToFirstRowOnChange: true,
            y: 675,
          }}
          onChange={(_pagination, _filters, sorter) => {
            setSorter(sorter)
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', alignItems: 'end' }}>
          <Pagination className={nextPage && 'pagination-has-next-page'} size='small' total={dataSource.length} current={page} pageSize={pageSize} showSizeChanger={false} itemRender={itemRender} onChange={onPageChange} showTotal={(total) => { return `Total ${total}` }} />
          <button
            type='button'
            className={paginationButtonClassName}
            onClick={() => { return isNextEnabled && onPageChange(page + 1, { fastForward: true }) }}
          >
            <RightOutlined />
          </button>
          {isPaginationLoading && <Spin style={{ marginLeft: '12px' }} size='small' />}
        </div>

        {isSortLoading && (
          <div className={style['sort-loading-container']}>
            <Spin />
          </div>
        )}
      </div>
    )
}

export default UserListContent
