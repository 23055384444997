export const transformInputChannel = (channel) => {
  let transformChannel = channel
  if (transformChannel === 'GOOGLE_CHAT') {
    transformChannel = 'gchat'
  }
  if (transformChannel === 'MS_TEAMS') {
    transformChannel = 'msteams'
  }
  transformChannel = transformChannel.toLowerCase()
  return transformChannel
}
