import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Table, Typography, Result } from 'antd'
import { capitalizeWord } from '@core/helpers'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import classnames from 'classnames'
import Title from './Title'
import SentencesListUIActionCreators from '../../store/actions/SentencesListUI'
import SentencesActionCreators from '../../store/actions/sentences'
import BulkOperationDataActions from '../../store/actions/BulkOperationData'
import SentenceActions from './SentenceActions'

import style from './style.module.scss'
import Sentence from './Sentence'

const SentenceQueryResultsTable = () => {
  const [selectedHighlightedFilters, setSelectedHighlightedFilters] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const bulkModePermitted = usePermittedFeaturesLookup('utterances.data.actionToggleBulkMode')
  const currentlyOpenedSentenceId = useSelector(({ data }) => { return data.sentences.selectedSentence.id }, shallowEqual)
  const { isPending, hasError, current, meta, filter, sentences, noGeneralizedSentences } = useSelector(({ data }) => {
    return {
      isPending: data.sentences.isPending,
      hasError: data.sentences.hasError,
      current: data.searches.current,
      sentences: data.sentences.listed,
      meta: data.sentences.meta,
      filter: data.sentences.filter,
      noGeneralizedSentences: data.sentences.listed.filter((sentence) => {
        return !sentence.hasGeneralizedKeyword
      }),
    }
  }, shallowEqual)

  // No need for redux?
  const { bulkMode, sentenceMode, noGeneralizedMode } = useSelector(({ data }) => {
    return {
      bulkMode: data.sentencesListUI.bulkMode,
      sentenceMode: data.sentencesListUI.sentenceMode,
      noGeneralizedMode: data.sentencesListUI.noGeneralizedMode,
    }
  })

  // what the fuck
  const sentenceToShow = useMemo(() => {
    let processedSentences = sentences
    if (selectedHighlightedFilters.length) {
      selectedHighlightedFilters.forEach((selectedHighlightedFilter) => {
        processedSentences = processedSentences.filter(({ generalized: { generalized } }) => {
          return generalized.includes(selectedHighlightedFilter.text)
        })
      })
    }

    return processedSentences
  }, [sentences, selectedHighlightedFilters])

  const clearTableSelection = () => {
    setSelectedRowKeys([])
  }

  const filterSentenceByCurrentTag = ({ text, backgroundColor }) => {
    for (let i = 0; i < selectedHighlightedFilters.length; i++) {
      if (selectedHighlightedFilters[i].text.includes(text)) {
        return
      }
    }

    setSelectedHighlightedFilters((prev) => { return prev.concat([{ text, backgroundColor }]) })
  }

  const removeGeneralizedFilterTag = (selectedHighlightedFilter) => {
    setSelectedHighlightedFilters((prev) => {
      return prev.filter(
        (previousItem) => { return previousItem.text !== selectedHighlightedFilter.text },
      )
    })
  }

  const setSentenceViewMode = (mode) => {
    SentencesListUIActionCreators.setSentenceMode(mode)
  }

  const setNoGeneralizedMode = (checked) => {
    SentencesListUIActionCreators.setNoGeneralizedMode(checked)
  }

  const onPaginationChange = async (page, perPage) => {
    // clear current pagination selection
    setSelectedRowKeys([])

    // clear bulk reducer
    BulkOperationDataActions.dismiss()

    // actual fetch
    await SentencesActionCreators.fetch({
      ...filter,
      page,
      perPage,
    })
  }

  const toggleBulkMode = () => {
    // clear current pagination selection
    setSelectedRowKeys([])

    // clear bulk reducer
    BulkOperationDataActions.dismiss()

    // Remove UI
    SentencesListUIActionCreators.toggleBulkMode()
  }

  const getRowSelection = () => {
    if (!bulkMode || !bulkModePermitted) return undefined

    return {
      onChange: (newlySelectedRowKeys) => {
        setSelectedRowKeys(newlySelectedRowKeys)
        BulkOperationDataActions.select(newlySelectedRowKeys)
      },
      selectedRowKeys,
    }
  }

  useEffect(() => {
    setSelectedRowKeys([])
    setSelectedHighlightedFilters([])
    BulkOperationDataActions.dismiss()
  }, [isPending])

  if (hasError) {
    return (
      <Result
        status='error'
        title={'We\'ve encountered some query issues, please check your query'}
      />
    )
  }

  return (
    <>
      <Title
        isLoading={isPending}
        totalResults={meta.totalCount ?? 0}
        selectedGeneralizedFilters={selectedHighlightedFilters}
        setSentenceViewMode={setSentenceViewMode}
        toggleBulkMode={toggleBulkMode}
        removeGeneralizedFilterTag={removeGeneralizedFilterTag}
        showOnlySentenceWithNoGeneralizedTag={setNoGeneralizedMode}
        clearTableSelection={clearTableSelection}
      />
      <Table
        bordered
        size='small'
        rowKey='id'
        loading={isPending}
        className={style.SentenceQueryResultsTable}
        rowClassName={(record) => {
          if (currentlyOpenedSentenceId === record.id) return style.Selected
          return undefined
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          y: 760,
        }}
        columns={[
          {
            key: 'sentence',
            dataIndex: 'id',
            title: (<Typography.Text strong>Sentence</Typography.Text>),
            render: (id, sentence, index) => {
              return (
                <Sentence
                  bulkModePermitted={bulkModePermitted}
                  sentence={sentence}
                  searchingTerms={current.texts}
                  sentenceArrayindex={index}
                  onHighlightedFilterClick={filterSentenceByCurrentTag}
                  isBulkMode={bulkMode}
                  showGeneralized={sentenceMode === 'generalized'}
                  showStopwordsRemoved={sentenceMode === 'stopwords'}
                />
              )
            },
          },
          {
            key: 'status',
            dataIndex: 'training_status',
            title: (<Typography.Text strong>Status</Typography.Text>),
            render: (trainingStatus, sentence) => {
              return (
                <Typography.Text
                  className={classnames(style.TrainingStatus, style[sentence.training_status])}
                >
                  {trainingStatus ? capitalizeWord(trainingStatus) : 'N/A'}
                </Typography.Text>
              )
            },
            width: 96,
          },
          {
            key: 'actions',
            title: (<Typography.Text strong>Actions</Typography.Text>),
            render: (_, sentence, index) => {
              return (
                <SentenceActions
                  sentence={sentence}
                  sentenceArrayindex={index}
                />
              )
            },
            width: 168,
          },
        ]}
        rowSelection={getRowSelection()}
        dataSource={noGeneralizedMode ? noGeneralizedSentences : sentenceToShow}
        pagination={{
          defaultCurrent: meta.page,
          pageSize: meta.perPage,
          total: meta.totalCount,
          showTotal: () => { return `Showing ${sentenceToShow.length} of ${meta.totalCount.toLocaleString()} sentences` },
          onChange: onPaginationChange,
        }}
      />
    </>
  )
}

export default SentenceQueryResultsTable
