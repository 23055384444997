import { useSelector } from 'react-redux'

export const useInternationalizeVersionNewMinVersion = () => {
  return useSelector(({ core }) => {
    const { maxVersion, minNewVersionNumber } = core.internationalize

    return { maxVersion, minNewVersionNumber }
  })
}

export const useInternationalizeFetchStatus = () => {
  return useSelector(({ core }) => {
    const { loading, hasError } = core.internationalize

    return { loading, hasError }
  })
}

export const useInternationalizeVersionSelector = () => {
  return useSelector(({ core }) => {
    const { listed } = core.internationalize

    return listed
  })
}

export const useInternationalizeVersionDetail = (id) => {
  return useSelector(({ core }) => {
    if (!id) {
      return undefined
    }

    const { internationalize: { hashedIndex, listed } } = core
    const versionHashCursor = hashedIndex[id]

    return listed[versionHashCursor]
  })
}
