export const setNgramsMinMax = ({
  unigram,
  bigram,
  trigram,
}) => {
  const min = []
  const max = []

  if (unigram) {
    min.push(1)
    max.push(1)
  }
  if (bigram) {
    min.push(2)
    max.push(2)
  }
  if (trigram) {
    min.push(3)
    max.push(3)
  }

  return { min: Math.min.apply(null, min), max: Math.max.apply(null, max) }
}

export const sortThenStringifyNgramsData = ({ data, sortingOption }) => {
  if (!data) return data

  let sortedData
  switch (sortingOption) {
    case 'value-asc': {
      sortedData = Object.entries(data).sort(([/* ngram */, a], [/* ngram */, b]) => { return a - b })
      break
    }
    case 'value-desc': {
      sortedData = Object.entries(data).sort(([/* ngram */, a], [/* ngram */, b]) => { return b - a })
      break
    }
    default: {
      sortedData = data
    }
  }

  const endIndex = sortedData.length - 1
  let temp = '{\n'

  sortedData.forEach(([key, value], index) => {
    temp += `    "${key}"`
    temp += ':  '
    temp += value

    if (index !== endIndex) {
      temp += ','
    }

    temp += '\n'
  })

  temp += '}'

  return temp
}
