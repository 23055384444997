import sentences from './sentences'
import selectedSentence from './selectedSentence'
import searches from './searches'
import sentenceNotes from './sentenceNotes'
import dynamicEntities from './dynamicEntities'
import rightPanel from './rightPanel'
import editSentenceModal from './EditSentenceModal'
import bulkOperationData from './BulkOperationData'
import bulkOperationModal from './BulkOperationModal'
import sentencesListUI from './SentencesListUI'
import sentencesListCheck from './SentencesListCheck'
import drawer from './Drawer'

export default {
  sentences,
  selectedSentence,
  searches,
  sentenceNotes,
  dynamicEntities,
  rightPanel,
  editSentenceModal,
  bulkOperationData,
  bulkOperationModal,
  sentencesListUI,
  sentencesListCheck,
  drawer,
}
