import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Layout,
  Typography,
  Input,
  Button,
  Row,
  Col,
  PageHeader,
  Form,
  notification,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../../Faqs/api'

const { Content } = Layout
const { Title, Text } = Typography

const EditVersion = () => {
  const { version_id } = useParams()
  const history = useHistory()

  const [{ loading, data: version, hasError }] = useRequest(Api.getVersion, version_id)

  const [form] = Form.useForm()
  const [hasUpdateError, setHasUpdateError] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { return form.resetFields() }, [version])

  const goBack = () => { return history.replace('/versions/faqs') }

  const onFinish = async (values) => {
    try {
      const response = await Api.updateVersion(version_id, values)

      if (response) {
        form.resetFields()
        notification.success({
          message: `Faq Version #${version_id} successfully updated`,
          duration: 10,
        })

        goBack()
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasUpdateError(true)
    }
  }

  if (loading || !version) return null

  return (
    <Content className='EditVersion'>
      <PageHeader onBack={goBack} subTitle='Back to i18n Versions List'>
        <Title>{`Edit FAQ Version #${version_id}`}</Title>
      </PageHeader>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          note: version && version.note ? version.note : undefined,
        }}
      >
        <Row>
          <Col span='24'>
            <Form.Item
              name='note'
              label='Version note'
              rules={[
                {
                  required: true,
                  message: 'Please add version note',
                },
              ]}
            >
              <Input placeholder='Enter version note' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Button type='primary' htmlType='submit'>Submit</Button>
              {(hasError || hasUpdateError) && (<Text type='danger'>&thinsp;&thinsp;&thinsp;We&#39;ve encountered some problems, please try again in few minutes</Text>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  )
}

export default EditVersion
