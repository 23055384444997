import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Button,
  Divider,
  Form,
  Select,
  Radio,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import style from './index.module.scss'
import Api from '../../../../api'
import { EmailAutomationsContext } from '../../../../context'

const { Sider } = Layout

const LeftSider = ({ onSubmit }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const trainingModes = [
    { value: 'new', text: 'New' },
    { value: 'retrain', text: 'Retrain' },
  ]

  const [{ loading: modelLoading, data: models }] = useRequest(Api.Models.getModels, { version: currentVersion, datasource })
  const [{ loading: intentLoading, data: intentList }] = useRequest(Api.Intents.getIntents, { version: currentVersion, datasource })

  const onFinish = (values) => {
    onSubmit(values)
  }

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={480}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Training</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            style={{ marginBottom: 10 }}
            name='trainingMode'
            initialValue='new'
          >

            <Radio.Group size='small'>
              {trainingModes.map(({ value, text }) => { return <Radio.Button key={value} value={value}>{text}</Radio.Button> })}
            </Radio.Group>
          </Form.Item>

          <Form.Item shouldUpdate={(prevValues, curValues) => { return prevValues.trainingMode !== curValues.trainingMode }}>
            {({ getFieldValue }) => {
              const trainingMode = getFieldValue('trainingMode')
              if (trainingMode === 'new') return null
              return (
                <>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label='Model'
                    name='modelId'
                    rules={[{ required: true, message: 'Please select your model!' }]}
                  >
                    <Select
                      disabled={modelLoading}
                      placeholder='Please select model'
                      options={models?.map(({ id }) => {
                        return { value: id, label: id }
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label='Intents'
                    name='intentNames'
                    rules={[{ required: true, message: 'Please select your intents!' }]}
                  >
                    <Select
                      disabled={intentLoading}
                      mode='multiple'
                      placeholder='Please select intents'
                      allowClear
                      options={intentList?.intents?.filter(({ name }) => { return !name?.includes('not-') }).map(({ id, name }) => {
                        return { value: name, label: name || id }
                      })}
                    />
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>
          <Button type='primary' htmlType='submit' block>
            Train (Run)
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LeftSider
