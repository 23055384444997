import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Typography, Result, Button, Row, Col, Divider, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import useRequest from '@core/hooks/useRequest'

import Api from '../../../../api'

import { EmailAutomationsContext } from '../../../../context'
import { ViewEmailModalContext } from '../context'

import ClientInformation from './ClientInformation'
import FeedbackAndCorrectness from './FeedbackAndCorrectness'
import EmailContentPreview from './EmailContentPreview'
import EmailAnalysisResponseLog from './EmailAnalysisResponseLog'
import LinguisticAccuracyCheck from './LinguisticAccuracyCheck'

import style from './style.module.scss'

const AnalysisAccuracy = ({ selectedEmail }) => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const { emailId: id, capId, countryOfEmployment } = useContext(ViewEmailModalContext)
  const dataSourceQueryParams = { version: currentVersion, datasource }

  const [{ loading, hasError, data }, { makeRequest }] = useRequest(Api.EmailAnalysisAccuracyCheck.get, { id, ...dataSourceQueryParams })

  if (hasError) {
    return (
      <div className={style.AnalysisAccuracy}>
        <Result
          status='warning'
          title='There are errors when retrieving accuracy information.'
          extra={(
            <Button type='primary' key='console' onClick={makeRequest}>
              Try again
            </Button>
          )}
        />
      </div>
    )
  }

  if (loading) return (<Result icon={<LoadingOutlined />} />)
  if (!data) return (<Result title='There are no responses' />) // No response saved yet

  return (
    <div className={style.AnalysisAccuracy}>
      <Row className={style.EmailPreviewAndAccuracy}>
        <Col span={16} className={style.OuterEmailPreviewContainer}>
          <EmailContentPreview selectedEmail={selectedEmail} />
        </Col>
        <Col span={8} className={style.ResponseLogAndAccuracyCheck}>
          <Row>
            <Col flex='auto'>
              <ClientInformation
                capId={capId}
                countryOfEmployment={countryOfEmployment}
              />
            </Col>
            <Col flex={1}>
              <FeedbackAndCorrectness
                tcFeedback={data?.tc_feedback}
                tcAccuracy={data?.tc_accuracy}
                correctness={{ e2e: data?.e2e_correctness }}
              />
            </Col>
          </Row>
          <EmailAnalysisResponseLog analysisResponse={data?.analysis_response || undefined} />
          <Divider />
          {!data?.analysis_response
            ? (<Result status='warning' title='There are no analysis responses from TC' />)
            : (
              <>
                <LinguisticAccuracyCheck
                  initialValues={{
                    email_content: data?.email_content ?? 'NORMAL',
                    email_style: data?.email_style ?? null,
                    arranger_email: data?.arranger_email ?? null,
                    attachment: data?.attachment ?? null,
                    user_error: data?.user_error ?? null,
                    preprocessing_correctness: data?.preprocessing_correctness ?? null,
                    intent_correctness: data?.intent_correctness ?? null,
                    specific_intent_correctness: data?.specific_intent_correctness ?? null,
                    details_correctness: data?.details_correctness ?? null,
                  }}
                  makeRequest={makeRequest}
                />
                <div className={style.LastEdited}>
                  <Tooltip title={`Raw: ${data?.updated_at}, Localized time: ${dayjs(data?.updated_at).format('MMM DD, YYYY HH:mm:ssZ')}`}>
                    <Typography.Text type='secondary' italic>
                      {`Last edited: ${dayjs.utc(data?.updated_at).format('MMM DD, YYYY HH:mm:ssZ')} by ${data?.updated_by ?? '?'}`}
                    </Typography.Text>
                  </Tooltip>
                </div>
              </>
            )}
        </Col>
      </Row>
    </div>
  )
}

AnalysisAccuracy.propTypes = {
  selectedEmail: PropTypes.shape({
    id: PropTypes.string,
    from_address: PropTypes.string,
    to_address: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    cleaned_email_subject: PropTypes.string,
    cleaned_email_body: PropTypes.string,
    source: PropTypes.string,
    language: PropTypes.string,
    email_sent_at: PropTypes.string,
    record_created_at: PropTypes.string,
    status: PropTypes.string,
    datasetType: PropTypes.string,
    feedback: PropTypes.string,
    intentSamples: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  }).isRequired,
}

export default AnalysisAccuracy
