import React from 'react'
import { useSelector } from 'react-redux'
import { Form, Select, Space } from 'antd'
import style from './index.module.scss'
import { setFilterTypeType, setFilterStatusType } from '../../../types/notesFilter'

const { Option } = Select
const { Item } = Form

const NotesFilter = ({ setFilterType, setFilterStatus }) => {
  const { noteTypes, noteStatus } = useSelector(({ data }) => { return { ...data.sentenceNotes } })
  const NoteTypeChildren = noteTypes.map((type) => { return <Option key={type}>{type}</Option> })
  const noteStatusChildren = noteStatus.map((status) => { return <Option key={status}>{status}</Option> })
  return (
    <Space direction='vertical' className={style.notes_filters__select}>
      <Item>
        <Select
          mode='tags'
          showSearch
          placeholder='Specify the notes types'
          defaultValue={[]}
          onChange={(values) => { return setFilterType(values) }}
        >
          {NoteTypeChildren}
        </Select>
      </Item>
      <Item>
        <Select
          mode='tags'
          showSearch
          placeholder='Specify the notes status'
          defaultValue={[]}
          onChange={(values) => { return setFilterStatus(values) }}
        >
          {noteStatusChildren}
        </Select>
      </Item>
    </Space>
  )
}

NotesFilter.propTypes = {
  setFilterType: setFilterTypeType.isRequired,
  setFilterStatus: setFilterStatusType.isRequired,
}

export default NotesFilter
