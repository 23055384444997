import { wrapActionCreatorWithDispatch } from './dispatchInjection'
import { CLUSTER } from '../constants'
import Api from '../../api'

const wrapped = wrapActionCreatorWithDispatch({
  getWorkerStats: () => {
    return async (dispatch) => {
      dispatch({ type: CLUSTER.GET_WORKER_HOST_STATS.REQUEST })

      try {
        const response = await Api.Cluster.getWorkerStats()

        if (response && response.status === 'success') {
          dispatch({
            type: CLUSTER.GET_WORKER_HOST_STATS.SUCCESS,
            payload: response.data,
          })

          return response
        }

        dispatch({ type: CLUSTER.GET_WORKER_HOST_STATS.FAILED })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: CLUSTER.GET_WORKER_HOST_STATS.FAILED })

        throw error
      }
    }
  },

  scaleUpWorker: () => {
    return async (dispatch) => {
      dispatch({ type: CLUSTER.SCALE_UP_WORKER.REQUEST })

      try {
        const response = await Api.Cluster.scaleUpWorker()

        if (response && response.status === 'success') {
          dispatch({
            type: CLUSTER.SCALE_UP_WORKER.SUCCESS,
            payload: response.data,
          })

          wrapped.getWorkerStats()

          return response
        }

        dispatch({ type: CLUSTER.SCALE_UP_WORKER.FAILED })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: CLUSTER.SCALE_UP_WORKER.FAILED })

        throw error
      }
    }
  },

  scaleDownWorker: () => {
    return async (dispatch) => {
      dispatch({ type: CLUSTER.SCALE_DOWN_WORKER.REQUEST })

      try {
        const response = await Api.Cluster.scaleDownWorker()

        if (response && response.status === 'success') {
          dispatch({
            type: CLUSTER.SCALE_DOWN_WORKER.SUCCESS,
            payload: response.data,
          })

          wrapped.getWorkerStats()

          return response
        }

        dispatch({ type: CLUSTER.SCALE_DOWN_WORKER.FAILED })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: CLUSTER.SCALE_DOWN_WORKER.FAILED })

        throw error
      }
    }
  },
})

export default wrapped
