import React from 'react'
import { filterCategoryType, hasFilterValueType } from '../../../types/filterCategoryDivider'
import style from './style.module.scss'

const FilterCategoryDivider = ({ filterCategory, hasFilterValue }) => {
  return (
    <div className={style.FilterCategoryDivider}>
      <span style={hasFilterValue ? { color: '#1890ff' } : undefined}><strong>{filterCategory}</strong></span>
    </div>
  )
}

FilterCategoryDivider.defaultProps = {
  hasFilterValue: false,
}

FilterCategoryDivider.propTypes = {
  filterCategory: filterCategoryType.isRequired,
  hasFilterValue: hasFilterValueType,
}

export default FilterCategoryDivider
