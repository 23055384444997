import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  Space,
  Divider,
} from 'antd'

const ExpandableErrorMessage = ({ error }) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpand = () => {
    setExpanded((prev) => { return !prev })
  }

  return (
    <>
      <Space size='middle' align='start'>
        <Typography.Text>We&apos;ve encountered some problems, please try again in few minutes</Typography.Text>
        <Button size='small' danger onClick={toggleExpand}>
          Detail
        </Button>
      </Space>
      {expanded && <Divider />}
      {expanded && <pre>{JSON.stringify(error, null, 2)}</pre>}
    </>
  )
}

ExpandableErrorMessage.defaultProps = {
  error: {},
}

ExpandableErrorMessage.propTypes = {
  error: PropTypes.shape({}),
}

export default ExpandableErrorMessage
