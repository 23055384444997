import React from 'react'
import { Tabs } from 'antd'
import DisplayTaggerTrainingsTab from './DisplayTaggerTrainingsTab'
import AnalysisTab from './AnalysisTab'
import TrainingTab from './TrainingTab'

const { TabPane } = Tabs

const TaggersTab = () => {
  return (
    <Tabs defaultActiveKey='1' type='card'>
      <TabPane key='1' tab='Display'>
        <DisplayTaggerTrainingsTab />
      </TabPane>
      <TabPane key='2' tab='Analysis'>
        <AnalysisTab />
      </TabPane>
      <TabPane key='3' tab='Training'>
        <TrainingTab />
      </TabPane>
    </Tabs>
  )
}

export default TaggersTab
