import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DomainIntentCollection from './pages/DomainIntentCollection'

const DomainIntentModule = () => {
  return (
    <Switch>
      <Route path='/hierarchies' component={DomainIntentCollection} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/hierarchies',
    component: DomainIntentModule,
  },
  name: 'Domain/Intent',
}

export default DomainIntentModule
