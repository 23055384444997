import { PERMISSIONS } from '../constants'

const INITIAL_STATE = {
  isPending: true, // NOTE: Forced true to make app landing to the spinning state first
  hasError: false,
  authorization: {
    raw: {},
    transformed: {},
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PERMISSIONS.FETCH.FAILED: {
      return {
        isPending: false,
        hasError: true,
        authorization: {},
      }
    }
    case PERMISSIONS.FETCH.REQUEST: {
      return {
        ...INITIAL_STATE,
      }
    }
    case PERMISSIONS.FETCH.SUCCESS: {
      return {
        isPending: false,
        hasError: false,
        authorization: {
          ...action.payload,
        },
      }
    }
    default: return state
  }
}
