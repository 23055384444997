import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, List, Space, Tag } from 'antd'

const TaggerVectorDisplay = ({ vector, visible, onClose }) => {
  return (
    <Drawer title='Vector' placement='left' onClose={onClose} visible={visible} width='auto'>
      <List
        size='small'
        bordered
        dataSource={Object.keys(vector)}
        renderItem={(key) => {
          return (
            <List.Item>
              <Space>
                <Tag>{key}</Tag>
                <Tag color={vector[key] ? 'green' : 'red'}>{vector[key].toString()}</Tag>
              </Space>
            </List.Item>
          )
        }}
      />
    </Drawer>
  )
}

TaggerVectorDisplay.propTypes = {
  vector: PropTypes.shape().isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TaggerVectorDisplay
