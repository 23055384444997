import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  Popconfirm,
  Space,
  Table,
} from 'antd'

import style from './index.module.scss'

const SingleSavedSimulationItem = (simulation) => {
  const {
    id,
    note,
    created_at,
    updated_at,
    created_by,
  } = simulation

  return (
    <dl>
      <dt><Typography.Text strong>ID</Typography.Text></dt>
      <dd>{id}</dd>
      <dt><Typography.Text strong>Note</Typography.Text></dt>
      <dd>{note}</dd>
      <dt><Typography.Text strong>Created at</Typography.Text></dt>
      <dd>{created_at}</dd>
      <dt><Typography.Text strong>Updated at</Typography.Text></dt>
      <dd>{updated_at || 'N/A'}</dd>
      <dt><Typography.Text strong>Created by</Typography.Text></dt>
      <dd>{created_by}</dd>
    </dl>
  )
}

SingleSavedSimulationItem.propTypes = {
  simulation: PropTypes.shape({
    id: PropTypes.string,
    note: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    created_by: PropTypes.string,
  }).isRequired,
}

const SavedSimulations = ({
  savedSimulatorLoading,
  conversationSimulations,
  loadToSimulator,
  loadToEndToEnd,
}) => {
  return (
    <div className={style.savedSimulations}>
      <Typography.Title level={4}>Saved Simulation</Typography.Title>
      <div className={style.savedSimulations__list}>
        <Table
          loading={savedSimulatorLoading}
          columns={[
            { title: 'Note', dataIndex: 'note', key: 'note' },
            {
              title: 'Share',
              dataIndex: '',
              key: 'share',
              render: (savedSimulation) => {
                return (
                  <Typography.Paragraph
                    className={style.copyable_link}
                    copyable={{
                      text: `${window.location.origin}/conversations/simulation?id=${savedSimulation.id}#tab__simulator`,
                    }}
                  >
                    Link
                  </Typography.Paragraph>
                )
              },
            },
            {
              title: 'Action',
              dataIndex: '',
              key: 'action',
              fixed: 'right',
              width: 320,
              render: (savedSimulation) => {
                const { note, id } = savedSimulation
                return (
                  <Space>
                    <Popconfirm
                      title={`Confirm load simulation "${note}"?`}
                      placement='topRight'
                      onConfirm={loadToSimulator(id)}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button type='primary' size='small'>
                        Load to Simulator
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title={`Confirm load simulation "${note}" for End to End test?`}
                      placement='topRight'
                      onConfirm={loadToEndToEnd(id)}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button className={style.loadToEndToEnd} type='primary' size='small'>
                        Load to End to End
                      </Button>
                    </Popconfirm>
                  </Space>
                )
              },
            },
          ]}
          expandable={{ expandedRowRender: SingleSavedSimulationItem }}
          dataSource={conversationSimulations.map((saved) => { return { ...saved, key: saved.id } })}
        />
      </div>
    </div>
  )
}

SavedSimulations.propTypes = {
  savedSimulatorLoading: PropTypes.bool.isRequired,
  conversationSimulations: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadToSimulator: PropTypes.func.isRequired,
  loadToEndToEnd: PropTypes.func.isRequired,
}

export default SavedSimulations
