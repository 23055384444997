import React from 'react'
import {
  Modal,
  List,
  Row,
  Col,
  Typography,
} from 'antd'
import PropTypes from 'prop-types'

const { Title } = Typography
const ClassificationModal = ({
  visible,
  onCancel,
  trueClass,
  predictedClass,
  items,
}) => {
  return (
    <Modal
      title={<Title level={4}>{`True: ${trueClass}, Predicted: ${predictedClass} (${items.length} sentences)`}</Title>}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <List
            size='small'
            bordered
            dataSource={items}
            renderItem={(item) => {
              return (
                <List.Item>
                  <List.Item.Meta
                    title={item.text}
                    description={item.generalized}
                  />
                </List.Item>
              )
            }}
          />
        </Col>
      </Row>
    </Modal>
  )
}

ClassificationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  trueClass: PropTypes.string.isRequired,
  predictedClass: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      generalized: PropTypes.string.isRequired,
      trueClass: PropTypes.string.isRequired,
      predictedClass: PropTypes.string,
      confidence: PropTypes.number,
    }),
  ).isRequired,
}

export default ClassificationModal
