import React, { useState } from 'react'
import { Form, Spin, Divider } from 'antd'

import useSymbolizer from '@core/hooks/useSymbolizer'
import TaggerSelect from '@core/components/TaggerFormItems/TaggerSelect'
import TaggerDesiredResult from '../../TaggerFormItems/TaggerDesiredResult'
import TaggerPhraseToMatch from '../../TaggerFormItems/TaggerPhraseToMatch'
import TaggerProcessedSentenceDisplay from '../../TaggerFormItems/TaggerProcessedSentenceDisplay'

import { formInstanceType } from '../../../types/form'
import {
  sentenceType,
  taggerType as taggerPropType,
  phraseIndex,
  phrase as phraseType,
  desiredResult,
} from '../../../types/updateCaseModal'

const TaggerForm = ({
  form,
  sentence,
  tagger_type,
  phrase_index,
  phrase,
  desired_result,
}) => {
  const [{ loading }, analysis] = useSymbolizer(sentence)
  const { desymbolizedSplitted } = analysis
  const [selectedPhrase, setSelectedPhrase] = useState({ value: phrase, phraseIndex: phrase_index })
  const [selectedTagger, setSelectedTagger] = useState(tagger_type)
  const onSelectPhrase = (item) => {
    const fields = form.getFieldsValue()
    setSelectedPhrase(item)
    form.setFieldsValue({ ...fields, phrase: JSON.stringify(item) })
  }
  return (
    <Form
      form={form}
      layout='vertical'
      name='form__training_tagger'
      initialValues={{
        tagger_type,
        phrase: `[${phrase_index}]: ${phrase}`,
        desired_result,
      }}
      onValuesChange={(e) => {
        if (e.phrase) setSelectedPhrase(JSON.parse(e.phrase))
        if (e.tagger_type) setSelectedTagger(e.tagger_type)
      }}
    >
      {loading && <Spin />}
      {!loading && desymbolizedSplitted && (
        <>
          <TaggerProcessedSentenceDisplay
            sentence={sentence}
            desymbolizedSplitted={desymbolizedSplitted}
            selectedPhrase={selectedPhrase}
            onSelectPhrase={onSelectPhrase}
          />
          <Divider />
          <TaggerPhraseToMatch rules={[{ required: true, message: 'Select the [index] Phrase/Word' }]} items={desymbolizedSplitted} />
          <TaggerSelect rules={[{ required: true, message: 'Please selected Target tagger type' }]} />
          <TaggerDesiredResult form={form} selectedTagger={selectedTagger} rules={[{ required: true, message: 'Please enter desired result' }]} />
        </>
      )}
    </Form>
  )
}

TaggerForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
  tagger_type: taggerPropType.isRequired,
  phrase_index: phraseIndex.isRequired,
  phrase: phraseType.isRequired,
  desired_result: desiredResult.isRequired,
}

export default TaggerForm
