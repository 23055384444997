module.exports = (selectedIntent, words) => {
  return {
    title: {
      text: `Word Cloud: ${selectedIntent?.name || ''}`,
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b} ({c})',
    },
    series: [{
      type: 'wordCloud',
      shape: 'spiral',
      left: 'center',
      top: 'center',
      width: '70%',
      height: '70%',
      right: null,
      bottom: null,
      sizeRange: [8, 60],
      rotationRange: [0, 0],
      rotationStep: 0,
      gridSize: 4,
      drawOutOfBound: false,
      layoutAnimation: true,
      textStyle: {
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        color() {
          return `rgb(${[
            Math.round(Math.random() * 160),
            Math.round(Math.random() * 160),
            Math.round(Math.random() * 160),
          ].join(',')})`
        },
      },
      emphasis: {
        focus: 'self',
        textStyle: {
          shadowBlur: 10,
          shadowColor: '#333',
        },
      },
      data: words,
    }],
  }
}
