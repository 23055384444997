// Temporary use while maintaining multiple AWS accounts

export const getBaseUrlProduction = (fallbackValue) => {
  return process.env.REACT_APP_IS_GBT_CLOUD
    ? process.env.REACT_APP_BASE_URL_PRODUCTION
    : fallbackValue
}

export const getBaseUrlStaging = (fallbackValue) => {
  return process.env.REACT_APP_IS_GBT_CLOUD
    ? process.env.REACT_APP_BASE_URL_QA
    : fallbackValue
}

export const getBaseUrlCanary = (fallbackValue) => {
  return process.env.REACT_APP_IS_GBT_CLOUD
    ? process.env.REACT_APP_BASE_URL_DEV
    : fallbackValue
}
