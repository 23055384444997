import React, { useContext, useState } from 'react'
import { Layout, Spin, Alert } from 'antd'
import { EmailAutomationsContext } from '../../../context'
import Api from '../../../api'
import LeftSider from './LeftSider'

const { Content } = Layout

const Training = () => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [loading, setLoading] = useState(false)
  const [flashMessage, setFlashMessage] = useState('')

  const onSubmit = async () => {
    if (loading) return
    setFlashMessage('')
    setLoading(true)
    try {
      const { data } = await Api.DataExtractionModels.train({ version: currentVersion, datasource })
      setFlashMessage(`The progress of training can be seen in the SageMaker pipeline named "${data.pipeline_name}" on SageMaker Studio.`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <LeftSider onSubmit={onSubmit} />
      <Content>
        {loading && <Spin />}
        {flashMessage && <Alert message={flashMessage} type='success' />}
      </Content>
    </div>
  )
}

export default Training
