import React from 'react'
import { Input } from 'antd'
import { onChangeType, placeholderType } from '../../types/formInput'
import { searchToArrayByComma } from '../../helpers/index'

const GetTermsInput = ({ getTerms, placeholder }) => {
  const processTerms = (event) => {
    getTerms(searchToArrayByComma(event.target.value))
  }

  return <Input placeholder={placeholder} onChange={processTerms} />
}

GetTermsInput.propTypes = {
  getTerms: onChangeType.isRequired,
  placeholder: placeholderType,
}

GetTermsInput.defaultProps = {
  placeholder: '',
}

export default GetTermsInput
