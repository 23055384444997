import React from 'react'
import { Typography } from 'antd'
import { versionType } from '../../types/versionLabel'
import style from './index.module.scss'

const VersionLabel = ({ version }) => {
  return (
    <div className={style.Badge}>
      <div className={style.Badge_boundary}>
        <Typography.Text title={`Version ID: ${version?.id}, Note: ${version?.note}`} strong>
          {`Version: ${version?.note} / i18n Version: ${version?.i18n_version}`}
        </Typography.Text>
        {process.env.REACT_APP_GIT_HEAD ? (
          <>
            <Typography.Text strong>&thinsp;;&thinsp;</Typography.Text>
            <Typography.Text title={`Full git-sha: ${process.env.REACT_APP_GIT_HEAD}`} strong>
              {`Dashboard version: ${process.env.REACT_APP_GIT_HEAD.slice(0, 7)}`}
            </Typography.Text>
          </>

        ) : null}
      </div>
    </div>
  )
}

VersionLabel.defaultProps = {
  version: 'DATASET_VERSION',
}

VersionLabel.propTypes = {
  version: versionType,
}

export default VersionLabel
