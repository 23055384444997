import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Select } from 'antd'
import { sortForIntentSelectOptions } from '../../../helpers'
import useSelectedIntentIds from './useSelectedIntentIds'
import useMouseEnterOrLeaveIntentSelectBox from './useMouseEnterOrLeaveIntentSelectBox'
import style from './style.module.scss'

// value and onChange are convention for antd custom form element
// https://ant.design/components/form#components-form-demo-customized-form-controls
// value is intentionally omitted, will just use the assignedIntents
// the value is dynamic anyway
// but will keep the onChange in order to update internal antd form state
const CoordinatedIntentSelectBoxes = ({ intentPairs, onChange: onChangeToAntd, assignedIntents, value }) => {
  // if there is value, meaning it is from view
  // each of item (both +/-) will be { label: <String>, value: <Number>}
  // UI related
  // this used for handled show state to prevent moving between input getting blurred when moved out of component
  const [dropdownShow, setDropdownShow] = useState(false)
  const [
    mouseEnter,
    {
      mouseEnterPositiveIntent,
      mouseLeavePositiveIntent,
      mouseEnterNegativeIntent,
      mouseLeaveNegativeIntent,
    },
  ] = useMouseEnterOrLeaveIntentSelectBox()
  const [
    selectedIntentIds,
    {
      selectPositive,
      deselectPositive,
      clearPositive,
      selectNegative,
      deselectNegative,
      clearNegative,
      reinitializeWithProps,
      updateFromProps,
    },
  ] = useSelectedIntentIds(assignedIntents)
  const positiveSelectRef = useRef(null)
  const negativeSelectRef = useRef(null)

  // selection event
  const onPositiveSelect = (_, option) => {
    selectPositive(option)
  }

  const onPositiveDeSelect = (deselectedIntentId) => {
    deselectPositive(deselectedIntentId)
  }

  const onPositiveClear = () => {
    clearPositive()
  }

  const onPositiveChange = (_, options) => {
    const positiveIntentSelected = options
    const negativeIntentSelected = selectedIntentIds.negative.map((intent) => { return { label: intent.label, value: intent.value } })
    onChangeToAntd([].concat(positiveIntentSelected, negativeIntentSelected))
  }

  const onNegativeSelect = (_, option) => {
    selectNegative(option)
  }

  const onNegativeDeSelect = (deselectedIntentId) => {
    deselectNegative(deselectedIntentId)
  }

  const onNegativeClear = () => {
    clearNegative()
  }

  const onNegativeChange = (_, options) => {
    const positiveIntentSelected = selectedIntentIds.positive.map((intent) => { return { label: intent.label, value: intent.value } })
    const negativeIntentSelected = options
    onChangeToAntd([].concat(positiveIntentSelected, negativeIntentSelected))
  }

  // UI state events
  const onFocus = () => {
    setDropdownShow(true)
  }

  const onBlur = () => {
    if (!mouseEnter.positive && !mouseEnter.negative) setDropdownShow(false)
  }

  useEffect(() => {
    reinitializeWithProps(assignedIntents)
  }, [reinitializeWithProps, assignedIntents])

  useEffect(() => {
    if (Array.isArray(value)) updateFromProps(value)
  }, [updateFromProps, value])

  return (
    <Row gutter={[8, 0]} className={style.CoordinatedIntentSelectBoxes}>
      <Col span={12}>
        <Select
          allowClear
          mode='multiple'
          open={dropdownShow}
          optionFilterProp='label'
          onFocus={onFocus}
          onBlur={onBlur}
          // specific interactions
          onChange={onPositiveChange}
          onSelect={onPositiveSelect}
          onDeselect={onPositiveDeSelect}
          onClear={onPositiveClear}
          // specific options
          options={Object.values(intentPairs).map((intent) => {
            const shouldDisableOption = () => {
              const isOppositeIntentDisabled = selectedIntentIds.negative
              .map((selectedIntent) => { return selectedIntent.value })
              .indexOf(intent.negative.id) !== -1

              if (assignedIntents) {
                // this falls to one email view case
                return isOppositeIntentDisabled
              || assignedIntents?.map((assignedIntent) => { return assignedIntent.value }).indexOf(intent.positive.id) !== -1
              }

              return isOppositeIntentDisabled
            }

            return ({
              title: intent.positive.name,
              label: intent.positive.name,
              value: intent.positive.id,
              disabled: shouldDisableOption(),
            })
          }).sort(sortForIntentSelectOptions)}
          // specific ref and placeholder
          ref={positiveSelectRef}
          placeholder='Positive Intent(s)'
          // specific event per intent polarity
          onMouseEnter={mouseEnterPositiveIntent}
          onMouseLeave={mouseLeavePositiveIntent}
          onInputKeyDown={(event) => {
            const { key, shiftKey } = event
            if (key === 'Escape' || (shiftKey && key === 'Tab')) {
              setDropdownShow(false)
              positiveSelectRef.current.blur()
            }
          }}
        />
      </Col>
      <Col span={12}>
        <Select
          allowClear
          mode='multiple'
          open={dropdownShow}
          optionFilterProp='label'
          onFocus={onFocus}
          onBlur={onBlur}
          // specific interactions
          onChange={onNegativeChange}
          onSelect={onNegativeSelect}
          onDeselect={onNegativeDeSelect}
          onClear={onNegativeClear}
          // specific options
          options={Object.values(intentPairs).map((intent) => {
            const shouldDisableOption = () => {
              const isOppositeIntentDisabled = selectedIntentIds.positive
              .map((selectedIntent) => { return selectedIntent.value })
              .indexOf(intent.positive.id) !== -1

              if (assignedIntents) {
                // this falls to one email view case
                return isOppositeIntentDisabled
                || assignedIntents?.map((assignedIntent) => { return assignedIntent.value }).indexOf(intent.negative.id) !== -1
              }

              return isOppositeIntentDisabled
            }

            return ({
              title: intent.negative.name,
              label: intent.negative.name,
              value: intent.negative.id,
              disabled: shouldDisableOption(),
            })
          }).sort(sortForIntentSelectOptions)}
          // specific ref and placeholder
          ref={negativeSelectRef}
          placeholder='Negative Intent(s)'
          // specific event per intent polarity
          onMouseEnter={mouseEnterNegativeIntent}
          onMouseLeave={mouseLeaveNegativeIntent}
          onInputKeyDown={(event) => {
            const { key, shiftKey } = event
            if (key === 'Escape' || (!shiftKey && key === 'Tab')) {
              setDropdownShow(false)
              negativeSelectRef.current.blur()
            }
          }}
        />
      </Col>
    </Row>
  )
}

CoordinatedIntentSelectBoxes.defaultProps = {
  intentPairs: {},
  assignedIntents: [],
  onChange: undefined,
  value: [],
}

CoordinatedIntentSelectBoxes.propTypes = {
  assignedIntents: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string, value: PropTypes.number,
    }),
  ),
  intentPairs: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.shape({
        positive: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          training_count: PropTypes.number,
        }),
        negative: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          training_count: PropTypes.number,
        }),
      }),
    ),
  ),
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
}

export default CoordinatedIntentSelectBoxes
