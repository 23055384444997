import dayjs from 'dayjs'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Layout, Button, Divider, Form, Radio, Input, DatePicker, Checkbox, Popover } from 'antd'
import classnames from 'classnames'

import { LeftOutlined, RightOutlined, InfoOutlined } from '@ant-design/icons'

import style from './index.module.scss'

const { Sider } = Layout

// TODO: make channels as object with key as real value and value as display value and move to global file constant
const channels = ['GOOGLE_CHAT', 'MS_TEAMS', 'SMS', 'WHATSAPP', 'SLACK', 'ZOOM', 'GBT']
const statuses = ['ACTIVE', 'PENDING', 'CLOSED', 'CANCELED', 'TIMEOUT']
const datasources = ['production', 'staging', 'canary']

const toInitialValues = (initialFilterValues) => {
  const initialValues = {
    ...initialFilterValues,
  }

  if (initialFilterValues.dateRange) {
    initialValues.dateRange = [
      dayjs(initialFilterValues.dateRange[0]),
      dayjs(initialFilterValues.dateRange[1]),
    ]
  }

  return initialValues
}

const LeftSider = ({ initialFilterValues, onSearch }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()
  const onFinish = (values) => {
    const {
      conversationId,
      notificationId,
      status,
      email,
      channel,
      channelUserId,
      fromCreatedAt,
      toCreatedAt,
      includeDeleted,
      datasource,
    } = values

    const filter = {
      conversationId,
      notificationId,
      status,
      email,
      channel,
      channelUserId,
      fromCreatedAt,
      toCreatedAt,
      includeDeleted,
      datasource,
    }

    if (values.dateRange) {
      filter.fromCreatedAt = values.dateRange[0].format('YYYY-MM-DD')
      filter.toCreatedAt = values.dateRange[1].format('YYYY-MM-DD')
    }
    onSearch(filter)
  }

  const popOverContent = (
    <div>
      <p>ACTIVE - Notification succesfully sent to the user, waiting for response</p>
      <p>PENDING - Notification currently on hold, pending to be sent in the queue</p>
      <p>RESPONDED - Notification has been responded, PTC conversation not yet closed</p>
      <p>CLOSED - Notification and the PTC conversation was closed</p>
      <p>CANCELED - Notification and the PTC conversation was canceled and closed</p>
      <p>TIMEOUT - Notification was closed because of exceeded timer limits</p>
    </div>
  )

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Notification Query</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={toInitialValues(initialFilterValues)}
        >
          <Form.Item
            style={{ marginBottom: 10 }}
            name='email'
          >
            <Input placeholder='Lookup by email' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='channelUserId'
          >
            <Input placeholder='Lookup by channel user ID' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='conversationId'
          >
            <Input placeholder='Lookup by conversation ID' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='notificationId'
          >
            <Input placeholder='Lookup by notification ID' />
          </Form.Item>
          <Divider>
            <small><strong>By Channel</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='channel'
          >
            <Radio.Group size='small'>
              <Radio.Button value={undefined}>
                ANY
              </Radio.Button>
              {channels.map((channel, index) => {
                let value = channel.toLowerCase()
                if (channel === 'GOOGLE_CHAT') {
                  value = 'gchat'
                }
                if (channel === 'MS_TEAMS') {
                  value = 'msteams'
                }

                return (
                  <Radio.Button key={index} value={value}>
                    {channel}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Divider>
            <small><strong>By Status</strong></small>
          </Divider>
          <div className={style.left_sider__collapse_button_container}>
            <Popover placement='topLeft' content={popOverContent} title='Status Meaning'>
              <Button
                size='small'
                shape='circle'
                icon={<InfoOutlined />}
              />
            </Popover>
          </div>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='status'
          >
            <Radio.Group size='small'>
              <Radio.Button value={undefined}>
                ANY
              </Radio.Button>
              {statuses.map((status, index) => { return <Radio.Button key={index} value={status}>{status}</Radio.Button> })}
            </Radio.Group>
          </Form.Item>
          <Divider>
            <small><strong>By Source</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='datasource'
          >
            <Radio.Group size='small'>
              {datasources.map((datasource, index) => {
                return (
                  <Radio.Button key={index} value={datasource}>
                    {datasource.charAt(0).toUpperCase() + datasource.substring(1, datasource.length)}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Divider>
            <small><strong>By Date Range</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='dateRange'
          >
            <DatePicker.RangePicker format='DD/MM/YYYY' />
          </Form.Item>
          <Divider>
            <small><strong>Options</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='includeDeleted'
            valuePropName='checked'
          >
            <Checkbox>Include Deleted Notifications</Checkbox>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
          >
            Search
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSearch: PropTypes.func.isRequired,
  initialFilterValues: PropTypes.shape({
    datasource: PropTypes.string.isRequired,
  }).isRequired,
}

export default LeftSider
