import React from 'react'

const highlightChildren = (textToHighlight, highlightFields) => {
  let children = [textToHighlight]

  for (let i = 0; i < highlightFields.length; i++) {
    const field = highlightFields[i]

    children = children.map((child) => {
      if (typeof child === 'string') {
        return child.split(field).map((text, index, arr) => {
          return ([
            text,
            index !== (arr.length - 1) && <span key={index} style={{ background: 'yellow' }}>{field}</span>,
          ])
        }).reduce((prev, cur) => { return ([...prev, ...cur]) }, [])
      }

      return [child]
    }).reduce((prev, cur) => { return ([...prev, ...cur]) }, [])
  }

  return children
}

const HighlightedLogPreviewer = ({ text, omitFields, highlightFields = [] }) => {
  try {
    const parse = JSON.parse(text)
    const filterParse = Object.fromEntries(Object.entries(parse).filter(([key]) => { return !omitFields.includes(key) }))
    const json = JSON.stringify(filterParse, null, 2)
    const children = highlightChildren(json, highlightFields)

    return (
      <pre>
        {children}
      </pre>
    )
  } catch (e) {
    const children = highlightChildren(text, highlightFields)

    return <div>{children}</div>
  }
}

export default HighlightedLogPreviewer
