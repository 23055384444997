import React from 'react'
import { Table, Tag } from 'antd'
import { useVersionSelector } from '@core/hooks/useVersions'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import VersionsActionsButtons from './VersionActionsButtons'
import { tagColors } from '../../constants/versionStatusColor'

const VersionsList = () => {
  const versionSelectorResult = useVersionSelector()
  const { current, loading } = versionSelectorResult
  let { listed: versions } = versionSelectorResult

  const shouldDisplayOnlyProductionVersion = usePermittedFeaturesLookup('versions.viewProductionOnly')
  if (shouldDisplayOnlyProductionVersion) {
    versions = versions.filter((v) => { return v.status.includes('PRODUCTION') })
  }

  const columns = [
    {
      title: 'Version id',
      dataIndex: 'id',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return [].concat(status).map((item, index) => {
          return (
            <Tag key={index} color={tagColors[item]}>{item}</Tag>
          )
        })
      },
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: VersionsActionsButtons,
    },
  ]

  return (
    <Table
      loading={loading}
      dataSource={versions.map((version, index) => {
        return {
          ...version,
          isCurrentVersion: version.id === current,
          key: index,
        }
      })}
      size='small'
      columns={columns}
      pagination={false}
      sticky
    />
  )
}

export default VersionsList
