import React from 'react'
import { Form, Divider, Select, Spin } from 'antd'
import PropTypes from 'prop-types'

import useRequest from '@core/hooks/useRequest'
import Api from '@core/api'

const ByLanguage = ({ supportedLanguages }) => {
  const [
    { loading: languageDetailsLoading, data: languagesDict },
  ] = useRequest(Api.Languages.getLanguageDetails)

  if (languageDetailsLoading) return <Spin />

  return (
    <>
      <Divider>
        <small><strong>By Languages</strong></small>
      </Divider>
      <Form.Item name='language' label='Languages'>
        <Select
          mode='multiple'
          allowClear
          placeholder='Select the desired languages'
          showSearch
          optionFilterProp='children'
          filterOption={(input, option) => {
            const value = (option.value ?? '').toLowerCase()
            const name = (option.name ?? '').toLowerCase()
            return (value.includes(input.toLowerCase()) || name.includes(input.toLowerCase()))
          }}
        >
          <Select.Option key='disabled' value='disabled'>
            {'\u2753 Disabled'}
          </Select.Option>
          { languagesDict && supportedLanguages.map((code) => {
            const language = languagesDict[code]
            if (language) {
              return (
                <Select.Option
                  key={language.code}
                  value={language.code}
                  name={language.name}
                >
                  {`${language.flagISO} ${language.name} (${language.code})`}
                </Select.Option>
              )
            }
            return null
          })}
        </Select>
      </Form.Item>
    </>
  )
}

ByLanguage.propTypes = {
  supportedLanguages: PropTypes.arrayOf(PropTypes.string),
}

ByLanguage.defaultProps = {
  supportedLanguages: ['en', 'es', 'de', 'fr'],
}

export default ByLanguage
