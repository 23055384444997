import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, Space, Divider, Typography } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { sortTextOrNull } from '@core/helpers'
import style from '../index.module.scss'
import TestCaseDetail from '../TestCaseDetail'

const { Panel } = Collapse

const TestCase = ({ exchanges }) => {
  return (
    <Collapse bordered={false} expandIcon={({ isActive }) => { return isActive ? <MinusOutlined /> : <PlusOutlined /> }}>
      {exchanges.map((exchangeWithTestCases) => {
        const isSomeFail = exchangeWithTestCases.testCases.some((testCase) => { return testCase.isAllPassed === false })
        return (
          <Panel
            className={style.collapsePanel}
            header={(
              <span
                className={`${isSomeFail ? style.testFailed : style.testPassed}`}
              >
                {`Exchange#${exchangeWithTestCases.exchangeId} (${isSomeFail ? 'FAILED' : 'PASSED'})`}
              </span>
            )}
            key={exchangeWithTestCases.id}
          >
            {exchangeWithTestCases.testCases.sort((a, b) => {
              return sortTextOrNull(a?.description, b?.description)
            }).map((testCase) => {
              return (
                <>
                  <Space>
                    <span className={style.toMatchText}>Description:</span>
                    {testCase.description}
                    {testCase.skip && <Typography.Text level={4} strong>(SKIP)</Typography.Text>}
                  </Space>
                  {testCase.expected.responseKeys && <TestCaseDetail testCaseDetail={testCase} type='responseKeys' />}
                  {testCase.expected.textTerms && <TestCaseDetail testCaseDetail={testCase} type='textTerms' />}
                  {(testCase.expected.intent || testCase.expected.domain) && <TestCaseDetail testCaseDetail={testCase} type='domainIntent' />}
                  <Divider dashed />
                </>
              )
            })}
          </Panel>
        )
      })}
    </Collapse>
  )
}

TestCase.propTypes = {
  exchanges: PropTypes.arrayOf(PropTypes.shape({
    exchangeId: PropTypes.string,
    sequence: PropTypes.number,
    testCases: PropTypes.arrayOf(PropTypes.object),
  })).isRequired,
}

export default TestCase
