import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Row, Col, Typography, Skeleton, Spin, Result } from 'antd'
import PreprocessedSentence from './DesymbolizedSentence'

import style from './style.module.scss'

const SelectableDesymbolizedEmailSentence = ({
  extractionsLoading,
  isSubmitting,
  emailId,
  sentences,
  onClickSymbol,
  selectedSymbol,
}) => {
  if (extractionsLoading) return (<Skeleton active />)

  const onSelectPhrase = (sentenceIndex) => {
    return (symbolInfo) => {
      onClickSymbol(sentenceIndex, symbolInfo)
    }
  }

  return (
    <>
      <Typography.Title level={5}>
        Email Body
      </Typography.Title>
      <Spin spinning={isSubmitting}>
        {(!sentences || !sentences.length) && (
          <Result
            title='Could not retrieved desymbolized sentence or no results from symbolizer'
            subTitle={(
              <Typography.Paragraph>
                Email ID:
                {' '}
                {emailId ? <Typography.Text copyable={{ tooltips: 'Copy this Email ID for report' }}>{emailId}</Typography.Text> : 'N/A'}
              </Typography.Paragraph>
            )}
          />
        )}
        {
          sentences.map((sentence, index) => {
            const sentenceIndex = index
            return (
              <Row
                wrap={false}
                gutter={[0, 0]}
                key={`${sentenceIndex}:${index}`}
                className={classnames(
                  style.PreprocessedSentence,
                  { [style.SelectedSentence]: Boolean(sentenceIndex === selectedSymbol.sentenceIndex) },
                )}
              >
                <Col flex='36px'>
                  <Typography.Text>
                    {`[${sentenceIndex}]:`}
                  </Typography.Text>
                </Col>
                <Col flex='auto'>
                  <PreprocessedSentence
                    key={index}
                    symbols={sentence.tokens ?? sentence.desymbolizedSplitted ?? []}
                    selectedPhrase={selectedSymbol.symbolInfo}
                    onSelectPhrase={onSelectPhrase(sentenceIndex)}
                  />
                </Col>
              </Row>
            )
          })
        }
      </Spin>
    </>
  )
}

SelectableDesymbolizedEmailSentence.defaultProps = {
  sentences: [],
  selectedSymbol: { sentenceIndex: null, symbolInfo: null },
}

SelectableDesymbolizedEmailSentence.propTypes = {
  extractionsLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  emailId: PropTypes.string.isRequired,
  sentences: PropTypes.arrayOf(PropTypes.shape({
    desymbolizedSplitted: PropTypes.arrayOf(
      PropTypes.shape({
        occurrenceIndex: PropTypes.number.isRequired,
        original: PropTypes.string.isRequired,
        sources: PropTypes.arrayOf(PropTypes.string),
        predictedSource: PropTypes.string,
      }),
    ),
    preprocessedSentence: PropTypes.string,
    sentenceIndex: PropTypes.number,
  })),
  onClickSymbol: PropTypes.func.isRequired,
  selectedSymbol: PropTypes.shape({
    sentenceIndex: PropTypes.number,
    symbolInfo: PropTypes.shape({
      occurrenceIndex: PropTypes.number,
      original: PropTypes.string,
      sources: PropTypes.arrayOf(PropTypes.string),
      predictedSource: PropTypes.string,
    }),
  }),
}

export default SelectableDesymbolizedEmailSentence
