import { wrapActionCreatorWithDispatch } from './dispatchInjection'
import { PERMISSIONS } from '../constants'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  fetch: () => {
    return async (dispatch) => {
    // NOTE: server will send back the 'merged' feature one
      dispatch({ type: PERMISSIONS.FETCH.REQUEST })
      try {
        const response = await Api.PermittedFeatures.get()

        if (response && response.status === 'success') {
          const permittedFeaturePropNames = Object.keys(response.data)
          dispatch({
            type: PERMISSIONS.FETCH.SUCCESS,
            payload: {
              raw: response.data,
              transformed: permittedFeaturePropNames.reduce((route, categoryTitle) => {
                const splitGroup = categoryTitle.split(/(?=[A-Z])/g)

                if (splitGroup.length > 1) {
                  const topLevelCategoryTitle = splitGroup[0]
                  // NOTE: This is heavily relied on only two-levels deep,
                  // there is no check on third level
                  const subLevelTitle = [...splitGroup.slice(1)]
                  const [firstSubLevelTitle] = subLevelTitle
                  subLevelTitle[0] = firstSubLevelTitle.charAt(0).toLowerCase() + firstSubLevelTitle.slice(1)

                  route[topLevelCategoryTitle] = {
                    ...route[topLevelCategoryTitle] || {},
                    [subLevelTitle.join('')]: response.data[categoryTitle],
                  }
                } else {
                  route[categoryTitle] = response.data[categoryTitle]
                }

                return route
              }, {}),
              // TODO: Too looong...
              routes: Object.values(
                permittedFeaturePropNames.reduce((route, categoryTitle) => {
                  const splitGroup = categoryTitle.split(/(?=[A-Z])/g)

                  if (!response.data[categoryTitle].viewTab) return route

                  if (splitGroup.length > 1) {
                    const topCategoryTitle = splitGroup[0]
                    // NOTE: This is heavily relied on only two-levels deep,
                    // there is no check on third level
                    const subLevelTitle = [...splitGroup.slice(1)]

                    route[topCategoryTitle] = route[topCategoryTitle] || {}

                    route[topCategoryTitle] = {
                      title: route[topCategoryTitle].title
                            || topCategoryTitle.charAt(0).toUpperCase() + topCategoryTitle.slice(1),
                      children: route[topCategoryTitle].children || [],
                    }

                    route[topCategoryTitle].children.push({
                      to: `/${subLevelTitle.join('-').toLowerCase()}`,
                      title: `${subLevelTitle.join('')}`,
                    })
                  } else {
                    route[categoryTitle] = {
                      to: `/${categoryTitle}`,
                      title: categoryTitle.charAt(0).toUpperCase() + categoryTitle.slice(1),
                    }
                  }

                  return route
                }, {}),
              ),
            },
          })

          return response
        }

        dispatch({ type: PERMISSIONS.FETCH.FAILED })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: PERMISSIONS.FETCH.FAILED })

        throw error
      }
    }
  },
})
