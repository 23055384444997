import { wrapActionCreatorWithDispatch } from './dispatchInjection'
import { INTENT_TREE } from '../constants'
import { buildDomainIntentFetchPayload } from '../../helpers'
import Api from '../../api'

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  fetch: () => {
    return async (dispatch) => {
      dispatch({ type: INTENT_TREE.FETCH.REQUEST })
      try {
        const response = await Api.getDomainsIntents()

        if (response && response.data) {
          return dispatch({
            type: INTENT_TREE.FETCH.SUCCESS,
            payload: buildDomainIntentFetchPayload(response.data),
          })
        }

        dispatch({ type: INTENT_TREE.FETCH.FAILED })

        throw new Error('Response incompartible')
      } catch (error) {
        dispatch({
          type: INTENT_TREE.FETCH.FAILED,
          error,
        })

        throw error
      }
    }
  },
})
