import React, { useEffect } from 'react'
import {
  Form,
  Input,
  Button,
} from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import style from './index.module.scss'

const CategoryForm = ({
  form,
  category,
  loading,
  onFinish,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { return form.resetFields() }, [category])

  const handleFinish = (values) => {
    return onFinish({
      ...values,
      subcategories: values.subcategories.filter((subcategory) => { return !!subcategory.name }),
    })
  }

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout='horizontal'
      initialValues={category}
    >
      <Form.Item
        label='Name'
        name='name'
        rules={[{ required: true, message: 'Please input name!' }]}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Input readOnly={loading} />
      </Form.Item>
      <h4>Subcategories</h4>
      <Form.List
        name='subcategories'
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div style={{ display: 'flex' }} key={field.key}>
                    <div style={{ width: '5%' }}>
                      {`${index + 1}.`}
                    </div>
                    <div style={{ width: '85%' }}>
                      <Form.Item name={[field.name, 'name']}>
                        <Input placeholder='Name' readOnly={loading} />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined
                      className={style.delete_button__subcategory_form}
                      onClick={() => { return remove(field.name) }}
                    />
                  </div>
                )
              })}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => { return add() }}
                  style={{ width: '100%' }}
                  icon={<PlusOutlined />}
                  disabled={loading}
                >
                  Add subcategory
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}

CategoryForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any.isRequired,
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    subcategories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default CategoryForm
