import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'antd'
import RecentlyAddedIntents from '../../RecentlyAddedIntents'
import CoordinatedIntentSelectBoxes from '../../CoordinatedIntentSelectBoxes'

const AddTrainingCasesForm = ({ intents, addIntents, emailHasBeenSelected, showNoEmailSelectedAlertForIntent, recentlyAddedIntents }) => {
  const [form] = Form.useForm()
  const onFinish = async (values) => {
    const { intentIds } = values
    const response = await addIntents(intentIds)

    if (response?.status === 'success') form.resetFields()
  }

  const onFinishFailed = () => {
    if (!emailHasBeenSelected) {
      showNoEmailSelectedAlertForIntent()
    }
  }

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label='Intent' name='intentIds' rules={[{ required: true, message: 'Please select intent' }]}>
          <CoordinatedIntentSelectBoxes intentPairs={intents} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>Add</Button>
        </Form.Item>
      </Form>
      <RecentlyAddedIntents recentlyAddedIntents={recentlyAddedIntents} />
    </>
  )
}

AddTrainingCasesForm.defaultProps = {
  recentlyAddedIntents: [],
}

AddTrainingCasesForm.propTypes = {
  intents: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.shape({
        positive: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          training_count: PropTypes.number,
        }),
        negative: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          training_count: PropTypes.number,
        }),
      }),
    ),
  ).isRequired,
  emailHasBeenSelected: PropTypes.bool.isRequired,
  addIntents: PropTypes.func.isRequired,
  showNoEmailSelectedAlertForIntent: PropTypes.func.isRequired,
  recentlyAddedIntents: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string,
      addedIntents: PropTypes.arrayOf(PropTypes.string),
      emailIds: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
}

export default AddTrainingCasesForm
