import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Descriptions,
  Button,
  Divider,
  List,
  Spin,
  Card,
  Typography,
} from 'antd'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import style from './index.module.scss'
import AddDynamicEntityModal from './AddDynamicEntitiesModal'
import EditSentenceDynamicEntities from './EditDynamicEntitiesModal'
import DeleteDynamicEntitiesModal from './DeleteDynamicEntitiesModal'
import DynamicEntitiesInnerList from './DynamicEntitiesInnerList'

const { Paragraph } = Typography

const DynamicEntities = () => {
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const { selectedDynamicEntity, isPending, selectedSentence } = useSelector(({ data }) => {
    return {
      isPending: data.dynamicEntities.isPending,
      selectedDynamicEntity: data.dynamicEntities.selectedDynamicEntity,
      selectedSentence: data.sentences.selectedSentence,
    }
  })

  if (!selectedSentence.data) {
    return (
      <Paragraph>{`The data for sentence ${selectedSentence.id} is corrupted. Please add an empty object to the field data as a default.`}</Paragraph>
    )
  }

  return (
    <div className={style.right_panel__dynamic_entities}>
      {
        selectedDynamicEntity
        && editModalVisible
        && (
          <EditSentenceDynamicEntities
            visible={editModalVisible}
            closeModal={() => { return setEditModalVisible(false) }}
          />
        )
      }

      {
        selectedDynamicEntity
        && deleteModalVisible
        && (
          <DeleteDynamicEntitiesModal
            visible={deleteModalVisible}
            closeModal={() => { return setDeleteModalVisible(false) }}
          />
        )
      }

      {
        isPending
          ? <Spin />
          : (
            <>
              <AddDynamicEntityModal
                visible={addModalVisible}
                closeModal={() => { return setAddModalVisible(false) }}
              />
              <Descriptions size='small' bordered column={5}>
                <Descriptions.Item label='Text' span={5}>{selectedSentence.text}</Descriptions.Item>
                <Descriptions.Item label='Generalized' span={5}>{selectedSentence.generalized.generalized}</Descriptions.Item>
              </Descriptions>
              <Divider>Dynamic Entities</Divider>
              <div className={style.right_panel__dynamic_entities_content}>
                <List
                  bordered
                  size='small'
                  itemLayout='horizontal'
                  dataSource={Object.values(selectedSentence.data.dynamicEntities || [])}
                  renderItem={(entity, j) => {
                    return (
                      <List.Item key={j}>
                        <Card
                          style={{
                            width: '100%',
                          }}
                          title={entity.name}
                        >
                          <DynamicEntitiesInnerList
                            entity={entity}
                            setEditModalVisible={setEditModalVisible}
                            setDeleteModalVisible={setDeleteModalVisible}
                          />
                        </Card>
                      </List.Item>
                    )
                  }}
                />
              </div>
              <PermittedFeatureCheck featurePath='utterances.data.actionInsertDynamicEntities'>
                <Button
                  type='primary'
                  className={style.right_panel__dynamic_entities_add}
                  onClick={() => {
                    setAddModalVisible(true)
                  }}
                >
                  Add new item
                </Button>
              </PermittedFeatureCheck>
            </>
          )
      }
    </div>
  )
}

export default DynamicEntities
