import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { EDIT_SENTENCE_MODAL_UI } from '../constants'

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  open: (sentence) => {
    return async (dispatch) => {
      dispatch({
        type: EDIT_SENTENCE_MODAL_UI.OPEN,
        payload: { sentence },
      })
    }
  },
  dismiss: () => {
    return async (dispatch) => {
      dispatch({
        type: EDIT_SENTENCE_MODAL_UI.CLOSE,
      })
    }
  },
})
