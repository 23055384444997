import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  List,
  Typography,
  Button,
  Popconfirm,
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useIntentLookup } from '@core/hooks/useDomainIntentLookup'
import style from './index.module.scss'

const ListItem = ({ item, onEditTrainingCase, onConfirmDelete }) => {
  const { parentDomain } = useIntentLookup(item.train_intent_id)

  let trainingCaseType
  if (item.train_domain) trainingCaseType = 'domain'
  if (item.train_intent) trainingCaseType = 'intent'
  if (item.tagger_type) trainingCaseType = 'tagger'

  const onConfirm = (event) => {
    onConfirmDelete(trainingCaseType, event)
  }

  if (item.train_domain || item.train_intent) {
    return (
      <List.Item key={item.id}>
        <Row className={style.row} align='middle'>
          <Col flex='1'>
            {item.train_domain && item.train_domain}
            {item.train_intent && `${parentDomain.name} / ${item.train_intent}`}
          </Col>
          <Col flex='1' className={style.col}>
            <Button type='link' onClick={onEditTrainingCase(item, trainingCaseType)}>Edit</Button>
            <Popconfirm title='Are you sure？' onConfirm={onConfirm} icon={<QuestionCircleOutlined className={style.confirm_delete} />}>
              <Button type='link' danger>Delete</Button>
            </Popconfirm>
          </Col>
        </Row>
      </List.Item>
    )
  }

  return (
    <List.Item key={item.id}>
      <Row className={style.row} align='middle' gutter={10}>
        <Col flex='1'>
          <Typography.Text mark>
            {item.tagger_type}
          </Typography.Text>
        </Col>
        <Col flex='1'>{item.phrase}</Col>
        <Col flex='1'>{`[${item.phrase_index}]`}</Col>
        <Col flex='1'>{item.desired_result}</Col>
        <Col flex='1' className={style.col}><Button type='link' onClick={onEditTrainingCase(item, trainingCaseType)}>Edit</Button></Col>
        <Popconfirm title='Are you sure？' onConfirm={onConfirm} icon={<QuestionCircleOutlined className={style.confirm_delete} />}>
          <Button type='link' danger>Delete</Button>
        </Popconfirm>
      </Row>
    </List.Item>
  )
}

ListItem.propTypes = {
  item: PropTypes.shape({
    train_intent_id: PropTypes.number,
    train_domain: PropTypes.string,
    train_intent: PropTypes.string,
    tagger_type: PropTypes.string,
    id: PropTypes.string,
    phrase: PropTypes.string,
    phrase_index: PropTypes.number,
    desired_result: PropTypes.string,
  }).isRequired,
  onEditTrainingCase: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
}

export default ListItem
