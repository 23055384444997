import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Form,
  Button,
  Input,
  Checkbox,
  Typography,
  Divider,
  Space,
  notification,
} from 'antd'
import style from './index.module.scss'

const requiredFieldRules = [{ required: true, message: 'Required field' }]

const AddNewCaseModal = ({
  addNewCase,
  closeModal,
  modalVisible,
  addNewCasePending,
}) => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)
  const [matchResponseKey, setMatchResponseKey] = useState(false)
  const [matchTextTerms, setMatchTextTerms] = useState(false)
  const [matchDomainIntent, setMatchDomainIntent] = useState(false)
  const onChecked = (hookStateSetter) => {
    return (event) => {
      const { target: { checked } } = event
      hookStateSetter(checked)
    }
  }

  const onFinish = async (fields) => {
    setHasError(false)
    try {
      const response = await addNewCase(fields)

      if (response && response.status === 'success') {
        notification.success({
          message: 'New Exchange Test case has been added',
          duration: 10,
        })

        closeModal()
      }
    } catch (error) {
      setHasError(true)
    }
  }

  if (!modalVisible) return null

  return (
    <Modal
      title='Add new test case'
      visible={modalVisible}
      onCancel={closeModal}
      maskClosable={false}
      closable={false}
      destroyOnClose
      footer={[
        <Button key='back' onClick={closeModal} disabled={addNewCasePending}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' disabled={addNewCasePending} loading={addNewCasePending} onClick={form.submit}>
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        name='ExchangeTestCase__form--new_case'
        initialValues={{ skip: false }}
        onFinish={onFinish}
      >
        <Form.Item
          label='Description'
          name='description'
          rules={requiredFieldRules}
        >
          <Input.TextArea disabled={addNewCasePending} rows={2} placeholder='Describe Test' />
        </Form.Item>
        <Form.Item
          name='skip'
          valuePropName='checked'
        >
          <Checkbox disabled={addNewCasePending}>
            <Typography.Text strong>Skip</Typography.Text>
          </Checkbox>
        </Form.Item>
        <Divider />
        <Space className={style.MatchingCondition} direction='vertical' size='middle'>
          <Checkbox
            disabled={addNewCasePending}
            checked={matchResponseKey}
            onChange={onChecked(setMatchResponseKey)}
          >
            <Typography.Text strong>Matching Response Key</Typography.Text>
          </Checkbox>
          {matchResponseKey && (
            <Form.Item
              name='responseKeys'
              rules={matchResponseKey ? requiredFieldRules : null}
              preserve={false}
            >
              <Input disabled={addNewCasePending} placeholder='Use comma (,) for multiple matches' />
            </Form.Item>
          )}
          <Checkbox
            disabled={addNewCasePending}
            checked={matchTextTerms}
            onChange={onChecked(setMatchTextTerms)}
          >
            <Typography.Text strong>Matching Text Terms</Typography.Text>
          </Checkbox>
          {matchTextTerms && (
            <Form.Item
              name='textTerms'
              rules={matchTextTerms ? requiredFieldRules : null}
              preserve={false}
            >
              <Input disabled={addNewCasePending} placeholder='Use comma (,) for multiple matches' />
            </Form.Item>
          )}
          <Checkbox
            disabled={addNewCasePending}
            checked={matchDomainIntent}
            onChange={onChecked(setMatchDomainIntent)}
          >
            <Typography.Text strong>Match Domain / Intent</Typography.Text>
          </Checkbox>
          {matchDomainIntent && (
            <Form.Item
              className={style.MatchingCondition__DomainIntent}
              name='domain'
              rules={matchDomainIntent ? requiredFieldRules : null}
              preserve={false}
            >
              <Input disabled={addNewCasePending} placeholder='Domain' />
            </Form.Item>
          )}
          {matchDomainIntent && (
            <Form.Item
              className={style.MatchingCondition__DomainIntent}
              name='intent'
              rules={matchDomainIntent ? requiredFieldRules : null}
              preserve={false}
            >
              <Input disabled={addNewCasePending} placeholder='Intent' />
            </Form.Item>
          )}
          {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
        </Space>
      </Form>
    </Modal>
  )
}

AddNewCaseModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  addNewCasePending: PropTypes.bool.isRequired,
  addNewCase: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default AddNewCaseModal
