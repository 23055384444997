import React from 'react'
import { Form, Select } from 'antd'
import { itemsType } from '@core/types/formItems'

const PhraseToMatch = ({ symbols }) => {
  return (
    <Form.Item
      name='phrase'
      label='Select the [Occurrence Index] Phrase/Word/Symbol'
      rules={[{ required: true, message: 'Select the [Occurrence Index] Phrase/Word/Symbol' }]}
    >
      <Select
        placeholder={symbols !== null ? 'Select the phrase to process' : 'Please select sentence first'}
        showSearch
      >
        {!symbols.length && <Select.Option value='' disabled selected>Please select sentence first!</Select.Option>}
        {symbols.length && symbols.map((symbol, index) => {
          return (
            <Select.Option value={JSON.stringify(symbol)} key={index}>
              {`[Index: ${symbol.occurrenceIndex}]: ${symbol.original}`}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

PhraseToMatch.propTypes = {
  symbols: itemsType,
}

PhraseToMatch.defaultProps = {
  symbols: [],
}

export default PhraseToMatch
