import React from 'react'
import { Form, Select } from 'antd'
import { rulesType, itemsType } from '@core/types/formItems'

const TaggerPhraseToMatch = ({ rules, items }) => {
  return (
    <Form.Item name='phrase' label='Select the [index] Phrase/Word' rules={rules}>
      <Select placeholder='Select the phrase to process'>
        {items.map((item) => {
          return (
            <Select.Option value={JSON.stringify(item)} key={`[${item.phraseIndex}]: ${item.value}`}>
              {`[${item.phraseIndex}]: ${item.value}`}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

TaggerPhraseToMatch.propTypes = {
  rules: rulesType,
  items: itemsType,
}

TaggerPhraseToMatch.defaultProps = {
  rules: [],
  items: [],
}

export default TaggerPhraseToMatch
