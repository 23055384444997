export const DB_CHANNEL_MAP = {
  GBT: 'GBT',
  GOOGLE_CHAT: 'Google Chat',
  MS_TEAMS: 'MS Teams',
  SLACK: 'Slack',
  SMS: 'SMS',
  WHATSAPP: 'WhatsApp',
  ZOOM: 'Zoom',
  WEBEX: 'WebEx',
}
