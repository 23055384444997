import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Menu } from 'antd'
import AppLogoTitle from '@core/components/AppLogoTitle'
import style from './index.module.scss'

const { Header } = Layout

const PublicLayout = ({ children }) => {
  return (
    <Layout className={style.Main}>
      <Header>
        <Menu mode='horizontal'>
          <AppLogoTitle />
        </Menu>
      </Header>
      <Layout className={style.Main__content}>
        {children}
      </Layout>
    </Layout>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PublicLayout
