import React, { useState, useEffect } from 'react'
import {
  Space,
  Row,
  Button,
  Typography,
  Table,
  Pagination,
  List,
  Popconfirm,
  Modal,
  notification,
} from 'antd'
import PropTypes from 'prop-types'

import {
  sortNumberOrNull,
  sortTextOrNull,
  sortDateTimeOrNull,
  shallowCleanFalsyObject,
} from '@core/helpers'
import useRequest from '@core/hooks/useRequest'

import Api from '../../../../api'

import styles from './index.module.scss'
import AddTestCollectionButton from './AddTestCollectionButton'
import RunSelectedTestCollectionButton from './RunSelectedTestCollectionButton'
import EditTestCollectionButton from './EditTestCollectionButton'
import ManageTestCases from './ManageTestCases'

const {
  findManyTestCollection,
  deleteTestCollectionById,
  deleteManyTestCollectionByIds,
} = Api.EndToEndTestCollection

const notifySuccessDeletion = () => {
  notification.success({
    message: 'Success',
    description: 'Deleted',
  })
}

const TestCollection = ({
  onRunTestCollectionSuccess,
  activeKey,
  shouldUpdate,
  shouldUpdateDispatcher,
}) => {
  const [filter, setFilter] = useState({})
  const [selectedTestCollectionIds, setSelectedTestCollectionIds] = useState([])
  const [{ loading, data }, { makeRequest }] = useRequest(findManyTestCollection, shallowCleanFalsyObject(filter))
  const { items = [], meta = {} } = data || {}
  const isBulkOperationDisabled = selectedTestCollectionIds.length < 1

  useEffect(() => {
    if (activeKey === 'testCollection') {
      if (shouldUpdate.testCollections) {
        makeRequest()
        shouldUpdateDispatcher({ type: 'testCollections', payload: { status: false } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  const triggerSearchHandler = () => {
    shouldUpdateDispatcher({ type: 'collectionChoices', payload: { status: true } })
    setFilter((prevFilter) => { return { ...prevFilter, _: Math.random() } })
  }

  const noTestCasesHandler = () => {
    notification.error({
      message: 'Error',
      description: 'The test could not start running as there are 0 test cases. Please add test cases first before trying again.',
    })
  }

  const paginationChangeHandler = (page, perPage) => {
    if (filter.perPage !== perPage) {
      setFilter((prevFilter) => { return { ...prevFilter, page: 1, perPage } })
    } else {
      setFilter((prevFilter) => { return { ...prevFilter, page, perPage } })
    }
  }

  const deleteTestCollectionHandlerWrapper = (testCollectionId) => {
    return async () => {
      try {
        await deleteTestCollectionById(testCollectionId)
        triggerSearchHandler()
        notifySuccessDeletion()
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `We've run into the issue of delete collection id: ${testCollectionId} `,
          duration: 5,
        })
      }
    }
  }

  const deleteManyTestCollectionHandlerWrapper = (testCollectionIds) => {
    return async () => {
      Modal.confirm({
        centered: true,
        closable: true,
        title: 'Are you sure you want to delete these collections?',
        cancelText: 'No',
        okText: 'Yes',
        okType: 'danger',
        okButtonProps: {
          type: 'primary',
        },
        onOk: async () => {
          if (!testCollectionIds.length) return undefined
          try {
            if (testCollectionIds.length === 1) {
              await deleteTestCollectionById(testCollectionIds[0])
            } else {
              await deleteManyTestCollectionByIds(testCollectionIds)
            }
            triggerSearchHandler()
            notifySuccessDeletion()
            setSelectedTestCollectionIds([])
          } catch (error) {
            notification.error({
              message: 'Error',
              description: 'We\'ve run into the issue of delete collections.',
              duration: 5,
            })
          }
          return undefined
        },
      })
    }
  }

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys) => {
      setSelectedTestCollectionIds(selectedRowKeys)
    },
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'description',
      sorter: (a, b) => { return sortTextOrNull(a.description, b.description) },
    },
    {
      title: 'Number of Test Cases',
      dataIndex: ['testCases', 'length'],
      sorter: (a, b) => { return sortNumberOrNull(a.testCases.length, b.testCases.length) },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: (a, b) => { return sortDateTimeOrNull(a.createdAt, b.createdAt) },
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      sorter: (a, b) => { return sortDateTimeOrNull(a.updatedAt, b.updatedAt) },
    },
    {
      title: 'Action',
      key: 'action',
      width: 330,
      render: (_, record) => {
        const ModalTitle = (
          <>
            <Typography.Text>Edit</Typography.Text>
            &nbsp;
            <Typography.Text type='secondary'>{`(${record.description})`}</Typography.Text>
          </>
        )

        const testCollectionId = record.id

        const ManageTestCasesModalTitle = (
          <>
            <Typography.Text>Managing Test Cases</Typography.Text>
            &nbsp;
            <Typography.Text type='secondary'>{`(${record.description})`}</Typography.Text>
          </>
        )

        const runTestCollectionHandlerWrapper = (id, description) => {
          return async () => {
            try {
              await Api.EndToEndTestCollection.evaluateByTestCollectionIds({ ids: [id], jobName: description })
              onRunTestCollectionSuccess()
            } catch (error) {
              if (error.data?.status === 'Forbidden') {
                noTestCasesHandler()
                return
              }
              notification.error({
                message: 'Error',
                description: error.message || 'Something went wrong',
              })
            }
          }
        }

        return (
          <Space>
            <Button size='small' type='primary' onClick={runTestCollectionHandlerWrapper(record.id, record.description)}>Run</Button>
            <ManageTestCases
              testCollectionId={testCollectionId}
              linkTestCases={record.testCases}
              modalTitle={ManageTestCasesModalTitle}
              afterSuccessHandler={triggerSearchHandler}
            />
            <EditTestCollectionButton
              buttonProps={{ size: 'small', type: 'secondary', text: 'Edit' }}
              afterSuccessHandler={triggerSearchHandler}
              initialValues={record}
              testCollectionId={testCollectionId}
              modalTitle={ModalTitle}
            />
            <Popconfirm
              title={(
                <>
                  <Typography.Text>Are you sure you want to delete</Typography.Text>
                  &nbsp;
                  <Typography.Text strong>
                    &lsquo;
                    {record.description}
                    &lsquo;
                  </Typography.Text>
                  &nbsp;
                  <Typography.Text>collection?</Typography.Text>
                </>
              )}
              onConfirm={deleteTestCollectionHandlerWrapper(testCollectionId)}
              okButtonProps={{ type: 'danger' }}
              okText='Yes'
              cancelText='No'
            >
              <Button size='small' type='danger'>Delete</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const TableTitle = () => {
    return (
      <Row justify='space-between'>
        <Typography.Title level={5} id={styles.testCollectionTitle}>Collections</Typography.Title>
        <Space>
          <AddTestCollectionButton buttonProps={{ size: 'small', type: 'primary', text: '+ Add New Collection' }} afterSuccessHandler={triggerSearchHandler} />
          <RunSelectedTestCollectionButton
            buttonProps={{ size: 'small', type: 'primary', text: 'Run Selected', disabled: isBulkOperationDisabled }}
            selectedTestCollectionIds={selectedTestCollectionIds}
            afterSuccessHandler={onRunTestCollectionSuccess}
            noTestCasesHandler={noTestCasesHandler}
          />
          <Button size='small' type='danger' disabled={isBulkOperationDisabled} onClick={deleteManyTestCollectionHandlerWrapper(selectedTestCollectionIds)}>Delete Selected</Button>
        </Space>
      </Row>
    )
  }

  const expandedRowRender = (record) => {
    return (
      <List
        size='small'
        dataSource={record.testCases?.sort((a, b) => { return sortTextOrNull(a.description, b.description) })}
        renderItem={(testCase) => { return <List.Item>{testCase.description}</List.Item> }}
      />
    )
  }

  return (
    <>
      <Table
        pagination={false}
        loading={loading}
        title={TableTitle}
        size='small'
        rowSelection={rowSelection}
        columns={columns}
        dataSource={items?.map((record) => {
          if (!record.updatedAt) {
            record.updatedAt = record.createdAt
          } return record
        })}
        rowKey='id'
        expandable={{
          expandedRowRender,
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          y: 375,
        }}
      />
      <Row align='end'>
        <Pagination
          className={styles.paginationComponent}
          showSizeChanger
          size='small'
          total={filter?.totalCount || meta?.totalCount || 1}
          pageSize={filter?.perPage || meta?.pageSize || 50}
          current={filter?.page || meta?.page || 1}
          onChange={paginationChangeHandler}
        />
      </Row>
    </>
  )
}

TestCollection.propTypes = {
  onRunTestCollectionSuccess: PropTypes.func,
  activeKey: PropTypes.string.isRequired,
  shouldUpdate: PropTypes.shape({
    testCases: PropTypes.bool,
    collectionChoices: PropTypes.bool,
    testCollections: PropTypes.bool,
  }).isRequired,
  shouldUpdateDispatcher: PropTypes.func.isRequired,
}

TestCollection.defaultProps = {
  onRunTestCollectionSuccess: () => { },
}

export default TestCollection
