export const fields = [
  {
    name: 'messageCount',
    color: 'green',
    type: 'number',
    title: 'Messages',
    abbreviation: 'Msg',
    template: '{{data}}',
    featured: true,
  },
  {
    name: 'wordCount',
    color: 'red',
    type: 'number',
    title: 'Tokens',
    abbreviation: 'Tokens',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'averageWordCountPerMessage',
    color: 'red',
    type: 'number',
    title: 'Avg. Tokens/Message',
    abbreviation: 'Avg. Tokens/Msg',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'timeToAgent',
    color: 'red',
    type: 'duration',
    title: 'Time To Agent',
    abbreviation: 'TT Agent',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'totalConversationDuration',
    color: 'blue',
    type: 'duration',
    title: 'Conversation time',
    abbreviation: 'CONV',
    template: '{{data}}',
    featured: true,
  },
  {
    name: 'botMessageCount',
    color: 'green',
    type: 'number',
    title: 'Bot Message Count',
    abbreviation: 'Bot Msg Count',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'customerMessageCount',
    color: 'grey',
    type: 'number',
    title: 'Customer Message Count',
    abbreviation: 'Customer Msg Count',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'customerAverageSubsequentMessages',
    color: 'grey',
    type: 'number',
    title: 'Customer Average Subsequent Messages',
    abbreviation: 'Customer Avg Subsequent Msg',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'customerLongestDelayResponding',
    color: 'grey',
    type: 'duration',
    title: 'Customer Longest Delay Responding',
    abbreviation: 'Customer Longest Delay Resp',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'customerAverageDelayResponding',
    color: 'grey',
    type: 'duration',
    title: 'Customer Average Delay Responding',
    abbreviation: 'Customer Avg Delay Resp',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'customerTotalWaitTime',
    color: 'grey',
    type: 'duration',
    title: 'Customer Wait Time',
    abbreviation: 'TTW-C',
    template: '{{data}}',
    featured: true,
  },
  {
    name: 'agentMessageCount',
    color: 'skyblue',
    type: 'number',
    title: 'Agent Message Count',
    abbreviation: 'Agent Msg Count',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'agentAverageSubsequentMessages',
    color: 'skyblue',
    type: 'number',
    title: 'Agent Average Subsequent Messages',
    abbreviation: 'Agent Avg Subsequent Msg',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'agentLongestDelayResponding',
    color: 'skyblue',
    type: 'duration',
    title: 'Agent Longest Delay Responding',
    abbreviation: 'Agent Longest Delay Resp',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'agentAverageDelayResponding',
    color: 'skyblue',
    type: 'duration',
    title: 'Agent Average Delay Responding',
    abbreviation: 'Agent Avg Delay Resp',
    template: '{{data}}',
    featured: false,
  },
  {
    name: 'agentTotalWaitTime',
    color: 'skyblue',
    type: 'duration',
    title: 'Agent Wait Time',
    abbreviation: 'TTW-A',
    template: '{{data}}',
    featured: true,
  },
]

export const featuredFields = fields.filter(({ featured }) => { return featured === true })
