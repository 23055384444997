import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, Divider, Typography, Button } from 'antd'
import { visibleType, closeModalType } from '../../../types/dynamicEntitiesModal'
import DynamicEntitiesActions from '../../../store/actions/dynamicEntities'

const { Paragraph } = Typography

const DeleteDynamicEntitiesModal = ({ visible, closeModal }) => {
  const { isPending, selectedDynamicEntity, selectedSentence } = useSelector(({ data }) => {
    return {
      isPending: data.dynamicEntities.isPending,
      selectedDynamicEntity: data.dynamicEntities.selectedDynamicEntity,
      selectedSentence: data.sentences.selectedSentence,
    }
  })

  const onClose = async () => {
    await DynamicEntitiesActions.reset()
    closeModal()
  }
  const handleOk = async () => {
    const tempDynamicEntities = selectedSentence.data.dynamicEntities
    tempDynamicEntities[selectedDynamicEntity.name].entities.splice([selectedDynamicEntity.index], 1)
    Object.keys(tempDynamicEntities).forEach((key) => {
      if (tempDynamicEntities[key].entities.length === 0) delete tempDynamicEntities[key]
    })
    await DynamicEntitiesActions.edit(selectedSentence.id,
      { ...selectedSentence.data, dynamicEntities: tempDynamicEntities })
    await onClose()
  }

  return (
    <Modal
      title='Delete Dynamic Entity'
      visible={visible}
      closable={false}
      centered
      width={1000}
      footer={[
        <Button key='back' onClick={onClose} loading={isPending}>
          Cancel
        </Button>,
        <Button key='submit' type='danger' onClick={handleOk} loading={isPending}>
          Delete
        </Button>,
      ]}
    >
      <Paragraph>Are you sure you want to delete?</Paragraph>
      <Divider />
      <Paragraph strong>{`Value: ${selectedDynamicEntity.value}`}</Paragraph>
      <Paragraph>{`Synonyms: ${selectedDynamicEntity.synonyms.join(', ')}`}</Paragraph>
    </Modal>
  )
}

DeleteDynamicEntitiesModal.propTypes = {
  visible: visibleType.isRequired,
  closeModal: closeModalType.isRequired,
}

export default DeleteDynamicEntitiesModal
