import React, { useState, useEffect, useReducer } from 'react'
import {
  Form,
  Modal,
  Button,
  Typography,
  Table,
  Tabs,
  notification,
  Layout,
  InputNumber,
} from 'antd'

import PropTypes from 'prop-types'

import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import { shallowCleanFalsyObject } from '@core/helpers'

import useRequest from '@core/hooks/useRequest'

import Api from '../../api'

const { Content } = Layout
const { TabPane } = Tabs

const TAB_KEYS = {
  FEATURES: 'FEATURES',
  CHANNELS: 'CHANNELS',
}

const featureColumnsTable = [
  {
    title: 'Feature Name',
    dataIndex: 'name',
    key: 'name',
  },
]

const ACTIONS = {
  RESET: 'RESET',
  SAVE_FEATURES_CHECKED_ROW_KEYS: 'SAVE_FEATURES_CHECKED_ROW_KEYS',
  SAVE_CHANNEL_CHECKED_ROW_KEYS: 'SAVE_CHANNEL_CHECKED_ROW_KEYS',
  REMOVE_FEATURES_CHECKED_ROW_KEYS: 'REMOVE_FEATURES_CHECKED_ROW_KEYS',
  REMOVE_CHANNEL_CHECKED_ROW_KEYS: 'REMOVE_CHANNEL_CHECKED_ROW_KEYS',
}

const initialState = {
  featuresCheckedRows: [],
  channelCheckedRows: [],
  channelUncheckedRows: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SAVE_FEATURES_CHECKED_ROW_KEYS:
      return { featuresCheckedRows: Array.from(new Set([].concat(state.featuresCheckedRows || [], action.payload))) }
    case ACTIONS.SAVE_CHANNEL_CHECKED_ROW_KEYS:
      return { channelCheckedRows: Array.from(new Set([].concat(state.channelCheckedRows || [], action.payload))) }
    case ACTIONS.REMOVE_FEATURES_CHECKED_ROW_KEYS: {
      const checkedRowsSet = new Set(state.featuresCheckedRows)
      const removingSet = new Set([].concat(action.payload))
      const difference = new Set([...checkedRowsSet].filter((key) => { return !removingSet.has(key) }))
      return { featuresCheckedRows: Array.from(difference) }
    }
    case ACTIONS.REMOVE_CHANNEL_CHECKED_ROW_KEYS: {
      const checkedRowsSet = new Set(state.channelCheckedRows)
      const removingSet = new Set([].concat(action.payload))
      const difference = new Set([...checkedRowsSet].filter((key) => { return !removingSet.has(key) }))
      return { channelCheckedRows: Array.from(difference), channelUncheckedRows: Array.from(removingSet).concat(state.channelUncheckedRows || []) }
    }
    case ACTIONS.RESET:
      return initialState
    default:
      return state
  }
}

const priorityReducer = (state, action) => {
  state[action.key] = action.priority
  return state
}

const EditDeploymentGroupModal = ({ modalVisibility, setModalVisibility, initialValues = {}, triggerSearch: triggerSearchDeploymentGroups }) => {
  const [{ featuresCheckedRows, channelCheckedRows, channelUncheckedRows }, dispatch] = useReducer(reducer, initialState)
  const [isFeaturesRowDirty, setIsFeaturesRowDirty] = useState(false)
  const [isChannelRowDirty, setIsChannelRowDirty] = useState(false)
  const [isPriorityRowDirty, setIsPriorityRowDirty] = useState(false)
  const [selectedFeatureKeys, setSelectedFeatureKeys] = useState([])
  const [selectedChannelKeys, setSelectedChannelKeys] = useState([])
  const [{ loading, data: featuresData }] = useRequest(Api.FeatureFlags.findMany, shallowCleanFalsyObject({ showAll: true }))
  const [{ data: channelsData }] = useRequest(Api.ClientGroups.searchAvailableChannels,
    shallowCleanFalsyObject({ environment: initialValues?.environment }))
  const [selectedPriority, priorityDispatch] = useReducer(priorityReducer, {})
  const [hasPriorityError, setHasPriorityError] = useState(false)

  const [tab, setTab] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : TAB_KEYS.FEATURES,
  )

  const validatePriority = (newValue) => {
    const isValidPriority = Object.values(selectedPriority)
    .concat(newValue)
    .filter((value) => {
      return value
    })
    .every((value) => {
      return Number.isInteger(value) && value >= 0 && value <= 100
    })
    setHasPriorityError(!isValidPriority)
  }

  const channelColumnsTable = [
    {
      title: 'Channel Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (value, record) => {
        return (
          <InputNumber
            defaultValue={initialValues?.latestChannels?.[record.key]?.priority || 0}
            size='small'
            style={{ resize: 'vertical' }}
            rows={1}
            onChange={(newValue) => {
              setIsPriorityRowDirty(true)
              priorityDispatch({ key: record.key, priority: newValue })
              return validatePriority(newValue)
            }}
          />
        )
      },
    },
  ]

  const handleTabChange = (newTab) => {
    const { latestChannels } = initialValues
    if (latestChannels) {
      if (newTab === TAB_KEYS.CHANNELS) {
        dispatch({
          type: ACTIONS.SAVE_CHANNEL_CHECKED_ROW_KEYS,
          payload: Object.keys(latestChannels).filter((channelKey) => { return latestChannels[channelKey].value }),
        })
      }
    }
    window.location.hash = `#${newTab}`
    setTab(newTab)
  }

  useEffect(() => {
    const { latestFeatures } = initialValues
    if (latestFeatures) {
      dispatch({ type: ACTIONS.SAVE_FEATURES_CHECKED_ROW_KEYS, payload: Object.keys(latestFeatures) })
    }

    return () => {
      dispatch({ type: ACTIONS.RESET })
    }
  }, [initialValues.id, initialValues])

  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasSentRequest, setHasSentRequest] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (modalVisibility === true) {
      setHasSentRequest(false)
      setHasError(false)
      setTab(TAB_KEYS.FEATURES)
    }
  }, [modalVisibility])

  const closeModal = () => {
    setConfirmLoading(false)
    setHasSentRequest(false)
    setHasError(false)
    form.resetFields()
    setModalVisibility(false)
    triggerSearchDeploymentGroups()
  }

  const onSubmit = async () => {
    if (!isFeaturesRowDirty && !isChannelRowDirty && !isPriorityRowDirty) {
      closeModal()
      return
    }

    if (hasError) setHasError(false)

    setConfirmLoading(true)
    let response = null
    let payload = {}
    let features
    let channels
    try {
      const { latestFeatures, latestChannels } = initialValues
      if (isFeaturesRowDirty) {
        features = selectedFeatureKeys?.reduce((acc, curr) => {
          acc.features[curr] = true
          return acc
        }, { features: {} })
      } else {
        features = Object.keys(latestFeatures || []).reduce((acc, curr) => {
          acc.features[curr] = true
          return acc
        }, { features: {} })
      }

      if (isChannelRowDirty) {
        channels = Object.assign(selectedChannelKeys?.reduce((acc, curr) => {
          acc[curr] = { value: true }
          return acc
        }, {}),
        channelUncheckedRows?.reduce((acc, curr) => {
          acc[curr] = { value: false }
          return acc
        }, {}))
      } else {
        channels = Object.entries(latestChannels || {}).reduce((acc, [key, curr]) => {
          acc[key] = { value: curr.value || false }
          return acc
        }, {})
      }

      if (isPriorityRowDirty) {
        Object.entries(selectedPriority).forEach(([key, value]) => {
          if (!channels[key]) channels[key] = {}
          channels[key].priority = value
        })
      }
      // preserve unchanged priority even if some row priority changed
      Object.keys(latestChannels || {}).forEach((key) => {
        if (!channels[key]) channels[key] = {}
        if (!Number.isInteger(channels[key].priority)) channels[key].priority = latestChannels?.[key]?.priority || 0
      })

      payload = {
        channels,
        options: features,
      }
      response = await Api.ClientGroups.updateById(initialValues.id, payload)

      if (response?.data?.errorMessage?.toUpperCase() === 'CONFLICT') {
        const conflictError = Error()
        conflictError.status = 409
        throw conflictError
      }
      if (response) {
        form.resetFields()
        setConfirmLoading(false)
        setHasSentRequest(true)
        notification.success({
          message: 'Success',
          description: (
            <>
              <Typography.Text>Only deployment group,</Typography.Text>
              <Typography.Text strong>
                  &nbsp;
                {`'${initialValues.name}'`}
                  &nbsp;
              </Typography.Text>
              <Typography.Text>on</Typography.Text>
              <Typography.Text strong>
                  &nbsp;
                {`'${initialValues.environment}'`}
                  &nbsp;
              </Typography.Text>
              <Typography.Text>will be affected by this edit.</Typography.Text>
            </>
          ),
        })
        closeModal()
        // return response
      }
    } catch (error) {
      console.info('Validate Failed:', error)
      setConfirmLoading(false)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404].indexOf(error.status) !== -1) {
        setHasError(true)
        notification.error({
          duration: null,
          message: 'Error',
          description: 'Something went wrong',
        })
      } else if (error.status && [400].indexOf(error.status) !== -1) {
        setHasError(true)
        notification.error({
          duration: null,
          message: 'Error',
          description: 'Make sure CAP IDs, and Country of Employment are in correct format',
        })
      } else if (error.status && [409].indexOf(error.status) !== -1) {
        setHasError(true)

        if (response?.data?.items) {
          const failedClientGroupsTexts = response.data.items.reduce((acc, curr) => {
            acc.push(`'${curr.name}' ON ${curr.environment}`)
            return acc
          }, [])
          const description = `
          Record with the same Type, CAP IDs, and Country of Employment already exists under the name
          ${failedClientGroupsTexts.join(', ')}
          The client group was not added to any environments, please uncheck the invalid environment before trying to create a client group again.
        `
          notification.error({
            duration: null,
            message: 'Error',
            description,
          })
        }
      }
    }
  }

  const onBack = () => {
    closeModal()
    if (hasSentRequest) {
      triggerSearchDeploymentGroups()
    }
  }

  const featuresRowSelection = {
    type: 'checkbox',
    defaultSelectedRowKeys: featuresCheckedRows,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys) => {
      setIsFeaturesRowDirty(true)
      setSelectedFeatureKeys(selectedRowKeys)
    },
    onSelect: (record, selected) => {
      if (!selected) {
        dispatch({ type: ACTIONS.REMOVE_FEATURES_CHECKED_ROW_KEYS, payload: record.key })
      }
    },
  }

  const channelsRowSelection = {
    type: 'checkbox',
    defaultSelectedRowKeys: channelCheckedRows,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys) => {
      setIsChannelRowDirty(true)
      setSelectedChannelKeys(selectedRowKeys)
    },
    onSelect: (record, selected) => {
      if (!selected) {
        dispatch({ type: ACTIONS.REMOVE_CHANNEL_CHECKED_ROW_KEYS, payload: record.key })
      }
    },
  }

  if (loading) return <LoadingSkeleton />
  return (
    <Modal
      // forceRender
      title={(
        <>
          <Typography.Text>Edit</Typography.Text>
          &nbsp;
          <Typography.Text
            type='secondary'
          >
            {`(${initialValues.name})`}
          </Typography.Text>
        </>
      )}
      visible={modalVisibility}
      onOk={onSubmit}
      okText='Submit'
      confirmLoading={confirmLoading}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button
          key='back'
          onClick={onBack}
        >
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={confirmLoading}
          disabled={hasPriorityError}
          onClick={onSubmit}
        >
          Save
        </Button>,
      ]}
      centered
      width='75vw'
      bodyStyle={{
        height: '75vh',
      }}
      style={{
        transition: 'none',
        animationDuration: '0s',
      }}
    >
      <Content>
        <Tabs
          activeKey={tab}
          onChange={handleTabChange}
        >
          <TabPane key={TAB_KEYS.FEATURES} tab='Features'>
            <Table
              columns={featureColumnsTable}
              dataSource={featuresData?.items}
              scroll={{
                scrollToFirstRowOnChange: true,
                y: 500,
              }}
              rowSelection={featuresRowSelection}
              rowKey={(record) => { return record.key }}
            />
          </TabPane>
          <TabPane key={TAB_KEYS.CHANNELS} tab='Channels'>
            <Table
              columns={channelColumnsTable}
              dataSource={channelsData}
              scroll={{
                scrollToFirstRowOnChange: true,
                y: 500,
              }}
              rowSelection={channelsRowSelection}
              rowKey={(record) => { return record.key }}
            />
            {hasPriorityError && <Typography.Text type='danger'>The priority value has to be a whole number or in a valid range or 0 to 100.</Typography.Text>}
          </TabPane>
        </Tabs>
      </Content>
    </Modal>
  )
}

EditDeploymentGroupModal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf({}).isRequired,
}

export default EditDeploymentGroupModal
