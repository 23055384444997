import React, { useEffect } from 'react'
import { Spin } from 'antd'
import { useSelector } from 'react-redux'
import StorageUtils from '@core/utils/storage'
import PermittedFeaturesActions from '@core/store/actions/PermittedFeatures'
import AuthActions from '@core/store/actions/Auth'
import DefaultPrivateRouting from './Default'
import VersionCheckpoint from './VersionCheckpoint'

import style from './index.module.scss'

export default () => {
  const { isPending } = useSelector(({ core }) => { return core.permittedFeatures })
  useEffect(() => {
    const refresh_token = StorageUtils.getItemFromSession('refresh_token')
    const access_token = StorageUtils.getItemFromSession('access_token')

    const getDeployedVersion = () => {
      return process.env.REACT_APP_GIT_HEAD ?? 'N/A'
    }

    const fetchFeaturePermission = async () => {
      if (refresh_token && access_token) {
        await PermittedFeaturesActions.fetch()
      }
    }

    const synchronizeToken = async () => {
      if (refresh_token && access_token) {
        await AuthActions.synchronizeToken({ refresh_token, access_token })
      }
    }

    window.getDeployedVersion = getDeployedVersion
    window.dashboardVersion = process.env.REACT_APP_GIT_HEAD ?? 'N/A'

    synchronizeToken()
    fetchFeaturePermission()
  }, [])

  if (isPending) {
    return (
      <div className={style.Permission__pending}>
        <Spin size='large' />
      </div>
    )
  }

  if (!StorageUtils.getItemFromSession('version')) return (<VersionCheckpoint />)
  return <DefaultPrivateRouting />
}
