import React from 'react'
import { useSelector } from 'react-redux'
import { Form, Divider, Space, Radio } from 'antd'
import { setFilterSourceType, sourceType } from '../../../types/sourceFilter'

const { Item } = Form

const SourceFilter = ({ source, setFilterSource }) => {
  const { sources } = useSelector(({ data }) => { return { ...data.searches } })

  const handleSizeChange = (value) => { return setFilterSource(value.target.value) }

  return (
    <>
      <Divider>
        <small><strong>By Source</strong></small>
      </Divider>
      <Space direction='vertical'>
        <Item>
          <Radio.Group value={source} onChange={handleSizeChange} size='small'>
            {sources.map((item, index) => { return <Radio.Button key={index} value={item.value}>{item.title}</Radio.Button> })}
          </Radio.Group>
        </Item>
      </Space>
    </>
  )
}

SourceFilter.propTypes = {
  setFilterSource: setFilterSourceType.isRequired,
  source: sourceType.isRequired,
}

export default SourceFilter
