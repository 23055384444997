import PropTypes from 'prop-types'

const {
  string,
  objectOf,
  node,
  arrayOf,
} = PropTypes

export const titleType = string
export const tabContentType = objectOf(node)
export const tabListType = arrayOf(objectOf(string))
