import React from 'react'
import {
  Layout,
  Typography,
  Table,
  Button,
  Popconfirm,
  notification,
} from 'antd'
import { useHistory, useRouteMatch } from 'react-router-dom'
import useRequest from '@core/hooks/useRequest'
import { getCurrentFaqVersionId } from '@core/helpers/faqVersions'
import Api from '../../../Faqs/api'

export default () => {
  const faqVersionId = getCurrentFaqVersionId()
  const [{ loading, data, hasError }, { makeRequest }] = useRequest(Api.getVersions)
  const { path } = useRouteMatch()
  const history = useHistory()

  const duplicateVersion = ({ id }) => {
    return async () => {
      try {
        const response = await Api.duplicateVersion(id)

        if (response) {
          notification.success({
            message: 'Successfully duplicated version',
            duration: 5,
          })

          makeRequest()
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Failed duplicated version',
          duration: 5,
        })
      }
    }
  }

  const reindexVersion = ({ id }) => {
    return async () => {
      try {
        const response = await Api.reindexVersion(id)

        if (response) {
          notification.success({
            message: 'Successfully reindexed questions in this version',
            duration: 5,
          })

          makeRequest()
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Failed reindexed questions in this version',
          duration: 5,
        })
      }
    }
  }

  return (
    <Layout.Content>
      <Typography.Title level={4}>FAQ Versions</Typography.Title>
      {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      {data && (
        <Table
          loading={loading}
          columns={[
            {
              title: 'Version',
              dataIndex: 'id',
              render: (id) => { return <Typography.Text strong>{faqVersionId === id ? `${id} (Current)` : id}</Typography.Text> },
            },
            {
              title: 'Note',
              dataIndex: 'note',
            },
            {
              title: 'Actions',
              key: 'actions',
              fixed: 'right',
              render: (version) => {
                return (
                  <>
                    <Popconfirm
                      title='Confirm reindex data in this version?'
                      onConfirm={reindexVersion(version)}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button type='link'>
                        Reindex
                      </Button>
                    </Popconfirm>
                    <Button type='link' onClick={() => { history.push(`${path}/${version.id}`) }}>
                      View
                    </Button>
                    <Popconfirm
                      title='Confirm Clone version?'
                      onConfirm={duplicateVersion(version)}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button type='link'>
                        Duplicate
                      </Button>
                    </Popconfirm>
                    <Button type='link' onClick={() => { history.push(`${path}/${version.id}/edit`) }}>
                      Edit
                    </Button>
                  </>
                )
              },
            },
          ]}
          dataSource={data.items ?? []}
        />
      )}
    </Layout.Content>
  )
}
