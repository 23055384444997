import React, { useState } from 'react'
import { Form, Spin, Divider, Switch } from 'antd'

import useSymbolizer from '@core/hooks/useSymbolizer'
import TaggerSelect from '@core/components/TaggerFormItems/TaggerSelect'
import TaggerExpectedResult from '../../TaggerFormItems/TaggerExpectedResult'
import TaggerPhraseToMatch from '../../TaggerFormItems/TaggerPhraseToMatch'
import TaggerProcessedSentenceDisplay from '../../TaggerFormItems/TaggerProcessedSentenceDisplay'
import TaggerNote from '../../TaggerFormItems/TaggerNote'

import { formInstanceType, sentenceType } from '../../../types/form'

const TaggerForm = ({ form, sentence }) => {
  const [{ loading }, analysis] = useSymbolizer(sentence)
  const { desymbolizedSplitted } = analysis
  const [selectedPhrase, setSelectedPhrase] = useState(null)
  const onSelectPhrase = (item) => {
    const fields = form.getFieldsValue()
    setSelectedPhrase(item)
    form.setFieldsValue({ ...fields, phrase: JSON.stringify(item) })
  }
  return (
    <Form
      form={form}
      layout='vertical'
      name='form__test_tagger'
      initialValues={{
        expected_result: 'tagger-pass',
      }}
      onValuesChange={(e) => {
        if (e.phrase) setSelectedPhrase(JSON.parse(e.phrase))
      }}
    >
      {loading && <Spin />}
      {!loading && desymbolizedSplitted && (
        <>
          <TaggerProcessedSentenceDisplay
            sentence={sentence}
            desymbolizedSplitted={desymbolizedSplitted}
            selectedPhrase={selectedPhrase}
            onSelectPhrase={onSelectPhrase}
          />
          <Divider />
          <TaggerPhraseToMatch rules={[{ required: true, message: 'Select the [index] Phrase/Word' }]} items={desymbolizedSplitted} />
          <TaggerSelect onlyEntityTaggers rules={[{ required: true, message: 'Please selected Target tagger type' }]} />
          <TaggerExpectedResult rules={[{ required: true, message: 'Please enter phrase index' }]} />
          <Divider />
          <Form.Item name='skip' label='Skip test for now' valuePropName='checked'><Switch /></Form.Item>
          <TaggerNote />
        </>
      )}
    </Form>
  )
}

TaggerForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
}

export default TaggerForm
