import React, { useState, useEffect } from 'react'
import {
  Form,
  Modal,
  Button,
  Typography,
  Divider,
  Table,
  notification,
} from 'antd'

import PropTypes from 'prop-types'

import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import { shallowCleanFalsyObject, sortTextOrNull } from '@core/helpers'

import useRequest from '@core/hooks/useRequest'

import Api from '../../api'

const columnsTable = [
  {
    title: 'Deployment Groups',
    dataIndex: 'name',
    key: 'name',
  },
]

const AssignFeatureFlagToClientGroupsModal = ({
  modalVisibility,
  setModalVisibility,
  initialValues = {},
  triggerSearch: triggerSearchDeploymentGroups,
  environment,
}) => {
  const [isRowDirty, setIsRowDirty] = useState(false)
  const [selectedClientGroupIds, setSelectedClientGroupIds] = useState([])
  const query = { environment, showAll: true }
  const [{ loading, data }] = useRequest(Api.ClientGroups.findMany, shallowCleanFalsyObject(query))

  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasSentRequest, setHasSentRequest] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (modalVisibility === true) {
      setHasSentRequest(false)
      setHasError(false)
    }
  }, [modalVisibility])

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasSentRequest(false)
    setHasError(false)
    form.resetFields()
    triggerSearchDeploymentGroups()
  }

  const onSubmit = async () => {
    if (!isRowDirty) {
      closeModal()
      return
    }

    const clientGroupsLookup = data?.items?.reduce((acc, curr) => {
      acc[curr.id] = {
        id: curr.id,
        latestFeatures: curr.latestFeatures,
      }
      return acc
    }, {})
    const clientGroupsToBeUpdated = []
    const currentFeatureFlagKey = initialValues.key
    selectedClientGroupIds.forEach((id) => {
      const previousConfiguredValue = clientGroupsLookup[id]?.latestFeatures?.[currentFeatureFlagKey]?.value
      const isPreviousValueNotTrue = previousConfiguredValue !== true
      if (isPreviousValueNotTrue) {
        const transformedLatestFeatures = Object.values(clientGroupsLookup[id]?.latestFeatures || {})
        .reduce((acc, item) => {
          acc[item.key] = item.value
          return acc
        }, {})
        const featuresToBeUpdated = {
          ...transformedLatestFeatures,
          [currentFeatureFlagKey]: true,
        }
        clientGroupsToBeUpdated.push({
          clientGroupId: id,
          featureFlagIds: [initialValues.id],
          options: {
            features: featuresToBeUpdated,
          },
        })
      }
    })

    const selectedClintGroupIdsSet = new Set(selectedClientGroupIds)
    const allLoadedClientGroups = Object.keys(clientGroupsLookup)
    const unselectedClientGroupIds = Array.from(
      new Set(allLoadedClientGroups.filter((id) => { return !selectedClintGroupIdsSet.has(id) })),
    )

    unselectedClientGroupIds.forEach((id) => {
      const isNoLinkedFeaturesFromClientGroup = !clientGroupsLookup[id]?.latestFeatures
      if (isNoLinkedFeaturesFromClientGroup) return

      const previousConfiguredValue = clientGroupsLookup[id]?.latestFeatures?.[currentFeatureFlagKey]?.value
      const isPreviousValueNotFalse = previousConfiguredValue !== false
      if (isPreviousValueNotFalse) {
        const transformedLatestFeatures = Object.values(clientGroupsLookup[id]?.latestFeatures || {})
        .reduce((acc, item) => {
          if (item.key !== currentFeatureFlagKey) {
            acc[item.key] = item.value
          }
          return acc
        }, {})
        const featuresToBeUpdated = transformedLatestFeatures
        clientGroupsToBeUpdated.push({
          clientGroupId: id,
          featureFlagIds: [initialValues.id],
          options: {
            features: featuresToBeUpdated,
          },
        })
      }
    })

    if (hasError) setHasError(false)

    setConfirmLoading(true)
    let response = null
    try {
      response = await Api.ClientGroups.updateManyAssignedFeatures(clientGroupsToBeUpdated)

      if (response?.data?.errorMessage?.toUpperCase() === 'CONFLICT') {
        const conflictError = Error()
        conflictError.status = 409
        throw conflictError
      }
      if (response) {
        form.resetFields()
        setConfirmLoading(false)
        setHasSentRequest(true)
        notification.success({
          message: 'Success',
          description: (
            <>
              <Typography.Text>Only feature,</Typography.Text>
              <Typography.Text strong>
                  &nbsp;
                {`'${initialValues.name}'`}
                  &nbsp;
              </Typography.Text>
              <Typography.Text>on</Typography.Text>
              <Typography.Text strong>
                  &nbsp;
                {`'${environment}'`}
                  &nbsp;
              </Typography.Text>
              <Typography.Text>will be affected by this edit.</Typography.Text>
            </>
          ),
        })
        closeModal()
        // return response
      }
    } catch (error) {
      console.info('Validate Failed:', error)
      setConfirmLoading(false)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) {
        setHasError(true)
        notification.error({
          duration: null,
          message: 'Error',
          description: 'Something went wrong',
        })
      } else if (error.status && [409].indexOf(error.status) !== -1) {
        setHasError(true)

        if (response?.data?.items) {
          const failedClientGroupsTexts = response.data.items.reduce((acc, curr) => {
            acc.push(`'${curr.name}' ON ${curr.environment}`)
            return acc
          }, [])
          const description = `
          Record with the same Type, CAP IDs, and Country of Employment already exists under the name
          ${failedClientGroupsTexts.join(', ')}
          The client group was not added to any environments, please uncheck the invalid environment before trying to create a client group again.
        `
          notification.error({
            duration: null,
            message: 'Error',
            description,
          })
        }
      }
    }
  }

  const onBack = () => {
    closeModal()
    if (hasSentRequest) {
      triggerSearchDeploymentGroups()
    }
  }

  const linkedClientGroupIds = initialValues?.linkedClientGroups?.map((linkedClientGroup) => {
    return linkedClientGroup.id
  })

  const rowSelection = {
    type: 'checkbox',
    defaultSelectedRowKeys: linkedClientGroupIds,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys) => {
      setIsRowDirty(true)
      setSelectedClientGroupIds(selectedRowKeys)
    },
  }

  if (loading) return <LoadingSkeleton />

  const sortedClientGroups = data?.items?.slice()?.sort((a, b) => {
    return sortTextOrNull(a.name, b.name)
  })

  return (
    <Modal
      title={(
        <>
          <Typography.Text>Assign</Typography.Text>
          &nbsp;
          <Typography.Text
            type='secondary'
          >
            {`(${initialValues.name})`}
          </Typography.Text>
        </>
      )}
      visible={modalVisibility}
      onOk={onSubmit}
      confirmLoading={confirmLoading}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button
          key='back'
          onClick={onBack}
        >
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={confirmLoading}
          onClick={onSubmit}
        >
          Save
        </Button>,
      ]}
      centered
      width='75vw'
      bodyStyle={{
        height: '75vh',
      }}
      style={{
        transition: 'none',
        animationDuration: '0s',
      }}
    >
      <Typography.Text>Deployment Groups</Typography.Text>
      <Divider style={{ border: 'none' }} />
      <Table
        columns={columnsTable}
        dataSource={sortedClientGroups}
        meta={data?.meta}
        pagination={{
          showSizeChanger: false, // this cause bugs when it changed without syncing with meta.per_page
          showTotal: (total, range) => { return `${range[0]}-${range[1]} of ${total} items` },
          current: data?.meta?.page,
          defaultPageSize: data?.meta?.per_page,
          total: data?.meta?.total_count,
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          y: 500,
        }}
        rowSelection={rowSelection}
        rowKey={(record) => { return record.id }}
      />
    </Modal>
  )
}

AssignFeatureFlagToClientGroupsModal.propTypes = {
  environment: PropTypes.string.isRequired,
  modalVisibility: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf({}).isRequired,
}

export default AssignFeatureFlagToClientGroupsModal
