import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Input,
  Button,
  Tabs,
  Typography,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import style from './index.module.scss'

const MultilineResponse = ({ name, lines }) => {
  return (
    <Form.List
      name={name}
      initialValue={lines}
      rules={[
        {
        // global validator
          validator: async (_, values) => {
            if (!values) {
              return Promise.reject(new Error('At least one variation required'))
            }

            return values
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            {fields.map((field, index) => {
              return (
                <div style={{ display: 'flex' }} key={field.key}>
                  <div style={{ width: '5%' }}>
                    {`${index + 1}.`}
                  </div>
                  <div style={{ width: '85%' }}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onSubmit']}
                      key={field.key}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: index === 0 ? 'Enter a response' : 'Enter a response or remove this field',
                        },
                      ]}
                    >
                      <Input placeholder='Answer' />
                    </Form.Item>
                  </div>
                  {index !== 0 && (
                    <MinusCircleOutlined
                      className={style.btn__Splitline_delete}
                      onClick={() => { return remove(field.name) }}
                    />
                  )}
                </div>
              )
            })}
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => { return add() }}
                style={{ width: '100%' }}
                icon={<PlusOutlined />}
              >
                Add split line
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )
      }}
    </Form.List>
  )
}
MultilineResponse.defaultProps = {
  lines: [''],
}

MultilineResponse.propTypes = {
  name: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(PropTypes.string),
}

const buildInitialPanes = (choices) => {
  const { data: variations } = choices // data is array of object contains "lines"
  const panes = []

  for (let index = 0; index < variations.length; index++) {
    const variation = variations[index]

    panes.push({
      title: index + 1,
      content: <MultilineResponse name={`directResponse.${index}.${Date.now()}`} lines={variation.lines} />,
      key: index,
      closable: false,
    })
  }

  return panes
}

const DirectChoiceResponseVariations = ({ choices }) => {
  const [activeKey, setActiveKey] = useState('0') // antd forced to string
  const [panes, setPanes] = useState(buildInitialPanes(choices))

  const add = () => {
    const prevPanes = panes
    setPanes((prev) => {
      return ([
        ...prev,
        {
          title: `${prev.length + 1}`,
          // seems like a problem on the antd
          // if the old value was added again (same name), it retrives the 'actually' old value
          // seems like antd NEVER clear the context inside antd?
          // but rather keep it there...
          // put a unique timestamp in the name fixes the issue
          // or probably more like stale value?
          // on all previous code is working because activeKey was add into the name and it keeps increasing
          content: (
            <MultilineResponse
              name={`directResponse.${prev.length === 1 ? 1 : prev.length + 1}.${Date.now()}`}
            />
          ),
          key: `${prev.length === 1 ? 1 : prev.length + 1}`,
          closable: true,
        },
      ])
    })
    setActiveKey(`${prevPanes.length === 1 ? 1 : prevPanes.length + 1}`)
  }

  const remove = (targetKey) => {
    let newActiveKey = activeKey
    let lastIndex
    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1
      }
    })

    const newPanes = panes
    .filter((pane) => { return pane.key !== targetKey })
    .map((pane, index) => {
      return {
        title: `${index + 1}`,
        content: pane.content,
        closable: index !== 0,
        key: `${index}`,
      }
    })

    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key
      } else {
        // this seemes like will never occur...
        // unless every tab is closable
        newActiveKey = newPanes[0].key
      }
    }

    setPanes(newPanes)
    setActiveKey(newActiveKey)
  }

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const onEdit = (targetKey, receivedAction) => {
    const actions = { remove } // anything else? antd did not document this AT ALL

    actions[receivedAction](targetKey)
  }

  return (
    <>
      <Typography.Text className={style.variations_label}>Direct Response Variations</Typography.Text>
      <Tabs
        hideAdd
        type='editable-card'
        onChange={onChange}
        onEdit={onEdit}
        activeKey={activeKey}
        defaultActiveKey='0'
        tabBarExtraContent={(<Button icon={<PlusOutlined />} type='link' onClick={add} />)}
      >
        {panes.map((pane) => {
          return (
            <Tabs.TabPane tab={pane.title} key={pane.key} closable={pane.closable} forceRender>
              {pane.content}
            </Tabs.TabPane>
          )
        })}
      </Tabs>
    </>
  )
}

DirectChoiceResponseVariations.defaultProps = {
  choices: {
    data: [],
  },
}

DirectChoiceResponseVariations.propTypes = {
  choices: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default DirectChoiceResponseVariations
