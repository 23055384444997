import React, { useState, useEffect } from 'react'
import ConversationPage from '@core/components/ConversationPage'
import { getComputedConversationMessages } from '@core/helpers'
import PropTypes from 'prop-types'
import { notification, Spin } from 'antd'
import { useUser } from '../../../../../hooks/useUser'
import Api from '../../../../../api'

const openErrorLoadMessagesNotification = (error) => {
  notification.error({
    message: `[CONVERSATION] ${error.message}`,
    description: error.data?.status,
  })
}

const ConversationPanel = ({ conversationId }) => {
  const [messages, setMessages] = useState([])
  const { environment } = useUser()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!conversationId) {
      return () => { }
    }
    let isMount = true
    const getConversations = async () => {
      setIsLoading(true)
      try {
        const { data: { items } } = await Api.User.getConversationIdFromLEId(conversationId, { datasource: environment.toLowerCase() })
        const convoId = items?.[0]?.id
        if (!convoId) {
          setIsLoading(false)
          openErrorLoadMessagesNotification({ message: 'Fail to find conversationId from le_id' })

          return
        }

        const { data } = await Api.User.getOneConversation(convoId, { datasource: environment.toLowerCase() })
        if (!isMount) return
        setMessages(data?.messages ? getComputedConversationMessages(data.messages) : [])
      } catch (error) {
        if (!isMount) return
        setMessages([])
        openErrorLoadMessagesNotification(error)
      }
      setIsLoading(false)
    }

    getConversations()

    return () => {
      isMount = false
    }
  }, [conversationId, environment])

  if (!conversationId) {
    return <div>No open conversations.</div>
  }

  return (
    <div style={{ maxHeight: '250px', overflowY: 'scroll' }}>
      {isLoading
        ? <div style={{ display: 'flex', justifyContent: 'center' }}><Spin /></div>
        : <ConversationPage messages={messages} />}
    </div>
  )
}

ConversationPanel.propTypes = {
  conversationId: PropTypes.string.isRequired,
}

export default ConversationPanel
