export const generateGrafanaLokiLink = ({ labels, from, to }) => {
  // eslint-disable-next-line prefer-template
  const labelQuery = Object.entries(labels).map(([key, value]) => {
    return (`${key}="${value}"`)
  }).join(', ')
  // eslint-disable-next-line prefer-template
  const query = '{' + labelQuery + '}'

  const panes = {
    j8h: {
      datasource: '8YtBjkXnk',
      queries: [
        {
          refId: 'Louise - Conversation Manager - User',
          expr: query,
          queryType: 'range',
          datasource: {
            type: 'loki',
            uid: '8YtBjkXnk',
          },
          editorMode: 'builder',
        },
      ],
      range: {
        from: Math.floor(from).toString(),
        to: Math.floor(to).toString(),
      },
    },
  }

  const stringPanes = JSON.stringify(panes)
  const url = new URL('https://grafana.messaging.gbtconnect.com/explore')
  url.searchParams.set('panes', stringPanes)
  url.searchParams.set('schemaVersion', '1')
  url.searchParams.set('orgId', '1')

  return url.toString()
}
