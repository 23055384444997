import callApi from '@core/utils/http-client'
import { getBaseUrlCanary, getBaseUrlStaging, getBaseUrlProduction } from '@core/helpers/gbtCloudVariableSwitcher'

const getEnvBaseUrl = {
  production: getBaseUrlProduction('https://hermes.messaging.gbtconnect.com'),
  staging: getBaseUrlStaging('https://hermes.staging.messaging.gbtconnect.com'),
  canary: getBaseUrlCanary('https://hermes.canary.messaging.gbtconnect.com'),
  development: process.env.REACT_APP_BASE_URL || 'http://localhost:3000',
}

export default {
  getAllConversationIds: (data, env) => {
    return callApi({
      baseURL: getEnvBaseUrl[env],
      url: '/api/v1/conversation-analysis/conversations',
      method: 'post',
      data,
    })
  },
  getStateHierarchy: (env, { stateKeyCategories, dateRange }) => {
    return callApi({
      baseURL: getEnvBaseUrl[env],
      url: '/api/v1/conversation-analysis/states/hierarchy',
      method: 'post',
      data: {
        stateKeyCategories, dateRange,
      },
    })
  },
  getConversationById: (conversationId, env) => {
    return callApi({
      baseURL: getEnvBaseUrl[env],
      url: `/api/v1/conversation-analysis/conversations/${conversationId}`,
      method: 'get',
    })
  },
  getStatesAfterIntents: (data, env) => {
    return callApi({
      baseURL: getEnvBaseUrl[env],
      url: '/api/v1/conversation-analysis/states/intents/after',
      method: 'post',
      data,
    })
  },
  getStatesBeforeIntents: (data, env) => {
    return callApi({
      baseURL: getEnvBaseUrl[env],
      url: '/api/v1/conversation-analysis/states/intents/before',
      method: 'post',
      data,
    })
  },
  getIntentStructures: (data, env) => {
    return callApi({
      baseURL: getEnvBaseUrl[env],
      url: '/api/v1/conversation-analysis/intents/structures',
      method: 'post',
      data,
    })
  },
  getVocabularyPerIntent: (data, env) => {
    return callApi({
      baseURL: getEnvBaseUrl[env],
      url: '/api/v1/conversation-analysis/intents/vocabulary',
      method: 'post',
      data,
    })
  },
  updateStatus: (data, env) => {
    return callApi({
      baseURL: getEnvBaseUrl[env],
      url: '/api/v1/conversation-analysis/status/',
      method: 'post',
      data,
    })
  },
}
