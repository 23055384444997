import React from 'react'
import PropTypes from 'prop-types'
import { Select, Form } from 'antd'
import { STATUSES } from '../../../constant/utterances'

const TrainingStatus = ({ multiple, rules }) => {
  return (
    <Form.Item
      name='training_status'
      rules={rules}
    >
      <Select
        mode={multiple ? 'multiple' : undefined}
        placeholder='Select status(es)'
        allowClear
      >
        {!STATUSES.length && <Select.Option value='' disabled>---</Select.Option>}
        {STATUSES.length && STATUSES.map(({ label, value }) => {
          return (
            <Select.Option
              key={label}
              value={value}
            >
              {label}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

TrainingStatus.defaultProps = {
  multiple: true,
  rules: [],
}

TrainingStatus.propTypes = {
  multiple: PropTypes.bool,
  rules: PropTypes.arrayOf(PropTypes.shape({
    required: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  })),
}

export default TrainingStatus
