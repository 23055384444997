import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Typography,
  Spin,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../api'

const { Title, Text } = Typography

const DatasetExpandedRow = ({ domain, intent }) => {
  const [
    {
      loading,
      data: dataset,
      hasError,
    },
  ] = useRequest(Api.getDataset, { domain, intent })

  return (
    <Row gutter={[24, 16]}>
      {loading && <Spin />}
      {hasError && <Col span={24}><Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text></Col>}
      {dataset && (
        <>
          <Col span={12}>
            <Title level={5}>Training Set</Title>
            {dataset.training.map((sentence, i) => {
              return (
                <div key={sentence.id}>
                  <Text>{`${i + 1}. ${sentence.text}`}</Text>
                </div>
              )
            })}
          </Col>
          <Col span={12}>
            <Title level={5}>Test Set</Title>
            {dataset.test.map((sentence, i) => {
              return (
                <div key={sentence.id}>
                  <Text>{`${i + 1}. ${sentence.text}`}</Text>
                </div>
              )
            })}
          </Col>
        </>
      )}
    </Row>
  )
}

DatasetExpandedRow.propTypes = {
  domain: PropTypes.string,
  intent: PropTypes.string,
}

DatasetExpandedRow.defaultProps = {
  domain: '',
  intent: '',
}

export default DatasetExpandedRow
