import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Form, Input, Button, Descriptions, Modal, Col, Row, Typography, Divider, Space } from 'antd'

import SentencesActions from '../../store/actions/sentences'
import EditSentenceModalActions from '../../store/actions/EditSentenceModal'

const { Text } = Typography

const EditSentenceModal = () => {
  const { isPending, hasError } = useSelector(({ data }) => { return { ...data.sentences } })
  const editSentenceModalData = useSelector(({ data }) => { return (data.editSentenceModal) })

  const [form] = Form.useForm()
  const onSubmit = async (value) => {
    try {
      const status = await SentencesActions.edit(editSentenceModalData.data.id, { text: value.sentence })
      if (status.success) EditSentenceModalActions.dismiss()
    } catch (e) {
      EditSentenceModalActions.dismiss()
    }
  }

  useEffect(() => {
    if (editSentenceModalData.data) {
      form.setFieldsValue({
        sentence: editSentenceModalData.data.text,
      })
    }
  }, [editSentenceModalData.data, form])

  if (!editSentenceModalData.visibility) return null

  return (
    <Modal
      title='Edit Sentence'
      visible={editSentenceModalData.visibility}
      onCancel={EditSentenceModalActions.dismiss}
      width={1000}
      centered
      footer={null}
    >
      <Form
        form={form}
        name='Sentence Analysis'
        onFinish={onSubmit}
        initialValues={{
          sentence: editSentenceModalData.data.text,
        }}
      >
        <Space size='large'>
          <Text type='secondary'>{`Sentence ID: ${editSentenceModalData.data.id}`}</Text>
          <Text type='secondary'>{`Source: ${editSentenceModalData.data.source}`}</Text>
        </Space>
        <Descriptions size='small' bordered column={5}>
          <Descriptions.Item label='Text' span={5}>{editSentenceModalData.data.text}</Descriptions.Item>
          <Descriptions.Item label='Generalized' span={5}>{editSentenceModalData.data.generalized}</Descriptions.Item>
        </Descriptions>
        <Divider />
        <Row justify='space-around' align='middle'>
          <Col span={20}>
            <Form.Item
              validateStatus={hasError ? 'error' : null}
              help={hasError ? 'Text already exists in another sentence' : null}
              label='Sentence'
              name='sentence'
              rules={[{ required: true, message: 'Please enter a sentence' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item span={8}>
              <Button type='default' htmlType='submit' loading={isPending}> Update </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Modal>
  )
}

EditSentenceModal.propTypes = {
}

export default EditSentenceModal
