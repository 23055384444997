import React, { useState, useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Select, Form, Typography } from 'antd'
import style from './style.module.scss'

const ExtractorSelector = React.forwardRef(({
  extractorsTypesList,
  forcedExtractor,
  onExtractorSelected,
  onExtractorBlur,
}, ref) => {
  const selectRef = useRef()
  const [shouldOpen, setShouldOpen] = useState()
  const formItemLabel = forcedExtractor
    ? (
      <>
        Tagger type
        &thinsp;
        <Typography.Text type='danger'>Disabled, used from config</Typography.Text>
      </>
    )
    : 'Tagger type'
  const selectPlaceholder = forcedExtractor
    ? 'Selection disable due to form config above'
    : 'Select an Tagger type'

  const openOptions = () => { setShouldOpen(true) }

  const onSelect = () => {
    onExtractorSelected()
    setShouldOpen(false)
  }

  const onBlur = () => {
    onExtractorBlur()
    setShouldOpen(false)
  }

  useImperativeHandle(ref, () => {
    return {
      focusAndOpen: () => {
        selectRef.current.focus()
        setShouldOpen(true) // i think this works... but it needs to handle event now...
      },
    }
  })

  return (
    <Form.Item
      name='extractorType'
      label={formItemLabel}
      rules={[{ required: true, message: 'Please select Tagger type' }]}
    >
      <Select
        ref={selectRef}
        className={style.TargetExtractorType}
        placeholder={selectPlaceholder}
        disabled={forcedExtractor}
        onFocus={openOptions}
        onInputKeyDown={openOptions}
        onSelect={onSelect}
        onBlur={onBlur}
        open={shouldOpen}
        showSearch
      >
        {!extractorsTypesList.length && <Select.Option value='' disabled selected>---</Select.Option>}
        {extractorsTypesList.length && extractorsTypesList.map((extractor) => {
          return (
            <Select.Option value={extractor} key={extractor}>{extractor}</Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
})

ExtractorSelector.defaultProps = {
  extractorsTypesList: [],
}

ExtractorSelector.propTypes = {
  forcedExtractor: PropTypes.bool.isRequired,
  extractorsTypesList: PropTypes.arrayOf(PropTypes.string),
  onExtractorSelected: PropTypes.func.isRequired,
  onExtractorBlur: PropTypes.func.isRequired,
}

export default ExtractorSelector
