import { combineReducers } from 'redux'
import { VERSIONS } from '@core/store/constants'
import { CoreReducer } from '@core/store'
import { DataReducer } from '../../modules/Data/store'
import { SentenceAnalysisReducer } from '../../modules/SentenceAnalysis/store'
import { ExperimentsReducer } from '../../modules/Experiments/store'
import { ConversationAnalysisReducer } from '../../modules/ConversationAnalysis/store'

const combinedAppReducer = combineReducers({
  core: CoreReducer,
  data: DataReducer,
  sentenceAnalysis: SentenceAnalysisReducer,
  experiments: ExperimentsReducer,
  conversationAnalysis: ConversationAnalysisReducer,
})

// NOTE: When user change version, all data in the state machine needs to be reset
export const versionizedAppReducer = (state, action) => {
  if (action.type === VERSIONS.SET) {
    state = {
      core: {
        versions: {
          ...state.core.versions,
          current: action.payload.version,
        },
        ...state.core,
      },
    }
  }
  return combinedAppReducer(state, action)
}

