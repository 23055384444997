import React from 'react'
import PropTypes from 'prop-types'
import JsonPreview from '@core/components/JsonPreview'
import { Table, Switch } from 'antd'

const calculatePrecision = (tp = 0, fp = 0) => {
  if (tp === 0) return 0
  return Number((tp / (tp + fp)).toFixed(4))
}

const calculateRecall = (tp = 0, fn = 0) => {
  if (tp === 0) return 0
  return Number((tp / (tp + fn)).toFixed(4))
}
const MetricTable = ({ taggerWithEvaluation, fullTable, setFullTable }) => {
  const matrixLookup = taggerWithEvaluation?.evaluation?.summary
  const extractLabels = taggerWithEvaluation?.evaluation?.tagged
  const miniColumns = [{
    title: 'Name',
    width: 100,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: 'Correct',
    dataIndex: 'correct',
    key: 'correct',
    width: 120,
  }, {
    title: 'Incorrect',
    dataIndex: 'incorrect',
    key: 'incorrect',
    width: 120,

  }, {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: 120,
  }, {
    title: 'Precision',
    key: 'precision',
    width: 120,
    render: (row) => {
      const summary = matrixLookup?.[row.name]
      if (summary) {
        const { fp, tp } = summary
        return (
          <span>
            {calculatePrecision(tp, fp)}
          </span>
        )
      }
      return <span> None </span>
    },
  },
  {
    title: 'Recall',
    key: 'recall',
    width: 120,
    render: (row) => {
      const summary = matrixLookup?.[row.name]
      if (summary) {
        const { fn, tp } = summary
        return <span>{calculateRecall(tp, fn)}</span>
      }

      return <span> None </span>
    },
  }, {
    title: 'F1',
    key: 'f1',
    width: 120,
    render: (row) => {
      // F1 Score = 2 * (Precision * Recall) / (Precision + Recall)
      const summary = matrixLookup?.[row.name]
      if (summary) {
        const { fn, tp, fp } = summary
        const precision = calculatePrecision(tp, fp)
        const recall = calculateRecall(tp, fn)
        const f1 = (precision && recall) ? 2 * ((precision * recall) / (precision + recall))?.toFixed(4) : 0
        return (
          <span>
            {f1}
          </span>
        )
      }

      return <span> None </span>
    },
  },

  ]
  const columns = [...miniColumns]
  const tableDatas = []
  if (taggerWithEvaluation?.evaluation?.tagged?.length) {
    const scoreMetrics = taggerWithEvaluation?.evaluation?.score
    for (let y = 0; y < scoreMetrics?.length; y++) {
      const dataResults = scoreMetrics[y]
      const cell = { incorrect: 0, correct: 0, total: 0, name: '' }
      for (let x = 0; x < dataResults.length; x++) {
        if (x === 0) {
          cell.name = extractLabels[y]
        }
        if (y === 0) {
          // add name add first column
          columns.push({
            title: extractLabels[x],
            dataIndex: extractLabels[x],
            key: extractLabels[x],
            ellipsis: true,
            width: 130,
          })
        }

        cell[extractLabels[x]] = dataResults[x].count
        if (x === y) {
          cell.correct += dataResults[x].count
        } else {
          cell.incorrect += dataResults[x].count
        }
        cell.total += dataResults[x].count
      }
      tableDatas.push(cell)
    }
  }

  const onChange = (checked) => {
    setFullTable(checked)
  }

  const tableHeader = () => {
    return (
      <>
        <span>Show full table: </span>
        <Switch defaultChecked onChange={onChange} checked={fullTable} />
      </>
    )
  }

  return (
    <>
      {!tableDatas?.length && <JsonPreview content={JSON.stringify(taggerWithEvaluation, null, 2)} autoSize />}
      {!!tableDatas?.length
        && (
          <Table
            title={tableHeader}
            size='small'
            style={{ width: '95vw' }}
            columns={fullTable ? columns : miniColumns}
            dataSource={fullTable ? tableDatas : tableDatas.map((row) => {
              const { name, correct, incorrect, total } = row
              return { name, correct, incorrect, total }
            })}
            scroll={{
              x: 200,
            }}
          />
        )}
    </>
  )
}

MetricTable.propTypes = {
  taggerWithEvaluation: PropTypes.shape({
    label: PropTypes.string.isRequired,
    evaluation: PropTypes.shape({
      tagged: PropTypes.arrayOf(PropTypes.string),
      score: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        count: PropTypes.number,
      }))), // [[{count:00},{count:01}],[{count:10},{count:11}]]
      summary: PropTypes.objectOf(PropTypes.shape({
        tp: PropTypes.number,
        fp: PropTypes.number,
        fn: PropTypes.number,
      })), // {origin:{fn:0, fp:1, fn:2}}
    }).isRequired,
  }).isRequired,
  fullTable: PropTypes.bool.isRequired,
  setFullTable: PropTypes.func.isRequired,
}

MetricTable.defaultProps = {
}

export default MetricTable
