export const STATUSES = [
  {
    label: 'New',
    value: 'NEW',
  },
  {
    label: 'Submitted',
    value: 'SUBMITTED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Excluded',
    value: 'EXCLUDED',
  },
]
