import React, { useState } from 'react'
import { Form, Input, Button, Select, Typography, notification, Cascader } from 'antd'
import {
  domainListType,
  intentCascaderType,
  intentTreeType,
} from '@core/types/domainIntentCollectionTypes'
import GenericLabelOperation from '../GenericLabelOperation'
import IntentActions from '../../store/actions/Intent'

const { Text } = Typography

const AddNewIntentForm = ({ domainList }) => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)

  // eslint-disable-next-line consistent-return
  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        const { name, domain_id } = values
        response = await IntentActions.add({ name, domain_id })

        if (response) {
          form.resetFields()
          notification.success({
            message: 'Successfully added new Intent',
            duration: 10,
          })
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name='intent_addNew'
      onFinish={onFinish}
    >
      <Form.Item
        name='domain_id'
        label='Domain'
        rules={[
          {
            required: true,
            message: 'Please selected domain',
          },
        ]}
      >
        <Select placeholder='Select a domain'>
          {domainList.map((domain, index) => {
            return (
              <Select.Option value={domain.id} key={index}>{domain.name}</Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label='Intent name'
        name='name'
        rules={[{ required: true, message: 'Please enter intent name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Form>
  )
}

AddNewIntentForm.propTypes = {
  domainList: domainListType.isRequired,
}

const RenameIntentForm = ({ intentCascader }) => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)

  // eslint-disable-next-line consistent-return
  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        const { current_intent_path, new_intent_name } = values
        response = await IntentActions.rename(current_intent_path[1], {
          name: new_intent_name,
          domain_id: current_intent_path[0],
        })

        if (response) {
          form.resetFields()
          notification.success({
            message: 'Successfully rename Intent',
            duration: 10,
          })
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      name='intent_rename'
      onFinish={onFinish}
    >
      <Form.Item
        name='current_intent_path'
        label='Select Intent to rename'
      >
        <Cascader
          options={intentCascader}
          placeholder='Select intent'
          show
        />
      </Form.Item>
      <Form.Item
        label='Intent name'
        name='new_intent_name'
        rules={[{ required: true, message: 'Please enter new intent name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Form>
  )
}

RenameIntentForm.propTypes = {
  intentCascader: intentCascaderType.isRequired,
}

const ReassignIntent = ({ domainList, intentCascader }) => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)

  // eslint-disable-next-line consistent-return
  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        const { intent, domain_id } = values
        response = await IntentActions.rename(intent[1], { domain_id })

        if (response) {
          form.resetFields()
          notification.success({
            message: 'Successfully re-assign Intent',
            duration: 10,
          })
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      name='intent_reassign'
      onFinish={onFinish}
    >
      <Form.Item
        name='intent'
        label='Source Intent'
        rules={[{ required: true, message: 'Please select intent' }]}
      >
        <Cascader
          options={intentCascader}
          placeholder='Select intent'
          showSearch
        />
      </Form.Item>
      <Form.Item
        label='Re-Assign to new domain'
        name='domain_id'
        rules={[{ required: true, message: 'Please select new Domain for an Intent to be re-assigned' }]}
      >
        <Select placeholder='Select a domain'>
          {domainList.map((domain, index) => {
            return (
              <Select.Option value={domain.id} key={index}>{domain.name}</Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Form>
  )
}

ReassignIntent.propTypes = {
  domainList: domainListType.isRequired,
  intentCascader: intentCascaderType.isRequired,
}

const DeleteIntentForm = ({ intentTree }) => {
  // erm, just use intentTree
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)

  // eslint-disable-next-line consistent-return
  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        const { intent_ids } = values
        response = await IntentActions.delete(intent_ids)

        if (response) {
          form.resetFields()
          notification.success({
            message: 'Successfully delete Intent',
            duration: 10,
          })
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Form
      form={form}
      layout='vertical'
      name='intent_delete'
      onFinish={onFinish}
    >
      <Form.Item
        name='intent_ids'
        label='Intent to remove'
        rules={[
          {
            required: true,
            message: 'Please selected domain',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select an intent'
          mode='multiple'
        >
          {intentTree.map((domain) => {
            return (
              domain.children.length > 0 && (
                <Select.OptGroup label={domain.title}>
                  {domain.children.map((intent) => {
                    return (
                      <Select.Option value={intent.intentId} key={intent.value}>{`${domain.title} / ${intent.title}`}</Select.Option>
                    )
                  })}
                </Select.OptGroup>
              )
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Form>
  )
}

DeleteIntentForm.propTypes = {
  intentTree: intentTreeType.isRequired,
}

const IntentOperation = ({ domainList, intentCascader, intentTree }) => {
  return (
    <GenericLabelOperation
      title='Intent'
      tabContent={{
        tab_add: <AddNewIntentForm domainList={domainList} />,
        tab_rename: <RenameIntentForm intentCascader={intentCascader} />,
        tab_reassign: <ReassignIntent domainList={domainList} intentCascader={intentCascader} />,
        tab_delete: <DeleteIntentForm intentTree={intentTree} />,
      }}
      tabList={[
        { key: 'tab_add', tab: 'Add' },
        { key: 'tab_rename', tab: 'Rename' },
        { key: 'tab_reassign', tab: 'Re-assign' },
        { key: 'tab_delete', tab: 'Delete' },
      ]}
    />
  )
}

IntentOperation.propTypes = {
  domainList: domainListType.isRequired,
  intentCascader: intentCascaderType.isRequired,
  intentTree: intentTreeType.isRequired,
}

export default IntentOperation
