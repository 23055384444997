import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Table, Typography, Button, Space, Pagination, Spin, Descriptions, Input, Form, Alert, Tooltip } from 'antd'
import { BugOutlined, PartitionOutlined } from '@ant-design/icons'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import dayjs from 'dayjs'

import ConversationGraphModal from '../ConversationGraphModal'
import ConversationAnalysisActions from '../../../store/actions/analytics'

const ANALYSIS_KEY = 'conversation.1'

const DisplayFullConversations = () => {
  const { pageSize, results, currentConversation, isPending, hasError, env } = useSelector((state) => { return state.conversationAnalysis.analytics })
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form] = Form.useForm()

  const canDebugConversation = usePermittedFeaturesLookup('conversations.query.actionDebug')
  const showModal = (conversationId) => {
    setIsModalVisible(true)
    ConversationAnalysisActions.getConversationGraphData(conversationId)
  }

  const onDebugConversation = (conversationId) => {
    history.push(`/conversations/query/${conversationId}?datasource=${env.toLowerCase()}`)
  }

  const columns = [
    {
      key: 'index',
      width: '60px',
      render: (value, item, index) => {
        return (
          <Typography.Text disabled>{(results.meta.page - 1 || 0) * pageSize + index + 1}</Typography.Text>
        )
      },
    },
    {
      title: <b>Entry Text</b>,
      dataIndex: 'original',
      key: 'original',
      width: 'auto',
      ellipsis: true,
      render: (original, record) => {
        return (
          <Tooltip title={record.has_nulls && 'Has null intents'}>
            <Typography.Text mark={record.has_nulls} copyable>{original}</Typography.Text>
          </Tooltip>
        )
      },
    },
    {
      title: <b>Duration</b>,
      key: 'duration',
      width: '80px',
      render: (value, record) => {
        const firstMessage = dayjs(record.first_created_at)
        const lastMessage = dayjs(record.last_created_at)
        const difference = lastMessage.diff(firstMessage, 'minute')
        return (
          <Typography.Text>
            {difference > 0 ? `${difference} min` : `${lastMessage.diff(firstMessage, 'seconds')} s`}
          </Typography.Text>
        )
      },
    },
    {
      title: <b>Last Update</b>,
      dataIndex: 'last_created_at',
      key: 'last_created_at',
      width: '150px',
      render: (last_created_at) => {
        return (
          <Typography.Text>
            {dayjs(last_created_at).format('MMM/DD/YYYY HH:mm')}
          </Typography.Text>
        )
      },
    },
    {
      title: <b>Actions</b>,
      dataIndex: '',
      key: 'actions',
      width: '100px',
      render: (record) => {
        return (
          <Space size='middle'>
            <Button icon={<PartitionOutlined />} type='primary' onClick={() => { return showModal(record.conversation_id) }} />
            {canDebugConversation && <Button icon={<BugOutlined />} type='secondary' onClick={() => { return onDebugConversation(record.conversation_id) }} />}
          </Space>
        )
      },
    },
  ]

  const expandedRowRender = (record) => {
    const { conversation_id, last_created_at, first_created_at, conversation_environment } = record
    const firstMessage = dayjs(first_created_at)
    const lastMessage = dayjs(last_created_at)
    return (
      <Descriptions bordered size='small'>
        <Descriptions.Item label='Environment' span={3}>
          <Typography.Text code>{conversation_environment}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label='Conversation ID' span={3}>
          <Typography.Text code>{conversation_id}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label='First Travelers Message Timestamp' span={1}>
          <Typography.Text code>{firstMessage.format('MMM/DD/YYYY HH:mm')}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label='Last Travelers Message Timestamp' span={1}>
          <Typography.Text code>{lastMessage.format('MMM/DD/YYYY HH:mm')}</Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    )
  }

  return (
    <>
      {
        currentConversation && (
          <ConversationGraphModal
            isModalVisible={isModalVisible}
            closeModal={() => { return setIsModalVisible(false) }}
          />
        )
      }
      <Space direction='vertical' size='large'>
        <Form layout='inline' form={form}>
          {hasError && <Alert closable showIcon message={hasError.message || 'Conversation id invalid or non existant'} type='error' />}
          <Input.Group compact>
            <Form.Item
              label='Look by specific id'
              name='conversation_id'
              hasFeedback
              rules={[
                () => {
                  return {
                    validator(_, value) {
                      if (!value.match(/^[0-9A-F]{8}-?[0-9A-F]{4}-4[0-9A-F]{3}-?[89AB][0-9A-F]{3}-?[0-9A-F]{12}$/i)) {
                        return Promise.reject(new Error('Not a UUID format'))
                      }
                      return Promise.resolve()
                    },
                  }
                },
              ]}
            >
              <Input placeholder='Conversation id (UUID)' style={{ width: '400px' }} />
            </Form.Item>
            <Button icon={<PartitionOutlined />} type='primary' onClick={() => { return showModal(form.getFieldValue('conversation_id')) }}>View Conversation Graph</Button>
            {canDebugConversation && <Button icon={<BugOutlined />} type='secondary' onClick={() => { onDebugConversation(form.getFieldValue('conversation_id')) }}>Debug Conversation</Button>}
          </Input.Group>
        </Form>
        <div style={{ maxHeight: 500, overflow: 'auto' }}>
          {isPending && <Spin />}
          {!isPending && (
            <Table
              rowKey='conversation_id'
              bordered
              dataSource={results.conversations}
              columns={columns}
              expandable={{ expandedRowRender }}
              pagination={false}
              size='small'
            />
          )}
        </div>
        <Pagination
          defaultCurrent={1}
          total={results.meta.totalCount}
          pageSize={pageSize}
          current={results.meta.page || 1}
          showSizeChanger
          onChange={(page, newSize) => {
            if (newSize) ConversationAnalysisActions.setPageSize(newSize)
            ConversationAnalysisActions.selectForAnalyze(ANALYSIS_KEY, { page, pageSize: newSize })
          }}
        />
      </Space>
    </>
  )
}

export default DisplayFullConversations
