import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import { Handle, Position } from 'react-flow-renderer'

const customNodeStyles = {
  background: '#8F00FF',
  color: '#fff',
  maxWidth: '250px',
}

const StateNode = ({ data, isConnectable }) => {
  return (
    <>
      <Card style={customNodeStyles}>
        <p>{data.state_key}</p>
      </Card>
      <Handle type='target' position={data.stateExists ? Position.Top : Position.Left} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
      <Handle type='source' position={Position.Right} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
    </>
  )
}

StateNode.propTypes = {
  data: PropTypes.shape({
    stateExists: PropTypes.bool.isRequired,
    state_key: PropTypes.string.isRequired,
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
}

export default StateNode
