import cloneDeep from 'lodash.clonedeep'
import { SELECTED_SENTENCE } from '../constants'

export const INITIAL_STATE = {
  id: null,
  hasError: { extraction: false, cases: false },
  data: null,
  isPending: false,
  testCases: {
    domain_test_sets: [],
    hashedIndex_domain_test_sets: {},
    intent_test_sets: [],
    hashedIndex_intent_test_sets: {},
    tagger_test_sets: [],
    hashedIndex_tagger_test_sets: {},
    extractor_sets: [],
    hashedIndex_extractor_sets: {},
    generalizer_test_sets: [],
    hashedIndex_generalizer_test_sets: {},
  },
  trainingCases: {
    domain_training_sets: [],
    hashedIndex_domain_training_sets: {},
    intent_training_sets: [],
    hashedIndex_intent_training_sets: {},
    tagger_training_sets: [],
    hashedIndex_tagger_training_sets: {},
  },
  generalized: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECTED_SENTENCE.EDIT.REQUEST: {
      return {
        ...state,
        isPending: true,
      }
    }
    case SELECTED_SENTENCE.EDIT.FAILED: {
      return {
        ...state,
        isPending: false,
      }
    }
    case SELECTED_SENTENCE.EDIT.SUCCESS: {
      // Payload includes id
      return {
        ...state,
        isPending: false,
        ...action.payload,
      }
    }
    case SELECTED_SENTENCE.FETCH.REQUEST:
    case SELECTED_SENTENCE.TEST_CASES.FETCH.REQUEST: {
      return {
        ...state,
        hasError: { extraction: state.hasError.extraction, cases: state.hasError.cases },
        isPending: true,
      }
    }
    case SELECTED_SENTENCE.FETCH.SUCCESS: {
      return {
        ...state,
        isPending: false,
        hasError: { extraction: false, cases: state.hasError.cases },
        id: action.payload.id,
        data: action.payload.data,
        generalized: action.payload.generalized,
      }
    }
    case SELECTED_SENTENCE.FETCH.FAILED: {
      return {
        ...state,
        ...action.payload, // NOTE: payload here is the initial reset one
        hasError: {
          extraction: (action.payload.meta && action.payload.meta.isPartiallyFailed),
          cases: state.hasError.cases,
        },
        isPending: false,
      }
    }
    // NOTE: currently it loads all case here...
    case SELECTED_SENTENCE.TEST_CASES.FETCH.SUCCESS: {
      return {
        ...state,
        isPending: false,
        hasError: { extraction: state.hasError.extraction, cases: state.hasError.cases },
        testCases: action.payload.testCases,
        trainingCases: action.payload.trainingCases,
      }
    }
    case SELECTED_SENTENCE.TEST_CASES.FETCH.FAILED: {
      return {
        ...state,
        hasError: { extraction: state.hasError.extraction, cases: true },
        isPending: false,
      }
    }
    // Add case
    case SELECTED_SENTENCE.TEST_CASES.ADD_DOMAIN.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.ADD_INTENT.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.ADD_TAGGER.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.ADD_EXTRACTOR.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.ADD_GENERALIZER.SUCCESS: {
      const newState = cloneDeep(state)
      const { result, type } = action.payload
      const newReducer = {
        ...newState,
        hasError: { extraction: newState.hasError.extraction, cases: false }, // NOTE: we will need to split it later on between test/training
        testCases: {
          ...newState.testCases,
          [`${type}_test_sets`]: newState.testCases[`${type}_test_sets`].concat([{ index: state.testCases[`${type}_test_sets`].length, ...result }]),
        },
      }
      return newReducer
    }
    // Add case
    case SELECTED_SENTENCE.TRAINING_CASES.ADD_DOMAIN.SUCCESS:
    case SELECTED_SENTENCE.TRAINING_CASES.ADD_INTENT.SUCCESS:
    case SELECTED_SENTENCE.TRAINING_CASES.ADD_TAGGER.SUCCESS:
    case SELECTED_SENTENCE.TRAINING_CASES.ADD_GENERALIZER.SUCCESS: {
      const newState = cloneDeep(state)
      const { result, type } = action.payload
      return {
        ...newState,
        hasError: { extraction: newState.hasError.extraction, cases: false }, // NOTE: we will need to split it later on between test/training
        trainingCases: {
          ...newState.trainingCases,
          [`${type}_training_sets`]: newState.trainingCases[`${type}_training_sets`].concat([{ index: state.trainingCases[`${type}_training_sets`].length, ...result }]),
        },
      }
    }

    case SELECTED_SENTENCE.TEST_CASES.UPDATE_DOMAIN.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.UPDATE_INTENT.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.UPDATE_TAGGER.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.UPDATE_EXTRACTOR.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.UPDATE_GENERALIZER.SUCCESS: {
      const newState = cloneDeep(state)
      const {
        payload: {
          result,
          index,
          type,
        },
      } = action

      if (!result) {
        newState.testCases[`${type}_test_sets`] = []
      } else {
        newState.testCases[`${type}_test_sets`][index] = {
          ...newState.testCases[`${type}_test_sets`][index],
          ...result,
        }
      }

      return {
        ...newState,
        hasError: { extraction: false, cases: false },
        testCases: newState.testCases,
      }
    }
    case SELECTED_SENTENCE.TRAINING_CASES.UPDATE_DOMAIN.SUCCESS:
    case SELECTED_SENTENCE.TRAINING_CASES.UPDATE_INTENT.SUCCESS:
    case SELECTED_SENTENCE.TRAINING_CASES.UPDATE_TAGGER.SUCCESS:
    case SELECTED_SENTENCE.TRAINING_CASES.UPDATE_GENERALIZER.SUCCESS: {
      const newState = cloneDeep(state)
      const {
        payload: {
          result,
          index,
          type,
        },
      } = action

      newState.trainingCases[`${type}_training_sets`][index] = {
        ...newState.trainingCases[`${type}_training_sets`][index],
        ...result,
      }
      return {
        ...newState,
        hasError: { extraction: false, cases: false },
        trainingCases: newState.trainingCases,
      }
    }
    case SELECTED_SENTENCE.TRAINING_CASES.DELETE_DOMAIN.SUCCESS:
    case SELECTED_SENTENCE.TRAINING_CASES.DELETE_INTENT.SUCCESS:
    case SELECTED_SENTENCE.TRAINING_CASES.DELETE_TAGGER.SUCCESS: {
      const { type } = action.payload
      return {
        ...state,
        trainingCases: {
          ...state.trainingCases,
          [`${type}_training_sets`]: action.payload[`${type}_training_sets`],
          [`hashedIndex_${type}_training_sets`]: action.payload[`hashedIndex_${type}_training_sets`],
        },
      }
    }
    case SELECTED_SENTENCE.TEST_CASES.DELETE_DOMAIN.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.DELETE_INTENT.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.DELETE_TAGGER.SUCCESS:
    case SELECTED_SENTENCE.TEST_CASES.DELETE_EXTRACTOR.SUCCESS: {
      const { type } = action.payload
      return {
        ...state,
        testCases: {
          ...state.testCases,
          [`${type}_test_sets`]: action.payload[`${type}_test_sets`],
          [`hashedIndex_${type}_test_sets`]: action.payload[`hashedIndex_${type}_test_sets`],
        },
      }
    }
    default: return state
  }
}
