import React, { Component, useContext } from 'react'
import { PropTypes } from 'prop-types'
import {
  Tabs,
  Button,
  Typography,
  Dropdown,
  Menu,
  Form,
  Input,
  Spin,
} from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { reHashIndex } from '@core/helpers'
import { FaqChoiceCategoriesContext } from '../../../../../helpers/context'
import { getFaqAnswerChoices } from '../../../../../query'

import style from './index.module.scss'

const RespondingAnswer = ({ name, isFallbackTab }) => {
  const selectedFaqChoiceCategory = useContext(FaqChoiceCategoriesContext)
  const initialValues = isFallbackTab ? [selectedFaqChoiceCategory.default_fallback_response] : ['']

  if (!selectedFaqChoiceCategory.default_fallback_response) return null

  return (
    <Form.List
      name={name}
      initialValue={initialValues}
      rules={[
        {
        // global validator
          validator: async (_, values) => {
            if (!values) {
              return Promise.reject(new Error('enter the field or remove it'))
            }

            return values
          },
        },
      ]}
    >
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => {
              return (
                <Form.Item
                  required={false}
                  key={field.key}
                >
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      validateTrigger={['onSubmit']}
                      {...field}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: index === 0 ? 'Enter a default FAQ fallback response' : 'Enter a FAQ text response or remove this field',
                        },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input.TextArea
                        placeholder='Answer'
                        style={{ resize: 'vertical' }}
                        rows={1}
                        maxLength={360}
                        showCount
                      />
                    </Form.Item>
                    {index !== 0 && (
                      <Button
                        type='link'
                        size='small'
                        onClick={() => { return remove(field.name) }}
                        danger
                        style={{ paddingRight: 0 }}
                      >
                        <MinusCircleOutlined className='dynamic-delete-button' />
                      </Button>
                    )}
                  </div>
                </Form.Item>
              )
            })}
            <Button
              type='dashed'
              onClick={() => { return add() }}
              style={{ width: '60%' }}
              icon={<PlusOutlined />}
            >
              Add Split Line Field
            </Button>
          </>
        )
      }}
    </Form.List>
  )
}

RespondingAnswer.propTypes = {
  name: PropTypes.string.isRequired,
  isFallbackTab: PropTypes.bool.isRequired,
}

const initialPanes = [
  {
    title: 'Default Fallback response',
    content: <RespondingAnswer name='fallback_response' isFallbackTab />,
    key: '0',
    closable: false,
  },
]

class ChoicesResponse extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      activeKey: initialPanes[0].key,
      panes: initialPanes,
      availableOptions: [],
      availableOptionsHash: {},
      isFetchingOptions: false,
      selectedOptions: [],
    }
  }

  async componentDidMount() {
    const { choiceCategoryId } = this.props
    this.fetchChoiceCategoryOptions(choiceCategoryId)
  }

  componentDidUpdate(prevProps) {
    const { choiceCategoryId } = this.props
    // Must check Previous one MUST have value to clear it
    // otherwise it goes infinite
    if (prevProps.choiceCategoryId && choiceCategoryId === null) {
      this.clearOptions()
    }

    if (choiceCategoryId && (choiceCategoryId !== prevProps.choiceCategoryId)) {
      this.fetchChoiceCategoryOptions(choiceCategoryId)
    }
  }

  clearOptions = () => {
    this.setState((prevProps) => {
      return {
        activeKey: prevProps.panes[0].key,
        panes: [prevProps.panes[0]],
        availableOptions: [],
        isFetchingOptions: false,
        selectedOptions: [],
      }
    })
  }

  fetchChoiceCategoryOptions = async (choiceCategoryId) => {
    const { faqVersionId } = this.props
    this.setState((state) => {
      return {
        ...state,
        isFetchingOptions: true,
      }
    })

    const choices = await getFaqAnswerChoices(faqVersionId, choiceCategoryId)

    if (choices && choices.length) {
      this.setState(() => {
        return {
          isFetchingOptions: false,
          activeKey: initialPanes[0].key,
          panes: initialPanes,
          availableOptions: choices,
          availableOptionsHash: reHashIndex(choices),
          selectedOptions: [],
        }
      })
    }
  }

  renderTabContent = (activeKey) => {
    return (
      <RespondingAnswer
        activeKey={activeKey}
        name={`choices.${activeKey}`}
        isFallbackTab={false}
      />
    )
  }

  onChange = (activeKey) => {
    this.setState({ activeKey })
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }

  addChoice = (choiceOptionId) => {
    const { panes, availableOptions, availableOptionsHash } = this.state
    const newPanes = [...panes]
    const selectedOption = availableOptions[availableOptionsHash[choiceOptionId]]
    const activeKey = selectedOption.id

    newPanes.push({
      title: selectedOption.name,
      content: this.renderTabContent(activeKey, selectedOption),
      key: activeKey,
      option: selectedOption,
    })

    this.setState((prevState) => {
      return {
        panes: newPanes,
        activeKey,
        selectedOptions: [...prevState.selectedOptions, choiceOptionId],
      }
    })
  }

  // this triggered when tab remove
  remove = (targetKey) => {
    // Tab will always at least has 1 length
    const { panes, activeKey } = this.state
    let newActiveKey = activeKey
    let lastIndex
    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1
      }
    })

    const newPanes = panes.filter((pane) => { return pane.key !== targetKey })
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key
      } else {
        // this seemes like will never occur...
        newActiveKey = newPanes[0].key
      }
    }

    this.setState((prevState) => {
      return {
        panes: newPanes,
        activeKey: newActiveKey,
        selectedOptions: prevState.selectedOptions.filter((id) => { return id !== targetKey }),
      }
    })
  }

  overlayMenuContent = () => {
    const { availableOptions, selectedOptions, isFetchingOptions } = this.state

    if (isFetchingOptions) return <Spin />

    return (
      <Menu
        onClick={(event) => {
          this.addChoice(event.key) // Required, for finding the objects
        }}
      >
        {!availableOptions.length && (<Menu.Item disabled key='1'>No choice(s) available</Menu.Item>)}
        {availableOptions.length ? (
          // NOTE: Need to pass id to keys, antd limits what is passed on the Menu:onClick
          availableOptions.map((option) => {
            return (
              <Menu.Item
                disabled={selectedOptions.indexOf(option.id) !== -1}
                key={option.id}
              >
                {option.name}
              </Menu.Item>
            )
          })
        ) : null}
      </Menu>
    )
  }

  render() {
    const { panes, activeKey, isFetchingOptions } = this.state
    return (
      <>
        <Typography.Text className={style.MainSentence__Label}>Text Response to FAQ</Typography.Text>
        <Tabs
          hideAdd
          type='editable-card'
          onChange={this.onChange}
          activeKey={activeKey}
          onEdit={this.onEdit}
          tabBarExtraContent={(
            <Dropdown overlay={this.overlayMenuContent()}>
              <Button loading={isFetchingOptions} icon={<PlusOutlined />} type='link' />
            </Dropdown>
          )}
        >
          {panes.map((pane) => {
            return (
              <Tabs.TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
                {pane.content}
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </>
    )
  }
}

ChoicesResponse.defaultProps = {
  choiceCategoryId: null,
  faqVersionId: null,
}

ChoicesResponse.propTypes = {
  choiceCategoryId: PropTypes.string,
  faqVersionId: PropTypes.number,
}

export default ChoicesResponse
