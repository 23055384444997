import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AnalysisCollection from './pages/AnalysisCollection'

const AnalysisModule = () => {
  return (
    <Switch>
      <Route path='/analysis' component={AnalysisCollection} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/analysis',
    component: AnalysisModule,
  },
  name: 'Analysis',
}

export default AnalysisModule
