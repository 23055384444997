import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ClientGroupIndex from './pages/ClientGroupIndex'
import DeploymentGroupIndex from './pages/DeploymentGroupIndex'

const ClientGroupModule = () => {
  return (
    <Switch>
      <Route path='/client-groups' component={ClientGroupIndex} exact />
      <Route path='/client-groups/deployment-groups' component={DeploymentGroupIndex} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/client-groups',
    component: ClientGroupModule,
  },
  name: 'ClientGroups',
}

export default ClientGroupModule
