import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import ReactFlow, {
  ReactFlowProvider,
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  ConnectionLineType,
} from 'react-flow-renderer'
import {
  Modal,
  Badge,
  Space,
  Typography,
} from 'antd'

import TravelerNode from './nodes/TravelerNode'
import BotNode from './nodes/BotNode'
import StateNode from './nodes/StateNode'
import AgentNode from './nodes/AgentNode'
import SystemNode from './nodes/SystemNode'
import ErrorNode from './nodes/ErrorNode'

const nodeTypes = {
  traveler: TravelerNode,
  bot: BotNode,
  state: StateNode,
  agent: AgentNode,
  system: SystemNode,
  error: ErrorNode,
}

const generateNode = (id, type, position, data) => {
  return ({
    id: id.toString(),
    type,
    position,
    data,
  })
}

const ConversationGraphModal = ({ isModalVisible, closeModal }) => {
  const { currentConversation } = useSelector((state) => { return state.conversationAnalysis.analytics })
  const [nodes, setNodes] = useNodesState([])
  const [edges, setEdges] = useEdgesState([])

  useEffect(() => {
    const utterances = []
    let x = 150
    let y = 0
    const connections = []
    let origin = -1
    let index = 1

    const stateLastPositions = {}
    const connect = (utterance) => {
      utterances.push(utterance)
      if (origin > -1) {
        connections.push([origin, index])
        origin = index
      } else origin = index
      index++
      x += 350
    }

    currentConversation.utterances.forEach((utterance) => {
      const {
        state_key,
        utterance_source,
        utterance_type,
      } = utterance

      const stateExists = stateLastPositions[state_key]
      switch (utterance_source) {
        case 'TRAVELER':
          if (stateExists) {
            const tempBotUtterance = utterances.pop()
            tempBotUtterance.data.stateExists = stateExists
            utterances.push(tempBotUtterance)
          }
          if (stateLastPositions[state_key]) {
            x = stateLastPositions[state_key].x
            y += 300
          }
          stateLastPositions[state_key] = { x, y }
          connect(generateNode(index, 'state', { x, y }, { state_key, stateExists }))
          connect(generateNode(index, 'traveler', { x, y }, { ...utterance }))
          break
        case 'BOT':
          connect(generateNode(index, 'bot', { x, y }, { ...utterance }))
          break
        case 'AGENT':
          connect(generateNode(index, 'agent', { x, y }, { ...utterance }))
          break
        case 'SYSTEM':
          connect(generateNode(index, utterance_type === 'ERROR' ? 'error' : 'system', { x, y }, { ...utterance }))
          break
        default:
          break
      }
    })
    setNodes(utterances)

    const utterancesConnections = []
    connections.forEach((item) => {
      utterancesConnections.push({
        id: item.join('-'),
        source: item[0].toString(),
        target: item[1].toString(),
        arrowHeadType: 'arrowclosed',
        type: ConnectionLineType.SmoothStep,
        animated: true,
      })
    })
    setEdges(utterancesConnections)
  },
  [setEdges, setNodes, currentConversation])

  const nodeColor = (node) => {
    if (node.type === 'traveler') return node.data.domain && node.data.intent ? '#eceff1' : '#faad14'
    if (node.type === 'state') return '#8F00FF'
    if (node.type === 'bot') return '#0AA674'
    if (node.type === 'agent') return '#40a9ff'
    return '#eee'
  }
  if (!currentConversation) return null

  return (
    <Modal
      visible={isModalVisible}
      onCancel={closeModal}
      width='80%'
      maskClosable={false}
      footer={[
        <Space size='middle' style={{ paddingRight: '50px' }}>
          <Badge color='#8F00FF' text='State' />
          <Badge color='#ECEFF1' text='Traveler' />
          <Badge color='#0AA674' text='Bot' />
          <Badge color='#40A9FF' text='Agent' />
          <Badge color='#F85D22' text='System' />
          <Badge color='#DC143C' text='Error' />
        </Space>,
      ]}
      title={<Typography.Text strong>{`Conversation id: ${currentConversation.conversation_id}`}</Typography.Text>}
    >
      <ReactFlowProvider>
        <div style={{ height: '700px', width: 'auto' }}>
          <ReactFlow
            snapToGrid
            snapGrid={[15, 15]}
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            fitView
          >
            <Controls />
            <MiniMap
              nodeColor={nodeColor}
              nodeBorderRadius={2}
            />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </Modal>
  )
}

ConversationGraphModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default ConversationGraphModal
