import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Table,
  Typography,
} from 'antd'

import { sortTextOrNull } from '@core/helpers'
import { useSavedSimulation, useGetAllSavedSimulationEffect } from '../../../../../hooks/useSavedSimulation'

import Api from '../../../../../api'

const { updateDesiredLinkedTestCasesToTestCollection } = Api.EndToEndTestCollection

const columns = [
  {
    title: 'Description',
    dataIndex: 'note',
    sorter: (a, b) => { return sortTextOrNull(b.note, a.note) },
    defaultSortOrder: 'descend',
  },
]

const ManageTestCasesModal = ({
  cancelHandler,
  testCollectionId,
  linkTestCases,
  modalTitle,
  afterSuccessHandler,
}) => {
  const [
    {
      loading,
      data: { conversationSimulations: testCases },
    }, dispatch,
  ] = useSavedSimulation()
  const [selectedTestCaseIds, setSelectedTestCaseIds] = useState([])

  useGetAllSavedSimulationEffect(dispatch)

  const updateLinkedTestCasesHandler = async () => {
    try {
      const payload = {
        testCollectionId,
        simulationIds: selectedTestCaseIds,
      }
      await updateDesiredLinkedTestCasesToTestCollection(payload)
      if (afterSuccessHandler) {
        afterSuccessHandler()
      }
    } catch (error) {
      return Promise.reject(error)
    }
    return undefined
  }

  const linkedTestCaseIds = linkTestCases?.map((testCase) => { return testCase.id })

  const rowSelection = {
    type: 'checkbox',
    defaultSelectedRowKeys: linkedTestCaseIds,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys) => {
      setSelectedTestCaseIds(selectedRowKeys)
    },
  }

  testCases?.sort((a, b) => {
    return sortTextOrNull(a.description, b.description)
  })
  return (
    <Modal
      visible
      width='75vw'
      okText='Update'
      title={modalTitle}
      onOk={updateLinkedTestCasesHandler}
      onCancel={cancelHandler}
    >
      <Typography.Title level={5}>Test Cases</Typography.Title>
      <Table
        loading={loading}
        pagination={false}
        size='small'
        rowSelection={rowSelection}
        columns={columns}
        dataSource={testCases || []}
        rowKey='id'
        scroll={{
          scrollToFirstRowOnChange: true,
          y: 500,
        }}
      />
    </Modal>
  )
}

ManageTestCasesModal.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  testCollectionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  linkTestCases: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
  modalTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  afterSuccessHandler: PropTypes.func,
}

ManageTestCasesModal.defaultProps = {
  linkTestCases: [],
  afterSuccessHandler: () => { },
}

const ManageTestCases = ({
  testCollectionId,
  linkTestCases,
  modalTitle,
  afterSuccessHandler,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const manageTestCasesHandler = () => {
    setIsModalVisible(true)
  }
  const closeModalHandler = () => {
    setIsModalVisible(false)
  }
  const wrappedAfterSuccessHandler = () => {
    setIsModalVisible(false)
    if (afterSuccessHandler) {
      afterSuccessHandler()
    }
  }

  return (
    <>
      <Button size='small' type='secondary' onClick={manageTestCasesHandler}>Manage Test Cases</Button>
      {isModalVisible && (
        <ManageTestCasesModal
          cancelHandler={closeModalHandler}
          testCollectionId={testCollectionId}
          linkTestCases={linkTestCases}
          modalTitle={modalTitle}
          afterSuccessHandler={wrappedAfterSuccessHandler}
        />
      )}
    </>
  )
}

ManageTestCases.propTypes = {
  testCollectionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  linkTestCases: PropTypes.arrayOf(PropTypes.shape({})),
  modalTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  afterSuccessHandler: PropTypes.func,
}

ManageTestCases.defaultProps = {
  linkTestCases: [],
  afterSuccessHandler: () => { },
}

export default ManageTestCases
