import { getBaseUrlCanary, getBaseUrlStaging, getBaseUrlProduction } from '@core/helpers/gbtCloudVariableSwitcher'

import callApi from '../utils/http-client'

const getEnvBaseUrl = {
  production: getBaseUrlProduction('https://hermes.messaging.gbtconnect.com'),
  staging: getBaseUrlStaging('https://hermes.staging.messaging.gbtconnect.com'),
  canary: getBaseUrlCanary('https://hermes.canary.messaging.gbtconnect.com'),
}

export default {
  getDomainsIntents: () => {
    return callApi({
      url: '/api/v1/hierarchies/raw',
      method: 'get',
    })
  },
  getAllJobs: (params = {}) => {
    return callApi({
      url: '/api/v1/jobs',
      method: 'get',
      params,
    })
  },
  getJobStatus: ({ id }) => {
    return callApi({
      url: `/api/v1/jobs/${id}`,
      method: 'get',
    })
  },
  // Generalizer
  getGeneralizers: () => {
    return callApi({
      url: '/api/v1/generalizers',
      method: 'get',
    })
  },
  getTripByPnr: (environment, pnr) => {
    return callApi({
      baseURL: environment ? getEnvBaseUrl[environment] : undefined,
      url: '/api/v1/trips',
      method: 'get',
      params: {
        pnr,
      },
    })
  },
  Analysis: {
    extractInformation: (data) => {
      return callApi({
        url: '/api/v1/analysis/text',
        method: 'post',
        data,
      })
    },
    addToTestSet: (data) => {
      return callApi({
        url: '/api/v1/sentences/tests',
        method: 'post',
        data,
      })
    },
    symbolizedSentence: (text, version) => {
      return callApi({
        url: '/api/v1/analysis/symbolized/text',
        method: 'post',
        headers: {
          'x-version': version,
        },
        data: {
          text,
        },
      })
    },
  },
  Auth: {
    login: (data) => {
      return callApi({
        url: '/api/v1/auth/login',
        method: 'POST',
        data,
      })
    },
    logout: () => {
      return callApi({
        url: '/api/v1/auth/logout',
        method: 'POST',
      })
    },
  },
  PermittedFeatures: {
    get: () => {
      return callApi({
        url: '/api/v1/auth/permissions',
        method: 'get',
      })
    },
  },
  Versions: {
    get: () => {
      return callApi({
        url: '/api/v1/versions',
        method: 'get',
      })
    },
    create: (data) => {
      return callApi({
        url: '/api/v1/versions',
        method: 'post',
        data,
      })
    },
    update: (id, data) => {
      return callApi({
        url: `/api/v1/versions/${id}`,
        method: 'patch',
        data,
      })
    },
    deleteMany: (data) => {
      return callApi({
        url: '/api/v1/versions/batch/deletions',
        method: 'post',
        data,
      })
    },
    clone: (id) => {
      return callApi({
        url: `/api/v1/versions/${id}/clone`,
        method: 'post',
      })
    },
    attachTags: (id, data) => {
      return callApi({
        url: `/api/v1/versions/${id}/tags`,
        method: 'post',
        data,
      })
    },
    detachTags: (id, data) => {
      return callApi({
        url: `/api/v1/versions/${id}/tags`,
        method: 'delete',
        data,
      })
    },
    getTrainingSetsByVersionId: (id) => {
      return callApi({
        url: `/api/v1/versions/${id}/training_sets`,
        method: 'get',
      })
    },
    deploy: (id, deployEnvs) => {
      return callApi({
        url: `/api/v1/versions/${id}/deploy`,
        method: 'post',
        data: {
          status: deployEnvs,
        },
      })
    },
  },
  Internationalize: {
    Version: {
      getAll: () => {
        return callApi({
          url: '/api/v1/internationalize/versions',
          method: 'get',
        })
      },
      deleteVersion: (id) => {
        return callApi({
          url: `/api/v1/internationalize/versions/${id}`,
          method: 'delete',
        })
      },
      cloneVersion: (data) => {
        return callApi({
          url: '/api/v1/internationalize/messages/clone',
          method: 'post',
          data,
        })
      },
      editVersion: (id, data) => {
        return callApi({
          url: `/api/v1/internationalize/versions/${id}`,
          method: 'patch',
          data,
        })
      },
      delete: (id) => {
        return callApi({
          url: `/api/v1/internationalize/messages/${id}`,
          method: 'delete',
        })
      },
      getResponseMessage: (params) => {
        return callApi({
          url: '/api/v1/internationalize/messages',
          method: 'get',
          params,
        })
      },
      addResponseMessage: (data) => {
        return callApi({
          url: '/api/v1/internationalize/messages',
          method: 'post',
          data,
        })
      },
      editResponseMessage: (id, data) => {
        return callApi({
          url: `/api/v1/internationalize/messages/${id}`,
          method: 'patch',
          data,
        })
      },
    },
  },
  Cluster: { // FOR YARS
    getWorkerStats: () => {
      return callApi({
        url: '/api/v1/clusters/worker/stats',
        method: 'get',
      })
    },
    scaleUpWorker: () => {
      return callApi({
        url: '/api/v1/clusters/worker/up',
        method: 'post',
        data: {},
      })
    },
    scaleDownWorker: () => {
      return callApi({
        url: '/api/v1/clusters/worker/down',
        method: 'post',
        data: {},
      })
    },
  },
  Faqs: {
    getVersions: () => {
      return callApi({
        url: '/api/v1/faqs-versions',
        method: 'get',
      })
    },
  },
  Conversations: {
    getAll: (data, params) => {
      return callApi({
        url: '/api/v1/conversations',
        method: 'post',
        params,
        data,
      })
    },
    getOne: (id, params) => {
      return callApi({
        url: `/api/v1/conversations/${id}`,
        params,
        method: 'get',
      })
    },
  },
  Languages: {
    getLanguageDetails: () => {
      return callApi({
        url: '/api/v1/languages',
        method: 'get',
      })
    },
  },
}
