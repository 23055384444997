import { CONVERSATION_ANALYSIS } from '../constants'
import Api from '../../api'

const INITIAL_STATE = {
  isPending: false,
  hasError: false,
  currentAnalytic: null,
  analysisKey: null,
  results: null,
  currentConversation: null,
  pageSize: 20,
  lastUpdate: null,
  env: 'Staging',
  title: '',
  dateRange: [null, null],
  statusList: ['INVALID', 'IN_PROGRESS', 'TO_RECHECK', 'SOLVED'],
  responseKeyList: [],
  viewMode: 'raw',
  extraFilters: {
    domains: [],
    intents: [],
    defaultIntentTree: [],
    responseKey: [],
  },
}

INITIAL_STATE.analyticsLookup = {
  'conversation.1': {
    title: 'Check Conversations',
    fn: async ({ params = { page: 1, pageSize: INITIAL_STATE.pageSize }, env, dateRange }) => {
      const { page, pageSize } = params
      return Api.getAllConversationIds({ page, pageSize, dateRange }, env)
    },
  },
  'conversation.2': {
    title: 'Check State Heatmap',
    fn: async ({ env, extraFilters, dateRange }) => {
      const { stateKeyCategories } = extraFilters
      const payload = { dateRange }
      if (stateKeyCategories) payload.stateKeyCategories = stateKeyCategories
      return Api.getStateHierarchy(env, payload)
    },
  },
  'null-Intent-Interactions.1': {
    title: 'Bot Before NULL Intents',
    fn: async ({ params = { page: 1 }, env, dateRange, extraFilters }) => {
      const { page } = params
      const { status, responseKey } = extraFilters
      return Api.getStatesBeforeIntents({
        page, pageSize: INITIAL_STATE.pageSize, dateRange, status, responseKey, onlyNull: true,
      }, env)
    },
  },
  'null-Intent-Interactions.2': {
    title: 'Bot After NULL Intents',
    fn: async ({ params = { page: 1 }, env, dateRange, extraFilters }) => {
      const { page } = params
      const { status, responseKey } = extraFilters
      return Api.getStatesAfterIntents({
        page, pageSize: INITIAL_STATE.pageSize, dateRange, status, responseKey, onlyNull: true,
      }, env)
    },
  },
  'intent-Interactions.1': {
    title: 'Bot Before specific Intents',
    fn: async ({ params = { page: 1 }, env, dateRange, extraFilters }) => {
      const { page } = params
      const { status, intentNames: intents, domainNames: domains, responseKey } = extraFilters
      return Api.getStatesBeforeIntents({
        page, pageSize: INITIAL_STATE.pageSize, dateRange, status, intents, domains, responseKey,
      }, env)
    },
  },
  'intent-Interactions.2': {
    title: 'Bot After specific Intents',
    fn: async ({ params = { page: 1 }, env, dateRange, extraFilters }) => {
      const { page } = params
      const { status, intentNames: intents, domainNames: domains, responseKey } = extraFilters
      return Api.getStatesAfterIntents({
        page, pageSize: INITIAL_STATE.pageSize, dateRange, status, intents, domains, responseKey,
      }, env)
    },
  },
  'intents.1': {
    title: 'Structure Recognition Summary',
    fn: async ({ params = { page: 1, pageSize: INITIAL_STATE.pageSize }, env, dateRange }) => {
      const { page, pageSize } = params
      return Api.getIntentStructures({ page, pageSize, dateRange }, env)
    },
  },
  'vocabulary.1': {
    title: 'Vocabulary Per Intent',
    fn: async ({ params = {}, env, dateRange }) => { return Api.getVocabularyPerIntent({ ...params, dateRange }, env) },
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONVERSATION_ANALYSIS.SET_ENVIRONMENT: {
      return {
        ...state,
        env: action.payload,
      }
    }
    case CONVERSATION_ANALYSIS.SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload,
      }
    }
    case CONVERSATION_ANALYSIS.SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      }
    }
    case CONVERSATION_ANALYSIS.SELECT_FOR_ANALYZE.REQUEST: {
      return {
        ...state,
        currentAnalytic: null,
        analysisKey: action.payload,
        results: null,
        lastUpdate: null,
        hasError: false,
        currentConversation: null,
      }
    }
    case CONVERSATION_ANALYSIS.SELECT_FOR_ANALYZE.SUCCESS: {
      return {
        ...state,
        title: action.payload,
      }
    }
    case CONVERSATION_ANALYSIS.SELECT_FOR_ANALYZE.FAILED: {
      return {
        ...state,
        hasError: true,
      }
    }

    case CONVERSATION_ANALYSIS.FETCH_ANALYTIC.REQUEST: {
      return {
        ...state,
        hasError: false,
        isPending: true,
        results: null,
      }
    }
    case CONVERSATION_ANALYSIS.FETCH_ANALYTIC.SUCCESS: {
      return {
        ...state,
        isPending: false,
        hasError: false,
        currentAnalytic: action.payload.title,
        results: action.payload.results,
        lastUpdate: Date.now(),
      }
    }
    case CONVERSATION_ANALYSIS.FETCH_ANALYTIC.FAILED: {
      return {
        ...state,
        hasError: true,
        isPending: false,
        currentAnalytic: null,
        results: null,
      }
    }
    case CONVERSATION_ANALYSIS.GET_GRAPH_CONVERSATION.REQUEST: {
      return {
        ...state,
        currentConversation: null,
      }
    }
    case CONVERSATION_ANALYSIS.GET_GRAPH_CONVERSATION.SUCCESS: {
      return {
        ...state,
        hasError: false,
        currentConversation: action.payload[0],
      }
    }
    case CONVERSATION_ANALYSIS.GET_GRAPH_CONVERSATION.FAILED: {
      return {
        ...state,
        hasError: true,
        currentConversation: null,
      }
    }
    case CONVERSATION_ANALYSIS.UPDATE_STATUS.REQUEST: {
      return {
        ...state,
        hasError: false,
      }
    }
    case CONVERSATION_ANALYSIS.UPDATE_STATUS.SUCCESS: {
      return {
        ...state,
        results: {
          ...state.results,
          states: state.results.states.map((item) => {
            if (item.id === action.payload.id) return { ...item, status: action.payload.status }
            return item
          }),
        },
      }
    }
    case CONVERSATION_ANALYSIS.UPDATE_STATUS.FAILED: {
      return {
        ...state,
        hasError: true,
      }
    }
    case CONVERSATION_ANALYSIS.SET_EXTRA_FILTERS: {
      return {
        ...state,
        extraFilters: action.payload,
      }
    }
    case CONVERSATION_ANALYSIS.FETCH_RESPONSE_KEY_LIST.REQUEST: {
      return {
        ...state,
        hasError: false,
      }
    }
    case CONVERSATION_ANALYSIS.FETCH_RESPONSE_KEY_LIST.SUCCESS: {
      return {
        ...state,
        responseKeyList: action.payload,
      }
    }
    case CONVERSATION_ANALYSIS.FETCH_RESPONSE_KEY_LIST.FAILED: {
      return {
        ...state,
        hasError: true,
      }
    }
    case CONVERSATION_ANALYSIS.SET_VIEW_MODE: {
      return {
        ...state,
        viewMode: action.payload,
      }
    }
    default: return state
  }
}
