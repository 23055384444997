import React, { useState } from 'react'
import { Drawer, List, Tabs, Typography, Button, Space } from 'antd'
import { PartitionOutlined, BugOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import ConversationGraphModal from '../ConversationGraphModal'

import ConversationAnalysisActions from '../../../store/actions/analytics'

const StateNodeData = ({
  open,
  onClose,
  currentNodeData,
}) => {
  const { label, domainIntents, conversations, hasNull } = currentNodeData
  const { currentConversation, env } = useSelector((state) => { return state.conversationAnalysis.analytics })

  const canDebugConversation = usePermittedFeaturesLookup('conversations.query.actionDebug')
  const history = useHistory()

  const onDebugConversation = (conversationId) => {
    history.push(`/conversations/query/${conversationId}?datasource=${env.toLowerCase()}`)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = (conversationId) => {
    ConversationAnalysisActions.getConversationGraphData(conversationId)
    setIsModalVisible(true)
  }

  return (
    <Drawer
      title={<b>State Key Details</b>}
      placement='right'
      onClose={onClose}
      open={open}
      visible={open}
      width='auto'
      maskClosable={false}
      bodyStyle={{
        paddingRight: 16,
        paddingBottom: 0,
        paddingLeft: 16,
      }}
    >
      {
        currentConversation && (
          <ConversationGraphModal
            isModalVisible={isModalVisible}
            closeModal={() => { return setIsModalVisible(false) }}
          />
        )
      }
      <Space direction='vertical'>
        {hasNull && <Typography.Text mark>Has Null Intents</Typography.Text>}
        <Space>
          <Typography.Text>State Key:</Typography.Text>
          <Typography.Text strong>{label}</Typography.Text>
        </Space>
      </Space>
      <Tabs>
        <Tabs.TabPane key='domain-intent' tab='Domain / Intent Distribution'>
          <List size='small' bordered>
            {Object.entries(domainIntents)
            .sort((a, b) => { return b[1] - a[1] })
            .map(([intent, value]) => {
              return (
                <List.Item key={intent}>
                  <Typography.Text mark={intent.includes('null')}>
                    {[intent, value].join(': ')}
                  </Typography.Text>
                </List.Item>
              )
            })}
          </List>
        </Tabs.TabPane>
        <Tabs.TabPane key='conversations' tab='Conversations List'>
          <List size='small' bordered>
            {conversations
            .slice(0, 100)
            .map((conversation, index) => {
              const { conversationId, domain, intent } = conversation
              return (
                <List.Item
                  key={index}
                  style={(!domain || !intent) ? { color: 'yellow' } : null}
                >
                  <Space size='middle'>
                    <Typography.Text mark={(!domain || !intent)}>
                      {conversationId}
                    </Typography.Text>
                    <Button icon={<PartitionOutlined />} type='primary' onClick={() => { showModal(conversationId) }} />
                    {canDebugConversation && <Button icon={<BugOutlined />} type='secondary' onClick={() => { return onDebugConversation(conversationId) }} />}
                  </Space>
                </List.Item>
              )
            })}
          </List>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}

StateNodeData.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentNodeData: PropTypes.shape({
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    conversations: PropTypes.arrayOf(PropTypes.string).isRequired,
    domainIntents: PropTypes.shape().isRequired,
    utteranceIndex: PropTypes.number.isRequired,
    showDrawer: PropTypes.func.isRequired,
    hasNull: PropTypes.bool.isRequired,
  }).isRequired,
}

export default StateNodeData
