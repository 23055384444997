const emailContentOptionsArray = [
  { label: 'Normal', value: 'NORMAL' },
  { label: 'No Content', value: 'NO_CONTENT' },
  { label: 'Automated - Ignore', value: 'AUTO_IGNORE' },
  { label: 'Automated - Action', value: 'AUTO_ACTION' },
  { label: 'Encrypted - Action', value: 'ENCRYPTED_ACTION' },
]

const emailStyleOptionsArray = [
  { label: 'Paragraph', value: 'PARAGRAPH' },
  { label: 'Table', value: 'TABLE' },
  { label: 'Link', value: 'LINK' },
  { label: 'Combination', value: 'COMBINATION' },
  { label: 'Other', value: 'OTHER' },
]

const binaryOptionsArray = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
]

const binaryCorrectnessOptions = [
  { label: 'Correct', value: 'CORRECT' },
  { label: 'Incorrect', value: 'INCORRECT' },
  { label: 'N/A', value: 'N/A', disabled: true },
]

const correctnessOptionsWithNotAvailable = [
  { label: 'Correct', value: 'CORRECT' },
  { label: 'Partial', value: 'PARTIAL' },
  { label: 'Incorrect', value: 'INCORRECT' },
  { label: 'N/A', value: 'N/A' },
]

const correctnessOptionsWithNotAvailableDisabled = [
  { label: 'Correct', value: 'CORRECT' },
  { label: 'Partial', value: 'PARTIAL' },
  { label: 'Incorrect', value: 'INCORRECT' },
  { label: 'N/A', value: 'N/A', disabled: true },
]

export {
  emailContentOptionsArray,
  emailStyleOptionsArray,
  binaryOptionsArray,
  binaryCorrectnessOptions,
  correctnessOptionsWithNotAvailable,
  correctnessOptionsWithNotAvailableDisabled,
}
