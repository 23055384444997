import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { notification, Form } from 'antd'
import { stringCamelToSnake } from '@core/helpers/string'
import { UserContext } from '../../hooks/useUser'
import api from '../../api'

const openErrorNotification = (error, domain = 'UNKNOWN') => {
  notification.error({
    message: `[${domain}] ${error.message}`,
    description: error.data?.status,
  })
}

/**
 * @typedef {{columnKey: string; field: string; order: "ascend" | "descend"}} SortType
 * @param {SortType} sorter
 * */
const transformSorterToQueryParam = (sorter) => {
  if (!sorter.order) {
    return undefined
  }
  return `${stringCamelToSnake(sorter.field)}.${sorter.order.replace('end', '')}`
}

export const UserProvider = ({ children }) => {
  const [environment, setEnvironment] = useState('PRODUCTION')
  const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isInit, setIsInit] = useState(true)
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const latestFilter = useRef({})
  const pageSize = 15
  const [nextPage, setNextPage] = useState(false)
  const [isSortLoading, setIsSortLoading] = useState(false)
  /** @type {React.MutableRefObject<SortType?>} */
  const sorterRef = useRef()
  const internalPage = useRef(1)

  const getFilterFormFieldsValue = () => {
    const values = form.getFieldsValue()

    if (values.channels?.length === 1) {
      return { channel: values.channels?.[0] }
    }

    return {}
  }

  const [isPaginationLoading, setIsPaginationLoading] = useState(false)

  const loadUsers = async (currentDataSource, fastForward) => {
    setIsPaginationLoading(true)

    try {
      let newDataSource = [...currentDataSource]
      let loadMore = true
      while (loadMore) {
        const sort = sorterRef.current ? transformSorterToQueryParam(sorterRef.current) : undefined

        // eslint-disable-next-line no-await-in-loop
        const response = await api.User.getUserList(latestFilter.current,
          {
            datasource: environment.toLowerCase(),
            page: internalPage.current,
            pageSize: 100,
            sort,
          })
        setNextPage(response.data?.meta?.next ?? false)
        const newUsers = response.data?.items
        newDataSource = [...newDataSource, ...newUsers]
        const numberOfUserAdded = newDataSource.length - currentDataSource.length
        const numberToFill = pageSize - (currentDataSource.length % pageSize)
        loadMore = fastForward && response.data?.meta?.next && (numberToFill >= numberOfUserAdded)
        internalPage.current += 1
      }
      setDataSource(newDataSource)
    } catch (error) {
      openErrorNotification(error, 'LOAD_MORE_USER')
    }

    setIsPaginationLoading(false)
  }

  const onPageChange = async (
    newPage,
    { fastForward = false, currentPage = page, currentDataSource = dataSource, currentNextPage = nextPage }
    = {},
  ) => {
    if (newPage >= 1 && newPage < currentPage) {
      setPage(newPage)
      return
    }
    const lastPage = Math.ceil(currentDataSource.length / pageSize)
    const isNextPageExist = currentPage < lastPage
    if (isNextPageExist) {
      setPage(newPage)
    }

    const startLoadNextPage = newPage >= lastPage - 1
    if (!isPaginationLoading && startLoadNextPage && currentNextPage) {
      internalPage.current += 1
      await loadUsers(currentDataSource, fastForward)
    }
  }

  const loadInitialDataSource = async (filter, sorter) => {
    if (!sorter) setIsLoading(true)
    setIsInit(false)
    try {
      internalPage.current = 1
      await loadUsers([], true)
      setPage(1)
      if (!sorter) sorterRef.current = null
    } catch (error) {
      openErrorNotification(error, 'GET_USER')
      setDataSource([])
    }

    if (!sorter) setIsLoading(false)
  }

  useEffect(() => {
    const onKeyDown = (event) => {
      // FOR VIM USER
      if (event.srcElement?.tagName === 'INPUT' && event.code === 'Escape') {
        event.srcElement.blur()
        return
      }
      if (event.srcElement?.tagName === 'INPUT' || isLoading) {
        return
      }
      if (event.code === 'KeyN' && event.shiftKey) {
        const prevPage = page - 1
        if (prevPage > 0) {
          onPageChange(prevPage)
        }
      } else if (event.code === 'KeyN') {
        const lastPage = Math.ceil(dataSource.length / pageSize)
        const nextPageIndex = page + 1
        if (nextPageIndex <= lastPage || nextPage) {
          onPageChange(nextPageIndex, { fastForward: true })
        }
      }
    }
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isPaginationLoading, page, dataSource])

  const setSorter = async (sorter) => {
    setIsSortLoading(true)
    sorterRef.current = sorter
    await loadInitialDataSource(latestFilter.current, sorter)
    setIsSortLoading(false)
  }

  return (
    <UserContext.Provider
      value={{
        environment,
        setEnvironment,
        dataSource,
        setDataSource,
        loadInitialDataSource,
        isLoading,
        form,
        getFilterFormFieldsValue,
        isInit,
        page,
        onPageChange,
        pageSize,
        latestFilter,
        isPaginationLoading,
        nextPage,
        setIsLoading,
        isSortLoading,
        setSorter,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
