import React, { useState } from 'react'
import {
  Card,
  Typography,
} from 'antd'
import {
  titleType,
  tabContentType,
  tabListType,
} from '../../types/genericLabelOperationTypes'

const { Title } = Typography

const GenericLabelOperation = ({ title, tabContent, tabList }) => {
  const [tabKey, setTabKey] = useState('tab_add')
  const onTabChange = (key) => {
    setTabKey(key)
  }

  return (
    <Card
      title={<Title level={4}>{title}</Title>}
      tabList={tabList}
      activeTabKey={tabKey}
      onTabChange={onTabChange}
    >
      {tabContent[tabKey]}
    </Card>
  )
}

GenericLabelOperation.defaultProps = {
  tabList: [
    { key: 'tab_add', tab: 'Add' },
    { key: 'tab_rename', tab: 'Rename' },
  ],
}

GenericLabelOperation.propTypes = {
  title: titleType.isRequired,
  tabContent: tabContentType.isRequired,
  tabList: tabListType,
}

export default GenericLabelOperation
