import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Typography,
  Spin,
  Alert,
  Divider,
  Space,
  Statistic,
  Progress,
  Descriptions,
  List,
} from 'antd'
import {
  useRequestReducer,
  ACTION_REQUEST,
  ACTION_SUCCESS,
  ACTION_FAILED,
} from '@core/hooks/useRequest'
import Api from '@core/api'

const { Title, Text } = Typography

const JobsTableExpandedRow = ({ id }) => {
  const [
    {
      loading,
      data: job,
      hasError,
    },
    dispatch,
  ] = useRequestReducer()

  useEffect(() => {
    let isMounted = true
    const maxRetry = 3
    let retryCount = 0

    const getData = async (noRequestDispatch = false) => {
      if (!noRequestDispatch) {
        dispatch({ type: ACTION_REQUEST })
      }

      try {
        const { data } = await Api.getJobStatus({ id })
        if (isMounted) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: {
              data,
            },
          })
          if (data.status === 'RUNNING') {
            retryCount = 0
            setTimeout(() => {
              getData(true)
            }, 5000)
          }
        }
      } catch (error) {
        if (isMounted) {
          if (retryCount >= maxRetry) {
            dispatch({ type: ACTION_FAILED })
          } else {
            retryCount++
            setTimeout(() => {
              getData(true)
            }, 1000)
          }
        }
      }
    }
    getData()

    return () => {
      isMounted = false
    }
  }, [dispatch, id])

  const renderError = () => { return <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text> }

  const renderContent = () => {
    if (job.status === 'RUNNING') {
      if (!job.result) {
        return <Spin />
      }
      return (
        <Col span={24}>
          <Text>{job.result.step}</Text>
          {job.result.progress < 100 && <Spin style={{ paddingLeft: 10 }} />}
          {job.result.progress && <Progress strokeLinecap='square' percent={Math.round(job.result.progress * 100) / 100} />}
        </Col>
      )
    }

    if (!job.result || !job.result) {
      return renderError()
    }

    const features = Object.keys(job.result.features || {}).filter((key) => { return job.result.features[key] }).join(', ')

    return (
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <Descriptions title='Details' bordered>
            <Descriptions.Item label='Type'>{job.result.type || '-'}</Descriptions.Item>
            <Descriptions.Item label='Strategy'>{job.result.strategyName || '-'}</Descriptions.Item>
            <Descriptions.Item label='Epochs'>{job.result.epochs || '-'}</Descriptions.Item>
            <Descriptions.Item label='Window Size'>{job.result.windowSize || '-'}</Descriptions.Item>
            <Descriptions.Item label='Pattern Window Size'>{job.result.patternWindowSize || '-'}</Descriptions.Item>
            <Descriptions.Item label='Features'>{features || '-'}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Alert
            showIcon
            message={(<Text strong>Time results (ms)</Text>)}
            description={(
              <Space size='large' split={<Divider type='vertical' />}>
                {job.result.time && <Statistic title='Train Time' value={job.result.time.trainTime} />}
              </Space>
            )}
            type='info'
          />
        </Col>
        {job.result.trainingData && (
          <Col span={24}>
            <Title level={4}>Training Data</Title>
            <Row gutter={[16, 8]}>
              {job.result.keys.map((key) => {
                return (
                  <Col span={6} style={{ height: 480, overflow: 'scroll' }} key={key}>
                    <List
                      size='small'
                      header={<Title level={5}>{key}</Title>}
                      bordered
                      dataSource={job.result.trainingData[key]}
                      renderItem={(item, index) => {
                        return (
                          <List.Item>
                            <List.Item.Meta
                              title={`${index + 1}) ${item}`}
                            />
                          </List.Item>
                        )
                      }}
                    />
                  </Col>
                )
              })}
            </Row>
          </Col>
        )}
      </Row>
    )
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        {hasError && renderError()}
        {loading && <Spin />}
        {job && renderContent()}
      </Col>
    </Row>
  )
}

JobsTableExpandedRow.propTypes = {
  id: PropTypes.string.isRequired,
}

export default JobsTableExpandedRow
