import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Form,
  Alert,
  Input,
  Select,
} from 'antd'
import Api from '../../../api'

const AddIntentModal = ({
  visible,
  onCreated,
  onDismissed,
  currentVersion,
  datasource,
  intentOption,
}) => {
  const [form] = Form.useForm()
  const [creating, setCreating] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)

  if (!visible) return null

  const onOk = async () => {
    form.submit()
  }

  const onCancel = async () => {
    setCreating(false)
    onDismissed() // close from upper
  }

  const onFinish = async (values) => {
    setFlashMessage(null)
    setCreating(true)
    try {
      const { version_id: version, name, parentId } = values
      await Api.Intents.addIntents({ version, name, datasource, parentId })
      setCreating(false)
      onCreated({}) // close from upper
    } catch (error) {
      setFlashMessage({ type: 'error', message: error.message })
      setCreating(false)
    }
  }

  const handleFlashMessageClose = () => {
    setFlashMessage(null)
  }

  return (
    <Modal
      title='Add new intent'
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
    >
      {flashMessage && (
        <Alert
          message={flashMessage.message}
          type={flashMessage.type}
          showIcon
          closable
          onClose={handleFlashMessageClose}
          style={{ marginBottom: 8 }}
        />
      )}
      {/* Name and version_id */}
      {visible && (
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            version_id: currentVersion,
          }}
        >
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Please input name!' }]}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Input disabled={creating} />
          </Form.Item>
          <Form.Item
            name='parentId'
            label='Parent Intent'
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Select
              placeholder='Leave empty for new Parent Intent'
              allowClear
            >
              {intentOption?.intents.filter((intent) => { return !intent.name.startsWith('not-') }).map((intent) => {
                return (<Select.Option key={intent.id} value={intent.id}>{intent.name}</Select.Option>)
              })}
            </Select>
          </Form.Item>
          <br />
          <Form.Item
            label='To Version'
            name='version_id'
            rules={[{ required: true, message: 'Please enter version id!' }]}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Input disabled />
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

AddIntentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCreated: PropTypes.func.isRequired,
  onDismissed: PropTypes.func.isRequired,
  currentVersion: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  datasource: PropTypes.string.isRequired,
  intentOption: PropTypes.shape({
    intents: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
    intentPair: PropTypes.shape({ intentName: PropTypes.shape({ positive: PropTypes.shape({ id: PropTypes.number }) }) }),
  }).isRequired,
}

export default AddIntentModal
