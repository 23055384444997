import callApi from '@core/utils/http-client'

export default {
  getSentences: (data) => {
    return callApi({
      url: '/api/v1/sentences/filtered',
      method: 'post',
      data,
    })
  },
  getSentenceById: (sentenceId) => {
    return callApi({
      url: `/api/v1/sentences/${sentenceId}`,
      method: 'get',
    })
  },
  getSentenceTestCases: (sentenceId) => {
    return callApi({
      url: `/api/v1/sentences/${sentenceId}/tests`,
      method: 'get',
    })
  },
  getSentenceTrainingCases: (sentenceId) => {
    return callApi({
      url: `/api/v1/sentences/${sentenceId}/samples`,
      method: 'get',
    })
  },
  getSentenceConversations: (sentenceId, params) => {
    return callApi({
      url: `/api/v1/sentences/${sentenceId}/conversations`,
      method: 'get',
      params,
    })
  },
  addSentence: (data) => {
    return callApi({
      url: '/api/v1/sentences',
      method: 'post',
      data,
    })
  },
  addSentences: (data) => {
    return callApi({
      url: '/api/v1/sentences/batch/insertions',
      method: 'post',
      data,
    })
  },
  deleteSentences: (data) => {
    return callApi({
      url: '/api/v1/sentences/batch/deletions',
      method: 'post',
      data,
    })
  },
  // Test set(s) related
  addDomainTestSet: (data) => {
    return callApi({
      url: '/api/v1/domains/tests',
      method: 'post',
      data,
    })
  },
  addIntentTestSet: (data) => {
    return callApi({
      url: '/api/v1/intents/tests',
      method: 'post',
      data,
    })
  },
  addTaggerTestSet: (data) => {
    return callApi({
      url: '/api/v1/taggers/tests',
      method: 'post',
      data,
    })
  },
  addExtractorTestSet: (data) => {
    return callApi({
      url: '/api/v1/extractor/tests',
      method: 'post',
      data,
    })
  },
  // NOTE: This one seems like deprecated
  bulkAddIntentTestSets: (data) => {
    return callApi({
      url: '/api/v1/hierarchies/tests/batch',
      method: 'post',
      data,
    })
  },
  updateDomainTestSetById: (testCaseId, data) => {
    return callApi({
      url: `/api/v1/domains/tests/${testCaseId}`,
      method: 'patch',
      data,
    })
  },
  updateIntentTestSetById: (testCaseId, data) => {
    return callApi({
      url: `/api/v1/intents/tests/${testCaseId}`,
      method: 'patch',
      data,
    })
  },
  updateTaggerTestSetById: (testCaseId, data) => {
    return callApi({
      url: `/api/v1/taggers/tests/${testCaseId}`,
      method: 'patch',
      data,
    })
  },
  updateExtractorTestSetById: (testCaseId, data) => {
    return callApi({
      url: `/api/v1/extractor/tests/${testCaseId}`,
      method: 'patch',
      data,
    })
  },
  deleteDomainTestSetByIds: (data) => {
    return callApi({
      url: '/api/v1/domains/tests/batch/deletions',
      method: 'post',
      data,
    })
  },
  deleteIntentTestSetByIds: (data) => {
    return callApi({
      url: '/api/v1/intents/tests/batch/deletions',
      method: 'post',
      data,
    })
  },
  deleteTaggerTestSetByIds: (data) => {
    return callApi({
      url: '/api/v1/taggers/tests/batch/deletions',
      method: 'post',
      data,
    })
  },
  deleteExtractorTestSetByIds: (data) => {
    return callApi({
      url: '/api/v1/extractor/tests/batch/deletions',
      method: 'post',
      data,
    })
  },
  // Training set(s) related
  addDomainTrainingSet: (data) => {
    return callApi({
      url: '/api/v1/domains/samples',
      method: 'post',
      data,
    })
  },
  addIntentTrainingSet: (data) => {
    return callApi({
      url: '/api/v1/intents/samples',
      method: 'post',
      data,
    })
  },
  addTaggerTrainingSet: (data) => {
    return callApi({
      url: '/api/v1/taggers/samples',
      method: 'post',
      data,
    })
  },
  bulkAddIntentTrainingSets: (data) => {
    return callApi({
      url: '/api/v1/samples/batch',
      method: 'post',
      data,
    })
  },
  updateDomainTrainingSetById: (sample_case_id, data) => {
    return callApi({
      url: `/api/v1/domains/samples/${sample_case_id}`,
      method: 'patch',
      data,
    })
  },
  updateIntentTrainingSetById: (sample_case_id, data) => {
    return callApi({
      url: `/api/v1/intents/samples/${sample_case_id}`,
      method: 'patch',
      data,
    })
  },
  updateTaggerTrainingSetById: (sample_case_id, data) => {
    return callApi({
      url: `/api/v1/taggers/samples/${sample_case_id}`,
      method: 'patch',
      data,
    })
  },
  deleteDomainTrainingSetByIds: (data) => {
    return callApi({
      url: '/api/v1/domains/samples/batch/deletions',
      method: 'post',
      data,
    })
  },
  deleteIntentTrainingSetByIds: (data) => {
    return callApi({
      url: '/api/v1/intents/samples/batch/deletions',
      method: 'post',
      data,
    })
  },
  deleteTaggerTrainingSetByIds: (data) => {
    return callApi({
      url: '/api/v1/taggers/samples/batch/deletions',
      method: 'post',
      data,
    })
  },
  // Analyzing sentence stuff
  generalizeType: (text) => {
    return callApi({
      url: '/api/v1/analysis/generalized/text',
      method: 'post',
      data: {
        text,
      },
    })
  },
  extractInformation: (text) => {
    return callApi({
      url: '/api/v1/analysis/text',
      method: 'post',
      data: {
        text,
      },
    })
  },
  // Sentence's note stuff
  getNotesBySentenceId: (sentenceId) => {
    return callApi({
      url: `/api/v1/sentences/${sentenceId}/notes`,
      method: 'get',
    })
  },
  addNoteToSentence: (sentenceId, data) => {
    return callApi({
      url: `/api/v1/sentences/${sentenceId}/notes`,
      method: 'post',
      data,
    })
  },
  editNoteById: (noteId, data) => {
    return callApi({
      url: `/api/v1/sentences/notes/${noteId}`,
      method: 'patch',
      data,
    })
  },
  deleteNoteById: (noteId) => {
    return callApi({
      url: `/api/v1/sentences/notes/${noteId}`,
      method: 'delete',
    })
  },
  // Sentence
  editSentence: (sentenceId, data) => {
    return callApi({
      url: `/api/v1/sentences/${sentenceId}`,
      method: 'patch',
      data,
    })
  },
  // Sentence Data
  editSentenceData: (sentenceId, data) => {
    return callApi({
      url: `/api/v1/sentences/${sentenceId}/data`,
      method: 'patch',
      data,
    })
  },
  bulkToggleUnrecognized: ({ sentence_ids, unrecognized }) => {
    return callApi({
      url: '/api/v1/sentences/batch',
      method: 'patch',
      data: {
        sentence_ids,
        unrecognized,
      },
    })
  },
}
