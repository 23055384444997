import React from 'react'
import PropTypes from 'prop-types'
import { Select, Form } from 'antd'
import { taggerType } from '@core/types/formItems'
import { SAMPLE_FORM_TYPE } from '../../../../../../../constants/extractors'

import style from './style.module.scss'

const ExtractorDesiredValue = ({ caseType, selectedExtractor, desiredLabels }) => {
  return (
    <Form.Item
      name='desiredLabel'
      label='Desired value'
      rules={[{ required: true, message: 'Please enter desired value' }]}
    >
      <Select
        mode={caseType === SAMPLE_FORM_TYPE.NEW ? 'multiple' : undefined}
        className={style.ExtractorDesiredValue}
        placeholder={!selectedExtractor ? 'Select Tagger type' : 'Select desired value'}
        disabled={!selectedExtractor}
        showSearch
      >
        {!desiredLabels.length && <Select.Option value='' disabled selected>---</Select.Option>}
        {desiredLabels.map((desiredLabel) => {
          return (
            <Select.Option value={desiredLabel} key={desiredLabel}>{desiredLabel}</Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

ExtractorDesiredValue.defaultProps = {
  selectedExtractor: null,
  desiredLabels: [],
}

ExtractorDesiredValue.propTypes = {
  caseType: PropTypes.oneOf([SAMPLE_FORM_TYPE.NEW, SAMPLE_FORM_TYPE.EDIT]).isRequired,
  selectedExtractor: taggerType,
  desiredLabels: PropTypes.arrayOf(PropTypes.string),
}

export default ExtractorDesiredValue
