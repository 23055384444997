import PropTypes from 'prop-types'
import { sentenceTypes } from './sentence'

const {
  arrayOf,
  shape,
  bool,
  func,
} = PropTypes

export const selectedSentenceType = arrayOf(shape(sentenceTypes))
export const modalVisibilityType = bool
export const setModalVisibilityType = func
export const setTotalSelectedType = func
export const setModalTitleType = func
export const setSelectedCaseType = func
