import React from 'react'
import PropTypes from 'prop-types'
import { Form, Checkbox } from 'antd'

const ByOnlyPreprocessed = ({ checked, onCheckOnlyPreprocessed }) => {
  return (
    <Form.Item
      name='onlyPreprocessedSearch'
      valuePropName='checked'
    >
      <Checkbox onChange={onCheckOnlyPreprocessed} checked={checked}>
        <small><strong>Only Preprocessed Search</strong></small>
      </Checkbox>
    </Form.Item>
  )
}

ByOnlyPreprocessed.propTypes = {
  checked: PropTypes.bool.isRequired,
  onCheckOnlyPreprocessed: PropTypes.func.isRequired,
}

export default ByOnlyPreprocessed
