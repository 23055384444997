import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'

import style from './style.module.scss'

const approvalTypePastTense = {
  approve: 'approved',
  reject: 'rejected',
}

const SubmissionFeedbacks = ({
  intentApprovalError,
  intentApprovalSuccess,
  showDeletedError,
  showTaggingDeletedFeedback,
  showSubmissionError,
  showSubmittedFeedback,
}) => {
  return (
    <>
      {intentApprovalError && (
        <Alert
          className={style.AlertFeedback}
          closable
          message={`Error: Cannot ${intentApprovalError.type ?? 'approve/reject'} intent(s)`}
          type='error'
        />
      )}
      {intentApprovalSuccess && (
        <Alert
          className={style.AlertFeedback}
          closable
          message={`Success: Intent(s) has been ${approvalTypePastTense[intentApprovalSuccess.type] ?? 'approved/rejected'}`}
          type='success'
        />
      )}
      {showDeletedError && (
        <Alert
          className={style.AlertFeedback}
          closable
          message={`Error when removing this intent: "${showDeletedError.intentName}"`}
          type='error'
        />
      )}
      {showTaggingDeletedFeedback && (
        <Alert
          className={style.AlertFeedback}
          closable
          message={`Successfully deleted intent "${showTaggingDeletedFeedback.intentName}"`}
          type='info'
        />
      )}
      {showSubmissionError && (
        <Alert
          className={style.AlertFeedback}
          closable
          message={`Error while adding following intents: ${showSubmissionError.intentNames}`}
          type='error'
        />
      )}
      {showSubmittedFeedback && (
        <Alert
          className={style.AlertFeedback}
          closable
          message='Successfully added new intent(s)'
          type='success'
        />
      )}
    </>
  )
}

SubmissionFeedbacks.defaultProps = {
  intentApprovalError: null,
  intentApprovalSuccess: null,
  showDeletedError: null,
  showTaggingDeletedFeedback: null,
  showSubmissionError: null,
  showSubmittedFeedback: null,
}

SubmissionFeedbacks.propTypes = {
  intentApprovalError: PropTypes.shape({
    type: PropTypes.oneOf(['approve', 'reject']),
  }),
  intentApprovalSuccess: PropTypes.shape({
    type: PropTypes.oneOf(['approve', 'reject']),
  }),
  showDeletedError: PropTypes.shape({
    intentName: PropTypes.string,
  }),
  showTaggingDeletedFeedback: PropTypes.shape({
    intentName: PropTypes.string,
  }),
  showSubmissionError: PropTypes.shape({
    intentNames: PropTypes.string,
  }),
  showSubmittedFeedback: PropTypes.bool,
}

export default SubmissionFeedbacks
