import React from 'react'
import { Layout, Typography } from 'antd'

import AnalysisOverview from '../../components/AnalysisOverview'

const { Content } = Layout
const { Title } = Typography

export default () => {
  return (
    <Content>
      <Title level={4}>Manage Analysis</Title>
      <AnalysisOverview />
    </Content>
  )
}

