/* eslint-disable no-await-in-loop */
import Api from '../api'

const sleep = async (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout)
  })
}

export const callWithWorkerPod = async (fn, onWaiting, options = {}) => {
  if (['development', 'canary'].includes(process.env.NODE_ENV)) {
    return fn()
  }

  const retry = options.retry || 10
  const retryAfter = options.retryAfter || 30000

  await Api.Cluster.scaleUpWorker()
  for (let retryCount = 0; retryCount < retry; retryCount++) {
    const response = await Api.Cluster.getWorkerStats()
    if (response?.status === 'success' && !response.data.readyReplicas) {
      if (onWaiting) {
        onWaiting({
          retry,
          retryAfter,
          retryCount,
          data: response.data,
        })
      }
      await sleep(retryAfter)
    } else {
      break
    }
  }

  return fn()
}
