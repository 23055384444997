import React from 'react'
import { ReactFlowProvider } from 'react-flow-renderer'
import DisplayFlow from './DisplayFlow'

const DisplayStateHierarchy = () => {
  return (
    <div style={{ width: 'auto' }}>
      <ReactFlowProvider>
        <DisplayFlow />
      </ReactFlowProvider>
    </div>
  )
}

export default DisplayStateHierarchy
