import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Space,
} from 'antd'
import { conversationTagType } from '../../../../../Conversations/types'

const ConversationTags = ({ tags }) => {
  return (
    <Space direction='vertical' size={4}>
      <Typography.Text
        title='Tags'
        strong
      >
        Tags
      </Typography.Text>
      <Typography.Text type='secondary'>{tags.map(({ name }) => { return name }).join(', ') || '-'}</Typography.Text>
    </Space>
  )
}

ConversationTags.propTypes = {
  tags: PropTypes.arrayOf(conversationTagType).isRequired,
}

export default ConversationTags
