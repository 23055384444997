import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { SENTENCE_NOTES } from '../constants'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  fetch: (sentenceId) => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE_NOTES.FETCH.REQUEST, payload: sentenceId })
      try {
        const response = await Api.getNotesBySentenceId(sentenceId)
        if (response && response.data) {
          dispatch({ type: SENTENCE_NOTES.FETCH.SUCCESS, payload: response.data })
        }
      } catch (error) {
        dispatch({ type: SENTENCE_NOTES.FETCH.FAILED })
      }
    }
  },
  addNew: (sentenceId, note) => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE_NOTES.ADD_NEW.REQUEST })
      try {
        const response = await Api.addNoteToSentence(sentenceId, note)
        if (response && response.data) {
          dispatch({
            type: SENTENCE_NOTES.ADD_NEW.SUCCESS,
            payload: {
              sentenceId,
              data: response.data,
            },
          })
        }
      } catch (error) {
        dispatch({ type: SENTENCE_NOTES.ADD_NEW.FAILED })
      }
    }
  },
  edit: (note) => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE_NOTES.EDIT.REQUEST })
      try {
        const response = await Api.editNoteById(note.id, {
          note: note.note,
          type: note.type,
          status: note.status,
        })
        if (response && response.data) {
          dispatch({ type: SENTENCE_NOTES.EDIT.SUCCESS, payload: response.data })
        }
      } catch (error) {
        dispatch({ type: SENTENCE_NOTES.EDIT.FAILED })
      }
    }
  },
  delete: (sentenceId, noteId) => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE_NOTES.DELETE.REQUEST })
      try {
        const response = await Api.deleteNoteById(noteId)
        if (response && response.data) {
          dispatch({
            type: SENTENCE_NOTES.DELETE.SUCCESS,
            payload: {
              sentenceId,
              data: response.data,
            },
          })
        }
      } catch (error) {
        dispatch({ type: SENTENCE_NOTES.DELETE.FAILED })
      }
    }
  },
  select: (note) => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE_NOTES.SELECTED, payload: note })
    }
  },
  close: () => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE_NOTES.RESET })
    }
  },
})
