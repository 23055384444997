import React from 'react'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'
import { Result, Typography, Table, Popover, Button, Space } from 'antd'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import { shallowCleanFalsyObject, sortTextOrNull } from '@core/helpers'
import style from './index.module.scss'
import DataTable from './DataTable'
import Api from '../../../api'

const getStatusAndStateCellStyle = (status) => {
  switch (status?.toUpperCase()) {
    case 'ACTIVE': return {
      backgroundColor: '#A7FF91', fontWeight: 'bold', textAlign: 'center',
    }
    case 'PENDING': return {
      backgroundColor: '#FEFB9E', fontWeight: 'bold', textAlign: 'center',
    }
    case 'CLOSED': return {
      backgroundColor: '#000', fontWeight: 'bold', color: '#fff', textAlign: 'center',
    }
    case 'CANCELED': return {
      backgroundColor: 'salmon', fontWeight: 'bold', textAlign: 'center',
    }
    case 'TIMEOUT': return {
      backgroundColor: 'LightGrey', fontWeight: 'bold', textAlign: 'center',
    }
    default: return {
      backgroundColor: 'White', fontWeight: 'bold', textAlign: 'center',
    }
  }
}

const renderStatusCell = (item) => {
  return {
    props: { style: getStatusAndStateCellStyle(item.status) },
    children: (
      <span>{item.status}</span>
    ),
  }
}

const renderStateCell = (item) => {
  return {
    props: { style: getStatusAndStateCellStyle(item.state) },
    children: (
      <span>{item.state ?? 'NULL'}</span>
    ),
  }
}

const PTCNotificationItem = (item) => {
  const {
    // channel, used in the as main column
    channel_user_id,
    // conversation_id, used in the main column
    // created_at, used in the main column
    updated_at,
    deleted_at,
    created_by,
    // email, used in the as main column
    extra,
    id,
    message_type,
    notification_id,
    raw,
    // status, used in the as main column
    // state, used in the as main column
  } = item

  const other = {
    id,
    notification_id,
    message_type,
    updated_at,
    deleted_at,
    channel_user_id,
    created_by,
  }

  const miscInfo = {
    extra,
    raw,
    other,
  }

  return (
    <>
      {Object.entries(miscInfo).map(([key, value]) => {
        return (
          <Popover
            key={key}
            trigger='click'
            placement='topRight'
            content={(
              <pre className={style.popoverContentStyle}>
                {JSON.stringify(value, null, 2)}
              </pre>
            )}
          >
            <Button size='small'>
              {key}
            </Button>
          </Popover>
        )
      })}
    </>
  )
}

const PTCSubDataTab = (timeFormatter) => {
  return (item) => {
    const subColumnsTable = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 300,
        sorter: (a, b) => { return sortTextOrNull(a.channel, b.channel) },
      },
      {
        title: 'Channel',
        dataIndex: 'channel',
        key: 'channel',
        width: 125,
        sorter: (a, b) => { return sortTextOrNull(a.channel, b.channel) },
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 190,
        sorter: (a, b) => { return new Date(a.created_at).getTime() - new Date(b.created_at).getTime() },
        render: (createdAt) => {
          if (timeFormatter) {
            return <Typography.Text>{timeFormatter(createdAt)}</Typography.Text>
          }
          return <Typography.Text>{createdAt}</Typography.Text>
        },
      },
      {
        title: 'Notification Message',
        dataIndex: ['raw', 'notification', 'message'],
        key: 'message',
        width: 200,
        render: (message) => {
          return (
            <Typography.Text
              style={{ width: '95%' }}
              ellipsis={{ expandable: true, tooltip: message }}
            >
              {message}
            </Typography.Text>
          )
        },
        sorter: (a, b) => { return sortTextOrNull(a.raw?.notification?.message, b.raw?.notification?.message) },
      },
      {
        title: 'Status',
        key: 'status',
        width: 90,
        sorter: (a, b) => { return sortTextOrNull(a.status, b.status) },
        render: renderStatusCell,
      },
      {
        title: 'State',
        key: 'state',
        width: 90,
        sorter: (a, b) => { return sortTextOrNull(a.state, b.state) },
        render: renderStateCell,
      },
    ]

    return (
      <Table
        rowKey={(row) => { return row.id }}
        columns={subColumnsTable}
        dataSource={item.data}
        expandable={{ expandedRowRender: PTCNotificationItem }}
        pagination={false}
      />
    )
  }
}

const PTCDataTab = ({
  timeFormatter,
  filter,
  handlePaginationChange,
}) => {
  const {
    page,
    includeDeleted,
    datasource,
    ...rest
  } = filter
  const params = {
    page: filter.page,
    pageSize: filter.perPage,
    includeDeleted: filter.includeDeleted,
    datasource: filter.datasource,
  }

  const [{ loading, data, hasError }] = useRequest(Api.getAll, shallowCleanFalsyObject(rest), params)

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }

  const columnsTable = [
    {
      title: 'Conversation ID',
      dataIndex: 'conversation_id',
      key: 'conversation_id',
      width: 320,
      render: (id) => {
        return (
          <Space size={10}>
            <Typography.Text copyable>{id}</Typography.Text>
          </Space>
        )
      },
      sorter: (a, b) => { return sortTextOrNull(a.conversation_id, b.conversation_id) },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
      render: (email) => {
        return (
          <Space size={10}>
            <Typography.Text copyable>{email}</Typography.Text>
          </Space>
        )
      },
      sorter: (a, b) => { return sortTextOrNull(a.email, b.email) },
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      width: 30,
    },
  ]

  const { items, meta } = data

  return (
    items
    && (
      <DataTable
        columns={columnsTable}
        dataSource={items}
        meta={meta}
        filter={filter}
        expandable={{ expandedRowRender: PTCSubDataTab(timeFormatter) }}
        onChange={handlePaginationChange}
      />
    )
  )
}

PTCDataTab.propTypes = {
  timeFormatter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    datasource: PropTypes.string,
    page: PropTypes.number,
    perPage: PropTypes.number,
    includeDeleted: PropTypes.bool,
  }).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
}

export default PTCDataTab
