import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Layout,
  Form,
  Button,
  Alert,
  Descriptions,
  Table,
  Collapse,
  Card,
} from 'antd'
import {
  EditOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import TaggerSelect from '@core/components/TaggerFormItems/TaggerSelect'
import TaggerVectorDisplay from '../../VectorDisplay'
import TaggerTrainer from '../../../store/actions/taggers'

const { Panel } = Collapse
const { Sider, Content } = Layout

const AnalysisTab = () => {
  const [visible, setVisible] = useState(false)
  const [currentVector, setCurrentVector] = useState(null)

  const showDrawer = (vector) => {
    setCurrentVector(vector)
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const history = useHistory()
  const {
    analysis: {
      isAnalyzing,
      result,
      error,
    },
  } = useSelector((state) => { return state.experiments.taggers })

  const [form] = Form.useForm()
  const handleOnFinish = async ({ tagger_type }) => { return TaggerTrainer.analyzeTaggers(tagger_type) }
  const columns = [
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      render: (text) => { return text.toLowerCase() },
    },
    {
      title: 'Phrase',
      dataIndex: 'phrase',
      key: 'phrase',
      render: (text) => { return text.toLowerCase() },
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Output',
      dataIndex: 'output',
      key: 'output',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => { return <Button onClick={() => { return history.push(`/utterances/sentences/${record.sentence_id}`) }} icon={<EditOutlined />}>Edit</Button> },
    },
  ]

  const DisplayPairList = ({ list }) => {
    if (!list) return 'error'
    if (list.length > 0) {
      return (
        <Collapse>
          <Panel header={list.length}>
            { list.map(({ data, vector }) => {
              return (
                <Card
                  size='small'
                  type='inner'
                  extra={(
                    <Button
                      icon={<UnorderedListOutlined />}
                      onClick={() => { return showDrawer(vector) }}
                    >
                      See vector
                    </Button>
                  )}
                >
                  <Table
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                  />
                </Card>
              )
            })}
          </Panel>
        </Collapse>
      )
    }
    return list.length
  }

  return (
    <Layout>
      {currentVector && <TaggerVectorDisplay visible={visible} onClose={onClose} vector={currentVector} />}
      <Sider>
        <Form layout='vertical' form={form} onFinish={handleOnFinish}>
          <TaggerSelect onlyEntityTaggers multiple rules={[{ required: true, message: 'Please selected Target tagger type' }]} />
          <Button loading={isAnalyzing} type='primary' htmlType='submit' block>Analyze taggers</Button>
        </Form>
      </Sider>
      <Content>
        {!result && null}
        {result && (
          <>
            <Alert banner closable message='Finished analysis' type='success' />
            {
              result.map((item) => {
                const { tagger, totalVectors, conflicts, duplicates, usefulFeature } = item
                return (
                  <Descriptions
                    title={tagger}
                    bordered
                    size='large'
                    extra={(
                      <Button
                        icon={<UnorderedListOutlined />}
                        onClick={() => { return showDrawer(usefulFeature) }}
                      >
                        See Used Features
                      </Button>
                    )}
                  >
                    <Descriptions.Item label='Total' span={3}>{totalVectors}</Descriptions.Item>
                    <Descriptions.Item label='Conflicts' span={3}>
                      <DisplayPairList list={conflicts} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Duplicated' span={3}>
                      <DisplayPairList list={duplicates} />
                    </Descriptions.Item>
                  </Descriptions>
                )
              })
            }
          </>
        )}
        {
          error ? <Alert banner closable message={error.message} type='error' /> : null
        }
      </Content>
    </Layout>
  )
}

export default AnalysisTab
