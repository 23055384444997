import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Layout,
  Form,
  Button,
  Alert,
  Descriptions,
} from 'antd'
import { useSelector } from 'react-redux'
import { EditOutlined } from '@ant-design/icons'
import TaggerSelect from '@core/components/TaggerFormItems/TaggerSelect'
import TaggerTrainer from '../../../store/actions/taggers'

const { Sider, Content } = Layout
const TrainingTab = () => {
  const history = useHistory()
  const {
    training: {
      isTraining,
      result,
      error,
    },
  } = useSelector((state) => { return state.experiments.taggers })

  const [form] = Form.useForm()
  const handleOnFinish = async ({ tagger_type }) => { return TaggerTrainer.trainTaggers(tagger_type) }

  return (
    <Layout>
      <Sider>
        <Form layout='vertical' form={form} onFinish={handleOnFinish}>
          <TaggerSelect multiple rules={[{ required: true, message: 'Please selected Target tagger type' }]} />
          <Button loading={isTraining} type='primary' htmlType='submit' block>Train taggers</Button>
        </Form>
      </Sider>
      <Content>
        {!result && null}
        {
          result
              && (
                <>
                  <Alert banner closable message='Finished training' type='success' />
                  {
                    result.map((item) => {
                      return (
                        <Descriptions title={item.tagger} bordered size='large'>
                          <Descriptions.Item label='Version' span={3}>{`${item.version}`}</Descriptions.Item>
                          {item.errors && item.errors.map((itemError) => {
                            return (
                              <Descriptions.Item label='Errors' span={4}>
                                <Descriptions
                                  bordered
                                  size='large'
                                  extra={(
                                    <EditOutlined onClick={() => { return history.push(`/utterances/sentences/${itemError.sentenceId}`) }} />
                                  )}
                                >
                                  <Descriptions.Item label='Original Sentence' span={4}>{itemError.original}</Descriptions.Item>
                                  <Descriptions.Item label='Desymbolized Sentence' span={4}>{itemError.desymbolized}</Descriptions.Item>
                                  <Descriptions.Item label='Position' span={4}>{itemError.targetPosition}</Descriptions.Item>
                                  <Descriptions.Item label='Phrase' span={4}>{itemError.phrase}</Descriptions.Item>
                                  <Descriptions.Item span={4} label='Symbol keys'>
                                    {
                                      itemError.symbolKeys && Object.entries(itemError.symbolKeys).map((value) => {
                                        return (
                                          <Descriptions bordered size='large'>
                                            <Descriptions.Item label='key' span={4}>{value[0]}</Descriptions.Item>
                                            <Descriptions.Item label='original' span={4}>{value[1].original}</Descriptions.Item>
                                            <Descriptions.Item label='value' span={4}>{JSON.stringify(value[1].value)}</Descriptions.Item>
                                            <Descriptions.Item label='source' span={4}>{value[1].source.join(',')}</Descriptions.Item>
                                          </Descriptions>
                                        )
                                      })
                                    }
                                  </Descriptions.Item>
                                </Descriptions>
                              </Descriptions.Item>
                            )
                          })}
                        </Descriptions>
                      )
                    })
                  }
                </>
              )
        }
        {
          error ? <Alert banner closable message={error.message} type='error' /> : null
        }
      </Content>
    </Layout>
  )
}

export default TrainingTab
