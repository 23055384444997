import callApi from '@core/utils/http-client'

export default {
  extractInformation: (data) => {
    return callApi({
      url: '/api/v1/analysis/text',
      method: 'post',
      data,
    })
  },
  addToTestSet: (data) => {
    return callApi({
      url: '/api/v1/sentences/tests',
      method: 'post',
      data,
    })
  },
}
