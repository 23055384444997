import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Tag,
  Skeleton,
  Result,
} from 'antd'
import Actions from './Actions'

const tagColors = {
  DEFAULT: 'geekblue',
  DRAFT: 'cyan',
  DEVELOPMENT: 'blue',
  STAGING: 'volcano',
  PRODUCTION: 'gold',
  ARCHIVE: 'magenta',
}

const EmailVersionTable = ({
  versions,
  loading,
  hasError,
  currentVersion,
  setViewingVersion,
  onCloneFinished,
}) => {
  if (loading) return (<Skeleton active />)
  if (hasError) return (<Result status='error' title={'We\'ve encountered some query issues, please check your query'} />)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return [].concat(status).map((item, index) => {
          return (
            <Tag key={index} color={tagColors[item]}>{item}</Tag>
          )
        })
      },
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (version) => {
        return (
          <Actions version={version} setViewingVersion={setViewingVersion} onCloneFinished={onCloneFinished} />
        )
      },
    },
  ]

  return (
    <Table
      loading={loading}
      dataSource={(versions ?? []).map((version, index) => {
        return {
          ...version,
          isCurrentVersion: version.id === currentVersion,
          key: index,
        }
      })}
      size='small'
      columns={columns}
      pagination={false}
      sticky
    />
  )
}

EmailVersionTable.defaultProps = {
  currentVersion: null,
}

EmailVersionTable.propTypes = {
  versions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    note: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  currentVersion: PropTypes.string,
  setViewingVersion: PropTypes.func.isRequired,
  onCloneFinished: PropTypes.func.isRequired,
}

export default EmailVersionTable
