import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import style from './index.module.scss'
import { useUser } from '../../../hooks/useUser'

const ViewPTCAction = ({ conversationId, channel: userChannel }) => {
  const { environment, getFilterFormFieldsValue } = useUser()
  const { channel: filterChannel } = getFilterFormFieldsValue()
  let channel = userChannel ?? filterChannel
  if (channel === 'GOOGLE_CHAT') {
    channel = 'gchat'
  }
  if (channel === 'MS_TEAMS') {
    channel = 'msteams'
  }
  if (channel) { channel = channel.toLowerCase() }

  const ptcPageSearchParams = new URLSearchParams()
  const filter = { datasource: environment.toLowerCase(), channel, conversationId }
  ptcPageSearchParams.set('filter', JSON.stringify(filter))
  const ptcPageUrl = `/conversation-management/ptc?${ptcPageSearchParams.toString()}`

  return (
    <Tooltip title='View PTC'>
      <Link to={ptcPageUrl}>
        <NotificationOutlined className={style['active-action']} />
      </Link>
    </Tooltip>
  )
}

ViewPTCAction.propTypes = {
  conversationId: PropTypes.string,
  channel: PropTypes.string.isRequired,
}

ViewPTCAction.defaultProps = {
  conversationId: '',
}

export default ViewPTCAction
