import PropTypes from 'prop-types'
import { generalizedType } from './generalized'

const {
  string,
  bool,
  number,
  oneOfType,
  shape,
  array,
  func,
} = PropTypes

export const sentenceTypes = {
  id: string,
  text: string,
  selected: bool,
  generalized: generalizedType,
  cursor: number,
  created_at: string,
  deleted_at: oneOfType([string, bool]), // null workaround using bool?
  updated_at: oneOfType([string, bool]), // null workaround using bool?
  source: string,
}

export const sentencePropType = shape({
  id: PropTypes.string,
  source: PropTypes.string,
  text: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  deleted_at: PropTypes.string,
  notes_count: PropTypes.number,
  data: PropTypes.shape({}),
  created_by: PropTypes.string,
  updated_by: PropTypes.string,
  deleted_by: PropTypes.string,
  changed_timestamp: PropTypes.string,
  generalized: PropTypes.shape({
    generalized: PropTypes.string,
    latestDate: PropTypes.string,
    original: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        original: PropTypes.string,
        originalSynonyms: PropTypes.objectOf(PropTypes.string),
        phaseIndex: PropTypes.number,
        predictedSource: PropTypes.string,
        sources: PropTypes.arrayOf(PropTypes.string),
        symbolIndex: PropTypes.number,
        value: PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.array,
            PropTypes.object,
          ]),
        ),
      }),
    ),
  }),
  generalizedHtml: PropTypes.arrayOf(PropTypes.object),
  generalized_at: PropTypes.string,
  unrecognized: PropTypes.bool,
  training_status: PropTypes.string,
  stopwordsRemoved: PropTypes.string,
  hasGeneralizedKeyword: PropTypes.bool,
  cursor: PropTypes.number,
})
export const searchingTermsPropType = array
export const sentenceArrayindexPropType = number
export const bulkModePermittedPropType = bool
export const onHighlightedFilterClickPropType = func
export const isBulkModePropType = bool
export const showGeneralizedPropType = bool
export const showStopwordsRemovedPropType = bool
