import callApi from '@core/utils/http-client'

export default {
  getNgramsOccurrence: (data) => {
    return callApi({
      url: '/api/v1/analysis/ngrams-occurrence',
      method: 'post',
      data,
    })
  },
  getNgramsFrequency: (data) => {
    return callApi({
      url: '/api/v1/analysis/ngrams-frequency',
      method: 'post',
      data,
    })
  },
  postDuplicates: (data) => {
    return callApi({
      url: '/api/v1/analysis/duplicates',
      method: 'post',
      data,
    })
  },
}
