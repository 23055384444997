import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Col,
  Button,
  Space,
  DatePicker,
  Typography,
  Popconfirm,
} from 'antd'

const PersistentData = ({
  persistentDataSimulator,
  setMockDate,
  setMockActiveUser,
  clearActiveUser,
  shouldDisableDatePicker,
  shouldDisableEmailSearch,
}) => {
  const { loading, data: { user } } = persistentDataSimulator
  const [email, setEmail] = useState(user.email)
  const onEmailchange = (event) => {
    const { target: { value } } = event
    setEmail(value)
  }

  // NOTE: how does saved simulator knows who is chating?

  useEffect(() => {
    setEmail(user.email)
  }, [user.email])

  return (
    <>
      <Col xxl={3} xl={12} lg={12} md={12}>
        <Typography.Text>Set Chat Date</Typography.Text>
        <br />
        <DatePicker
          format='DD/MM/YYYY'
          onChange={setMockDate}
          disabled={shouldDisableDatePicker}
        />
      </Col>
      <Col xxl={6} xl={12} lg={12} md={12}>
        <Space align='end'>
          <div>
            <Typography.Text>Chat As</Typography.Text>
            {user ? <Typography.Text strong>{` ${user.email}`}</Typography.Text> : null}
            <Input.Search
              placeholder='Impersonate user (Enter Email)'
              enterButton='Set'
              onSearch={setMockActiveUser}
              loading={loading}
              value={email}
              readOnly={!!user.email}
              onChange={onEmailchange}
              disabled={!!(shouldDisableEmailSearch || user.email || loading)}
            />
          </div>
          {user.email ? (
            <Popconfirm
              title='Confirm?'
              onConfirm={clearActiveUser}
              okText='Yes'
              cancelText='No'
            >
              <Button type='link' danger>Clear</Button>
            </Popconfirm>
          ) : null}
        </Space>
      </Col>
    </>
  )
}

PersistentData.propTypes = {
  persistentDataSimulator: PropTypes.shape({
    email: PropTypes.string,
    loading: PropTypes.bool,
    data: PropTypes.shape({
      date: PropTypes.string,
      user: PropTypes.shape({
        email: PropTypes.string,
      }),
    }),
  }).isRequired,
  setMockDate: PropTypes.func.isRequired,
  setMockActiveUser: PropTypes.func.isRequired,
  clearActiveUser: PropTypes.func.isRequired,
  shouldDisableDatePicker: PropTypes.bool.isRequired,
  shouldDisableEmailSearch: PropTypes.bool.isRequired,
}

export default PersistentData
