import React from 'react'
import {
  Space,
  Button,
  Modal,
  notification,
  Popconfirm,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { useVersionSelector } from '@core/hooks/useVersions'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'

import VersionsActions from '../../../store/actions/Versions'
import style from './index.module.scss'

const showPromiseConfirm = (version) => {
  Modal.confirm({
    title: `This will change the current version to: ${version.id}`,
    icon: <ExclamationCircleOutlined />,
    content: 'This will discard all unsaved changed',
    onOk: async () => {
      try {
        await VersionsActions.set({
          id: version.id,
          note: version.note,
          status: version.status,
          i18n_version: version.i18n_version,
          faq_version_id: version.faq_version_id,
        })
      } catch (error) {
        notification.error({
          message: 'Error',
          description: error.message,
          duration: 5,
        })
      }
    },
    onCancel: () => {},
  })
}

export default (version) => {
  const history = useHistory()
  const { current } = useVersionSelector()
  const { path } = useRouteMatch()
  const selectVersion = () => {
    showPromiseConfirm(version)
  }

  const cloneVersion = () => {
    try {
      VersionsActions.clone(version)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Cloning version failed',
        duration: 5,
      })
    }
  }

  const showVersion = () => {
    history.push(`${path}/${version.id}`)
  }

  const editVersion = () => {
    history.push(`${path}/${version.id}/edit`)
  }

  const deleteVersion = async () => {
    await VersionsActions.delete([version.id])
  }

  return (
    <Space className='VersionActionsButtons' size={0} wrap>
      <PermittedFeatureCheck featurePath='versions.actionSelect'>
        <Button
          disabled={version.isCurrentVersion}
          type='link'
          onClick={selectVersion}
          className={style.Strong_Link}
          title={version.isCurrentVersion ? 'This is current version' : undefined}
        >
          Select
        </Button>
      </PermittedFeatureCheck>
      {current && (
        <PermittedFeatureCheck featurePath='versions.actionView'>
          <Button type='link' onClick={showVersion}>
            View
          </Button>
        </PermittedFeatureCheck>
      )}
      {current && (
        <PermittedFeatureCheck featurePath='versions.actionDuplicate'>
          <Button type='link' onClick={cloneVersion}>
            Duplicate
          </Button>
        </PermittedFeatureCheck>
      )}
      {current && (
        <PermittedFeatureCheck featurePath='versions.actionEdit'>
          <Button type='link' onClick={editVersion}>
            Edit
          </Button>
        </PermittedFeatureCheck>
      )}
      { process.env.REACT_APP_ENV === 'development' && current && (
        <PermittedFeatureCheck featurePath='versions.actionDelete'>
          <Popconfirm
            placement='top'
            title={(
              <>
                <p>THIS ACTION IS PERMANENT!!</p>
                <p>{`Delete: '${version.note}'`}</p>
                <p>{`id: ${version.id}`}</p>
              </>
            )}
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
            onConfirm={deleteVersion}
            okText='Yes'
            cancelText='No'
          >
            <Button type='link'>
              Delete
            </Button>
          </Popconfirm>
        </PermittedFeatureCheck>
      )}
    </Space>
  )
}
