import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { DYNAMIC_ENTITIES } from '../constants'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  reset: () => {
    return async (dispatch) => {
      dispatch({ type: DYNAMIC_ENTITIES.RESET })
    }
  },
  select: (dynamicEntity, dynamicEntityAction) => {
    return async (dispatch) => {
      dispatch({ type: DYNAMIC_ENTITIES.SELECTED, payload: { dynamicEntity, dynamicEntityAction } })
    }
  },
  edit: (sentenceId, data) => {
    return async (dispatch) => {
      dispatch({ type: DYNAMIC_ENTITIES.EDIT.REQUEST })
      try {
        const response = await Api.editSentenceData(sentenceId, data)
        if (response && response.data) {
          dispatch({ type: DYNAMIC_ENTITIES.EDIT.SUCCESS, payload: response.data })
        }
      } catch (error) {
        dispatch({ type: DYNAMIC_ENTITIES.EDIT.FAILED })
      }
    }
  },
})
