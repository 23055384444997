import React from 'react'
import { Input, Form } from 'antd'

const { TextArea } = Input
const ExtractorTestNote = () => {
  return (
    <Form.Item name='note' label='Note'>
      <TextArea />
    </Form.Item>
  )
}

export default ExtractorTestNote
