import React, { useState } from 'react'
import {
  Modal,
  Form,
  Input,
  Button,
  Typography,
} from 'antd'
import PropTypes from 'prop-types'

const SaveSimulationModal = ({
  modalVisible,
  closeModal,
  onSaveSimulation,
  savedSimulatorLoading,
}) => {
  const [hasError, setHasError] = useState(false)
  const [form] = Form.useForm()

  const onFinish = async (fields) => {
    try {
      await onSaveSimulation(fields)
    } catch (error) {
      setHasError(true)
    }
  }

  if (!modalVisible) return null

  return (
    <Modal
      title='Save Current Simulation'
      visible={modalVisible}
      onCancel={closeModal}
      maskClosable={false}
      closable={false}
      destroyOnClose
      footer={[
        <Button key='back' onClick={closeModal} disabled={savedSimulatorLoading}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' disabled={savedSimulatorLoading} loading={savedSimulatorLoading} onClick={form.submit}>
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        name='form__save_simulation'
      >
        <Form.Item
          name='note'
          label='Note'
          rules={[{ required: true, message: 'Simulation label/note is required' }]}
        >
          <Input placeholder='Simulation label/note' disabled={savedSimulatorLoading} />
        </Form.Item>
        {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      </Form>
    </Modal>
  )
}

SaveSimulationModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveSimulation: PropTypes.func.isRequired,
  savedSimulatorLoading: PropTypes.bool.isRequired,
}

export default SaveSimulationModal
