import React from 'react'
import { useSelector } from 'react-redux'
import { Menu } from 'antd'

const ConversationAnalysisMenuTree = () => {
  const { analyticsLookup } = useSelector((state) => { return state.conversationAnalysis.analytics })
  const categories = new Set(Object.keys(analyticsLookup).map((key) => { return key.replace(/\.[0-9]+/gi, '') }))
  return [...categories].map(
    (category) => {
      return (
        <Menu.ItemGroup key={category} title={category.charAt(0).toUpperCase() + category.slice(1)}>
          {
            Object.keys(analyticsLookup).map((key) => {
              if (key.includes(category) && analyticsLookup[key]) {
                return <Menu.Item key={key}>{analyticsLookup[key].title}</Menu.Item>
              }
              return null
            })
          }
        </Menu.ItemGroup>
      )
    },
  )
}

export default ConversationAnalysisMenuTree
