import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import style from './style.module.scss'

const SpinnerFillWidth = () => {
  return (
    <div className={style.SpinnerFillWidth}>
      <Spin size='large' indicator={<LoadingOutlined spin />} />
    </div>
  )
}

export default SpinnerFillWidth
