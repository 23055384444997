// NOTE: this has some dependency loading issue if load on the utils
import store from '../../../../setup/store'

/*
  Utils function that wraps all action creator function with dispatch() call

  // the deal is in order to execute thunk,
  // it needs to call 'dispatch' on top of the action creator
  // so this function will make all action creator being passed into dispatch
  // without calling dispatch inside component

  // using thunk it just calls a function that returns a function
  // dispatch(fetch()) or dispatch(clear())
  // but calling dispatch inside component is not a good idea

  // function will return like this:
  return {
    actionFn1: (...params) => dispatch(actionFn1(...params)),
    actionFn2: (...params) => dispatch(actionFn2(...params))
  }

  // somewhere in the componnt
  ActionCommiter.Sentences.actionFn1()

*/

// previously encapsulateThunkWithClosureDispatchCall
export const wrapActionCreatorWithDispatch = (actionCreators) => {
  if (typeof actionCreators !== 'object') {
    throw new Error('thunkDispatcherWrapper:: ACTION CREATOR MUST BE AN OBJECT')
  }

  const { dispatch } = store
  const transformedActionCreator = {}

  Object.keys(actionCreators).forEach((key) => {
    transformedActionCreator[key] = (...params) => { return dispatch(actionCreators[key](...params)) }
  })

  return transformedActionCreator
}
