import axios from 'axios'

// http-client: defines all api client definition
// NOTE: Currently it serve the axios, but it could be replaced with the class
// style with http client library injection instead
import defaultsDeep from 'lodash.defaultsdeep'
import StorageUtils from './storage'

const successResponseHandler = async (response) => {
  /*
    Axios put success response inside `data`
    and our backend response like this
    {
      status: <Boolean>,
      data: <Any>
    }
  */

  // const result = _.get(response, 'data.data', {})
  const result = response && response.data ? {
    ...response.data,
    transactionToken: response.headers['x-transaction-token'],
    responseTime: response.headers['x-response-time'],
  } : {}

  return result
}

const errorResponseHandler = async (error, fullRequest = {}) => {
  /*
    Axios puts the entire request / response object into the error, which makes it when printing the data
    it has buffers to print. This makes the logs unreadable when there is a giant buffer of numbers printed
    into the log. This bit takes the data from the error and wraps it in a new error, eliminating the weird buffers.
  */

  const errorResponse = {}
  errorResponse.original_name = error.name
  errorResponse.original_stack = error.stack

  if (error.response) {
    errorResponse.message = 'Response: Failure'
    errorResponse.data = error.response.data
    errorResponse.status = error.response.status
    errorResponse.headers = error.response.headers
  } else if (error.request) {
    errorResponse.message = 'Request: No Response Received'
    errorResponse.data = fullRequest
  } else {
    errorResponse.message = 'Setup: Unable to Build Request'
    errorResponse.data = fullRequest
  }

  return errorResponse
}

const getDefaultRequest = async () => {
  const token = StorageUtils.getItem('token')
  const headers = {}

  if (token) headers['X-Access-Token'] = token

  const requestObject = {
    method: 'get',
    timeout: 0,
    responseType: 'json',
    headers,
  }

  if (process.env.REACT_APP_BASE_URL) requestObject.baseURL = process.env.REACT_APP_BASE_URL
  if (process.env.REACT_APP_BUILD) requestObject.baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:3000'

  return requestObject
}

const httpClient = async (options) => {
  const defaultRequest = await getDefaultRequest()
  let newDefaultRequest = defaultRequest

  const isNotProduction = process.env.REACT_APP_ENV !== 'production'

  if (isNotProduction) {
    newDefaultRequest = {
      ...defaultRequest,
      headers: {
        ...defaultRequest.headers,
      },
    }
  }

  const fullRequest = defaultsDeep(options, newDefaultRequest)

  try {
    let response = await axios(fullRequest)
    response = await successResponseHandler(response)
    return response
  } catch (error) {
    const decoratedError = await errorResponseHandler(error, fullRequest)
    throw decoratedError // NOTE: throw an error exception to be consumed at API level instead
  }
}

export default httpClient

