import React from 'react'

import { Form, Select, Divider } from 'antd'

const analysisStatusOptions = ['none', 'processed', 'errored', 'filtered', 'unprocessed']
const e2eCorrectnessOptions = ['none', 'N/A', 'CORRECT', 'PARTIAL', 'INCORRECT']
const preprocessCorrectnessOptions = ['none', 'N/A', 'CORRECT', 'INCORRECT']
const correctnessOptions = ['N/A', 'CORRECT', 'PARTIAL', 'INCORRECT']
const limitSelect = (value) => {
  if (value?.length > 1) {
    // if you want only one element :).
    value.shift()
  }
}

const preventInputOnKeyDown = (e) => {
  const allowCharacters = new RegExp(/^[a-zA-Z-_0-9]/i)
  if (!allowCharacters.test(e.key)) {
    e.preventDefault()
    return false
  }
  return true
}

const ByAccuracyChecking = () => {
  return (
    <>
      <Divider>
        <small><strong> By Linguistic Accuracy</strong></small>
      </Divider>
      <Form.Item name='userCheckingIds' label='User IDs'>
        <Select
          mode='tags'
          placeholder='Select User IDs'
          allowClear
          onKeyDown={preventInputOnKeyDown}
        />
      </Form.Item>
      <Form.Item name='analysisResponseStatuses' label='Analysis API Response Statuses'>
        <Select
          mode='multiple'
          placeholder='Analysis API Response Statuses'
          allowClear
          options={analysisStatusOptions.map((option) => {
            return { value: option, label: option }
          })}
        />
      </Form.Item>
      <Form.Item name='e2eCorrectnesses' label='E2E Correctness Statuses'>
        <Select
          mode='multiple'
          placeholder='E2E Correctnesses'
          allowClear
          options={e2eCorrectnessOptions.map((option) => {
            return { value: option, label: option }
          })}
        />
      </Form.Item>
      <Form.Item name='preprocessingCorrectnesses' label='Pre-Process Correctness Statuses'>
        <Select
          mode='multiple'
          placeholder='Pre-Process Correctnesses'
          allowClear
          options={preprocessCorrectnessOptions.map((option) => {
            return { value: option, label: option }
          })}
        />
      </Form.Item>
      <Form.Item name='intentCorrectnesses' label='Intent Correctness'>
        <Select
          mode='multiple'
          onChange={limitSelect}
          placeholder='Intent Correctness'
          allowClear
          options={correctnessOptions.map((option) => {
            return { value: option, label: option }
          })}
        />
      </Form.Item>
      <Form.Item name='specificIntentCorrectnesses' label='Specific Intent Correctness'>
        <Select
          mode='multiple'
          onChange={limitSelect}
          placeholder='Specific Intent Correctness'
          allowClear
          options={correctnessOptions.map((option) => {
            return { value: option, label: option }
          })}
        />
      </Form.Item>
      <Form.Item name='detailsCorrectnesses' label='Details Correctness'>
        <Select
          mode='multiple'
          onChange={limitSelect}
          placeholder='Details Correctness'
          allowClear
          options={correctnessOptions.map((option) => {
            return { value: option, label: option }
          })}
        />
      </Form.Item>
    </>
  )
}

export default ByAccuracyChecking
