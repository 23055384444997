import React from 'react'
import { Button, Table, Space, Typography, Tooltip, notification } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import style from './index.module.scss'
import { OnPTCPropTypes } from '../../../UserPropTypes'
import { useUser } from '../../../../../hooks/useUser'
import api from '../../../../../api'
import ConfirmModal from './ConfirmModal'
import getJobStatus from './getJobStatus'

const PTCTab = ({ onPTC, conversationId }) => {
  const { environment, getFilterFormFieldsValue } = useUser()
  const columns = [
    {
      title: (<Typography.Text strong>Convo ID</Typography.Text>),
      dataIndex: 'conversation_id',
      key: 'conversation_id',
      render: (id) => {
        return (
          <Space size={10}>
            <Typography.Text
              ellipsis={{ suffix: '...' }}
              copyable={{ text: id }}
              style={{
                maxWidth: '100%',
              }}
            >
              <Tooltip placement='bottomLeft' title={id}>{id?.slice(0, 6)}</Tooltip>
            </Typography.Text>
          </Space>
        )
      },
    },
    {
      title: (<Typography.Text strong>Status</Typography.Text>),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: (<Typography.Text strong>Channel</Typography.Text>),
      dataIndex: 'channel',
      key: 'channel',
    },
    {
      title: (<Typography.Text strong>Hours</Typography.Text>),
      dataIndex: 'hours',
      key: 'hours',
      width: 70,
      render: (hours) => {
        return hours ?? '-'
      },
    },
    {
      title: (<Typography.Text strong>Actions</Typography.Text>),
      render: (_, { conversation_id, status }) => {
        if (!['ACTIVE', 'PENDING'].includes(status)) {
          return (<Space size='middle' />)
        }

        const closePTC = async () => {
          const { data } = await api.User.closePTC(
            {
              conversationId: conversation_id,
            },
            { datasource: environment.toLowerCase() },
          )

          const jobStatus = await getJobStatus(data.jobId, environment.toLowerCase())

          if (jobStatus === 'SUCCESS') {
            notification.success({ message: '[Close PTC] Success!' })
          } else {
            notification.error({ message: `[Close PTC] ${jobStatus}` })
          }
        }

        let buttonTitle = 'Close'
        if (status === 'ACTIVE') {
          buttonTitle = 'Close'
        } else if (status === 'PENDING') {
          buttonTitle = 'Delete'
        }

        return (
          <Space size='middle'>
            <ConfirmModal
              modalTitle={`Confirm ${buttonTitle.toLowerCase()} PTC?`}
              buttonTitle={buttonTitle}
              onConfirm={closePTC}
            />
          </Space>
        )
      },
    },
  ]

  let { channel } = getFilterFormFieldsValue()
  if (channel === 'GOOGLE_CHAT') {
    channel = 'gchat'
  }
  if (channel === 'MS_TEAMS') {
    channel = 'msteams'
  }
  channel = channel?.toLowerCase()

  const ptcPageSearchParams = new URLSearchParams()
  const filter = { datasource: environment.toLowerCase(), channel, conversationId }
  ptcPageSearchParams.set('filter', JSON.stringify(filter))
  const ptcPageUrl = `/conversation-management/ptc?${ptcPageSearchParams.toString()}`

  return (
    <>
      <div className={style.border}>
        <Table
          columns={columns}
          dataSource={onPTC.data?.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          })}
          size='small'
          pagination={false}
          scroll={{
            scrollToFirstRowOnChange: true,
            y: 170,
          }}
        />
      </div>
      <div className={style.pt16}>
        <Link to={ptcPageUrl}>
          <Button
            type='primary'
          >
            Visit PTC page
          </Button>
        </Link>
      </div>
    </>
  )
}

PTCTab.propTypes = {
  onPTC: OnPTCPropTypes.isRequired,
  conversationId: PropTypes.string,
}

PTCTab.defaultProps = {
  conversationId: '',
}

export default PTCTab
