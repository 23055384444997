import React, {
  useContext,
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Select,
  Spin,
} from 'antd'
import { FaqVersionContext } from '../../../../helpers/context'
import { getFaqCategories } from '../../../../query'

const GetSubCategories = ({ faqParentCategoryId }) => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [faqCategories, setFaqCategories] = useState([])

  const [selectedFaqCategories, setSelectedFaqCategories] = useState(undefined)
  const [faqCategoriesLoading, setFaqCategoriesLoading] = useState(false)

  useEffect(() => {
    let mounted = true

    const fetchFaqCategories = async () => {
      setFaqCategoriesLoading(true)
      if (mounted) {
        const categories = await getFaqCategories(faqVersionId, faqParentCategoryId)

        if (categories) {
          setFaqCategories(() => { return categories })
          setFaqCategoriesLoading(() => { return false })
        }
      }
    }

    fetchFaqCategories()

    return () => {
      mounted = false
    }
  }, [faqVersionId, faqParentCategoryId])

  // FIXME: View not properly cleared, value does cleared and params is correct though
  // and for some reason, it reads key as ui view value
  return (
    <Form.Item
      label='Subcategory'
      name='subcategory_id'
    >
      {faqCategoriesLoading
        ? <Spin />
        : (
          <Select
            placeholder='Subcategory'
            loading={faqCategoriesLoading}
            value={selectedFaqCategories}
            onChange={setSelectedFaqCategories}
            allowClear
          >
            {faqCategories.map(({ id, name }, index) => { return (<Select.Option key={index} value={id}>{name}</Select.Option>) })}
          </Select>
        )}
    </Form.Item>
  )
}

GetSubCategories.defaultProps = {
  faqParentCategoryId: null,
}

GetSubCategories.propTypes = {
  faqParentCategoryId: PropTypes.string,
}

export default GetSubCategories
