import { createContext } from 'react'

import StorageUtils from '@core/utils/storage'

export const getCurrentI18nVersionId = () => {
  const storageItem = StorageUtils.getItemFromSession('version')

  if (!storageItem) return null

  return JSON.parse(storageItem)?.i18n_version
}

export const I18nVersionContext = createContext({ selectedI18nVersionId: getCurrentI18nVersionId() })
