import React, { useState, useEffect } from 'react'
import {
  Layout,
  Tabs, Radio, Empty,
} from 'antd'
import dayjs from 'dayjs'
import { filterEmailFromSearchParams } from '@core/helpers/searchParams'
import { getEnvironmentFromSearchParams, getFromSearchParamsByKey } from '../../helpers'
import Api from '../../api'
import DeploymentGroupLeftSider from './DeploymentGroupLeftSider'
import DeploymentGroupDataTab from './DeploymentGroupDataTab'
import FeatureFlagLeftSider from './FeatureFlagLeftSider'
import FeatureFlagDataTab from './FeatureFlagDataTab'
import ChannelsLeftSider from './ChannelsLeftSider'
import ChannelsDataTab from './ChannelsDataTab'
import ConfigurationTestLeftSider from './ConfigurationTestLeftSider'
import ConfigurationTestDataTab from './ConfigurationTestDataTab'
import { BKK, UTC } from '../../constants'

const { Content } = Layout

const { TabPane } = Tabs

const TAB_KEYS = {
  DATA: 'DATA',
  FEATURES: 'FEATURES',
  CHANNELS: 'CHANNELS',
  CONFIGURATION_TEST: 'CONFIGURATION TEST',
}
const ENVIRONMENTS = ['DEVELOPMENT', 'CANARY', 'STAGING', 'PRODUCTION']
const TIMEZONES = {
  UTC: {
    value: UTC,
    formatter: (value) => { return dayjs(value).utc().format('DD/MM/YYYY HH:mm:ss') },
  },
  BKK: {
    value: BKK,
    formatter: (value) => { return dayjs(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss') },
  },
}

const defaultEnvironmentFilter = 'STAGING'
const initialFilterValues = { environment: defaultEnvironmentFilter }

const Component = () => {
  const [availableCapIdsAndCountryOfEmployment, setAvailableCapIdsAndCountryOfEmployment] = useState({
    availableCapIds: [],
    availableCountryOfEmployment: [],
  })

  const findAvailableCapIdsAndCountryOfEmployment = async () => {
    const result = await Api.ClientGroups.findAvailableCapIdsAndCountryOfEmployment()
    setAvailableCapIdsAndCountryOfEmployment(result.data)
  }

  const searchAvailableChannels = async (newFilter) => {
    await Api.ClientGroups.searchAvailableChannels(newFilter)
  }

  const [
    timeZone,
    // setTimeZone
  ] = useState(TIMEZONES.UTC.value)
  const [tab, setTab] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : TAB_KEYS.DATA,
  )
  const [filter, setFilter] = useState(getFromSearchParamsByKey('filter'))
  const [environment, setEnvironment] = useState(getEnvironmentFromSearchParams() || defaultEnvironmentFilter)

  const setInitialFilterFromSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filterAsString = searchParams.get('filter')
    if (!filterAsString) {
      setFilter(initialFilterValues)
    } else {
      try {
        const parsedFilter = JSON.parse(filterAsString)
        if (typeof parsedFilter === 'object' && parsedFilter !== null) {
          setFilter(Object.keys(parsedFilter).length ? parsedFilter : initialFilterValues)
        } else {
          throw new Error('parsedFilter is not an object, throw')
        }
      } catch (error) {
        setFilter(initialFilterValues)
      }
    }
  }

  const updateSearchParamsByFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filteredParams = filterEmailFromSearchParams(filter)
    searchParams.set('filter', JSON.stringify(filteredParams))
    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState(null, '', newRelativePathQuery)
  }

  useEffect(setInitialFilterFromSearchParams, [])

  useEffect(updateSearchParamsByFilter, [filter])

  useEffect(() => {
    findAvailableCapIdsAndCountryOfEmployment()
  }, [])

  const search = (newFilter) => {
    newFilter.page = 1
    setFilter({ ...newFilter, _: Math.random(), environment })
    searchAvailableChannels(newFilter)
  }

  useEffect(() => {
    search({ ...filter })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment])

  const handleEnvironmentChange = (e) => { return setEnvironment(e.target.value) }

  const triggerSearch = () => {
    search(filter)
    findAvailableCapIdsAndCountryOfEmployment()
    searchAvailableChannels({ ...filter, environment })
  }

  const handleTabChange = (newTab) => {
    window.location.hash = `#${newTab}`
    setTab(newTab)
  }

  const handlePaginationChange = (page, perPage) => {
    if (filter.perPage !== perPage) {
      setFilter((prevFilter) => { return { ...prevFilter, page: 1, perPage } })
    } else {
      setFilter((prevFilter) => { return { ...prevFilter, page, perPage } })
    }
  }

  return (
    <>
      {(filter && tab === TAB_KEYS.DATA) && (
        <DeploymentGroupLeftSider
          initialFilterValues={filter}
          onSearch={search}
          triggerSearch={triggerSearch}
          availableCapIdsAndCountryOfEmployment={availableCapIdsAndCountryOfEmployment}
        />
      )}
      {(filter && tab === TAB_KEYS.FEATURES) && (
        <FeatureFlagLeftSider
          initialFilterValues={filter}
          onSearch={search}
          triggerSearch={triggerSearch}
          availableCapIdsAndCountryOfEmployment={availableCapIdsAndCountryOfEmployment}
        />
      )}
      {(filter && tab === TAB_KEYS.CHANNELS) && (
        <ChannelsLeftSider
          initialFilterValues={filter}
          onSearch={search}
          triggerSearch={triggerSearch}
        />
      )}
      {(filter && tab === TAB_KEYS.CONFIGURATION_TEST) && (
        <ConfigurationTestLeftSider
          initialFilterValues={filter}
          onSearch={search}
          availableCapIdsAndCountryOfEmployment={availableCapIdsAndCountryOfEmployment}
        />
      )}
      <Content>
        <Tabs
          activeKey={tab}
          onChange={handleTabChange}
          tabBarExtraContent={(
            <Radio.Group size='small' onChange={handleEnvironmentChange} defaultValue={environment}>
              {ENVIRONMENTS.map((env, index) => {
                return (
                  <Radio.Button key={index} value={env}>
                    {env.charAt(0).toUpperCase() + env.substring(1, env.length)}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          )}
        >
          <TabPane key={TAB_KEYS.DATA} tab='Deployment Groups'>
            {filter ? (
              <DeploymentGroupDataTab
                filter={{ ...filter, environment }}
                timeFormatter={TIMEZONES[timeZone]?.formatter}
                handlePaginationChange={handlePaginationChange}
                triggerSearch={triggerSearch}
                environment={environment}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
          <TabPane key={TAB_KEYS.FEATURES} tab='Features'>
            {filter && tab === TAB_KEYS.FEATURES ? (
              <FeatureFlagDataTab
                filter={filter}
                timeFormatter={TIMEZONES[timeZone]?.formatter}
                handlePaginationChange={handlePaginationChange}
                triggerSearch={triggerSearch}
                environment={environment}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
          <TabPane key={TAB_KEYS.CHANNELS} tab='Channels'>
            {filter && tab === TAB_KEYS.CHANNELS ? (
              <ChannelsDataTab
                filter={filter}
                timeFormatter={TIMEZONES[timeZone]?.formatter}
                handlePaginationChange={handlePaginationChange}
                triggerSearch={triggerSearch}
                environment={environment}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
          <TabPane key={TAB_KEYS.CONFIGURATION_TEST} tab='Configuration Test'>
            {filter && tab === TAB_KEYS.CONFIGURATION_TEST ? (
              <ConfigurationTestDataTab
                filter={filter}
                environment={environment}
                handlePaginationChange={handlePaginationChange}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
        </Tabs>
      </Content>
    </>
  )
}

export default Component
