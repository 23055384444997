import React from 'react'
import {
  Typography,
  Space,
  Divider,
} from 'antd'
import { humanizeDuration } from '@core/helpers'
import { fields, featuredFields } from '../../../../../Conversations/constants/conversationStats'
import {
  customStatsTitleType,
  customStatsValueType,
  customStatsAbbreviationType,
  statsType,
  featuredType,
} from '../../../../../Conversations/types'

import style from './index.module.scss'

const StatisticItem = ({ title, abbreviation, value }) => {
  return (
    <Space className={style.statistic_item} direction='vertical' size={4}>
      <Typography.Text
        className={style.statistic_item__label}
        title={title}
        strong
      >
        {abbreviation.toUpperCase()}
      </Typography.Text>
      <Typography.Text type='secondary'>{value}</Typography.Text>
    </Space>
  )
}

const Statistics = ({ stats, featured }) => {
  const selectedFields = featured ? featuredFields : fields

  return (
    <div className='statistic_container'>
      <Space size={2} split={<Divider type='vertical' />} wrap>
        {selectedFields.map((field) => {
          return stats[field.name] !== undefined && (
            <StatisticItem
              key={field.name}
              abbreviation={field.abbreviation}
              title={field.title}
              value={field.template.replace(
                '{{data}}',
                field.type === 'duration'
                  ? humanizeDuration(stats[field.name], true)
                  : stats[field.name],
              )}
              color={field.color}
            />
          )
        })}
      </Space>
    </div>
  )
}

StatisticItem.propTypes = {
  title: customStatsTitleType.isRequired,
  value: customStatsValueType.isRequired,
  abbreviation: customStatsAbbreviationType.isRequired,
}

Statistics.propTypes = {
  stats: statsType.isRequired,
  featured: featuredType,
}

Statistics.defaultProps = {
  featured: true,
}

export default Statistics
