import { INTERNATIONALIZE } from '../constants'

const INITIAL_STATE = {
  versions: [],
  listed: [],
  hashedIndex: {},
  loading: false,
  hasError: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INTERNATIONALIZE.VERSION.GET_ALL.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        ...action.payload,
      }
    }
    case INTERNATIONALIZE.VERSION.UPDATE.SUCCESS: {
      return {
        ...state,
        listed: action.payload.listed,
      }
    }
    case INTERNATIONALIZE.VERSION.UPDATE.REQUEST:
    case INTERNATIONALIZE.VERSION.GET_ALL.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
      }
    }
    case INTERNATIONALIZE.VERSION.UPDATE.FAILED:
    case INTERNATIONALIZE.VERSION.GET_ALL.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
      }
    }
    default: return state
  }
}
