import React from 'react'
import PropTypes from 'prop-types'
import { Handle, Position } from 'react-flow-renderer'
import { Card, Popover, Typography, List } from 'antd'

const { Meta } = Card

const TravelerNode = ({ data, isConnectable }) => {
  const customNodeStyles = {
    backgroundColor: data.domain && data.intent ? '#ECEFF1' : '#FAAD14',
    color: '#000',
    maxWidth: '250px',
    height: 'auto',
    maxHeight: '500px',
  }
  return (
    <Popover
      title='Traveler Node'
      color='white'
      content={() => {
        return (
          <List size='small'>
            <List.Item>{`G: ${data.generalized}`}</List.Item>
            <List.Item>{`D: ${data.domain ? `${data.domain}: ${data.domain_confidence}` : 'null'}`}</List.Item>
            <List.Item>{`I: ${data.intent ? `${data.domain}: ${data.intent_confidence}` : 'null'}`}</List.Item>
          </List>
        )
      }}
    >
      <Card style={customNodeStyles}>
        <Meta
          style={{ marginBottom: '8px', lineHeight: '1.2' }}
          description={<b>{`${data.domain}/${data.intent}`}</b>}
        />
        <Typography.Text copyable>{data.original}</Typography.Text>
      </Card>
      <Handle type='target' position={Position.Left} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
      <Handle type='source' position={data.stateExists ? Position.Bottom : Position.Right} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
    </Popover>
  )
}

TravelerNode.propTypes = {
  data: PropTypes.shape({
    original: PropTypes.string.isRequired,
    generalized: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    domain_confidence: PropTypes.number.isRequired,
    intent: PropTypes.string.isRequired,
    intent_confidence: PropTypes.number.isRequired,
    extracted_data_keys: PropTypes.string.isRequired,
    stateExists: PropTypes.bool.isRequired,
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
}

export default TravelerNode
