import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Table, Typography, Space, Button, Pagination, Badge, Select, Radio, Col, Row } from 'antd'
import { RightOutlined, RobotOutlined, UserOutlined, PartitionOutlined, BugOutlined, FilterOutlined } from '@ant-design/icons'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'

import IntentTreeInput from '@core/components/IntentTreeInput'
import BeforeNullIntentList from './BeforeIntentList'
import AfterNullIntentList from './AfterIntentList'
import ConversationGraphModal from '../ConversationGraphModal'
import ConversationAnalysisActions from '../../../store/actions/analytics'
import AnalysisSentence from '../../AnalysisSentence'

import style from './style.module.scss'

const DisplayIntentInteractions = ({ after }) => {
  const ANALYSIS_KEY = after ? 'intent-Interactions.2' : 'intent-Interactions.1'
  const {
    results,
    pageSize,
    currentConversation,
    env,
    statusList,
    responseKeyList,
    extraFilters,
    viewMode,
  } = useSelector((state) => {
    return state.conversationAnalysis.analytics
  })
  const { status: statusFilter, defaultIntentTree, responseKey: responseKeyFilter } = extraFilters

  const canDebugConversation = usePermittedFeaturesLookup('conversations.query.actionDebug')
  const history = useHistory()
  const [isConversationModalVisible, setIsConversationModalVisible] = useState(false)

  const showConversationModal = (conversationId) => {
    setIsConversationModalVisible(true)
    ConversationAnalysisActions.getConversationGraphData(conversationId)
  }
  const onDebugConversation = (conversationId) => {
    history.push(`/conversations/query/${conversationId}?datasource=${env.toLowerCase()}`)
  }

  const botColumn = {
    title: (
      <Space>
        <RobotOutlined />
        <Typography.Text strong>{'Bot\'s Utterance'}</Typography.Text>
      </Space>
    ),
    className: style.AlignLeft,
    dataIndex: 'bot_response_text',
    width: '50%',
    key: 'bot_response_text',
    render: (value) => { return <Typography.Text>{value}</Typography.Text> },
  }

  const travelerColumn = {
    title: (
      <Space>
        <UserOutlined />
        <Typography.Text strong>{'Traveler\'s Utterance'}</Typography.Text>
      </Space>
    ),
    dataIndex: 'original',
    width: '50%',
    key: 'original',
    className: style.AlignLeft,
    render: (value, item) => {
      return (
        <Space align='start'>
          {!item.status && <Badge placement='start' dot />}
          <Space direction='vertical' size='small'>
            {(viewMode === 'raw' || viewMode === 'both') && <Typography.Text copyable>{value}</Typography.Text>}
            {(viewMode === 'generalized' || viewMode === 'both') && (
              <Space align='start'>
                { viewMode === 'both' && <Typography.Text type='success' strong>G:</Typography.Text> }
                <AnalysisSentence generalized={item.generalized} original={value} hermesVersion={item.hermes_version} viewMode='generalized' copyable={viewMode !== 'both'} />
              </Space>
            )}
          </Space>
        </Space>
      )
    },
  }

  const updateStatus = (id, status) => { return ConversationAnalysisActions.updateStatus(id, status) }
  const setStatusFilters = (status) => { return ConversationAnalysisActions.setExtraFilters({ status }) }
  const setIntentFilters = (intents) => { return ConversationAnalysisActions.setExtraFilters({ ...intents }) }
  const setResponseKeyFilters = (key) => { return ConversationAnalysisActions.setExtraFilters({ responseKey: key }) }
  const setViewMode = (mode) => { return ConversationAnalysisActions.setViewMode(mode) }

  const columns = [
    {
      key: 'index',
      width: '60px',
      render: (value, item, index) => {
        return (
          <Typography.Text disabled>{(results.meta.page - 1 || 0) * pageSize + index + 1}</Typography.Text>
        )
      },
    },
    {
      title: <Typography.Text strong>Domain/Intent</Typography.Text>,
      width: '200px',
      className: style.AlignLeft,
      render: (value, item) => {
        return (
          <Typography.Text>{`${item.domain}/ ${item.intent}`}</Typography.Text>
        )
      },
    },
    after ? travelerColumn : botColumn,
    {
      width: '50px',
      render: () => { return <RightOutlined /> },
    },
    after ? botColumn : travelerColumn,
    {
      title: <Typography.Text strong>Status</Typography.Text>,
      dataIndex: 'status',
      key: 'status',
      width: '250px',
      className: style.AlignLeft,
      render: (value, record) => {
        return (
          <Select defaultValue={value || 'NEW'} style={{ width: 'calc(100% - 32px)', margin: '0 16px' }} onSelect={(newStatus) => { return updateStatus(record.id, newStatus) }}>
            <Select.Option key='NEW' disabled>NEW</Select.Option>
            {statusList.map((status) => { return <Select.Option key={status}>{status}</Select.Option> })}
          </Select>
        )
      },
    },
    {
      title: <Typography.Text strong>Actions</Typography.Text>,
      dataIndex: '',
      key: 'actions',
      width: '100px',
      render: (record) => {
        return (
          <Space size='middle'>
            <Button icon={<PartitionOutlined />} type='primary' onClick={() => { return showConversationModal(record.conversation_id) }} />
            {canDebugConversation && <Button icon={<BugOutlined />} type='secondary' onClick={() => { return onDebugConversation(record.conversation_id) }} />}
          </Space>
        )
      },
    },
  ]

  return (
    <>
      {
        currentConversation && (
          <ConversationGraphModal
            isModalVisible={isConversationModalVisible}
            closeModal={() => { return setIsConversationModalVisible(false) }}
          />
        )
      }
      <Space direction='vertical' size='small'>
        <Row gutter={[16, 8]} align='middle'>
          <Col span={2}>
            <Typography align='right'>Filter by:</Typography>
          </Col>
          <Col span={8}>
            <Select
              defaultValue={statusFilter}
              showArrow
              suffixIcon={<FilterOutlined />}
              placeholder='Statuses'
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              onChange={setStatusFilters}
            >
              <Select.Option key='NEW'>NEW</Select.Option>
              {statusList.map((status) => { return <Select.Option key={status}>{status}</Select.Option> })}
            </Select>
          </Col>
          <Col span={14}>
            <Select
              defaultValue={responseKeyFilter}
              showArrow
              suffixIcon={<FilterOutlined />}
              placeholder='Response Text Keys'
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              onChange={setResponseKeyFilters}
            >
              {responseKeyList.map((responseKey) => { return <Select.Option key={responseKey}>{responseKey}</Select.Option> })}
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col offset={2} span={8} flex='auto'>
            <div className='ant-form-item-control-input-content'>
              <IntentTreeInput
                setIntent={setIntentFilters}
                availableIntents={defaultIntentTree}
                placeholder='Domains/Intents'
              />
            </div>
          </Col>
        </Row>
        <Row wrap={false} gutter={[16, 8]} align='middle'>
          <Col span={2}>
            <Typography align='right'>View Mode:</Typography>
          </Col>
          <Col span={22}>
            <Radio.Group
              options={[
                { label: 'Raw Text', value: 'raw' },
                { label: 'Generalized Text', value: 'generalized' },
                { label: 'Raw and Generalized Text', value: 'both' },
              ]}
              onChange={(event) => {
                setViewMode(event?.target?.value)
              }}
              value={viewMode}
            />
          </Col>
        </Row>

        <div style={{ overflowX: 'auto', marginTop: '18px' }}>
          <Table
            rowKey='id'
            bordered
            dataSource={results.states}
            columns={columns}
            className={style.Sentence}
            tableLayout='fixed'
            expandable={{
              expandedRowRender: (record) => {
                return (after
                  ? <AfterNullIntentList record={record} />
                  : <BeforeNullIntentList record={record} />)
              },
            }}
            scroll={{ y: 550 }}
            pagination={false}
            size='small'
          />
        </div>
        <Pagination
          defaultCurrent={1}
          total={results.meta.totalCount}
          pageSize={pageSize}
          current={results.meta.page || 1}
          showSizeChanger
          onChange={(page, newSize) => {
            if (newSize) ConversationAnalysisActions.setPageSize(newSize)
            ConversationAnalysisActions.selectForAnalyze(ANALYSIS_KEY, { page, pageSize: newSize })
          }}
        />
      </Space>
    </>
  )
}

DisplayIntentInteractions.defaultProps = { after: false }
DisplayIntentInteractions.propTypes = { after: PropTypes.bool }

export default DisplayIntentInteractions
