import React, { useState, useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Table,
  Typography,
  Row,
  Col,
  Spin,
  Result,
  Select,
  Button,
  Divider,
  Alert,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import useRequest from '@core/hooks/useRequest'
import useTimeoutAlert from '@core/hooks/useTimeoutAlert'
import Api from '../../../api'
import { BulkModeStorageInterface } from '../../../helpers'
import { EmailAutomationsContext } from '../../../context'
import { setSelectedEmails } from '../../../hooks/useEmailSearchResults'
import EmailPreview from '../EmailPreview'
import AddTrainingCasesForm from './AddTrainingCaseForm'
import AssignedIntentSampleTag from './AssignedIntentSampleTag'

const reMapIntentIdsToIntentsNamedList = (intentsList, selectedIntentId) => {
  return (
    intentsList.reduce((intentsNamedList, intent) => {
      if (selectedIntentId.indexOf(intent.id) !== -1) {
        intentsNamedList.push(intent.name)
      }

      return intentsNamedList
    }, [])
  )
}

const transformIntentIdsOfNumberToObject = (intentsList, selectedIntentIds) => {
  return (
    intentsList.reduce((intentsNamedList, intent) => {
      if (selectedIntentIds.indexOf(intent.id) !== -1) {
        intentsNamedList.push({ id: intent.id, name: intent.name })
      }

      return intentsNamedList
    }, [])
  )
}

const BulkEmailModal = ({
  visible,
  closeBulkEmailModal,
  clearEmailSelection,
  statusList,
  responseEmails,
  refreshSearchResult,
  updateMultipleEmailStatus,
  updateSpecificEmailIntentSamples,
  updateMultipleEmailIntentSamples,
}) => {
  const {
    datasource,
    emailSearchResults: [{ data: selected }, emailResultsDispatch],
    versions: { currentVersion } = {},
  } = useContext(EmailAutomationsContext)
  const [{ loading: intentLoading, data, hasError }] = useRequest(Api.Intents.getIntents, { version: currentVersion, datasource })
  const [showFetchingMessageBeforeClose, setShowFetchingMessageBeforeClose] = useState(false)
  const [recentlyAddedTrainingIntents, setRecentlyAddedTrainingIntents] = useState([])
  const [isAddingIntents, setIsAddingIntents] = useState(false)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
  const [activeEmails, setActiveEmails] = useState([...selected])
  const [updateStatus, setUpdateStatus] = useState('')
  const [statusAlert, setStatusAlert] = useState(null)
  const [intentAlert, setIntentAlert] = useState(null)
  const [showIntermediateChangeMessage, setShowIntermediateChangeMessage] = useTimeoutAlert(false)
  const previousActiveEmailChanged = useRef(selected.length)
  const hasBeenChangedRef = useRef(false)

  useEffect(() => {
    setSelectedEmails(emailResultsDispatch, { emails: responseEmails.items, ids: selected.map((email) => { return email.id }) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseEmails])

  const { intents, intentPairs } = data ?? { intents: [], intentPairs: {} }

  const showNoEmailSelectedAlertForIntent = () => {
    setIntentAlert({
      message: 'Error',
      description: 'Please select email(s) to apply Intent(s)',
    })
  }

  // removed the !data check, intent loading duration was noticable
  if (!visible) return null

  const onCloseModal = async () => {
    if (hasBeenChangedRef.current) {
      setShowFetchingMessageBeforeClose(true)
      clearEmailSelection()
      await refreshSearchResult()
    }

    closeBulkEmailModal()
  }

  const addTrainingCases = async (intentIds) => {
    let response = null
    setIntentAlert(null)
    setIsAddingIntents(true)
    setShowIntermediateChangeMessage(false)
    const selectedEmailIds = activeEmails.map((email) => { return email.id })
    const selectedIntentIds = intentIds.map((intent) => { return intent.value })

    if (!selectedEmailIds.length) {
      showNoEmailSelectedAlertForIntent()
      setIsAddingIntents(false)

      return undefined
    }

    try {
      response = await Api.Trainings.batchUpsert({
        version: currentVersion,
        datasource,
        intentIds: selectedIntentIds,
        emailItemIds: selectedEmailIds,
      })

      if (response?.status === 'success') {
        const statesUpdatePayload = {
          timestamp: dayjs().format('DD/MM/YYYY@HH:mm:ssZ'),
          addedIntents: reMapIntentIdsToIntentsNamedList(intents, selectedIntentIds),
          emailIds: selectedEmailIds,
          emailSubjects: activeEmails.map((email) => { return email.subject }),
        }

        if (activeEmails.length !== selected.length) {
          previousActiveEmailChanged.current = activeEmails.length
          setShowIntermediateChangeMessage(true)
        }

        updateMultipleEmailIntentSamples(selectedEmailIds, transformIntentIdsOfNumberToObject(intents, selectedIntentIds))

        BulkModeStorageInterface.updateWithNewItem(statesUpdatePayload, { datasource, caseType: 'trainingCases' })
        setRecentlyAddedTrainingIntents((prevIntentIds) => { return (prevIntentIds.concat([statesUpdatePayload])) })

        hasBeenChangedRef.current = true
      }

      setIsAddingIntents(false)
      return response
    } catch (error) {
      setIntentAlert({
        message: error.message,
        description: `${error.status} ${error.data?.status}`,
      })

      setIsAddingIntents(false)

      return null
    }
  }

  const changeEmailStatus = async () => {
    setStatusAlert(null)
    setIsUpdatingStatus(true)
    setShowIntermediateChangeMessage(false)
    const updatingEmailIds = activeEmails.map((email) => { return email.id })

    if (!updatingEmailIds.length) {
      setStatusAlert({
        message: 'Error',
        description: 'Please select email(s) to apply status',
      })

      setIsUpdatingStatus(false)

      return
    }

    try {
      const response = await Api.Emails.upsertManyEmails({
        status: updateStatus,
        datasource,
        version: currentVersion,
        emailItemIds: updatingEmailIds,
      })

      if (response?.status === 'success') {
        if (activeEmails.length !== selected.length) {
          previousActiveEmailChanged.current = activeEmails.length
          setShowIntermediateChangeMessage(true)
        }
        updateMultipleEmailStatus(updatingEmailIds, updateStatus)

        hasBeenChangedRef.current = true
      }
    } catch (error) {
      setStatusAlert({
        message: error.message,
        description: `${error.status} ${error.data?.status}`,
      })
    }

    setIsUpdatingStatus(false)
  }

  const renderIntermediateChangeMessage = () => {
    if (showIntermediateChangeMessage && (previousActiveEmailChanged.current !== selected.length)) {
      return (
        <Typography.Text type='warning'>
          {`＊ Only ${previousActiveEmailChanged.current} out of ${selected.length} email(s) has been changed`}
        </Typography.Text>
      )
    }

    return null
  }

  const rowSelection = {
    selectedRowKeys: activeEmails.map((email) => { return email.id }),
    onChange: (newSelectedRowKeys, selectedRows) => {
      setActiveEmails(selectedRows)
    },
  }

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      render: (subject) => { return <Typography.Text strong style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{subject}</Typography.Text> },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
    },
    {
      title: 'Assigned Intent Samples',
      dataIndex: 'intentSamples',
      width: '35%',
      render: (samples, email) => {
        if (!Array.isArray(samples) || !samples || !samples.length) return null

        return samples.map(({ name, id }) => {
          return (
            <AssignedIntentSampleTag
              key={id}
              id={id}
              name={name}
              onConfirmDelete={async () => {
                try {
                  const response = await Api.Trainings.deleteSample({
                    version: currentVersion,
                    emailId: email.id,
                    intentId: id,
                    datasource,
                  })

                  // NOTE: Bulk needs to do differently
                  // it reads current samples from search results
                  // we don't want to make it request from selected item to bulk
                  // because if we select 20, it's 20 request...
                  // it needs to filter out, instead
                  if (response?.status === 'success') {
                    const updatedAssignedIntents = samples.filter((intentSample) => {
                      return intentSample.id !== id
                    })
                    if (updatedAssignedIntents) updateSpecificEmailIntentSamples(email.id, updatedAssignedIntents)

                    hasBeenChangedRef.current = true
                  }
                } catch (error) {
                  console.error('BulkEmailModal::AssignedIntentSampleTag#onConfirmDelete - error during deleting training intents')
                  console.error(error)
                }
              }}
            />
          )
        })
      },
    },
  ]

  const renderSelectedEmail = () => {
    if (!selected.length) return (<Result status='warning' title='Please make a selection' />)

    return (
      <Table
        rowKey='id'
        pagination={{ pageSize: 100 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={selected}
        tableLayout='fixed'
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (email) => {
            return (
              <EmailPreview email={email} />
            )
          },
        }}
      />
    )
  }

  const renderModalBody = () => {
    if (hasError) return (<Result status='error' title={'We\'ve encountered problem on our side. Please try again'} />)
    if (showFetchingMessageBeforeClose) {
      return (
        <Row>
          <Col flex={1}>
            <Result
              status='info'
              icon={<LoadingOutlined />}
              title='Please wait and do not close this modal, updating email search results'
            />
          </Col>
        </Row>
      )
    }

    return (
      <Spin spinning={intentLoading || isAddingIntents || isUpdatingStatus}>
        <Row gutter={[16, 0]}>
          <Col span={18}>
            <Typography.Title level={5}>Selected Email(s):</Typography.Title>
            {renderSelectedEmail()}
            {renderIntermediateChangeMessage()}
          </Col>
          <Col span={6}>
            <Typography.Title level={5}>Add Training Samples</Typography.Title>
            {intentAlert ? (
              <Alert
                message={intentAlert.message}
                description={intentAlert.description}
                onClose={() => {
                  setIntentAlert(null)
                }}
                type='error'
                closable
              />
            ) : null}
            <AddTrainingCasesForm
              intents={intentPairs ?? {}}
              emailHasBeenSelected={Boolean(activeEmails.length)}
              addIntents={addTrainingCases}
              showNoEmailSelectedAlertForIntent={showNoEmailSelectedAlertForIntent}
              recentlyAddedIntents={recentlyAddedTrainingIntents}
            />
            <Divider />
            <Typography.Title level={5}>Update Status</Typography.Title>
            {statusAlert ? (
              <Alert
                message={statusAlert.message}
                description={statusAlert.description}
                onClose={() => {
                  setStatusAlert(null)
                }}
                type='error'
                closable
              />
            ) : null}
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Typography.Text>Status</Typography.Text>
                <Select
                  style={{ width: '100%', paddingTop: '8px' }}
                  placeholder='Select a Status'
                  value={updateStatus || undefined}
                  onSelect={(newStatus) => {
                    setUpdateStatus(newStatus)
                  }}
                >
                  {statusList.map((status) => { return (<Select.Option key={status} value={status}>{status}</Select.Option>) })}
                </Select>
              </Col>
              <Col span={24}>
                <Button type='primary' disabled={!updateStatus} onClick={changeEmailStatus}>
                  Update Status
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    )
  }

  return (
    <Modal
      title='Bulk Email Operations'
      visible={visible}
      footer={null}
      onCancel={onCloseModal}
      destroyOnClose
      width='80%'
    >
      {intentLoading ? (<Spin />) : renderModalBody()}
    </Modal>
  )
}

BulkEmailModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  clearEmailSelection: PropTypes.func.isRequired,
  closeBulkEmailModal: PropTypes.func.isRequired,
  statusList: PropTypes.arrayOf(PropTypes.string).isRequired,
  responseEmails: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      email_id: PropTypes.string,
      from_address: PropTypes.string,
      to_address: PropTypes.string,
      user_id: PropTypes.string,
      thread_id: PropTypes.string,
      language: PropTypes.string,
      source: PropTypes.string,
      subject: PropTypes.string,
      body: PropTypes.string,
      email_sent_at: PropTypes.string,
      record_created_at: PropTypes.string,
      cleaned_email_subject: PropTypes.string,
      cleaned_email_body: PropTypes.string,
      email_anatomy: PropTypes.shape({
        header: PropTypes.arrayOf(PropTypes.string),
        greetings: PropTypes.arrayOf(PropTypes.string),
        body: PropTypes.arrayOf(PropTypes.string),
        closing: PropTypes.arrayOf(PropTypes.string),
        ps: PropTypes.arrayOf(PropTypes.string),
        noise: PropTypes.arrayOf(PropTypes.string),
      }),
      intentSamples: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })),
      status: PropTypes.string,
      feedback: PropTypes.string,
    })),
  }).isRequired,
  refreshSearchResult: PropTypes.func.isRequired,
  updateMultipleEmailStatus: PropTypes.func.isRequired,
  updateSpecificEmailIntentSamples: PropTypes.func.isRequired,
  updateMultipleEmailIntentSamples: PropTypes.func.isRequired,
}

export default BulkEmailModal
