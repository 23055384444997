import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Alert, Button, Space, Table, Popconfirm, Result, message } from 'antd'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import useTimeoutAlert from '@core/hooks/useTimeoutAlert'
import useRequest from '@core/hooks/useRequest'

import Api from '../../../../../api'

import CopyPresetButton from './CopyPresetButton'

import style from './style.module.scss'

const ListPresets = ({
  filter,
  setFilter,
  modalVisible,
  onCancel,
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleteError, setIsDeleteError] = useState(false)
  const [showDeletedFeedback, setShowDeletedFeedback] = useTimeoutAlert(null)
  const [showCopiedFeedback, setShowCopiedFeedback] = useTimeoutAlert(null)
  const [{ loading, data, hasError }, { makeRequest }] = useRequest(Api.QueriesManagement.getMyPresets)

  const deleteQueryPreset = async (id) => {
    setIsDeleting(true)
    setIsDeleteError(false)
    setShowDeletedFeedback(null)

    try {
      const response = await Api.QueriesManagement.batchDeleteQueryPreset({ ids: [id] })

      if (response.status === 'success') {
        setShowDeletedFeedback(true)
        await makeRequest()
      }
    } catch (error) {
      setIsDeleteError(true)
    }

    setIsDeleting(false)
  }

  const onCopied = (presetName) => {
    return () => {
      setShowCopiedFeedback({ presetName })
    }
  }

  if (!modalVisible) return null

  return (
    <Modal
      title='Search Preset(s)'
      className='listPresets'
      open={modalVisible}
      visible={modalVisible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      width='80%'
    >
      <div className={style.QueriesPreset}>
        {hasError && (<Result status='warning' title='Could not retrieve query presets' />)}
        {isDeleteError && (<Alert closable showIcon type='error' message='Error during deleting query preset, please try again' />)}
        {showDeletedFeedback && (<Alert closable message='Successfully deleted query preset' type='success' />)}
        {showCopiedFeedback && (<Alert closable message={showCopiedFeedback?.presetName ? `Copied: ${showCopiedFeedback?.presetName}` : 'Preset copied'} type='success' />)}
        <Table
          loading={loading || isDeleting}
          rowKey={(item) => { return item.id }}
          columns={[
            {
              title: (<strong>Preset Name</strong>),
              dataIndex: 'query_name',
              key: 'query_name',
            },
            {
              title: (<strong>Actions</strong>),
              key: 'actions',
              width: 140,
              render: (item) => {
                const { origin, pathname } = window.location
                const url = `${origin}${pathname}?filter=${item.query_string}#emails`
                return (
                  <Space>
                    <Button
                      type='primary'
                      size='small'
                      onClick={() => {
                        const isAlreadyCurrentFilter = JSON.stringify(filter) === decodeURIComponent(item.query_string)

                        if (isAlreadyCurrentFilter) {
                          message.warning('You already had same filter applied!')
                        } else {
                          // use setFilter to automatically trigger search (state change)
                          setFilter(JSON.parse(decodeURIComponent(item.query_string)))
                        }
                      }}
                    >
                      Apply
                    </Button>
                    <CopyPresetButton url={url} onClick={onCopied(item.query_name)} />
                    <Popconfirm
                      title='Are you sure?'
                      onConfirm={() => { deleteQueryPreset(item.id) }}
                      okButtonProps={{ loading: isDeleting }}
                      icon={<QuestionCircleOutlined style={{ color: '#ff0000' }} />}
                    >
                      <Button danger type='link' size='small' icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Space>
                )
              },
            },
          ]}
          dataSource={data}
        />
      </div>
    </Modal>
  )
}

ListPresets.propTypes = {
  filter: PropTypes.shape({
    ids: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    regex: PropTypes.string,
    excludeText: PropTypes.string,
    excludeRegex: PropTypes.string,
    emailIds: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    trainIntentIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    excludeTrainIntentIds: PropTypes.arrayOf(PropTypes.number),
    predictedIntentNames: PropTypes.arrayOf(PropTypes.string),
    predictedIntentScoreMin: PropTypes.number,
    predictedIntentScoreMax: PropTypes.number,
    emailSentAt: PropTypes.arrayOf(PropTypes.string),
    recordCreatedAt: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string),
    feedbackSentiments: PropTypes.arrayOf(PropTypes.string),
    extractionLabelsTree: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
    onlyPreprocessedSearch: PropTypes.bool,
    language: PropTypes.arrayOf(PropTypes.string),
    perPage: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ListPresets
