import React from 'react'
import { CheckCircleOutlined, CheckCircleFilled, QuestionCircleOutlined, ClockCircleOutlined, ClockCircleFilled } from '@ant-design/icons'
import PropTypes from 'prop-types'
import style from './index.module.scss'

const StepIcon = ({ title, selected, state = 'NOT_REACHED', onClick }) => {
  return (
    <button onClick={(state !== 'NOT_REACHED') && onClick} type='button' style={{ position: 'relative', cursor: 'pointer' }}>
      {state === 'PASSED' && (selected
        ? <CheckCircleFilled style={{ fontSize: '30px' }} className={style.success} />
        : <CheckCircleOutlined style={{ fontSize: '30px' }} className={style.success} />)}
      {state === 'PENDING' && (selected
        ? <ClockCircleFilled style={{ fontSize: '30px' }} className={style.pending} />
        : <ClockCircleOutlined style={{ fontSize: '30px' }} className={style.pending} />)}
      {state === 'NOT_REACHED' && <QuestionCircleOutlined style={{ fontSize: '30px' }} className={style.disabled} />}
      <div style={{ position: 'absolute', top: '100%', left: '50%', transform: 'translateX(-50%)' }}>{title}</div>
    </button>
  )
}

StepIcon.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  state: PropTypes.oneOf(['PASSED', 'PENDING', 'NOT_REACHED']),
  onClick: PropTypes.func,
}

StepIcon.defaultProps = {
  selected: undefined,
  state: 'PASSED',
  onClick: undefined,
}

export default StepIcon
