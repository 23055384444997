import React, { useState, useContext } from 'react'
import {
  Table,
  Button,
  Space,
  Result,
  Empty,
  Skeleton,
  Modal,
  Typography,
  Divider,
} from 'antd'
import { SyncOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import useRequest from '@core/hooks/useRequest'
import Api from '../../api'
import { EmailAutomationsContext } from '../../context'
import AddIntentModal from './AddIntentModal'
import EditIntentModal from './EditIntentModal'
import style from './style.module.scss'

// How do we show test and training set

const Intents = () => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [addIntentModalVisible, setAddIntentModalVisible] = useState(false)
  const [selectedIntent, setSelectedIntent] = useState(null)
  const [editIntentModalVisible, setEditIntentModalVisible] = useState(false)
  const [
    {
      loading,
      data,
      hasError,
    },
    { makeRequest },
  ] = useRequest(Api.Intents.getIntents, { version: currentVersion, datasource })

  if (loading) return (<Skeleton active />)
  if (!data?.intents) return (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No result(s)' />)
  if (hasError) return <Result status='error' title={'We\'ve encountered some query issues, please check your query'} />

  const { intents, intentPairs, specificIntentPairs } = data

  const onUpdated = async () => {
    setEditIntentModalVisible(false)
    await makeRequest()
  }

  const showDeleteConfirm = (intent) => {
    return () => {
      Modal.confirm({
        title: (
          <>
            DELETING INTENT
            <Typography.Text strong>{intent.name}</Typography.Text>
            ?
          </>
        ),
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
        content: 'This is IRREVERSIBLE',
        onOk: async () => {
          if (!intent.positive || !intent.negative) {
            console.error('EmailAutomations::Intents#showDeleteConfirm.onOK - Malformed intent format received when trying to delete!')
          } else {
            await Api.Intents.deleteIntents({
              version: currentVersion,
              ids: [intent.positive.id, intent.negative.id],
              datasource,
            })
          }

          await onUpdated()
        },
        onCancel: () => { },
      })
    }
  }

  const openEditIntentModal = (intent) => {
    return () => {
      setSelectedIntent(intent)
      setEditIntentModalVisible(true)
    }
  }

  const onCreated = async () => {
    setAddIntentModalVisible(false)
    await makeRequest()
  }

  const renderIntentsTable = () => {
    const columns = [
      {
        title: 'Intent',
        dataIndex: 'name',
        key: 'name',
        render: (text) => {
          return (
            <Space>
              <Typography.Text strong type='success'>{text}</Typography.Text>
              <Divider type='vertical' />
              <Typography.Text strong type='danger'>{`not-${text}`}</Typography.Text>
            </Space>
          )
        },
      },
      {
        title: 'Training',
        dataIndex: 'training_count',
        key: 'training_count',
        render: (count) => {
          return (
            <>
              <Typography.Text strong type='success'>{count?.[0] ?? 0}</Typography.Text>
              <Divider type='vertical' />
              <Typography.Text strong type='danger'>{count?.[1] ?? 0}</Typography.Text>
            </>
          )
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (intent) => {
          return (
            <Space>
              <Button type='link' onClick={openEditIntentModal(intent)}>
                Rename
              </Button>
              <Button type='link' danger onClick={showDeleteConfirm(intent)}>
                Delete
              </Button>
            </Space>
          )
        },
      },
    ]

    const source = intents.filter((intent) => { return !intent.name.startsWith('not-') }).map((intent, index) => {
      const { positive, negative } = intentPairs[intent.name]

      if (!positive || !negative) console.error('EmailAutomations::Intents - Malformed intent(s) format received!')

      return {
        id: intent.id,
        name: intent.name,
        positive,
        negative,
        test_count: [positive?.test_count, negative?.test_count],
        training_count: [positive?.training_count, negative?.training_count],
        index,
        children: intent?.children?.filter((specificIntent) => { return !specificIntent.name.startsWith('not-') }).map((specificIntent) => {
          const { positive: specificPositive, negative: specificNegative } = specificIntentPairs[specificIntent.name]
          return {
            id: specificIntent.id,
            name: specificIntent.name,
            positive: specificPositive,
            negative: specificNegative,
            test_count: [specificPositive?.test_count, specificNegative?.test_count],
            training_count: [specificPositive?.training_count, specificNegative?.training_count],
          }
        }),
      }
    })

    return (
      <Table
        className={style.IntentTable}
        dataSource={source ?? []}
        columns={columns}
        size='small'
        pagination={false}
        rowKey='id'
        indentSize={0}
      />
    )
  }

  return (
    <>
      <Space>
        <Button
          type='primary'
          onClick={() => { setAddIntentModalVisible(true) }}
          disabled={loading}
        >
          Add new intent
        </Button>
        <Button
          type='default'
          onClick={async () => { await makeRequest() }}
          disabled={loading}
          icon={<SyncOutlined spin={loading} />}
        >
          Refresh
        </Button>
      </Space>
      {renderIntentsTable()}
      <AddIntentModal
        currentVersion={currentVersion}
        datasource={datasource}
        visible={addIntentModalVisible}
        onCreated={onCreated}
        onDismissed={() => { setAddIntentModalVisible(false) }}
        intentOption={data}
      />
      <EditIntentModal
        currentVersion={currentVersion}
        datasource={datasource}
        visible={editIntentModalVisible}
        intents={intents}
        selectedIntent={selectedIntent}
        onUpdated={onUpdated}
        onDismissed={() => {
          setEditIntentModalVisible(false)
          setSelectedIntent(null)
        }}
      />
    </>
  )
}

export default Intents
