import React from 'react'
import { Typography, notification, Tag, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import style from './index.module.scss'
import { OnCSATPropTypes } from '../../../UserPropTypes'
import { useUser } from '../../../../../hooks/useUser'
import api from '../../../../../api'
import { transformInputChannel } from '../../../../../utils/transformInputChannel'
import getJobStatus from './getJobStatus'
import ConfirmModal from './ConfirmModal'

const CSATTab = ({ onCSAT, conversationId, channel, channelUserId }) => {
  const { environment } = useUser()

  const closeCSAT = async () => {
    let data

    await api.User.closeCSAT(
      {
        channelUserId,
        channel: transformInputChannel(channel),
      },
      { datasource: environment.toLowerCase() },
    ).then((response) => {
      data = response.data
    }).catch((error) => {
      if (error.status === 400) {
        return
      }
      throw error
    })

    if (!data) {
      // Backward compatibility with older hermes/pan
      const response = await api.User.closeCSAT(
        {
          conversationId,
          channel: transformInputChannel(channel),
        },
        { datasource: environment.toLowerCase() },
      )
      data = response.data
    }

    const jobStatus = await getJobStatus(data.jobId, environment.toLowerCase())

    if (jobStatus === 'SUCCESS') {
      notification.success({ message: '[Close CSAT] Success!' })
    } else {
      notification.error({ message: `[Close CSAT] ${jobStatus}` })
    }
  }

  return (
    <>
      <div className={style.border}>
        {onCSAT.status && (
          <>
            <div>
              Conversation ID:
              {' '}
              {conversationId
                ? <Typography.Text copyable>{conversationId}</Typography.Text>
                : (
                  <>
                    <Tag color='warning'>UNAVAILABLE</Tag>
                    <Tooltip title='Due to conversation is already closed'><QuestionCircleOutlined /></Tooltip>
                  </>
                )}
            </div>
            <div>Status: OPEN</div>
          </>
        )}
        {!onCSAT.status && (
          'No open CSAT conversations.'
        )}
      </div>
      <div className={style.pt16}>
        <ConfirmModal
          modalTitle='Confirm close CSAT conversation?'
          buttonTitle='Close CSAT conversation'
          onConfirm={closeCSAT}
          disabled={!onCSAT.status}
        />
      </div>
    </>
  )
}

CSATTab.propTypes = {
  onCSAT: OnCSATPropTypes.isRequired,
  conversationId: PropTypes.string.isRequired,
  channelUserId: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
}

export default CSATTab
