import React, { useEffect } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Switch,
  Space,
  Modal,
  notification,
  Typography,
} from 'antd'
import ClusterActions from '../../store/actions/Cluster'
import { useClusterStatusSelector } from '../../hooks/useCluster'
import JsonPreview from '../JsonPreview'

const WorkerMenu = () => {
  const clusterStatus = useClusterStatusSelector()

  useEffect(() => {
    const getWorkerStats = async () => {
      await ClusterActions.getWorkerStats()
    }

    getWorkerStats()
  }, [])

  const isClusterCurrentlyScaled = Boolean(clusterStatus.replicas)

  const showPromiseConfirm = (isCurrentlyScaled) => {
    Modal.confirm({
      title: isCurrentlyScaled ? 'This will turn off scaling' : 'This will turn on scaling',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk: async () => {
        try {
          if (isCurrentlyScaled) await ClusterActions.scaleDownWorker()
          else await ClusterActions.scaleUpWorker()
        } catch (error) {
          notification.error({
            message: 'Error',
            description: error.message,
            duration: 5,
          })
        }
      },
      onCancel: () => {},
    })
  }

  return (
    <div>
      <JsonPreview content={JSON.stringify(clusterStatus, null, 2)} autoSize />
      <Space direction='horizontal'>
        <Typography.Text strong>Worker replicas</Typography.Text>
        <Switch checked={isClusterCurrentlyScaled} onChange={() => { return showPromiseConfirm(isClusterCurrentlyScaled) }} />
      </Space>
    </div>
  )
}

export default WorkerMenu
