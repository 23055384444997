import React from 'react'
import PropTypes from 'prop-types'
import { TreeSelect, Spin } from 'antd'

import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import { treeToIntentArray } from '@core/helpers'

const { SHOW_PARENT } = TreeSelect

const IntentTreeInput = ({ setIntent, placeholder, availableIntents }) => {
  const { intentTree } = useDomainIntentSelector()

  const processIntentTree = (value) => {
    setIntent(treeToIntentArray(intentTree, value))
  }

  if (intentTree) {
    return (
      <TreeSelect
        treeData={intentTree}
        allowClear
        label='Select the domains/intents'
        onChange={processIntentTree}
        treeCheckable
        showCheckedStrategy={SHOW_PARENT}
        placeholder={placeholder}
        value={availableIntents}
        style={{ width: '100%' }}
      />
    )
  }
  return (<Spin size='small' />)
}

IntentTreeInput.propTypes = {
  setIntent: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  availableIntents: PropTypes.arrayOf(PropTypes.string),
}

IntentTreeInput.defaultProps = {
  placeholder: 'Please select a domain/intent',
  availableIntents: undefined,
}

export default IntentTreeInput
