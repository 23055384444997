import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Button,
  Divider,
  Form,
  Radio,
  DatePicker,
  InputNumber,
} from 'antd'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import style from './index.module.scss'

const { Sider } = Layout

const MAX_LIMIT = 50000
const datasources = ['staging', 'production', 'canary']

const TagsLeftSider = ({ onSearch, onRunTaggingJobClick }) => {
  const [filter, setFilter] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values) => {
    const newFilter = {
      limit: values.limit,
      datasource: values.datasource,
    }

    if (values.date_range) {
      newFilter.date_range = [
        values.date_range[0].format('YYYY-MM-DD'),
        values.date_range[1].format('YYYY-MM-DD'),
      ]
    }

    setFilter(newFilter)
    const { limit, ...searchFilter } = newFilter
    onSearch(searchFilter)
  }

  const handleRunTaggingJobClick = () => {
    onRunTaggingJobClick(filter)
  }

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Conversations</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={{
            limit: MAX_LIMIT,
            datasource: datasources[0],
          }}
        >
          <Divider>
            <small><strong>By Date Range</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='date_range'
          >
            <DatePicker.RangePicker format='DD/MM/YYYY' />
          </Form.Item>
          <Divider>
            <small><strong>Limit (Only apply for running job)</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='limit'
          >
            <InputNumber min={1} max={MAX_LIMIT} />
          </Form.Item>
          <Divider>
            <small><strong>By Source</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='datasource'
          >
            <Radio.Group size='small'>
              {datasources.map((datasource, index) => {
                return (
                  <Radio.Button key={index} value={datasource}>
                    {datasource.charAt(0).toUpperCase() + datasource.substring(1, datasource.length)}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
          >
            Search
          </Button>
          {filter && (
            <Button block style={{ marginTop: 10 }} onClick={handleRunTaggingJobClick}>
              Run Tagging Job
            </Button>
          )}
        </Form>
      </div>
    </Sider>
  )
}

TagsLeftSider.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onRunTaggingJobClick: PropTypes.func.isRequired,
}

export default TagsLeftSider
