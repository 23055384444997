import { useMemo, useReducer } from 'react'

const transformToIntentIdsObject = (initialValue) => {
  const initialSelectedIntentIdsState = { positive: [], negative: [] }
  if (
    !Array.isArray(initialValue)
    || (Array.isArray(initialValue) && !initialValue.length)
    || !initialValue
  ) {
    return initialSelectedIntentIdsState
  }

  for (let index = 0; index < initialValue.length; index++) {
    const element = initialValue[index]

    // look at the 'label' is it contain 'not-'?
    if (element.label.includes('not-')) {
      // it is negative
      initialSelectedIntentIdsState.negative.push(element)
    } else {
      initialSelectedIntentIdsState.positive.push(element)
    }
  }

  // the passed format needs to be
  // { label: <String>, value: <Number>}
  return initialSelectedIntentIdsState
}

const SELECT_INTENT_ID_ACTIONS = {
  POSITIVE_SELECT: 'SELECT_INTENT_ID_ACTIONS.POSITIVE_SELECT',
  POSITIVE_DESELECT: 'SELECT_INTENT_ID_ACTIONS.POSITIVE_DESELECT',
  POSITIVE_CLEAR: 'SELECT_INTENT_ID_ACTIONS.POSITIVE_CLEAR',
  NEGATIVE_SELECT: 'SELECT_INTENT_ID_ACTIONS.NEGATIVE_SELECT',
  NEGATIVE_DESELECT: 'SELECT_INTENT_ID_ACTIONS.NEGATIVE_DESELECT',
  NEGATIVE_CLEAR: 'SELECT_INTENT_ID_ACTIONS.NEGATIVE_CLEAR',
  UPDATE_WITH_PROPS: 'SELECT_INTENT_ID_ACTIONS.UPDATE_WITH_PROPS',
  REINITIALIZE_WITH_PROPS: 'SELECT_INTENT_ID_ACTIONS.REINITIALIZE_WITH_PROPS',
  RESET: 'SELECT_INTENT_ID_ACTIONS.RESET',
}

const SELECT_INTENT_ID_INITIAL_STATE = {
  positive: [],
  negative: [],
}

const selectedIntentIdReducer = (state = SELECT_INTENT_ID_INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_INTENT_ID_ACTIONS.POSITIVE_SELECT: {
      const { option } = action.payload

      return {
        ...state,
        positive: state.positive.concat({ label: option.label, value: option.value }),
      }
    }
    case SELECT_INTENT_ID_ACTIONS.POSITIVE_DESELECT: {
      const { deselectedIntentId } = action.payload

      return {
        ...state,
        positive: state.positive.filter((intent) => {
          return intent.value !== deselectedIntentId
        }),
      }
    }
    case SELECT_INTENT_ID_ACTIONS.POSITIVE_CLEAR: {
      return {
        ...state,
        positive: [],
      }
    }
    case SELECT_INTENT_ID_ACTIONS.NEGATIVE_SELECT: {
      const { option } = action.payload

      return {
        ...state,
        negative: state.negative.concat({ label: option.label, value: option.value }),
      }
    }
    case SELECT_INTENT_ID_ACTIONS.NEGATIVE_DESELECT: {
      const { deselectedIntentId } = action.payload

      return {
        ...state,
        negative: state.negative.filter((intent) => {
          return intent.value !== deselectedIntentId
        }),
      }
    }
    case SELECT_INTENT_ID_ACTIONS.NEGATIVE_CLEAR: {
      return {
        ...state,
        negative: [],
      }
    }
    // merge with current state and from props
    case SELECT_INTENT_ID_ACTIONS.UPDATE_WITH_PROPS: {
      const { assignedIntents } = action.payload
      const { positive, negative } = state
      const spreadIntentsArray = [...positive, ...negative, ...assignedIntents]
      const uniqifiedIntents = Object.values(
        spreadIntentsArray.reduce((initIntentsObject, intent) => {
          return { ...initIntentsObject, [intent.value]: intent }
        }, {}),
      )

      return transformToIntentIdsObject(uniqifiedIntents)
    }
    // reset based on action.payload.assignedIntents
    case SELECT_INTENT_ID_ACTIONS.REINITIALIZE_WITH_PROPS: {
      // when props change meaning it
      // - retrieves from success intent change response
      // - email was changed
      // so it needs to be relies FULLY on new payload

      const { assignedIntents } = action.payload

      return transformToIntentIdsObject(assignedIntents)
    }
    case SELECT_INTENT_ID_ACTIONS.RESET: {
      return SELECT_INTENT_ID_INITIAL_STATE
    }

    default: return state
  }
}

const useSelectedIntentIds = (initialAssignedIntentProps) => {
  const [state, dispatch] = useReducer(selectedIntentIdReducer, initialAssignedIntentProps, transformToIntentIdsObject)

  const {
    selectPositive,
    deselectPositive,
    clearPositive,
    selectNegative,
    deselectNegative,
    clearNegative,
    updateFromProps,
    reinitializeWithProps,
    reset,
  } = useMemo(() => {
    return {
      selectPositive: (option) => {
        dispatch({
          type: SELECT_INTENT_ID_ACTIONS.POSITIVE_SELECT,
          payload: { option },
        })
      },
      deselectPositive: (deselectedIntentId) => {
        dispatch({
          type: SELECT_INTENT_ID_ACTIONS.POSITIVE_DESELECT,
          payload: { deselectedIntentId },
        })
      },
      clearPositive: () => {
        dispatch({ type: SELECT_INTENT_ID_ACTIONS.POSITIVE_CLEAR })
      },
      selectNegative: (option) => {
        dispatch({
          type: SELECT_INTENT_ID_ACTIONS.NEGATIVE_SELECT,
          payload: { option },
        })
      },
      deselectNegative: (deselectedIntentId) => {
        dispatch({
          type: SELECT_INTENT_ID_ACTIONS.NEGATIVE_DESELECT,
          payload: { deselectedIntentId },
        })
      },
      clearNegative: () => {
        dispatch({ type: SELECT_INTENT_ID_ACTIONS.NEGATIVE_CLEAR })
      },
      updateFromProps: (assignedIntents) => {
        dispatch({
          type: SELECT_INTENT_ID_ACTIONS.UPDATE_WITH_PROPS,
          payload: { assignedIntents },
        })
      },
      reinitializeWithProps: (assignedIntents) => {
        dispatch({
          type: SELECT_INTENT_ID_ACTIONS.REINITIALIZE_WITH_PROPS,
          payload: { assignedIntents },
        })
      },
      reset: () => {
        dispatch({ type: SELECT_INTENT_ID_ACTIONS.RESET })
      },
    }
  }, [])

  return [
    state,
    {
      selectPositive,
      deselectPositive,
      clearPositive,
      selectNegative,
      deselectNegative,
      clearNegative,
      updateFromProps,
      reinitializeWithProps,
      reset,
    },
  ]
}

export default useSelectedIntentIds
