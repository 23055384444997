import React from 'react'
import PropTypes from 'prop-types'
import { Result, Typography, Space } from 'antd'

import useRequest from '@core/hooks/useRequest'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import JsonPreview from '@core/components/JsonPreview'
import { shallowCleanFalsyObject, sortTextOrNull } from '@core/helpers'

import DataTable from '../../../components/DataTable'
import Api from '../../../api'

const fixedDeploymentGroupsFilter = { clientGroupType: ['DEPLOYMENTS'] }

const expandedRowRenderWrapper = () => {
  return (record, _, __, expanded) => {
    if (!expanded) return null
    return (
      <JsonPreview content={JSON.stringify(record, null, 2)} autoSize />
    )
  }
}

const DataTab = ({
  filter,
  handlePaginationChange,
  environment,
}) => {
  const clientGroupQuery = { environment, ...filter, ...fixedDeploymentGroupsFilter }
  const [{ loading, data, hasError }] = useRequest(Api.ClientGroups.findMany, shallowCleanFalsyObject(clientGroupQuery))

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }

  const { items, meta } = data

  let featureNames = []
  let channelsName = []
  items?.forEach((item) => {
    featureNames = featureNames.concat(
      Object.values(item?.latestFeatures || {})
      .filter((feature) => { return feature.value })
      .map(({ name }) => { return name }),
    )
    channelsName = channelsName.concat(
      Object.values(item?.latestChannels || {})
      .filter((channel) => { return channel.value })
      .map(({ name }) => { return name }),
    )
  })

  const sortedFeatureNames = Array.from(new Set(featureNames))?.sort()
  const sortedChannelNames = Array.from(new Set(channelsName))?.sort()

  const assignedFeaturesRenderText = sortedFeatureNames?.join(', ') || '-'
  const assignedChannelsRenderText = sortedChannelNames?.join(', ') || '-'

  const columnsTable = [
    {
      title: (<strong>Deployment Group</strong>),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return sortTextOrNull(a.name, b.name) },
    },
  ]

  return (
    <>
      <DataTable
        columns={columnsTable}
        dataSource={items}
        meta={meta}
        filter={filter}
        onPaginationChange={handlePaginationChange}
        expandable={{
          expandedRowRender: expandedRowRenderWrapper(),
        }}
      />
      <Typography.Title level={5} style={{ paddingBottom: 10 }}>
        <Typography.Text strong>Summary</Typography.Text>
      </Typography.Title>
      <Space align='center' style={{ paddingBottom: 10 }}>
        <Typography.Text strong>All Accessible Channels: </Typography.Text>
        <Typography.Text>
          {assignedChannelsRenderText}
        </Typography.Text>
      </Space>
      <br />
      <Space align='center' style={{ paddingBottom: 10 }}>
        <Typography.Text strong>All Available Features: </Typography.Text>
        <Typography.Text>
          {assignedFeaturesRenderText}
        </Typography.Text>
      </Space>
    </>
  )
}

DataTab.propTypes = {
  environment: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
}

export default DataTab
