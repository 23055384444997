import React from 'react'
import { Form, Select, Divider } from 'antd'

const approvalStatusOptions = [
  { label: 'Submitted', value: 'SUBMITTED' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Untagged', value: 'UNTAGGED' },
]

const ByApprovalStatus = () => {
  return (
    <>
      <Divider>
        <small><strong> By Approval Statuses </strong></small>
      </Divider>
      <Form.Item name='approvalStatuses'>
        <Select
          mode='multiple'
          placeholder='Select Statuses'
          allowClear
          options={approvalStatusOptions}
        />
      </Form.Item>
    </>
  )
}

export default ByApprovalStatus
