import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, Button, Space } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import ConversationAnalysisActions from '../../store/actions/analytics'

const SyncAnalysis = () => {
  const { results, lastUpdate, analysisKey, pageSize } = useSelector((state) => { return state.conversationAnalysis.analytics })
  const params = { pageSize }
  if (results) {
    const { meta } = results
    if (meta) {
      const { page } = meta
      if (page) params.page = page
    }
  } else params.page = 1

  return (
    <Space size='large'>
      <Button
        icon={<SyncOutlined />}
        onClick={() => { return ConversationAnalysisActions.selectForAnalyze(analysisKey, params) }}
      >
        Refresh
      </Button>
      {lastUpdate && <Typography.Text type='secondary'>{dayjs(lastUpdate).format('MMM/DD/YYYY HH:mm')}</Typography.Text>}
    </Space>
  )
}

export default SyncAnalysis
