import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Button,
  Divider,
  Layout,
  Tabs,
  Descriptions,
  Typography,
  Spin,
} from 'antd'
import { BugOutlined, SearchOutlined } from '@ant-design/icons'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import ConversationPage from '@core/components/ConversationPage'
import { getComputedConversationMessages } from '../../helpers'
import Api from '../../api'

import ConversationStatistics from '../ConversationStatistics'

import style from './index.module.scss'
import PNRModal from './PNRModal'

const { Sider } = Layout
const { TabPane } = Tabs

const RightSider = ({
  selectedConversationId,
  querySource,
  width,
  showInspectionButton,
  showStats,
}) => {
  const mounted = useRef()
  const isDebugAllowed = usePermittedFeaturesLookup('conversations.query.actionDebug')
  const isShowConversationDetails = usePermittedFeaturesLookup('conversations.query.conversationDetails')
  const isShowFullConversationDetails = usePermittedFeaturesLookup('conversations.query.fullConversationDetails')
  const [activeKey, setActiveKey] = useState('stats')
  const [pnrModalVisibility, setPnrModelVisibility] = useState(false)

  const handleActiveKeyChange = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const [conversation, setConversation] = useState(null)
  const [pnrs, setPnrs] = useState(null)
  const [selectedPnr, setSelectedPnr] = useState(null)
  const url = '/conversations/query'

  useEffect(() => {
    mounted.current = true
    const datasource = querySource || undefined
    const getConversations = async () => {
      setPnrs(null)
      if (selectedConversationId === null) {
        setConversation(null)
        return
      }

      try {
        const { data } = await Api.Conversations.getOne(selectedConversationId, { datasource })

        if (mounted.current && data) {
          setConversation({
            ...data,
            computedMessages: data && data.messages ? getComputedConversationMessages(data.messages) : [],
          })
        }
      } catch (error) {
        setConversation(null)
      }
    }

    getConversations()

    return () => {
      mounted.current = false
    }
  }, [selectedConversationId, querySource])

  useEffect(() => {
    const analyzeMessages = async (messages) => {
      const analyzedMessages = await Promise.all(messages.map(async (message) => {
        const { data } = await Api.Analysis.extractInformation({ text: message.text })

        return data
      }))

      const pnrList = analyzedMessages.reduce((prev, message) => {
        if (!message.data) {
          return prev
        }

        const {
          pnrs: gbtPnrs,
          approximatedPnrs,
          supplierPnrs,
        } = message.data

        const result = [
          ...prev,
          ...(gbtPnrs ?? []).map((pnr) => { return { pnr: pnr.toUpperCase(), type: 'GBT' } }),
          ...(approximatedPnrs ?? []).map((pnr) => { return { pnr: pnr.toUpperCase(), type: 'Approximated' } }),
          ...(supplierPnrs ?? []).map((pnr) => { return { pnr: pnr.toUpperCase(), type: 'Supplier' } }),
        ]

        return result
      }, []).filter((value, index, self) => {
        return index === self.findIndex((item) => {
          return (
            item.pnr === value.pnr
          )
        })
      })

      setPnrs(pnrList)
    }

    if (conversation?.computedMessages) {
      const filteredMessages = conversation?.computedMessages.filter((message) => { return message.messageType === 'CUSTOMER' })

      if (filteredMessages.length === 0) {
        setPnrs([])
      }

      analyzeMessages(filteredMessages)
    }
  }, [conversation?.computedMessages, conversation?.computedMessages.length])

  const selectPnr = (pnr) => {
    setSelectedPnr(pnr)
    setPnrModelVisibility(true)
  }

  const pnrList = (
    <>
      {pnrs !== null && pnrs.length === 0 && (
        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='PNRs'>
          <Typography.Text>
            -
          </Typography.Text>
        </Descriptions.Item>
      )}
      {pnrs === null && (
        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='PNRs'>
          <Typography.Text>
            <Spin size='small' />
          </Typography.Text>
        </Descriptions.Item>
      )}
      {pnrs !== null && pnrs.length !== 0 && pnrs.map((pnr, index) => {
        return (
          <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={index === 0 ? 'PNRs' : ''} key={pnr.pnr}>
            <Typography.Text>
              {pnr.pnr.toUpperCase()}
              <SearchOutlined className='ant-typography-copy' onClick={() => { selectPnr(pnr) }} />
            </Typography.Text>
          </Descriptions.Item>
        )
      })}
    </>
  )

  return (
    <Sider
      className={style.ConversationRightSider}
      defaultCollapsed
      collapsed={!selectedConversationId}
      theme='light'
      width={width}
      collapsedWidth={0}
    >
      {conversation
      && (
        <>
          {showStats && conversation.stats ? (
            <>
              <Tabs activeKey={activeKey} onChange={handleActiveKeyChange} style={{ paddingRight: 16 }}>
                <TabPane key='stats' tab='Stats'>
                  <ConversationStatistics stats={conversation.stats} />
                </TabPane>
              </Tabs>
              <Divider />
            </>
          ) : null}
          { isShowConversationDetails && (
            <Descriptions style={{ marginBottom: 16, width: '600px' }} title='Conversation Details' bordered column={1}>
              { isShowFullConversationDetails && (
                <>
                  <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Conversation ID'>
                    <Typography.Text copyable>{conversation.id}</Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Email'>
                    <Typography.Text copyable>{conversation.extra?.email || 'N/A'}</Typography.Text>
                  </Descriptions.Item>
                </>
              )}
              <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Conversation URL'>
                <Typography.Text
                  style={{ width: 400 }}
                  copyable
                  ellipsis={{ tooltip: `${window.location.origin}${url}/${conversation.id}${querySource ? `?datasource=${querySource}` : ''}` }}
                >
                  {`${window.location.origin}${url}/${conversation.id}${querySource ? `?datasource=${querySource}` : ''}`}
                </Typography.Text>
              </Descriptions.Item>
              {pnrList}
            </Descriptions>
          ) }
          {(isDebugAllowed && showInspectionButton) && (
            <Link
              to={{
                pathname: querySource ? `${url}/${conversation.id}?datasource=${querySource}` : `${url}/${conversation.id}`,
                state: {},
              }}
              style={{ width: 'fit-content' }}
              tabIndex={-1}
            >
              <Button
                title='Debug Conversation'
                icon={<BugOutlined />}
              >
                Inspect
              </Button>
            </Link>
          )}
          <Divider />
          <ConversationPage messages={conversation.computedMessages} />

          <PNRModal
            modalVisibility={pnrModalVisibility}
            setModalVisibility={setPnrModelVisibility}
            pnrList={pnrs}
            selectedPnr={selectedPnr?.pnr}
            selectedPnrType={selectedPnr?.type}
            datasource={querySource}
          />
        </>
      )}
    </Sider>
  )
}

RightSider.propTypes = {
  selectedConversationId: PropTypes.string,
  querySource: PropTypes.string,
  width: PropTypes.number,
  showInspectionButton: PropTypes.bool,
  showStats: PropTypes.bool,
}

RightSider.defaultProps = {
  selectedConversationId: null,
  querySource: undefined,
  width: 640,
  showInspectionButton: true,
  showStats: true,
}

export default RightSider
