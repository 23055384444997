import React from 'react'
import PropTypes from 'prop-types'

import BaseAddOrEditTestCollectionButton from '../BaseAddOrEditTestCollectionButton'

import Api from '../../../../../api'

const { insertTestCollection } = Api.EndToEndTestCollection

const AddTestCollectionButton = ({
  buttonProps,
  afterSuccessHandler,
  initialValues,
  modalTitle,
}) => {
  return (
    <BaseAddOrEditTestCollectionButton
      buttonProps={buttonProps}
      submitHandler={insertTestCollection}
      afterSuccessHandler={afterSuccessHandler}
      initialValues={initialValues}
      modalTitle={modalTitle}
    />
  )
}

AddTestCollectionButton.propTypes = {
  buttonProps: PropTypes.shape({
    size: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  afterSuccessHandler: PropTypes.func,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
  }),
  modalTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
}

AddTestCollectionButton.defaultProps = {
  buttonProps: {
    size: 'small',
    type: 'secondary',
    text: 'Add New Collection',
  },
  afterSuccessHandler: () => {},
  initialValues: {
    description: null,
  },
  modalTitle: 'Add new collection',
}

export default AddTestCollectionButton
