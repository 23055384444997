import cloneDeep from 'lodash.clonedeep'
import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { splitGeneralizedSentenceByHighlighted } from '@core/helpers/sentence'
import { SENTENCE } from '../constants'
import Api from '../../api'

const defaultFilter = {
  texts: [],
  sources: [],
  has_test_sets: [],
  has_training_sets: [],
  expected_generalized_types: [],
  test_intents: [],
  test_domains: [],
  is_sentence_duplicate: [],
  sentence_ids: [],
  desired_results: [],
}

const hasGeneralizedKeyword = (generalized) => {
  if (generalized.length === 1) return generalized[0].isTag

  let hasGeneralized = true

  for (let index = 0; index < generalized.length; index++) {
    // bail out early if found one
    if (generalized[index].isTag) {
      hasGeneralized = true // needs a reset if the 0 index is false then 1 index is true
      break
    }

    if (!generalized[index].isTag) {
      hasGeneralized = false
    }
  }

  return hasGeneralized
}

// TODO: We might need the state data, in case where the sentence response missing some fields
const reStructureSentences = (sentenceSource) => {
  return {
    sentencesHash: sentenceSource.reduce((acc, current, index) => {
      acc[current.id] = index
      return acc
    }, {}),
    listed: sentenceSource.map((sentence, index) => {
      const generalizedHtml = splitGeneralizedSentenceByHighlighted(sentence.generalized.generalized)
      return {
        ...sentence,
        hasGeneralizedKeyword: hasGeneralizedKeyword(generalizedHtml),
        generalizedHtml,
        cursor: index,
      }
    }),
    listedCheck: sentenceSource.reduce((acc, current) => {
      acc[current.id] = false
      return acc
    }, {}),
  }
}

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  reset: () => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE.RESET })
    }
  },
  select: (sentence, rightPanelType) => {
    return async (dispatch) => {
      dispatch({
        type: SENTENCE.SELECTED,
        payload: {
          sentence,
          rightPanelType,
        },
      })
    }
  },
  fetch: (filter = defaultFilter) => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE.FETCH.REQUEST, payload: { filter } })
      try {
        const response = await Api.getSentences(filter)

        if (response && response.data) {
          const newSentencesList = reStructureSentences(response.data.items)
          const payload = {
            ...newSentencesList,
            meta: response.data.meta,
          }
          dispatch({
            type: SENTENCE.FETCH.SUCCESS,
            payload,
          })

          return payload
        }

        dispatch({
          type: SENTENCE.FETCH.FAILED,
          payload: {
            listed: [],
          },
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SENTENCE.FETCH.FAILED,
          payload: {
            listed: [],
            error,
          },
        })

        throw error
      }
    }
  },
  addNewSentence: (formData) => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE.ADD_NEW.REQUEST })
      try {
        const response = await Api.addSentence(formData)
        if (response && response.status === 'success') {
          dispatch({
            type: SENTENCE.ADD_NEW.SUCCESS,
          })

          return response.data
        }

        dispatch({
          type: SENTENCE.ADD_NEW.FAILED,
          payload: null,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SENTENCE.ADD_NEW.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  addNewSentences: (formData) => {
    return async (dispatch) => {
      dispatch({ type: SENTENCE.BULK_MODE.ADD_NEW.REQUEST })
      try {
        const response = await Api.addSentences(formData)
        if (response && response.status === 'success') {
          dispatch({
            type: SENTENCE.BULK_MODE.ADD_NEW.SUCCESS,
          })

          return response.data
        }

        dispatch({
          type: SENTENCE.BULK_MODE.ADD_NEW.FAILED,
          payload: null,
          error: true,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SENTENCE.BULK_MODE.ADD_NEW.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  clear: () => {
    return (dispatch) => {
      dispatch({ type: SENTENCE.FETCH.RESET })
    }
  },
  delete: () => {
    return async (dispatch, getState) => {
      dispatch({ type: SENTENCE.DELETE.REQUEST })

      const selectedSentencesIds = getState().data.bulkOperationData.selectedSentences

      try {
        const response = await Api.deleteSentences({ sentence_ids: selectedSentencesIds })

        if (response && response.data) {
          const {
            sentences: {
              listed,
              sentencesHash,
            },
          } = getState().data

          const updatedSentences = cloneDeep(listed)
          response.data.forEach((removedSentences) => {
            updatedSentences.splice(sentencesHash[removedSentences.id], 1)
          })

          return dispatch({
            type: SENTENCE.DELETE.SUCCESS,
            payload: reStructureSentences(updatedSentences),
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SENTENCE.DELETE.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  edit: (sentenceId, editedSentence) => {
    return async (dispatch, getState) => {
      dispatch({ type: SENTENCE.EDIT.REQUEST })
      try {
        const response = await Api.editSentence(sentenceId, editedSentence)
        if (response && response.data) {
          let { sentences: { listed } } = getState().data

          if (response.data.id !== sentenceId) {
            const originalIndex = listed.findIndex((sentence) => { return sentence.id === sentenceId })
            listed.splice(originalIndex, 0, {
              ...listed[originalIndex],
              ...response.data,
              changed_timestamp: response.data.updated_at,
            })
          } else {
            listed = listed.map((sentence) => {
              if (sentence.id === sentenceId) {
                return {
                  ...sentence,
                  ...response.data,
                  changed_timestamp: response.data.updated_at,
                }
              }
              return sentence
            })
          }
          dispatch({
            type: SENTENCE.EDIT.SUCCESS,
            payload: listed,
          })
          return { success: true }
        }
        dispatch({
          type: SENTENCE.EDIT.FAILED,
        })
        return { success: false, errorCode: null }
      } catch (e) {
        dispatch({ type: SENTENCE.EDIT.FAILED })
        return { success: false, errorCode: e.status }
      }
    }
  },
  bulkToggleUnrecognized: (unrecognized) => {
    return async (dispatch, getState) => {
      const selectedSentencesIds = getState().data.bulkOperationData.selectedSentences

      dispatch({ type: SENTENCE.BULK_MODE.TOGGLE_UNRECOGNIZED.REQUEST })
      try {
        const response = await Api.bulkToggleUnrecognized({ sentence_ids: selectedSentencesIds, unrecognized })

        if (response && response.status === 'success') {
          const {
            sentences: {
              listed,
              sentencesHash,
            },
          } = getState().data

          const updatedSentences = cloneDeep(listed)
          for (let index = 0; index < response.data.length; index++) {
            const timeStamp = Date.now()
            updatedSentences[sentencesHash[response.data[index].id]] = {
              ...updatedSentences[sentencesHash[response.data[index].id]],
              ...response.data[index],
              changed_timestamp: `${new Date(timeStamp).toLocaleDateString('en-US')} ${new Date(timeStamp).toLocaleTimeString('en-US')}`,
              // NOTE: Bulk edit does not sends these two back
              generalized: updatedSentences[sentencesHash[response.data[index].id]].generalized,
              generalizedHtml: updatedSentences[sentencesHash[response.data[index].id]].generalizedHtml,
            }
          }

          dispatch({
            type: SENTENCE.BULK_MODE.TOGGLE_UNRECOGNIZED.SUCCESS,
            payload: {
              newSentencesSet: reStructureSentences(updatedSentences).listed,
            },
          })

          return response
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: SENTENCE.BULK_MODE.TOGGLE_UNRECOGNIZED.FAILED })
        throw error
      }
    }
  },
})
