import React from 'react'
import {
  Form,
  Typography,
  Select,
  Input,
  Tooltip,
} from 'antd'
import { domainListType } from '@core/types/domainIntentCollectionTypes'
import { testDomainIdType, noteType, onDomainChangeType } from '../../../types/updateCaseModal'
import { formInstanceType } from '../../../types/form'
import { generalizedType } from '../../../types/generalized'

const { Title } = Typography

const DomainForm = ({
  form,
  test_domain_id,
  note,
  domainList,
  onDomainChange,
  generalized,
}) => {
  return (
    <Form
      form={form}
      layout='vertical'
      name='form__update_test_domain'
      initialValues={{
        test_domain_id: test_domain_id || -1,
        note,
      }}
    >
      <Title level={4}>{`Modify Domain test case for: ${generalized.original}`}</Title>

      <Tooltip
        title='If you select a null domain, this will automatically remove the expected intent'
        color='orange'
      >
        <Form.Item
          name='test_domain_id'
          label='Test Domain'
          rules={[
            {
              required: true,
              message: 'Please selected test domain',
            },
          ]}
        >
          <Select
            placeholder='Select a test domain'
            onChange={onDomainChange}
            allowClear
          >
            <Select.Option value={-1} key={domainList.length}>NULL</Select.Option>
            <Select.OptGroup label='Domains'>
              {domainList.map((domain, index) => {
                return (
                  <Select.Option value={domain.id} key={index}>{domain.name}</Select.Option>
                )
              })}
            </Select.OptGroup>
          </Select>
        </Form.Item>
      </Tooltip>
      <Form.Item
        name='note'
        label='Note'
      >
        <Input.TextArea
          showCount
          maxLength={255}
          style={{ resize: 'vertical' }}
        />
      </Form.Item>
    </Form>
  )
}

DomainForm.propTypes = {
  form: formInstanceType.isRequired,
  test_domain_id: testDomainIdType.isRequired,
  note: noteType.isRequired,
  domainList: domainListType.isRequired,
  onDomainChange: onDomainChangeType.isRequired,
  generalized: generalizedType.isRequired,
}

export default DomainForm
