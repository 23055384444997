import React from 'react'
import { Divider, Form, Select } from 'antd'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../../api'

const baseOptions = [
  { label: 'none', value: 'none' },
]

const ByCapIdAndEmployment = () => {
  const [{ loading, data }] = useRequest(Api.ClientGroups.findAvailableCapIdsAndCountryOfEmployment)
  const availableCapIds = data?.availableCapIds || []
  const availableCountryOfEmployment = data?.availableCountryOfEmployment || []

  const capIdOptionsExcludeBaseOptions = availableCapIds.filter((item) => { return item !== '*' }).sort((a, b) => { return a.localeCompare(b) }).map((item) => { return { label: item, value: item } })
  const capIdOptions = [...baseOptions, ...capIdOptionsExcludeBaseOptions]

  const countryOfEmploymentOptionsExcludeBaseOptions = availableCountryOfEmployment.filter((item) => { return item !== '*' }).sort((a, b) => { return a.localeCompare(b) }).map((item) => { return { label: item, value: item } })
  const countryOfEmploymentOptions = [...baseOptions, ...countryOfEmploymentOptionsExcludeBaseOptions]

  return (
    <>
      <Divider>
        <small><strong>By Client Group</strong></small>
      </Divider>
      <Form.Item name='capIds' label='CAPIDs'>
        <Select
          loading={loading}
          mode='tags'
          placeholder='Select CAPID to search for'
          style={{ width: '100%' }}
          allowClear
          options={capIdOptions}
        />
      </Form.Item>
      <Form.Item name='countryOfEmployments' label='COEs'>
        <Select
          loading={loading}
          mode='tags'
          placeholder='Select COE to search for'
          style={{ width: '100%' }}
          allowClear
          options={countryOfEmploymentOptions}
        />
      </Form.Item>
    </>
  )
}

export default ByCapIdAndEmployment
