import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  List,
  Avatar,
  Row,
  Col,
  Typography,
  Badge,
  Input,
} from 'antd'
import dayjs from 'dayjs'
import ConversationPage from '../ConversationPage'
import style from './index.module.scss'
import { humanizeDuration } from '../../helpers'

const ConversationsPage = ({ conversations }) => {
  const [selectedConversation, setSelectedConversation] = useState(null)
  const [computedConversations, setComputedConversations] = useState([])
  const [filteredConversations, setFilteredConversations] = useState([])

  useEffect(() => {
    setSelectedConversation(null)
    setComputedConversations(
      conversations.map((conversation, index) => {
        const firstMessage = conversation.messages[0]
        const firstAgentMessage = conversation.messages.find(({ message_type }) => { return message_type === 'AGENT' })

        let humanizedDurationToAgent = '-'

        if (firstAgentMessage) {
          humanizedDurationToAgent = humanizeDuration(
            dayjs(firstAgentMessage.created_at).diff(firstMessage.created_at),
          )
        }

        return {
          ...conversation,
          sequence: index + 1,
          humanizedDurationToAgent,
        }
      }),
    )
  }, [conversations])

  useEffect(() => {
    setFilteredConversations(computedConversations)
  }, [computedConversations])

  if (selectedConversation) {
    const messages = selectedConversation.messages ? selectedConversation.messages.map((message) => {
      return {
        id: message.id,
        text: message.original,
        created_at: message.created_at,
        direction: message.message_type === 'CUSTOMER' ? 'left' : 'right',
        messageType: message.message_type,
      }
    }) : []

    return (
      <>
        <div className={style.conversation__header}>
          <Row justify='space-between'>
            <Col span='4'>
              <Button onClick={() => { return setSelectedConversation(null) }}>
                Back
              </Button>
            </Col>
            <Col span='16' style={{ textAlign: 'center' }}>
              <Typography.Title level={5} style={{ marginTop: 4 }}>
                Time to agent:
                {' '}
                {selectedConversation.humanizedDurationToAgent}
              </Typography.Title>
            </Col>
            <Col span='4' style={{ textAlign: 'right' }}>
              <Badge count={`${messages.length}`} style={{ marginTop: 4 }} />
            </Col>
          </Row>
        </div>
        <div className={style.conversation__container}>
          <ConversationPage messages={messages} />
        </div>
      </>
    )
  }

  const handleSearch = (search) => {
    const lowerCaseSearch = search.trim().toLowerCase()

    if (!lowerCaseSearch) {
      setFilteredConversations(computedConversations)
      return
    }

    setFilteredConversations(
      computedConversations.filter(
        (conversation) => {
          return conversation.messages.some(
            (message) => { return message.original.toLowerCase().includes(lowerCaseSearch) },
          )
        },
      ),
    )
  }

  return (
    <>
      <div className={style.conversations__header}>
        <Input.Search placeholder='input search text' onSearch={handleSearch} />
      </div>
      <div className={style.conversations__container}>
        <List
          size='small'
          itemLayout='horizontal'
          dataSource={filteredConversations}
          renderItem={(conversation) => {
            const Title = (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                className={style.conversations__list_item_title}
                role='button'
                tabIndex={0}
                onClick={() => { return setSelectedConversation(conversation) }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setSelectedConversation(conversation)
                  }
                }}
              >
                {conversation.messages[0].original}
              </a>
            )

            const Description = (
              <>
                <span>{dayjs(conversation.messages[0].created_at).format('llll')}</span>
                <span style={{ float: 'right' }}>{conversation.humanizedDurationToAgent}</span>
              </>
            )

            return (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar>{conversation.sequence}</Avatar>}
                  title={Title}
                  description={Description}
                />
              </List.Item>
            )
          }}
        />
      </div>
    </>
  )
}

ConversationsPage.propTypes = {
  conversations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      original: PropTypes.string.isRequired,
      message_type: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
}

export default ConversationsPage
