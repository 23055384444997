export const convertFaqToCsvString = (faqs, choicesResponse) => {
  // NOTE:
  /*
    what this function handle escape is:
    - double-quotes: wrap all string in the double quotes, and replace double-quotes inside with preceding double-quotes

    on complex fields or nested array/object:
    - it just json stringify
  */
  if (!Array.isArray(faqs)) throw new Error('convertFaqToCsvString:: Incompatible argument, must be array')

  const flattenedChoicesHash = choicesResponse.reduce((choiceNameHash, choiceCategory) => {
    choiceCategory.choices.reduce((obj, choice) => {
      choiceNameHash[choice.id] = {
        ...choice,
        parentCategory: {
          id: choiceCategory.id,
          name: choiceCategory.name,
        },
      }
      return obj
    }, {})
    return choiceNameHash
  }, {})

  let csvString = 'Category,Subcategory,Main_Sentence,Other_Question_Text_Variantions,Choice_Category,Default_Fallback_Response,Selected_Product_Answer_Variations,Language,Created_Date\n'

  for (let index = 0; index < faqs.length; index++) {
    const faq = faqs[index]
    let row = ''
    const similarQuestion = JSON.stringify(faq.similar_questions, ['text']).replaceAll('"', '""')
    const choiceCategoryName = `${faq.choice_category_id ? faq.choice_category.name : 'Direct Response'}`
    const fallbackResponse = JSON.stringify(faq.choice_category_id ? faq.fallback_response.lines : []).replaceAll('"', '""')
    const answerVariations = JSON.stringify(faq.responses.data.map(({ lines, choice_id }) => { return { lines, choice_category_name: flattenedChoicesHash[choice_id]?.name } })).replaceAll('"', '""')

    row = `"${faq.category.name}","${faq.subcategory.name}","${faq.main_question.text.replaceAll('"', '""')}","${similarQuestion}","${choiceCategoryName}","${fallbackResponse}","${answerVariations}","${faq.language}","${faq.created_at}"`

    csvString = csvString.concat(row, '\n')
  }

  return csvString
}

export const processFaqEntityPayload = (values, faqVersionId) => {
  const {
    faq_questions,
    fallback_response,
    language,
    category_id,
    subcategory_id,
    choice_category_id,
  } = values
  const [main_question, ...similar_questions] = faq_questions

  const payload = {}

  // shared part
  payload.language = language
  payload.faq_version_id = faqVersionId

  // question part
  payload.category_id = category_id
  payload.subcategory_id = subcategory_id
  payload.main_question = main_question
  if (similar_questions && similar_questions.length) {
    payload.similar_questions = similar_questions
  } else {
    payload.similar_questions = []
  }

  // answer part
  if (!choice_category_id) {
    payload.choice_category_id = null
    const directResponseEntries = Object.entries(values).filter(([key]) => { return key.indexOf('directResponse') !== -1 })
    payload.responses = directResponseEntries.length
      ? { data: directResponseEntries.map(([, lines]) => { return { lines } }) }
      : {}
  } else {
    payload.choice_category_id = choice_category_id
    payload.fallback_response = { lines: fallback_response }
    const answerEntries = Object.entries(values).filter(([key]) => { return key.indexOf('choices') !== -1 })

    payload.responses = answerEntries.length
      ? {
        data: answerEntries.map(([key, lines]) => {
          return {
            choice_id: key.split('.')[1],
            lines,
          }
        }),
      } : {}
  }

  return payload
}
