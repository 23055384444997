import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Layout,
  Typography,
  Input,
  Button,
  Row,
  Col,
  PageHeader,
  Form,
  notification,
} from 'antd'
import { useInternationalizeVersionDetail } from '@core/hooks/useInternationalize'

import InternationalActions from '@core/store/actions/Internationalize'
import VersionsActions from '../../../store/actions/Versions'

const { Content } = Layout
const { Title, Text } = Typography

const EditVersion = () => {
  const [form] = Form.useForm()
  const { version_id: versionId } = useParams()
  const history = useHistory()
  const version = useInternationalizeVersionDetail(versionId)
  const [hasError, setHasError] = useState(false)
  const onBack = () => { history.replace('/versions/i18n') }
  const onSuccessEditVersion = onBack

  // Do we need this?
  useEffect(() => {
    const fetchData = async () => {
      if (versionId) {
        await VersionsActions.get()
      }
    }

    fetchData()
  }, [versionId])

  // NOTE: antd not properly update initialValues, or is it because of 'initial'Values
  useEffect(() => {
    if (version && version.id) {
      form.setFieldsValue({
        note: version.note,
      })
    }
  }, [form, version])

  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        response = await InternationalActions.update({ id: versionId, ...values })

        if (response) {
          form.resetFields()
          notification.success({
            message: `Internationalize Version #${versionId} successfully updated`,
            duration: 10,
          })
          // navigate back to the Version page
          onSuccessEditVersion()
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Content className='EditVersion'>
      <PageHeader onBack={onBack} subTitle='Back to i18n Versions List'>
        <Title>{`Edit i18n Version #${versionId}`}</Title>
      </PageHeader>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          note: version && version.note ? version.note : undefined,
        }}
      >
        <Row>
          <Col span='24'>
            <Form.Item
              name='note'
              label='Version note'
              rules={[
                {
                  required: true,
                  message: 'Please add version note',
                },
              ]}
            >
              <Input placeholder='Enter version note' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Button type='primary' htmlType='submit'>Submit</Button>
              {hasError && (<Text type='danger'>&thinsp;&thinsp;&thinsp;We&#39;ve encountered some problems, please try again in few minutes</Text>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  )
}

export default EditVersion
