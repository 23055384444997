import React from 'react'
import { Form, Select } from 'antd'
import PropTypes from 'prop-types'

const baseOptions = [
  { label: 'Unknown', value: 'unknown' },
  { label: '*', value: '*' },
]

const CapIdCountryOfEmploymentSelect = ({ data }) => {
  const availableCapIds = data?.availableCapIds || []
  const availableCountryOfEmployment = data?.availableCountryOfEmployment || []

  const capIdOptionsExcludeBaseOptions = availableCapIds?.filter((item) => { return item !== '*' }) || []
  const capIdOptions = baseOptions.concat(
    capIdOptionsExcludeBaseOptions
    .sort((a, b) => { return a.localeCompare(b) })
    .map((item) => { return { label: item, value: item } }),
  )

  const countryOfEmploymentOptionsExcludeBaseOptions = availableCountryOfEmployment?.filter((item) => { return item !== '*' }) || []
  const countryOfEmploymentOptions = baseOptions.concat(
    countryOfEmploymentOptionsExcludeBaseOptions
    .sort((a, b) => { return a.localeCompare(b) })
    .map((item) => { return { label: item, value: item } }),
  )
  return (
    <>
      <Form.Item name='capIds' label='CAP IDs'>
        <Select
          mode='multiple'
          placeholder='Select CAP IDs'
          style={{ width: '100%' }}
          allowClear
          options={capIdOptions}
        />
      </Form.Item>
      <Form.Item name='countryOfEmployment' label='Countries of Employment'>
        <Select
          mode='multiple'
          placeholder='Select Countries of Employment'
          style={{ width: '100%' }}
          allowClear
          options={countryOfEmploymentOptions}
        />
      </Form.Item>
    </>
  )
}

CapIdCountryOfEmploymentSelect.propTypes = {
  data: PropTypes.shape({
    availableCapIds: PropTypes.arrayOf(PropTypes.string),
    availableCountryOfEmployment: PropTypes.arrayOf(PropTypes.string),
  }),
}

CapIdCountryOfEmploymentSelect.defaultProps = {
  data: {
    availableCapIds: [],
    availableCountryOfEmployment: [],
  },
}

export default CapIdCountryOfEmploymentSelect
