import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Typography,
  Form,
  Checkbox,
  Button,
  Spin,
  Radio,
  Space,
  Divider,
  InputNumber,
} from 'antd'

import JsonPreview from '@core/components/JsonPreview'
import IntentTreeInput from '@core/components/IntentTreeInput'

import Api from '../../api'
import { sortThenStringifyNgramsData } from '../../helpers'

const NgramsFrequency = () => {
  const [form] = Form.useForm()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [domains, setDomains] = useState([])
  const [intents, setIntents] = useState([])

  const [sortingOption, setSortingOption] = useState('value-asc')

  const [minValue, setMinValue] = useState()
  const [maxValue, setMaxValue] = useState()
  useEffect(() => {
    if (!form.isFieldsTouched(['min', 'max'])) return

    form.validateFields(['min', 'max'])
  }, [form, minValue, maxValue])

  const onFinish = async () => {
    setLoading(true)
    setHasError(false)
    try {
      setHasError(false)
      const { testing, training, min, max } = await form.validateFields()
      const response = await Api.getNgramsFrequency({
        domainIds: domains,
        intentIds: intents,
        testing,
        training,
        min,
        max,
      })
      setData(response.data?.ngrams)
    } catch (error) {
      setHasError(true)
    }
    setLoading(false)
  }

  const getDomainsIntents = (event) => {
    setIntents(event.intents)
    setDomains(event.domains)
  }

  const setIntentValue = (value) => {
    form.resetFields(['domains_intents_null'])
    getDomainsIntents(value)
  }

  const handleMinOnChange = (value) => { return setMinValue(value) }
  const handleMaxOnChange = (value) => { return setMaxValue(value) }
  const handleSortOption = (e) => { return setSortingOption(e.target.value) }

  const sortedData = sortThenStringifyNgramsData({ data, sortingOption })

  return (
    <Row gutter={[24, 16]}>
      <Col span={16}>
        <Typography.Title level={4}>Check the frequency</Typography.Title>
        <Typography.Text strong>
          This feature looks through a dataset, determined by the domain/intent,
          and shows the frequency of the ngrams in that dataset.
        </Typography.Text>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout='horizontal'
          onFinish={onFinish}
          initialValues={{
            testing: false,
            training: false,
          }}
        >
          <Form.Item name='domains_intents'>
            <IntentTreeInput
              form={form}
              setIntent={setIntentValue}
              placeholder='Specify domain/intent'
            />
          </Form.Item>

          <Space>
            <Form.Item name='testing' valuePropName='checked'>
              <Checkbox>Testing</Checkbox>
            </Form.Item>

            <Form.Item name='training' valuePropName='checked'>
              <Checkbox>Training</Checkbox>
            </Form.Item>
          </Space>

          <Divider type='vertical' />

          <Space align='baseline'>
            <Form.Item
              label='Min'
              name='min'
              rules={[
                { required: true, message: 'Required' },
                ({ getFieldValue }) => {
                  return {
                    validator(_, value) {
                      if (value !== undefined && value > getFieldValue('max')) {
                        return Promise.reject(new Error('<= Max'))
                      }
                      return Promise.resolve()
                    },
                  }
                },
              ]}
            >
              <InputNumber size='small' min={1} onChange={handleMinOnChange} />
            </Form.Item>
            <Form.Item
              label='Max'
              name='max'
              rules={[
                { required: true, message: 'Required' },
                ({ getFieldValue }) => {
                  return {
                    validator(_, value) {
                      if (value !== undefined && value < getFieldValue('min')) {
                        return Promise.reject(new Error('>= Min'))
                      }
                      return Promise.resolve()
                    },
                  }
                },
              ]}
            >
              <InputNumber size='small' onChange={handleMaxOnChange} />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item>
              <Button type='primary' htmlType='submit' disabled={loading}>
                Analyze
              </Button>
            </Form.Item>
          </Space>

          {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
        </Form>
      </Col>
      <Col span={16}>
        <Space style={{ marginBottom: '5px' }}>
          <Space>
            <span>Sort by:</span>
            <Radio.Group onChange={handleSortOption}>
              <Radio.Button value='value-asc'>Value ASC</Radio.Button>
              <Radio.Button value='value-desc'>Value DESC</Radio.Button>
            </Radio.Group>
          </Space>
        </Space>
        <Spin spinning={loading}>
          {data && <JsonPreview content={sortedData} autoSize />}
        </Spin>
      </Col>
    </Row>
  )
}

export default NgramsFrequency
