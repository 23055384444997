/* eslint-disable no-useless-escape */
import JSZip from 'jszip'
import dayjs from 'dayjs'

dayjs.extend(require('dayjs/plugin/timezone'))

// original: https://dev.to/nombrekeff/download-file-from-blob-21ho
export const downloadFile = (blob, fileName) => {
  if (!blob && !fileName) throw new Error('downloadFile:: Expected blob and filename argument')

  const blobURLRef = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = blobURLRef
  link.download = `${fileName}`

  document.body.appendChild(link)

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )

  // Remove link from body
  document.body.removeChild(link)

  // Clearing out the blob URL store
  URL.revokeObjectURL(blobURLRef)
}

export const downloadZipFiles = async (zipFileName, files) => {
  if (!files && files.length === 0) return
  const zip = new JSZip()
  const folder = zip.folder(zipFileName)
  files.forEach(({ fileName, content }) => {
    folder.file(fileName, content)
  })
  const compressedFile = await zip.generateAsync({ type: 'blob' })
  downloadFile(compressedFile, zipFileName)
}

export const downloadJsonFile = (content, fileName) => {
  const blob = new window.Blob([JSON.stringify(content, null, 2)], { type: 'application/json' })

  downloadFile(blob, fileName)
}

export const downloadCsvFile = (csvStringContent, fileName) => {
  const blob = new window.Blob([csvStringContent], { type: 'text/csv' })

  downloadFile(blob, fileName)
}

export const shallowCleanFalsyObject = (values) => {
  return Object.entries(values).reduce((obj, [key, value]) => {
    if (value) obj[key] = value
    return obj
  }, {})
}

export const removeUndefinedOrNullFromObject = (values) => {
  return Object.entries(values || {}).reduce((acc, [key, val]) => {
    if ([undefined, null].includes(val)) return acc
    acc[key] = val
    return acc
  }, {})
}

export const removeEmptyArrayFromObject = (values) => {
  return Object.entries(values || {}).reduce((acc, [key, val]) => {
    if (Array.isArray(val) && val.length === 0) return acc
    acc[key] = val
    return acc
  }, {})
}

export const reHashIndex = (listed) => {
  return listed.reduce((obj, job, index) => {
    obj[job.id] = index
    return obj
  }, {})
}

export const hashCollectionsById = (listed) => {
  return listed.reduce((hash, obj, index) => {
    hash[obj.id] = {
      index,
      ...obj,
    }
    return hash
  }, {})
}

export const buildDomainIntentFetchPayload = (responseData) => {
  const { domains, intents, lookup } = responseData
  const intentTree = [] // this is a full domain with intent put in the 'children' key
  const domainList = []
  const intentCascader = []
  const domainsIntentsTree = {}

  Object.entries(lookup).forEach(([domainId, intentIds], indexDomain) => {
    const domain = domains[domainId]
    const children = []
    const cascaderChildren = []
    domainsIntentsTree[domain.name] = []

    intentIds.forEach((intentId, indexIntent) => {
      const intent = intents[intentId]

      children.push({
        title: intent.name,
        label: intent.name,
        value: `${indexDomain}-${indexIntent}`,
        key: `${indexDomain}-${indexIntent}`,
        domainId,
        intentId,
      })

      cascaderChildren.push({
        value: intent.id,
        label: intent.name,
      })

      domainsIntentsTree[domain.name].push(intent.name)
    })

    domainList.push(domain)
    intentTree.push({
      title: domain.name,
      value: `${indexDomain}`,
      key: `${indexDomain}`,
      children,
      domainId,
    })

    intentCascader.push({
      value: domain.id,
      label: domain.name,
      children: cascaderChildren,
    })
  })

  return {
    rawLookup: responseData,
    intentTree,
    intentCascader,
    domainList,
    intentList: Object.values(responseData.intents),
    domainsIntentsTree,
  }
}

export const humanizeDuration = (ms, short = false) => {
  const DAY_MS = 86400000
  const HOUR_MS = 3600000
  const MINUTE_MS = 60000
  const SECOND_MS = 1000

  let msLeft = ms
  let display = ''

  const appendDisplay = (key, value) => {
    if (!value) return

    if (short) {
      switch (key) {
        case 'day':
          display += `${value}d `
          break
        case 'hour':
          display += `${value}h `
          break
        case 'minute':
          display += `${value}m `
          break
        case 'second':
          display += `${value}s `
          break
        default:
          break
      }
    } else {
      display += value > 1 ? `${value} ${key}s ` : `${value} ${key} `
    }
  }

  const day = Math.floor(msLeft / DAY_MS)
  msLeft %= DAY_MS
  const hour = Math.floor(msLeft / HOUR_MS)
  msLeft %= HOUR_MS
  const minute = Math.floor(msLeft / MINUTE_MS)
  msLeft %= MINUTE_MS
  const second = Math.floor(msLeft / SECOND_MS)

  appendDisplay('day', day)
  appendDisplay('hour', hour)
  appendDisplay('minute', minute)
  appendDisplay('second', second)

  display = display.trimRight()

  if (!display) {
    display = short ? '0s' : '0 second'
  }

  return display
}

export const searchToArrayByComma = (search, options = { toLowerCase: true }) => {
  const { toLowerCase } = options
  let str = search.trim()
  if (toLowerCase) str = str.toLowerCase()
  const terms = []
  let raw = false
  let firstCharacter = 0
  for (let i = 0; i < str.length; i++) {
    const character = str.charAt(i)
    if (character === '`') {
      raw = !raw
    }
    if (character === ',' && !raw) {
      terms.push(str.substring(firstCharacter, i).trim().replace(/`/gi, ''))
      firstCharacter = i + 1
    } else if (i === str.length - 1) {
      terms.push(str.substring(firstCharacter, i + 1).trim().replace(/`/gi, ''))
    }
  }
  return terms
}

export const treeToIntentArray = (intentTree, currentSelection) => {
  const domains = []
  const intents = []

  const domainNames = []
  const intentNames = []

  currentSelection.forEach((item) => {
    const indexes = item.split('-')
    if (indexes.length === 1) {
      domains.push(parseInt(intentTree[parseInt(indexes[0])].domainId))
      domainNames.push(intentTree[parseInt(indexes[0])].title)
    } else {
      intents.push(parseInt(intentTree[parseInt(indexes[0])].children[parseInt(indexes[1])].intentId))
      intentNames.push(intentTree[parseInt(indexes[0])].children[parseInt(indexes[1])].title)
    }
  })

  return {
    domains,
    intents,
    defaultIntentTree: currentSelection,
    domainNames,
    intentNames,
  }
}

export const getComputedConversationMessages = (messages) => {
  if (!messages) {
    console.error('getComputedConversationMessages:: missing "messages" parameter')
    return []
  }

  return messages.map((message) => {
    return {
      id: message.id,
      text: message.original,
      created_at: message.created_at,
      direction: message.message_type === 'CUSTOMER' ? 'left' : 'right',
      messageType: message.message_type,
      extracted: message.extracted,
      machineState: message.machine_state,
      status: message.status,
    }
  })
}

export const formatDateTime = (value, format = 'DD/MM/YYYY ddd HH:mm ([UTC+07:00])') => { return dayjs(value).tz('Asia/Bangkok').format(format) }

export const sortTextOrNull = (a, b) => {
  const types = [typeof a, typeof b]
  if (types[0] === 'string' && types[1] === 'string') {
    return a.localeCompare(b)
  }
  return !!a - !!b // cast to boolean to get -1 or 1
}

export const sortNumberOrNull = (a, b) => {
  const types = [typeof a, typeof b]
  if (types[0] === 'number' && types[1] === 'number') {
    return a - b
  }
  return Boolean(a) - Boolean(b) // cast to boolean to get -1 or 1
}

export const sortDateTimeOrNull = (a, b) => {
  if (!a) return -1
  if (!b) return 1
  return new Date(a).getTime() - new Date(b).getTime()
}

export const capitalizeWord = (string) => {
  if (typeof string !== 'string') return string

  return string.charAt(0).toLocaleUpperCase() + string.slice(1).toLocaleLowerCase()
}

export const upperCaseFirstCharacter = (string) => {
  if (typeof string !== 'string') return string

  return string.charAt(0).toLocaleUpperCase() + string.slice(1)
}
