import React from 'react'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import getOption from './getOption'

const ConversationsChartsCountByMonth = ({ data }) => {
  return (
    <ReactECharts
      option={getOption(data)}
    />
  )
}

ConversationsChartsCountByMonth.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default ConversationsChartsCountByMonth
