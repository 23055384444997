import React, { useState, useContext } from 'react'
import { Button, Table, Typography, Tag, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import useRequest from '@core/hooks/useRequest'
import { EmailAutomationsContext } from '../../../context'
import Api from '../../../api'
import EvaluationExpandedRow from './EvaluationExpandedRow'

const PUBLISHED = 'PUBLISHED'

const TrainedDataExtractionModels = () => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [
    { loading, data: models, hasError },
    { makeRequest },
  ] = useRequest(Api.DataExtractionModels.getModels, { version: currentVersion, datasource })

  const [isPublishing] = useState(false)

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => { return created_at },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return (
          <Tag color={status === PUBLISHED ? 'success' : 'blue'}>
            {status || 'DRAFTED'}
          </Tag>
        )
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: (model) => {
        const handlePublishClick = async () => {
          Modal.confirm({
            title: 'Publish',
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            content: `Assigning Data Extraction Model {${model.id}} to ${currentVersion}`,
            onOk: async () => {
              await Api.DataExtractionModels.publishModel({
                version: currentVersion, datasource, modelId: model.id,
              })
              await makeRequest()
            },

            onCancel: () => { },
          })
        }

        const isPublished = model.status === PUBLISHED

        return (
          <Button
            type='link'
            disabled={isPublished}
            loading={loading}
            onClick={handlePublishClick}
          >
            Publish
          </Button>
        )
      },
    },
  ]

  return (
    <>
      {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      <Table
        rowKey='id'
        className='components-table-demo-nested'
        loading={isPublishing || loading}
        columns={columns}
        dataSource={models || []}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <EvaluationExpandedRow key={record.id} id={record.id} />
            )
          },
        }}
      />
    </>
  )
}

export default TrainedDataExtractionModels
