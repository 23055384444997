import React from 'react'
import { Select, Form } from 'antd'
import { rulesType } from '@core/types/formItems'

const TaggerExpectedResult = ({ rules }) => {
  return (
    <Form.Item name='expected_result' label='Expected Result' rules={rules}>
      <Select defaultValue='tagger-pass' style={{ width: 200 }}>
        <Select.Option value='tagger-pass' key={0}>tagger-pass</Select.Option>
        <Select.Option value='tagger-fail' key={1}>tagger-fail</Select.Option>
      </Select>
    </Form.Item>
  )
}

TaggerExpectedResult.propTypes = {
  rules: rulesType,
}

TaggerExpectedResult.defaultProps = {
  rules: [],
}

export default TaggerExpectedResult
