import React from 'react'
import { Handle, Position } from 'react-flow-renderer'
import { Popover, Button, Typography, Space } from 'antd'
import PropTypes from 'prop-types'

const StateKeyNode = ({ data, isConnectable }) => {
  return (
    <Popover
      placement='right'
      color='white'
      content={() => {
        return (
          <Space direction='vertical'>
            <Typography.Text strong>{data.label}</Typography.Text>
            {data.hasNull && <Typography.Text mark>Has Null Intents</Typography.Text>}
            <Space>
              <Typography.Text>Total Conversations:</Typography.Text>
              <Typography.Text strong>{data.conversations.length}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text>Connected Conversations:</Typography.Text>
              <Typography.Text strong>{data.conversationConnections}</Typography.Text>
            </Space>
            <Button type='primary' onClick={() => { data.showDrawer(data) }}>
              See domains/intents distribution
            </Button>
          </Space>
        )
      }}
    >
      <div onPointerEnter={() => { data.selectNode(data.index) }}>
        <Typography.Text mark={data.hasNull} style={{ color: 'white' }}>{`[${data.index}] ${data.label}`}</Typography.Text>
        {data.utteranceIndex > 1 && <Handle type='target' position={Position.Top} style={{ borderRadius: 0 }} isConnectable={isConnectable} />}
        <Handle type='source' position={Position.Bottom} style={{ borderRadius: 0 }} isConnectable={isConnectable} />
      </div>
    </Popover>
  )
}

StateKeyNode.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    conversations: PropTypes.arrayOf(PropTypes.string).isRequired,
    domainIntents: PropTypes.shape().isRequired,
    utteranceIndex: PropTypes.number.isRequired,
    showDrawer: PropTypes.func.isRequired,
    hasNull: PropTypes.bool.isRequired,
    selectNode: PropTypes.func.isRequired,
    conversationConnections: PropTypes.number.isRequired,
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
}

export default StateKeyNode
