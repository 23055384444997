import React from 'react'
import PropTypes from 'prop-types'
import { QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Table, Popconfirm, Button, Space, Skeleton, Tooltip, Typography } from 'antd'
import { sortNumberOrNull, sortTextOrNull } from '@core/helpers'
import style from './style.module.scss'

const SamplesList = ({
  isInitializing,
  isSubmitting,
  openingSampleId,
  formPanelOpen,
  addNewCase,
  editSample,
  deleteSample,
  samples,
  extractorsTypes,
}) => {
  if (isInitializing) return (<Skeleton active />)

  const openSample = (sample) => {
    return () => { editSample(sample) }
  }

  const onConfirmDelete = (sample) => {
    return () => { deleteSample(sample) }
  }

  const getColumnTitleWithTooltip = (columnHeaderText, tooltipText) => {
    if (!tooltipText) return <Typography.Text>{columnHeaderText}</Typography.Text>
    return (
      <Tooltip title={tooltipText ?? columnHeaderText}>
        <Typography.Text>{columnHeaderText}</Typography.Text>
      </Tooltip>
    )
  }

  return (
    <>
      <div className={style.Title}>
        <Typography.Title level={5}>
          Training Case(s)
        </Typography.Title>
        <Button
          type='link'
          icon={<PlusOutlined />}
          onClick={addNewCase}
          disabled={formPanelOpen || isSubmitting}
        >
          Add new Case
        </Button>
      </div>
      <Table
        sticky
        loading={isSubmitting}
        rowKey={(sample) => { return sample.id }}
        scroll={{ x: true, y: 320 }}
        columns={[
          {
            title: getColumnTitleWithTooltip('Phrase', 'Tokenized'),
            dataIndex: 'phrase',
            key: 'phrase',
            width: 240,
            ellipsis: true,
            sorter: (a, b) => {
              return sortTextOrNull(a.phrase, b.phrase)
            },
          },
          {
            title: getColumnTitleWithTooltip('Occurrence Index', 'Order of appearance, count from whole email text'),
            dataIndex: 'occurrenceIndex',
            key: 'occurrenceIndex',
            width: 240,
            ellipsis: true,
            sorter: (a, b) => {
              return sortNumberOrNull(a.occurrenceIndex, b.occurrenceIndex)
            },
          },
          {
            title: 'Tagger type',
            dataIndex: 'extractorType',
            key: 'extractorType',
            width: 240,
            ellipsis: {
              showTitle: true,
            },
            filters: extractorsTypes,
            sorter: (a, b) => {
              return sortTextOrNull(a.extractorType, b.extractorType)
            },
            onFilter: (value, record) => {
              return record.extractorType === value
            },
          },
          {
            title: 'Desired value',
            dataIndex: 'desiredLabel',
            key: 'desiredLabel',
            width: 240,
            ellipsis: { showTitle: true },
            sorter: (a, b) => {
              return sortTextOrNull(a.desiredLabel, b.desiredLabel)
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, sample) => {
              return (
                <Space>
                  <Button
                    type='link'
                    disabled={sample.id === openingSampleId}
                    onClick={openSample(sample)}
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title='Are you sure?'
                    onConfirm={onConfirmDelete(sample.id)}
                    icon={<QuestionCircleOutlined />}
                  >
                    <Button type='link' danger>Delete</Button>
                  </Popconfirm>
                </Space>
              )
            },
          },
        ]}
        dataSource={samples}
        size='small'
        tableLayout='fixed'
      />
    </>
  )
}

SamplesList.defaultProps = {
  openingSampleId: null,
  samples: [],
  extractorsTypes: [],
}

SamplesList.propTypes = {
  isInitializing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  openingSampleId: PropTypes.string,
  formPanelOpen: PropTypes.bool.isRequired,
  addNewCase: PropTypes.func.isRequired,
  editSample: PropTypes.func.isRequired,
  deleteSample: PropTypes.func.isRequired,
  samples: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      outdated: PropTypes.bool,
      emailVersionId: PropTypes.string,
      phrase: PropTypes.string,
      extractorType: PropTypes.string,
      desiredLabel: PropTypes.string,
      phraseIndex: PropTypes.number,
      symbolIndex: PropTypes.number,
      sentenceIndex: PropTypes.number,
      _meta: PropTypes.shape({
        trainingData: PropTypes.string,
      }),
    }),
  ),
  extractorsTypes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}

SamplesList.defaultProps = {
  samples: [],
}

export default SamplesList
