import React, { useEffect } from 'react'
import { Modal, Form, Input } from 'antd'
import PropTypes from 'prop-types'
import Api from '../../../../api'

const ModelEditModal = ({ model, dismiss }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (model) {
      form.setFieldsValue({ name: model.name })
    }
  }, [model, form])

  const onOk = () => {
    form.submit()
  }

  const onCancel = () => {
    dismiss()
  }

  const onFinish = async (values) => {
    await Api.updateModel(model.id, values)
    dismiss(true)
  }

  return (
    <Modal
      title='Edit Model Name'
      destroyOnClose
      visible={!!model}
      okText='Save'
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form
        preserve={false}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name='name'
          rules={[{ required: true, message: 'Please input model name!' }]}
        >
          <Input placeholder='Name' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

ModelEditModal.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  dismiss: PropTypes.func.isRequired,
}

ModelEditModal.defaultProps = {
  model: null,
}

export default ModelEditModal
