import React from 'react'
import PropTypes from 'prop-types'
import { Space, Tooltip, Button } from 'antd'
import { FileExclamationOutlined, ProfileOutlined, EditOutlined, ReadOutlined } from '@ant-design/icons'
import { Link, useRouteMatch } from 'react-router-dom'
import { usePermittedSentenceListItemActions } from '@core/hooks/usePermissions'
import { sentencePropType } from '../../../types/sentence'
import SentenceDataActions from '../../../store/actions/sentences'
import NotesActions from '../../../store/actions/sentenceNotes'
import EditSentenceModalActions from '../../../store/actions/EditSentenceModal'

const SentenceActions = ({ sentence, sentenceArrayindex }) => {
  const hasDynamicEntities = Object.values(sentence.data.dynamicEntities || {}).length > 0
  const { url } = useRouteMatch()
  const {
    allowEditSentence,
    allowViewEntities,
    allowViewSentenceNotes,
    allowViewSentenceDetails,
  } = usePermittedSentenceListItemActions()
  const getSelectedStrokeColor = (conditionFlag) => { return (conditionFlag ? { color: '#50DEA0' } : { color: 'lightgray' }) }

  const onEditSentence = async (event) => {
    event.stopPropagation()

    await EditSentenceModalActions.open({
      id: sentence.id,
      text: sentence.text,
      source: sentence.source,
      generalized: sentence.generalized.generalized,
    })
  }

  const selectDynamicEntities = async (event) => {
    event.stopPropagation()
    await SentenceDataActions.select(sentence, 'Dynamic Entities')
  }

  const selectSentenceNotes = async (event) => {
    event.stopPropagation()
    await NotesActions.fetch(sentence.id)
    await SentenceDataActions.select(sentence, 'Sentence Notes')
  }

  const linkProps = {
    to: {
      pathname: `${url}/${sentence.id}`,
      state: {
        sentenceArrayindex,
      },
    },
    tabIndex: -1,
  }

  return (
    <Space>
      {allowEditSentence ? (
        <Tooltip title='Edit Sentence'>
          <Button
            title='Edit Sentence'
            shape='circle'
            style={{ color: 'lightgray' }}
            icon={<EditOutlined />}
            onClick={onEditSentence}
          />
        </Tooltip>
      ) : null}
      {allowViewEntities ? (
        <Tooltip title='Dynamic Entities'>
          <Button
            title='Add Data'
            shape='circle'
            style={getSelectedStrokeColor(hasDynamicEntities)}
            icon={<ReadOutlined />}
            onClick={selectDynamicEntities}
          />
        </Tooltip>
      ) : null}
      {allowViewSentenceNotes ? (
        <Tooltip title='Sentence Notes'>
          <Button
            title='View Sentence Note(s)'
            shape='circle'
            style={getSelectedStrokeColor(sentence.notes_count > 0)}
            icon={<FileExclamationOutlined />}
            onClick={selectSentenceNotes}
          />
        </Tooltip>
      ) : null}
      {allowViewSentenceDetails ? (
        <Tooltip title='Details'>
          <Link to={linkProps.to} tabIndex={linkProps.tabIndex}>
            <Button
              title='View Sentence Details'
              shape='circle'
              style={{ color: 'lightgray' }}
              icon={<ProfileOutlined />}
            />
          </Link>
        </Tooltip>
      ) : null}
    </Space>
  )
}

SentenceActions.propTypes = {
  sentence: sentencePropType.isRequired,
  sentenceArrayindex: PropTypes.number.isRequired,
}

export default SentenceActions
