import StoreUtils from '../utils/store'

const INTENT_TREE = {
  FETCH: StoreUtils.createActionStatus('INTENT_TREE/FETCH'),
  INTENT: {
    REASSIGN: StoreUtils.createActionStatus('INTENT_TREE/INTENT/REASSIGN'),
    RENAME: StoreUtils.createActionStatus('INTENT_TREE/INTENT/RENAME'),
    ADD: StoreUtils.createActionStatus('INTENT_TREE/INTENT/ADD'),
    DELETE: StoreUtils.createActionStatus('INTENT_TREE/INTENT/DELETE'),
  },
  DOMAIN: {
    RENAME: StoreUtils.createActionStatus('INTENT_TREE/DOMAIN/RENAME'),
    ADD: StoreUtils.createActionStatus('INTENT_TREE/DOMAIN/ADD'),
    DELETE: StoreUtils.createActionStatus('INTENT_TREE/DOMAIN/DELETE'),
  },
}

const PERMISSIONS = {
  FETCH: StoreUtils.createActionStatus('PERMISSIONS/FETCH'),
}

const VERSIONS = {
  SET: 'VERSIONS/SET',
  GET: StoreUtils.createActionStatus('VERSIONS/GET'),
  CREATE: StoreUtils.createActionStatus('VERSIONS/CREATE'),
  UPDATE: StoreUtils.createActionStatus('VERSIONS/UPDATE'),
  CLONE: StoreUtils.createActionStatus('VERSIONS/CLONE'),
  DELETE: StoreUtils.createActionStatus('VERSIONS/DELETE'),
  ATTACH_TAGS: StoreUtils.createActionStatus('VERSIONS/ATTACH_TAGS'),
  DETACH_TAGS: StoreUtils.createActionStatus('VERSIONS/DETACH_TAGS'),
  GET_TRAINING_SET: StoreUtils.createActionStatus('VERSIONS/GET_TRAINING_SET'),
  DEPLOY: StoreUtils.createActionStatus('VERSIONS/DEPLOY'),
}

const GENERALIZER = {
  FETCH: StoreUtils.createActionStatus('GENERALIZER/FETCH'),
}

const AUTH = {
  LOGIN: StoreUtils.createActionStatus('AUTH/LOGIN'),
  LOGOUT: StoreUtils.createActionStatus('AUTH/LOGOUT'),
  SYNCHRONIZE_TOKEN: 'AUTH/SYNCHRONIZE_TOKEN',
}

const CLUSTER = { // FOR YARS
  GET_WORKER_HOST_STATS: StoreUtils.createActionStatus('CLUSTER/GET_WORKER_HOST_STATS'),
  SCALE_UP_WORKER: StoreUtils.createActionStatus('CLUSTER/SCALE_UP_WORKER'),
  SCALE_DOWN_WORKER: StoreUtils.createActionStatus('CLUSTER/SCALE_DOWN_WORKER'),
}

const INTERNATIONALIZE = {
  VERSION: {
    GET_ALL: StoreUtils.createActionStatus('INTERNATIONALIZE/VERSION/GET_ALL'),
    UPDATE: StoreUtils.createActionStatus('INTERNATIONALIZE/VERSION/UPDATE'),
    CLONE: StoreUtils.createActionStatus('INTERNATIONALIZE/VERSION/CLONE'),
    DELETE: StoreUtils.createActionStatus('INTERNATIONALIZE/VERSION/DELETE'),
  },
}

export {
  INTENT_TREE,
  GENERALIZER,
  VERSIONS,
  AUTH,
  PERMISSIONS,
  CLUSTER,
  INTERNATIONALIZE,
}
