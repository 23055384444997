import { useState } from 'react'

const useCopyTextWithTimeout = (textToCopy) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
    }
    // backward compatible for old browsers
    document.execCommand('copy', true, text)
  }

  const handleCopy = async () => {
    try {
      await copyToClipboard(textToCopy)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    } catch (error) {
      console.error('useCopyTextWithTimeout - ', error)
    }
  }

  return [isCopied, handleCopy]
}

export default useCopyTextWithTimeout
