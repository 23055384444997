import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, Form, Input, Select, Descriptions, Row, Col, Divider, Typography, Button } from 'antd'
import { visibleType, closeModalType } from '../../../types/noteModal'
import NotesActions from '../../../store/actions/sentenceNotes'

const { Option } = Select
const { TextArea } = Input
const { Text } = Typography

const AddNoteModal = ({ visible, closeModal }) => {
  const { noteTypes, noteStatusNew, selectedSentence } = useSelector(({ data }) => {
    return {
      noteTypes: data.sentenceNotes.noteTypes,
      noteStatusNew: data.sentenceNotes.noteStatusNew,
      selectedSentence: data.sentences.selectedSentence,
    }
  })

  const noteChildren = noteTypes.map((type) => { return <Option key={type}>{type}</Option> })
  const noteStatusChildren = noteStatusNew.map((status) => { return <Option key={status}>{status}</Option> })

  const onClose = () => { return closeModal() }

  const onSubmit = async (value) => {
    await NotesActions.addNew(selectedSentence.id, value)
    onClose()
  }

  return (
    <Modal
      title='Add new note'
      visible={visible}
      onCancel={onClose}
      closable={false}
      centered
      width={1000}
      footer={null}
    >
      <Text type='secondary'>{`Sentence ID: ${selectedSentence.id}`}</Text>
      <Descriptions size='small' bordered column={5}>
        <Descriptions.Item label='Text' span={5}>{selectedSentence.text}</Descriptions.Item>
        <Descriptions.Item label='Generalized' span={5}>{selectedSentence.generalized.generalized}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Form
        layout='horizontal'
        name='form__batch_add_test_case'
        onFinish={onSubmit}
      >
        <Form.Item name='note' label='Note' rules={[{ required: true, message: 'Please add a note' }]}><TextArea /></Form.Item>

        <Form.Item name='type' label='Type' rules={[{ required: true, message: 'Please select a type' }]}>
          <Select
            size='small'
            placeholder='Select type'
            defaultValue={[]}
            style={{ width: '100%' }}
          >
            {noteChildren}
          </Select>
        </Form.Item>

        <Form.Item name='status' label='Status' rules={[{ required: true, message: 'Please select a status' }]}>
          <Select
            size='small'
            placeholder='Select status'
            style={{ width: '100%' }}
          >
            {noteStatusChildren}
          </Select>
        </Form.Item>

        <Row>
          <Col>
            <Form.Item>
              <Button onClick={onClose}>Cancel</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type='primary' htmlType='submit'>Save</Button>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  )
}

AddNoteModal.propTypes = {
  visible: visibleType.isRequired,
  closeModal: closeModalType.isRequired,
}

export default AddNoteModal
