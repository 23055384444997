import React from 'react'
import PropTypes from 'prop-types'
import { Space, Button } from 'antd'

import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'

const IntentsSelectionActions = ({
  saveAndApproveAll,
  saveChanges,
  addUnselectedIntentToNegativeForSubmission,
  clearSelection,

  allIntentsHasBeenSelected,
  saveAndApproveAllDisabled,
  saveChangesDisabled,
  setRemainingNegativeDisabled,
  resetDisabled,
}) => {
  return (
    <Space wrap>
      <PermittedFeatureCheck featurePath='emailautomations.intentTrainingData.actionApprove'>
        <Button
          type='primary'
          disabled={saveAndApproveAllDisabled}
          onClick={saveAndApproveAll}
        >
          Save & Approve All
        </Button>
      </PermittedFeatureCheck>
      <Button
        type='primary'
        disabled={saveChangesDisabled}
        onClick={saveChanges}
      >
        Save changes
      </Button>
      <Button
        type='dashed'
        disabled={setRemainingNegativeDisabled}
        danger
        onClick={addUnselectedIntentToNegativeForSubmission}
      >
        {
          allIntentsHasBeenSelected
            ? 'All intents has been submitted/selected'
            : 'Set all remaining intent(s) to "Negative"'
        }
      </Button>
      <Button
        type='default'
        disabled={resetDisabled}
        onClick={clearSelection}
      >
        Reset
      </Button>
    </Space>
  )
}

IntentsSelectionActions.propTypes = {
  saveAndApproveAll: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  addUnselectedIntentToNegativeForSubmission: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,

  allIntentsHasBeenSelected: PropTypes.bool.isRequired,
  saveAndApproveAllDisabled: PropTypes.bool.isRequired,
  saveChangesDisabled: PropTypes.bool.isRequired,
  setRemainingNegativeDisabled: PropTypes.bool.isRequired,
  resetDisabled: PropTypes.bool.isRequired,
}

export default IntentsSelectionActions
