import React, { useState } from 'react'
import { Modal, Tooltip, Collapse } from 'antd'
import { MonitorOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import StepPanel from './StepPanel'
import ConversationPanel from './ConversationPanel'
import LogPanel from './LogPanel'
import style from '../index.module.scss'
import { UserPropTypes } from '../../UserPropTypes'

const InvestigateModal = ({ user }) => {
  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title='Investigation'>
        <MonitorOutlined onClick={showModal} className={style['active-action']} />
      </Tooltip>
      {open && <InvestigateModalContent user={user} closeModal={closeModal} />}
    </>
  )
}

InvestigateModal.propTypes = {
  user: UserPropTypes.isRequired,
}

const InvestigateModalContent = ({ user, closeModal }) => {
  return (
    <Modal
      title='Investigate'
      visible
      onCancel={closeModal}
      footer={null}
      width={1800}
    >
      <div className={style.grid2Col}>
        <StepPanel user={user} />
        <Collapse accordion defaultActiveKey={['1']}>
          <Collapse.Panel header='Conversation' key='1'>
            <ConversationPanel conversationId={user?.onConversation?.data?.conversationId} />
          </Collapse.Panel>
          <Collapse.Panel header='Log' key='2' className={style.noPaddingContentBox}>
            <LogPanel user={user} />
          </Collapse.Panel>
        </Collapse>
      </div>
    </Modal>
  )
}

InvestigateModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  user: UserPropTypes.isRequired,
}

export default InvestigateModal

