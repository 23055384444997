import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip } from 'antd'

const GeneralizedSentence = ({ sentence }) => {
  return (
    <Typography.Text style={{ lineHeight: 1.8, wordSpacing: '2px' }}>
      {sentence?.map((token) => {
        if (token?.source) {
          const markStyle = {
            backgroundColor: token?.backgroundColor,
            cursor: 'pointer',
            borderRadius: '6px',
            color: token?.color,
            padding: '2px 6px',
          }

          return (
            <Tooltip overlayInnerStyle={{ color: '#000' }} placement='bottom' title={token?.value} color='#d7edfc' key={token?.symbolIndex}>
              <mark key={token?.symbolIndex} style={markStyle}>{token?.generalizedText ?? ''}</mark>
              {' '}
            </Tooltip>
          )
        }

        return (token?.value?.concat(' ')) ?? ''
      })}
    </Typography.Text>
  )
}

GeneralizedSentence.propTypes = {
  sentence: PropTypes.arrayOf(PropTypes.shape({
    source: PropTypes.string,
    symbolIndex: PropTypes.number,
    generalizedText: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
  })).isRequired,
}

export default GeneralizedSentence
