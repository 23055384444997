import React from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom'

import PTC from './pages/PTC'
import Logs from './pages/Logs'
import User from './pages/User'

const ConversationManagementModule = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {/* NOTE: Later on we might need to limit visibility using PermissionRoute, but it would be rely on backend */}
      <Redirect from={`${path}`} to={`${path}/ptc`} exact />
      <Route path={`${path}/ptc`} component={PTC} exact />
      <Route path={`${path}/logs`} component={Logs} exact />
      <Route path={`${path}/user`} component={User} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/conversation-management',
    component: ConversationManagementModule,
  },
  name: 'Conversation Management',
}

export default ConversationManagementModule
