import React from 'react'
import { Form, Checkbox, Divider } from 'antd'

const UnrecognizedFilter = () => {
  return (
    <>
      <Divider>
        <small><strong>Misc.</strong></small>
      </Divider>
      <Form.Item
        valuePropName='checked'
        name='is_unrecognized'
      >
        <Checkbox>Only Unrecognized</Checkbox>
      </Form.Item>
    </>
  )
}

export default UnrecognizedFilter
