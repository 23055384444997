import React, { useEffect } from 'react'
import {
  Form,
  Input,
  Button,
} from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import style from './index.module.scss'

const convertToInitialValues = (choiceCategory) => {
  return {
    ...choiceCategory,
    choices: choiceCategory.choices.map((choice) => {
      return {
        ...choice,
        synonyms: (choice.synonyms ?? []).join('\n'),
      }
    }),
  }
}

const convertToChoiceCategory = (values) => {
  return {
    ...values,
    choices: values.choices.map((choice) => {
      return {
        ...choice,
        synonyms: (choice.synonyms ?? '').split('\n').map((synonym) => { return synonym.trim() }).filter((synonym) => { return !!synonym }),
      }
    }),
  }
}

const ChoiceCategoryForm = ({
  form,
  choiceCategory,
  loading,
  onFinish,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { return form.resetFields() }, [choiceCategory])

  const handleFinish = (values) => { return onFinish(convertToChoiceCategory(values)) }

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout='horizontal'
      initialValues={convertToInitialValues(choiceCategory)}
    >
      <Form.Item
        label='Name'
        name='name'
        rules={[{ required: true, message: 'Please input name!' }]}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Input readOnly={loading} />
      </Form.Item>
      <Form.Item
        label='Question'
        name='question'
        rules={[{ required: true, message: 'Please input question!' }]}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Input readOnly={loading} />
      </Form.Item>
      <Form.Item
        label='Default Answer'
        name='default_fallback_response'
        rules={[{ required: true, message: 'Please input default answer!' }]}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Input readOnly={loading} />
      </Form.Item>
      <Form.Item
        label='Language'
        name='language'
        rules={[{ required: true, message: 'Please input language!' }]}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Input disabled />
      </Form.Item>
      <h4>Choices</h4>
      <Form.List
        name='choices'
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div style={{ display: 'flex' }} key={field.key}>
                    <div style={{ width: '5%' }}>
                      {`${index + 1}.`}
                    </div>
                    <div style={{ width: '85%' }}>
                      <Form.Item name={[field.name, 'name']}>
                        <Input placeholder='Name' readOnly={loading} />
                      </Form.Item>
                      <Form.Item name={[field.name, 'synonyms']}>
                        <Input.TextArea placeholder='Synonyms (one item per line)' readOnly={loading} rows='3' />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined
                      className={style.delete_button__choice_category_form}
                      onClick={() => { return remove(field.name) }}
                    />
                  </div>
                )
              })}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => { return add() }}
                  style={{ width: '100%' }}
                  icon={<PlusOutlined />}
                  disabled={loading}
                >
                  Add choice
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}

ChoiceCategoryForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any.isRequired,
  choiceCategory: PropTypes.shape({
    id: PropTypes.string,
    language: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      synonyms: PropTypes.arrayOf(PropTypes.string).isRequired,
      created_at: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default ChoiceCategoryForm
