import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Empty } from 'antd'
import { searchToArrayByComma } from '@core/helpers'
import { SingleChatExchange } from '../../ChatExchanges'
import ExistingTestCasesTabs from './ExistingTestCases'
import AddNewCaseModal from './AddNewCaseModal'
import style from './index.module.scss'

const ExchangeTestCase = ({
  exchange,
  addExchangeTestCase,
  updateExchangeTestCase,
  activeCurrentViewExchange,
  exchangesHashPairedWithTestCases,
  loading,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  const addNewCase = (options) => {
    const {
      description,
      skip,
      responseKeys,
      textTerms,
      domain,
      intent,
    } = options

    const payload = {
      exchangeId: exchange.id,
      description,
      skip,
    }

    if (responseKeys || textTerms || domain || intent) {
      payload.expected = {}
    }

    if (responseKeys) payload.expected.responseKeys = searchToArrayByComma(responseKeys, { toLowerCase: false })
    if (textTerms) payload.expected.textTerms = searchToArrayByComma(textTerms, { toLowerCase: false })
    if (domain) payload.expected.domain = domain
    if (intent) payload.expected.intent = intent

    // pass down for error
    return addExchangeTestCase(payload)
  }

  if (!exchange || !Object.keys(exchange).length) return <Empty description='Please select one of the exchange on the left to start adding end to end test' />

  return (
    <div className='ExchangeTestCase'>
      <div className={style.ExchangeMessages}>
        <div className={style.ExchangeDetails}>
          <div className={style.exchange_label}>
            <span className={style.exchange_label_text}>
              Exchange ID:&thinsp;
              <strong>{exchange.id}</strong>
            </span>
          </div>
        </div>
      </div>
      <SingleChatExchange exchange={exchange} />
      <ExistingTestCasesTabs
        exchangeId={exchange.id}
        testCases={exchangesHashPairedWithTestCases[activeCurrentViewExchange]}
        updateExchangeTestCase={updateExchangeTestCase}
        showModal={showModal}
        loading={loading}
      />
      {
        modalVisible && (
          <AddNewCaseModal
            addNewCase={addNewCase}
            closeModal={closeModal}
            addNewCasePending={loading}
            modalVisible={modalVisible}
          />
        )
      }
    </div>
  )
}

ExchangeTestCase.defaultProps = {
  exchange: null,
  exchangesHashPairedWithTestCases: {},
  activeCurrentViewExchange: '',
}

ExchangeTestCase.propTypes = {
  exchange: PropTypes.shape({
    id: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  activeCurrentViewExchange: PropTypes.string,
  addExchangeTestCase: PropTypes.func.isRequired,
  updateExchangeTestCase: PropTypes.func.isRequired,
  exchangesHashPairedWithTestCases: PropTypes.objectOf(PropTypes.array),
}

export default ExchangeTestCase
