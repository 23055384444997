import callApi from './client'

export default {
  Versions: {
    getVersions: ({ datasource }) => {
      return callApi({
        url: '/api/v1/emails/versions',
        method: 'get',
        datasource,
      })
    },
    deployVersion: ({ id, datasource, status }) => {
      return callApi({
        url: `/api/v1/emails/versions/${id}/deploy`,
        method: 'POST',
        datasource,
        data: { status },
      })
    },
    updateById: ({ id, datasource, note }) => {
      return callApi({
        url: `/api/v1/emails/versions/${id}`,
        method: 'PATCH',
        datasource,
        data: { note },
      })
    },
    cloneVersion: ({ id, datasource }) => {
      return callApi({
        url: `/api/v1/emails/versions/${id}/clone`,
        method: 'POST',
        datasource,
      })
    },
  },
  Emails: {
    getEmails: ({ version, perPage, page, datasource }, filter = {}) => {
      return callApi({
        url: '/api/v1/emails/items',
        method: 'POST',
        version,
        datasource,
        params: { perPage, page },
        data: {
          ...filter,
          source: 'salesforce',
        },
      })
    },
    getSamples: ({ version, datasource }, emailId) => {
      return callApi({
        url: `/api/v1/emails/items/${emailId}/samples`,
        method: 'get',
        version,
        datasource,
      })
    },
    upsert: ({ version, datasource, id, status }) => {
      return callApi({
        url: `/api/v1/emails/items/${id}/status`,
        method: 'POST',
        version,
        datasource,
        data: { status },
      })
    },
    upsertDatasetType: ({ version, datasource, id, datasetType }) => {
      return callApi({
        url: `/api/v1/emails/items/${id}/dataset-type`,
        method: 'POST',
        version,
        datasource,
        data: { datasetType },
      })
    },
    upsertManyEmails: ({ version, datasource, emailItemIds, status }) => {
      return callApi({
        url: '/api/v1/emails/items/status',
        method: 'POST',
        version,
        datasource,
        data: { status, emailItemIds },
      })
    },
    preprocessAllEmailData: ({ steps, datasource, version }) => {
      return callApi({
        url: '/api/v1/emails/items/preprocess/all',
        method: 'POST',
        datasource,
        version,
        data: { steps },
      })
    },
    getAllModels: ({ version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/intents/models',
        method: 'GET',
        datasource,
        version,
      })
    },
    getModelEvaluation: ({ version, datasource, id }) => {
      return callApi({
        url: `/api/v1/emails/intents/models/${id}/evaluation`,
        method: 'GET',
        datasource,
        version,
      })
    },
    analyzeEmailByBody: (
      { body, countryOfEmployment, capId = 'test' },
      { version, datasource, preprocessingMethod },
    ) => {
      const apiBody = {
        emailSentAt: '1970-01-01 00:00:00',
        caseId: '1',
        fromAddress: 'nlpdashboard1@30secondstofly.com',
        toAddress: 'nlpdashboard2@30secondstofly.com',
        source: 'nlpDashboard',
        subject: 'From nlpDashboard',
        body,
        context: {
          capId,
          countryOfEmployment,
        },
      }

      return callApi({
        url: '/api/v1/emails/analyze',
        method: 'post',
        headers: { 'x-app-request-id': 'debug' },
        version,
        datasource,
        preprocessingMethod,
        data: apiBody,
      })
    },
  },
  Intents: {
    getIntents: ({ version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/intents',
        version,
        datasource,
        method: 'get',
      })
    },
    getRecognizedIntentsByText: (
      text, { version, datasource },
    ) => {
      return callApi({
        url: '/api/v1/emails/intents/recognize',
        method: 'post',
        version,
        datasource,
        data: { text },
      })
    },
    addIntents: ({ name, version, datasource, parentId }) => {
      return callApi({
        url: '/api/v1/emails/intents',
        method: 'post',
        version,
        datasource,
        data: { name, parentId },
      })
    },
    deleteIntents: ({ ids, version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/intents',
        method: 'delete',
        version,
        datasource,
        data: { ids },
      })
    },
    updateIntent: ({ version, oldName, newName: name, datasource }) => {
      return callApi({
        url: `/api/v1/emails/intents/${oldName}`,
        method: 'patch',
        version,
        datasource,
        data: { name },
      })
    },
  },
  Trainings: {
    batchUpsert: ({ version, emailItemIds, intentIds, datasource }) => {
      return callApi({
        url: '/api/v1/emails/intents/training-samples/batch-upsert',
        method: 'post',
        version,
        datasource,
        data: {
          emailItemIds,
          intentIds,
        },
      })
    },
    deleteSample: ({ version, emailId, intentId, datasource }) => {
      return callApi({
        url: `/api/v1/emails/items/${emailId}/intents/${intentId}/training-samples`,
        method: 'delete',
        version,
        datasource,
      })
    },
    batchApprove: async ({ version, emailId, intentIds, datasource }) => {
      return callApi({
        url: `/api/v1/emails/items/${emailId}/intents/approve/batch`,
        method: 'patch',
        version,
        datasource,
        data: {
          intentIds,
        },
      })
    },
    batchReject: async ({ version, emailId, intentIds, datasource }) => {
      return callApi({
        url: `/api/v1/emails/items/${emailId}/intents/reject/batch`,
        method: 'patch',
        version,
        datasource,
        data: {
          intentIds,
        },
      })
    },
  },
  Models: {
    getModels: ({ version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/intents/models',
        method: 'get',
        version,
        datasource,
      })
    },
    train: ({ version, datasource, modelId, intentNames }) => {
      return callApi({
        url: '/api/v1/emails/intents/models/train',
        method: 'post',
        version,
        datasource,
        data: {
          modelId,
          intentNames,
        },
      })
    },
    getTraining: ({ version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/intents/models/train',
        method: 'get',
        version,
        datasource,
      })
    },
    publishModel: ({ modelId, version, datasource }) => {
      return callApi({
        url: `/api/v1/emails/intents/models/${modelId}/publish`,
        method: 'patch',
        version,
        datasource,
      })
    },
    updateModel: () => { return Promise.resolve({ success: true }) },
  },
  DataExtractions: {
    getForOneEmail: async ({ version, datasource }, emailId) => {
      return callApi({
        url: `/api/v1/emails/items/${emailId}/extraction/samples`,
        method: 'get',
        version,
        datasource,
      })
    },
    upsertTrainingCase: async ({ version, datasource, emailId, data }) => {
      return callApi({
        url: `/api/v1/emails/items/${emailId}/extraction/sample`,
        method: 'post',
        version,
        datasource,
        data,
      })
    },
    deleteTrainingCase: async ({ version, datasource, data }) => {
      return callApi({
        url: '/api/v1/emails/items/extraction/samples',
        method: 'delete',
        version,
        datasource,
        data,
      })
    },
    getExtractorTypes: async ({ version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/extraction/hierarchy',
        method: 'get',
        version,
        datasource,
      })
    },
    getCountOfDataExtractionSamples: async ({ version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/extraction/count',
        method: 'get',
        version,
        datasource,
      })
    },
  },
  Jobs: {
    getAllJobs: (params = {}, version) => {
      return callApi({
        url: '/api/v1/emails/jobs',
        method: 'get',
        params,
        version,
      })
    },
    getJobStatus: ({ id }) => {
      return callApi({
        url: `/api/v1/emails/jobs/${id}`,
        method: 'get',
      })
    },
  },
  DataExtractionModels: {
    getModels: ({ version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/data-extraction/models',
        method: 'GET',
        version,
        datasource,
      })
    },
    train: ({ version, datasource }) => {
      return callApi({
        url: '/api/v1/emails/data-extraction/models/train',
        method: 'POST',
        version,
        datasource,
      })
    },
    publishModel: ({ modelId, version, datasource }) => {
      return callApi({
        url: `/api/v1/emails/data-extraction/models/${modelId}/publish`,
        method: 'PUT',
        version,
        datasource,
      })
    },
    updateModel: () => { return Promise.resolve({ success: true }) },
    getModelEvaluation: ({ version, datasource, modelId }) => {
      return callApi({
        url: `/api/v1/emails/data-extraction/models/${modelId}/evaluation`,
        method: 'GET',
        datasource,
        version,
      })
    },
  },
  QueriesManagement: {
    addNewPreset: ({ query_name, query_string }) => {
      return callApi({
        url: '/api/v1/emails/queryPresets',
        method: 'post',
        data: { query_name, query_string },
      })
    },
    batchDeleteQueryPreset: ({ ids }) => {
      return callApi({
        url: '/api/v1/emails/queryPresets',
        method: 'delete',
        data: { ids },
      })
    },
    getAllPresets: () => {
      return callApi({
        url: '/api/v1/emails/queryPresets',
        method: 'get',
      })
    },
    getMyPresets: () => {
      return callApi({
        url: '/api/v1/emails/queryPresets/me',
        method: 'get',
      })
    },
  },
  EmailAnalysisAccuracyCheck: {
    get: ({ version, datasource, id }) => {
      return callApi({
        url: `/api/v1/emails/items/${id}/checking`,
        method: 'get',
        version,
        datasource,
      })
    },
    insert: ({ version, datasource, data, id }) => {
      return callApi({
        url: `/api/v1/emails/items/${id}/checking`,
        method: 'post',
        version,
        datasource,
        data,
      })
    },
  },
  ClientGroups: {
    findAvailableCapIdsAndCountryOfEmployment: () => {
      return callApi({
        url: '/api/v1/client-groups/cap-country',
        method: 'get',
      })
    },
  },
}
