import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Form, Input, Select, Descriptions, Divider, Typography, Button } from 'antd'
import { visibleType, closeModalType } from '../../../types/dynamicEntitiesModal'
import DynamicEntitiesActions from '../../../store/actions/dynamicEntities'
import { buildDynamicEntityValidator } from '../../../helpers'

const { Option } = Select
const { Text } = Typography

const EditSentenceDynamicEntities = ({ visible, closeModal }) => {
  const [form] = Form.useForm()

  const { dynamicEntityTypes, selectedDynamicEntity, selectedSentence } = useSelector(({ data }) => {
    return {
      dynamicEntityTypes: data.dynamicEntities.dynamicEntityTypes,
      selectedDynamicEntity: data.dynamicEntities.selectedDynamicEntity,
      selectedSentence: data.sentences.selectedSentence,
    }
  })

  const dataTypesChildren = dynamicEntityTypes.map((type) => { return <Option key={type}>{type}</Option> })

  const onClose = async () => {
    await DynamicEntitiesActions.reset()
    closeModal()
  }
  const onSubmit = async (input) => {
    const tempDynamicEntities = selectedSentence.data.dynamicEntities
    tempDynamicEntities[selectedDynamicEntity.name].entities[selectedDynamicEntity.index] = ({
      value: input.value.toLowerCase(),
      synonyms: input.synonyms.toLowerCase().split(','),
    })
    await DynamicEntitiesActions.edit(selectedSentence.id,
      { ...selectedSentence.data, dynamicEntities: tempDynamicEntities })
    await onClose()
  }

  useEffect(() => {
    form.setFieldsValue({
      type: selectedDynamicEntity.name,
      value: selectedDynamicEntity.value,
      synonyms: selectedDynamicEntity.synonyms.join(', '),
    })
  }, [form, selectedDynamicEntity])

  return (
    <Modal
      title='Edit Dynamic Entity'
      visible={visible}
      onCancel={onClose}
      closable={false}
      centered
      width={1000}
      footer={null}
    >
      <Text type='secondary'>{`Sentence ID: ${selectedSentence.id}`}</Text>
      <Descriptions size='small' bordered column={5}>
        <Descriptions.Item label='Text' span={5}>{selectedSentence.text}</Descriptions.Item>
        <Descriptions.Item label='Generalized' span={5}>{selectedSentence.generalized.generalized}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        layout='horizontal'
        name='form__batch_add_test_case'
        onFinish={onSubmit}
      >
        <Form.Item name='type' label='Type' rules={[{ required: true, message: 'Please select a type' }]}>
          <Select
            disabled
            size='small'
            placeholder='Select type'
            style={{ width: '100%' }}
          >
            {dataTypesChildren}
          </Select>
        </Form.Item>

        <Form.Item
          name='value'
          label='Value'
          rules={[{ required: true, message: 'Please add a value' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='synonyms'
          label='Synonyms'
          rules={[
            { required: true, message: 'Please add at least one synomym' },
            (buildDynamicEntityValidator(selectedSentence.generalized.generalized)),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type='primary' htmlType='submit'>Save</Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

EditSentenceDynamicEntities.propTypes = {
  visible: visibleType.isRequired,
  closeModal: closeModalType.isRequired,
}

export default EditSentenceDynamicEntities
