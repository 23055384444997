import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Layout, Button, Divider, Form, Radio, Input, InputNumber, DatePicker } from 'antd'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import style from './index.module.scss'

const { Sider } = Layout

const channels = ['GOOGLE_CHAT', 'MS_TEAMS', 'SMS', 'WHATSAPP', 'SLACK', 'ZOOM', 'GBT']
const datasources = ['production', 'staging', 'canary']

const toInitialValues = (initialFilterValues) => {
  const initialValues = {
    ...initialFilterValues,
  }

  return initialValues
}

const LeftSider = ({ initialFilterValues, onSearch }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()
  const onFinish = (values) => {
    const {
      text,
      limit,
      channel,
      start,
      end,
      datasource,
    } = values

    const filter = {
      text,
      limit,
      channel,
      start,
      end,
      datasource,
    }

    if (values.dateRange) {
      filter.start = Date.parse(values.dateRange[0]) / 1000
      filter.end = Date.parse(values.dateRange[1]) / 1000
    }

    onSearch(filter)
  }

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    types: {
      number: 'Limit is not a valid number!',
    },
    number: {
      range: 'Limit must be between ${min} and ${max}',
    },
  }
  /* eslint-enable no-template-curly-in-string */

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Logs Query</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
          layout='vertical'
          initialValues={toInitialValues(initialFilterValues)}
        >
          <Form.Item
            style={{ marginBottom: 10 }}
            name='text'
          >
            <Input
              placeholder='Lookup with Text'
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='limit'
            rules={[{ type: 'number', min: 1, max: 1000 }]}
          >
            <InputNumber
              placeholder='Lookup with Limit'
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Divider>
            <small><strong>By Channel</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='channel'
          >
            <Radio.Group size='small'>
              {channels.map((channel, index) => {
                return (
                  <Radio.Button key={index} value={channel}>
                    {channel}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Divider>
            <small><strong>By Source</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='datasource'
          >
            <Radio.Group size='small'>
              {datasources.map((datasource, index) => {
                return (
                  <Radio.Button key={index} value={datasource}>
                    {datasource.charAt(0).toUpperCase() + datasource.substring(1, datasource.length)}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Divider>
            <small><strong>By Date Range</strong></small>
          </Divider>
          <Form.Item
            style={{ marginBottom: 10 }}
            name='dateRange'
          >
            <DatePicker.RangePicker showTime format='DD/MM/YYYY HH:mm:ss' />
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
          >
            Search
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSearch: PropTypes.func.isRequired,
  initialFilterValues: PropTypes.shape({
    channel: PropTypes.string.isRequired,
    datasource: PropTypes.string.isRequired,
  }).isRequired,
}

export default LeftSider
