import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import CoreApi from '@core/api'
import { CONVERSATION_ANALYSIS } from '../constants'
import Api from '../../api'

const fetchAnalytic = async (
  dispatch, analytic, params, env, dateRange, extraFilters, defaultValues,
) => {
  dispatch({ type: CONVERSATION_ANALYSIS.FETCH_ANALYTIC.REQUEST })
  try {
    const response = await analytic.fn({ params, env: env.toLowerCase(), dateRange, extraFilters, defaultValues })
    if (response && response.data) {
      dispatch({
        type: CONVERSATION_ANALYSIS.FETCH_ANALYTIC.SUCCESS,
        payload: {
          title: analytic.title,
          results: response.data,
        },
      })
    } else {
      dispatch({ type: CONVERSATION_ANALYSIS.FETCH_ANALYTIC.FAILED })
    }
  } catch (error) {
    dispatch({ type: CONVERSATION_ANALYSIS.FETCH_ANALYTIC.FAILED })
  }
}

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  setEnvironment: (env) => {
    return async (dispatch) => {
      dispatch({ type: CONVERSATION_ANALYSIS.SET_ENVIRONMENT, payload: env })
    }
  },
  setDateRange: (dateRange) => {
    return async (dispatch) => {
      dispatch({ type: CONVERSATION_ANALYSIS.SET_DATE_RANGE, payload: dateRange })
    }
  },
  setPageSize: (newSize) => {
    return async (dispatch) => {
      dispatch({ type: CONVERSATION_ANALYSIS.SET_PAGE_SIZE, payload: newSize })
    }
  },
  selectForAnalyze: (key, params) => {
    return async (dispatch, getState) => {
      const { analyticsLookup, env, dateRange, extraFilters, defaultValues } = getState().conversationAnalysis.analytics
      dispatch({ type: CONVERSATION_ANALYSIS.SELECT_FOR_ANALYZE.REQUEST, payload: key })
      if (analyticsLookup[key]) {
        dispatch({ type: CONVERSATION_ANALYSIS.SELECT_FOR_ANALYZE.SUCCESS, payload: analyticsLookup[key].title })
        await fetchAnalytic(dispatch, analyticsLookup[key], params, env, dateRange, extraFilters, defaultValues)
      } else {
        dispatch({ type: CONVERSATION_ANALYSIS.SELECT_FOR_ANALYZE.FAILED })
      }
    }
  },
  getConversationGraphData: (conversationId) => {
    return async (dispatch, getState) => {
      const { env } = getState().conversationAnalysis.analytics
      dispatch({ type: CONVERSATION_ANALYSIS.GET_GRAPH_CONVERSATION.REQUEST })
      try {
        const results = await Api.getConversationById(conversationId, env.toLowerCase())
        if (results && results.data && results.data.length === 1) {
          dispatch({
            type: CONVERSATION_ANALYSIS.GET_GRAPH_CONVERSATION.SUCCESS,
            payload: results.data,
          })
        } else {
          dispatch({ type: CONVERSATION_ANALYSIS.GET_GRAPH_CONVERSATION.FAILED })
        }
      } catch (e) {
        dispatch({ type: CONVERSATION_ANALYSIS.GET_GRAPH_CONVERSATION.FAILED })
      }
    }
  },
  updateStatus: (id, status) => {
    return async (dispatch, getState) => {
      const { env } = getState().conversationAnalysis.analytics
      dispatch({ type: CONVERSATION_ANALYSIS.UPDATE_STATUS.REQUEST })
      try {
        const results = await Api.updateStatus({ id, status }, env.toLowerCase())
        if (results) {
          dispatch({ type: CONVERSATION_ANALYSIS.UPDATE_STATUS.SUCCESS, payload: { id, status } })
        } else {
          dispatch({ type: CONVERSATION_ANALYSIS.UPDATE_STATUS.FAILED })
        }
      } catch (e) {
        dispatch({ type: CONVERSATION_ANALYSIS.UPDATE_STATUS.FAILED })
      }
    }
  },
  setExtraFilters: (filters) => {
    return async (dispatch, getState) => {
      const { extraFilters } = getState().conversationAnalysis.analytics
      dispatch({ type: CONVERSATION_ANALYSIS.SET_EXTRA_FILTERS, payload: { ...extraFilters, ...filters } })
    }
  },
  fetchResponseKeyList: (i18nVersionId) => {
    return async (dispatch) => {
      dispatch({ type: CONVERSATION_ANALYSIS.FETCH_RESPONSE_KEY_LIST.REQUEST })
      try {
        const results = await CoreApi.Internationalize.Version.getResponseMessage({ app: 'lynx', version: i18nVersionId, sort: 'key', show_all: true })
        if (results) {
          const keys = results.data?.items?.map((item) => { return item.key })
          // filtering duplicated keys
          const responseKeyList = [...new Set(keys)]

          dispatch({ type: CONVERSATION_ANALYSIS.FETCH_RESPONSE_KEY_LIST.SUCCESS, payload: responseKeyList })
        } else {
          dispatch({ type: CONVERSATION_ANALYSIS.FETCH_RESPONSE_KEY_LIST.FAILED })
        }
      } catch (error) {
        dispatch({ type: CONVERSATION_ANALYSIS.FETCH_RESPONSE_KEY_LIST.FAILED })
      }
    }
  },
  setViewMode: (viewMode) => {
    return async (dispatch) => {
      dispatch({ type: CONVERSATION_ANALYSIS.SET_VIEW_MODE, payload: viewMode })
    }
  },
})
