import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  PageHeader,
  Divider,
  Layout,
} from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'

import SentencesMetaData from '../../components/SentenceMetaData'

import style from './index.module.scss'

const { Content } = Layout

const ShowSelectedSentence = () => {
  const history = useHistory()
  const stateRouter = history.location.state
  const sentenceArrayindex = stateRouter ? stateRouter.sentenceArrayindex : { sentenceArrayindex: null }

  const onBack = () => { history.push('/utterances/sentences') }
  const { listed = [] } = useSelector(({ data }) => { return data.sentences })

  const previousLink = (listed.length && sentenceArrayindex - 1 >= 0) ? `${listed[sentenceArrayindex - 1].id}` : ''
  const nextLink = (listed.length && sentenceArrayindex < listed.length - 1) ? `${listed[sentenceArrayindex + 1].id}` : ''
  const onLeftKeyPress = () => {
    if (previousLink) {
      history.push({
        pathname: previousLink,
        state: {
          sentenceArrayindex: sentenceArrayindex - 1 >= 0 ? sentenceArrayindex - 1 : null,
        },
      })
    }
  }

  const onRightKeyPress = () => {
    if (nextLink) {
      history.push({
        pathname: nextLink,
        state: {
          sentenceArrayindex: sentenceArrayindex <= listed.length - 1 ? sentenceArrayindex + 1 : null,
        },
      })
    }
  }

  useHotkeys('left', onLeftKeyPress, {}, [previousLink, sentenceArrayindex, listed])
  useHotkeys('a', onLeftKeyPress, {}, [previousLink, sentenceArrayindex, listed])
  useHotkeys('right', onRightKeyPress, {}, [nextLink, sentenceArrayindex, listed])
  useHotkeys('d', onRightKeyPress, {}, [nextLink, sentenceArrayindex, listed])

  return (
    <Content>
      <PageHeader
        className={style.Top__header}
        onBack={onBack}
        title='Back to sentences list'
      >
        {(previousLink || nextLink) && (
          <>
            <Divider />
            <Row align='middle' className='messages__sentence_navigator'>
              <Col span={12}>
                {previousLink && (
                  <Link
                    to={{
                      pathname: previousLink,
                      state: {
                        sentenceArrayindex: sentenceArrayindex - 1 >= 0 ? sentenceArrayindex - 1 : null,
                      },
                    }}
                  >
                    <LeftOutlined />
                    &thinsp;
                    Previous Sentence
                  </Link>
                )}
              </Col>
              <Col span={12}>
                {nextLink && (
                  <Link
                    to={{
                      pathname: nextLink,
                      state: {
                        sentenceArrayindex: sentenceArrayindex <= listed.length - 1 ? sentenceArrayindex + 1 : null,
                      },
                    }}
                  >
                    Next Sentence
                    &thinsp;
                    <RightOutlined />
                  </Link>
                )}
              </Col>
            </Row>
          </>
        )}
      </PageHeader>

      <Row wrap='false'>
        <Col flex='1'>
          <SentencesMetaData />
        </Col>
      </Row>
    </Content>
  )
}

export default ShowSelectedSentence
