// TODO: Handle level two intent
export default function generateTableDatasource(intentEndpointResponse) {
  /*
  // what about children
  [
    { positive: 'quote', negative: 'not-quote', submitted: 'positive', status: approval_status }
    { positive: 'visa', negative: 'not-visa', submitted: 'negative', status: approval_status }
  ]
  */

  // now: we have to take the existing training to the account
  if (!intentEndpointResponse) return []

  const {
    intentPairs,
    specificIntentPairs,
  } = intentEndpointResponse

  const intentDatasource = intentPairs ? Object.values(intentPairs).map((intentDefinition) => {
    return {
      id: `${intentDefinition.positive.name}::${intentDefinition.negative.name}`,
      level: 1,
      positive: intentDefinition.positive.name,
      negative: intentDefinition.negative.name,
      raw: intentDefinition,
    }
  }) : []

  const specificIntentDatasource = specificIntentPairs ? Object.values(specificIntentPairs).map((intentDefinition) => {
    return {
      id: `${intentDefinition.positive.name}::${intentDefinition.negative.name}`,
      level: 2,
      positive: intentDefinition.positive.name,
      negative: intentDefinition.negative.name,
      raw: intentDefinition,
    }
  }) : []

  const sortByPositive = (levelOne, levelTwo) => {
    const merged = [...levelOne, ...levelTwo]

    return merged.sort((a, b) => {
      const types = [typeof a.positive, typeof b.positive]
      if (types[0] === 'string' && types[1] === 'string') {
        return a.positive.localeCompare(b.positive)
      }
      return !!a - !!b // cast to boolean to get -1 or 1
    })
  }

  return sortByPositive(intentDatasource, specificIntentDatasource)
}
