import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Form,
  Alert,
} from 'antd'
import KeywordPhrasesForm from '../KeywordPhrasesForm'
import Api from '../../../api'

const KeywordPhrasesAddDialog = ({
  faqVersionId,
  visible,
  onDismiss,
  onCreated,
}) => {
  const [form] = Form.useForm()
  const [creating, setCreating] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)

  useEffect(() => {
    if (!visible) setFlashMessage(null)
  }, [visible])

  if (!visible) return null

  const onOk = async () => {
    form.submit()
  }

  const onCancel = async () => {
    setCreating(false)
    onDismiss()
  }

  const onFinish = async (values) => {
    setFlashMessage(null)
    setCreating(true)
    try {
      const { data: responseData } = await Api.addCombination({
        ...values,
        faq_version_id: faqVersionId,
      })
      setCreating(false)
      onCreated(responseData)
    } catch (error) {
      setFlashMessage({ type: 'error', message: error.message })
      setCreating(false)
    }
  }

  const handleFlashMessageClose = () => {
    setFlashMessage(null)
  }

  return (
    <Modal
      title='Add Faq Keyword Phrases Combinations'
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      {flashMessage && (
        <Alert
          message={flashMessage.message}
          type={flashMessage.type}
          showIcon
          closable
          onClose={handleFlashMessageClose}
          style={{ marginBottom: 8 }}
        />
      )}
      {visible && (
        <KeywordPhrasesForm
          form={form}
          combinations={{
            language: 'en',
            keywords: '',
            phrases: [],
          }}
          loading={creating}
          onFinish={onFinish}
        />
      )}
    </Modal>
  )
}

KeywordPhrasesAddDialog.propTypes = {
  faqVersionId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
}

export default KeywordPhrasesAddDialog
