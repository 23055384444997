import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Alert,
  Form,
  Spin,
  Space,
  Button,
} from 'antd'
import useTimeoutAlert from '@core/hooks/useTimeoutAlert'
import useRequest from '@core/hooks/useRequest'

import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'

import Api from '../../../../../api'
import { EmailAutomationsContext } from '../../../../../context'
import CoordinatedIntentSelectBoxes from '../../../CoordinatedIntentSelectBoxes'

import IntentsList from './IntentsList'

import style from './style.module.scss'

const mapFilterSubmittedIntent = (intents) => {
  return intents.reduce((array, intent) => {
    if (intent.approval_status === 'SUBMITTED') array.push(intent.id)

    return array
  }, [])
}

const TrainingCases = ({ selectedEmailId, locallyUpdateAssignedIntents, setIsFormDirty, activeKey }) => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const dataSourceQueryParams = { version: currentVersion, datasource }

  const [pendingRequest, setPendingRequest] = useState(false)
  const [intentApprovalError, setIntentApprovalError] = useState(false)
  const [intentApprovalSuccess, setIntentApprovalSuccess] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)
  const [isAddingIntents, setIsAddingIntents] = useState(false)
  const [showAddedFeedback, setShowAddedFeedback] = useTimeoutAlert(null)
  const [showDeletedFeedback, setShowDeletedFeedback] = useTimeoutAlert(null)
  const [showAddedError, setShowAddedError] = useTimeoutAlert(null)
  const [showDeletedError, setShowDeletedError] = useTimeoutAlert(null)
  const [form] = Form.useForm()

  const [
    { loading: currentSampleLoading, data: assignedTrainingIntents },
    { silentMakeRequest: getTrainingCases },
  ] = useRequest(Api.Emails.getSamples, dataSourceQueryParams, selectedEmailId)
  const [{ data: intents }] = useRequest(Api.Intents.getIntents, dataSourceQueryParams)

  const onFinish = async (values) => {
    const { intentIds } = values
    setIsAddingIntents(true)
    setShowAddedFeedback(null)

    try {
      const response = await Api.Trainings.batchUpsert({
        version: currentVersion,
        intentIds: intentIds.map((intent) => { return intent.value }),
        emailItemIds: [selectedEmailId],
        datasource,
      })

      if (response?.status === 'success') {
        const updatedAssignedIntents = await getTrainingCases() // this returns updated one, we can use this response
        if (updatedAssignedIntents) locallyUpdateAssignedIntents(updatedAssignedIntents)
        setShowAddedFeedback(true)
      }
    } catch (error) {
      console.error('TrainingCases#onFinish - error - ', error)
      setShowAddedError({
        intentNames: intentIds.reduce((acc, { label }, index, array) => {
          return `${acc} ${label}${index < array.length - 1 ? ',' : ''}`
        }, ''),
      })
      console.error('TrainingCases#onFinish - error during submit training intents')
      console.error(error)
    }

    form.resetFields()
    setIsFormDirty(false)
    setIsAddingIntents(false)
  }

  /*
    *** Intents List ***
  */

  // batch delete doesn't update status to IN_PROGRESS
  const deleteIntentTagging = async (intent) => {
    setIsDeleting(true)
    setShowDeletedFeedback(null)

    try {
      const response = await Api.Trainings.deleteSample({
        version: currentVersion,
        emailId: selectedEmailId,
        intentId: intent.id,
        datasource,
      })

      if (response?.status === 'success') {
        const updatedAssignedIntents = await getTrainingCases() // this returns updated one, we can use this response
        if (updatedAssignedIntents) locallyUpdateAssignedIntents(updatedAssignedIntents)

        form.resetFields()
      }
    } catch (error) {
      console.error('TrainingCases#deleteIntentTagging - error - ', error)
      setShowDeletedError({ intentName: intent.name })
      console.error(error)
    }

    form.resetFields()
    setIsDeleting(false)
    setShowDeletedFeedback({ intentName: intent.name })
  }

  const approveIntents = async (intentIds) => {
    setPendingRequest(true)
    setIntentApprovalError(null)
    setIntentApprovalSuccess(null)

    try {
      const response = await Api.Trainings.batchApprove({
        version: currentVersion,
        emailId: selectedEmailId,
        intentIds,
        datasource,
      })

      if (response?.status === 'success') {
        await getTrainingCases()
        setIntentApprovalSuccess({ type: 'approve' })
      }
    } catch (error) {
      console.error('requestFn - error - ', error)
      setIntentApprovalError({ type: 'approve' })
    }

    setPendingRequest(false)
  }

  const rejectIntents = async (intentIds) => {
    setPendingRequest(true)
    setIntentApprovalError(null)
    setIntentApprovalSuccess(null)

    try {
      const response = await Api.Trainings.batchReject({
        version: currentVersion,
        emailId: selectedEmailId,
        intentIds,
        datasource,
      })

      if (response?.status === 'success') {
        await getTrainingCases()
        setIntentApprovalSuccess({ type: 'reject' })
      }
    } catch (error) {
      console.error('requestFn - error - ', error)
      setIntentApprovalError({ type: 'reject' })
    }

    setPendingRequest(false)
  }

  const approveIntentTagging = (intent) => {
    approveIntents([intent.id])
  }

  const rejectIntentTagging = (intent) => {
    rejectIntents([intent.id])
  }

  const approveAllIntentTagging = () => {
    approveIntents(mapFilterSubmittedIntent(assignedTrainingIntents))
  }

  const rejectAllIntentTagging = () => {
    rejectIntents(mapFilterSubmittedIntent(assignedTrainingIntents))
  }

  useEffect(() => {
    form.setFieldsValue({
      intentIds: assignedTrainingIntents?.map((intent) => {
        return { label: intent.name, value: intent.id }
      }),
    })
  }, [form, assignedTrainingIntents])

  useEffect(() => {
    form.resetFields()
  }, [activeKey, form])

  return (
    <Spin spinning={isDeleting || isAddingIntents || currentSampleLoading}>
      <IntentsList
        loading={pendingRequest}
        intents={assignedTrainingIntents ?? []}
        intentApprovalSuccess={intentApprovalSuccess}
        intentApprovalError={intentApprovalError}
        deleteIntentTagging={deleteIntentTagging}
        approveIntentTagging={approveIntentTagging}
        rejectIntentTagging={rejectIntentTagging}
        approveAllIntentTagging={approveAllIntentTagging}
        rejectAllIntentTagging={rejectAllIntentTagging}
      />
      <PermittedFeatureCheck featurePath='emailautomations.intentTrainingData.actionAdd'>
        {showDeletedError && (
          <Alert
            className={style.AlertFeedback}
            closable
            message={`Error when removing this intent: "${showDeletedError.intentName}"`}
            type='error'
          />
        )}
        {showDeletedFeedback && (
          <Alert
            className={style.AlertFeedback}
            closable
            message={`Successfully deleted intent "${showDeletedFeedback.intentName}"`}
            type='info'
          />
        )}
        <Form
          form={form}
          className={style.Form}
          layout='vertical'
          onFinish={onFinish}
          onValuesChange={() => { setIsFormDirty(true) }}
          initialValues={{
            intentIds: assignedTrainingIntents?.map((intent) => {
              return { label: intent.name, value: intent.id }
            }),
          }}
        >
          <Typography.Title level={5}>Add new Training Case</Typography.Title>
          {showAddedError && (
            <Alert
              className={style.AlertFeedback}
              closable
              message={`Error while adding following intents: ${showAddedError.intentNames}`}
              type='error'
            />
          )}
          {showAddedFeedback && (
            <Alert
              className={style.AlertFeedback}
              closable
              message='Successfully added new intent(s)'
              type='success'
            />
          )}
          <Form.Item
            label='Intent'
            name='intentIds'
            rules={[{ required: true, message: 'Please select intent' }]}
          >
            <CoordinatedIntentSelectBoxes
              intentPairs={intents?.intentPairs || {}}
              assignedIntents={assignedTrainingIntents?.map((intent) => {
                return { label: intent.name, value: intent.id }
              })}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>Add</Button>
            </Space>
          </Form.Item>
        </Form>
      </PermittedFeatureCheck>
    </Spin>
  )
}
TrainingCases.defaultProps = {
  setIsFormDirty: () => { },
  activeKey: 'parentTabKey',
}

TrainingCases.propTypes = {
  selectedEmailId: PropTypes.string.isRequired,
  locallyUpdateAssignedIntents: PropTypes.func.isRequired,
  setIsFormDirty: PropTypes.func,
  activeKey: PropTypes.string,
}

export default TrainingCases
