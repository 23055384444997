import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import {
  EXTRACT_INFORMATION,
  ADD_TEST_SET,
  OPEN_MODAL,
  DISMISS_MODAL,
  SET_SENTENCE_ANALYSIS_DATA,
} from '../constants'
import Api from '../../api'

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  extractInformation: (text = '', data, options = {}) => {
    return async (dispatch) => {
      dispatch({ type: EXTRACT_INFORMATION.REQUEST })
      try {
        const { showTags, onlyGeneralize } = options
        const payload = { text, options: { show_tags: showTags, only_generalize: onlyGeneralize } }
        if (data && Object.entries(data).length > 0) {
          payload.data = data
        }
        const response = await Api.extractInformation(payload)
        if (response && response.data) {
          dispatch({
            type: EXTRACT_INFORMATION.SUCCESS,
            payload: response,
          })
        } else {
          dispatch({
            type: EXTRACT_INFORMATION.FAILED,
            payload: {
              result: null,
            },
          })
        }
      } catch (error) {
        dispatch({
          type: EXTRACT_INFORMATION.FAILED,
          payload: {
            result: null,
          },
        })
      }
    }
  },
  addToTestSet: (text, test_intent) => {
    return async (dispatch) => {
      dispatch({ type: ADD_TEST_SET.REQUEST, payload: { text } })
      try {
        const response = await Api.addToTestSet({ text, test_intent })
        if (response && response.data) {
          dispatch({ type: ADD_TEST_SET.SUCCESS })
        } else {
          dispatch({ type: ADD_TEST_SET.FAILED })
        }
      } catch (error) {
        dispatch({ type: ADD_TEST_SET.FAILED })
      }
    }
  },
  openModal: (initialSentence = '', data = null) => {
    return async (dispatch) => {
      dispatch({
        type: OPEN_MODAL,
        payload: { initialSentence, data, hasData: data !== null },
      })
    }
  },
  setData: (data) => {
    return async (dispatch) => {
      dispatch({
        type: SET_SENTENCE_ANALYSIS_DATA,
        payload: { data, hasData: data !== null },
      })
    }
  },
  dismissModal: () => {
    return async (dispatch) => {
      dispatch({ type: DISMISS_MODAL })
    }
  },
})
