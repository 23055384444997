import { wrapActionCreatorWithDispatch } from './dispatchInjection'
import { AUTH } from '../constants'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  login: ({ username, password }) => {
    return async (dispatch) => {
      dispatch({
        type: AUTH.LOGIN.REQUEST,
      })

      try {
        const response = await Api.Auth.login({ username, password })

        if (response && response.status === 'success') {
          dispatch({
            type: AUTH.LOGIN.SUCCESS,
            payload: response.data,
          })

          return response.data
        }

        dispatch({
          type: AUTH.LOGIN.FAILED,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: AUTH.LOGIN.FAILED,
          error,
        })

        throw error
      }
    }
  },
  synchronizeToken: ({ refresh_token, access_token }) => {
    return async (dispatch) => {
      dispatch({
        type: AUTH.SYNCHRONIZE_TOKEN,
        payload: {
          refresh_token,
          access_token,
        },
      })
    }
  },
  logout: () => {
    return async (dispatch) => {
      dispatch({
        type: AUTH.LOGOUT.REQUEST,
      })

      try {
        const response = await Api.Auth.logout()

        if (response && response.status === 'success') {
          dispatch({
            type: AUTH.LOGOUT.SUCCESS,
          })

          return response.data
        }

        dispatch({
          type: AUTH.LOGOUT.FAILED,
        })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: AUTH.LOGOUT.FAILED,
          error,
        })

        throw error
      }
    }
  },
  hardLogout: () => {
    return async (dispatch) => {
      dispatch({
        type: AUTH.LOGOUT.SUCCESS,
      })
    }
  },
})
