import React from 'react'
import { Form, Input, Typography, Cascader } from 'antd'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import { formInstanceType, sentenceType } from '../../../types/form'

const { Title } = Typography

const IntentForm = ({ form, sentence }) => {
  const { intentCascader } = useDomainIntentSelector()
  if (intentCascader[0].value > 0) intentCascader.unshift({ value: -1, label: 'NULL', children: [{ label: 'NULL', value: -1 }] })

  return (
    <Form
      form={form}
      layout='vertical'
      name='form__test_intent'
      initialValues={{
        test_domain: null,
        test_intent: null,
      }}
    >
      <Title level={4}>{sentence}</Title>
      <Form.Item
        name='domain_intent'
        label='Select Test Domain/Intent'
        rules={[
          {
            required: true,
            message: 'Please selected desired Domain/Intent',
          },
        ]}
      >
        <Cascader
          options={intentCascader}
          placeholder='Select Test Domain/Intent'
        />
      </Form.Item>
      <Form.Item name='note' label='Note'>
        <Input.TextArea showCount maxLength={255} style={{ resize: 'vertical' }} />
      </Form.Item>
    </Form>
  )
}

IntentForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
}

export default IntentForm
