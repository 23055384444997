import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import {
  ANALYZE_TAGGERS,
  TRAIN_TAGGERS,
  DISPLAY_TAGGER_TRAININGS,
} from '../constants'
import Api from '../../api'

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  trainTaggers: (taggers) => {
    return async (dispatch) => {
      dispatch({ type: TRAIN_TAGGERS.REQUEST })
      try {
        const response = await Api.trainTaggers({ taggers })
        if (response && response.data) {
          dispatch({
            type: TRAIN_TAGGERS.SUCCESS,
            payload: response.data,
          })
        } else {
          dispatch({
            type: TRAIN_TAGGERS.FAILED,
            payload: {
              error: 'No result returned',
            },
          })
        }
      } catch (error) {
        dispatch({
          type: TRAIN_TAGGERS.FAILED,
          payload: {
            error,
          },
        })
      }
    }
  },
  analyzeTaggers: (taggers) => {
    return async (dispatch) => {
      dispatch({ type: ANALYZE_TAGGERS.REQUEST })
      try {
        const response = await Api.analyzeTaggers({ taggers })
        if (response && response.data) {
          dispatch({
            type: ANALYZE_TAGGERS.SUCCESS,
            payload: response.data,
          })
        } else {
          dispatch({
            type: ANALYZE_TAGGERS.FAILED,
            payload: {
              error: 'No result returned',
            },
          })
        }
      } catch (error) {
        dispatch({
          type: ANALYZE_TAGGERS.FAILED,
          payload: {
            error,
          },
        })
      }
    }
  },
  displayTaggerTrainings: (taggers) => {
    return async (dispatch) => {
      dispatch({ type: DISPLAY_TAGGER_TRAININGS.REQUEST })
      try {
        const response = await Api.displayTaggerTrainings({ taggers })
        if (response && response.data) {
          dispatch({
            type: DISPLAY_TAGGER_TRAININGS.SUCCESS,
            payload: response.data,
          })
        } else {
          dispatch({
            type: DISPLAY_TAGGER_TRAININGS.FAILED,
            payload: {
              error: 'No result returned',
            },
          })
        }
      } catch (error) {
        dispatch({
          type: DISPLAY_TAGGER_TRAININGS.FAILED,
          payload: {
            error,
          },
        })
      }
    }
  },
})
