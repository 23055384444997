import React, { useState } from 'react'
import {
  Input,
  Button,
  Typography,
  Radio,
  Form,
  Spin,
} from 'antd'
import api from '../../api'
import style from './index.module.scss'

const EmailUUIDConverter = () => {
  const [tab, setTab] = useState('Email to UUID')

  return (
    <>
      <Radio.Group
        size='small'
        value={tab}
        onChange={(e) => { return setTab(e.target.value) }}
        style={{ marginBottom: 16 }}
      >
        {['Email to UUID', 'UUID to Email'].map((option, index) => {
          return (
            <Radio.Button key={index} value={option}>
              {option}
            </Radio.Button>
          )
        })}
      </Radio.Group>
      {tab === 'Email to UUID' && <EmailToUUID />}
      {tab === 'UUID to Email' && <UUIDToEmail />}
    </>
  )
}

export const EmailToUUID = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [resultText, setResultText] = useState('')
  const [isError, setIsError] = useState(false)

  const onConvert = async ({ email }) => {
    setIsLoading(true)
    try {
      const { data } = await api.getEmailUUIDConversion({
        emailOrUUID: email,
      })
      setResultText(data)
      setIsError(false)
    } catch {
      setResultText('Error')
      setIsError(true)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Form className={style.flexCenterGap} onFinish={onConvert}>
        <Typography.Text className={style.mb} strong>Email:</Typography.Text>
        <Form.Item
          name='email'
        >
          <Input
            style={{ width: '300px' }}
            disabled={isLoading}
            placeholder='Enter email address'
          />
        </Form.Item>
        <Button
          className={style.mb}
          disabled={isLoading}
          htmlType='submit'
          type='primary'
        >
          Convert
        </Button>
      </Form>
      <div className={style.flexCenterGap}>
        <Typography.Text strong>UUID:</Typography.Text>
        {isLoading
          ? <Spin />
          : (
            <Typography.Text
              copyable={!isError && resultText.length}
              type={isError ? 'danger' : ''}
            >
              {resultText}
            </Typography.Text>
          )}
      </div>
    </>
  )
}

export const UUIDToEmail = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [resultText, setResultText] = useState('')
  const [isError, setIsError] = useState(false)

  const onConvert = async ({ uuid }) => {
    setIsLoading(true)
    try {
      const { data } = await api.getEmailUUIDConversion({
        emailOrUUID: uuid,
      })
      setResultText(data)
      setIsError(false)
    } catch {
      setResultText('Error')
      setIsError(true)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Form className={style.flexCenterGap} onFinish={onConvert}>
        <Typography.Text className={style.mb} strong>UUID:</Typography.Text>
        <Form.Item
          name='uuid'
        >
          <Input
            style={{ width: '300px' }}
            disabled={isLoading}
            placeholder='Enter UUID'
          />
        </Form.Item>
        <Button
          className={style.mb}
          disabled={isLoading}
          htmlType='submit'
          type='primary'
        >
          Convert
        </Button>
      </Form>
      <div className={style.flexCenterGap}>
        <Typography.Text strong>Email:</Typography.Text>
        {isLoading
          ? <Spin />
          : (
            <Typography.Text
              copyable={!isError && resultText.length}
              type={isError ? 'danger' : ''}
            >
              {resultText}
            </Typography.Text>
          )}
      </div>
    </>
  )
}

export default EmailUUIDConverter
