import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { BULK_OPERATION_MODAL_UI } from '../constants'

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  open: (selectedOperation) => {
    return async (dispatch) => {
      dispatch({
        type: BULK_OPERATION_MODAL_UI.OPEN,
        payload: {
          selectedOperation: selectedOperation.toLowerCase(),
        },
      })
    }
  },
  close: () => {
    return async (dispatch) => {
      dispatch({ type: BULK_OPERATION_MODAL_UI.CLOSE })
    }
  },
})
