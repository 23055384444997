import cloneDeep from 'lodash.clonedeep'
import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { reHashIndex } from '@core/helpers'
import { SELECTED_SENTENCE, SENTENCE } from '../constants'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  bulkAddIntentTestSetsAllSettled: (sentenceIds, testDomainIntent) => {
    return async (dispatch, getState) => {
      dispatch({ type: SENTENCE.BULK_MODE.APPLY_TEST.REQUEST })
      try {
        const response = await Promise.allSettled(sentenceIds.map(async (sentenceId) => {
          const { test_domain_id, test_intent_id } = testDomainIntent

          if (test_domain_id === -1) {
            const testDomain = await Api.addDomainTestSet({ sentence_id: sentenceId, test_domain_id })

            return {
              sentenceId,
              testDomainResponse: testDomain.data || testDomain.reason,
              failed: !!testDomain.reason,
            }
          }
          // previously, the allSettled on L#13 is not destructed and return as the const value
          // holyshit...
          const [testDomain, testIntent] = await Promise.allSettled([
            Api.addDomainTestSet({ sentence_id: sentenceId, test_domain_id }),
            Api.addIntentTestSet({ sentence_id: sentenceId, test_intent_id }),
          ])
          // transform the allSettled return value

          // NOTE: this is SEMI happy path
          return {
            sentenceId,
            // actually we might not need to send the response of these...
            testDomainResponse: testDomain.value.data || testDomain.reason,
            testIntentResponse: testIntent?.value.data || testIntent?.reason,
            failed: !!(testDomain.reason || testIntent.reason),
          }
        }))

        if (response && response.length >= 1) {
          const { listed, sentencesHash } = getState().data.sentences
          const newSentencesSet = cloneDeep(listed)
          response.forEach((updatedSentence) => {
            const timeStamp = Date.now()
            const index = sentencesHash[updatedSentence.value.sentenceId]
            newSentencesSet[index] = {
              ...newSentencesSet[index],
              changed_timestamp: `${new Date(timeStamp).toLocaleDateString('en-US')} ${new Date(timeStamp).toLocaleTimeString('en-US')}`,
            }
          })

          return dispatch({
            type: SENTENCE.BULK_MODE.APPLY_TEST.SUCCESS,
            payload: {
              sentencesChanged: response.map((testCase) => { return testCase.sentence_id }),
              newSentencesSet,
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SENTENCE.BULK_MODE.APPLY_TEST.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  // NOTE: This one seems like deprecated
  bulkAddIntentTestSets: (sentenceIds, testDomainIntent) => {
    return async (dispatch, getState) => {
      dispatch({ type: SENTENCE.BULK_MODE.APPLY_TEST.REQUEST })
      try {
        const response = await Api.bulkAddIntentTestSets({
          sentence_ids: sentenceIds,
          ...testDomainIntent,
        })

        if (response && response.data) {
          const {
            listed,
            sentencesHash,
          } = getState().data.sentences

          const newSentencesSet = cloneDeep(listed)
          response.data.forEach((updatedSentence) => {
            const timeStamp = Date.now()
            const index = sentencesHash[updatedSentence.sentence_id]
            newSentencesSet[index] = {
              ...newSentencesSet[index],
              changed_timestamp: `${new Date(timeStamp).toLocaleDateString('en-US')} ${new Date(timeStamp).toLocaleTimeString('en-US')}`,
            }
          })

          return dispatch({
            type: SENTENCE.BULK_MODE.APPLY_TEST.SUCCESS,
            payload: {
              sentencesChanged: response.data.map((testCase) => { return testCase.sentence_id }),
              newSentencesSet,
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SENTENCE.BULK_MODE.APPLY_TEST.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  addDomainTestSet: (sentence_id, { test_domain_id, note }) => {
    return async (dispatch) => {
      dispatch({
        type: SELECTED_SENTENCE.TEST_CASES.ADD_DOMAIN.REQUEST,
      })

      const payload = {
        sentence_id,
        test_domain_id,
        note,
      }

      try {
        const response = await Api.addDomainTestSet(payload)

        if (response && response.data) {
          if (response.data.intentTestSetId) {
            dispatch({
              type: SELECTED_SENTENCE.TEST_CASES.UPDATE_INTENT.SUCCESS,
              payload: {
                result: null,
                index: 0,
                type: 'intent',
              },
            })
          }
          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.ADD_DOMAIN.SUCCESS,
            payload: {
              result: response.data,
              type: 'domain',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.ADD_DOMAIN.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  addIntentTestSet: (sentence_id, formData) => {
    return async (dispatch) => {
      const { domain_intent, note } = formData
      const [/* test_domain_id */, test_intent_id] = domain_intent

      dispatch({
        type: SELECTED_SENTENCE.TEST_CASES.ADD_INTENT.REQUEST,
      })

      const payload = {
        sentence_id,
        test_intent_id,
        note,
      }

      try {
        const response = await Api.addIntentTestSet(payload)

        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.ADD_INTENT.SUCCESS,
            payload: {
              result: response.data,
              type: 'intent',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.ADD_INTENT.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  addTaggerTestSet: (sentence_id, formData) => {
    return async (dispatch) => {
      dispatch({
        type: SELECTED_SENTENCE.TEST_CASES.ADD_TAGGER.REQUEST,
      })
      const { expected_result, phrase: tempPhrase, tagger_type, note, skip } = formData
      const { value: phrase, phraseIndex: phrase_index } = JSON.parse(tempPhrase)
      const payload = {
        sentence_id,
        expected_result,
        phrase,
        phrase_index,
        tagger_type,
        note,
        skip,
      }

      try {
        const response = await Api.addTaggerTestSet(payload)

        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.ADD_TAGGER.SUCCESS,
            payload: {
              result: response.data,
              type: 'tagger',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.ADD_TAGGER.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  addExtractorTestSet: (sentence_id, formData) => {
    return async (dispatch) => {
      dispatch({
        type: SELECTED_SENTENCE.TEST_CASES.ADD_EXTRACTOR.REQUEST,
      })
      const payload = {
        sentence_id,
        ...formData,
      }
      const intent_id = formData.test_intent_id[1]
      payload.test_intent_id = intent_id

      try {
        const response = await Api.addExtractorTestSet(payload)

        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.ADD_EXTRACTOR.SUCCESS,
            payload: {
              result: response.data,
              type: 'extractor',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.ADD_EXTRACTOR.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  updateDomainTestSetById: (sentence_id, testDomainIntent, rowIndex) => {
    return async (dispatch) => {
      const { test_domain_id } = testDomainIntent

      const payload = {
        test_domain_id,
      }

      dispatch({
        type: SELECTED_SENTENCE.TEST_CASES.UPDATE_DOMAIN.REQUEST,
      })

      try {
        const response = await Api.updateDomainTestSetById(sentence_id, payload)
        if (response && response.data) {
          if (response.data.intentTestSetId) {
            dispatch({
              type: SELECTED_SENTENCE.TEST_CASES.UPDATE_INTENT.SUCCESS,
              payload: {
                result: null,
                index: rowIndex,
                type: 'intent',
              },
            })
          }
          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.UPDATE_DOMAIN.SUCCESS,
            payload: {
              result: response.data,
              index: rowIndex,
              type: 'domain',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.UPDATE_DOMAIN.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  updateIntentTestSetById: (sentence_id, testDomainIntent, rowIndex) => {
    return async (dispatch) => {
      const { test_intent_id } = testDomainIntent
      const payload = {}

      if (test_intent_id) payload.test_intent_id = test_intent_id

      dispatch({
        type: SELECTED_SENTENCE.TEST_CASES.UPDATE_INTENT.REQUEST,
      })

      try {
        const response = await Api.updateIntentTestSetById(sentence_id, payload)
        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.UPDATE_INTENT.SUCCESS,
            payload: {
              result: response.data,
              index: rowIndex,
              type: 'intent',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.UPDATE_INTENT.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  updateTaggerTestSetById: (sentence_id, expectedTaggerValue, rowIndex) => {
    return async (dispatch) => {
      const { tagger_type, phrase: tempPhrase, expected_result, note, skip } = expectedTaggerValue

      let validatedPhrase
      try {
        validatedPhrase = JSON.parse(tempPhrase)
      } catch (e) {
        const temp = tempPhrase.split(':').map((item) => { return item.trim() })
        validatedPhrase = { phraseIndex: temp[0].replace(/(\[|\])/gi, ''), value: temp[1] }
      }
      const { value: phrase, phraseIndex: phrase_index } = validatedPhrase
      const payload = {
        tagger_type,
        phrase,
        phrase_index,
        expected_result,
        note: note || '',
        skip,
      }

      dispatch({
        type: SELECTED_SENTENCE.TEST_CASES.UPDATE_TAGGER.REQUEST,
      })

      try {
        const response = await Api.updateTaggerTestSetById(sentence_id, payload)
        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.UPDATE_TAGGER.SUCCESS,
            payload: {
              result: response.data,
              index: rowIndex,
              type: 'tagger',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.UPDATE_TAGGER.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  updateExtractorTestSetById: (sentence_id, expectedExtractorValue) => {
    return async (dispatch) => {
      const {
        expected_data,
        skip,
        note,
        test_intent_id,
        relative_date,
      } = expectedExtractorValue

      const payload = {
        expected_data: JSON.stringify(JSON.parse(expected_data)),
        skip: skip || false,
        note: note || '',
        test_intent_id: test_intent_id[1],
        relative_date,
      }
      dispatch({
        type: SELECTED_SENTENCE.TEST_CASES.UPDATE_EXTRACTOR.REQUEST,
      })

      try {
        const response = await Api.updateExtractorTestSetById(sentence_id, payload)
        if (response && response.data) {
          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.UPDATE_EXTRACTOR.SUCCESS,
            payload: {
              result: response.data,
              index: 0,
              type: 'extractor',
            },
          })
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.UPDATE_EXTRACTOR.FAILED,
          payload: null,
          error,
        })

        throw error
      }
    }
  },
  deleteDomainTestCase: (caseId) => {
    return async (dispatch, getState) => {
      dispatch({ type: SELECTED_SENTENCE.TEST_CASES.DELETE_DOMAIN.REQUEST })
      try {
        const response = await Api.deleteDomainTestSetByIds({ ids: [caseId] })
        if (response && response.data) {
          const {
            hashedIndex_domain_test_sets: hashedList,
            domain_test_sets,
          } = getState().data.selectedSentence.testCases
          const newDomainTestSets = cloneDeep(domain_test_sets)
          newDomainTestSets.splice(hashedList[caseId], 1)

          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.DELETE_DOMAIN.SUCCESS,
            payload: {
              type: 'domain',
              domain_test_sets: newDomainTestSets,
              hashedIndex_domain_test_sets: reHashIndex(newDomainTestSets),
            },
          })
        }
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.DELETE_DOMAIN.FAILED,
        })
        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.DELETE_DOMAIN.FAILED,
          error,
        })

        throw error
      }
    }
  },
  deleteIntentTestCase: (caseId) => {
    return async (dispatch, getState) => {
      dispatch({ type: SELECTED_SENTENCE.TEST_CASES.DELETE_INTENT.REQUEST })
      try {
        const response = await Api.deleteIntentTestSetByIds({ ids: [caseId] })
        if (response && response.data) {
          const {
            hashedIndex_intent_test_sets: hashedList,
            intent_test_sets,
          } = getState().data.selectedSentence.testCases
          const newIntentTestSets = cloneDeep(intent_test_sets)
          newIntentTestSets.splice(hashedList[caseId], 1)

          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.DELETE_INTENT.SUCCESS,
            payload: {
              type: 'intent',
              intent_test_sets: newIntentTestSets,
              hashedIndex_intent_test_sets: reHashIndex(newIntentTestSets),
            },
          })
        }
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.DELETE_INTENT.FAILED,
        })
        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.DELETE_INTENT.FAILED,
          error,
        })

        throw error
      }
    }
  },
  deleteTaggerTestCase: (caseId) => {
    return async (dispatch, getState) => {
      dispatch({ type: SELECTED_SENTENCE.TEST_CASES.DELETE_TAGGER.REQUEST })
      try {
        const response = await Api.deleteTaggerTestSetByIds({ ids: [caseId] })
        if (response && response.data) {
          const {
            hashedIndex_tagger_test_sets: hashedList,
            tagger_test_sets,
          } = getState().data.selectedSentence.testCases

          const newTaggerTestSets = cloneDeep(tagger_test_sets)
          newTaggerTestSets.splice(hashedList[caseId], 1)

          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.DELETE_TAGGER.SUCCESS,
            payload: {
              type: 'tagger',
              tagger_test_sets: newTaggerTestSets,
              hashedIndex_tagger_test_sets: reHashIndex(newTaggerTestSets),
            },
          })
        }
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.DELETE_TAGGER.FAILED,
        })
        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.DELETE_TAGGER.FAILED,
          error,
        })

        throw error
      }
    }
  },
  deleteExtractorTestCase: (caseId) => {
    return async (dispatch, getState) => {
      dispatch({ type: SELECTED_SENTENCE.TEST_CASES.DELETE_EXTRACTOR.REQUEST })
      try {
        const response = await Api.deleteExtractorTestSetByIds({ ids: [caseId] })
        if (response && response.data) {
          const {
            hashedIndex_extractor_test_sets: hashedList,
            extractor_test_sets,
          } = getState().data.selectedSentence.testCases

          const newExtractorTestSets = cloneDeep(extractor_test_sets)
          newExtractorTestSets.splice(hashedList[caseId], 1)

          return dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.DELETE_EXTRACTOR.SUCCESS,
            payload: {
              type: 'extractor',
              extractor_test_sets: newExtractorTestSets,
              hashedIndex_extractor_test_sets: reHashIndex(newExtractorTestSets),
            },
          })
        }
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.DELETE_EXTRACTOR.FAILED,
        })
        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.DELETE_EXTRACTOR.FAILED,
          error,
        })
        throw error
      }
    }
  },
})
