import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { buildDomainIntentFetchPayload } from '@core/helpers'
import { INTENT_TREE } from '@core/store/constants'
import CoreApi from '@core/api'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  add: ({ name, domain_id }) => {
    return async (dispatch) => {
      dispatch({ type: INTENT_TREE.INTENT.ADD.REQUEST })
      try {
        const response = await Api.addNewIntent({ name, domain_id })

        if (response && response.status === 'success') {
          const updatedDomainsIntents = await CoreApi.getDomainsIntents()
          return dispatch({
            type: INTENT_TREE.INTENT.ADD.SUCCESS,
            payload: buildDomainIntentFetchPayload(updatedDomainsIntents.data),
          })
        }

        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({ type: INTENT_TREE.INTENT.ADD.FAILED })

        throw error
      }
    }
  },
  rename: (intent_id, { name, domain_id }) => {
    return async (dispatch) => {
      dispatch({ type: INTENT_TREE.INTENT.RENAME.REQUEST })
      try {
        const response = await Api.renameIntent(intent_id, { name, domain_id })

        if (response && response.status === 'success') {
          const updatedDomainsIntents = await CoreApi.getDomainsIntents()
          return dispatch({
            type: INTENT_TREE.INTENT.RENAME.SUCCESS,
            payload: buildDomainIntentFetchPayload(updatedDomainsIntents.data),
          })
        }

        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({ type: INTENT_TREE.INTENT.RENAME.FAILED })

        throw error
      }
    }
  },
  // literally same endpoint as rename but just send a domain_id in the body, done
  reassign: (intent_id, { domain_id }) => {
    return async (dispatch) => {
      dispatch({ type: INTENT_TREE.INTENT.REASSIGN.REQUEST })
      try {
        const response = await Api.renameIntent(intent_id, { domain_id })

        if (response && response.status === 'success') {
          const updatedDomainsIntents = await CoreApi.getDomainsIntents()
          return dispatch({
            type: INTENT_TREE.INTENT.REASSIGN.SUCCESS,
            payload: buildDomainIntentFetchPayload(updatedDomainsIntents.data),
          })
        }

        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({ type: INTENT_TREE.INTENT.REASSIGN.FAILED })

        throw error
      }
    }
  },
  delete: (ids) => {
    return async (dispatch) => {
      dispatch({ type: INTENT_TREE.INTENT.DELETE.REQUEST })
      try {
        const response = await Api.deleteIntent({ ids })

        if (response && response.status === 'success') {
          const updatedDomainsIntents = await CoreApi.getDomainsIntents()
          return dispatch({
            type: INTENT_TREE.INTENT.DELETE.SUCCESS,
            payload: buildDomainIntentFetchPayload(updatedDomainsIntents.data),
          })
        }

        throw new Error('Response Incompatible')
      } catch (error) {
        dispatch({ type: INTENT_TREE.INTENT.DELETE.FAILED })

        throw error
      }
    }
  },
})
