import React, { useState, useContext, useEffect } from 'react'
import {
  Row,
  Col,
  Result,
  Divider,
} from 'antd'
import PropTypes from 'prop-types'
import useTimeoutAlert from '@core/hooks/useTimeoutAlert'
import Api from '../../../../api'
import EmailPreview from '../../EmailPreview'
import { EmailAutomationsContext } from '../../../../context'
import { EmailItemsUpdaterContext } from '../context'

import PredictedIntentClassifications from './PredictedIntentClassifications'
import AnalysisStatus from './AnalysisStatus'
import TrainingCases from './TrainingCases'

const IntentTraining = ({ selectedEmail, setIsFormDirty, activeKey }) => {
  const { updateSpecificEmailStatus, updateSpecificEmailIntentSamples } = useContext(EmailItemsUpdaterContext)
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [statusIsLoading, setStatusIsLoading] = useState(false)
  const [emailStatus, setEmailStatus] = useState(selectedEmail.status)
  const [showStatusUpdateAlert, setShowStatusUpdateAlert] = useTimeoutAlert(null)

  // when email gets tagged, its status changed to 'IN_PROGRESS' regardless of the status
  // we need to get status from server we can do it on front-end but not a good idea actually...

  // the ideal is
  // - front-end make a request, received updated rows from response
  // - front-end 'updates' data (tables) to make sure it shows the latest updated one

  // for the deletion
  // reading from hermes code it seems like it was keeping the status like that
  // because in order to delete, the status of that email can't be "NEW" anyway
  // but unlike insert, insert changes the status to 'IN_PROGRESS' anyway

  const locallyUpdateAssignedIntents = (updatedIntents) => {
    updateSpecificEmailIntentSamples(selectedEmail.id, updatedIntents)
  }

  const updateStatus = async (newStatus) => {
    setStatusIsLoading(true)

    try {
      const response = await Api.Emails.upsert({
        id: selectedEmail.id,
        status: newStatus,
        datasource,
        version: currentVersion,
      })

      if (response?.status === 'success') {
        updateSpecificEmailStatus(selectedEmail.id, newStatus)
        setEmailStatus(newStatus)
      }
    } catch (error) {
      console.error('IntentTraining#updateStatus - error - ', error)
      setShowStatusUpdateAlert({
        message: error.message,
        description: `${error.status} ${error.data?.status}`,
      })
    }

    setStatusIsLoading(false)
  }

  useEffect(() => {
    setEmailStatus(selectedEmail.status)
  }, [selectedEmail.status])

  return (
    <Row gutter={16}>
      <Col span={14}>
        <EmailPreview email={selectedEmail} />
      </Col>
      <Col span={10}>
        {
          !selectedEmail
            ? (
              <Result
                status='warning'
                title='Email items malformed or not a correct data type'
              />
            )
            : (
              <>
                <PredictedIntentClassifications emailBody={selectedEmail.body} />
                <Divider />
                <AnalysisStatus
                  emailStatus={emailStatus}
                  updateStatus={updateStatus}
                  statusIsLoading={statusIsLoading}
                  showStatusUpdateAlert={showStatusUpdateAlert}
                />
                <Divider />
                <TrainingCases
                  selectedEmailId={selectedEmail.id}
                  locallyUpdateAssignedIntents={locallyUpdateAssignedIntents}
                  setIsFormDirty={setIsFormDirty}
                  activeKey={activeKey}
                />
              </>
            )
        }
      </Col>
    </Row>
  )
}

IntentTraining.defaultProps = {
  selectedEmail: null,
  setIsFormDirty: () => { },
  activeKey: 'parentTabKey',
}

IntentTraining.propTypes = {
  selectedEmail: PropTypes.shape({
    id: PropTypes.string,
    from_address: PropTypes.string,
    to_address: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    source: PropTypes.string,
    language: PropTypes.string,
    email_sent_at: PropTypes.string,
    record_created_at: PropTypes.string,
    status: PropTypes.string,
    feedback: PropTypes.string,
  }),
  setIsFormDirty: PropTypes.func,
  activeKey: PropTypes.string,
}

export default IntentTraining
