import callApi from '@core/utils/http-client'

export default {
  getDatasetInfo: () => {
    return callApi({
      url: '/api/v1/experiment/dataset/info',
      method: 'get',
    })
  },
  getDataset: ({ domain, intent }) => {
    return callApi({
      url: '/api/v1/experiment/dataset',
      method: 'get',
      params: {
        domain,
        intent,
      },
    })
  },
  getDatasetDownload: () => {
    return callApi({
      url: '/api/v1/experiment/dataset/download',
      method: 'get',
    })
  },
  trainDomains: ({
    epochs,
    windowSize,
    patternWindowSize,
    trainingDataId,
    selectedKeys,
    features,
  }) => {
    return callApi({
      url: '/api/v1/recognition/domains',
      method: 'post',
      data: {
        epochs,
        windowSize,
        patternWindowSize,
        trainingDataId,
        selectedKeys,
        features,
      },
    })
  },
  trainIntents: ({
    epochs,
    windowSize,
    patternWindowSize,
    trainingDataId,
    selectedKeys,
    features,
  }) => {
    return callApi({
      url: '/api/v1/recognition/intents',
      method: 'post',
      data: {
        epochs,
        windowSize,
        patternWindowSize,
        trainingDataId,
        selectedKeys,
        features,
      },
    })
  },
  test: ({ type }) => {
    return callApi({
      url: '/api/v1/recognition/test',
      method: 'post',
      data: {
        type,
      },
    })
  },
  getModels: ({ type }) => {
    return callApi({
      url: '/api/v1/experiment/models',
      method: 'get',
      params: {
        type,
      },
    })
  },
  updateModel: (id, { name }) => {
    return callApi({
      url: `/api/v1/experiment/models/${id}`,
      method: 'put',
      data: {
        name,
      },
    })
  },
  publishModel: ({ id, type }) => {
    return callApi({
      url: `/api/v1/experiment/models/${id}/publish`,
      method: 'post',
      data: {
        type,
      },
    })
  },
  generalizeSentences: ({ resume }) => {
    return callApi({
      url: '/api/v1/sentences/generalize',
      method: 'post',
      params: {
        resume,
      },
    })
  },
  trainTaggers: ({ taggers }) => {
    return callApi({
      url: '/api/v1/recognition/taggers',
      method: 'post',
      data: {
        taggers,
      },
    })
  },
  analyzeTaggers: ({ taggers }) => {
    return callApi({
      url: '/api/v1/analysis/taggers',
      method: 'post',
      data: {
        taggers,
      },
    })
  },
  displayTaggerTrainings: ({ taggers }) => {
    return callApi({
      url: '/api/v1/taggers/samples/list',
      method: 'post',
      data: {
        taggers,
      },
    })
  },
}
