import { useLocation } from 'react-router-dom'

// NOTE: To use URLSearchParams
// if you're expecting it to return object of query params, no
// it's an instance with getter.
// So you'll need to use like this
// const someVariableYouWantToStore = useQueryParams().get('__enter_your_params__')
// if there is no query params, it will return null

const useQueryParams = () => { return new URLSearchParams(useLocation().search) }

export default useQueryParams
