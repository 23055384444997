import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Button,
  Divider,
  Form,
  Radio,
} from 'antd'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import style from './index.module.scss'

const { Sider } = Layout

const types = [
  // { value: 'domain', text: 'Domain' },
  { value: 'intent', text: 'Intent' },
  // { value: 'tagger', text: 'Tagger' },
  // { value: 'data', text: 'Data' },
]

const LeftSider = ({ onSubmit }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values) => {
    onSubmit(values)
  }

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Evaluation</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={{
            type: 'intent',
          }}
        >
          <Form.Item
            style={{ marginBottom: 10 }}
            name='type'
          >
            <Radio.Group size='small'>
              {types.map(({ value, text }, index) => { return <Radio.Button key={index} value={value}>{text}</Radio.Button> })}
            </Radio.Group>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
          >
            Test (Run)
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LeftSider
