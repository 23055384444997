import { useSelector, shallowEqual } from 'react-redux'

export const usePermittedEmailAutomationFeatures = () => {
  const permittedFeatures = useSelector(({ core }) => { return (core.permittedFeatures.authorization) }, shallowEqual)

  const { transformed } = permittedFeatures

  return {
    email: transformed.email,
    emailautomations: transformed.emailautomations,
  }
}
