import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'antd'
import { FormDirtyContext } from '../../../context'

import RadioGroupOptions from './RadioGroupOptions'

import {
  emailContentOptionsArray,
  emailStyleOptionsArray,
  binaryOptionsArray,
  binaryCorrectnessOptions,
  correctnessOptionsWithNotAvailable,
  correctnessOptionsWithNotAvailableDisabled,
} from './accuracyOptions'

import style from './style.module.scss'

const InitialAccuracyCheckForm = ({ onFinish }) => {
  const { isFormDirty, setIsFormDirty } = useContext(FormDirtyContext)
  const [isNotNormalEmail, setIsNotNormalEmail] = useState(false)
  const [shouldDisableSpecificIntentAndDetails, setShouldDisableSpecificIntentAndDetails] = useState(false)
  const [shouldDisableDetail, setShouldDisableDetail] = useState(false)
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      className={style.Form}
      size='small'
      labelAlign='left'
      colon={false}
      onFinish={onFinish}
      onValuesChange={(values) => {
        if (!isFormDirty) setIsFormDirty(true)

        if (values.intent_correctness === 'PARTIAL' || values.intent_correctness === 'INCORRECT') {
          form.setFieldsValue({
            specific_intent_correctness: 'N/A',
            details_correctness: 'N/A',
          })

          setShouldDisableSpecificIntentAndDetails(true)
        }

        if (values.intent_correctness === 'CORRECT') {
          setShouldDisableSpecificIntentAndDetails(false)
        }

        if (values.specific_intent_correctness === 'INCORRECT') {
          form.setFieldsValue({ details_correctness: 'N/A' })
          setShouldDisableDetail(true)
        }

        if (values.specific_intent_correctness && values.specific_intent_correctness !== 'INCORRECT') {
          setShouldDisableDetail(false)
        }

        if (values.email_content && values.email_content !== 'NORMAL') {
          setIsNotNormalEmail(true)

          form.setFieldsValue({
            email_style: 'N/A',
            arranger_email: 'N/A',
            attachment: 'N/A',
            user_error: 'N/A',
            preprocessing_correctness: 'N/A',
            intent_correctness: 'N/A',
            specific_intent_correctness: 'N/A',
            details_correctness: 'N/A',
          })
        }

        if (values.email_content && values.email_content === 'NORMAL') {
          setIsNotNormalEmail(false)

          form.setFieldsValue({
            email_style: null,
            arranger_email: null,
            attachment: null,
            user_error: null,
            preprocessing_correctness: null,
            intent_correctness: null,
            specific_intent_correctness: null,
            details_correctness: null,
          })
        }
      }}
    >
      <RadioGroupOptions
        label='Email Content'
        name='email_content'
        validationMessage='Email Content is required'
        options={emailContentOptionsArray}
        disabled={false}
      />
      <RadioGroupOptions
        label='Email Style'
        name='email_style'
        validationMessage='Email style is required'
        options={emailStyleOptionsArray}
        disabled={isNotNormalEmail}
      />
      <RadioGroupOptions
        label='Request for someone else'
        name='arranger_email'
        validationMessage='Request for someone else is required'
        options={binaryOptionsArray}
        disabled={isNotNormalEmail}
      />
      <RadioGroupOptions
        label='Contains attachment'
        name='attachment'
        validationMessage='Contains attachment is required'
        options={binaryOptionsArray}
        disabled={isNotNormalEmail}
      />
      <RadioGroupOptions
        label='User Error'
        name='user_error'
        validationMessage='User error is required'
        options={binaryOptionsArray}
        disabled={isNotNormalEmail}
      />
      <RadioGroupOptions
        label='Pre-process'
        name='preprocessing_correctness'
        validationMessage='Pre-process is required'
        options={binaryCorrectnessOptions}
        disabled={isNotNormalEmail}
      />
      <RadioGroupOptions
        label='Intent'
        name='intent_correctness'
        validationMessage='Intent is required'
        options={correctnessOptionsWithNotAvailableDisabled}
        disabled={isNotNormalEmail}
      />
      <RadioGroupOptions
        label='Specific Intent'
        name='specific_intent_correctness'
        validationMessage='Specific Intent is required'
        options={correctnessOptionsWithNotAvailable}
        disabled={isNotNormalEmail || shouldDisableSpecificIntentAndDetails}
      />
      <RadioGroupOptions
        label='Details'
        name='details_correctness'
        validationMessage='Details is required'
        options={correctnessOptionsWithNotAvailable}
        disabled={isNotNormalEmail || shouldDisableSpecificIntentAndDetails || shouldDisableDetail}
      />
      <Form.Item className={style.FormActions}>
        <Button type='primary' htmlType='submit'>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

InitialAccuracyCheckForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
}

const ExistingAccuracyCheckForm = ({ initialValues }) => {
  return (
    <Form
      className={style.Form}
      size='small'
      labelAlign='left'
      colon={false}
      initialValues={initialValues}
    >
      <RadioGroupOptions
        label='Email Content'
        name='email_content'
        validationMessage='Email Content is required'
        options={emailContentOptionsArray}
        disabled
      />
      <RadioGroupOptions
        label='Email Style'
        name='email_style'
        validationMessage='Email style is required'
        options={emailStyleOptionsArray}
        disabled
      />
      <RadioGroupOptions
        label='Request for someone else'
        name='arranger_email'
        validationMessage='Request for someone else is required'
        options={binaryOptionsArray}
        disabled
      />
      <RadioGroupOptions
        label='Contains attachment'
        name='attachment'
        validationMessage='Contains attachment is required'
        options={binaryOptionsArray}
        disabled
      />
      <RadioGroupOptions
        label='User Error'
        name='user_error'
        validationMessage='User error is required'
        options={binaryOptionsArray}
        disabled
      />
      <RadioGroupOptions
        label='Pre-process'
        name='preprocessing_correctness'
        validationMessage='Pre-process is required'
        options={binaryCorrectnessOptions}
        disabled
      />
      <RadioGroupOptions
        label='Intent'
        name='intent_correctness'
        validationMessage='Intent is required'
        options={correctnessOptionsWithNotAvailableDisabled}
        disabled
      />
      <RadioGroupOptions
        label='Specific Intent'
        name='specific_intent_correctness'
        validationMessage='Specific Intent is required'
        options={correctnessOptionsWithNotAvailable}
        disabled
      />
      <RadioGroupOptions
        label='Details'
        name='details_correctness'
        validationMessage='Details is required'
        options={correctnessOptionsWithNotAvailable}
        disabled
      />
    </Form>
  )
}

ExistingAccuracyCheckForm.propTypes = {
  initialValues: PropTypes.shape({
    email_content: PropTypes.string,
    email_style: PropTypes.string,
    arranger_email: PropTypes.string,
    attachment: PropTypes.string,
    user_error: PropTypes.string,
    preprocessing_correctness: PropTypes.string,
    intent_correctness: PropTypes.string,
    specific_intent_correctness: PropTypes.string,
    details_correctness: PropTypes.string,
  }).isRequired,
}

export {
  InitialAccuracyCheckForm,
  ExistingAccuracyCheckForm,
}
