import { RIGHT_PANEL, SENTENCE } from '../constants'

const INITIAL_STATE = {
  type: null,
  enabled: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SENTENCE.SELECTED:
      return {
        ...state, type: action.payload.rightPanelType, enabled: true,
      }
    case RIGHT_PANEL.CLOSE:
      return {
        ...state, type: '', enabled: false,
      }
    default:
      return state
  }
}
