import React from 'react'
import { Button, Modal } from 'antd'
import { shallowEqual, useSelector } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons'
// FIXME: f
import ReactReduxProvider from '../../../../../../ReactReduxProvider'
import store from '../../../../../../setup/store'

import { clearTableSelectionType } from '../../../../types/sentencesQueryResultsTitle'
import { useSelectedSentences } from '../../../../hooks/useSelectedSentences'
import BulkOperationModalActions from '../../../../store/actions/BulkOperationModal'
import SentencesActionCreators from '../../../../store/actions/sentences'

import style from './style.module.scss'

const SelectedSentenceContent = () => {
  const selectedSentences = useSelectedSentences()

  return (
    <ul>
      {selectedSentences.map((sentence, index) => { return (<li key={index}>{sentence.text}</li>) })}
    </ul>
  )
}

const BulkActions = ({ clearTableSelection }) => {
  const showDeleteConfirm = (event) => {
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation()
    }

    // Modal.confirm does not have Redux context, it's like it's out of the App tree
    Modal.confirm({
      title: 'Are you sure you want to delete these sentence(s)',
      icon: <ExclamationCircleOutlined />,
      // f
      content: (
        <ReactReduxProvider store={store}>
          <SelectedSentenceContent />
        </ReactReduxProvider>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        await SentencesActionCreators.delete()
        clearTableSelection()
      },
      onCancel: () => {},
    })
  }

  const totalSelectedSentences = useSelector(({ data }) => {
    return data.bulkOperationData.selectedSentences.length
  }, shallowEqual)

  const openBulkOperationModal = (type) => {
    return (event) => {
      event.stopPropagation()
      BulkOperationModalActions.open(type)
    }
  }

  return (
    <div className={style.BulkActions}>
      <Button
        type='primary'
        onClick={openBulkOperationModal('test')}
        disabled={!totalSelectedSentences}
      >
        Apply to Domain/Intent Test case
      </Button>
      <Button
        type='primary'
        onClick={openBulkOperationModal('training')}
        disabled={!totalSelectedSentences}
      >
        Apply to Domain/Intent Training case
      </Button>
      <Button
        type='default'
        onClick={openBulkOperationModal('unrecognized')}
        disabled={!totalSelectedSentences}
      >
        Mark as Recognized
      </Button>
      <Button
        danger
        onClick={showDeleteConfirm}
        disabled={!totalSelectedSentences}
      >
        Delete sentence(s)
      </Button>
    </div>
  )
}

BulkActions.propTypes = {
  clearTableSelection: clearTableSelectionType.isRequired,
}

export default BulkActions
