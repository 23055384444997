import React from 'react'
import { Form, Checkbox, Divider } from 'antd'
import IntentTreeInput from '@core/components/IntentTreeInput'
import { onChangeType } from '../../../types/testCasesFilter'

const TestCasesFilter = ({
  onExpectNullSelect,
  onNoIntentDomainSelect,
  setIntentValue,
}) => {
  return (
    <>
      <Form.Item name='test_domain_intents'>
        <IntentTreeInput
          setIntent={setIntentValue}
          placeholder='Specify domain/intent'
        />
      </Form.Item>
      <Form.Item
        valuePropName='checked'
        name='no_domain_test_set'
        onChange={onNoIntentDomainSelect}
      >
        <Checkbox>Does not have a domain case</Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName='checked'
        name='no_intent_test_set'
        onChange={onNoIntentDomainSelect}
      >
        <Checkbox>Does not have an intent case</Checkbox>
      </Form.Item>
      <Divider plain>OR</Divider>
      <Form.Item
        valuePropName='checked'
        name='test_expected_null'
        onChange={onExpectNullSelect}
      >
        <Checkbox>Expect NULL result</Checkbox>
      </Form.Item>
    </>
  )
}

TestCasesFilter.propTypes = {
  onExpectNullSelect: onChangeType.isRequired,
  onNoIntentDomainSelect: onChangeType.isRequired,
  setIntentValue: onChangeType.isRequired,
}

export default TestCasesFilter
