/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import style from './index.module.scss'
import StepIcon from './StepIcon'
import AuthenticateTab from './AuthenticateTab'
import ConversationTab from './ConversationTab'
import CSATTab from './CSATTab'
import PTCTab from './PTCTab'
import { UserPropTypes } from '../../../UserPropTypes'

const StepPanel = ({ user }) => {
  const [selectedStep, setSelectedStep] = useState(undefined)

  let StepTab = <div>Please select a step to see information.</div>
  if (selectedStep === 'AUTHENTICATE') {
    StepTab = <AuthenticateTab user={user} />
  }
  if (selectedStep === 'CONVERSATION') {
    StepTab = <ConversationTab onConversation={user.onConversation} channel={user.fromChannel} />
  }
  if (selectedStep === 'CSAT') {
    StepTab = (
      <CSATTab
        onCSAT={user.onCSAT}
        conversationId={user.onConversation?.data?.conversationId}
        channel={user.fromChannel}
        channelUserId={user.channelUserId}
      />
    )
  }
  if (selectedStep === 'PTC') {
    StepTab = <PTCTab onPTC={user.onPTC} conversationId={user.onConversation?.data?.conversationId} />
  }

  const pendingSteps = []
  if (user.onAuthentication?.status) {
    pendingSteps.push('AUTHENTICATE')
  }
  if (user.onConversation?.status) {
    pendingSteps.push('CONVERSATION')
  }
  if (user.onCSAT?.status) {
    pendingSteps.push('CSAT')
  }
  if (user.onPTC?.status) {
    pendingSteps.push('PTC')
  }

  const registerStep = (step) => {
    return ({
      selected: selectedStep === step,
      onClick: () => { setSelectedStep(selectedStep === step ? undefined : step) },
      state: pendingSteps.includes(step) ? 'PENDING' : 'PASSED',
    })
  }

  return (
    <div style={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '36px' }}>
        <StepIcon title='Authenticate' {...registerStep('AUTHENTICATE')} />
        <div className={style.spaceX} />
        <StepIcon title='Conversation' {...registerStep('CONVERSATION')} />
        <div className={style.spaceLineX} />
        <StepIcon title='CSAT' {...registerStep('CSAT')} />
        <div className={style.spaceX} />
        <StepIcon title='PTC' {...registerStep('PTC')} />
      </div>
      <div style={{ fontWeight: 'bold' }}>Information:</div>
      {StepTab}
    </div>
  )
}

StepPanel.propTypes = {
  user: UserPropTypes.isRequired,
}

export default StepPanel
