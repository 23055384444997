import React, { useState, useContext, useEffect } from 'react'
import { Button, Space } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { EmailAutomationsContext } from '../../context'
import ViewEditModal from './ViewEditModal'
import List from './List'
import Api from '../../api'

const Versions = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [viewingVersion, setViewingVersion] = useState(null)
  const { versions, refreshVersions, datasource } = useContext(EmailAutomationsContext)
  const {
    data,
    loading,
    hasError,
    currentVersion,
  } = versions

  const onUpdated = async () => {
    setModalVisible(false)
    setViewingVersion(null)
    await refreshVersions()
  }

  const onDismissed = () => {
    setModalVisible(false)
    setViewingVersion(null)
  }

  const viewSelectedVersion = (version) => {
    setViewingVersion(version)
    setModalVisible(true)
  }

  const deployToEnvironmentFn = async (id, status) => {
    const response = await Api.Versions.deployVersion({ id, datasource, status })
    await refreshVersions()
    return response
  }

  const onCloneFinished = async () => {
    await refreshVersions()
  }

  useEffect(() => {
    if (!modalVisible) setViewingVersion(null)
  }, [modalVisible])

  return (
    <>
      <Space direction='vertical'>
        <Button
          type='default'
          onClick={async () => { await refreshVersions() }}
          disabled={loading}
          icon={<SyncOutlined spin={loading} />}
        >
          Refresh
        </Button>
        <List
          versions={data}
          loading={loading}
          hasError={hasError}
          currentVersion={currentVersion}
          setViewingVersion={viewSelectedVersion}
          onCloneFinished={onCloneFinished}
        />
      </Space>
      <ViewEditModal
        version={viewingVersion}
        visible={modalVisible}
        onUpdated={onUpdated}
        onDismissed={onDismissed}
        deployToEnvironmentFn={deployToEnvironmentFn}
      />
    </>
  )
}

export default Versions
