import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Typography,
  Table,
  Spin,
  Button,
  Tooltip,
  Space,
  Divider,
  Popconfirm,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import JsonPreview from '@core/components/JsonPreview'
import { splitNewLineFieldWithDelimiter, splitDegradedResponsesWithDelimiter, getLanguageCode } from '../../../helpers'
import DeleteConfirmationModal from '../DeleteConfirmationModal'

const ResponseTextList = ({
  loading,
  data,
  hasError,
  openAddSentenceModal,
  openEditSentenceModal,
  fetchOnTableChange,
  onSelectSentence,
  onDeleteResponseText,
  deleteResponseText,
}) => {
  const source = data?.items || []
  const meta = data?.meta || {}
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [selectedResponseText, setSelectedResponseText] = useState({})
  const closeDeleteResponseTextModal = () => {
    setDeleteModalVisible(false)
  }

  const confirmDelete = (savedData) => {
    return () => {
      setSelectedResponseText(savedData)
      setDeleteModalVisible(true)
    }
  }

  const onDelete = (responseText) => { return onDeleteResponseText(responseText) }

  const onTableChange = (pagination, filters, sorter) => {
    fetchOnTableChange(pagination, filters, sorter)
  }

  const columns = [
    // NOTE: Need to use 'rowKey'
    // otherwise: point this field to 'speechKey'
    // { title: 'Key', dataIndex: 'speechKey' }
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: '15%',
      render: (key) => { return <Tooltip placement='topLeft' title={key}>{key}</Tooltip> },
      sorter: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => {
        return (
          splitDegradedResponsesWithDelimiter(value).map((variation, index, variations) => {
            return (
              <React.Fragment key={index}>
                <ul>
                  {splitNewLineFieldWithDelimiter(variation).map((message, messageIndex) => {
                    return (
                      <li key={messageIndex}>{message}</li>
                    )
                  })}
                </ul>
                {(index !== (variations.length - 1)) && <Divider />}
              </React.Fragment>
            )
          })
        )
      },
    },
    {
      title: 'Language',
      dataIndex: 'lang_code',
      key: 'lang_code',
      width: '10%',
      render: (langCode) => { return <Typography.Text>{getLanguageCode(langCode)}</Typography.Text> },
      // sorter: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (sentence) => {
        return (
          <Space className='actions' wrap>
            <Button
              type='link'
              onClick={() => {
                openEditSentenceModal()
                onSelectSentence(sentence)
              }}
            >
              Edit
            </Button>
            <Popconfirm
              placement='topRight'
              icon={<ExclamationCircleOutlined />}
              title='Confirm delete response text?'
              onConfirm={confirmDelete(sentence)}
              okText='Yes'
              okType='danger'
              cancelText='No'
            >
              <Button
                type='link'
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        )
      }
      ,
    },
  ]

  return (
    <>
      <Row gutter={[24, 16]}>
        {hasError && <Col span={24}><Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text></Col>}
        {data && (
          <Col span={24}>
            <Spin size='large' spinning={loading}>
              <Table
                size='small'
                onChange={onTableChange}
                columns={columns}
                dataSource={source.map((record/* , index, array */) => {
                  return {
                  // NOTE: In case we don't want to use rowKey
                  // add a new key called speechKey
                  // and then in the column, dataIndex use the speechKey
                    ...record,
                    keyPath: record.key,
                  }
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div><JsonPreview content={JSON.stringify(record, null, 2)} /></div>
                    )
                  },
                }}
                rowKey={(record) => { return (record.id) }}
                title={() => {
                  return (
                    <Button
                      type='primary'
                      onClick={openAddSentenceModal}
                    >
                      Add Connector Text
                    </Button>
                  )
                }}
                pagination={{
                  showSizeChanger: false, // this cause bugs when it changed without syncing with meta.per_page
                  showTotal: (total, range) => { return `${range[0]}-${range[1]} of ${total} items` },
                  current: meta.page,
                  defaultPageSize: meta.per_page,
                  total: meta.total_count,
                }}
              />
            </Spin>
          </Col>
        )}
      </Row>
      <DeleteConfirmationModal
        loading={deleteResponseText.loading}
        hasError={deleteResponseText.hasError}
        visible={deleteModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
        onDelete={onDelete}
        onClose={closeDeleteResponseTextModal}
        selectedResponseText={selectedResponseText}
      />
    </>
  )
}

ResponseTextList.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.objectOf(PropTypes.number),
  }).isRequired,
  hasError: PropTypes.bool.isRequired,
  openAddSentenceModal: PropTypes.func.isRequired,
  openEditSentenceModal: PropTypes.func.isRequired,
  onSelectSentence: PropTypes.func.isRequired,
  fetchOnTableChange: PropTypes.func.isRequired,
  onDeleteResponseText: PropTypes.func.isRequired,
  deleteResponseText: PropTypes.shape({
    loading: PropTypes.bool,
    hasError: PropTypes.bool,
  }).isRequired,
}

export default ResponseTextList
