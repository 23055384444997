import React from 'react'
import { Form, Input, Divider } from 'antd'
import PropTypes from 'prop-types'

const ByTerms = ({ children }) => {
  return (
    <>
      <Divider>
        <small><strong>By Texts/Regexes</strong></small>
      </Divider>
      {children}
      <Form.Item name='text' label='Including Texts'>
        <Input placeholder='Use (,) for multiple text' />
      </Form.Item>
      <Form.Item name='excludeText' label='Excluding Text'>
        <Input placeholder='Use (,) for multiple text' />
      </Form.Item>
    </>
  )
}
ByTerms.defaultProps = {
  children: null,
}

ByTerms.propTypes = {
  children: PropTypes.element,
}
export default ByTerms
