import React, { useState, useContext } from 'react'
import { Table } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import useRequest from '@core/hooks/useRequest'
import { sortNumberOrNull } from '@core/helpers'
import style from './style.module.scss'
import Api from '../../api'
import { EmailAutomationsContext } from '../../context'

const countText = (number) => {
  return <span className={style.textCount}>{number}</span>
}

const nestedTable = ({ desiredValues }) => {
  const nestedColums = [{
    title: 'Desired Value',
    dataIndex: 'desiredLabel',
    key: 'desiredLabel',

  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    width: 500,
    render: countText,
  }]
  return (
    <Table
      columns={nestedColums}
      dataSource={desiredValues?.sort((a, b) => { return sortNumberOrNull(b.count, a.count) })}
      pagination={false}
      size='small'
      bordered
    />
  )
}

const DataExtraction = () => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [{ loading, data }] = useRequest(Api.DataExtractions.getCountOfDataExtractionSamples, { version: currentVersion, datasource })
  const [expandKeys, setExpandKeys] = useState([])
  const columns = [
    {
      title: 'Tagger',
      dataIndex: 'tagger',
      key: 'tagger',
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      width: 400,
      render: countText,
    },
    {
      width: 100,
      title: 'Action',
      render: (row) => { return expandKeys.includes(row.tagger) ? <UpOutlined /> : <DownOutlined /> },
    },
  ]

  return (
    <Table
      className={style.DataExtractionTable}
      loading={loading}
      columns={columns}
      rowKey='tagger'
      expandable={{
        columnWidth: 0,
        onExpandedRowsChange: (expandedRowKeys) => {
          setExpandKeys(expandedRowKeys)
        },
        expandIcon: () => { return null },
        expandedRowRender: nestedTable,
        expandRowByClick: true,
      }}
      pagination={false}
      size='middle'
      dataSource={data}
    />
  )
}

export default DataExtraction
