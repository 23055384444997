import React from 'react'
import {
  Form,
  Typography,
  Select,
  Input,
} from 'antd'
import { domainListType, intentListType } from '@core/types/domainIntentCollectionTypes'
import {
  testDomainIdType,
  testIntentIdType,
  noteType,
  onDomainChangeType,
} from '../../../types/updateCaseModal'
import { formInstanceType } from '../../../types/form'
import { generalizedType } from '../../../types/generalized'

const { Title } = Typography

const IntentForm = ({
  form,
  test_domain_id,
  test_intent_id,
  note,

  domainList,
  intentList,
  onDomainChange,
  generalized,
}) => {
  return (
    <Form
      form={form}
      layout='vertical'
      name='form__update_test_intent'
      initialValues={{
        test_domain_id,
        test_intent_id: test_intent_id || -1,
        note,
      }}
    >
      <Title level={4}>{`Modify Intent test case for: ${generalized.original}`}</Title>
      <Form.Item
        name='test_domain_id'
        label='Test Domain'
        rules={[
          {
            required: true,
            message: 'Please selected test domain',
          },
        ]}
      >
        <Select
          placeholder='Select a test domain'
          onChange={onDomainChange}
          allowClear
        >
          {domainList.map((domain, index) => {
            return (
              <Select.Option value={domain.id} key={index}>{domain.name}</Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name='test_intent_id'
        label='Test Intent'
        rules={[
          {
            required: true,
            message: 'Please selected test intent',
          },
        ]}
      >
        <Select
          placeholder='Select a test intent'
          allowClear
        >
          <Select.Option value={-1} key={domainList.length}>NULL</Select.Option>
          <Select.OptGroup label='Intents'>
            {intentList.map((intent, index) => {
              return (
                <Select.Option value={intent.intentId} key={index}>{intent.label}</Select.Option>
              )
            })}
          </Select.OptGroup>
        </Select>
      </Form.Item>
      <Form.Item
        name='note'
        label='Note'
      >
        <Input.TextArea
          showCount
          maxLength={255}
          style={{ resize: 'vertical' }}
        />
      </Form.Item>
    </Form>
  )
}

IntentForm.propTypes = {
  form: formInstanceType.isRequired,
  test_domain_id: testDomainIdType.isRequired,
  test_intent_id: testIntentIdType.isRequired,
  note: noteType.isRequired,
  domainList: domainListType.isRequired,
  intentList: intentListType.isRequired,
  onDomainChange: onDomainChangeType.isRequired,
  generalized: generalizedType.isRequired,
}

export default IntentForm
