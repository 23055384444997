import React from 'react'
import { Cascader, Form } from 'antd'
import { rulesType } from '@core/types/formItems'
import { intentCascaderType } from '@core/types/domainIntentCollectionTypes'

const ExtractorEvaluationIntent = ({ rules, intentCascader }) => {
  return (
    <Form.Item name='test_intent_id' label='Evaluating with this intent' rules={rules}>
      <Cascader
        options={intentCascader}
        placeholder='Select Test Domain/Intent'
      />
    </Form.Item>
  )
}

ExtractorEvaluationIntent.propTypes = {
  rules: rulesType,
  intentCascader: intentCascaderType,
}

ExtractorEvaluationIntent.defaultProps = {
  rules: [],
  intentCascader: null,
}

export default ExtractorEvaluationIntent
