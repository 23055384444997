import React from 'react'
import PropTypes from 'prop-types'
import { Result, Typography, Collapse, Alert } from 'antd'
import SpinnerFillWidth from '@core/components/SpinnerFillWidth'

import style from './style.module.scss'

const RuleBasedPreprocessed = ({ loading, data, hasError }) => {
  if (loading) return <SpinnerFillWidth />
  if (hasError) return <Result title='Something went wrong, please try again' status='error' />

  const { __debug } = data ?? {}

  return (
    <Typography.Paragraph>
      {__debug?.preprocessed ?? 'N/A'}
    </Typography.Paragraph>
  )
}

RuleBasedPreprocessed.defaultProps = {
  data: null,
}

RuleBasedPreprocessed.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasError: PropTypes.bool.isRequired,
}

const NlpPreprocessed = ({ loading, data, hasError }) => {
  if (loading) return <SpinnerFillWidth />
  if (hasError) return <Result title='Something went wrong, please try again' status='error' />

  const { __debug } = data ?? {}

  return (
    <Collapse
      className={style.NlpRulesBasedCollapsible}
      defaultActiveKey={['nlpOutput', 'processedWithRulesBased']}
      bordered={false}
    >
      <Collapse.Panel
        key='nlpOutput'
        header={<Typography.Text strong>NLP Output</Typography.Text>}
      >
        <Typography.Paragraph className={style.PreprocessedResponse}>
          {__debug?.extractiveSummarized ?? 'N/A'}
        </Typography.Paragraph>
      </Collapse.Panel>
      <Collapse.Panel
        key='processedWithRulesBased'
        header={<Typography.Text strong>Final Output (after passing rules-based)</Typography.Text>}
      >
        <Typography.Paragraph className={style.PreprocessedResponse}>
          {__debug?.preprocessed ?? 'N/A'}
        </Typography.Paragraph>
      </Collapse.Panel>
    </Collapse>
  )
}

NlpPreprocessed.defaultProps = {
  data: null,
}

NlpPreprocessed.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasError: PropTypes.bool.isRequired,
}

const CheckPreprocessedStringBetweenMethods = ({ ruleBasedDebug, mlPreprocessedDebug }) => {
  // if nullish, don't render
  // if one of them nullish, don't render
  // if there is no debug field, don't render
  if (!ruleBasedDebug || !mlPreprocessedDebug) return null

  const { preprocessed: ruleBasedDebugPreprocessed } = ruleBasedDebug
  const { preprocessed: mlPreprocessedDebugPreprocessed } = mlPreprocessedDebug

  if (ruleBasedDebugPreprocessed !== mlPreprocessedDebugPreprocessed) return null

  return (
    <Alert
      className={style.IdenticalResultAlert}
      type='warning'
      message='Both "Only Rule-based" and "NLP + Rules-based preprocess results are equal'
      showIcon
    />
  )
}

CheckPreprocessedStringBetweenMethods.defaultProps = {
  ruleBasedDebug: null,
  mlPreprocessedDebug: null,
}

CheckPreprocessedStringBetweenMethods.propTypes = {
  ruleBasedDebug: PropTypes.shape({
    preprocessed: PropTypes.string,
    generalized: PropTypes.string,
  }),
  mlPreprocessedDebug: PropTypes.shape({
    preprocessed: PropTypes.string,
    generalized: PropTypes.string,
  }),
}

export {
  RuleBasedPreprocessed,
  NlpPreprocessed,
  CheckPreprocessedStringBetweenMethods,
}
