import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Modal,
  Button,
  Typography,
  Result,
} from 'antd'
import SentencesActionCreators from '../../../store/actions/sentences'
import {
  modalVisibilityType,
  setModalVisibilityType,
} from '../../../types/addCaseModal'

const { Text } = Typography

const AddNewSentenceModal = ({ modalVisibility, setModalVisibility }) => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasSentRequest, setHasSentRequest] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (modalVisibility === true) {
      setHasSentRequest(false)
      setHasError(false)
    }
  }, [modalVisibility])

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasSentRequest(false)
    setHasError(false)
    form.resetFields()
  }

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    if (hasError) setHasError(false)

    setConfirmLoading(true)
    try {
      let response = null
      const values = await form.validateFields()

      if (values) {
        response = await SentencesActionCreators.addNewSentence(values)
        if (response) {
          form.resetFields()
          setConfirmLoading(false)
          setHasSentRequest(true)
          closeModal()
          // return response
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)
      setConfirmLoading(false)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Modal
      title='Add new Sentence'
      visible={modalVisibility}
      onOk={onSubmit}
      closable={false}
      okText='Submit'
      confirmLoading={confirmLoading}
      maskClosable={false}
      onCancel={closeModal}
      footer={hasSentRequest ? null : [
        <Button key='back' onClick={closeModal}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={confirmLoading} onClick={onSubmit}>
          Submit
        </Button>,
      ]}
    >
      {hasSentRequest && (
        <Result
          title='Your operation has been executed'
          extra={(
            <Button key='back' type='primary' onClick={closeModal}>
              Close
            </Button>
          )}
        />
      )}
      {!hasSentRequest && (
        <Form
          form={form}
          layout='vertical'
          name='form__new_sentence'
        >
          <Form.Item
            name='text'
            rules={[
              {
                required: true,
                message: 'Please enter new sentence',
              },
            ]}
          >
            <Input
              placeholder='Add new sentence'
            />
          </Form.Item>
          {/* <Form.Item
            label='Data'
            name='data'
          >
            <TextArea
              name='data'
              placeholder='Additional data/information'
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item> */}
        </Form>
      )}
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Modal>
  )
}

AddNewSentenceModal.propTypes = {
  modalVisibility: modalVisibilityType.isRequired,
  setModalVisibility: setModalVisibilityType.isRequired,
}

export default AddNewSentenceModal
