import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Space } from 'antd'
import classnames from 'classnames'
import {
  removeEmptyArrayFromObject,
  removeUndefinedOrNullFromObject,
  searchToArrayByComma,
  shallowCleanFalsyObject,
} from '@core/helpers'

import ByLanguage from '@core/components/LanguageFormItems/ByLanguage'

import { toInitialValues, updateFilter, setDateRangeValue } from '../../../helpers/filters'

import CollapseSidebarButton from './CollapseSidebarButton'
import QueryPresetButtons from './QueryManagementButtons'
// import ByOpenSearchIds from './ByOpenSearchIds'
import ByOnlyPreprocessed from './ByOnlyPreprocessed'
import ByTextParams from './ByTextParams'
import ByOriginalSourceEmailId from './ByOriginalSourceEmailId'
import ByTrainAndTestIntents from './ByTrainAndTestIntents'
import ByDateRange from './ByDateRange'
import ByStatuses from './ByStatus'
// import BySource from './BySource' // NOTE: Hide for now, we currently only have SalesForce
import style from './style.module.scss'
import ByFeedback from './ByFeedback'
import ByDataExtractionLabels from './ByDataExtractionLabels'
import ByRegularExpression from './ByRegularExpression'
import ByDatasetType from './ByDatasetType'
import ListPresets from './QueryManagementModals/ListPresets'
import SavePreset from './QueryManagementModals/SavePreset'
import ByAccuracyChecking from './ByAccuracyChecking'
import ByEmailEnvironment from './ByEmailEnvironment'
import AutoFillSearchForEmailAnalysisButton from './AutoFillSearchForEmailAnalysisButton'
import ByCapIdAndEmployment from './ByCapIdAndEmployment'
import ByApprovalStatus from './ByApprovalStatus'
import ByCreditCardMasking from './ByCreditCardMasking'

// Reference: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
const supportedLanguages = ['en'].sort()

const QueryParams = ({ filter, setFilter, activeFilter, setActiveFilter, makeSearch }) => {
  const [form] = Form.useForm()
  // ui state
  const [modalVisible, setModalVisible] = useState(false)
  const [queryModalType, setQueryModalType] = useState('')
  const [collapsed, setCollapsed] = useState(false)
  const setCollapseSidebar = () => { return setCollapsed((prev) => { return !prev }) }
  const closeModal = () => {
    setModalVisible(false)
    setQueryModalType('')
  }

  const [onlyPreprocessedSearch, setOnlyPreprocessedSearch] = useState(true)

  const onFinish = async (values) => {
    let filters = { ...values }
    if (values.ids) filters.ids = searchToArrayByComma(values.ids, { toLowerCase: false })
    if (values.emailIds) filters.emailIds = searchToArrayByComma(values.emailIds, { toLowerCase: false })
    if (values.emailSentAt) filters.emailSentAt = setDateRangeValue(values.emailSentAt)
    if (values.recordCreatedAt) filters.recordCreatedAt = setDateRangeValue(values.recordCreatedAt)

    filters = shallowCleanFalsyObject(filters)
    filters = removeUndefinedOrNullFromObject(filters)
    filters = removeEmptyArrayFromObject(filters)

    makeSearch(filters)
  }

  const onCheckOnlyPreprocessed = (event) => {
    const { target: { checked } } = event
    setOnlyPreprocessedSearch(checked)
    if (!checked) {
      const { predictedIntentNames } = form.getFieldsValue()
      if (predictedIntentNames) form.setFieldsValue({ predictedIntentNames: predictedIntentNames.filter((intent) => { return intent !== 'none' }) })
    }
  }

  useEffect(() => {
    const { page, perPage, ...restOfFilters } = filter

    form.resetFields()
    form.setFieldsValue(updateFilter(restOfFilters))
    setOnlyPreprocessedSearch(Boolean(restOfFilters.onlyPreprocessedSearch))
  }, [form, filter])

  return (
    <>
      {/* // TODO: Maybe Change color or something if there is a filter applied */}
      <div className={classnames(style.EmailQueryParams, collapsed && style.collapsed)}>
        <Space className={style.QueryButtonCommands}>
          <CollapseSidebarButton
            collapsed={collapsed}
            onClick={setCollapseSidebar}
          />
          <QueryPresetButtons
            collapsed={collapsed}
            openSaveQueryModal={() => {
              setModalVisible(true)
              setQueryModalType('new')
            }}
            openQueriesListModal={() => {
              setModalVisible(true)
              setQueryModalType('list')
            }}
          />
        </Space>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={toInitialValues(filter)}
          onValuesChange={(_, allValues) => {
            setActiveFilter(allValues) // used for save preset filter only
          }}
        >
          <AutoFillSearchForEmailAnalysisButton form={form} />
          {/* <ByOpenSearchIds /> */}
          <ByOriginalSourceEmailId />
          <ByEmailEnvironment />
          <ByLanguage supportedLanguages={supportedLanguages} />
          <ByTextParams>
            <ByOnlyPreprocessed onCheckOnlyPreprocessed={onCheckOnlyPreprocessed} checked={onlyPreprocessedSearch} />
          </ByTextParams>
          <ByRegularExpression form={form} />
          <ByTrainAndTestIntents form={form} onlyPreprocessedSearch={onlyPreprocessedSearch} />
          <ByDatasetType />
          <ByDateRange />
          {/* // NOTE: Hide for now, we currently only have SalesForce */}
          {/* <BySource /> */}
          <ByStatuses />
          <ByApprovalStatus />
          <ByFeedback />
          <ByDataExtractionLabels />
          <ByCapIdAndEmployment />
          <ByAccuracyChecking />
          <ByCreditCardMasking />
          <Button type='primary' htmlType='submit' block>
            Search
          </Button>
        </Form>
      </div>
      {
        queryModalType === 'list'
        && (
          <ListPresets
            filter={filter}
            setFilter={setFilter}
            modalVisible={modalVisible}
            onCancel={closeModal}
          />
        )
      }
      {
        queryModalType === 'new'
        && (
          <SavePreset
            activeFilter={activeFilter}
            modalVisible={modalVisible}
            onOk={closeModal}
            onCancel={closeModal}
          />
        )
      }
    </>
  )
}

QueryParams.propTypes = {
  filter: PropTypes.shape({
    ids: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    regex: PropTypes.string,
    excludeText: PropTypes.string,
    excludeRegex: PropTypes.string,
    emailIds: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    trainIntentIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    excludeTrainIntentIds: PropTypes.arrayOf(PropTypes.number),
    predictedIntentNames: PropTypes.arrayOf(PropTypes.string),
    predictedIntentScoreMin: PropTypes.number,
    predictedIntentScoreMax: PropTypes.number,
    emailSentAt: PropTypes.arrayOf(PropTypes.string),
    recordCreatedAt: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string),
    feedbackSentiments: PropTypes.arrayOf(PropTypes.string),
    extractionLabelsTree: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
    onlyPreprocessedSearch: PropTypes.bool,
    containsCreditCard: PropTypes.arrayOf(PropTypes.string),
    language: PropTypes.arrayOf(PropTypes.string),
    perPage: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  activeFilter: PropTypes.shape({
    ids: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    regex: PropTypes.string,
    excludeText: PropTypes.string,
    excludeRegex: PropTypes.string,
    emailIds: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    trainIntentIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    excludeTrainIntentIds: PropTypes.arrayOf(PropTypes.number),
    predictedIntentNames: PropTypes.arrayOf(PropTypes.string),
    predictedIntentScoreMin: PropTypes.number,
    predictedIntentScoreMax: PropTypes.number,
    emailSentAt: PropTypes.arrayOf(PropTypes.string),
    recordCreatedAt: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string),
    feedbackSentiments: PropTypes.arrayOf(PropTypes.string),
    extractionLabelsTree: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
    onlyPreprocessedSearch: PropTypes.bool,
    containsCreditCard: PropTypes.arrayOf(PropTypes.string),
    language: PropTypes.arrayOf(PropTypes.string),
    perPage: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  setActiveFilter: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  makeSearch: PropTypes.func.isRequired,
}

export default QueryParams
