import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import classnames from 'classnames'
import {
  Spin,
  List,
  Button,
  Divider,
  Typography,
  Tooltip,
} from 'antd'
import style from './index.module.scss'
import { humanizeDuration } from '../../helpers'
import useAnalysis from '../../hooks/useAnalysis'

const TYPE_MESSAGE = 'MESSAGE'
const TYPE_DATE = 'DATE'

const ItemType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.string.isRequired,
  direction: PropTypes.string,
  className: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  createdTime: PropTypes.string,
  createdAtDateTime: PropTypes.string,
  humanizedDurationFromLastMessage: PropTypes.string,
  extracted: PropTypes.objectOf(PropTypes.object),
  machineState: PropTypes.objectOf(PropTypes.object),
  status: PropTypes.string.isRequired,
})

const DateRow = ({ item }) => {
  return (
    <div className={item.className}>
      <Tooltip title={item.createdAtDateTime}>
        <span>
          {`${item.createdDate}@${item.createdTime}`}
        </span>
      </Tooltip>
    </div>
  )
}

DateRow.propTypes = {
  item: ItemType.isRequired,
}

const Metadata = ({ title, text }) => {
  return (
    <span title={title} className={style.message_analyze__domain_intent}>
      {`${text}`}
    </span>
  )
}

Metadata.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const MessageMetadata = ({ domain, intent }) => {
  return (
    <>
      <Metadata title={`Domain: ${domain}`} text={`${domain}`} />
      {' / '}
      <Metadata title={`Intent: ${intent}`} text={`${intent}`} />
    </>
  )
}

MessageMetadata.propTypes = {
  domain: PropTypes.string.isRequired,
  intent: PropTypes.string.isRequired,
}

const SentenceDomainIntent = ({ sentence }) => {
  const [{ loading, isError }, analysis] = useAnalysis(sentence)

  if (loading) return (<Spin size='small' />)

  if (isError) {
    return (
      <Typography.Text type='danger'>Analyze Error</Typography.Text>
    )
  }

  return <MessageMetadata domain={analysis.domain} intent={analysis.intent} />
}

SentenceDomainIntent.propTypes = {
  sentence: PropTypes.string.isRequired,
}

const MessageRow = ({ item }) => {
  const [showAnalysis, setShowAnalysis] = useState(false)
  const toggleAnalysis = () => {
    setShowAnalysis((prev) => { return !prev })
  }

  const stateValue = JSON.stringify(((item.machineState || {}).state || {}).value) || 'NO DATA'
  const isCustomerMessageWithMetadata = item.direction === 'left' && item.extracted
  const isCustomerMessageWithoutMetadata = item.direction === 'left' && !item.extracted

  return (
    <>
      <div className={item.className}>
        <div>{item.text}</div>
        <div>
          <small className={style.time}>
            {item.createdTime}
          </small>
          {item.humanizedDurationFromLastMessage !== undefined && (
            <small className={style.message__duration_from_last_message}>
              {`[${item.humanizedDurationFromLastMessage}]`}
            </small>
          )}
        </div>
        <Divider className={style.message_analyze__divider} />

        {isCustomerMessageWithMetadata && (
          <MessageMetadata domain={item.extracted.domain} intent={item.extracted.intent} />
        )}
        {isCustomerMessageWithoutMetadata && (
          <>
            <Button
              onClick={toggleAnalysis}
              className={style.message_analyze__button}
              type='link'
            >
              {showAnalysis ? 'Close' : 'Analyze Domain/Intent'}
            </Button>
            {showAnalysis && ' | '}
            {showAnalysis && <SentenceDomainIntent sentence={item.text} />}
          </>
        )}
        {item.direction === 'right' && (<Metadata title='Macahine state' text={stateValue} />)}
      </div>
      { item.status && (
        <div className={`${style.status_container} ${style[item.status.toLowerCase()]} ${style[item.direction]}`}>
          {item.status === 'ERROR' ? 'Failed' : item.status.substring(0, 1) + item.status.substring(1).toLowerCase()}
        </div>
      )}
    </>

  )
}

MessageRow.propTypes = {
  item: ItemType.isRequired,
}

const ConversationPage = ({ messages, showOnlyCustomerMessage }) => {
  const items = []
  let previousItemMessage

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i]

    const createdAt = dayjs(message.created_at).tz('Asia/Bangkok')
    const createdDate = createdAt.format('DD/MM/YYYY')
    const createdTime = createdAt.format('HH:mm')

    if (!previousItemMessage || previousItemMessage.createdDate !== createdDate) {
      items.push({
        id: createdDate,
        className: style.date_container,
        type: TYPE_DATE,
        createdDate,
        createdTime,
        createdAtDateTime: message.created_at,
        status: message.status,
      })
    }

    const itemMessage = {
      id: message.id,
      text: message.text,
      type: TYPE_MESSAGE,
      direction: message.direction,
      className: `${style.message_container} ${style[message.messageType.toLowerCase()]}`,
      extracted: message.extracted,
      machineState: message.machineState,
      createdDate,
      createdTime,
      createdAt,
      createdAtDateTime: message.created_at,
      status: message.status,
    }

    if (previousItemMessage) {
      itemMessage.humanizedDurationFromLastMessage = humanizeDuration(
        createdAt.diff(previousItemMessage.createdAt),
      )
    }

    items.push(itemMessage)

    previousItemMessage = itemMessage
  }

  // NOTE: Cannot at tabs view here otherwise it will also show up on the conversation tab too
  return (
    <List
      className={classnames('conversation_container', { only_customer_message: showOnlyCustomerMessage })}
      dataSource={items}
      renderItem={(item) => {
        return (
          item.type === TYPE_DATE
            ? <DateRow key={item.id} item={item} />
            : <MessageRow key={item.id} item={item} />
        )
      }}
    />
  )
}

ConversationPage.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
    messageType: PropTypes.oneOf(['AGENT', 'BOT', 'CUSTOMER', 'SYSTEM']).isRequired,
    extracted: PropTypes.objectOf(PropTypes.object),
    machineState: PropTypes.objectOf(PropTypes.object),
    status: PropTypes.oneOf(['PENDING', 'SENT', 'ERROR']).isRequired,
  })).isRequired,
  showOnlyCustomerMessage: PropTypes.bool,
}

ConversationPage.defaultProps = {
  showOnlyCustomerMessage: false,
}

export default ConversationPage
