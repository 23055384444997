import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import useRequest from '@core/hooks/useRequest'
import Api from '../../api'

const TagsSelect = ({ multiple }) => {
  const [{ data }] = useRequest(Api.getTags)
  const { items: tags } = data ?? { items: [] }

  return (
    <Form.Item name='tags' label='Tags'>
      <Select
        mode={multiple ? 'multiple' : ''}
        placeholder='Select Tags'
        style={{ width: '100%' }}
        allowClear
      >
        {tags.map((tag) => {
          return (
            <Select.Option key={tag.name} value={tag.name}>
              {tag.name}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

TagsSelect.propTypes = {
  multiple: PropTypes.bool,
}

TagsSelect.defaultProps = {
  multiple: false,
}

export default TagsSelect
