const getOption = (data) => {
  return {
    title: {
      text: 'All Conversation Tags',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: data.map(({ name }) => { return name }),
    },
    series: [
      {
        name: 'Conv. Count',
        type: 'bar',
        data: data.map(({ value }) => { return value }),
      },
    ],
  }
}

export default getOption
