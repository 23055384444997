import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Typography } from 'antd'
import {
  selectedItemTrainingType,
  modalVisibilityType,
  setModalVisibilityType,
  setSelectedItemType,
} from '../../types/updateCaseModal'
import TrainingSetsActions from '../../store/actions/TrainingSets'
import Modal from '../FormSubmissionModal'
import DomainForm from './Domain'
import IntentForm from './Intent'
import TaggerForm from './Tagger'

const { Text } = Typography
const handleFormError = ({ setConfirmLoading, setHasError, error }) => {
  console.info('Validate Failed:', error)
  setConfirmLoading(false)

  // NOTE: Must not be the validation error
  if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
}

// NOTE: We dont' have an update for the generalizer yet
const UpdateTrainingCase = ({
  selectedItem,
  setSelectedItem,

  modalVisibility,
  setModalVisibility,
}) => {
  // Change intent reducers
  const {
    domainList,
    generalized,
  } = useSelector(({ core, data }) => {
    return {
      domainList: core.domainIntent.domainList,
      generalized: data.selectedSentence.generalized,
    }
  })
  const {
    id,
    index,
    train_domain_id,
    train_intent_id,
    note,
    phrase,
    phrase_index,
    desired_result,
    tagger_type,

    type: trainingCaseType,
  } = selectedItem
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasError(false)
    setSelectedItem({})
    form.resetFields()
  }

  const formSubmission = {
    domain: async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['train_domain_id'])
        if (values) {
          response = await TrainingSetsActions.updateDomainTrainingSetById(id, values, index)
          if (response) {
            form.resetFields()
            setConfirmLoading(true)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
    intent: async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['domain_intent'])
        if (values) {
          response = await TrainingSetsActions.updateIntentTrainingSetById(id, values, index)
          if (response) {
            form.resetFields()
            setConfirmLoading(true)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
    tagger: async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['tagger_type', 'phrase', 'desired_result'])
        if (values) {
          response = await TrainingSetsActions.updateTaggerTrainingSetById(id, values, index)
          if (response) {
            form.resetFields()
            setConfirmLoading(true)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
  }

  return modalVisibility && (
    <Modal
      title={`Edit Training Case for ${trainingCaseType}`}
      visible={modalVisibility}
      onOk={formSubmission[trainingCaseType]}
      onCancel={closeModal}
      confirmLoading={confirmLoading}
      closable
    >
      {trainingCaseType === 'domain' && (
        <DomainForm
          form={form}
          train_domain_id={train_domain_id}
          note={note}
          domainList={domainList}
          generalized={generalized}
          sentence={generalized.original}
        />
      )}
      {trainingCaseType === 'intent' && (
        <IntentForm
          form={form}
          train_domain_id={train_domain_id}
          train_intent_id={train_intent_id}
          note={note}
          generalized={generalized}
          sentence={generalized.original}
        />
      )}
      {trainingCaseType === 'tagger' && (
        <TaggerForm
          form={form}
          phrase={phrase}
          phrase_index={phrase_index}
          desired_result={desired_result}
          tagger_type={tagger_type}
          note={note}
          sentence={generalized.original}
          generalized={generalized}
        />
      )}
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Modal>
  )
}

UpdateTrainingCase.propTypes = {
  selectedItem: selectedItemTrainingType.isRequired,
  modalVisibility: modalVisibilityType.isRequired,
  setModalVisibility: setModalVisibilityType.isRequired,
  setSelectedItem: setSelectedItemType.isRequired,
}

export default UpdateTrainingCase
