export const mapFilterSubmittedIntent = (intents) => {
  return intents.reduce((array, intent) => {
    if (intent.approval_status === 'SUBMITTED') array.push(intent.id)

    return array
  }, [])
}

export const checkIfWasSubmittedPositive = (intentName, samplesToCheck) => {
  return Boolean(samplesToCheck?.find((sample) => { return sample.name === intentName }))
}

export const checkIfWasSubmittedNegative = (intentName, samplesToCheck) => {
  return Boolean(samplesToCheck?.find((sample) => { return sample.name === intentName }))
}

export const checkIfOppositeWasSubmitted = (intentName, samplesToCheck) => {
  let oppositeIntent = ''
  if (intentName.startsWith('not-')) {
    // was called on negative
    oppositeIntent = intentName.slice(4)
  } else {
    oppositeIntent = `not-${intentName}`
  }

  return Boolean(samplesToCheck?.find((sample) => { return sample.name === oppositeIntent }))
}

export const findIdOfIntentByName = (intentName, intentPairs, specificIntentPairs) => {
  if (!intentName || !intentPairs) {
    console.error('findIdOfIntentByName - one of params missing, did you pass them?')
    return undefined
  }

  if (intentName.includes('_')) {
    if (intentName.startsWith('not-')) return specificIntentPairs[intentName.slice(4)].negative.id

    return specificIntentPairs[intentName].positive.id
  }

  if (intentName.startsWith('not-')) {
    return intentPairs[intentName.slice(4)].negative.id
  }

  return intentPairs[intentName].positive.id
}

export const findSubmittedIntentTagging = (record, submittedIntentTaggingList) => {
  if (!Array.isArray(submittedIntentTaggingList) || !submittedIntentTaggingList.length) {
    return undefined // Array#find returns undefined
  }

  return submittedIntentTaggingList?.find((sample) => {
    return sample.name === record.positive || sample.name === record.negative
  })
}
