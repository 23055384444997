import PropTypes from 'prop-types'

const {
  bool,
  string,
  number,
  shape,
  func,
  arrayOf,
} = PropTypes

export const customStatsTitleType = string
export const customStatsValueType = string
export const customStatsAbbreviationType = string
export const customStatsColorType = string
export const customStatsColonType = number

export const statsType = shape({
  messageCount: number,
  wordCount: number,
  averageWordCountPerMessage: number,
  totalConversationDuration: number,
  timeToAgent: number,
  botMessageCount: number,
  customerMessageCount: number,
  agentMessageCount: number,
  customerAverageSubsequentMessages: number,
  agentAverageSubsequentMessages: number,
  customerLongestDelayResponding: number,
  agentLongestDelayResponding: number,
  customerAverageDelayResponding: number,
  agentAverageDelayResponding: number,
  customerTotalWaitTime: number,
  agentTotalWaitTime: number,
})

export const extraType = shape({
  firstSentenceAnalysis: shape({
    predictedClass: string,
  }),
})

export const conversationType = shape({
  id: string.isRequired,
  stats: statsType.isRequired,
  extra: extraType.isRequired,
  message_id: string.isRequired,
  message_original: string.isRequired,
  message_type: string.isRequired,
  message_created_at: string.isRequired,
})

export const conversationsType = arrayOf(conversationType)

export const metaType = shape({
  totalCount: number.isRequired,
  perPage: number.isRequired,
  pageCount: number.isRequired,
  page: number.isRequired,
})

export const handlePaginationChangeType = func

export const onItemClickType = func

export const onClickType = func

export const onToggleExcludeFromTrainingType = func

export const featuredType = bool

export const responseTextModalOpenType = bool
export const responseTextModalLoadingType = bool
export const responseTextModalHasErrorType = bool
export const responseTextModalOnCloseType = func
export const querySourceType = string

export const selectedEditResponseTextShape = shape({
  id: string,
  app: string,
  key: string,
  value: string,
  client: string,
  lang_code: string,
  version: number,
})

export const simulationInfoPropShape = {
  id: string,
  note: string,
  created_at: string,
  created_by: string,
  updated_at: string,
}

export const e2eTestCaseShape = shape({
  id: string,
  description: string,
  skip: bool,
  expected: shape({
    responseKeys: arrayOf(string),
    textTerms: arrayOf(string),
    domain: string,
    intent: string,
  }),
})

export const evaluationResultsType = shape({
  responseKeys: bool,
  textTerms: bool,
  domain: bool,
  intent: bool,
})

export const conversationsDataFilterType = shape({
  texts: arrayOf(string).isRequired,
  exclude_texts: arrayOf(string).isRequired,
  regexes: arrayOf(string).isRequired,
  exclude_regexes: arrayOf(string).isRequired,
  message_type: string.isRequired,
  exclude_from_training: number.isRequired,
  tags: arrayOf(string).isRequired,
  datasource: string.isRequired,
  page: number,
  perPage: number,
})

export const conversationsChartFilterType = shape({
  texts: arrayOf(string).isRequired,
  exclude_texts: arrayOf(string).isRequired,
  regexes: arrayOf(string).isRequired,
  exclude_regexes: arrayOf(string).isRequired,
  message_type: string.isRequired,
  exclude_from_training: number.isRequired,
  tags: arrayOf(string).isRequired,
  datasource: string.isRequired,
})

export const conversationTagType = shape({
  name: string.isRequired,
})

export const e2eStateType = PropTypes.shape({
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.shape({
    exchanges: PropTypes.arrayOf(PropTypes.object),
    exchangesHashTable: PropTypes.objectOf(PropTypes.number),
    testCases: PropTypes.arrayOf(PropTypes.object),
    simulationInfo: PropTypes.shape(simulationInfoPropShape),
    rawExchanges: PropTypes.arrayOf(PropTypes.object),
    exchangesHashPairedWithTestCases: PropTypes.objectOf(PropTypes.array),
  }),
})
