import React, {
  useContext,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Input,
  Button,
  Select,
  Typography,
  Spin,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useFetchFaqCategoriesFilter } from '../../../../hooks/useManageFaqs'
import { FaqVersionContext } from '../../../../helpers/context'
import GetSubCategories from './GetSubCategories'
import style from './index.module.scss'

const MultiVarietiesQuestions = ({ name }) => {
  return (
    <>
      <Typography.Text className={style.MainSentence__Label}>Main FAQ Sentence</Typography.Text>
      <Form.List
        name={name}
        rules={[
          {
          // global validator
            validator: async (_, values) => {
              if (!values) {
                return Promise.reject(new Error('enter the field or remove it'))
              }

              return values
            },
          },
        ]}
      >
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <Form.Item
                    className='FAQ_Sentence'
                    required={false}
                    key={field.key}
                  >
                    <div style={{ display: 'flex' }}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onSubmit']}
                        name={[field.name, 'text']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: index === 0 ? 'Enter a FAQ Sentence' : 'Enter a similar FAQ Question Sentence or remove this field',
                          },
                        ]}
                        style={{ flex: 1 }}
                      >
                        <Input placeholder={index === 0 ? 'Main FAQ Sentence' : 'Similar FAQ Question Sentence'} maxLength={360} />
                      </Form.Item>
                      {index !== 0 && (
                        <Button
                          type='link'
                          size='small'
                          onClick={() => { return remove(field.name) }}
                          danger
                          style={{ paddingRight: 0 }}
                        >
                          <MinusCircleOutlined className='dynamic-delete-button' />
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                )
              })}
              <Button
                type='dashed'
                onClick={() => { return add() }}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Add another similar sentence
              </Button>
            </>
          )
        }}
      </Form.List>
    </>
  )
}

MultiVarietiesQuestions.propTypes = {
  name: PropTypes.string.isRequired,
}

const Questions = ({ form }) => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [selectedFaqCategories, setSelectedFaqCategories] = useState(undefined)
  const [{ loading }, faqCategories] = useFetchFaqCategoriesFilter(faqVersionId)
  const onClear = () => {
    form.resetFields(['subcategory_id'])
  }

  const renderFaqQuestionCategory = () => {
    if (loading) return (<Spin />)

    return (
      <Select
        placeholder='Category'
        loading={loading}
        value={selectedFaqCategories}
        onChange={setSelectedFaqCategories}
        onClear={onClear}
        allowClear
      >
        {
          faqCategories
          .filter((category) => { return !category.parent_id })
          .map(({ id, name }, index) => { return (<Select.Option key={index} value={id}>{name}</Select.Option>) })
        }
      </Select>
    )
  }

  return (
    <>
      <Form.Item
        label='Category'
        name='category_id'
        rules={[{ required: true, message: 'Required field' }]}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        {renderFaqQuestionCategory()}
      </Form.Item>
      <GetSubCategories key={selectedFaqCategories} faqParentCategoryId={selectedFaqCategories} />
      {/* This first index is 'main_question' anything else is in similar_questions as object of { text: <VALUES>}  */}
      <MultiVarietiesQuestions name='faq_questions' />
    </>
  )
}

Questions.propTypes = {
  form: PropTypes.shape(PropTypes.object).isRequired,
}

export default Questions
