import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Result, Typography, Button, Space, Table } from 'antd'

import useRequest from '@core/hooks/useRequest'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import { shallowCleanFalsyObject, sortTextOrNull } from '@core/helpers'

import EditDeploymentGroupModal from '../../../components/EditDeploymentGroupModal'
import DataTable from '../../../components/DataTable'
import Api from '../../../api'

const fixedDeploymentGroupsFilter = { clientGroupType: ['DEPLOYMENTS'] }

const expandedRowRenderWrapper = (allFeatureFlags) => {
  return (record, _, __, expanded) => {
    if (!expanded) return null

    const allFeatureFlagsNameToFalseLookup = allFeatureFlags?.items?.reduce((acc, curr) => {
      acc[curr.name] = false
      return acc
    }, {})
    const latestFeaturesNameToValueLookup = Object.values(record?.latestFeatures || {}).reduce((acc, { name, value }) => {
      acc[name] = value
      return acc
    }, {})
    const mergedLookup = { ...allFeatureFlagsNameToFalseLookup, ...latestFeaturesNameToValueLookup }
    const dataSource = Object.entries(mergedLookup).map(([name, value]) => { return { name, value } })
    const assignedCount = Object.values(mergedLookup || {}).filter((value) => { return value }).length

    const columns = [
      {
        title: (
          <>
            <Typography.Text>Feature Name</Typography.Text>
            <Typography.Text type='secondary'>{` (${assignedCount}/${allFeatureFlags?.items?.length} features enabled)`}</Typography.Text>
          </>
        ),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => { return sortTextOrNull(a.name, b.name) },
      },
      {
        title: 'Status',
        dataIndex: 'value',
        key: 'value',
        sorter: (a, b) => { return sortTextOrNull(a.value, b.value) },
        render: (value) => {
          if (value === true) return <Typography.Text type='success'>Enabled</Typography.Text>
          if (value === false) return <Typography.Text type='danger'>Disabled</Typography.Text>
          return <Typography.Text>N/A</Typography.Text>
        },
      },
    ]

    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    )
  }
}

const sortFeatureNames = (latestFeatures) => { return Object.values(latestFeatures || {}).map(({ name }) => { return name })?.sort() }

const sortChannelNames = (latestChannels) => {
  return Object.values(latestChannels || {}).filter((channel) => { return channel.value }).map(({ name }) => { return name })?.sort()
}

const DataTab = ({
  timeFormatter,
  filter,
  handlePaginationChange,
  triggerSearch,
  environment,
}) => {
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editModalInitialValues, setEditModalInitialValues] = useState({})
  const [assignedFeaturesSortDirection, setAssignedFeaturesSortDirection] = useState()
  const [assignedChannelsSortDirection, setAssignedChannelsSortDirection] = useState()

  const clientGroupQuery = { ...filter, ...fixedDeploymentGroupsFilter }
  const [{ loading, data, hasError }] = useRequest(Api.ClientGroups.findMany, shallowCleanFalsyObject(clientGroupQuery))
  const [{ data: allFeatureFlags }] = useRequest(Api.FeatureFlags.findMany, { showAll: true })

  const onEditModalClicked = (record) => {
    setEditModalInitialValues(record)
    setEditModalVisible(true)
  }

  const onSetEditModalVisibility = (visible) => {
    setEditModalVisible(visible)
    setEditModalInitialValues({})
  }

  const handleTableChange = (/* pagination */_, /* filters */__, sorter) => {
    if (sorter.columnKey === 'latestFeatures') {
      setAssignedFeaturesSortDirection(sorter.order)
    }
    if (sorter.columnKey === 'latestChannels') {
      setAssignedChannelsSortDirection(sorter.order)
    }
  }

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }

  const { items, meta } = data

  let transformedItems = items?.map((item) => {
    const sortedFeatureNames = sortFeatureNames(item.latestFeatures)
    const allFeaturesCount = allFeatureFlags?.items?.length
    const isAllFeaturesAssigned = allFeaturesCount
      && allFeaturesCount === sortedFeatureNames?.length
    const isNoFeatureAssigned = !allFeaturesCount || !sortedFeatureNames.length

    const assignedFeaturesRenderText = isAllFeaturesAssigned ? 'ALL' : sortedFeatureNames?.join(', ') || '-'

    const sortedChannelsName = sortChannelNames(item.latestChannels)
    const isNoChannelAssigned = !sortedChannelsName.length

    const assignedChannelsRenderText = sortedChannelsName?.join(', ') || '-'

    return {
      ...item,
      isAllFeaturesAssigned,
      isNoFeatureAssigned,
      isNoChannelAssigned,
      assignedFeaturesRenderText,
      assignedChannelsRenderText,
    }
  })

  const itemsWithAllAssignedFeatures = []
  const itemsWithNoAssignedFeatures = []
  const itemsWithSomeAssignedFeatures = []
  transformedItems?.forEach((item) => {
    const { isAllFeaturesAssigned, isNoFeatureAssigned } = item

    if (isAllFeaturesAssigned) {
      itemsWithAllAssignedFeatures.push(item)
    } else if (isNoFeatureAssigned) {
      itemsWithNoAssignedFeatures.push(item)
    } else {
      itemsWithSomeAssignedFeatures.push(item)
    }
  })

  if (assignedFeaturesSortDirection === 'ascend') {
    const sortedItemsWithSomeAssignedFeatures = [...itemsWithSomeAssignedFeatures].sort((a, b) => {
      return sortTextOrNull(a.assignedFeaturesRenderText, b.assignedFeaturesRenderText)
    })

    transformedItems = [].concat(
      itemsWithAllAssignedFeatures,
      sortedItemsWithSomeAssignedFeatures,
      itemsWithNoAssignedFeatures,
    )
  } else if (assignedFeaturesSortDirection === 'descend') {
    const sortedItemsWithSomeAssignedFeatures = [...itemsWithSomeAssignedFeatures].sort((a, b) => {
      return sortTextOrNull(b.assignedFeaturesRenderText, a.assignedFeaturesRenderText)
    })

    transformedItems = [].concat(
      sortedItemsWithSomeAssignedFeatures,
      itemsWithAllAssignedFeatures,
      itemsWithNoAssignedFeatures,
    )
  }

  const itemsWithNoAssignedChannels = []
  const itemsWithSomeAssignedChannels = []
  transformedItems?.forEach((item) => {
    const { isNoChannelAssigned } = item

    if (isNoChannelAssigned) {
      itemsWithNoAssignedChannels.push(item)
    } else {
      itemsWithSomeAssignedChannels.push(item)
    }
  })

  if (assignedChannelsSortDirection === 'ascend') {
    const sortedItemsWithSomeAssignedChannels = [...itemsWithSomeAssignedChannels].sort((a, b) => {
      return sortTextOrNull(a.assignedChannelsRenderText, b.assignedChannelsRenderText)
    })

    transformedItems = [].concat(
      sortedItemsWithSomeAssignedChannels,
      itemsWithNoAssignedChannels,
    )
  } else if (assignedChannelsSortDirection === 'descend') {
    const sortedItemsWithSomeAssignedChannels = [...itemsWithSomeAssignedChannels].sort((a, b) => {
      return sortTextOrNull(b.assignedChannelsRenderText, a.assignedChannelsRenderText)
    })

    transformedItems = [].concat(
      sortedItemsWithSomeAssignedChannels,
      itemsWithNoAssignedChannels,
    )
  }

  const columnsTable = [
    {
      title: (<strong>ID</strong>),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: (<strong>Name</strong>),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return sortTextOrNull(a.name, b.name) },
    },
    {
      title: (<strong>CAP ID</strong>),
      dataIndex: 'capIds',
      sorter: (a, b) => { return a.capIds.join().localeCompare(b.capIds.join()) },
      render: (capIds) => { return <Typography.Text>{capIds.join(',')}</Typography.Text> },
    },
    {
      title: (<strong>Country of Employment</strong>),
      dataIndex: 'countryOfEmployment',
      sorter: (a, b) => { return a.countryOfEmployment.join().localeCompare(b.countryOfEmployment.join()) },
      render: (countryOfEmployment) => { return <Typography.Text>{countryOfEmployment.join(',')}</Typography.Text> },
    },
    {
      title: (<strong>Assigned Features</strong>),
      dataIndex: 'assignedFeaturesRenderText',
      key: 'latestFeatures',
      sorter: true,
    },
    {
      title: (<strong>Assigned Channels</strong>),
      dataIndex: 'assignedChannelsRenderText',
      key: 'latestChannels',
      sorter: true,
    },
    {
      title: (<strong>Updated At</strong>),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 190,
      sorter: (a, b) => { return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime() },
      render: (updatedAt) => {
        if (!updatedAt) return <Typography.Text>-</Typography.Text>
        if (timeFormatter) {
          return <Typography.Text>{timeFormatter(updatedAt)}</Typography.Text>
        }
        return <Typography.Text>{updatedAt}</Typography.Text>
      },
    },
    {
      title: (<strong>Actions</strong>),
      key: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Button type='secondary' size='small' onClick={() => { return onEditModalClicked(record) }}>Edit</Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <DataTable
        columns={columnsTable}
        dataSource={transformedItems}
        meta={meta}
        filter={filter}
        onPaginationChange={handlePaginationChange}
        onTableChange={handleTableChange}
        expandable={{
          expandedRowRender: expandedRowRenderWrapper(allFeatureFlags),
        }}
      />
      {editModalVisible && (
        <EditDeploymentGroupModal
          key={editModalInitialValues.id}
          modalVisibility={editModalVisible}
          setModalVisibility={onSetEditModalVisibility}
          initialValues={editModalInitialValues}
          triggerSearch={triggerSearch}
          environment={environment}
        />
      )}
    </>
  )
}

DataTab.propTypes = {
  timeFormatter: PropTypes.func.isRequired,
  environment: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
}

export default DataTab
