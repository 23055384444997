import React, {
  useContext,
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Select,
  Spin,
} from 'antd'
import { FaqVersionContext } from '../../../../../helpers/context'
import { getFaqCategories } from '../../../../../query'

const GetSubCategories = ({ faqParentCategoryId }) => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [faqCategories, setFaqCategories] = useState([])
  const [selectedFaqCategories, setSelectedFaqCategories] = useState(undefined)
  const [faqCategoriesLoading, setFaqCategoriesLoading] = useState(false)

  useEffect(() => {
    let mounted = true

    const fetchFaqCategories = async () => {
      setFaqCategoriesLoading(true)
      if (mounted) {
        const categories = await getFaqCategories(faqVersionId, faqParentCategoryId)

        if (categories) {
          setFaqCategories(() => { return categories })
          setFaqCategoriesLoading(() => { return false })
        }
      }
    }

    // If there is no faqParentCategoryId do not waste request
    if (faqParentCategoryId) fetchFaqCategories()

    return () => {
      mounted = false
    }
  }, [faqVersionId, faqParentCategoryId])

  const renderFaqSubCategoriesSelect = () => {
    if (faqCategoriesLoading) return (<Spin />)

    return (
      <Select
        placeholder={faqParentCategoryId ? 'Subcategory' : 'Please select Category first'}
        loading={faqCategoriesLoading}
        value={selectedFaqCategories}
        onChange={setSelectedFaqCategories}
        disabled={!faqParentCategoryId}
        allowClear
      >
        {
          faqParentCategoryId
            ? faqCategories.map(({ id, name }, index) => { return (<Select.Option key={index} value={id}>{name}</Select.Option>) })
            : <Select.Option value={-1} disabled selected>Please select Category first</Select.Option>
        }
      </Select>
    )
  }

  return (
    <Form.Item
      label='Subcategory'
      name='subcategory_id'
      rules={[{ required: true, message: 'Required field' }]}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
    >
      {renderFaqSubCategoriesSelect()}
    </Form.Item>
  )
}

GetSubCategories.defaultProps = {
  faqParentCategoryId: null,
}

GetSubCategories.propTypes = {
  faqParentCategoryId: PropTypes.string,
}

export default GetSubCategories
