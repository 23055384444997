
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// NOTE: Local development causing CORS

export const setupSentry = async () => {
  console.log('### setupSentry ###')
  Sentry.init({
    dsn: 'https://6d04bad9dc3248d5bce6efd8d4c1c9a5@glitchtip.messaging.gbtconnect.com/7',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
