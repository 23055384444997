import React from 'react'
import { Tag, Empty, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useLogDisplay } from './useLogDisplay'
import { generateGrafanaLokiLink } from '../../../../../utils/generateGrafanaLokiLink'

import GrafanaLogo from './GrafanaLogo'
import HighlightedLogPreviewer from './HighlightedLogPreviewer'

const getLogLevel = (text) => {
  return /"level":"(\w*)"/gm.exec(text)?.[1] ?? 'unknown'
}

const LogContent = () => {
  const { logs, omitFields, textsToQuery } = useLogDisplay()

  return (
    <div style={{ height: '300px', overflowY: 'auto' }}>
      {
        !logs?.length && (<Empty style={{ marginTop: '50px' }} />)
      }
      {logs?.map(([timestamp, log, meta], index) => {
        const level = getLogLevel(log)?.toLowerCase()

        let color = 'grey'
        if (level === 'warn') {
          color = 'yellow'
        }
        if (level === 'error') {
          color = '#f50'
        }
        if (level === 'fatal') {
          color = 'magenta'
        }

        const openGrafana = () => {
          const from = (timestamp / (10 ** 6)) - 3 * 60 * 1000
          const to = (timestamp / (10 ** 6)) + 3 * 60 * 1000
          const url = generateGrafanaLokiLink({ labels: meta, from, to })
          window.open(url, '_blank')
        }

        return (
          // Disable due to linting error bug
          // eslint-disable-next-line prefer-template
          <div key={index + '-' + timestamp} style={{ backgroundColor: 'rgb(233, 233, 233)', marginBottom: '4px' }}>
            <div style={{ display: 'flex', paddingBottom: '2px', backgroundColor: level === 'error' ? color : 'none' }}>
              <Tag color={color}>{getLogLevel(log)}</Tag>
              <Tag>
                {dayjs(timestamp / (10 ** 6)).utc().format('DD/MM/YYYY HH:mm:ss')}
                +00:00
              </Tag>
              <Tag>{meta.component}</Tag>
              <Tag>{meta.container}</Tag>
              <Tooltip title='Open in Grafana'>
                <Tag
                  style={{ marginLeft: 'auto', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                  onClick={openGrafana}
                >
                  <GrafanaLogo />
                </Tag>
              </Tooltip>
            </div>
            <div style={{ backgroundColor: '#aaa', height: '1px' }} />
            <HighlightedLogPreviewer omitFields={omitFields} text={log} highlightFields={textsToQuery} />
          </div>
        )
      })}
    </div>
  )
}

export default LogContent
