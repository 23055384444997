import { VERSIONS, AUTH } from '../constants'

const INITIAL_STATE = {
  current: null,
  listed: [],
  hashmap: {},
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERSIONS.SET: {
      return {
        ...state,
        current: action.payload.version,
      }
    }
    case VERSIONS.GET.SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    }
    case VERSIONS.CREATE.SUCCESS:
    case VERSIONS.CLONE.SUCCESS: {
      return {
        ...state,
        listed: [].concat(...state.listed, action.payload),
      }
    }
    case VERSIONS.DELETE.SUCCESS:
      return {
        ...state,
        listed: state.listed.filter((version) => { return !(action.payload.includes(version.id)) }),
      }
    case AUTH.LOGIN.SUCCESS:
    case AUTH.LOGOUT.SUCCESS: {
      return {
        ...INITIAL_STATE,
      }
    }
    case VERSIONS.GET.FAILED:
    case VERSIONS.GET.REQUEST: {
      return {
        ...state,
        loading: false,
      }
    }
    case VERSIONS.UPDATE.SUCCESS:
    default: return state
  }
}
