import React, { useContext } from 'react'
import {
  Layout,
  Typography,
  Spin,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import { EmailAutomationsContext } from '../../../context'
import Api from '../../../api'
import LeftSider from './LeftSider'
import JobsTable from './JobsTable'

const { Content } = Layout
const { Text } = Typography

const Training = () => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const [
    {
      loading,
      data: jobs,
      hasError,
    },
    { makeRequest },
  ] = useRequest(Api.Jobs.getAllJobs, {
    path: 'providers.EmailIntentModels.train',
  }, currentVersion)

  const onSubmit = async (submittedForm) => {
    if (loading) return
    const { modelId, intentNames } = submittedForm
    await Api.Models.train({ version: currentVersion, datasource, modelId, intentNames })
    await makeRequest()
  }

  return (
    <div style={{ display: 'flex' }}>
      <LeftSider onSubmit={onSubmit} />
      <Content>
        {loading && <Spin />}
        {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
        {jobs && <JobsTable jobs={jobs} />}
      </Content>
    </div>
  )
}

export default Training
