import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Typography,
  Empty,
  Button,
  Divider,
  Spin,
} from 'antd'
import DescribeTestCase from './DescribeTestCase'
import style from './index.module.scss'

// NOTE: Running test meaning running whole conversation test
// whereas each exchange could have or not have test applied
const RunningTest = ({
  loading,
  exchanges,
  evaluateTestCases,
  exchangesHashPairedWithTestCases,
}) => {
  const [evaluationResult, setEvaluationResult] = useState({})

  const evaluateAndUpdateResult = async () => {
    setEvaluationResult({}) // clear the previous results, prevent misunderstanding
    const result = await evaluateTestCases()

    if (Object.entries(result).length) {
      setEvaluationResult(result)
    }
  }

  if (!exchanges.length || !Object.keys(exchanges).length) return <Empty description='Please select one of the saved simulation first to run the test' />

  return (
    <div className={style.RunningTest}>
      <Button
        onClick={evaluateAndUpdateResult}
        className={style.RunTest}
        loading={loading}
        type='default'
        size='small'
        htmlType='button'
        block
      >
        Run Test(s)
      </Button>
      {loading && <Spin />}
      {!loading && (
        <div className={style.ExchangeTestCaseList}>
          <Collapse>
            {Object
            .entries(exchangesHashPairedWithTestCases)
            .map(([exchangeId, testCasesInExchange], exchangeIndex) => {
              return (
                <Collapse.Panel
                  key={exchangeIndex}
                  header={<Typography.Text strong>{`Exchange#${exchangeId || 'N/A'}`}</Typography.Text>}
                >
                  {testCasesInExchange.map((testCase, testCaseIndex) => {
                    return (
                      <Fragment key={testCaseIndex}>
                        <DescribeTestCase
                          testCase={testCase}
                          results={evaluationResult[exchangeId]?.testCaseSimulationsHash[testCase.id] || {}}
                        />
                        {testCaseIndex !== testCasesInExchange.length - 1 ? <Divider /> : null}
                      </Fragment>
                    )
                  })}
                </Collapse.Panel>
              )
            })}
          </Collapse>
        </div>
      )}

    </div>
  )
}

RunningTest.defaultProps = {
  exchangesHashPairedWithTestCases: {},
}

RunningTest.propTypes = {
  loading: PropTypes.bool.isRequired,
  exchanges: PropTypes.arrayOf(PropTypes.object).isRequired,
  evaluateTestCases: PropTypes.func.isRequired,
  exchangesHashPairedWithTestCases: PropTypes.objectOf(PropTypes.array),
}

export default RunningTest
