import PropTypes from 'prop-types'

const { string, number, func, arrayOf, shape } = PropTypes

export const generalizedSentenceType = string
export const onHighlightedFilterClickType = func
export const tagsType = arrayOf(
  shape({
    phrase: string,
    phraseIndex: number,
    tags: arrayOf(shape({
      tag: string,
      type: string,
      value: number,
      vector: shape(),
    })),
    source: arrayOf(string),
  }),
)
