import React, { useState } from 'react'
import { Typography, Space, Row, Col, Tooltip, Switch, Alert, List, Descriptions } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const TaggerProcessedSentenceDisplay = ({
  sentence,
  desymbolizedSplitted,
  selectedPhrase,
  onSelectPhrase,
}) => {
  const [showOriginal, setShowOriginal] = useState(false)
  return (
    <Space direction='vertical'>
      <Space>
        <Tooltip
          color='black'
          title='Select the word or phrase to process, then select the "Tagger Type" and finally choose the expected/desired result for it.'
        >
          <InfoCircleOutlined />
        </Tooltip>
        <Typography.Text strong>Processed Sentence:</Typography.Text>
      </Space>

      <Row gutter={16}>
        { desymbolizedSplitted.map((item) => {
          return (
            <Col>
              {
                item.source
                  ? (
                    <Tooltip
                      placement='bottom'
                      arrowPointAtCenter
                      color='white'
                      title={(
                        <>
                          <Descriptions bordered size='small'>
                            <Descriptions.Item label='Phrase Index' span={3}>{item.phraseIndex}</Descriptions.Item>
                            <Descriptions.Item label='Entities' span={3}>{item.source}</Descriptions.Item>
                          </Descriptions>
                          {item.originalSynonyms && (
                            <List
                              header={(<b>Original Synonyms</b>)}
                              bordered
                              size='small'
                              dataSource={Object.entries(item.originalSynonyms)}
                              renderItem={([entity, original]) => {
                                return (
                                  <List.Item key={`${entity}-${original}`}>{`${entity}: ${original}`}</List.Item>
                                )
                              }}
                            />
                          )}
                        </>
                      )}
                    >
                      <Typography.Link
                        style={{ color: 'black' }}
                        onClick={() => { return onSelectPhrase(item) }}
                        mark={selectedPhrase
                            && selectedPhrase.value === item.value
                            && selectedPhrase.phraseIndex === item.phraseIndex}
                        code={item.source}
                      >
                        {item.value}
                      </Typography.Link>
                    </Tooltip>
                  )
                  : (
                    <Typography.Link
                      style={{ color: 'black' }}
                      onClick={() => { return onSelectPhrase(item) }}
                      mark={selectedPhrase
                        && selectedPhrase.value === item.value
                        && selectedPhrase.phraseIndex === item.phraseIndex}
                    >
                      {item.value}
                    </Typography.Link>
                  )
              }

            </Col>
          )
        })}
      </Row>
      <Space>
        <Typography.Text type='secondary' strong>Show Original Sentence</Typography.Text>
        <Switch
          defaultChecked={false}
          onChange={(value) => { return setShowOriginal(value) }}
        >
          Show Original Sentence
        </Switch>
      </Space>
      {showOriginal && <Row><Alert type='info' description={sentence} /></Row>}
    </Space>
  )
}

TaggerProcessedSentenceDisplay.propTypes = {
  sentence: PropTypes.string.isRequired,
  desymbolizedSplitted: PropTypes.string.isRequired,
  selectedPhrase: PropTypes.shape({
    value: PropTypes.string.isRequired,
    phraseIndex: PropTypes.number.isRequired,
  }).isRequired,
  onSelectPhrase: PropTypes.func.isRequired,
}

export default TaggerProcessedSentenceDisplay
