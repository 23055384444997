import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Space,
  Input,
  Typography,
  Button,
  Form,
  Divider,
  Tabs,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { searchToArrayByComma } from '@core/helpers'
import { e2eTestCaseShape } from '../../../../../types'
import style from './index.module.scss'

const requiredFieldRules = [{ required: true, message: 'Required field' }]

const TestCaseTabPane = ({
  testCase,
  updateTestCase,
  isPending,
}) => {
  const {
    id: testCaseId,
    description: testCaseDescription,
    skip: skipTest,
    expected,
  } = testCase
  const testCaseResponseKeys = expected?.responseKeys
  const testCaseTextTerms = expected?.textTerms
  const testCaseDomain = expected?.domain
  const testCaseIntent = expected?.intent

  const [form] = Form.useForm()
  const [matchResponseKey, setMatchResponseKey] = useState(!!testCaseResponseKeys)
  const [matchTextTerms, setMatchTextTerms] = useState(!!testCaseTextTerms)
  const [matchDomainIntent, setMatchDomainIntent] = useState(!!(testCaseDomain || testCaseIntent))
  const onChecked = (hookStateSetter) => {
    return (event) => {
      const { target: { checked } } = event
      hookStateSetter(checked)
    }
  }

  const onUpdate = () => {
    const formValues = form.getFieldsValue()
    const {
      description,
      skip,
      responseKeys,
      textTerms,
      domain,
      intent,
    } = formValues

    const payload = {
      id: testCaseId,
      description,
      skip,
    }

    if (responseKeys || textTerms || domain || intent) {
      payload.expected = {}
    }

    if (responseKeys) payload.expected.responseKeys = searchToArrayByComma(responseKeys, { toLowerCase: false })
    if (textTerms) payload.expected.textTerms = searchToArrayByComma(textTerms, { toLowerCase: false })
    if (domain) payload.expected.domain = domain
    if (intent) payload.expected.intent = intent

    updateTestCase(payload)
  }

  // yah, f
  useEffect(() => {
    const {
      skip: skipAsEffect,
      description: descriptionAsEffect,
      expected: expectedAsEffect,
    } = testCase
    const responseKeys = expectedAsEffect?.responseKeys
    const textTerms = expectedAsEffect?.textTerms
    const domain = expectedAsEffect?.domain
    const intent = expectedAsEffect?.intent

    setMatchResponseKey(!!responseKeys)
    setMatchTextTerms(!!textTerms)
    setMatchDomainIntent(!!(domain || intent))

    form.setFieldsValue({
      skip: skipAsEffect,
      description: descriptionAsEffect,
      responseKeys: responseKeys && responseKeys.length ? responseKeys.join(', ') : '',
      textTerms: textTerms && textTerms.length ? textTerms.join(', ') : '',
      domain: domain || '',
      intent: intent || '',
    })
  }, [testCase, form])

  return (
    <div className='CaseSelection'>
      <Space className={style.TestCaseId}>
        <Typography.Text strong>Test Case ID:</Typography.Text>
        <Typography.Text>{testCaseId}</Typography.Text>
      </Space>
      <Form
        id={`Form__TestCase--${testCaseId}`}
        name={`Form__TestCase--${testCaseId}`}
        form={form}
        layout='vertical'
        initialValues={{
          skip: skipTest,
          description: testCaseDescription,
          responseKeys: testCaseResponseKeys && testCaseResponseKeys.length ? testCaseResponseKeys.join(', ') : '',
          textTerms: testCaseTextTerms && testCaseTextTerms.length ? testCaseTextTerms.join(', ') : '',
          domain: testCaseDomain || '',
          intent: testCaseIntent || '',
        }}
        onFinish={onUpdate}
      >
        <Form.Item
          label='Description'
          name='description'
          rules={requiredFieldRules}
        >
          <Input.TextArea disabled={isPending} rows={2} placeholder='Describe Test' />
        </Form.Item>
        <Form.Item
          name='skip'
          valuePropName='checked'
        >
          <Checkbox disabled={isPending}>
            <Typography.Text strong>Skip</Typography.Text>
          </Checkbox>
        </Form.Item>
        <Divider />
        <Space className={style.MatchingCondition} direction='vertical' size='middle'>
          <Checkbox
            disabled={isPending}
            checked={matchResponseKey}
            onChange={onChecked(setMatchResponseKey)}
          >
            <Typography.Text strong>Matching Response Key</Typography.Text>
          </Checkbox>
          {matchResponseKey && (
            <Form.Item
              name='responseKeys'
              rules={matchResponseKey ? requiredFieldRules : null}
              preserve={false}
            >
              <Input disabled={isPending} placeholder='Use comma (,) for multiple matches' />
            </Form.Item>
          )}
          <Checkbox
            disabled={isPending}
            checked={matchTextTerms}
            onChange={onChecked(setMatchTextTerms)}
          >
            <Typography.Text strong>Matching Text Terms</Typography.Text>
          </Checkbox>
          {matchTextTerms && (
            <Form.Item
              name='textTerms'
              rules={matchTextTerms ? requiredFieldRules : null}
              preserve={false}
            >
              <Input disabled={isPending} placeholder='Use comma (,) for multiple matches' />
            </Form.Item>
          )}
          <Checkbox
            disabled={isPending}
            checked={matchDomainIntent}
            onChange={onChecked(setMatchDomainIntent)}
          >
            <Typography.Text strong>Match Domain / Intent</Typography.Text>
          </Checkbox>
          {matchDomainIntent && (
            <Form.Item
              className={style.MatchingCondition__DomainIntent}
              name='domain'
              rules={matchDomainIntent ? requiredFieldRules : null}
              preserve={false}
            >
              <Input disabled={isPending} placeholder='Domain' />
            </Form.Item>
          )}
          {matchDomainIntent && (
            <Form.Item
              className={style.MatchingCondition__DomainIntent}
              name='intent'
              rules={matchDomainIntent ? requiredFieldRules : null}
              preserve={false}
            >
              <Input disabled={isPending} placeholder='Intent' />
            </Form.Item>
          )}
          <Button
            className={style.Save}
            type='default'
            size='small'
            htmlType='submit'
            loading={isPending}
          >
            UPDATE
          </Button>
        </Space>
      </Form>
    </div>
  )
}

TestCaseTabPane.propTypes = {
  testCase: e2eTestCaseShape.isRequired,
  updateTestCase: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
}

const ExistingTestCasesTabs = ({
  loading,
  testCases,
  updateExchangeTestCase,
  showModal,
}) => {
  const updateTestCase = (payload) => {
    updateExchangeTestCase(payload)
  }

  return (
    <Tabs
      type='card'
      tabBarExtraContent={(
        <Button
          type='link'
          onClick={showModal}
          icon={<PlusOutlined />}
          loading={loading}
        >
          Add new case
        </Button>
      )}
    >
      {testCases.map((testCase, index) => {
        const tabTitle = index + 1
        // NOTE: Putting Tabs.TabPane in the another component and calling it here will not render at all
        // antd must have some kind of 'direct first level, sibling' force hack something on Tabs > Tabs.Tabpane.
        return (
          <Tabs.TabPane tab={tabTitle} key={index}>
            <TestCaseTabPane
              testCase={testCase}
              updateTestCase={updateTestCase}
              isPending={loading}
            />
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  )
}

ExistingTestCasesTabs.defaultProps = {
  testCases: [],
}

ExistingTestCasesTabs.propTypes = {
  testCases: PropTypes.arrayOf(PropTypes.object),
  updateExchangeTestCase: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default ExistingTestCasesTabs
