/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: This file is started to be a big mess
import React, { useState } from 'react'
import {
  Row,
  Col,
  List,
  Typography,
  Form,
  Tabs,
  notification,
} from 'antd'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  caseType as casePropType,
  dataSourceType,
  renderAddNewCaseFormType,
} from '../../types/caseList'
import TestSetsActions from '../../store/actions/TestSets'
import FormSubmissionModal from '../FormSubmissionModal'
import AddNewCaseDropdownButton from '../AddNewCaseDropDownButton'
import UpdateTestCaseModal from '../UpdateTestCase'
import ListItem from './ListItem'

import style from './index.module.scss'

const { TabPane } = Tabs
const { Title, Text } = Typography
const handleFormError = ({ setConfirmLoading, setHasError, error }) => {
  console.info('Validate Failed:', error)
  setConfirmLoading(false)

  // NOTE: Must not be the validation error
  if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
}

const TestCaseList = ({
  caseType = 'Test',
  dataSource,
  renderAddNewCaseForm,
}) => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [updateTestCaseModalVisible, setUpdateTestCaseModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState(caseType)
  const [selectedCase, setSelectedCase] = useState('')
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [form] = Form.useForm()
  const { data, generalized } = useSelector((state) => { return state.data.selectedSentence })
  const { sentence_id: sentenceId } = useParams()
  const generalizedSentence = generalized && generalized.generalized ? generalized.generalized : ''
  const sentence = data && data.text ? data.text : ''
  const domainCaseExist = dataSource.domain.length >= 1
  const expectsNullDomain = dataSource.domain[0]?.test_domain_id === null
  const intentCaseExist = dataSource.intent.length >= 1 || expectsNullDomain
  const extractorCaseExist = dataSource.extractor?.length > 0

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasError(false)
    setSelectedCase('')
    form.resetFields()
  }

  const handleFormSubmission = (handler) => {
    return async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields()
        if (values) {
          response = await handler(sentenceId, values)
          if (response) {
            form.resetFields()
            setConfirmLoading(true)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    }
  }
  const onEditTestCase = (item, type) => {
    const localItem = item
    const localType = type
    return () => {
      setSelectedItem({ ...localItem, type: localType })
      setUpdateTestCaseModalVisible(true)
    }
  }

  // specifying each modal to validate what field feels like a hack...
  const formSubmission = {
    domain: async () => {
      if (hasError) setHasError(false)
      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['test_domain_id', 'note'])
        if (values) {
          response = await TestSetsActions.addDomainTestSet(sentenceId, values)
          if (response) {
            form.resetFields()
            setConfirmLoading(false)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
    intent: async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['domain_intent', 'note'])
        if (values) {
          response = await TestSetsActions.addIntentTestSet(sentenceId, values)
          if (response) {
            form.resetFields()
            setConfirmLoading(false)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
    tagger: async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['tagger_type', 'phrase', 'expected_result', 'note', 'skip'])
        if (values) {
          response = await TestSetsActions.addTaggerTestSet(sentenceId, values)
          if (response) {
            form.resetFields()
            setConfirmLoading(false)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
    extractor: async () => {
      if (hasError) setHasError(false)
      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['expected_data', 'test_intent_id', 'skip', 'note', 'relative_date'])
        if (values) {
          response = await TestSetsActions.addExtractorTestSet(sentenceId, values)
          if (response) {
            form.resetFields()
            setConfirmLoading(false)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
    generalizer: handleFormSubmission(Promise.resolve),
  }

  const onDelete = {
    domain: async (caseId) => {
      try {
        const response = await TestSetsActions.deleteDomainTestCase(caseId)
        if (response && response.data) {
          notification.info({
            message: 'Test case domain has been deleted',
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Test case domain deletion error',
          description: error.message,
          duration: 5,
        })
      }
    },
    intent: async (caseId) => {
      try {
        const response = await TestSetsActions.deleteIntentTestCase(caseId)
        if (response && response.data) {
          notification.info({
            message: 'Test case intent has been deleted',
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Test case intent deletion error',
          description: error.message,
          duration: 5,
        })
      }
    },
    tagger: async (caseId) => {
      try {
        const response = await TestSetsActions.deleteTaggerTestCase(caseId)
        if (response && response.data) {
          notification.info({
            message: 'Test case tagger has been deleted',
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Test case tagger deletion error',
          description: error.message,
          duration: 5,
        })
      }
    },
    extractor: async (caseId) => {
      try {
        const response = await TestSetsActions.deleteExtractorTestCase(caseId)
        if (response && response.data) {
          notification.info({
            message: 'Test case data extraction has been deleted',
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Test case data extraction  deletion error',
          description: error.message,
          duration: 5,
        })
      }
    },
  }

  const AntDListFooter = () => { return (<div className={style.metadata__ListFooter}>End of the list</div>) }

  const onOk = selectedCase ? formSubmission[selectedCase] : null

  const listProps = {
    className: style.metadata__List,
    footer: <AntDListFooter />,
    bordered: true,
    position: 'true',
    size: 'small',
    renderItem: (item) => {
      return (
        <ListItem
          item={item}
          onEditTestCase={onEditTestCase}
          onConfirmDelete={(testCaseType) => {
            if (testCaseType === 'nullDomain') testCaseType = 'domain'
            if (testCaseType === 'nullIntent') testCaseType = 'intent'
            onDelete[testCaseType](item.id)
          }}
        />
      )
    },
  }

  return (
    <>
      <Row justify='space-between'>
        <Col span={12}>
          <Title level={4}>{`${caseType} case`}</Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <AddNewCaseDropdownButton
            setModalVisibility={setModalVisibility}
            setModalTitle={setModalTitle}
            setSelectedCase={setSelectedCase}
            domainCaseExist={domainCaseExist}
            intentCaseExist={intentCaseExist}
            extractorCaseExist={extractorCaseExist}
            caseType={caseType}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey='1' type='card' size='large'>
        <TabPane tab='Domain' key='1'>
          <List {...listProps} dataSource={dataSource.domain} />
        </TabPane>
        <TabPane tab='Intent' key='2'>
          { expectsNullDomain && <Text type='warning'>Your expected Domain is null</Text>}
          <List {...listProps} dataSource={dataSource.intent} />
        </TabPane>
        <TabPane tab='Tagger' key='3'>
          <List {...listProps} dataSource={dataSource.tagger} />
        </TabPane>
        <TabPane tab='Data' key='4'>
          <List {...listProps} dataSource={dataSource.extractor} />
        </TabPane>
        {/* <TabPane tab='Generalizer' key='5'>
          <List {...listProps} dataSource={dataSource.generalizer} />
        </TabPane> */}
      </Tabs>
      <FormSubmissionModal
        visible={modalVisibility}
        title={modalTitle}
        onOk={onOk}
        onCancel={closeModal}
        loading={confirmLoading}
        closable
        hasError={hasError}
      >
        {modalVisibility && renderAddNewCaseForm(
          selectedCase,
          form,
          sentence,
          generalizedSentence,
        )}
      </FormSubmissionModal>
      {updateTestCaseModalVisible && (
        <UpdateTestCaseModal
          modalVisibility={updateTestCaseModalVisible}
          setModalVisibility={setUpdateTestCaseModalVisible}
          setSelectedItem={setSelectedItem}
          id={selectedItem.id}
          index={selectedItem.index}
          test_domain={selectedItem.test_domain}
          test_domain_id={selectedItem.test_domain_id}
          test_intent={selectedItem.test_intent}
          test_intent_id={selectedItem.test_intent_id}
          tagger_data={selectedItem}
          extractor_data={selectedItem}
          testCaseType={selectedItem.type}
        />
      )}
    </>
  )
}

TestCaseList.propTypes = {
  caseType: casePropType,
  dataSource: dataSourceType,
  renderAddNewCaseForm: renderAddNewCaseFormType,
}

TestCaseList.defaultProps = {
  caseType: 'Test',
  dataSource: {
    domain: [],
    intent: [],
    tagger: [],
    extractor: [],
    generalizer: [],
  },
  renderAddNewCaseForm: () => { return null },
}

export default TestCaseList
