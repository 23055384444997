import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Table,
  Tag,
  Typography,
  Tooltip,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../api'
import ModelEditModal from './ModelEditModal'

const { Text } = Typography

const PUBLISHED = 'PUBLISHED'

const ModelsTable = ({ type }) => {
  const [{
    loading,
    data: models,
    hasError,
  }, { makeRequest }] = useRequest(Api.getModels, { type })
  const [isPublishing, setIsPublishing] = useState(false)
  const [editingModel, setEditingModel] = useState(null)

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, model) => {
        const onEditClick = () => {
          setEditingModel(model)
        }

        return (
          <>
            <span style={{ marginRight: 4 }}>
              {name}
            </span>
            <Tooltip title='Edit name'>
              <EditOutlined onClick={onEditClick} />
            </Tooltip>
          </>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => { return created_at },
    },
    { title: 'Class Count', dataIndex: 'count', key: 'count' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return (
          <Tag color={status === PUBLISHED ? 'success' : 'blue'}>
            {status}
          </Tag>
        )
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: (model) => {
        const handlePublishClick = async () => {
          setIsPublishing(true)
          await Api.publishModel({ id: model.id, type })
          setIsPublishing(false)
          await makeRequest()
        }

        const isPublished = model.status === PUBLISHED

        return (
          <Button
            type='link'
            disabled={isPublished}
            loading={loading}
            onClick={handlePublishClick}
          >
            Publish
          </Button>
        )
      },
    },
  ]

  const dismissModelEditModal = async (reloadModels = false) => {
    setEditingModel(null)
    if (reloadModels) {
      await makeRequest()
    }
  }

  return (
    <>
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
      <ModelEditModal model={editingModel} dismiss={dismissModelEditModal} />
      <Table
        rowKey='id'
        className='components-table-demo-nested'
        loading={isPublishing || loading}
        columns={columns}
        dataSource={models || []}
      />
    </>
  )
}

ModelsTable.propTypes = {
  type: PropTypes.string.isRequired,
}

export default ModelsTable
