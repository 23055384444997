import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Layout,
  PageHeader,
  Descriptions,
} from 'antd'
import { useInternationalizeVersionDetail } from '@core/hooks/useInternationalize'

const ResponseText = () => {
  const { version_id } = useParams()
  const history = useHistory()
  const version = useInternationalizeVersionDetail(version_id)

  const onBack = () => { history.replace('/versions/i18n') }

  if (!version) return null

  return (
    <Layout.Content className='InternationalizeVersion__View'>
      <PageHeader
        title={`i18n Response Text Version #${version_id}`}
        onBack={onBack}
      />
      <Descriptions column={1} bordered size='small'>
        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='ID'>{version.id || 'N/A'}</Descriptions.Item>
        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Note'>{version.note || 'N/A'}</Descriptions.Item>
        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Created at'>{version.created_at || 'N/A'}</Descriptions.Item>
        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Updated at'>{version.updated_at || 'N/A'}</Descriptions.Item>
      </Descriptions>
    </Layout.Content>
  )
}

export default ResponseText
