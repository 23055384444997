import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spin, Space, Empty, Alert, Typography } from 'antd'
import ReactFlow, {
  Controls,
  MiniMap,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from 'react-flow-renderer'
import StateKeyNode from './StateKeyNode'
import StateNodeData from './StateNodeData'
import SelectStateKeys from './SelectStateKeys'

const xDelta = 300
const yDelta = 150
let maxConversationsSize = 0

const calculatePosition = (state, maxX, layers) => {
  const position = { x: 150, y: 0 }
  const currentLayerIndex = [...layers[state.data.utteranceIndex]].indexOf(state.id)
  position.x = (maxX - layers[state.data.utteranceIndex].size + (2 * currentLayerIndex)) * (xDelta / 2)
  position.y = (state.data.utteranceIndex - 1) * yDelta
  return position
}

const calculateHeat = (curr, max) => {
  if (max === 0) return 'white'
  const value = (curr / max) * 255
  return `rgb(${value}, 0, ${255 - value})`
}

const nodeTypes = { stateKey: StateKeyNode }

const DisplayFlow = () => {
  const { isPending, results } = useSelector((state) => { return state.conversationAnalysis.analytics })
  const [nodes, setNodes] = useNodesState([])
  const [edges, setEdges] = useEdgesState([])
  const [edgesString, setEdgesString] = useState([])

  const [open, setOpen] = useState(false)
  const [currentNodeData, setCurrentNodeData] = useState(false)

  const [selectedNode, setSelectedNode] = useState(null)

  const showDrawer = (data) => {
    setCurrentNodeData(data)
    setOpen(true)
  }

  const selectNode = (index) => {
    if (index !== selectedNode) setSelectedNode(index)
  }

  const onClose = () => {
    setOpen(false)
  }

  const { setCenter } = useReactFlow()

  useEffect(() => {
    const layers = {}
    let maxX = -1
    let maxConnectionSize = 0
    const states = {}
    const stateIndex = {}

    if (results.stateHierarchy) {
      Object.entries(results.stateHierarchy).forEach(([stateKey, item], idx) => {
        const { conversations, domainIntents, utteranceIndex, index, hasNull } = item
        if (!layers[utteranceIndex]) layers[utteranceIndex] = new Set()
        layers[utteranceIndex].add(index.toString())

        if (layers[utteranceIndex].size > maxX) maxX = layers[utteranceIndex].size
        if (conversations.length > maxConversationsSize && idx > 0) maxConversationsSize = conversations.length

        if (!states[stateKey]) {
          stateIndex[index] = stateKey
          const node = {
            id: index.toString(),
            sourcePosition: 'bottom',
            targetPosition: 'top',
            type: 'stateKey',
            data: {
              index,
              label: stateKey,
              conversations,
              conversationConnections: 0,
              domainIntents,
              utteranceIndex,
              showDrawer,
              hasNull,
              selectNode,
            },
          }
          states[stateKey] = node
        }
      })

      const connections = {}
      Object.values(results.conversationFlows).forEach((flow) => {
        if (flow.length === 1) return
        for (let i = 1; i < flow.length; i++) {
          const id = [flow[i - 1], flow[i]].join('-')
          if (!connections[id]) {
            connections[id] = {
              id,
              source: flow[i - 1].toString(),
              target: flow[i].toString(),
              arrowHeadType: 'arrowclosed',
              type: ConnectionLineType.Bezier,
              animated: true,
              size: 1,
              style: { visibility: 'hidden' },
            }
          } else {
            connections[id].size++
          }
          if (connections[id].size > maxConnectionSize) maxConnectionSize = connections[id].size
        }
      })

      const connectionsArr = Object.values(connections).map((connection) => {
        states[stateIndex[connection.source]].data.conversationConnections += connection.size
        connection.heat = {
          color: calculateHeat(connection.size, maxConnectionSize),
          size: `${0.75 + ((connection.size / maxConnectionSize))}px`,
        }
        return connection
      })
      setEdges(connectionsArr)
      setEdgesString(JSON.stringify(connectionsArr))

      const statesArr = Object.values(states).map((state) => {
        state.position = calculatePosition(state, maxX, layers)
        state.style = {
          width: 'auto',
          padding: 10,
          borderStyle: 'none',
          backgroundColor: calculateHeat(state.data.conversations.length, maxConversationsSize),
        }
        return state
      })
      setNodes(statesArr)

      if (statesArr.length > 0) setCenter(statesArr[0].position.x, statesArr[0].position.y, { zoom: 1 })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNodes, setEdges, results, setCenter])

  useEffect(() => {
    if (selectedNode) {
      const connectionsArr = Object.values(JSON.parse(edgesString)).map((connection) => {
        if (connection.target === selectedNode.toString() || connection.source === selectedNode.toString()) {
          connection.style = { stroke: connection.heat.color, strokeWidth: connection.heat.size, visibility: 'visible' }
          connection.label = connection.size
        } else {
          connection.style = { visibility: 'hidden' }
          connection.label = ''
        }
        return connection
      })
      setEdges(connectionsArr)
    }
  }, [selectedNode, setEdges, edgesString])

  if (isPending || !results) return <Spin />

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {currentNodeData
        && (
          <StateNodeData
            currentNodeData={currentNodeData}
            open={open}
            onClose={onClose}
          />
        )}
      <SelectStateKeys defaultStateKeyCategories={results.defaultStateKeyCategories} stateKeyCategoriesOpts={results.stateKeys} />
      {nodes.length > 0 ? (
        <div style={{ height: '600px', width: 'auto', border: '2px #006FCF solid' }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            snapToGrid
            snapGrid={[15, 15]}
            nodeTypes={nodeTypes}
            onInit={(rfi) => {
              if (nodes.length > 0) {
                rfi.setCenter(nodes[0].position.x, nodes[0].position.y, { zoom: 1 })
              }
            }}
            fitView
          >
            <Controls
              onFitView={() => {
                if (nodes.length > 0) {
                  setCenter(nodes[0].position.x, nodes[0].position.y, { zoom: 1 })
                }
              }}
            />
            <MiniMap
              zoomable
              nodeStrokeColor={(node) => { return calculateHeat(node.data.conversations.length, maxConversationsSize) }}
              nodeStrokeWidth={45}
            />
          </ReactFlow>
          <Alert
            message='How to read the state:'
            description={(
              <Space direction='vertical'>
                <Typography.Text strong style={{ background: 'linear-gradient(to right, red , blue)', width: 'auto', padding: '3px 5px', color: 'white', textAlign: 'center' }}>[HOT] Conversation density [COLD]</Typography.Text>
                <Typography.Text strong>{'[{Node Temporary Id}] {Conversation Depth Layer}-#{State Key Label}'}</Typography.Text>
                <Typography.Text strong mark>Has Null Intents</Typography.Text>
              </Space>
            )}
            type='info'
            showIcon
          />
        </div>
      ) : <Empty />}
    </Space>
  )
}

export default DisplayFlow
