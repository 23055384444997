import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip } from 'antd'

// TODO: need to figure out the issue of some words in raw text was transformed in generalized version i.e. "don't" -> "do", "not"
const RawSentence = ({ sentence }) => {
  return (
    <Typography.Text style={{ lineHeight: 1.8, wordSpacing: '2px' }}>
      {sentence?.map((token) => {
        if (token?.source) {
          const markStyle = {
            backgroundColor: 'hsla(0,0%,58.8%,.1)',
            cursor: 'pointer',
            border: '1px solid hsla(0,0%,39.2%,.2)',
            borderRadius: '6px',
            padding: '2px 6px',
          }

          return (
            <Tooltip overlayInnerStyle={{ color: token?.color }} placement='bottom' title={token?.generalizedText} color={token?.backgroundColor} key={token?.symbolIndex}>
              <mark key={token?.symbolIndex} style={markStyle}>{token?.value ?? ''}</mark>
              {' '}
            </Tooltip>
          )
        }

        return (token?.value?.concat(' ')) ?? ''
      })}
    </Typography.Text>
  )
}

RawSentence.propTypes = {
  sentence: PropTypes.arrayOf(PropTypes.shape({
    source: PropTypes.string,
    symbolIndex: PropTypes.number,
    generalizedText: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
  })).isRequired,
}

export default RawSentence
