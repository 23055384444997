import React, { useState } from 'react'
import { Form, Input, Button, Select, Typography, notification } from 'antd'
import { domainListType } from '@core/types/domainIntentCollectionTypes'
import GenericLabelOperation from '../GenericLabelOperation'
import DomainActions from '../../store/actions/Domain'

const { Text } = Typography

const AddNewDomainForm = () => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)

  // eslint-disable-next-line consistent-return
  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        const { name } = values
        response = await DomainActions.add({ name })

        if (response) {
          form.resetFields()
          notification.success({
            message: 'Successfully added new Domain',
            duration: 10,
          })
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name='domain_addNew'
      onFinish={onFinish}
    >
      <Form.Item
        label='Domain name'
        name='name'
        rules={[{ required: true, message: 'Please enter domain name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Form>
  )
}

const RenameDomainForm = ({ domainList }) => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)

  // eslint-disable-next-line consistent-return
  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        const { domain_id, name } = values
        response = await DomainActions.rename(domain_id, { name })

        if (response) {
          form.resetFields()
          notification.success({
            message: 'Successfully rename Domain',
            duration: 10,
          })
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      name='domain_rename'
      onFinish={onFinish}
    >
      <Form.Item
        name='domain_id'
        label='Domain to change'
        rules={[
          {
            required: true,
            message: 'Please selected domain',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a domain'
        >
          {domainList.map((domain, index) => {
            return (
              <Select.Option value={domain.id} key={index}>{domain.name}</Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label='New Domain name'
        name='name'
        rules={[{ required: true, message: 'Please enter new domain name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Form>
  )
}

RenameDomainForm.propTypes = {
  domainList: domainListType.isRequired,
}

const DeleteDomainForm = ({ domainList }) => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)

  const onFinish = async () => {
    if (hasError) setHasError(false)
    try {
      let response = null
      const values = await form.validateFields()
      if (values) {
        const { domain_ids } = values
        response = await DomainActions.delete(domain_ids)

        if (response) {
          form.resetFields()
          notification.success({
            message: 'Successfully delete Domain',
            duration: 10,
          })
        }
      }
    } catch (error) {
      console.info('Validate Failed:', error)

      // NOTE: Must not be the validation error
      if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
    }
  }

  return (
    <Form
      form={form}
      layout='vertical'
      name='domain_delete'
      onFinish={onFinish}
    >
      <Form.Item
        name='domain_ids'
        label='Domain to remove'
        rules={[
          {
            required: true,
            message: 'Please selected domain',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a domain'
          mode='multiple'
        >
          {domainList.map((domain, index) => {
            return (
              <Select.Option value={domain.id} key={index}>{domain.name}</Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Form>
  )
}

DeleteDomainForm.propTypes = {
  domainList: domainListType.isRequired,
}

const DomainOperation = ({ domainList }) => {
  return (
    <GenericLabelOperation
      title='Domain'
      tabContent={{
        tab_add: <AddNewDomainForm />,
        tab_rename: <RenameDomainForm domainList={domainList} />,
        tab_delete: <DeleteDomainForm domainList={domainList} />,
      }}
      tabList={[
        { key: 'tab_add', tab: 'Add' },
        { key: 'tab_rename', tab: 'Rename' },
        { key: 'tab_delete', tab: 'Delete' },
      ]}
    />
  )
}

DomainOperation.propTypes = {
  domainList: domainListType.isRequired,
}

export default DomainOperation
