import callApi from '@core/utils/http-client'

export default {
  reAssignIntent: (intent_id, data) => {
    return callApi({
      url: `/api/v1/intents/${intent_id}`,
      method: 'patch',
      data,
    })
  },
  renameIntent: (intent_id, data) => {
    return callApi({
      url: `/api/v1/intents/${intent_id}`,
      method: 'patch',
      data,
    })
  },
  addNewIntent: (data) => {
    return callApi({
      url: '/api/v1/intents',
      method: 'post',
      data,
    })
  },
  deleteIntent: (data) => {
    return callApi({
      url: '/api/v1/intents/batch/deletions',
      method: 'post',
      data,
    })
  },
  renameDomain: (domain_id, data) => {
    return callApi({
      url: `/api/v1/domains/${domain_id}`,
      method: 'patch',
      data,
    })
  },
  addNewDomain: (data) => {
    return callApi({
      url: '/api/v1/domains',
      method: 'post',
      data,
    })
  },
  deleteDomain: (data) => {
    return callApi({
      url: '/api/v1/domains/batch/deletions',
      method: 'post',
      data,
    })
  },
}
