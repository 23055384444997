import React, { useState, useMemo } from 'react'
import {
  Layout,
  PageHeader,
  Tabs,
  Button,
  Drawer,
  Typography,
  Space,
  Tag,
} from 'antd'
import {
  FileSearchOutlined,
  TagsOutlined,
  UnorderedListOutlined,
  SettingFilled,
  AppstoreAddOutlined,
} from '@ant-design/icons'
import TabPaneLabel from '@core/components/TabPaneLabel'
import { getCurrentFaqVersionId } from '@core/helpers/faqVersions'
import { FaqVersionContext } from '../../helpers/context'
import SelectVersion from '../../components/SelectVersion'
import ManageFaqs from '../../components/ManageFaqs'
import ManageCategories from '../../components/ManageCategories'
import ManageChoices from '../../components/ManageChoices'
import ManageKeywordPhrases from '../../components/ManageKeywordPhrases'

const FaqsHome = () => {
  const [faqVersionId, setFaqVersionId] = useState(getCurrentFaqVersionId())
  const [faqVersionDrawerOpen, setFaqVersionDrawerOpen] = useState(false)
  const [activeKey, setActiveKey] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : 'query',
  )
  const faqVersionMemoContext = useMemo(() => { return { faqVersionId, setFaqVersionId } }, [faqVersionId])

  const handleActiveKeyChange = (newActiveKey) => {
    window.location.hash = `#${newActiveKey}`
    setActiveKey(newActiveKey)
  }

  const onFaqSettingClicked = () => {
    setFaqVersionDrawerOpen((prev) => { return !prev })
  }

  const closeFaqVersionDrawer = () => { return setFaqVersionDrawerOpen(false) }

  return (
    <>
      <Layout.Content className='Faqs__Home'>
        <FaqVersionContext.Provider value={faqVersionMemoContext}>
          <PageHeader
            title={(
              <Space>
                <Typography.Title level={4}>FAQs Management</Typography.Title>
                <Tag color='green'>{`Current FAQ Version: ${faqVersionId}`}</Tag>
              </Space>
            )}
          />
          <Tabs
            activeKey={activeKey}
            onChange={handleActiveKeyChange}
            tabBarExtraContent={(
              <Button
                type='link'
                icon={<SettingFilled />}
                onClick={onFaqSettingClicked}
              >
                Select FAQs Version
              </Button>
            )}
          >
            <Tabs.TabPane
              key='query'
              tab={(
                <TabPaneLabel
                  title='Manage FAQs'
                  icon={<FileSearchOutlined />}
                  isActive={activeKey === 'query'}
                />
              )}
              forceRender
            >
              <ManageFaqs />
            </Tabs.TabPane>
            <Tabs.TabPane
              key='manage_categories'
              tab={(
                <TabPaneLabel
                  title='Manage Categories &amp; Subcategories'
                  icon={<TagsOutlined />}
                  isActive={activeKey === 'manage_categories'}
                />
              )}
              forceRender
            >
              <ManageCategories />
            </Tabs.TabPane>
            <Tabs.TabPane
              key='manage_choice'
              tab={(
                <TabPaneLabel
                  title='Manage Choices'
                  icon={<UnorderedListOutlined />}
                  isActive={activeKey === 'manage_choice'}
                />
              )}
              forceRender
            >
              <ManageChoices />
            </Tabs.TabPane>
            <Tabs.TabPane
              key='manage_keyword_phrases'
              tab={(
                <TabPaneLabel
                  title='Manage Keyword Combinations'
                  icon={<AppstoreAddOutlined />}
                  isActive={activeKey === 'manage_keyword_phrases'}
                />
              )}
              forceRender
            >
              <ManageKeywordPhrases />
            </Tabs.TabPane>
          </Tabs>
        </FaqVersionContext.Provider>
      </Layout.Content>
      {/* NOTE: FAQ version is shared across faq question , choices category and choices */}
      {faqVersionDrawerOpen && (
        <Drawer
          title='Select FAQs Version'
          placement='right'
          onClose={closeFaqVersionDrawer}
          visible={faqVersionDrawerOpen}
          destroyOnClose
          width='auto' // NOTE: antd 'forced' width on the style attribute
        >
          <SelectVersion selectedVersion={faqVersionId} onSelectVersion={setFaqVersionId} />
        </Drawer>
      )}
    </>
  )
}

export default FaqsHome
