import PropTypes from 'prop-types'

const {
  shape,
  string,
  number,
  bool,
  func,
} = PropTypes

export const responseTextModalOpenType = bool
export const responseTextModalLoadingType = bool
export const responseTextModalHasErrorType = bool
export const responseTextModalOnCloseType = func
export const selectedEditResponseTextShape = shape({
  id: string,
  app: string,
  key: string,
  value: string,
  client: string,
  lang_code: string,
  version: number,
})
