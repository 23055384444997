import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Tabs,
  Typography,
  Divider,
  Row,
  Col,
  Result,
  Space,
  Button,
} from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import JsonPreview from '@core/components/JsonPreview'

const EmailPreview = ({ email }) => {
  const [activeEmailPreviewTab, setActiveEmailPreviewTab] = useState('compare')
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    setIsCopied(false)
  }, [email])

  if (!email) {
    return (
      <Result
        status='warning'
        title='Email items malformed or not a correct data type'
      />
    )
  }

  return (
    <Tabs activeKey={activeEmailPreviewTab} onChange={setActiveEmailPreviewTab}>
      <Tabs.TabPane key='compare' tab='Compare Pre-processed'>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Space align='baseline'>
              <Typography.Title level={4}>Raw</Typography.Title>
              <Button
                type='text'
                size='small'
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(email.body)
                  setIsCopied(true)
                }}
              >
                {isCopied && <Typography.Text underline type='secondary'>Copied</Typography.Text>}
              </Button>
            </Space>
            <Divider />
            <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
              {email.body}
            </Typography.Paragraph>
          </Col>
          <Col span={12}>
            <Typography.Title level={4}>Pre-processed</Typography.Title>
            <Divider />
            <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
              {email.cleaned_email_body}
            </Typography.Paragraph>
          </Col>
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane key='anatomy' tab='Anatomy'>
        <JsonPreview content={JSON.stringify(email.email_anatomy, null, 2)} autoSize />
      </Tabs.TabPane>
      <Tabs.TabPane key='source' tab='Source'>
        <JsonPreview content={JSON.stringify(email, null, 2)} autoSize />
      </Tabs.TabPane>
    </Tabs>
  )
}

EmailPreview.propTypes = {
  email: PropTypes.shape({
    id: PropTypes.string,
    from_address: PropTypes.string,
    to_address: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    source: PropTypes.string,
    language: PropTypes.string,
    email_sent_at: PropTypes.string,
    record_created_at: PropTypes.string,
    cleaned_email_subject: PropTypes.string,
    cleaned_email_body: PropTypes.string,
    email_anatomy: PropTypes.shape({
      header: PropTypes.arrayOf(PropTypes.string),
      greetings: PropTypes.arrayOf(PropTypes.string),
      body: PropTypes.arrayOf(PropTypes.string),
      closing: PropTypes.arrayOf(PropTypes.string),
      ps: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
}

export default EmailPreview
