import React from 'react'
import { Form, Checkbox } from 'antd'
import TaggerSelect from '@core/components/TaggerFormItems/TaggerSelect'

const TaggerCasesFilter = () => {
  return (
    <>
      <TaggerSelect multiple />
      <Form.Item
        valuePropName='checked'
        name='has_tagger_training_sets'
      >
        <Checkbox>Tagger training set</Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName='checked'
        name='has_tagger_test_sets'
      >
        <Checkbox>Tagger test set</Checkbox>
      </Form.Item>
    </>
  )
}

export default TaggerCasesFilter
