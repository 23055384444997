import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Typography,
  Divider,
  Button,
  Alert,
  Result,
} from 'antd'
import {
  splitNewLineFieldWithDelimiter,
  splitDegradedResponsesWithDelimiter,
} from '../../../helpers'

const DeleteConfirmationModal = ({
  visible,
  selectedResponseText,
  loading,
  onClose,
  onDelete,
}) => {
  const [hasSentRequest, setHasSentRequest] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onFinish = async () => {
    setHasError(false)

    try {
      const response = await onDelete(selectedResponseText)
      if (response && response.status === 'success') {
        setHasSentRequest(true)
      }
    } catch (error) {
      setHasError(true)
    }
  }

  // technically visible is 'changed' only when the modal is either open/close
  useEffect(() => {
    setHasSentRequest(false)
    setHasError(false)
  }, [visible])

  if (!visible) return null

  return (
    <Modal
      title='Deleting Response Text'
      visible={visible}
      maskClosable={false}
      closable={!loading}
      centered
      onOk={onFinish}
      onCancel={onClose}
      destroyOnClose
      width={1024}
      footer={hasSentRequest ? null : [
        // NOTE: Weird, Back button completely ignore loading props
        // but Submit is working, even with the conditional rendering does not work
        // !loading ? (
        //   <Button
        //     key='back'
        //     onClick={onClose}
        //     disabled={loading}
        //   >
        //     Cancel
        //   </Button>
        // ) : null,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          disabled={loading}
          onClick={onFinish}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      {hasSentRequest && (
        <Result
          status='warning'
          title='Response Text successfully deleted'
          extra={(
            <Button key='back' type='primary' onClick={onClose}>
              Close
            </Button>
          )}
        />
      )}
      {!hasSentRequest && (
        <>
          <p>Are you sure deleting this response text?</p>
          <dl>
            <dt><Typography.Text strong>Key</Typography.Text></dt>
            <dd>{selectedResponseText.key}</dd>
            <dt><Typography.Text strong>Language code</Typography.Text></dt>
            <dd>{selectedResponseText.lang_code || 'N/A'}</dd>
            <dt><Typography.Text strong>Text</Typography.Text></dt>
            <dd>
              {splitDegradedResponsesWithDelimiter(selectedResponseText.value).map((variation, index, variations) => {
                return (
                  <React.Fragment key={index}>
                    <ul>
                      {splitNewLineFieldWithDelimiter(variation).map((message, messageIndex) => {
                        return (
                          <li key={messageIndex}>{message}</li>
                        )
                      })}
                    </ul>
                    {(index !== (variations.length - 1)) && <Divider />}
                  </React.Fragment>
                )
              })}
            </dd>
          </dl>
        </>
      )}
      {hasError && <Alert showIcon type='error' message='We&#39;ve encountered some problems, please try again in few minutes' />}
    </Modal>
  )
}

DeleteConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  selectedResponseText: PropTypes.shape({
    key: PropTypes.string,
    lang_code: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default DeleteConfirmationModal
