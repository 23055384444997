import React, { useState } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Spin,
  Empty,
  Divider,
  Radio,
  Alert,
  Space,
} from 'antd'
import JsonPreview from '@core/components/JsonPreview'
import { useTeaProfileLookupReducer, lookupTeaTravelerProfile } from '../../hooks/useTeaProfileLookupReducer'

const TEA_ENVIRONMENT = ['canary', 'staging', 'production']

const TEAProfileLookup = () => {
  const [form] = Form.useForm()
  const [state, teaProfileLookupDispatch] = useTeaProfileLookupReducer()
  const [flashMessage, setFlashMessage] = useState(null)
  const handleFlashMessageClose = () => { return setFlashMessage(null) }
  const onFinish = async ({ email, environment }) => {
    setFlashMessage(null)

    try {
      await lookupTeaTravelerProfile(teaProfileLookupDispatch, environment, { email })
    } catch (error) {
      if (error.status === 404) {
        setFlashMessage({ type: 'error', message: 'Traveler not found' })
      }
    }
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={6}>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            environment: TEA_ENVIRONMENT[1],
          }}
        >
          <Form.Item
            name='email'
            rules={[{ required: true, message: 'Please enter Traveler\'s email!' }]}
          >
            <Input placeholder={'Traveler\'s Email'} />
          </Form.Item>
          <Divider>
            <small><strong>Environment</strong></small>
          </Divider>
          <Form.Item name='environment'>
            <Radio.Group size='small'>
              {TEA_ENVIRONMENT.map((env, index) => {
                return (
                  <Radio.Button key={index} value={env}>
                    {env.charAt(0).toUpperCase() + env.substring(1)}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' disabled={state.loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
        {state.hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes.</Typography.Text>}
      </Col>
      <Col span={18}>
        {flashMessage && (
          <Alert
            message={flashMessage.message}
            type={flashMessage.type}
            showIcon
            closable
            afterClose={handleFlashMessageClose}
            style={{ marginBottom: 8 }}
          />
        )}
        {state.loading && <Spin size='large' />}
        {(!state.loading && !state.data) && <Empty description='Enter Traveler email to search profile inside TEA' />}
        {(!state.loading && state.data) && (
          <>
            <Space align='center' style={{ paddingBottom: 10 }}>
              <Typography.Text strong>Accessible Channels: </Typography.Text>
              <Typography.Text>
                {state.channel?.join(', ') || 'N/A'}
              </Typography.Text>
            </Space>
            <JsonPreview content={JSON.stringify(state.data, null, 2)} autoSize />
          </>
        )}
      </Col>
    </Row>
  )
}

export default TEAProfileLookup
