import PropTypes from 'prop-types'

const {
  bool,
  string,
  func,
} = PropTypes

export const collapsedContainerClassNameType = string
export const collapsedType = bool
export const onClickType = func
