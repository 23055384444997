import React from 'react'
import PropTypes from 'prop-types'
import {
  useHistory,
  useLocation,
} from 'react-router-dom'
import {
  Layout,
  Menu,
  Button,
  notification,
  Spin,
} from 'antd'
import { useSelector } from 'react-redux'
import { useVersionSelector } from '@core/hooks/useVersions'
import AuthActions from '@core/store/actions/Auth'
import { DefaultMenu } from '@core/components/AppMenu'
import AppLogoTitle from '@core/components/AppLogoTitle'

import style from './index.module.scss'

const { Header } = Layout

// NOTE: Cannot put a hook in here since it's not component, only to hooks reference
const onLogoutWrap = (history) => {
  return async () => {
    try {
      const logoutResponse = await AuthActions.logout()

      if (logoutResponse) {
        history.replace('/login')
      }
    } catch (error) {
    // At this point, all the data still persist
      const key = `open${Date.now()}`
      const hardLogout = () => {
        AuthActions.hardLogout()
        notification.close(key)
        history.replace('/login')
      }

      notification.error({
        message: 'Logout error',
        description: 'Try again in a few secounds, or close current tab',
        duration: 5,
        key,
        btn: (
          <Button type='primary' size='small' onClick={hardLogout}>
            Logout now
          </Button>
        ),
      })
    }
  }
}

// FIXME: a little dupe code here
const EntrywayLayout = ({ children }) => {
  const isLoggingOut = useSelector(({ core }) => { return core.auth.isLoggingOut })
  const history = useHistory()

  return (
    <Spin spinning={isLoggingOut}>
      <Layout className={style.Main}>
        <Header>
          <Menu mode='horizontal'>
            <AppLogoTitle />
            <Menu.Item danger>
              <Button type='link' danger onClick={onLogoutWrap(history)}>
                Logout
              </Button>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout className={style.Main__content}>
          {children}
        </Layout>
      </Layout>
    </Spin>
  )
}

const DefaultPrivateLayout = ({ children }) => {
  const isLoggingOut = useSelector(({ core }) => { return core.auth.isLoggingOut })
  const location = useLocation()

  return (
    <Spin spinning={isLoggingOut}>
      <Layout className={style.Main}>
        <Header>
          <AppLogoTitle />
          <DefaultMenu selectedKeys={[location.pathname]} onLogoutWrap={onLogoutWrap} />
        </Header>
        <Layout className={style.Main__content}>
          {children}
        </Layout>
      </Layout>
    </Spin>
  )
}

const PrivateLayout = ({ children }) => {
  const { current: currentVersion } = useVersionSelector()

  if (!currentVersion) return <EntrywayLayout>{children}</EntrywayLayout>

  return <DefaultPrivateLayout currentVersion={currentVersion}>{children}</DefaultPrivateLayout>
}

EntrywayLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

DefaultPrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PrivateLayout
