import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, Tree, Layout, Divider, Statistic, Card, Row, Col } from 'antd'
import 'echarts-wordcloud'
import ReactECharts from 'echarts-for-react'

import getOption from './getOption'

const { Sider, Content } = Layout

const DisplayVocabularyIntent = () => {
  const { isPending, results } = useSelector((state) => { return state.conversationAnalysis.analytics })
  const [vocabulary, setVocabulary] = useState(null)
  const [intentTree, setIntentTree] = useState(null)
  const [words, setWords] = useState(null)
  const [selectedIntent, setSelectedIntent] = useState(null)
  const [selectedWord, setSelectedWord] = useState(null)

  useEffect(() => {
    const intentTreeTemp = []
    setVocabulary(results.vocabulary)

    const tempWords = []
    // Building existing intents tree
    Object.keys(results.vocabulary).forEach((domain) => {
      intentTreeTemp.push({
        title: <Typography.Text>{domain}</Typography.Text>,
        key: [domain],
        children: domain !== 'null'
          ? Object.keys(results.vocabulary[domain])
          .map((intent) => {
            return {
              title: <Typography.Text>{intent}</Typography.Text>,
              key: [domain, intent],
            }
          })
          : null,
      })

      Object.keys(results.vocabulary[domain]).forEach(
        (intent) => {
          Object.entries(results.vocabulary[domain][intent]).forEach(([name, value]) => {
            const idx = tempWords.findIndex((item) => { return item.name === name })
            if (idx > -1) tempWords[idx].value += value
            else tempWords.push({ name, value })
          })
        },
      )
    })
    setIntentTree(intentTreeTemp)
    setWords(tempWords)
  }, [results])

  const onSelectIntent = (input) => {
    setSelectedWord(null)
    if (!input || input.length === 0) return
    const [[domain, intent]] = input
    const tempWords = []
    let total = 0

    const aggregateWordCount = (currentIntent) => {
      Object.entries(vocabulary[domain][currentIntent])
      .forEach((([name, value]) => {
        const idx = tempWords.findIndex((item) => { return item.name === name })
        if (idx > -1) tempWords[idx].value += value
        else tempWords.push({ name, value })
        total += value
      }))
    }

    if (!intent && vocabulary[domain]) {
      Object.keys(vocabulary[domain]).forEach((innerIntent) => { return aggregateWordCount(innerIntent) })
      setSelectedIntent({ name: domain, total })
    } else {
      aggregateWordCount(intent)
      setWords(tempWords)
      setSelectedIntent({ name: `${domain}/${intent}`, total })
    }
    setWords(tempWords)
  }

  return (
    <Layout>
      <Sider>
        {!isPending && vocabulary && (
          <>
            <Typography.Title level={5}>Domain/Intents</Typography.Title>
            <Tree
              showLine
              treeData={intentTree}
              defaultExpandedKeys={['0-0']}
              defaultSelectedKeys={['0-0']}
              onSelect={onSelectIntent}
            />
          </>
        )}
      </Sider>
      <Content>
        {words && (
          <ReactECharts
            option={getOption(selectedIntent, words)}
            onEvents={{ click: (e) => { return setSelectedWord(e.data) } }}
          />
        )}
        <Divider />
        <Row gutter={16}>
          <Col span={8}>
            <Card><Statistic title='Total Words' value={results.total} /></Card>
          </Col>
          {
            selectedIntent && (
              <Col span={8}>
                <Card><Statistic title={selectedIntent.name} value={(selectedIntent.total / results.total) * 100} suffix='%' precision={2} /></Card>
              </Col>
            )
          }
          {
            selectedWord && (
              <Col span={8}>
                <Card><Statistic title={selectedWord.name} value={(selectedWord.value / results.total) * 100} suffix='%' precision={2} /></Card>
              </Col>
            )
          }
        </Row>
      </Content>
    </Layout>
  )
}

export default DisplayVocabularyIntent
