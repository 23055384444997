import { useState, useEffect } from 'react'

const useTimeoutAlert = (init = null) => {
  const [showAlert, setShowAlert] = useState(init)

  useEffect(() => {
    let timeout

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(null)
      }, 3000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [showAlert])

  return [showAlert, setShowAlert]
}

export default useTimeoutAlert
