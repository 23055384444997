import StoreUtils from '@core/utils/store'

const RIGHT_PANEL = {
  CLOSE: 'RIGHT_PANEL/CLOSE',
}

const SENTENCE = {
  FETCH: StoreUtils.createActionStatus('SENTENCE/FETCH'),
  ADD_NEW: StoreUtils.createActionStatus('SENTENCE/ADD_NEW'),
  BULK_MODE: {
    APPLY_TEST: StoreUtils.createActionStatus('SENTENCE/BULK_MODE/APPLY_TEST'),
    APPLY_TRAINING: StoreUtils.createActionStatus('SENTENCE/BULK_MODE/APPLY_TRAINING'),
    ADD_NEW: StoreUtils.createActionStatus('SENTENCE/BULK_MODE/ADD_NEW'),
    TOGGLE_UNRECOGNIZED: StoreUtils.createActionStatus('SENTENCE/BULK_MODE/TOGGLE_UNRECOGNIZED'),
  },
  DELETE: StoreUtils.createActionStatus('SENTENCE/DELETE'),
  EDIT: StoreUtils.createActionStatus('SENTENCE/EDIT'),
  RESET: 'SENTENCE/RESET',
  SELECTED: 'SENTENCE/SELECTED',
}

const SENTENCE_LIST_UI = {
  TOGGLE_BULK_MODE: 'SENTENCE_LIST_UI/TOGGLE_BULK_MODE',
  TOGGLE_NO_GENERALIZED: 'SENTENCE_LIST_UI/TOGGLE_NO_GENERALIZED',
  SET_SENTENCE_MODE: 'SENTENCE_LIST_UI/SET_SENTENCE_MODE',
}

const SENTENCE_LIST_CHECK = {
  TOGGLE: 'SENTENCE_LIST_CHECK/TOGGLE',
}

const EDIT_SENTENCE_MODAL_UI = {
  OPEN: 'EDIT_SENTENCE_MODAL_UI/OPEN',
  CLOSE: 'EDIT_SENTENCE_MODAL_UI/CLOSE',
}

const BULK_OPERATION_MODAL_UI = {
  OPEN: 'BULK_OPERATION_MODAL_UI/OPEN',
  CLOSE: 'BULK_OPERATION_MODAL_UI/CLOSE',
}

const BULK_OPERATION_MODAL_DATA = {
  DISMISS: 'BULK_OPERATION_MODAL_DATA/DISMISS',
  SELECT: 'BULK_OPERATION_MODAL_DATA/SELECT',
}

const SENTENCE_NOTES = {
  FETCH: StoreUtils.createActionStatus('SENTENCE_NOTES/FETCH'),
  ADD_NEW: StoreUtils.createActionStatus('SENTENCE_NOTES/ADD_NEW'),
  EDIT: StoreUtils.createActionStatus('SENTENCE_NOTES/EDIT'),
  DELETE: StoreUtils.createActionStatus('SENTENCE_NOTES/DELETE'),
  SELECTED: 'SENTENCE_NOTES/SELECTED',
  RESET: 'SENTENCE_NOTES/RESET',
}

const DYNAMIC_ENTITIES = {
  EDIT: StoreUtils.createActionStatus('DYNAMIC_ENTITIES/EDIT'),
  SELECTED: 'DYNAMIC_ENTITIES/SELECTED',
  RESET: 'DYNAMIC_ENTITIES/RESET',
}

const SELECTED_SENTENCE = {
  FETCH: StoreUtils.createActionStatus('SELECTED_SENTENCE/FETCH'),
  EDIT: StoreUtils.createActionStatus('SELECTED_SENTENCE/EDIT'),
  TEST_CASES: {
    FETCH: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/FETCH'),
    ADD_DOMAIN: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/ADD_DOMAIN'),
    ADD_INTENT: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/ADD_INTENT'),
    ADD_TAGGER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/ADD_TAGGER'),
    ADD_EXTRACTOR: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/ADD_EXTRACTOR'),
    ADD_GENERALIZER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/ADD_GENERALIZER'),

    UPDATE_DOMAIN: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/UPDATE_DOMAIN'),
    UPDATE_INTENT: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/UPDATE_INTENT'),
    UPDATE_TAGGER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/UPDATE_TAGGER'),
    UPDATE_EXTRACTOR: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/UPDATE_EXTRACTOR'),
    UPDATE_GENERALIZER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/UPDATE_GENERALIZER'),

    DELETE_DOMAIN: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/DELETE_DOMAIN'),
    DELETE_INTENT: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/DELETE_INTENT'),
    DELETE_TAGGER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/DELETE_TAGGER'),
    DELETE_EXTRACTOR: StoreUtils.createActionStatus('SELECTED_SENTENCE/TEST_CASES/DELETE_EXTRACTOR'),
  },
  TRAINING_CASES: {
    FETCH: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/FETCH'),
    ADD_DOMAIN: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/ADD_DOMAIN'),
    ADD_INTENT: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/ADD_INTENT'),
    ADD_TAGGER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/ADD_TAGGER'),
    ADD_GENERALIZER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/ADD_GENERALIZER'),
    UPDATE_DOMAIN: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/UPDATE_DOMAIN'),
    UPDATE_INTENT: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/UPDATE_INTENT'),
    UPDATE_TAGGER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/UPDATE_TAGGER'),
    UPDATE_GENERALIZER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/UPDATE_GENERALIZER'),

    DELETE_DOMAIN: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/DELETE_DOMAIN'),
    DELETE_INTENT: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/DELETE_INTENT'),
    DELETE_TAGGER: StoreUtils.createActionStatus('SELECTED_SENTENCE/TRAINING_CASES/DELETE_TAGGER'),
  },
}

const INTENT_TREE = {
  FETCH: StoreUtils.createActionStatus('INTENT_TREE/FETCH'),
}

const DRAWER = {
  TOGGLE_VISIBILITY: 'DRAWER/TOGGLE_VISIBILITY',
}

export {
  SENTENCE,
  INTENT_TREE,
  SENTENCE_LIST_UI,
  SENTENCE_LIST_CHECK,
  SELECTED_SENTENCE,
  SENTENCE_NOTES,
  DYNAMIC_ENTITIES,
  RIGHT_PANEL,
  EDIT_SENTENCE_MODAL_UI,
  BULK_OPERATION_MODAL_UI,
  BULK_OPERATION_MODAL_DATA,
  DRAWER,
}
