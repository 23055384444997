import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Button,
  Divider,
  Form,
  Input,
} from 'antd'
import classnames from 'classnames'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import style from './index.module.scss'
import CapIdCountryOfEmploymentSelect from '../../../components/CapIdCountryOfEmploymentSelect'

const { Sider } = Layout

const LeftSider = ({ initialFilterValues, onSearch, availableCapIdsAndCountryOfEmployment }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()
  const onFinish = (values) => {
    onSearch(values)
  }

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Deployment Group Query</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={initialFilterValues}
        >
          <Form.Item
            style={{ marginBottom: 10 }}
            name='nameOrDescription'
          >
            <Input placeholder='Search by name, description' />
          </Form.Item>
          <Divider>
            <small><strong>By CAP IDs</strong></small>
            <br />
            <small><strong>and Countries of Employment</strong></small>
          </Divider>
          <CapIdCountryOfEmploymentSelect data={availableCapIdsAndCountryOfEmployment} />
          <Button
            className={style.left_sider__search_button}
            type='primary'
            htmlType='submit'
            block
          >
            Search
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSearch: PropTypes.func.isRequired,
  initialFilterValues: PropTypes.shape({ }).isRequired,
  availableCapIdsAndCountryOfEmployment: PropTypes.shape({
    availableCapIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableCountryOfEmployment: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

export default LeftSider
