import PropTypes from 'prop-types'

const {
  array,
  number,
  func,
  bool,
} = PropTypes

export const isLoadingType = bool
export const totalResultsType = number
export const selectedGeneralizedFilterTagsType = array
export const removeGeneralizedFilterTagType = func
export const setSentenceViewModeType = func
export const showOnlySentenceWithNoGeneralizedTagsType = func
export const toggleBulkModeType = func
export const clearTableSelectionType = func
