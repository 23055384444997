import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import StorageUtils from '@core/utils/storage'

export const RedirectComponent = ({ location }) => {
  return (
    <Redirect
      to={{ pathname: '/versions', state: { from: location } }}
    />
  )
}

RedirectComponent.defaultProps = {
  location: {},
}

RedirectComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export const PrivateRoute = ({ component: SelectedComponent, ...rest }) => {
  const renderProps = (props) => {
    return (StorageUtils.getItem('versions') ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
      <SelectedComponent {...props} />
    ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
      <RedirectComponent {...props} />
    ))
  }
  return <Route {...rest} render={renderProps} />
}

PrivateRoute.propTypes = {
  component: PropTypes.node.isRequired,
}

export const PermissionRoute = ({ children, moduleProps, featurePath }) => {
  if (!featurePath) throw new Error('PermissionRoute:: missing featurePath lookup')
  const featureAllowed = usePermittedFeaturesLookup(featurePath)

  // NOTE: Feels like a terrible fixes for the route
  if (featureAllowed && children) {
    // NOTE: Cannot use expression { children } because it was meant for 'one' React element
    // The only way to render it as a children without using Fragment and old array trick ([<elem.../>])
    // is React.Children.toArray
    return React.Children.toArray(children)
  }

  if (featureAllowed && !children) {
    return (<Route {...moduleProps} />)
  }

  return (
    <Route path={moduleProps.path}>
      <Redirect to='/utterances/sentences' />
    </Route>
  )
}

PermissionRoute.defaultProps = {
  children: null,
  moduleProps: null, // For deep nested level, deep nested use children style
}

PermissionRoute.propTypes = {
  children: PropTypes.node,
  moduleProps: PropTypes.shape({
    path: PropTypes.string,
    // NOTE: This is func because is pass deep down to the react-router-dom with a feature checked again
    component: PropTypes.func,
  }),
  featurePath: PropTypes.string.isRequired,
}
