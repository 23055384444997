import React from 'react'
import {
  Form,
  Input,
  Select,
  Typography,
  Tooltip,
} from 'antd'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import { formInstanceType, sentenceType } from '../../../types/form'

const { Title } = Typography

const DomainForm = ({ form, sentence }) => {
  const { domainList } = useDomainIntentSelector()

  return (
    <Form
      form={form}
      layout='vertical'
      name='form__test_domain'
      initialValues={{
        test_domain: null,
      }}
    >
      <Title level={4}>{sentence}</Title>
      <Tooltip color='orange' title='If you select a null domain, this will automatically remove the expected intent'>
        <Form.Item
          name='test_domain_id'
          label='Test Domain'
          rules={[
            {
              required: true,
              message: 'Please selected test domain',
            },
          ]}
        >
          <Select
            placeholder='Select a test domain'
            allowClear
          >
            <Select.Option value={-1} key={domainList.length}>NULL</Select.Option>
            <Select.OptGroup label='Domains'>
              {domainList.map((domain, index) => {
                return (
                  <Select.Option value={domain.id} key={index}>{domain.name}</Select.Option>
                )
              })}
            </Select.OptGroup>
          </Select>
        </Form.Item>
      </Tooltip>

      <Form.Item name='note' label='Note'>
        <Input.TextArea showCount maxLength={255} style={{ resize: 'vertical' }} />
      </Form.Item>
    </Form>
  )
}

DomainForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
}

export default DomainForm
