import React from 'react'
import { PropTypes } from 'prop-types'
import { Col, Row, Descriptions } from 'antd'
import dayjs from 'dayjs'

const BeforeNullIntentList = ({ record }) => {
  const {
    conversation_id,
    traveler_state_key,
    hermes_version,
    domain,
    intent,
    generalized,
    traveler_created_at,
    i18n_version,
    bot_state_key,
    bot_response_key,
    bot_created_at,
  } = record
  return (
    <Col>
      <Row>
        <Descriptions bordered>
          <Descriptions.Item label='Conversation ID' span={12}>{conversation_id}</Descriptions.Item>
        </Descriptions>
      </Row>
      <Row>
        <Col justify='center' span={12}>
          <Descriptions title='Bot' bordered>
            <Descriptions.Item label='Internationalize Version' span={12}>{i18n_version}</Descriptions.Item>
            <Descriptions.Item label='State Key' span={12}>{bot_state_key}</Descriptions.Item>
            <Descriptions.Item label='Response Key' span={12}>{bot_response_key}</Descriptions.Item>
            <Descriptions.Item label='Created At' span={12}>{dayjs(bot_created_at).format('MMM/DD/YYYY HH:mm')}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col justify='center' span={12}>
          <Descriptions title='Traveler' bordered>
            <Descriptions.Item label='Hermes Version' span={12}>{hermes_version}</Descriptions.Item>
            <Descriptions.Item label='State Key' span={12}>{traveler_state_key}</Descriptions.Item>
            <Descriptions.Item label='Generalized' span={12}>{generalized}</Descriptions.Item>
            <Descriptions.Item label='Domain/Intent' span={12}>{`${domain}/${intent}`}</Descriptions.Item>
            <Descriptions.Item label='Created At' span={12}>{dayjs(traveler_created_at).format('MMM/DD/YYYY HH:mm')}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Col>
  )
}

BeforeNullIntentList.propTypes = {
  record: PropTypes.shape({
    conversation_id: PropTypes.bool.isRequired,
    traveler_state_key: PropTypes.bool.isRequired,
    hermes_version: PropTypes.bool.isRequired,
    domain: PropTypes.bool.isRequired,
    intent: PropTypes.bool.isRequired,
    generalized: PropTypes.bool.isRequired,
    traveler_created_at: PropTypes.bool.isRequired,
    i18n_version: PropTypes.bool.isRequired,
    bot_state_key: PropTypes.bool.isRequired,
    bot_response_key: PropTypes.bool.isRequired,
    bot_created_at: PropTypes.bool.isRequired,
  }).isRequired,
}

export default BeforeNullIntentList
