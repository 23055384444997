import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Button, Space, Typography, Badge } from 'antd'
import { SelectOutlined } from '@ant-design/icons'

import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'

import style from './style.module.scss'

const LINEBREAK_SHOWMORE_THRESHOLD = 15

const mapMatchAllExecElementToIndexPosition = (matchRegexExec) => {
  /*
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#return_value
    [
      ...match(es)...,
      // there are some special "named" properties of its exec() state here
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#using_exec
      index: Number,
      input: String,
      groups: null-prototype object
    ]
  */
  return matchRegexExec.index
}

const EmailContent = ({
  analyzeEmail,
  showingMode,
  isNewEmailOrMissingStatus,
  content,
}) => {
  const {
    subject,
    body,
    cleanedEmailSubject,
    cleanedEmailBody,
  } = content
  const lineBreaksIndexPosition = {
    raw: Array.from(body.matchAll(/\n/g), mapMatchAllExecElementToIndexPosition),
    cleaned: Array.from(cleanedEmailBody.matchAll(/\n/g), mapMatchAllExecElementToIndexPosition),
  }
  const mapBodyContent = { raw: body, cleaned: cleanedEmailBody }
  const mapSubjectContent = { raw: subject, cleaned: cleanedEmailSubject }

  const [expanded, setExpanded] = useState(false)
  const [showExpandableButton, setShowExpandableButton] = useState(lineBreaksIndexPosition[showingMode].length > LINEBREAK_SHOWMORE_THRESHOLD)
  const elementRef = useRef(null)

  // #matchAll() is iterators
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/matchAll#regexp.prototype.exec_and_matchall
  // otherwise concerning the internal regex index ===> use "indexOf + looping"

  // usually with the "Caution..." placeholder is already 169 characters

  const getEmailBody = () => {
    // if line break count is less than or equal 15,
    // don't bother check OR it's expanding, don't care type, just show
    if (lineBreaksIndexPosition[showingMode].length <= 15 || expanded) {
      return mapBodyContent[showingMode]
    }

    return mapBodyContent[showingMode].slice(0, lineBreaksIndexPosition[showingMode][LINEBREAK_SHOWMORE_THRESHOLD - 1])
  }

  const toggleExpand = () => {
    setExpanded((prevState) => { return !prevState })
  }

  useEffect(() => {
    setExpanded(false) // reset mode whenever user change show raw or cleaned

    const element = elementRef.current
    const elementHeight = element.offsetHeight
    const computedStyle = window.getComputedStyle(element)
    // https://stackoverflow.com/a/783936
    const lineCount = elementHeight / parseFloat(computedStyle.lineHeight).toFixed(2)

    if (lineCount < LINEBREAK_SHOWMORE_THRESHOLD) {
      setShowExpandableButton(false)
    } else {
      element.classList.add(style.Expandable)
      setShowExpandableButton(true)
    }
  }, [showingMode])

  return (
    <div className={style.EmailContent}>
      <Space>
        {isNewEmailOrMissingStatus && <Badge placement='start' dot />}
        <Typography.Text className={style.Subject} strong>
          {mapSubjectContent[showingMode]}
        </Typography.Text>
        <PermittedFeatureCheck featurePath='email.analysis.viewTab'>
          <Button size='small' type='text' icon={<SelectOutlined />} onClick={analyzeEmail} />
        </PermittedFeatureCheck>
      </Space>
      <div
        ref={elementRef}
        className={classnames(
          style.Body,
          { [`${style.Expandable}`]: showExpandableButton },
          { [`${style.Expanded}`]: expanded },
        )}
      >
        <p>
          {getEmailBody()}
        </p>
      </div>
      {showExpandableButton && (
        <Button
          className={style.ExpandContent}
          size='small'
          type='link'
          onClick={toggleExpand}
        >
          {`${expanded ? 'See less' : 'See more'}`}
        </Button>
      )}
    </div>
  )
}

EmailContent.propTypes = {
  analyzeEmail: PropTypes.func.isRequired,
  showingMode: PropTypes.oneOf(['raw', 'cleaned']).isRequired,
  isNewEmailOrMissingStatus: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    subject: PropTypes.string,
    body: PropTypes.string,
    cleanedEmailSubject: PropTypes.string,
    cleanedEmailBody: PropTypes.string,
  }).isRequired,
}

export default EmailContent
