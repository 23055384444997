import { INTENT_TREE } from '../constants'

const INITIAL_STATE = {
  intentTree: [],
  intentCascader: [],
  domainList: [],
  intentList: [],
  rawLookup: {
    lookup: {},
    domains: {},
    intents: {},
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INTENT_TREE.INTENT.RENAME.SUCCESS:
    case INTENT_TREE.INTENT.ADD.SUCCESS:
    case INTENT_TREE.INTENT.DELETE.SUCCESS:
    case INTENT_TREE.DOMAIN.RENAME.SUCCESS:
    case INTENT_TREE.DOMAIN.ADD.SUCCESS:
    case INTENT_TREE.DOMAIN.DELETE.SUCCESS:
    case INTENT_TREE.FETCH.SUCCESS: return { ...state, ...action.payload }
    case INTENT_TREE.FETCH.RESET: return { ...INITIAL_STATE }
    case INTENT_TREE.FETCH.PENDING:
    case INTENT_TREE.INTENT.RENAME.REQUEST:
    case INTENT_TREE.INTENT.ADD.REQUEST:
    case INTENT_TREE.DOMAIN.RENAME.REQUEST:
    case INTENT_TREE.DOMAIN.ADD.REQUEST:
    case INTENT_TREE.INTENT.RENAME.FAILED:
    case INTENT_TREE.INTENT.ADD.FAILED:
    case INTENT_TREE.DOMAIN.RENAME.FAILED:
    case INTENT_TREE.DOMAIN.ADD.FAILED:
    case INTENT_TREE.FETCH.FAILED:
    default: return state
  }
}
