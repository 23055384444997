import React from 'react'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import {
  collapsedContainerClassNameType,
  collapsedType,
  onClickType,
} from '../../../types/collapseSideBarButton'

const CollapsedButton = ({
  collapsedContainerClassName,
  collapsed,
  onClick,
}) => {
  return (
    <div className={collapsedContainerClassName}>
      <Button
        size='small'
        shape='circle'
        icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
        onClick={onClick}
      />
    </div>
  )
}

CollapsedButton.propTypes = {
  collapsedContainerClassName: collapsedContainerClassNameType.isRequired,
  collapsed: collapsedType.isRequired,
  onClick: onClickType.isRequired,
}

export default CollapsedButton
