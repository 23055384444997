import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import StorageUtils from '@core/utils/storage'
import Login from './Login'
import RedirectOkta from './Redirect'
import PublicPagesLayout from './_layouts'

const PublicPages = () => {
  const history = useHistory()
  useEffect(() => {
    // NOTE: There is somekind of additional mechanism,
    // if the token expired, toss user back to public page
    // if either one of them gone, toss user back to public page
    StorageUtils.clearAll()

    // FIXME: This doesn't feel right
    const { pathname } = history.location
    if (['/login', '/okta-signin-success'].indexOf(pathname) === -1) history.replace('/login')
  }, [history])

  // For some reason Redirect overrides everything, cannot access other routes
  return (
    <PublicPagesLayout>
      <Route path='/login' component={Login} />
      <Route path='/okta-signin-success' component={RedirectOkta} />
      {/* <Redirect from='/' to='/login' exact /> */}
      {/* <Redirect to='/login' /> */}
    </PublicPagesLayout>
  )
}

export default PublicPages
