import { BULK_OPERATION_MODAL_DATA, SENTENCE } from '../constants'

const INITIAL_STATE = {
  selectedSentences: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // TODO: UtteranceTableRefactor - one time use, remove this
    case BULK_OPERATION_MODAL_DATA.SELECT: {
      return {
        ...state,
        selectedSentences: action.payload.selectedSentences,
      }
    }
    case SENTENCE.BULK_MODE.APPLY_TRAINING.SUCCESS:
    case SENTENCE.BULK_MODE.APPLY_TEST.SUCCESS:
    case SENTENCE.BULK_MODE.TOGGLE_UNRECOGNIZED.SUCCESS:
    case BULK_OPERATION_MODAL_DATA.DISMISS:
    case SENTENCE.DELETE.SUCCESS: {
      return {
        ...INITIAL_STATE,
      }
    }
    default: return state
  }
}
