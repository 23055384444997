/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {
  Row,
  Col,
  List,
  Typography,
  Form,
  Tabs,
  notification,
} from 'antd'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  caseType as casePropType,
  dataSourceType,
  renderAddNewCaseFormType,
} from '../../types/caseList'
import TrainingSetsActions from '../../store/actions/TrainingSets'
import FormSubmissionModal from '../FormSubmissionModal'
import AddNewCaseDropdownButton from '../AddNewCaseDropDownButton'
import UpdateTrainingCase from '../UpdateTrainingCase'
import ListItem from './ListItem'

import style from './index.module.scss'

const { TabPane } = Tabs
const { Title } = Typography
const handleFormError = ({ setConfirmLoading, setHasError, error }) => {
  console.info('Validate Failed:', error)
  setConfirmLoading(false)

  // NOTE: Must not be the validation error
  if (error.status && [500, 404, 400].indexOf(error.status) !== -1) setHasError(true)
}

const TrainingCaseList = ({
  caseType,
  dataSource = [],
  renderAddNewCaseForm = () => { return null },
}) => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [updateTrainingCaseModalVisible, setUpdateTrainingCaseModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState(caseType)
  const [selectedCase, setSelectedCase] = useState('')
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [hasError, setHasError] = useState(false)
  const [form] = Form.useForm()
  const { sentence_id: sentenceId } = useParams()
  const { data } = useSelector((state) => { return state.data.selectedSentence })
  const sentence = data && data.text ? data.text : ''

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasError(false)
    setSelectedCase('')
  }

  const onEditTrainingCase = (item, type) => {
    const localItem = item
    const localType = type
    return () => {
      setSelectedItem({ ...localItem, type: localType })
      setUpdateTrainingCaseModalVisible(true)
    }
  }

  const formSubmission = {
    domain: async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['train_domain_id'])
        if (values) {
          response = await TrainingSetsActions.addDomainTrainingSet(sentenceId, values)
          if (response) {
            form.resetFields()
            setConfirmLoading(true)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
    intent: async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['domain_intent'])
        if (values) {
          response = await TrainingSetsActions.addIntentTrainingSet(sentenceId, values)
          if (response) {
            form.resetFields()
            setConfirmLoading(true)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
    tagger: async () => {
      if (hasError) setHasError(false)

      setConfirmLoading(true)
      try {
        let response = null
        const values = await form.validateFields(['tagger_type', 'phrase', 'desired_result'])

        if (values) {
          response = await TrainingSetsActions.addTaggerTrainingSet(sentenceId, values)
          if (response) {
            form.resetFields()
            setConfirmLoading(true)
            closeModal()
          }
        }
      } catch (error) {
        handleFormError({ setConfirmLoading, setHasError, error })
      }
    },
  }

  const onDelete = {
    domain: async (caseId) => {
      try {
        const response = await TrainingSetsActions.deleteDomainTrainingCase(caseId)
        if (response && response.data) {
          notification.info({
            message: 'Training domain has been deleted',
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Training domain deletion error',
          description: error.message,
          duration: 5,
        })
      }
    },
    intent: async (caseId) => {
      try {
        const response = await TrainingSetsActions.deleteIntentTrainingCase(caseId)
        if (response && response.data) {
          notification.info({
            message: 'Training intent has been deleted',
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Training intent deletion error',
          description: error.message,
          duration: 5,
        })
      }
    },
    tagger: async (caseIds) => {
      try {
        const response = await TrainingSetsActions.deleteTaggerTrainingCase(caseIds)
        if (response && response.data) {
          notification.info({
            message: 'Training tagger has been deleted',
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Training tagger deletion error',
          description: error.message,
          duration: 5,
        })
      }
    },
  }

  const listProps = {
    footer: <div className={style.metadata__ListFooter}>End of the list</div>,
    bordered: true,
    position: 'true',
    className: style.metadata__List,
    size: 'small',
    renderItem: (item) => {
      return (
        <ListItem
          item={item}
          onEditTrainingCase={onEditTrainingCase}
          onConfirmDelete={(trainingCaseType) => {
            onDelete[trainingCaseType](item.id)
          }}
        />
      )
    },
  }

  return (
    <>
      <Row justify='space-between'>
        <Col span={12}>
          <Title level={4}>{`${caseType} case`}</Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <AddNewCaseDropdownButton
            setModalVisibility={setModalVisibility}
            setModalTitle={setModalTitle}
            setSelectedCase={setSelectedCase}
            caseType={caseType}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey='1' type='card' size='large'>
        <TabPane tab='Domain' key='1'>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <List {...listProps} dataSource={dataSource.domain} />
        </TabPane>
        <TabPane tab='Intent' key='2'>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <List {...listProps} dataSource={dataSource.intent} />
        </TabPane>
        <TabPane tab='Tagger' key='3'>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <List {...listProps} dataSource={dataSource.tagger} />
        </TabPane>
      </Tabs>
      <FormSubmissionModal
        visible={modalVisibility}
        title={modalTitle}
        onOk={selectedCase ? formSubmission[selectedCase.toLowerCase()] : () => {}}
        onCancel={closeModal}
        loading={confirmLoading}
        closable
        hasError={hasError}
      >
        {modalVisibility && renderAddNewCaseForm(selectedCase.toLowerCase(), form, sentence)}
      </FormSubmissionModal>
      {updateTrainingCaseModalVisible && (
        <UpdateTrainingCase
          modalVisibility={updateTrainingCaseModalVisible}
          setModalVisibility={setUpdateTrainingCaseModalVisible}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        />
      )}
    </>
  )
}

TrainingCaseList.propTypes = {
  caseType: casePropType,
  dataSource: dataSourceType,
  renderAddNewCaseForm: renderAddNewCaseFormType,
}

TrainingCaseList.defaultProps = {
  caseType: 'Training',
  dataSource: {
    domain: [],
    intent: [],
    tagger: [],
    generalizer: [],
  },
  renderAddNewCaseForm: () => { return null },
}

export default TrainingCaseList
