import { sortTextOrNull } from '@core/helpers'

const recentlyUpdatedStorageName = 'LOUISE--EmailAutomations_bulkModeChangeHistory'
const INITIAL_STATE = {
  development: { datasource: 'development', testCases: [], trainingCases: [] },
  canary: { datasource: 'canary', testCases: [], trainingCases: [] },
  staging: { datasource: 'staging', testCases: [], trainingCases: [] },
  production: { datasource: 'production', testCases: [], trainingCases: [] },
}

export const BulkModeStorageInterface = {
  createNewRecentlyUpdated: () => {
    window.localStorage.setItem(recentlyUpdatedStorageName, JSON.stringify(INITIAL_STATE))
  },
  retrieveRecentlyChanged: () => {
    const recentlyChanged = window.localStorage.getItem(recentlyUpdatedStorageName)

    if (recentlyChanged) return JSON.parse(recentlyChanged)

    BulkModeStorageInterface.createNewRecentlyUpdated()

    return JSON.parse(window.localStorage.getItem(recentlyUpdatedStorageName))
  },
  updateWithNewItem: (newItem, { datasource, caseType }) => {
    if (caseType !== 'testCases' && caseType !== 'trainingCases') {
      console.error('BulkModeStorageInterface::updateWithNewItem - unmatch caseType, expect either "testCases" or "trainingCases"')
      return
    }
    let state = JSON.parse(window.localStorage.getItem(recentlyUpdatedStorageName))

    if (!state) {
      BulkModeStorageInterface.createNewRecentlyUpdated()
      state = JSON.parse(window.localStorage.getItem(recentlyUpdatedStorageName))
    }

    const newState = { ...state }
    newState[datasource][caseType] = newState[datasource][caseType].concat(newItem)

    window.localStorage.setItem(recentlyUpdatedStorageName, JSON.stringify(newState))
  },
  purgeRecentlyUpdateLog: () => {
    window.localStorage.setItem(recentlyUpdatedStorageName, JSON.stringify(INITIAL_STATE))
  },
}

export const sortForIntentSelectOptions = (a, b) => {
  const types = [typeof a.label, typeof b.label]
  if (types[0] === 'string' && types[1] === 'string') {
    return a.label.localeCompare(b.label)
  }
  return !!a - !!b // cast to boolean to get -1 or 1
}

export const sortIntentTrainingCaseApprovalStatus = (intentTrainingCases) => {
  /*
    We have to sort by status
    The training cases should be ordered by status in this order,

    Submitted > Approved > Rejected

    then for the training cases with the same status, the cases should be ordered alphabetically ASC

    For example, this set of training cases
    - quote with status Rejected
    - invoice with status Rejected
    - not-itinerary with status Approved
    - not-visa with status Submitted
    - change with status Submitted
    will be ordered as:
    - change with status Submitted
    - not-visa with status Submitted
    - not-itinerary with status Approved
    - invoice with status Rejected
    - quote with status Rejected
  */

  /*
    Extra handling: will set intent status to 'UNAVAILABLE' when there is no status.
    And it must not show button
  */

  const byStatus = {
    submitted: [],
    approved: [],
    rejected: [],
  }

  // in case there is missing status
  const outOfCategory = []

  // categorize
  intentTrainingCases.forEach((intentTrainingCase) => {
    if (intentTrainingCase.approval_status) byStatus[intentTrainingCase.approval_status.toLowerCase()].push(intentTrainingCase)
    else outOfCategory.push({ ...intentTrainingCase, status: 'UNAVAILABLE' })
  })

  // internal a-z sort
  return [
    ...byStatus.submitted.sort((a, b) => { return sortTextOrNull(a.name, b.name) }),
    ...byStatus.approved.sort((a, b) => { return sortTextOrNull(a.name, b.name) }),
    ...byStatus.rejected.sort((a, b) => { return sortTextOrNull(a.name, b.name) }),
    // this SHOULD not be possible after migration...
    ...outOfCategory.sort((a, b) => { return sortTextOrNull(a.name, b.name) }),
  ]
}
