import React, { useEffect, useRef, useCallback } from 'react'
import {
  Form,
  Input,
  Button,
  Spin,
  Modal,
  Col,
  Row,
  Alert,
  Badge,
  Checkbox,
} from 'antd'

import { useSelector } from 'react-redux'
import JsonPreview from '@core/components/JsonPreview'
import DynamicDataFieldSet from './components/DynamicDataFieldSet'
import Analyze from './store/actions/sentenceAnalysis'

// NOTE: maybe use _.debounce?
// https://gist.githubusercontent.com/treyhuffine/d628c0cd2e7d25f829159e08c29e92c0/raw/93c25650e395b71ea91086462896e90598dab8a6/debounce-description.js
const debounce = (fn, wait) => {
  let timeout
  return (...args) => {
    const debouncedFn = () => {
      // null timeout to indicate the debounce ended
      timeout = null
      fn(...args)
    }
    clearTimeout(timeout)

    // calls  when it is already passed this 'wait' period
    timeout = setTimeout(debouncedFn, wait)
  }
}

const AffixButton = () => {
  const openSentenceAnalysis = () => { return Analyze.openModal() }
  return (
    <div className='affix--sentence_analysis'>
      <Button type='primary' onClick={openSentenceAnalysis} size='large' shape='round'>
        Utterance Analysis
      </Button>
    </div>
  )
}

const SentenceAnalysis = () => {
  const {
    sentenceAnalysis: {
      modalVisibility,
      initialSentence,
      data,
      hasData,
      extractInformationIsPending,
      extractInformationResults,
      extractInformationHeaders,
      addToTestSetIsPending,
      addToTestSetHasError,
      addToTestSetSuccess,
    },
  } = useSelector((state) => { return state.sentenceAnalysis })

  const formRef = useRef(null)

  const analyze = useCallback((item = {}) => {
    if (initialSentence && formRef.current && initialSentence !== formRef.current.getFieldValue('sentence')) {
      formRef.current.setFieldsValue({
        sentence: initialSentence,
      })
      Analyze.extractInformation(initialSentence, item)
    }
  }, [initialSentence])

  useEffect(() => {
    analyze(data)
  }, [initialSentence, data, analyze])

  useEffect(() => {
    const logoAreaWidth = document.getElementsByClassName('Louise--LogoTitle')[0].offsetWidth
    const buttonWidth = document.getElementsByClassName('affix--sentence_analysis')[0].offsetWidth
    // document.querySelector returns HTMLCollection, "array like"
    const menuItemSize = [...document.querySelector('.louise .ant-layout-header ul').children].reduce((prev, next) => { return prev + next.offsetWidth }, 0)
    const totalMenuOccupied = menuItemSize + logoAreaWidth + buttonWidth

    const debouncedResize = debounce(() => {
      if (!document.querySelector('.affix--sentence_analysis')) return
      if ((window.innerWidth < totalMenuOccupied)) {
        document.querySelector('.affix--sentence_analysis').style = null
        document.querySelector('.affix--sentence_analysis').style.top = '70px'
      } else {
        // just use default style
        document.querySelector('.affix--sentence_analysis').style = null
      }
    }, 200)

    debouncedResize()
    window.addEventListener('resize', debouncedResize)

    return () => {
      window.removeEventListener('resize', debouncedResize)
    }
  }, [])

  const onSubmit = (value) => {
    const { showTags, onlyGeneralize } = value
    Analyze.extractInformation(value.sentence, data, { showTags, onlyGeneralize })
  }

  const onCancel = () => {
    Analyze.dismissModal()
  }

  const addToTestSet = () => {
    Analyze.addToTestSet(extractInformationResults.original, extractInformationResults.intent)
  }

  return (
    <Modal
      title='Analyze Sentence'
      visible={modalVisibility}
      onCancel={onCancel}
      width={1000}
      centered
      footer={null}
    >
      {addToTestSetHasError && <Alert message='Something wrong' type='error' style={{ marginBottom: 16 }} />}
      {addToTestSetSuccess && <Alert message='The test case was added.' type='success' style={{ marginBottom: 16 }} /> }
      <Form
        name='Sentence Analysis'
        onFinish={onSubmit}
        ref={formRef}
      >
        <Row justify='space-around' align='middle'>
          <Col span={20}>
            <Form.Item
              label='Sentence'
              name='sentence'
              rules={[{ required: true, message: 'Please enter sentence to analyze' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item span={8}>
              <Button type='default' htmlType='submit'>Send</Button>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' align='middle' gutter='10'>
          <Col>
            <Form.Item valuePropName='checked' label='Show tags' name='showTags' defaultChecked={false}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item valuePropName='checked' label='Only Generalize' name='onlyGeneralize' defaultChecked={false}>
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' align='middle'>
          <Badge color={hasData ? 'green' : 'red'} text='Has Dynamic Entities' />
        </Row>
        <Row justify='start' align='middle'>
          <Col>
            <DynamicDataFieldSet />
          </Col>
        </Row>
      </Form>
      {extractInformationIsPending && <Spin />}
      {!extractInformationIsPending && extractInformationHeaders.responseTime && (
        <>
          <Row justify='space-between'>
            <Col>{`${extractInformationHeaders.responseTime} ms`}</Col>
            <Col>{extractInformationHeaders.transactionToken}</Col>
          </Row>
          <JsonPreview content={JSON.stringify(extractInformationResults, null, 2)} autoSize />
          <Button onClick={addToTestSet} disabled={addToTestSetIsPending || addToTestSetSuccess}>
            Apply to Domain/Intent Test case
          </Button>
        </>
      )}
    </Modal>
  )
}

const SentenceAnalysisModule = () => {
  return (
    <>
      <SentenceAnalysis />
      <AffixButton />
    </>
  )
}

export default SentenceAnalysisModule
