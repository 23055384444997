import {
  useState,
  useReducer,
  useRef,
  useEffect,
} from 'react'
import clonedeep from 'lodash.clonedeep'
import StoreUtils from '@core/utils/store'
import { reHashIndex } from '@core/helpers'
import Api from '../api'
import { getFaqCategories, getChoiceCategories } from '../query'

const INITIAL_STATE = {
  loading: false,
  hasError: false,
  error: null,
  data: {
    items: [],
    hash: {},
  },
  meta: {
    page: 1,
    pageCount: 0,
    perPage: 50,
    totalCount: 0,
  },
}

export const MANAGE_FAQS = {
  FETCH: StoreUtils.createActionStatus('MANAGE_FAQS/FETCH'),
  ADD_ONE: StoreUtils.createActionStatus('MANAGE_FAQS/ADD_ONE'),
  UPDATE_ONE: StoreUtils.createActionStatus('MANAGE_FAQS/UPDATE_ONE'),
  PAGINATION: 'MANAGE_FAQS/PAGINATION',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case MANAGE_FAQS.PAGINATION: {
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      }
    }
    case MANAGE_FAQS.FETCH.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: {
          items: action.payload.data.items,
          hash: action.payload.data.hash,
        },
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
      }
    }
    case MANAGE_FAQS.UPDATE_ONE.SUCCESS: {
      const updatedFaqEntityIndex = state.data.hash[action.payload.id]
      const items = clonedeep(state.data.items)
      items[updatedFaqEntityIndex] = { ...items[updatedFaqEntityIndex], ...action.payload }

      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: {
          ...state.data,
          items,
        },
        meta: {
          ...state.meta,
        },
      }
    }
    case MANAGE_FAQS.FETCH.REQUEST:
    case MANAGE_FAQS.ADD_ONE.REQUEST:
    case MANAGE_FAQS.UPDATE_ONE.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
      }
    }
    case MANAGE_FAQS.ADD_ONE.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
      }
    }
    case MANAGE_FAQS.FETCH.FAILED:
    case MANAGE_FAQS.ADD_ONE.FAILED:
    case MANAGE_FAQS.UPDATE_ONE.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      }
    }
    default: throw new Error('MISSING REDUCER EVENT')
  }
}

export const useManageFaqsReducer = () => { return useReducer(reducer, INITIAL_STATE) }

export const setPagination = (dispatch, pagination) => {
  dispatch({
    type: MANAGE_FAQS.PAGINATION,
    payload: {
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
  })
}

// NOTE: Not really hooks...
export const getFaqs = async (dispatch, faqVersion, params = {}) => {
  dispatch({ type: MANAGE_FAQS.FETCH.REQUEST })
  try {
    const response = await Api.getFaqs({
      faq_version_id: faqVersion,
      ...params,
    })

    if (response && response.status === 'success') {
      dispatch({
        type: MANAGE_FAQS.FETCH.SUCCESS,
        payload: {
          data: {
            items: response.data.items,
            hash: reHashIndex(response.data.items),
          },
          meta: response.data.meta,
        },
      })

      return response.data
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: MANAGE_FAQS.FETCH.FAILED,
      error,
    })

    throw error
  }
}

export const useFetchFaqsEffect = (dispatch, faqVersion, searchParams = {}) => {
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    const fetchFaqs = async () => {
      dispatch({ type: MANAGE_FAQS.FETCH.REQUEST })
      try {
        const response = await Api.getFaqs({
          faq_version_id: faqVersion,
          ...searchParams,
        })

        if (mounted.current && response && response.status === 'success') {
          dispatch({
            type: MANAGE_FAQS.FETCH.SUCCESS,
            payload: {
              data: {
                items: response.data.items,
                hash: reHashIndex(response.data.items),
              },
              meta: response.data.meta,
            },
          })

          return response.data
        }

        throw new Error('Response Incompatible')
      } catch (error) {
        if (mounted.current) {
          dispatch({
            type: MANAGE_FAQS.FETCH.FAILED,
            error,
          })
        }

        throw error
      }
    }

    fetchFaqs()

    return () => {
      mounted.current = false
    }
  }, [dispatch, faqVersion, searchParams])
}

export const createFaqEntity = async (dispatch, payload) => {
  dispatch({ type: MANAGE_FAQS.ADD_ONE.REQUEST })
  try {
    const response = await Api.addFaq(payload)

    if (response && response.status === 'success') {
      dispatch({ type: MANAGE_FAQS.ADD_ONE.SUCCESS })

      return response.data
    }
    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: MANAGE_FAQS.ADD_ONE.FAILED,
      error,
    })

    throw error
  }
}

export const updateFaqEntity = async (dispatch, id, payload) => {
  dispatch({ type: MANAGE_FAQS.UPDATE_ONE.REQUEST })
  try {
    const response = await Api.updateFaq(id, payload)

    if (response && response.status === 'success') {
      dispatch({
        type: MANAGE_FAQS.UPDATE_ONE.SUCCESS,
        payload: response.data,
      })

      return response.data
    }
    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({
      type: MANAGE_FAQS.UPDATE_ONE.FAILED,
      error,
    })

    throw error
  }
}

export const useFetchFaqCategoriesFilter = (faqVersionId, faqParentCategoryId) => {
  const [faqCategories, setFaqCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    const fetchFaqCategories = async () => {
      setLoading(true)
      setIsError(false)
      try {
        const categories = await getFaqCategories(faqVersionId, faqParentCategoryId)

        if (mounted.current && categories) {
          setFaqCategories(() => { return categories })
          setLoading(() => { return false })
        }
      } catch (error) {
        if (mounted.current) {
          setIsError(error)
          setLoading(false)
        }
      }
    }

    fetchFaqCategories()

    return () => {
      mounted.current = false
    }
  }, [faqVersionId, faqParentCategoryId])

  return [{ loading, isError }, faqCategories]
}

export const useFetchFaqChoiceCategoriesFilter = (faqVersionId) => {
  const [faqCategories, setFaqCategories] = useState([])
  const [faqCategoriesHash, setFaqCategoriesHash] = useState({})
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    const fetchFaqChoiceCategories = async () => {
      setLoading(true)
      setIsError(false)
      try {
        const categories = await getChoiceCategories(faqVersionId)

        if (mounted.current && categories) {
          setFaqCategories(() => { return categories })
          setFaqCategoriesHash(() => { return reHashIndex(categories) })
          setLoading(() => { return false })
        }
      } catch (error) {
        if (mounted.current) {
          setIsError(error)
          setLoading(false)
        }
      }
    }

    fetchFaqChoiceCategories()

    return () => {
      mounted.current = false
    }
  }, [faqVersionId])

  return [{ loading, isError }, faqCategories, faqCategoriesHash]
}
