import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  Modal,
  Typography,
  Button,
  Divider,
  Checkbox,
  Tabs,
  Radio,
  notification,
  Spin,
  Space,
  Row,
  Tag,
} from 'antd'
import { useHotkeys } from 'react-hotkeys-hook'
import { ExclamationCircleFilled } from '@ant-design/icons'

import { upperCaseFirstCharacter, downloadZipFiles } from '@core/helpers'

import { EmailAutomationsContext } from '../../../context'
import Api from '../../../api'

import {
  ViewEmailModalContext,
  DataExtractionFormContext,
  EmailItemsUpdaterContext,
  FormDirtyContext,
} from './context'

import IntentTraining from './IntentTraining'

import IntentTrainingV2 from './IntentTrainingV2'

import DataExtraction from './DataExtraction'
import AnalysisAccuracy from './AnalysisAccuracy'

import style from './style.module.scss'

const ViewEmailModal = ({
  initiallySelectedTab,
  selectedEmail,
  paginationThreshold,
  selectedEmailIndexTableRow,
  currentPaginationLength,
  visible,
  closeViewEmailModal,
  // hotkeys
  backwardOneResult,
  forwardOneResult,
  // select for bulk mode
  selectToBulkMode,
  hasBeenSelectedForBulkMode,
  // status update to reducer only
  updateSpecificEmailStatus,
  updateSpecificEmailIntentSamples,
  updateSpecificEmailDatasetType,
}) => {
  const checkboxRef = useRef(null)
  const [selectedForBulk, setSelectedForBulk] = useState(hasBeenSelectedForBulkMode)
  const [activeTabKey, setActiveTabKey] = useState(initiallySelectedTab ?? 'intents')
  const { confirm } = Modal
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isDatasetTypeLoading, setIsDatasetTypeLoading] = useState(false)

  const {
    versions: { currentVersion } = {},
    datasource,
    permissions,
  } = useContext(EmailAutomationsContext)

  const { emails } = permissions ?? {}

  /* Special 'Form' config */
  const [dataExtractionFormConfig, setDataExtractionFormConfig] = useState({
    openExtractor: false,
    forcedExtractor: false,
    selectedExtractor: null,
  })

  const onSelectForBulkMode = () => {
    setSelectedForBulk((prevCheckState) => { return (!prevCheckState) })
    selectToBulkMode(selectedEmail, !selectedForBulk)
    checkboxRef.current.blur()
  }

  const showWarningSwitchTab = (newActiveTabKey) => {
    confirm({
      width: 450,
      title: 'Do you want to change tab without submit form ?',
      icon: <ExclamationCircleFilled />,
      okText: 'Continue',
      onOk() {
        setActiveTabKey(newActiveTabKey)
        setIsFormDirty(false)
      },
      cancelText: 'Cancel',
    })
  }

  // FIXME: This needs to implement with the navigation hotkey
  // const showWarningSwitchingEmail = (functionToBackwardOfForward) => {
  //   confirm({
  //     width: 450,
  //     title: 'Do you want to change email without submit form?',
  //     icon: <ExclamationCircleFilled />,
  //     okText: 'Continue',
  //     onOk() {
  //       setIsFormDirty(false)
  //       functionToBackwardOfForward()
  //     },
  //     cancelText: 'Cancel',
  //   })
  // }

  const updateActiveTabKey = (newActiveTabKey) => {
    if (isFormDirty) {
      showWarningSwitchTab(newActiveTabKey)
    } else {
      setActiveTabKey(newActiveTabKey)
    }
  }

  const updateDatasetType = async (newDatasetType) => {
    setIsDatasetTypeLoading(true)

    try {
      const response = await Api.Emails.upsertDatasetType({
        id: selectedEmail.id,
        datasetType: newDatasetType,
        datasource,
        version: currentVersion,
      })
      if (response?.status === 'success') {
        const updatedEmail = response.data[0]
        updateSpecificEmailDatasetType(selectedEmail.id, updatedEmail)
      }
    } catch (error) {
      notification.error({ duration: 2, message: 'Error', description: 'Failed to update dataset type.' })
    }
    setIsDatasetTypeLoading(false)
  }

  useEffect(() => {
    setSelectedForBulk(hasBeenSelectedForBulkMode) // always set, 'cause some of them can be false
  }, [hasBeenSelectedForBulkMode])

  // if clicking next and it does not have intent case
  // it needs to have button to switch back to intent

  // Hotkeys
  // Third parameters is dependencies that is  used in the event handler like normal hooks
  // NOTE: Newer version introduced 'Scoped', very well worth looking

  // FIXME: this shit needs to handle form dirty, it handles different level
  useHotkeys('pageup', () => { backwardOneResult() }, [backwardOneResult])
  useHotkeys('left', () => { backwardOneResult() }, [backwardOneResult])
  useHotkeys('a', () => { backwardOneResult() }, [backwardOneResult])
  useHotkeys('pagedown', () => { forwardOneResult() }, [forwardOneResult])
  useHotkeys('right', () => { forwardOneResult() }, [forwardOneResult])
  useHotkeys('d', () => { forwardOneResult() }, [forwardOneResult])

  if (!visible) return null

  const onDownloadEmailPreprocessingCase = () => {
    const { body: email, cleaned_email_body: expected, id } = selectedEmail
    downloadZipFiles(id, [
      { content: email, fileName: 'email.txt' },
      { content: expected, fileName: 'expected.txt' },
      { content: JSON.stringify({ skip: false }), fileName: 'properties.json' },
    ])
  }

  return (
    <Modal
      title={(
        <Space>
          <Typography.Text>
            Email Details
          </Typography.Text>
          <Tag color='orange'>
            <span>Selected Work Environments:</span>
            &thinsp;
            <strong>{datasource}</strong>
          </Tag>
        </Space>
      )}
      visible={visible}
      onCancel={closeViewEmailModal}
      destroyOnClose
      width='90%'
      className={classnames(
        style.ViewEmailModal,
        { [style.ContainizedModalWithinViewport]: activeTabKey === 'emailChecking' },
      )}
      footer={emails?.actionDownloadPreprocessing ? (
        <Button onClick={onDownloadEmailPreprocessingCase}>Download Preprocessing Case</Button>
      ) : null}
    >
      <div className={style.CurrentViewingEmailNavigation}>
        <Button
          type='link'
          onClick={backwardOneResult}
          disabled={paginationThreshold.isCurrentlyFirstItem}
        >
          &lt; Back
        </Button>
        <Typography.Text>
          {`${selectedEmailIndexTableRow} of ${currentPaginationLength}`}
        </Typography.Text>
        <Button
          type='link'
          onClick={forwardOneResult}
          disabled={paginationThreshold.isCurrentlyLastItem}
        >
          Next &gt;
        </Button>
      </div>
      <Divider />
      <>
        <Row justify='space-between'>
          {emails?.actionBulkMode && (
            <Space>
              <Checkbox
                ref={checkboxRef}
                checked={selectedForBulk}
                onChange={onSelectForBulkMode}
              >
                <Typography.Text>Check/Uncheck to bulk</Typography.Text>
              </Checkbox>
            </Space>
          )}
          {emails?.actionSelectDataSetType ? (
            <Space>
              {isDatasetTypeLoading && <Spin />}
              <Radio.Group
                value={selectedEmail.datasetType}
                onChange={(event) => {
                  updateDatasetType(event.target.value)
                }}
                disabled={isDatasetTypeLoading}
                defaultValue='ANY'
              >
                <Radio.Button value='ANY'>ANY</Radio.Button>
                <Radio.Button value='TRAINING'>TRAINING</Radio.Button>
                <Radio.Button value='TEST'>TEST</Radio.Button>
                <Radio.Button value='BOTH'>BOTH</Radio.Button>
              </Radio.Group>
            </Space>
          ) : null}
        </Row>
        <Divider />
        <ViewEmailModalContext.Provider
          value={{
            emailId: selectedEmail.id,
            emailBody: selectedEmail.body,
            emailStatus: selectedEmail.status,
            capId: selectedEmail.cap_id,
            countryOfEmployment: selectedEmail.country_of_employment,
          }}
        >
          <DataExtractionFormContext.Provider
            value={{
              formConfig: dataExtractionFormConfig,
              setDataExtractionFormConfig,
            }}
          >
            <EmailItemsUpdaterContext.Provider
              value={{
                updateSpecificEmailStatus,
                updateSpecificEmailIntentSamples,
              }}
            >
              {/* context hell... merge later... */}
              <FormDirtyContext.Provider
                value={{
                  isFormDirty,
                  setIsFormDirty,
                }}
              >
                <Tabs
                  className={style[upperCaseFirstCharacter(activeTabKey)]}
                  type='card'
                  activeKey={activeTabKey}
                  onChange={updateActiveTabKey}
                  destroyInactiveTabPane
                >
                  {emails?.actionViewIntentTraining && (
                    <Tabs.TabPane key='intents' tab='Intent Training'>
                      <IntentTraining
                        selectedEmail={selectedEmail}
                        setIsFormDirty={setIsFormDirty}
                        activeKey={activeTabKey}
                      />
                    </Tabs.TabPane>
                  )}
                  {emails?.actionViewIntentTraining && (
                    <Tabs.TabPane key='intents-v2' tab='(rev) Intent Training V2'>
                      <IntentTrainingV2
                        selectedEmail={selectedEmail}
                        setIsFormDirty={setIsFormDirty}
                        activeKey={activeTabKey}
                      />
                    </Tabs.TabPane>
                  )}
                  {emails?.actionViewDataExtraction && (
                    <Tabs.TabPane key='dataExtraction' tab='Data Extraction'>
                      <DataExtraction
                        setIsFormDirty={setIsFormDirty}
                        activeKey={activeTabKey}
                      />
                    </Tabs.TabPane>
                  )}
                  {emails?.actionViewEmailChecking && (
                    <Tabs.TabPane key='emailChecking' tab='Email Checking'>
                      <AnalysisAccuracy
                        selectedEmail={selectedEmail}
                        activeKey={activeTabKey}
                      />
                    </Tabs.TabPane>
                  )}
                </Tabs>
              </FormDirtyContext.Provider>
            </EmailItemsUpdaterContext.Provider>
          </DataExtractionFormContext.Provider>
        </ViewEmailModalContext.Provider>
      </>
    </Modal>
  )
}

ViewEmailModal.defaultProps = {
  initiallySelectedTab: '',
  selectedEmail: null,
}

ViewEmailModal.propTypes = {
  initiallySelectedTab: PropTypes.string,
  selectedEmail: PropTypes.shape({
    id: PropTypes.string,
    from_address: PropTypes.string,
    to_address: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    cleaned_email_subject: PropTypes.string,
    cleaned_email_body: PropTypes.string,
    source: PropTypes.string,
    language: PropTypes.string,
    email_sent_at: PropTypes.string,
    record_created_at: PropTypes.string,
    status: PropTypes.string,
    datasetType: PropTypes.string,
    feedback: PropTypes.string,
    intentSamples: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
    cap_id: PropTypes.string,
    country_of_employment: PropTypes.string,
  }),
  selectedEmailIndexTableRow: PropTypes.number.isRequired,
  currentPaginationLength: PropTypes.number.isRequired,
  paginationThreshold: PropTypes.shape({
    isCurrentlyFirstItem: PropTypes.bool,
    isCurrentlyLastItem: PropTypes.bool,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
  closeViewEmailModal: PropTypes.func.isRequired,
  backwardOneResult: PropTypes.func.isRequired,
  forwardOneResult: PropTypes.func.isRequired,
  selectToBulkMode: PropTypes.func.isRequired,
  hasBeenSelectedForBulkMode: PropTypes.bool.isRequired,
  updateSpecificEmailStatus: PropTypes.func.isRequired,
  updateSpecificEmailIntentSamples: PropTypes.func.isRequired,
  updateSpecificEmailDatasetType: PropTypes.func.isRequired,
}

export default ViewEmailModal
