import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Input, Alert, Spin, Typography } from 'antd'
import useTimeoutAlert from '@core/hooks/useTimeoutAlert'
import Api from '../../../../../api'

import style from './style.module.scss'

const SavePreset = ({
  activeFilter,
  modalVisible,
  onCancel,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [showAddedFeedback, setShowAddedFeedback] = useTimeoutAlert(null)

  const saveNewQueryPreset = async () => {
    setLoading(true)
    setHasError(false)
    setShowAddedFeedback(null)

    try {
      const values = await form.validateFields()

      // for full link: window.location.href

      const payload = {
        query_name: values.query_name,
        query_string: encodeURIComponent(JSON.stringify(activeFilter)),
      }

      const response = await Api.QueriesManagement.addNewPreset(payload)

      if (response?.status === 'success') {
        setShowAddedFeedback(true)
      }
    } catch (error) {
      setHasError(true)
    }

    setLoading(false)
  }

  if (!modalVisible) return null

  return (
    <Modal
      title='Add New Preset'
      className='addNewPreset'
      open={modalVisible}
      visible={modalVisible}
      onOk={saveNewQueryPreset}
      onCancel={onCancel}
      okButtonProps={{ loading }}
      okText='Save'
      closable={!loading}
      cancelButtonProps={{ loading, danger: true, type: 'primary' }}
      destroyOnClose
      width='80%'
    >
      <div className={style.SavePreset}>
        {hasError && (<Alert closable showIcon type='error' message='Error during saving query preset, please try again' />)}
        {showAddedFeedback && (<Alert closable message='Successfully added new query preset' type='success' />)}
        <Spin spinning={loading}>
          <Form
            form={form}
            layout='vertical'
            name='addNewPresetForm'
            initialValues={{
              presetName: '',
            }}
          >
            <Form.Item
              name='query_name'
              label='Preset Name'
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the query preset!',
                },
              ]}
            >
              <Input placeholder='Enter the preset name' />
            </Form.Item>
          </Form>
          <Typography.Text type='secondary'>* Currently &quot;selected&quot; filter will be used for saving as a preset</Typography.Text>
        </Spin>
      </div>
    </Modal>
  )
}

SavePreset.propTypes = {
  activeFilter: PropTypes.shape({
    ids: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    regex: PropTypes.string,
    excludeText: PropTypes.string,
    excludeRegex: PropTypes.string,
    emailIds: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    trainIntentIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    excludeTrainIntentIds: PropTypes.arrayOf(PropTypes.number),
    predictedIntentNames: PropTypes.arrayOf(PropTypes.string),
    predictedIntentScoreMin: PropTypes.number,
    predictedIntentScoreMax: PropTypes.number,
    emailSentAt: PropTypes.arrayOf(PropTypes.string),
    recordCreatedAt: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string),
    feedbackSentiments: PropTypes.arrayOf(PropTypes.string),
    extractionLabelsTree: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
    onlyPreprocessedSearch: PropTypes.bool,
    language: PropTypes.arrayOf(PropTypes.string),
    perPage: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default SavePreset
