import React from 'react'
import PropTypes from 'prop-types'
import style from './index.module.scss'

const SubcategoryColumn = ({ subcategories }) => {
  return (
    <ul className={style.ul__Subcategory}>
      {subcategories.map((subcategory) => {
        return (
          <li key={subcategory.id}>
            <div>
              <span>{subcategory.name}</span>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

SubcategoryColumn.propTypes = {
  subcategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
  })).isRequired,
}

export default SubcategoryColumn
