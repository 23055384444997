import React from 'react'
import PropTypes from 'prop-types'
import { Table, Row, Pagination } from 'antd'

import style from './index.module.scss'

const Component = ({ columns, dataSource, meta, onTableChange, onPaginationChange, filter, scroll, rowSelection, rowKey, expandable }) => {
  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        size='small'
        pagination={false}
        rowKey={rowKey}
        scroll={scroll}
        rowSelection={rowSelection}
        expandable={expandable}
        onChange={onTableChange}
      />
      <Row align='end'>
        <Pagination
          className={style.right_panel__pagination_component}
          total={filter?.totalCount || meta?.totalCount}
          pageSize={filter?.perPage || meta?.pageSize || 50}
          current={filter?.page || meta?.page || 1}
          showSizeChanger
          onChange={onPaginationChange}
        />
      </Row>
    </>
  )
}

Component.propTypes = {
  columns: PropTypes.arrayOf({}).isRequired,
  dataSource: PropTypes.arrayOf({}).isRequired,
  rowSelection: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  onPaginationChange: PropTypes.func.isRequired,
  onTableChange: PropTypes.func,
  rowKey: PropTypes.func,
  scroll: {
    scrollToFirstRowOnChange: PropTypes.bool,
    y: PropTypes.number,
  },
  filter: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
}

Component.defaultProps = {
  rowSelection: undefined,
  rowKey: (row) => { return row.id },
  expandable: undefined,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: 650,
  },
  onTableChange: undefined,
}

export default Component
