import React, { useState } from 'react'
import { Modal, Tooltip, Tag } from 'antd'
import PropTypes from 'prop-types'
import ReactJson from 'react-json-view'

import { UserPropTypes } from '../../UserPropTypes'

const RawModal = ({ user }) => {
  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title='View raw payload'>
        <Tag style={{ cursor: 'pointer' }} color='#108ee9' onClick={showModal}>RAW</Tag>
      </Tooltip>
      {open && <RawModalContent user={user} closeModal={closeModal} />}
    </>
  )
}

RawModal.propTypes = {
  user: UserPropTypes.isRequired,
}

const RawModalContent = ({ closeModal, user }) => {
  return (
    <Modal
      title='View Raw Payload'
      visible
      onCancel={closeModal}
      footer={null}
      width={1000}
    >
      <div style={{ border: '1px solid #d9d9d9', padding: '16px' }}>
        <ReactJson displayDataTypes={false} displayObjectSize={false} collapsed={2} src={user} />
      </div>
    </Modal>
  )
}

RawModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  user: UserPropTypes.isRequired,
}

export default RawModal
