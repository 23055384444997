import React from 'react'
import PropTypes from 'prop-types'
import style from '../index.module.scss'

const REPORT_DETAIL = {
  responseKeys: 'responseKeys',
  textTerms: 'textTerms',
  domainIntent: 'domainIntent',
}

const TestCaseDetail = ({ testCaseDetail, type }) => {
  let actual
  let evaluated
  let expected
  let title
  const { skip } = testCaseDetail
  switch (type) {
    case REPORT_DETAIL.responseKeys: {
      expected = testCaseDetail.expected.responseKeys?.join(', ')
      actual = testCaseDetail.actual.responseKeys?.join(', ')
      title = 'Matching response key:'
      evaluated = !testCaseDetail.evaluation.responseKeys
      break
    }
    case REPORT_DETAIL.textTerms: {
      expected = testCaseDetail.expected.textTerms?.join(', ')
      actual = testCaseDetail.actual.textTerms?.join(', ')
      title = 'Matching text terms:'
      evaluated = !testCaseDetail.evaluation.textTerms
      break
    }
    case REPORT_DETAIL.domainIntent: {
      expected = `${testCaseDetail.expected.domain} / ${testCaseDetail.expected.intent}`
      actual = `${testCaseDetail.actual.domain} / ${testCaseDetail.actual.intent}`
      title = 'Matching domain / intent :'
      evaluated = !testCaseDetail.evaluation.domain || !testCaseDetail.evaluation.intent
      break
    }
    default: return null
  }
  return (
    <>
      <div className={style.toMatchText}>
        {title}
      </div>
      <div className={skip ? null : style.testPassed}>
        <div>
          {`Expected: ${expected}`}
        </div>
        <p className={evaluated ? style.testFailed : null}>
          {`Actual: ${actual}`}
        </p>
      </div>
    </>
  )
}

TestCaseDetail.propTypes = {
  testCaseDetail: PropTypes.shape({
    expected: PropTypes.shape({
      responseKeys: PropTypes.arrayOf(PropTypes.string),
      domain: PropTypes.string,
      intent: PropTypes.string,
      textTerms: PropTypes.arrayOf(PropTypes.string),
    }),
    actual: PropTypes.shape({
      domain: PropTypes.string,
      intent: PropTypes.string,
      responseKeys: PropTypes.arrayOf(PropTypes.string),
      textTerms: PropTypes.arrayOf(PropTypes.string),
    }),
    evaluation: PropTypes.shape({
      domain: PropTypes.bool,
      intent: PropTypes.bool,
      responseKeys: PropTypes.bool,
      textTerms: PropTypes.bool,
    }),
    isAllPassed: PropTypes.bool,
    skip: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string.isRequired,
}

export default TestCaseDetail
