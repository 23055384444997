import React from 'react'
import { useSelector } from 'react-redux'
import { Table, Typography, Pagination, Space, Spin } from 'antd'

import ConversationAnalysisActions from '../../../store/actions/analytics'
import GeneralizedSentence from '../../../../Data/components/GeneralizedSentence'

const ANALYSIS_KEY = 'intents.1'
const DisplayIntentStructures = () => {
  const { results, pageSize, isPending } = useSelector((state) => { return state.conversationAnalysis.analytics })
  const columns = [
    {
      key: 'index',
      width: '60px',
      render: (value, item, index) => {
        return (
          <Typography.Text disabled>{(results.meta.page - 1 || 0) * pageSize + index + 1}</Typography.Text>
        )
      },
    },
    {
      title: <b>Domain</b>,
      dataIndex: 'domain',
      key: 'domain',
      width: '200px',
      render: (value) => { return <Typography.Text mark={!value}>{value || 'NULL'}</Typography.Text> },
    },
    {
      title: <b>Intent</b>,
      dataIndex: 'intent',
      key: 'intent',
      width: '250px',
      render: (value) => { return <Typography.Text mark={!value}>{value || 'NULL'}</Typography.Text> },
    },
    {
      title: <b>Structure</b>,
      dataIndex: 'structure',
      key: 'structure',
      width: 'auto',
      ellipsis: true,
      render: (value) => { return <GeneralizedSentence generalizedVer={value} /> },
    },
    {
      title: <b>{`Count (Total: ${results.totalStructuresCount})`}</b>,
      dataIndex: 'count',
      width: '200px',
      align: 'center',
      render: (value) => {
        return (
          <Typography.Text
            style={{ color: `hsl(${360 * (parseInt(value) / results.totalStructuresCount) + 240}, 100%, 50%)` }}
          >
            {value}
          </Typography.Text>
        )
      },
    },
  ]
  return (
    <Space direction='vertical' size='large'>
      <div style={{ maxHeight: 600, overflow: 'auto' }}>
        {isPending && <Spin />}
        {!isPending && (
          <Table
            rowKey='id'
            bordered
            dataSource={results.states}
            columns={columns}
            pagination={false}
            size='small'
          />
        )}
      </div>
      <Pagination
        defaultCurrent={1}
        total={results.meta.totalCount}
        pageSize={pageSize}
        current={results.meta.page || 1}
        showSizeChanger
        onChange={(page, newSize) => {
          if (newSize) ConversationAnalysisActions.setPageSize(newSize)
          ConversationAnalysisActions.selectForAnalyze(ANALYSIS_KEY, { page, pageSize: newSize })
        }}
      />
    </Space>
  )
}

export default DisplayIntentStructures
