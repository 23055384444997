import callApi from '@core/utils/http-client'

export default {
  getLogs: async (params) => {
    return callApi({
      url: '/api/v1/logs',
      method: 'get',
      params,
    })
  },
  getAll: async (data, params) => {
    return callApi({
      url: '/api/v1/notifications',
      method: 'post',
      params,
      data,
    })
  },
  getIssue: async (data, params) => {
    return callApi({
      url: '/api/v1/notifications/state/issue',
      method: 'post',
      params,
      data,
    })
  },
  releaseNotifications: async (data, params) => {
    return callApi({
      url: '/api/v1/notifications/state',
      method: 'put',
      data,
      params,
    })
  },
  deleteNotifications: async (data, params) => {
    return callApi({
      url: '/api/v1/notifications/state',
      method: 'delete',
      data,
      params,
    })
  },
  closePTCNotifications: async (data, params) => {
    return callApi({
      url: `/api/v1/notifications/${params.channel}/conversations/${params.conversationId}/close`,
      method: 'put',
      data,
      params,
    })
  },
  User: {
    getUserList: async (data, params) => {
      return callApi({
        url: '/api/v1/users/diagnostic/query',
        method: 'post',
        params,
        data,
      })
    },
    getConversationIdFromLEId: (leId, params) => {
      return callApi({
        url: '/api/v1/conversations',
        params,
        method: 'post',
        data: {
          page: 1,
          perPage: 1,
          le_conversation_ids: [
            leId,
          ],
        },
      })
    },
    getOneConversation: (id, params) => {
      return callApi({
        url: `/api/v1/conversations/${id}`,
        params,
        method: 'get',
      })
    },
    getLokiLog: (data, signals) => {
      return callApi({
        url: '/api/v1/logs/regex',
        method: 'post',
        data,
        signals,
      })
    },
    sentPairingLink: (data, params) => {
      return callApi({
        url: '/api/v1/conversationManagement/sentPairingLink',
        method: 'post',
        params,
        data,
      })
    },
    unpair: (data, params) => {
      return callApi({
        url: '/api/v1/conversationManagement/unpair',
        method: 'post',
        params,
        data,
      })
    },
    closeConversation: (data, params) => {
      return callApi({
        url: '/api/v1/conversationManagement/closeConversation',
        method: 'post',
        params,
        data,
      })
    },
    closeCSAT: (data, params) => {
      return callApi({
        url: '/api/v1/conversationManagement/closeCSAT',
        method: 'post',
        params,
        data,
      })
    },
    closePTC: (data, params) => {
      return callApi({
        url: '/api/v1/conversationManagement/notifications/close',
        method: 'put',
        params,
        data,
      })
    },
    getJobStatus: (jobId, params) => {
      return callApi({
        url: `/api/v1/conversationManagement/jobs/${jobId}`,
        method: 'get',
        params,
      })
    },
  },
}
