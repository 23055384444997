import { useReducer, useRef, useEffect } from 'react'
import StoreUtils from '@core/utils/store'
import { reHashIndex } from '@core/helpers'
import Api from '../api'

export const SavedSimulationApi = {
  findAll: () => { return Api.Simulation.findAll() },
  findAllCanary: () => { return Api.Simulation.findAll(true) },
  findById: (id) => { return Api.Simulation.findById(id) },
  findOnCanaryById: (id) => { return Api.Simulation.findById(id, true) },
  insert: (data) => { return Api.Simulation.insert(data) },
}

const INITIAL_STATE = {
  loading: false,
  hasError: false,
  error: null,
  data: {
    conversationSimulations: [],
    hashTable: {},
  },
}

export const SAVED_SIMULATION = {
  FETCH: StoreUtils.createActionStatus('SAVED_SIMULATION/FETCH'),
  FETCH_ONE: StoreUtils.createActionStatus('SAVED_SIMULATION/FETCH_ONE'),
  INSERT: StoreUtils.createActionStatus('SAVED_SIMULATION/INSERT'),
}

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVED_SIMULATION.INSERT.REQUEST:
    case SAVED_SIMULATION.FETCH_ONE.REQUEST:
    case SAVED_SIMULATION.FETCH.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
      }
    }
    case SAVED_SIMULATION.FETCH_ONE.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
      }
    }
    case SAVED_SIMULATION.FETCH.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: {
          conversationSimulations: action.payload.conversationSimulations,
          hashTable: action.payload.hashTable,
        },
      }
    }
    case SAVED_SIMULATION.INSERT.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: null,
        data: {
          conversationSimulations: action.payload.conversationSimulations,
          hashTable: action.payload.hashTable,
        },
      }
    }
    case SAVED_SIMULATION.INSERT.FAILED:
    case SAVED_SIMULATION.FETCH_ONE.FAILED:
    case SAVED_SIMULATION.FETCH.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      }
    }
    default: return state
  }
}

export const useSavedSimulation = () => { return useReducer(reducer, INITIAL_STATE) }

export const useGetAllSavedSimulationEffect = (dispatch) => {
  // const [savedSimulations, setSavedSimulations] = useState([])
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    const fetchSavedSimulation = async () => {
      dispatch({ type: SAVED_SIMULATION.FETCH.REQUEST })

      try {
        const response = await SavedSimulationApi.findAll()
        if (mounted.current && response.status === 'success') {
          // setSavedSimulations(response)
          dispatch({
            type: SAVED_SIMULATION.FETCH.SUCCESS,
            payload: {
              conversationSimulations: response.data,
              hashTable: reHashIndex(response.data),
            },
          })
        }
      } catch (error) {
        if (mounted.current) {
          dispatch({ type: SAVED_SIMULATION.FETCH.FAILED, error })
        }
      }
    }

    fetchSavedSimulation()

    return () => {
      mounted.current = false
    }
  }, [dispatch]) // srsly?, view <~> dispatcher?

  // return savedSimulations
}

export const findOneSimulation = async (dispatch, id) => {
  dispatch({ type: SAVED_SIMULATION.FETCH_ONE.REQUEST })

  try {
    const response = await Api.Simulation.findById(id)

    if (response.status === 'success') {
      dispatch({ type: SAVED_SIMULATION.FETCH_ONE.SUCCESS })

      return response.data
    }

    throw new Error('Response Incompatible')
  } catch (error) {
    dispatch({ type: SAVED_SIMULATION.FETCH_ONE.FAILED })

    throw error
  }
}

// save tooks the whole current state of chat
// literally the whole thing of the chat simulator reducer
export const saveChatSimulation = async (dispatch, data) => {
  dispatch({ type: SAVED_SIMULATION.INSERT.REQUEST })

  // NOTE: found one rare case,something broke on this part where rawExchanges is not exist
  // need to check if it has or not, prevent from brake the whole app
  // it could be when lynx is not able to fetch the conversation.
  // could be related to 'self signed certificate in certificate chain' (got one on local)

  try {
    const { saveInfomation, chatSimulationData } = data
    const payload = {
      note: saveInfomation.note,
      tripCacheId: chatSimulationData.conversationData.tripCacheId,
      exchanges: chatSimulationData.rawExchanges.map((exchange, index) => {
        return {
          sequence: index,
          profile: exchange.profile,
          entity: exchange.entity,
          dynamic_entities: exchange.dynamic_entities || exchange.dynamicEntities,
          state_before: exchange.state_before || exchange.stateBefore,
          state_after: exchange.state || exchange.stateAfter,
          state: exchange.state,
          user_text: exchange.user_text || exchange.incomingText,
          bot_responses: (exchange.bot_responses || exchange.responseTexts).map((responseText, responseTextIndex) => {
            return {
              i18n_key: responseText.i18n_key || `${responseText.variation}.${responseText.stage}`,
              sequence: responseText.sequence || responseTextIndex,
              text: responseText.text || responseText.replaced[0],
            }
          }),
        }
      }),
    }

    const response = await SavedSimulationApi.insert(payload)

    if (response.status === 'success') {
      const newSavedSimulations = await SavedSimulationApi.findAll()

      if (newSavedSimulations.status === 'success') {
        dispatch({
          type: SAVED_SIMULATION.INSERT.SUCCESS,
          payload: {
            conversationSimulations: newSavedSimulations.data,
            hashTable: reHashIndex(newSavedSimulations.data),
          },
        })

        return response.data
      }
    }

    throw new Error('Response incompatible')
  } catch (error) {
    dispatch({
      type: SAVED_SIMULATION.INSERT.FAILED,
      error,
    })

    throw error
  }
}
