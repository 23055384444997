import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Layout, Tabs, Empty } from 'antd'
import RightSider from '@core/components/RightSider'
import { filterEmailFromSearchParams } from '@core/helpers/searchParams'
import ConversationsLeftSider from './ConversationsLeftSider'
import ConversationsDataTab from './ConversationsDataTab'
import ConversationsChartsTab from './ConversationsChartsTab'

const { Content } = Layout
const { TabPane } = Tabs

const DATA_TAB_KEY = 'data'
const CHARTS_TAB_KEY = 'charts'

const initialFilterValues = {
  texts: [],
  exclude_texts: [],
  regexes: [],
  exclude_regexes: [],
  message_type: 'ANY',
  tags: [],
  datasource: 'staging',
  bot_relevance_type: 'ANY',
  exclude_from_training: -1,
  conversation_ids: [],
  le_conversation_ids: [],
  emails: [],
  date_range: [
    dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ],
}

const ConversationsPage = () => {
  const [tab, setTab] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : DATA_TAB_KEY,
  )
  const [filter, setFilter] = useState(null)
  const [selectedConversation, setSelectedConversation] = useState('') // id of selected conversation is here

  const setInitialFilterFromSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filterAsString = searchParams.get('filter')
    if (!filterAsString) {
      setFilter(initialFilterValues)
    } else {
      try {
        const parsedFilter = JSON.parse(filterAsString)
        if (typeof parsedFilter === 'object' && parsedFilter !== null) {
          setFilter(Object.keys(parsedFilter).length ? parsedFilter : initialFilterValues)
        } else {
          throw new Error('parsedFilter is not an object, throw')
        }
      } catch (error) {
        setFilter(initialFilterValues)
      }
    }
  }

  const updateSearchParamsByFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filteredParams = filterEmailFromSearchParams(filter)
    searchParams.set('filter', JSON.stringify(filteredParams))
    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState(null, '', newRelativePathQuery)
    setSelectedConversation(null)
  }

  useEffect(setInitialFilterFromSearchParams, [])

  useEffect(updateSearchParamsByFilter, [filter])

  const search = (newFilter) => {
    newFilter.page = 1
    setFilter(newFilter)
    setSelectedConversation(null)
  }

  const handleTabChange = (newTab) => {
    window.location.hash = `#${newTab}`
    setTab(newTab)
  }

  const onItemClick = (conversation) => {
    setSelectedConversation(conversation !== selectedConversation ? conversation : null)
  }

  const handlePaginationChange = (page, perPage) => {
    if (filter.perPage !== perPage) {
      setFilter((prevFilter) => { return { ...prevFilter, page: 1, perPage } })
    } else {
      setFilter((prevFilter) => { return { ...prevFilter, page, perPage } })
    }
  }

  const { perPage, page, ...chartFilter } = filter ?? {}

  return (
    <>
      {filter && <ConversationsLeftSider initialFilterValues={filter} onSearch={search} />}
      <Content>
        <Tabs activeKey={tab} onChange={handleTabChange}>
          <TabPane key={DATA_TAB_KEY} tab='Data'>
            {filter ? (
              <ConversationsDataTab
                selectedConversationId={selectedConversation ? selectedConversation.id : ''}
                filter={filter}
                onItemClick={onItemClick}
                handlePaginationChange={handlePaginationChange}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
          <TabPane key={CHARTS_TAB_KEY} tab='Charts'>
            {filter ? (
              <ConversationsChartsTab filter={chartFilter} />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
        </Tabs>
      </Content>
      <RightSider
        selectedConversationId={selectedConversation ? selectedConversation.id : null}
        querySource={filter && filter.datasource ? filter.datasource : null}
      />
    </>
  )
}

export default ConversationsPage
