import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Space } from 'antd'
import style from './style.module.scss'

const RecentlyAddedIntents = ({ recentlyAddedIntents }) => {
  if (!recentlyAddedIntents.length) return null

  return (
    <>
      <Space align='start'>
        <Typography.Title level={5}>
          Recently added
        </Typography.Title>
        <Typography.Text type='danger'>
          <sup>*</sup>
          Saved via localStorage, do not clear site data!
        </Typography.Text>

      </Space>
      <Typography.Paragraph>
        <ul className={style.RecentlyAddedList}>
          {recentlyAddedIntents.map(({ timestamp, addedIntents, emailSubjects/* , emailIds */ }, index) => {
            return (
              <li key={index}>
                <ul className={style.RecentlyAddedRecord}>
                  <Typography.Text strong>{timestamp}</Typography.Text>
                  {/* Email IDs */}
                  {/* <li>
                  <Typography.Text strong>Email IDs:</Typography.Text>
                  <ul className={style.TopicList}>
                    {emailIds.map((emailId, idIndex) => (
                      <li key={idIndex}>
                        <Typography.Text type='secondary' italic>
                          {emailId}
                        </Typography.Text>
                      </li>
                    ))}
                  </ul>
                </li> */}
                  <li>
                    <Typography.Text strong>Email:</Typography.Text>
                    <ul className={style.TopicList}>
                      {emailSubjects.map((subject, idIndex) => {
                        return (
                          <li key={idIndex}>
                            <Typography.Text type='secondary' italic>
                              {`${subject.slice(0, 50)}...`}
                            </Typography.Text>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                  <li>
                    <Typography.Text strong>Intents:</Typography.Text>
                    <ul className={style.TopicList}>
                      {addedIntents.map((intentName, idIndex) => {
                        return (
                          <li key={idIndex}>
                            <Typography.Text type='secondary' italic>
                              {intentName}
                            </Typography.Text>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                </ul>
              </li>
            )
          })}
        </ul>
      </Typography.Paragraph>
    </>
  )
}

RecentlyAddedIntents.defaultProps = {
  recentlyAddedIntents: [],
}

RecentlyAddedIntents.propTypes = {
  recentlyAddedIntents: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string,
      addedIntents: PropTypes.arrayOf(PropTypes.string),
      emailIds: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
}

export default RecentlyAddedIntents
