import React from 'react'
import classnames from 'classnames'
import {
  Row,
  Col,
  Typography,
  Space,
  Radio,
  Tag,
  Switch,
  Spin,
} from 'antd'
import { useSelector } from 'react-redux'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import {
  isLoadingType,
  totalResultsType,
  selectedGeneralizedFilterTagsType,
  removeGeneralizedFilterTagType,
  setSentenceViewModeType,
  showOnlySentenceWithNoGeneralizedTagsType,
  toggleBulkModeType,
  clearTableSelectionType,
} from '../../../types/sentencesQueryResultsTitle'
import BulkActions from './BulkActions'

import style from './style.module.scss'

const sentenceViewModes = [
  { label: 'Text', value: 'text' },
  { label: 'Generalized', value: 'generalized' },
  { label: 'Stopwords', value: 'stopwords' },
]

const Title = ({
  isLoading,
  totalResults,
  selectedGeneralizedFilters,
  removeGeneralizedFilterTag,
  setSentenceViewMode,
  showOnlySentenceWithNoGeneralizedTag,
  toggleBulkMode,
  clearTableSelection,
}) => {
  const { bulkMode, sentenceMode, noGeneralizedMode } = useSelector(({ data }) => {
    return {
      bulkMode: data.sentencesListUI.bulkMode,
      sentenceMode: data.sentencesListUI.sentenceMode,
      noGeneralizedMode: data.sentencesListUI.noGeneralizedMode,
    }
  })

  const changeViewMode = (event) => {
    const { target: { value } } = event
    return setSentenceViewMode(value)
  }

  const removeTag = (selectedTag) => {
    return () => {
      return removeGeneralizedFilterTag(selectedTag)
    }
  }

  return (
    <div className={style.Title}>
      <Typography.Title level={5}>
        {`Total Result(s): ${totalResults.toLocaleString()} sentence${totalResults > 1 ? 's' : ''}`}
      </Typography.Title>
      <Spin spinning={isLoading}>
        <Row justify='space-between' wrap>
          <Col span={12}>
            <Space align='start'>
              <Typography.Text>Sentence Mode:</Typography.Text>
              <Radio.Group
                options={sentenceViewModes}
                onChange={changeViewMode}
                value={sentenceMode}
                optionType='button'
                size='small'
              />
            </Space>
          </Col>
          <Col span={12}>
            <div className={style.DisplayOptions}>
              <PermittedFeatureCheck featurePath='utterances.data.actionToggleBulkMode'>
                <Space align='start'>
                  <Typography.Text>Bulk Mode</Typography.Text>
                  <Switch onChange={toggleBulkMode} defaultChecked={false} checked={bulkMode} />
                </Space>
              </PermittedFeatureCheck>
              <Space align='start'>
                <Typography.Text>Only Sentence with no tag</Typography.Text>
                <Switch onChange={showOnlySentenceWithNoGeneralizedTag} defaultChecked={false} checked={noGeneralizedMode} />
              </Space>
            </div>
          </Col>
        </Row>
        <Row className={classnames(style.GeneralizedFilter, !selectedGeneralizedFilters.length && style.Empty)}>
          <Col flex='128px'>
            <Typography.Text>Generalized Filter:</Typography.Text>
          </Col>
          <Col flex='auto'>
            {selectedGeneralizedFilters.map((selectedHighlightedFilter) => {
              return (
                <Tag
                  key={selectedHighlightedFilter.text}
                  color={selectedHighlightedFilter.backgroundColor}
                  closable
                  onClose={removeTag(selectedHighlightedFilter)}
                >
                  {selectedHighlightedFilter.text}
                </Tag>
              )
            })}
          </Col>
        </Row>
        <PermittedFeatureCheck featurePath='utterances.data.actionToggleBulkMode'>
          {/* FIXME: Props drilling */}
          {bulkMode && <BulkActions clearTableSelection={clearTableSelection} />}
        </PermittedFeatureCheck>
      </Spin>
    </div>
  )
}

Title.propTypes = {
  isLoading: isLoadingType.isRequired,
  totalResults: totalResultsType.isRequired,
  selectedGeneralizedFilters: selectedGeneralizedFilterTagsType.isRequired,
  removeGeneralizedFilterTag: removeGeneralizedFilterTagType.isRequired,
  setSentenceViewMode: setSentenceViewModeType.isRequired,
  showOnlySentenceWithNoGeneralizedTag: showOnlySentenceWithNoGeneralizedTagsType.isRequired,
  toggleBulkMode: toggleBulkModeType.isRequired,
  clearTableSelection: clearTableSelectionType.isRequired,
}

export default React.memo(Title)
