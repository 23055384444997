import callApi from '@core/utils/http-client'
import {
  getBaseUrlCanary,
  getBaseUrlProduction,
} from '@core/helpers/gbtCloudVariableSwitcher'

const querystring = require('querystring')

const getEnvBaseUrl = {
  production: getBaseUrlProduction('https://hermes.messaging.gbtconnect.com'),
  canary: getBaseUrlCanary('https://hermes.canary.messaging.gbtconnect.com'),
  local: process.env.REACT_APP_BASE_URL || 'http://localhost:3000',
}

export default {
  getAll: (data, params) => {
    return callApi({
      url: '/api/v1/conversations',
      method: 'post',
      params,
      data,
    })
  },
  getAllChartData: (data, params) => {
    return callApi({
      url: '/api/v1/conversations/chart-data',
      method: 'post',
      params,
      data,
    })
  },
  getAllTagChartData: (data, params) => {
    return callApi({
      url: '/api/v1/conversations/tag-chart-data',
      method: 'post',
      params,
      data,
    })
  },
  getOne: (id, params) => {
    return callApi({
      url: `/api/v1/conversations/${id}`,
      params,
      method: 'get',
    })
  },
  update: (id, data, params) => {
    return callApi({
      url: `/api/v1/conversations/${id}`,
      method: 'patch',
      params,
      data,
    })
  },
  getTags: (params) => {
    return callApi({
      url: '/api/v1/conversations/tags',
      method: 'get',
      params,
    })
  },
  runTaggingJob: (data, params) => {
    return callApi({
      url: '/api/v1/conversations/run-tagging',
      method: 'post',
      params,
      data,
    })
  },
  // NOTE: Four of them used to have canary check
  // we leave it with default in case of we're using it again
  Simulation: {
    findUser: (params) => {
      return callApi({
        url: '/api/v1/simulations/users',
        method: 'get',
        params,
      })
    },
    simulate: (data, env) => {
      return callApi({
        baseURL: env !== 'staging' ? getEnvBaseUrl[env] : undefined,
        url: '/api/v1/simulations/simulate',
        method: 'post',
        data,
      })
    },
    closeSimulation: (conversationId, env) => {
      return callApi({
        baseURL: env !== 'staging' ? getEnvBaseUrl[env] : undefined,
        url: `/api/v1/simulations/${conversationId}/close`,
        method: 'put',
      })
    },
    findAll: (isCanary = false) => {
      return callApi({
        baseURL: isCanary ? getBaseUrlCanary('https://hermes.canary.messaging.gbtconnect.com') : undefined,
        url: '/api/v1/simulations',
        method: 'get',
      })
    },
    findById: (id, isCanary = false) => {
      return callApi({
        baseURL: isCanary ? getBaseUrlCanary('https://hermes.canary.messaging.gbtconnect.com') : undefined,
        url: `/api/v1/simulations/${id}`,
        method: 'get',
      })
    },
    insert: (data) => {
      return callApi({
        url: '/api/v1/simulations',
        method: 'post',
        data,
      })
    },
    updateSimulation: (id, data) => {
      return callApi({
        url: `/api/v1/simulations/${id}`,
        method: 'patch',
        data,
      })
    },
  },
  EndToEnd: {
    findTestCasesByExchangeSimulationId: (ids) => {
      return callApi({
        url: '/api/v1/e2e',
        method: 'get',
        params: {
          exchangeSimulationIds: ids,
        },
        /*
        NOTE: This is exceptional case for finding exchange test case 'crash' with validator

        In this endpoint, backend specify it needs to be an array
        ```
          query: Joi.object({
            exchangeSimulationIds: Joi.array().items(Joi.string().guid({ version: ['uuidv4'] })).optional()
          })
        ```

        However, it seems like "Joi cannot distinguish array with 'one' element and string".
        If it goes like this:
          - ?exchangeSimulationIds=abcdef&exchangeSimulationIds=ghijklmnop
        it works.

        but these does not
          - ?exchangeSimulationIds=abcdef --> this got recognized as string
          - ?exchangeSimulationIds[]=abcdef --> this got recognized as invalid, even it is a valid query string array format

        The workaround here if not on the backend is check the length of one and use JSON.stringify,
        the query string will just looks ugly because encoding

          - ?exchangeSimulationIds=[%22hahaha-hahaha-hahaha-hahaha-hahaha%22]

        seems like joi was doing internal JSON.parse as I'm aware.

        As of version we use 15.1.1, we don't have access to Joi.*ANY*.custom()
      */
        paramsSerializer: {
          serialize: (params) => {
            // if (params.exchangeSimulationIds.length === 1) return `exchangeSimulationIds=${JSON.stringify(params.exchangeSimulationIds)}`
            return querystring.stringify(params)
          },
        },
      })
    },
    findOneTestCaseById: (id) => {
      return callApi({
        url: `/api/v1/e2e/${id}`,
        method: 'get',
      })
    },
    updateTestCaseById: (id, data) => {
      return callApi({
        url: `/api/v1/e2e/test-cases/${id}`,
        method: 'patch',
        data,
      })
    },
    evaluate: (data) => {
      return callApi({
        url: '/api/v1/e2e/evaluate',
        method: 'post',
        data,
      })
    },
    evaluateBySimulationIdsWithTestReport: (data) => {
      return callApi({
        url: '/api/v1/e2e/simulations/evaluate',
        method: 'post',
        data,
      })
    },
    insert: (data) => {
      return callApi({
        url: '/api/v1/e2e',
        method: 'post',
        data,
      })
    },
    getAllTestCaseCollection: (params) => {
      return callApi({
        url: '/api/v1/e2e/collections',
        method: 'get',
        params,
      })
    },
  },
  EndToEndTestCollection: {
    findManyTestCollection: ({ page, perPage }) => {
      return callApi({
        url: '/api/v1/e2e/collections',
        method: 'get',
        params: {
          page,
          perPage,
        },
      })
    },
    insertTestCollection: (data) => {
      return callApi({
        url: '/api/v1/e2e/collections',
        method: 'post',
        data,
      })
    },
    deleteTestCollectionById: (id) => {
      return callApi({
        url: `/api/v1/e2e/collections/${id}`,
        method: 'delete',
      })
    },
    deleteManyTestCollectionByIds: (ids) => {
      return callApi({
        url: '/api/v1/e2e/collections',
        method: 'delete',
        params: {
          ids,
        },
        paramsSerializer: {
          serialize: (params) => {
            if (params.ids.length === 1) return `ids=${JSON.stringify(params.ids)}`
            return querystring.stringify(params)
          },
        },
      })
    },
    updateDesiredLinkedTestCasesToTestCollection: (data) => {
      return callApi({
        url: `/api/v1/e2e/collections/${data.testCollectionId}/test-cases/desired-link`,
        method: 'put',
        data: {
          simulationIds: data.simulationIds,
        },
      })
    },
    updateDescriptionByTestCollectionId: (id, data) => {
      return callApi({
        url: `/api/v1/e2e/collections/${id}`,
        method: 'patch',
        data,
      })
    },
    evaluateByTestCollectionIds: ({ ids, jobName }) => {
      return callApi({
        url: '/api/v1/e2e/collections/evaluate',
        method: 'post',
        data: {
          ids,
          jobName,
        },
      })
    },
  },
  EndToEndTestCases: {
    deleteTestCasesByIds: (ids) => {
      return callApi({
        url: '/api/v1/simulations',
        method: 'delete',
        params: {
          ids,
        },
        data: {},
        paramsSerializer: {
          serialize: (params) => {
            return querystring.stringify(params)
          },
        },
      })
    },
    addTestCasesToCollection: (testCollectionId, simulationIds) => {
      return callApi({
        url: `/api/v1/e2e/collections/${testCollectionId}/test-cases/link`,
        method: 'post',
        data: {
          simulationIds,
        },
      })
    },
  },
  EndToEndTestReports: {
    findTestReports: (params) => {
      return callApi({
        url: '/api/v1/e2e/reports',
        method: 'get',
        params,
      })
    },
    terminateJob: (id) => {
      return callApi({
        url: `/api/v1/jobs/${id}/terminate`,
        method: 'patch',
      })
    },
    rerunJob: (reportId) => {
      return callApi({
        url: `/api/v1/e2e/reports/${reportId}/rerun`,
        method: 'post',
        data: {},
      })
    },
  },
}
