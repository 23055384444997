import React from 'react'
import PropTypes from 'prop-types'
import { Row, Radio } from 'antd'
import { DislikeFilled, LikeFilled } from '@ant-design/icons'

import style from './style.module.scss'

const Feedback = ({ sentiment }) => {
  return (
    <Row>
      <Radio.Group
        disabled
        defaultValue={sentiment}
        optionType='button'
        buttonStyle='solid'
        className={style.Feedback}
      >
        <Radio.Button
          className='positive'
          value='positive'
        >
          <LikeFilled />
        </Radio.Button>
        <Radio.Button
          className='negative'
          value='negative'
        >
          <DislikeFilled />
        </Radio.Button>
      </Radio.Group>
    </Row>
  )
}

Feedback.defaultProps = {
  sentiment: undefined,
}

Feedback.propTypes = {
  sentiment: PropTypes.string,
}

export default Feedback
