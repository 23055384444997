export default {
  // Keyname is entity
  AbsoluteDates: { type: 'GxDATE', category: 'DATE' },
  RelativeDates: { type: 'GxRELATIVEDATE', category: 'RELATIVEDATE' },
  DateRanges: { type: 'GcDATERANGE', category: 'DATERANGE' },
  FlightNumber: { type: 'GcFLIGHTNUMBER', category: 'FLIGHTNUMBER' },
  Times: { type: 'GxTIME', category: 'TIME' },
  URLs: { type: 'GxURL', category: 'URL' },
  Emails: { type: 'GxEMAIL', category: 'EMAIL' },
  Timeframes: { type: 'GsTIME', category: 'TIME' },
  Airlines: { type: 'GsAIRLINE', category: 'PROVIDER' },
  RailCompanies: { type: 'GsRAILCOMPANY', category: 'PROVIDER' },
  Airports: { type: 'GsAIRPORT', category: 'LOCATION' },
  CabinClass: { type: 'GsCABIN', category: 'FEATURE' },
  Cities: { type: 'GsCITY', category: 'LOCATION' },
  Countries: { type: 'GsCOUNTRY', category: 'LOCATION' },
  FlightType: { type: 'GsPATH', category: 'FEATURE' },
  Days: { type: 'GxNUMBERDAYS', category: 'PERIOD' },
  Nights: { type: 'GxNUMBERDAYS', category: 'PERIOD' },
  States: { type: 'GsSTATE', category: 'LOCATION' },
  CommonPhrases: { type: 'GsCOMMON', category: 'PHRASE' },
  NegativePhrases: { type: 'GsNEGATIVE', category: 'PHRASE' },
  PositivePhrases: { type: 'GsPOSITIVE', category: 'PHRASE' },
  HotelRating: { type: 'GxHOTELRATING', category: 'RATING' },
  HotelChains: { type: 'GsHOTELCHAIN', category: 'HOTELCHAIN' },
  CarRentalChains: { type: 'GsCARRENTALCHAIN', category: 'CARRENTALCHAIN' },
  CarService: { type: 'GsCARSERVICE', category: 'CARSERVICE' },
  CarServiceChain: { type: 'GsCARSERVICECHAIN', category: 'CARSERVICECHAIN' },
  BusStations: { type: 'GsBUSSTATION', category: 'BUSSTATION' },
  FerryStations: { type: 'GsFERRYSTATION', category: 'FERRYSTATION' },
  RailwayStations: { type: 'GsRAILWAYSTATION', category: 'RAILWAYSTATION' },
  Price: { type: 'GxPRICE', category: 'PRICE' },
  KnownTravelerReference: {
    type: 'GsKNOWNTRAVELERREFERENCE',
    category: 'KNOWNTRAVELERREFERENCE',
  },
  BookingReference: { type: 'GsBOOKINGREFERENCE', category: 'BOOKINGREFERENCE' },
  ChildCount: { type: 'GxCHILDCOUNT', category: 'COUNT' },
  GreetingPhrases: { type: 'GsGREETING', category: 'PHRASE' },
  FarewellPhrases: { type: 'GsFAREWELL', category: 'PHRASE' },
  GratitudePhrases: { type: 'GsGRATITUDE', category: 'PHRASE' },
  HotelAmenities: { type: 'GsHOTELAMENITY', category: 'HOTELAMENITY' },
  Return: { type: 'GsRETURN', category: 'DATE' },
  CheckIn: { type: 'GsCHECKIN', category: 'DATETIME' },
  CheckOut: { type: 'GsCHECKOUT', category: 'DATETIME' },
  PNRs: { type: 'GsPNR', category: 'PNR' },
  Default: { type: 'GsTEXT', category: 'TEXT' },
  Arrival: { type: 'GsARRIVAL', category: 'ARRIVAL' },
  Departure: { type: 'GsDEPARTURE', category: 'DEPARTURE' },
  Distance: { type: 'GxDISTANCE', category: 'DISTANCE' },
  Invoice: { type: 'GsINVOICE', category: 'INVOICE' },
  Flight: { type: 'GsFLIGHT', category: 'FLIGHT' },
  Hotel: { type: 'GsHOTEL', category: 'HOTEL' },
  Car: { type: 'GsCAR', category: 'CAR' },
  Companies: { type: 'GsCOMPANY', category: 'COMPANY' },
  Rails: { type: 'GsRAIL', category: 'RAIL' },
  Know: { type: 'GsKNOW', category: 'KNOW' },
  Change: { type: 'GsCHANGE', category: 'CHANGE' },
  Cancel: { type: 'GsCANCEL', category: 'CANCEL' },
  Application: { type: 'GsAPPLICATION', category: 'APPLICATION' },
  Error: { type: 'GsERROR', category: 'ERROR' },
  Profile: { type: 'GsPROFILE', category: 'PROFILE' },
  Booking: { type: 'GsBOOKING', category: 'BOOKING' },
  Help: { type: 'GsHELP', category: 'HELP' },
  Agent: { type: 'GsAGENT', category: 'AGENT' },
  Person: { type: 'GsPERSON', category: 'PERSON' },
  Password: { type: 'GsPASSWORD', category: 'PASSWORD' },
  SignIn: { type: 'GsSIGNIN', category: 'SIGNIN' },
  PaymentMethod: { type: 'GsPAYMENTMETHOD', category: 'PAYMENTMETHOD' },
  Refund: { type: 'GsREFUND', category: 'REFUND' },
  DayOfWeek: { type: 'GsDAYOFWEEK', category: 'DAYOFWEEK' },
  Layover: { type: 'GsLAYOVER', category: 'LAYOVER' },
  Months: { type: 'GsMONTH', category: 'MONTH' },
  DatePeriods: { type: 'GsDATEPERIOD', category: 'DATEPERIOD' },
}

export const generalizerCategory = {
  DATE: ['GxDATE', 'GsRETURN'],
  TIME: ['GxTIME', 'GsTIME'],
  PROVIDER: ['GsAIRLINE'],
  LOCATION: ['GsAIRPORT', 'GsCITY', 'GsCOUNTRY', 'GsSTATE'],
  FEATURE: ['GsCABIN', 'GsPATH'],
  PERIOD: ['GxNUMBERDAYS'],
  PHRASE: ['GsCOMMON', 'GsNEGATIVE', 'GsPOSITIVE', 'GsGREETING', 'GsFAREWELL', 'GsGRATITUDE'],
  RATING: ['GxHOTELRATING'],
  PRICE: ['GxPRICE'],
  COUNT: ['GxCHILDCOUNT'],
  HOTEL: ['GsHOTELAMENITY'],
  DATETIME: ['GsCHECKIN', 'GsCHECKOUT'],
  PNR: ['GsPNR'],
  TEXT: ['GsTEXT'],
  GsARRIVAL: ['GsARRIVAL'],
  GsDEPARTURE: ['GsDEPARTURE'],
}

// Gs => Static data, e.g. city name
// Gx => Dynamic data, date
export const generalizeFlatten = [
  'GxPRICE',
  'GxDATE',
  'GxTIME',
  'GxNUMBERDAYS',
  'GxHOTELRATING',
  'GxCHILDCOUNT',
  'GsTIME',
  'GsRETURN',
  'GsAIRLINE',
  'GsAIRPORT',
  'GsCITY',
  'GsCOUNTRY',
  'GsSTATE',
  'GsCABIN',
  'GsPATH',
  'GsCOMMON',
  'GsNEGATIVE',
  'GsPOSITIVE',
  'GsGREETING',
  'GsFAREWELL',
  'GsGRATITUDE',
  'GsHOTELAMENITY',
  'GsCHECKIN',
  'GsCHECKOUT',
  'GsPNR',
  'GsTEXT',
  'GsARRIVAL',
  'GsDEPARTURE',
]

export const generalizeGroup = {
  dynamic: [
    'GxPRICE',
    'GxDATE',
    'GxTIME',
    'GxNUMBERDAYS',
    'GxHOTELRATING',
    'GxCHILDCOUNT',
  ],
  static: [
    'GsTIME',
    'GsRETURN',
    'GsAIRLINE',
    'GsAIRPORT',
    'GsCITY',
    'GsCOUNTRY',
    'GsSTATE',
    'GsCABIN',
    'GsPATH',
    'GsCOMMON',
    'GsNEGATIVE',
    'GsPOSITIVE',
    'GsGREETING',
    'GsFAREWELL',
    'GsGRATITUDE',
    'GsHOTELAMENITY',
    'GsCHECKIN',
    'GsCHECKOUT',
    'GsPNR',
    'GsTEXT',
    'GsARRIVAL',
    'GsDEPARTURE',
  ],
}

export const generalizerClass = {
  GxPRICE: '#F8AF52',
  GxDATE: '#D98348',
  GxTIME: '#F0835B',
  GxNUMBERDAYS: '#D95948',
  GxHOTELRATING: '#F85260',
  GxCHILDCOUNT: '#8FFC77',
  GsTIME: '#C8D966',
  GsRETURN: '#F0DE7D',
  GsAIRLINE: '#D9B166',
  GsAIRPORT: '#FAAA73',
  GsCITY: '#7AB5FF',
  GsCOUNTRY: '#6AD8DE',
  GsSTATE: '#81F5BD',
  GsCABIN: '#6EDE6A',
  GsPATH: '#D6FF78',
  GsCOMMON: '#9897FF',
  GsNEGATIVE: '#5091DE',
  GsPOSITIVE: '#64EBF5',
  GsGREETING: '#50DEA0',
  GsFAREWELL: '#59FF62',
  GsGRATITUDE: '#D8A8FF',
  GsHOTELAMENITY: '#675FDE',
  GsCHECKIN: '#76AAF5',
  GsCHECKOUT: '#5FD0DE',
  GsPNR: '#6BFFC5',
  GsTEXT: '#D9BF3B',
  GsARRIVAL: '#F89F43',
  GsDEPARTURE: '#FACEE6',
}
