import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import ConfusionMatrix from '../../ConfusionMatrix'
import style from './style.module.scss'

const IntentEvaluation = ({ intent, evaluation, handleClickChart }) => {
  const { classification_metrics } = evaluation

  const classificationMetricsEntries = Object.entries(classification_metrics).reduce((metricsObject, currentMetric) => {
    const [currentMetricName, currentMetricObjectValue] = currentMetric
    if (Object.hasOwn(currentMetricObjectValue, 'value')) {
      metricsObject[currentMetricName] = currentMetricObjectValue.value
    } else {
      metricsObject[currentMetricName] = currentMetricObjectValue
    }

    return metricsObject
  }, {})

  return (
    <Col className={style.IntentEvaluation} sm={24} md={12} lg={8} xl={8} xxl={6}>
      <header>
        <span className={style.IntentLabel}>Intent:&thinsp;</span>
        <span className={style.IntentName}>{intent}</span>
      </header>
      <div>
        <span className={style.AccuracyLabel}>Accuracy:&thinsp;</span>
        <span className={style.AccuracyValue}>{`${(classificationMetricsEntries.accuracy_score * 100).toFixed(2)}%`}</span>
      </div>
      <div>
        <span>Full evaluations:</span>
      </div>
      <ul>
        {Object.entries(classificationMetricsEntries).map((metric) => {
          const [metricName, metricValue] = metric
          return (<li key={`${metricName}-${metricValue}`}>{`${metricName}: ${Math.round((metricValue) * 10000) / 10000}`}</li>)
        })}
      </ul>
      <ConfusionMatrix
        intentName={intent}
        classification={{
          true_positives: classification_metrics?.true_positives || {},
          true_negatives: classification_metrics?.true_negatives || {},
          false_positives: classification_metrics?.false_positives || {},
          false_negatives: classification_metrics?.false_negatives || {},
        }}
        handleClickChart={handleClickChart}
      />
    </Col>
  )
}

IntentEvaluation.defaultProps = {
  evaluation: {}, // NOTE: There is a chance that it can be null from backend
  handleClickChart: () => { },
}

IntentEvaluation.propTypes = {
  intent: PropTypes.string.isRequired,
  evaluation: PropTypes.shape({
    classification_metrics: PropTypes.shape({
      roc_auc: PropTypes.number,
      true_positives: PropTypes.number,
      true_negatives: PropTypes.number,
      false_positives: PropTypes.number,
      false_negatives: PropTypes.number,
      f1_score: PropTypes.number,
      precision_score: PropTypes.number,
      recall_score: PropTypes.number,
      accuracy_score: PropTypes.number,
    }),
  }),
  handleClickChart: PropTypes.func,
}

export default IntentEvaluation
