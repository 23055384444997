import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Button,
  Form,
  Spin,
  Typography,
  Space,
  notification,
  Row,
  Col,
  Divider,
  Radio,
  Modal,
  Checkbox,
  Switch,
} from 'antd'

import useQueryParams from '@core/hooks/useQueryParams'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import { useInternationalizeVersionSelector } from '@core/hooks/useInternationalize'

import style from './index.module.scss'
import ChatExchanges from '../ChatExchanges'
import SaveSimulationModal from './SaveSimulationModal'
import DataStats from './DataStats'
import PersistentData from './PersistentData'
import ConfigPanel from './ConfigPanel'

import I18nVersionPanel, { I18nVersionPanelDataProvider } from '../../I18nVersionPanel'

import { I18nVersionContext, getCurrentI18nVersionId } from '../../../helpers/context'

const handleConnectorMessage = (value) => {
  switch (value) {
    case 'google_chat': return 'Bot + Google Chat'
    case 'msteams': return 'Bot + MS Teams'
    default: return 'Bot Only'
  }
}

const Simulator = ({
  conversationId,
  simulateChat,
  resetSimulation,
  view,
  revert,
  exchanges,
  latestExchangeId,
  loading,
  // stats
  dynamicEntities,
  profile,
  state,
  // flag
  allowToUpdateStats,
  fromExchangeId,
  // simulation target
  selectedSimulationTarget,
  connectorMessage,
  setConnectorMessage,
  // saving simulation
  saveSimulation,
  savedSimulatorLoading,
  updateConversationData,
  savedSimulatorError,
  // used for visiting the page via links
  loadToSimulator,
  // persistent data
  persistentDataSimulator,
  setMockDate,
  setMockActiveUser,
  clearActiveUser,
}) => {
  const queryParams = useQueryParams()
  const id = queryParams.get('id')
  const updateStats = (name) => {
    const payload = {}
    return (stats) => {
      payload[`${name}`] = JSON.parse(stats)
      updateConversationData(payload)
    }
  }

  const simulate = (payload) => { return simulateChat(payload) }

  useEffect(() => {
    if (id) {
      loadToSimulator(id)()
    }
    // NOTE: We don't need it to update on every prop change
    // this one supposed to be on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isI18nVersionAllowed = usePermittedFeaturesLookup('conversations.simulation.actionSelectI18nVersion')
  const [i18nVersionId, setI18nVersionId] = useState(getCurrentI18nVersionId())
  const [chatDate, setChatDate] = useState()
  const [i18nVersionModalOpen, setI18nVersionModalOpen] = useState(false)
  const [isSettingPanelShown, setIsSettingPanelShown] = useState(true)
  const i18nVersions = useInternationalizeVersionSelector().map((v) => { return { ...v, key: v.id } })
  const currentSelectedVersion = i18nVersions.find((item) => { return item.id === i18nVersionId })

  const closeI18nVersionModal = () => { return setI18nVersionModalOpen(false) }
  const toggleSettingPanel = () => { return setIsSettingPanelShown(!isSettingPanelShown) }

  const setMockDateAndSetChatDate = (momentDate) => {
    setChatDate(momentDate.format('DD/MM/YYYY'))
    setMockDate(momentDate)
  }

  return (
    <div className='simulator_panels'>
      {/* ConfigPanel is Gear shaped box */}
      <Space className='simulator_configurations' align='start'>
        <ConfigPanel
          i18nVersionId={i18nVersionId}
          connectorMessage={handleConnectorMessage(connectorMessage)}
          persistentDataSimulator={persistentDataSimulator}
          chatDate={chatDate}
          toggleSettingPanel={toggleSettingPanel}
          shouldShowCurrentValue={!isSettingPanelShown}
        />
        {isSettingPanelShown && (
          <div>
            <Row gutter={[16, 16]}>
              <PersistentData
                persistentDataSimulator={persistentDataSimulator}
                setMockDate={setMockDateAndSetChatDate}
                setMockActiveUser={setMockActiveUser}
                clearActiveUser={clearActiveUser}
                shouldDisableDatePicker={exchanges.length > 0 || loading}
                shouldDisableEmailSearch={exchanges.length > 0 || loading}
              />
              <Col xxl={6} xl={12} lg={12} md={12}>
                <Space direction='vertical' size={0}>
                  <Typography.Text>Choose message combination</Typography.Text>
                  <Radio.Group buttonStyle='solid' onChange={setConnectorMessage} value={connectorMessage}>
                    <Radio.Button value={undefined}>Bot Only</Radio.Button>
                    <Radio.Button value='google_chat'>Bot + Google Chat</Radio.Button>
                    <Radio.Button value='msteams'>Bot + MS Teams</Radio.Button>
                  </Radio.Group>
                </Space>
              </Col>
              {isI18nVersionAllowed && (
                <Col xxl={9} xl={12} lg={12} md={12}>
                  <Space direction='vertical' size={0}>
                    <Typography.Text>Select i18n version for simulating bot messages</Typography.Text>
                    <div>
                      <Typography.Text strong>Current Version:&thinsp;&thinsp;</Typography.Text>
                      <Typography.Text strong type='success'>{currentSelectedVersion ? `[${currentSelectedVersion?.id}] ${currentSelectedVersion?.note}` : ''}</Typography.Text>
                      <I18nVersionPanelDataProvider>
                        <Button type='link' onClick={() => { setI18nVersionModalOpen(true) }}>Change i18n Version</Button>
                      </I18nVersionPanelDataProvider>
                    </div>
                  </Space>
                  <div />
                </Col>
              )}
            </Row>
          </div>
        )}
      </Space>
      <Divider />
      <Row className='simulator' gutter={[16, 0]}>
        <Col span={14}>
          <ChatPanel
            simulateChat={simulate}
            resetSimulation={resetSimulation}
            view={view}
            revert={revert}
            latestExchangeId={latestExchangeId}
            loading={loading}
            exchanges={exchanges}
            i18nVersionId={i18nVersionId}
            // saving simulation
            showSavedSimulation={selectedSimulationTarget.toLowerCase() !== 'canary'}
            saveSimulation={saveSimulation}
            savedSimulatorLoading={savedSimulatorLoading}
            savedSimulatorError={savedSimulatorError}
            conversationId={conversationId}
          />
        </Col>
        <Col span={10}>
          <SimulatorDataStats
            dynamicEntities={dynamicEntities}
            profile={profile}
            state={state}
            fromExchangeId={fromExchangeId}
            allowToUpdateStats={allowToUpdateStats}
            updateStats={updateStats}
            loading={loading}
          />
        </Col>
      </Row>
      {
        i18nVersionModalOpen && (
          <Modal
            title='Change i18n Version'
            visible={i18nVersionModalOpen}
            onOk={closeI18nVersionModal}
            onCancel={closeI18nVersionModal}
            footer={null}
          >
            <I18nVersionContext.Provider value={{ i18nVersionId, setI18nVersionId }}>
              <I18nVersionPanel />
            </I18nVersionContext.Provider>
          </Modal>
        )
      }
    </div>
  )
}

Simulator.defaultProps = {
  savedSimulatorError: null,
  connectorMessage: undefined,
}

Simulator.propTypes = {
  conversationId: PropTypes.string.isRequired,
  simulateChat: PropTypes.func.isRequired,
  resetSimulation: PropTypes.func.isRequired,
  view: PropTypes.func.isRequired,
  revert: PropTypes.func.isRequired,
  exchanges: PropTypes.arrayOf(PropTypes.object).isRequired,
  latestExchangeId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  // simulation target
  selectedSimulationTarget: PropTypes.string.isRequired,
  // stats
  dynamicEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  profile: PropTypes.shape({}).isRequired,
  state: PropTypes.shape({}).isRequired,
  // flag
  allowToUpdateStats: PropTypes.bool.isRequired,
  fromExchangeId: PropTypes.string.isRequired,
  connectorMessage: PropTypes.string,
  setConnectorMessage: PropTypes.func.isRequired,
  // saving simulation
  saveSimulation: PropTypes.func.isRequired,
  savedSimulatorLoading: PropTypes.bool.isRequired,
  updateConversationData: PropTypes.func.isRequired,
  savedSimulatorError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // used for visiting the page via links
  loadToSimulator: PropTypes.func.isRequired,
  // persistent data
  persistentDataSimulator: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  setMockDate: PropTypes.func.isRequired,
  setMockActiveUser: PropTypes.func.isRequired,
  clearActiveUser: PropTypes.func.isRequired,
}

const SimulatorDataStats = ({
  dynamicEntities,
  profile,
  state,
  fromExchangeId,
  allowToUpdateStats,
  loading,
  updateStats,
}) => {
  return (
    <div className='simulation_data'>
      <Row gutter={[8, 0]}>
        <Typography.Text strong>{`Viewing Exchange ID#${fromExchangeId}`}</Typography.Text>
        {/* FIXME: Flag is popped up when viewing another exchange, it can be view though */}
        {!allowToUpdateStats && (
          <Typography.Text type='danger'>
            Cannot update past exchange data, only latest one is allowed
          </Typography.Text>
        )}
      </Row>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <DataStats
            collapsable
            statName='Dynamic Entities'
            stats={JSON.stringify(dynamicEntities, null, 2)}
            allowToUpdateStats={allowToUpdateStats}
            onUpdateConversationStat={updateStats('dynamicEntities')}
            loading={loading}
          />
        </Col>
        <Col span={12}>
          <DataStats
            collapsable
            statName='Extra'
            stats={JSON.stringify(profile, null, 2)}
            allowToUpdateStats={allowToUpdateStats}
            onUpdateConversationStat={updateStats('profile')}
            loading={loading}
          />
        </Col>
      </Row>
      <Row gutter={[8, 0]} style={{ flex: 1 }}>
        <Col span={24}>
          <DataStats
            statName='Conversation State/Data'
            stats={JSON.stringify(state, null, 2)}
            allowToUpdateStats={allowToUpdateStats}
            onUpdateConversationStat={updateStats('state')}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  )
}

SimulatorDataStats.propTypes = {
  dynamicEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  profile: PropTypes.shape({}).isRequired,
  state: PropTypes.shape({}).isRequired,
  fromExchangeId: PropTypes.string.isRequired,
  allowToUpdateStats: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  updateStats: PropTypes.func.isRequired,
}

const ChatPanel = ({
  simulateChat,
  resetSimulation,
  view,
  revert,
  exchanges,
  latestExchangeId,
  loading,
  // saving simulation
  showSavedSimulation,
  saveSimulation,
  savedSimulatorLoading,
  i18nVersionId,
  conversationId,
}) => {
  const isSaveConversationAllowed = usePermittedFeaturesLookup('conversations.simulation.actionSaveConversation')
  const inputRef = useRef(null)
  const [form] = Form.useForm()
  const [hasFormError, setHasFormError] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [trackConversationAnalytics, setTrackConversationAnalytics] = useState(false)
  const [applyDeploymentGroups, setApplyDeploymentGroups] = useState(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  const onFinish = (value) => {
    setHasFormError(false)
    simulateChat({
      ...value,
      i18nVersionId,
      trackConversationAnalytics,
      applyDeploymentGroups,
    })
    // NOTE: This works fine, but not sure if anything will broke the render flow (unmount thing) or not
    form.resetFields()
  }

  const onFinishFailed = () => {
    // args is an object: { values, errorFields, outOfDate }
    setHasFormError(true)
  }

  // For clearing error
  const onInputChange = (event) => {
    const { target: { value } } = event
    if (hasFormError && value) setHasFormError(false)
  }

  const onSaveSimulation = async (fields) => {
    try {
      const result = await saveSimulation(fields)
      if (result) setModalVisible(false)
    } catch (error) {
      notification.error({
        message: 'Unable to save simulation',
        description: 'Encounter unexpected error, please try again',
        duration: 5,
      })
    }
  }

  useEffect(() => {
    // NOTE: this should not get shoved into recursive stupidity
    // only way that loading is changed is on initial load and submission...
    inputRef.current.focus({
      cursor: 'end',
    })
  }, [loading])

  return (
    <div className={style.simulation_chat}>
      <div className={style.simulation_chat__header}>
        <Space>
          <Button
            type='primary'
            danger
            size='small'
            onClick={() => { return resetSimulation(trackConversationAnalytics && exchanges.length) }}
            disabled={loading}
          >
            Reset Chat
          </Button>
          {isSaveConversationAllowed && showSavedSimulation && exchanges.length ? (
            <Button type='primary' size='small' onClick={showModal} disabled={loading}>
              Save Conversation
            </Button>
          ) : null}
          <Checkbox
            disabled={exchanges.length}
            onChange={(e) => { return setTrackConversationAnalytics(e.target.checked) }}
          >
            Track Conversation Analytics
          </Checkbox>
          <Typography.Text mark level={5}>{`ID: ${conversationId}`}</Typography.Text>
          <Switch
            defaultChecked={applyDeploymentGroups}
            onChange={(check) => {
              setApplyDeploymentGroups(check)
            }}
            checkedChildren='Apply Deployment Groups'
            unCheckedChildren='Apply Deployment Groups'
          />
        </Space>
      </div>
      <div className={style.simulation_chat__messages}>
        <ChatExchanges
          exchanges={exchanges}
          viewData={view}
          revert={revert}
          latestExchangeId={latestExchangeId}
          loading={loading}
        />
      </div>
      <div className={style.simulation_chat__footer}>
        <Form
          form={form}
          layout='inline'
          className={style.message_input}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name='text'
            noStyle
            validateTrigger={['onSubmit']}
            rules={[{ required: true, message: 'Enter a message' }]}
          >
            <Input
              ref={inputRef}
              placeholder='Enter chat message'
              style={{ marginRight: '8px' }}
              className={hasFormError && style.message_input_error}
              disabled={loading}
              onChange={onInputChange}
            />
          </Form.Item>
          {hasFormError && (
            <Typography.Text type='danger' className={style.message_input_error_label}>Field is empty</Typography.Text>
          )}
          <Form.Item noStyle>
            <Button type='primary' htmlType='submit' disabled={loading}>
              {!loading && 'Send'}
              {loading && <Spin size='small' />}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {modalVisible && (
        <SaveSimulationModal
          modalVisible={modalVisible}
          closeModal={closeModal}
          onSaveSimulation={onSaveSimulation}
          savedSimulatorLoading={savedSimulatorLoading}
        />
      )}
    </div>
  )
}

ChatPanel.propTypes = {
  conversationId: PropTypes.string.isRequired,
  resetSimulation: PropTypes.func.isRequired,
  simulateChat: PropTypes.func.isRequired,
  view: PropTypes.func.isRequired,
  revert: PropTypes.func.isRequired,
  exchanges: PropTypes.arrayOf(PropTypes.object).isRequired,
  latestExchangeId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  i18nVersionId: PropTypes.number.isRequired,
  // saving simulation
  showSavedSimulation: PropTypes.bool.isRequired,
  saveSimulation: PropTypes.func.isRequired,
  savedSimulatorLoading: PropTypes.bool.isRequired,
}

export default Simulator
