import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { reHashIndex } from '@core/helpers'
import { SELECTED_SENTENCE } from '../constants'
import { INITIAL_STATE } from '../reducers/selectedSentence'
import Api from '../../api'

const appendIndexToElement = (collection) => { return collection.map((element, index) => { return { index, ...element } }) }

// need Curly brackets for readability
// eslint-disable-next-line arrow-body-style
const compactInitialEvaluations = (collection, field) => {
  // eslint-disable-next-line arrow-body-style
  return collection.filter((element) => {
    return !(typeof element[field] === 'string' && element[field] === 'null')
  })
}

export default wrapActionCreatorWithDispatch({
  fetchTestAndTrainingSets: (sentenceId) => {
    return async (dispatch) => {
      dispatch({ type: SELECTED_SENTENCE.TEST_CASES.FETCH.REQUEST })
      try {
        const [testCases, trainingCases] = await Promise.all([
          Api.getSentenceTestCases(sentenceId),
          Api.getSentenceTrainingCases(sentenceId),
        ])

        if (testCases && trainingCases) {
          const { data: testCasesSet } = testCases // object
          const { data: trainingCasesSet } = trainingCases // array

          // NOTE: Revise this in future, performance might suffer
          testCasesSet.domain_test_sets = appendIndexToElement(testCasesSet.domain_test_sets)
          testCasesSet.hashedIndex_domain_test_sets = reHashIndex(testCasesSet.domain_test_sets)
          testCasesSet.intent_test_sets = appendIndexToElement(testCasesSet.intent_test_sets)
          testCasesSet.hashedIndex_intent_test_sets = reHashIndex(testCasesSet.intent_test_sets)
          testCasesSet.generalizer_test_sets = appendIndexToElement(testCasesSet.generalizer_test_sets)
          testCasesSet.hashedIndex_generalizer_test_sets = reHashIndex(testCasesSet.generalizer_test_sets)
          testCasesSet.tagger_test_sets = appendIndexToElement(testCasesSet.tagger_test_sets)
          testCasesSet.hashedIndex_tagger_test_sets = reHashIndex(testCasesSet.tagger_test_sets)
          testCasesSet.extractor_test_sets = appendIndexToElement(testCasesSet.extractor_test_sets)
          testCasesSet.hashedIndex_extractor_test_sets = reHashIndex(testCasesSet.extractor_test_sets)

          // Workaround: if the sentence is not currently have domain/intent test case, it will have null as a first one
          // must check if it has a length of 1 or 2 or not, there will be no 0 length for domain and intent
          // Why 2?, becuase if we add one, next time server will count as two rows: one is actually one we added and one is the 'null' one
          // and we allow domain/intent to have only one test per sentence
          if (testCasesSet.domain_test_sets.length <= 2) testCasesSet.domain_test_sets = compactInitialEvaluations(testCasesSet.domain_test_sets, 'test_domain')
          if (testCasesSet.intent_test_sets.length <= 2) testCasesSet.intent_test_sets = compactInitialEvaluations(testCasesSet.intent_test_sets, 'test_intent')

          trainingCasesSet[0].domain_training_sets = appendIndexToElement(trainingCasesSet[0].domain_training_sets)
          trainingCasesSet[0].hashedIndex_domain_training_sets = reHashIndex(trainingCasesSet[0].domain_training_sets)
          trainingCasesSet[0].intent_training_sets = appendIndexToElement(trainingCasesSet[0].intent_training_sets)
          trainingCasesSet[0].hashedIndex_intent_training_sets = reHashIndex(trainingCasesSet[0].intent_training_sets)
          trainingCasesSet[0].tagger_training_sets = appendIndexToElement(trainingCasesSet[0].tagger_training_sets)
          trainingCasesSet[0].hashedIndex_tagger_training_sets = reHashIndex(trainingCasesSet[0].tagger_training_sets)

          dispatch({
            type: SELECTED_SENTENCE.TEST_CASES.FETCH.SUCCESS,
            payload: {
              testCases: testCasesSet,
              trainingCases: trainingCasesSet[0],
            },
          })
        }
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.TEST_CASES.FETCH.FAILED,
          error,
        })
      }
    }
  },
  fetchById: (sentenceId) => {
    return async (dispatch) => {
      dispatch({ type: SELECTED_SENTENCE.FETCH.REQUEST })
      let response
      try {
      // must fetch by id in case of it access directly to the sentence details
        response = await Api.getSentenceById(sentenceId)
        if (response && response.data) {
          const generizedRes = await Api.generalizeType(response.data.text)

          // NOTE: This is perfect route when it success to get the information
          dispatch({
            type: SELECTED_SENTENCE.FETCH.SUCCESS,
            payload: {
              id: response.data.id,
              data: response.data,
              generalized: (generizedRes && generizedRes.data) ? generizedRes.data : {},
            },
          })
        }
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.FETCH.FAILED,
          payload: {
            id: response?.data ? response.data.id : sentenceId,
            data: response?.data ? response.data : {
              source: 'Error: cannot extract information',
            },
            testCases: INITIAL_STATE.testCases,
            trainingCases: INITIAL_STATE.trainingCases,
            generalized: {
              generalized: 'Error: cannot extract information',
              original: response?.data ? response.data.text : 'Error: cannot extract information',
            },
            meta: {
              isPartiallyFailed: response.status === 'success',
            },
          },
          error,
        })
      }
    }
  },
  edit: (id, options) => {
    return async (dispatch) => {
      dispatch({ type: SELECTED_SENTENCE.EDIT.REQUEST })

      let response
      try {
        response = await Api.editSentence(id, {
          unrecognized: options.unrecognized,
        })

        if (response && response.status === 'success') {
          dispatch({
            type: SELECTED_SENTENCE.EDIT.SUCCESS,
            payload: response.data,
          })

          return response
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({
          type: SELECTED_SENTENCE.EDIT.FAILED,
          payload: error,
        })

        throw error
      }
    }
  },
})
