import React from 'react'
import {
  Layout,
  Typography,
  Tabs,
} from 'antd'
import InformationCurrent from '../../components/InformationCurrent/index'
import TEAProfileLookup from '../../components/TEAProfileLookup'
import EmailUUIDConverter from '../../components/EmailUUIDConverter'

const { Content } = Layout
const { Title } = Typography
const { TabPane } = Tabs

const InformationPage = () => {
  return (
    <Content>
      <Title level={4}>Information</Title>
      <Tabs defaultActiveKey='1'>
        <TabPane key='1' tab='Current'>
          <InformationCurrent />
        </TabPane>
        <TabPane key='2' tab='Profile Lookup'>
          <TEAProfileLookup />
        </TabPane>
        <TabPane key='3' tab='Email / UUID Converter'>
          <EmailUUIDConverter />
        </TabPane>
      </Tabs>
    </Content>
  )
}

export default InformationPage
