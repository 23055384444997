import { SENTENCE_NOTES } from '../constants'

const INITIAL_STATE = {
  notes: [],
  isPending: false,
  hasError: false,
  selectedNote: null,
  noteTypes: ['COMMON_PHRASE', 'ENTITY', 'REGEX', 'OTHER'],
  noteStatus: ['PENDING', 'DONE', 'INFO'],
  noteStatusNew: ['PENDING', 'INFO'],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SENTENCE_NOTES.FETCH.REQUEST:
      return {
        ...state, isPending: true, hasError: false,
      }
    case SENTENCE_NOTES.FETCH.SUCCESS:
      return {
        ...state, isPending: false, hasError: false, notes: action.payload,
      }
    case SENTENCE_NOTES.FETCH.FAILED:
      return {
        ...state, isPending: false, hasError: true,
      }
    case SENTENCE_NOTES.ADD_NEW.REQUEST:
      return {
        ...state, isPending: true, hasError: false,
      }
    case SENTENCE_NOTES.ADD_NEW.SUCCESS:
      return {
        ...state, notes: action.payload.data, isPending: false, hasError: false,
      }
    case SENTENCE_NOTES.ADD_NEW.FAILED:
      return {
        ...state, isPending: false, hasError: true,
      }
    case SENTENCE_NOTES.EDIT.REQUEST:
      return {
        ...state, isPending: true, hasError: false,
      }
    case SENTENCE_NOTES.EDIT.SUCCESS:
      return {
        ...state, notes: action.payload, isPending: false, hasError: false,
      }
    case SENTENCE_NOTES.EDIT.FAILED:
      return {
        ...state, isPending: false, hasError: true,
      }
    case SENTENCE_NOTES.DELETE.REQUEST:
      return {
        ...state, isPending: true, hasError: false,
      }
    case SENTENCE_NOTES.DELETE.SUCCESS:
      return {
        ...state, notes: action.payload.data, isPending: false, hasError: false,
      }
    case SENTENCE_NOTES.DELETE.FAILED:
      return {
        ...state, isPending: false, hasError: true,
      }
    case SENTENCE_NOTES.SELECTED:
      return {
        ...state, isPending: false, hasError: false, selectedNote: action.payload,
      }
    default:
      return {
        ...state, isPending: false, hasError: false,
      }
  }
}
