import React, { useEffect } from 'react'
import {
  Layout,
  Typography,
  Table,
  Button,
  Popconfirm,
  notification,
} from 'antd'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  useInternationalizeVersionSelector,
  useInternationalizeFetchStatus,
} from '@core/hooks/useInternationalize'
import { useVersionSelector } from '@core/hooks/useVersions'
import InternationalActions from '@core/store/actions/Internationalize'

export default () => {
  const { current: currentDatasetVersion } = useVersionSelector()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { loading } = useInternationalizeFetchStatus()
  const i18nVersions = useInternationalizeVersionSelector().map((v) => { return { ...v, key: v.id } })

  const cloneI18nVersion = (version) => {
    return async () => {
      try {
        const response = await InternationalActions.clone({ from_version: version.id })

        if (response) {
          notification.success({
            message: 'Successfully Duplicated version',
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Duplicate version failed',
          duration: 5,
        })
      }
    }
  }

  useEffect(() => {
    const fetchVersion = async () => {
      await InternationalActions.getAll()
    }

    fetchVersion()
  }, [])

  return (
    <Layout.Content>
      <Typography.Title level={4}>Internationalize (i18n) Versions</Typography.Title>
      <Table
        loading={loading}
        columns={[
          {
            title: 'Version',
            dataIndex: 'id',
            render: (versionId) => { return <Typography.Text strong>{currentDatasetVersion.i18n_version === versionId ? `${versionId} (Current)` : versionId}</Typography.Text> },
          },
          {
            title: 'Note',
            dataIndex: 'note',
          },
          {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            render: (version) => {
              return (
                <>
                  <Button type='link' onClick={() => { history.push(`${path}/${version.id}`) }}>
                    View
                  </Button>
                  <Popconfirm
                    title='Confirm Clone version?'
                    onConfirm={cloneI18nVersion(version)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button type='link'>
                      Duplicate
                    </Button>
                  </Popconfirm>
                  <Button type='link' onClick={() => { history.push(`${path}/${version.id}/edit`) }}>
                    Edit
                  </Button>
                </>
              )
            },
          },
        ]}
        dataSource={i18nVersions}
      />
    </Layout.Content>
  )
}
