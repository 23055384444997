import PropTypes from 'prop-types'

const {
  shape,
  bool,
  func,
  string,
  number,
  object,
} = PropTypes

export const idType = string
export const indexType = number

export const sentenceType = string

export const testDomainIdType = number
export const testIntentIdType = number
export const noteType = string
export const testCaseTypeType = string
export const taggerDataType = shape(object)
export const extractorDataType = shape(object)

export const modalVisibilityType = bool
export const setModalVisibilityType = func
export const setSelectedItemType = func

export const onDomainChangeType = func

export const selectedItemTrainingType = shape({
  id: string,
  index: number,
  trainingCaseType: string,
  train_domain_id: number,
  train_intent_id: number,

  phrase: string,
  phrase_index: number,
  desired_result: string,
  tagger_type: string,

  note: string,
  type: string,
})

export const taggerType = string
export const phraseIndex = number
export const phrase = string
export const desiredResult = string
