import React, { useState } from 'react'
import {
  Layout,
  Form,
  Button,
  Divider,
  Collapse,
} from 'antd'
import classnames from 'classnames'

import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'

import SentencesActionCreators from '../../store/actions/sentences'
import RighPanelActions from '../../store/actions/rightPanel'

import AddNewSentenceButton from '../AddNewSentenceButton'
import CollapseSidebarButton from './CollapseSidebarButton'

import TermsFilter from './TermsFilter'
import NotesFilter from './NotesFilter'
import DataExtractionCasesFilter from './DataExtractionCasesFilter'
import SourceFilter from './SourceFilter'
import DateRangeFilter from './DateRangeFilter'

// Training, Test
import TestCasesFilter from './TestCasesFilter'
import TrainingCaseFilter from './TrainingCasesFilter'
import TaggerCasesFilter from './TaggerCasesFilter'
import UnrecognizedFilter from './UnrecognizedFilter'

import FilterCategoryDivider from './FilterCategoryDivider'

import style from './index.module.scss'
import TrainingStatusFilter from './TrainingStatusFilter'

const { Sider } = Layout

const SideMenu = () => {
  const sentenceNotesEnabled = usePermittedFeaturesLookup('utterances.data.viewSentenceNotes')
  const [testCaseIntents, setTestCaseIntents] = useState([])
  const [testCaseDomains, setTestCaseDomains] = useState([])
  const [trainingCaseIntents, setTrainingCaseIntents] = useState([])
  const [trainingCaseDomains, setTrainingCaseDomains] = useState([])

  const [terms, setTerms] = useState([])
  const [excludeTerms, setExcludeTerms] = useState([])
  const [regexes, setRegexes] = useState([])
  const [excludeRegexes, setExcludeRegexes] = useState([])

  const [notesType, setNotesTypes] = useState([])
  const [notesStatus, setNotesStatus] = useState([])
  const [source, setSource] = useState('ANY')
  const [dateRange, setDateRange] = useState('ANYTIME')
  const [collapsed, setCollapsed] = useState(false)

  const [form] = Form.useForm()

  const getTerms = (event) => { return setTerms(event) }
  const getExcludeTerms = (event) => { return setExcludeTerms(event) }
  const getRegexes = (event) => { return setRegexes(event) }
  const getExcludeRegexes = (event) => { return setExcludeRegexes(event) }
  const getTrainingCaseDomainsIntents = (event) => {
    setTrainingCaseIntents(event.intents)
    setTrainingCaseDomains(event.domains)
  }

  const getTestCaseDomainsIntents = (event) => {
    setTestCaseIntents(event.intents)
    setTestCaseDomains(event.domains)
  }

  const getNotesType = (event) => { return setNotesTypes(event) }
  const getNotesStatus = (event) => { return setNotesStatus(event) }
  const getSource = (event) => { return setSource(event) }
  const getDateRange = (event) => { return setDateRange(event) }

  const parseDomainIntentIds = (ids) => { return (ids.map((id) => { return parseInt(id) })) }
  const setCollapseSidebar = () => { return setCollapsed((prev) => { return !prev }) }

  const onExpectNullSelect = (value) => {
    if (value.target.checked) {
      form.resetFields(['no_domain_test_set', 'no_intent_test_set', 'test_domain_intents'])
    }
  }
  const onNoIntentDomainSelect = (value) => {
    if (value.target.checked) {
      form.resetFields(['test_expected_null'])
    }
  }

  const setIntentValue = (value) => {
    form.resetFields(['test_expected_null'])
    getTestCaseDomainsIntents(value)
  }

  const handleOnFinish = async (values) => {
    const filter = {
      texts: terms,
      exclude_texts: excludeTerms,
      regexes,
      exclude_regexes: excludeRegexes,
      test_intent_ids: parseDomainIntentIds(testCaseIntents),
      test_domain_ids: parseDomainIntentIds(testCaseDomains),
      train_intent_ids: parseDomainIntentIds(trainingCaseIntents),
      train_domain_ids: parseDomainIntentIds(trainingCaseDomains),
      notes_types: notesType,
      notes_status: notesStatus,
      date_range: dateRange,
      source_type: source,
      test_expected_null: values.test_expected_null,
      is_unrecognized: values.is_unrecognized,
      has_tagger_training_sets: values.has_tagger_training_sets,
      has_tagger_test_sets: values.has_tagger_test_sets,
      tagger_types: values.tagger_type,
      has_extractor_tests_sets: values.has_extractor_tests_sets,
    }

    if (values.test_expected_null) {
      filter.test_domain_ids = []
      filter.test_intent_ids = []
    }

    if (values.no_domain_test_set) {
      filter.test_domain_ids = [null, ...parseDomainIntentIds(testCaseDomains)]
      filter.test_intent_ids = [null, ...parseDomainIntentIds(testCaseIntents)]
    }

    if (values.no_intent_test_set) {
      filter.test_intent_ids = [null, ...parseDomainIntentIds(testCaseIntents)]
    }

    if (values.no_domain_training_set) {
      filter.train_domain_ids = [null, ...parseDomainIntentIds(trainingCaseDomains)]
      filter.train_intent_ids = [null, ...parseDomainIntentIds(trainingCaseIntents)]
    }

    if (values.no_intent_training_set) {
      filter.train_intent_ids = [null, ...parseDomainIntentIds(trainingCaseIntents)]
    }

    if (values.training_status && values.training_status.length) {
      filter.training_status = values.training_status
    }

    await RighPanelActions.close()
    SentencesActionCreators.fetch(filter, true)
  }

  return (
    <Sider
      className={classnames('aside_filter aside_filter__sentence_query', { collapsed }, style.UtterancesFilter)}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <CollapseSidebarButton
        collapsedContainerClassName={style.left_sider__collapse_button_container}
        collapsed={collapsed}
        onClick={setCollapseSidebar}
      />
      <PermittedFeatureCheck featurePath='utterances.data.actionInsertSentence'>
        <AddNewSentenceButton collapsed={collapsed} />
      </PermittedFeatureCheck>
      <Form
        style={{ display: collapsed ? 'none' : 'block' }}
        form={form}
        onFinish={handleOnFinish}
        layout='vertical'
        initialValues={{
          no_domain_test_set: false,
          no_intent_test_set: false,
          no_domain_training_set: false,
          no_intent_training_set: false,
        }}
      >
        <Divider className={style.side_filter__divider}>
          <strong>Utterances Query</strong>
        </Divider>
        <div className='aside_filter--wrapper'>
          <TermsFilter
            getTerms={getTerms}
            getExcludeTerms={getExcludeTerms}
            getRegexes={getRegexes}
            getExcludeRegexes={getExcludeRegexes}
          />
          <Collapse defaultActiveKey='1' bordered>
            {/* TODO: Collapse.Panel probably can be just it's functionality, not a number */}
            <Collapse.Panel
              key='1'
              header={(<FilterCategoryDivider filterCategory='By Training Case(s) (Dom/Int)' />)}
            >
              <TrainingCaseFilter setIntent={getTrainingCaseDomainsIntents} />
            </Collapse.Panel>
            <Collapse.Panel
              key='2'
              header={(<FilterCategoryDivider filterCategory='By Test Case(s) (Dom/Int)' />)}
            >
              <TestCasesFilter
                form={form}
                setIntent={getTestCaseDomainsIntents}
                onExpectNullSelect={onExpectNullSelect}
                onNoIntentDomainSelect={onNoIntentDomainSelect}
                setIntentValue={setIntentValue}
              />
            </Collapse.Panel>
            <Collapse.Panel
              key='trainingStatusFilter'
              header={(<FilterCategoryDivider filterCategory='By Status' />)}
            >
              <TrainingStatusFilter />
            </Collapse.Panel>
            <Collapse.Panel
              key='3'
              header={(<FilterCategoryDivider filterCategory='By Tagger Case(s)' />)}
            >
              <TaggerCasesFilter />
            </Collapse.Panel>
            <Collapse.Panel
              key='4'
              header={(<FilterCategoryDivider filterCategory='By Data Case' />)}
            >
              <DataExtractionCasesFilter />
            </Collapse.Panel>
            {/*
              FIXME:/NOTE: This is temporary check for disable note.
              In the future, side filter will have it's own flag
            */}
            {/*
              NOTE: antd mutate wrapper component and add a keys + extra props to it,
              workaround is use a conditional render to make it not render wrap component
            */}
            {sentenceNotesEnabled
              ? (
                <Collapse.Panel
                  key='5'
                  header={(<FilterCategoryDivider filterCategory='By Note' />)}
                >
                  <NotesFilter setFilterType={getNotesType} setFilterStatus={getNotesStatus} />
                </Collapse.Panel>
              )
              : null}
          </Collapse>
          <SourceFilter source={source} setFilterSource={getSource} />
          <DateRangeFilter dateRange={dateRange} setFilterDateRange={getDateRange} />
          <UnrecognizedFilter />
        </div>
        <Button
          type='primary'
          htmlType='submit'
          block
        >
          Search
        </Button>
      </Form>
    </Sider>
  )
}

export default SideMenu
