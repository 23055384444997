import React from 'react'
import { Redirect } from 'react-router-dom'

const OverviewModule = () => { return <Redirect to='/utterances/sentences' /> }

export const ModuleConfig = {
  routeProps: {
    path: '/',
    component: OverviewModule,
    exact: true,
  },
  name: 'Overview',
}

export default OverviewModule
