import React from 'react'
import PropTypes from 'prop-types'
import TrainingCases from './TrainingCases'
import style from './style.module.scss'

const DataExtraction = ({ setIsFormDirty, activeKey }) => {
  // Kept it like this, "in case we have to add something 'next' to it"
  return (
    <div className={style.DataExtraction}>
      <TrainingCases setIsFormDirty={setIsFormDirty} activeKey={activeKey} />
    </div>
  )
}

DataExtraction.defaultProps = {
  setIsFormDirty: () => { },
  activeKey: 'parentTabKey',
}

DataExtraction.propTypes = {
  setIsFormDirty: PropTypes.func,
  activeKey: PropTypes.string,
}

export default DataExtraction
