/*
  From hermes [22/11/22]
  hermes/src/helpers/ArrayHelper.js
*/

export const arrayPad = (arr, len, fill) => {
  return arr.concat(Array(len).fill(fill)).slice(0, len)
}

export const chunk = (arr, chunkSize) => {
  /**
   * This function split an array into chunks by the chunkSize
   * An example: chunk([1, 2, 3, 4], 2) will return [[1, 2], [3, 4]]
   */
  const chunks = []
  let i = 0

  while (i < arr.length) {
    chunks.push(arr.slice(i, i += chunkSize))
  }

  return chunks
}

export const difference = (array, ...otherArrays) => {
  const set = new Set()
  array.forEach((item) => {
    set.add(item)
  })
  otherArrays.forEach((otherArray) => {
    otherArray.forEach((item) => {
      set.delete(item)
    })
  })

  return [...set]
}

export const intersection = (...arrays) => {
  const map = new Map()
  arrays.forEach((array) => {
    array.forEach((item) => {
      map.set(item, (map.get(item) ?? 0) + 1)
    })
  })

  return [...map.keys()].filter((item) => {
    return map.get(item) === arrays.length
  })
}

export const without = (array, ...otherArrays) => {
  return array.filter((item) => {
    return otherArrays.indexOf(item) === -1
  })
}

export const uniq = (array) => {
  return [...new Set(array)]
}

export const index = (array, key) => {
  return array.reduce((prev, curr) => {
    prev[curr[key]] = curr
    return prev
  }, {})
}
