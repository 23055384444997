import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Tabs } from 'antd'
import ConversationsPage from '@core/components/ConversationsPage'
import API from '../../api'

const ConversationsFromSelectedDatasource = ({ selectedSentence, datasource }) => {
  const [conversations, setConversations] = useState([])
  useEffect(() => {
    const getConversations = async () => {
      const { data } = await API.getSentenceConversations(selectedSentence.id, { datasource })
      setConversations(data)
    }

    getConversations()
  }, [selectedSentence, datasource])

  return <ConversationsPage conversations={conversations} />
}

ConversationsFromSelectedDatasource.propTypes = {
  selectedSentence: PropTypes.shape({ id: PropTypes.string }).isRequired,
  datasource: PropTypes.oneOf(['staging', 'production']).isRequired,
}

const SentenceConversations = () => {
  const { sentences: { selectedSentence } } = useSelector(({ data }) => { return { ...data } })

  return (
    <Tabs>
      <Tabs.TabPane tab='Staging' key='StagingConversation'>
        <ConversationsFromSelectedDatasource selectedSentence={selectedSentence} datasource='staging' />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Production' key='ProductionConversation'>
        <ConversationsFromSelectedDatasource selectedSentence={selectedSentence} datasource='production' />
      </Tabs.TabPane>
    </Tabs>
  )
}

export default SentenceConversations
