import React, { useEffect, useState, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Layout,
  Typography,
  PageHeader,
  Descriptions,
  Button,
  Tag,
  Skeleton,
  Modal,
  Space,
  notification,
  Select,
} from 'antd'
import { useVersionDetail, useVersionCurrentlySelected } from '@core/hooks/useVersions'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'

import VersionsActions from '../../../store/actions/Versions'
import { tagColors } from '../../../constants/versionStatusColor'
import { versionDetailsType } from '../../../types/versionDetails'

const { Content } = Layout
const { Title } = Typography

const VersionDetails = ({ version }) => {
  return (
    <Descriptions column={1} bordered size='small'>
      <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='ID'>{version.id || '>> version.id <<'}</Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Note'>{version.note || '>> version.note <<'}</Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Internationlize version (i18n)'>{version.i18n_version || '>> version.i18n_version <<'}</Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Status'>
        {version.status.map((item, index) => { return <Tag key={index} color={tagColors[item]}>{item}</Tag> || '>> version.status <<' })}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label='Created at'>{version.created_at || '>> version.created_at <<'}</Descriptions.Item>
    </Descriptions>
  )
}

const deployConfirmation = (version, deployEnvs) => {
  Modal.confirm({
    title: 'Deploying version',
    width: '768px',
    content: (
      <Space direction='vertical'>
        <span>{`This action will deploy the following version to the ${deployEnvs.join(', ')} environment:`}</span>
        <VersionDetails version={version} />
        <strong>Are you sure?</strong>
      </Space>
    ),
    cancelText: 'No, Cancel',
    okText: 'Yes, confirm',
    onOk: async () => {
      try {
        const response = await VersionsActions.deploy(version.id, deployEnvs)

        if (response.status === 'success') {
          notification.success({
            message: 'Successfully Deployed',
            description: `Version: ${version.id} has been deployed`,
            duration: 5,
          })
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: error.message,
          duration: 5,
        })
      }
    },
  })
}

const ShowVersion = () => {
  const { version_id: versionId } = useParams()
  const version = useVersionDetail(versionId)
  const currentVersion = useVersionCurrentlySelected()
  const history = useHistory()
  const [deployEnvs, setDeployEnvs] = useState([])

  const onBack = () => { history.push('/versions/datasets') }
  const handleDeployEnvChange = (value) => {
    setDeployEnvs(value)
  }
  // useMemo required because lint error
  const deployableEnvs = useMemo(() => { return (['DEVELOPMENT', 'CANARY', 'STAGING', 'PRODUCTION']) }, [])

  const deployCurrentVersion = () => {
    deployConfirmation(version, deployEnvs)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!version) await VersionsActions.get()
    }

    fetchData()
  }, [version])

  useEffect(() => {
    // will crash the page if user view this page 'directly'
    // because the 'version' object is not exist
    // plaster fix is use the optional chanining + '||'
    const setData = async () => {
      const filtered = [].concat(version?.status || []).filter((env) => { return deployableEnvs.includes(env) })
      setDeployEnvs(filtered)
    }

    setData()
  }, [version, deployableEnvs])

  if (!version) {
    return (
      <Content className='ShowVersion'>
        <Skeleton active />
      </Content>
    )
  }

  return (
    <Content className='ShowVersion'>
      <PageHeader
        onBack={onBack}
        subTitle='Back to versions'
        extra={version.status !== 'ARCHIVE' ? [
          <PermittedFeatureCheck key={deployEnvs.length} featurePath='versions.actionDeploy'>
            <Select
              style={{ width: 300 }}
              mode='multiple'
              value={deployEnvs}
              placeholder='Select environment'
              allowClear
              onChange={handleDeployEnvChange}
            >
              {deployableEnvs.map((env, index) => {
                return (
                  <Select.Option value={env} key={index}>{env}</Select.Option>
                )
              })}
            </Select>
            <Button key='1' type='primary' onClick={deployCurrentVersion} disabled={!deployEnvs.length}>
              DEPLOY
            </Button>
          </PermittedFeatureCheck>,
        ] : undefined}
      >
        <Title level={4}>{`Version: ${versionId}`}</Title>
        {(currentVersion === versionId) && <Tag color='#108ee9'>This is currently selected version</Tag>}
      </PageHeader>
      <VersionDetails version={version} />
    </Content>
  )
}

VersionDetails.propTypes = {
  version: versionDetailsType.isRequired,
}

export default ShowVersion
