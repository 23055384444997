import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Space, Tag } from 'antd'
import {
  QuestionCircleFilled,
  DislikeFilled,
  LikeFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  StopOutlined,
} from '@ant-design/icons'

import style from './style.module.scss'

const CORRECTNESS_TAG_COLOR = {
  CORRECT: 'green',
  PARTIAL: 'geekblue',
  INCORRECT: 'red',
  'N/A': 'gold',
  NONE: 'default',
}

const getTagColor = (value) => {
  if (!value) return 'default'

  return CORRECTNESS_TAG_COLOR[value]
}

const FeedbackAndCorrectness = ({ tcFeedback, tcAccuracy, correctness }) => {
  return (
    <div className={style.FeedbackAndCorrectness}>
      <Space className={style.FeedbackAndAccuracy}>
        <Typography.Text strong>TC Feedback:</Typography.Text>
        <Space>
          {/* TC Feedback */}
          {/* Sentiment enum was store as lowercase on backend */}
          {tcFeedback === 'positive' && <LikeFilled className={style.FeedbackPositive} />}
          {tcFeedback === 'negative' && <DislikeFilled className={style.FeedbackNegative} />}
          {(!tcFeedback || tcFeedback === 'NONE' || tcFeedback === 'N/A') && <QuestionCircleFilled className={style.FeedbackEmpty} />}
          {/* TC Accuracy */}
          {/* Sentiment enum was store as uppercase on backend */}
          {tcAccuracy === 'CORRECT' && <CheckCircleOutlined className={style.AccuracyCorrect} />}
          {tcAccuracy === 'INCORRECT' && <CloseCircleOutlined className={style.AccuracyInCorrect} />}
          {(tcAccuracy === 'NONE' || tcAccuracy === 'N/A') && <StopOutlined className={style.AccuracyEmpty} />}
        </Space>
      </Space>
      <Space>
        <Typography.Text strong>E2E Correctness:</Typography.Text>
        <Tag color={getTagColor(correctness?.e2e)}>{correctness?.e2e || 'NONE'}</Tag>
      </Space>
    </div>
  )
}

FeedbackAndCorrectness.defaultProps = {
  tcFeedback: '',
  tcAccuracy: '',
  correctness: { e2e: 'NONE' },
}

FeedbackAndCorrectness.propTypes = {
  tcFeedback: PropTypes.string,
  tcAccuracy: PropTypes.string,
  correctness: PropTypes.shape({ e2e: PropTypes.string }),
}

export default FeedbackAndCorrectness
