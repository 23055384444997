import React from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Statistic,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import ConversationsChartsCountByMonth from './ConversationsChartsCountByMonth'
import ConversationsChartsTags from './ConversationsChartsTags'
import ConversationsChartsTopTagsCountByMonth from './ConversationsChartsTopTagsCountByMonth'
import ConversationsChartsTopTags from './ConversationsChartsTopTags'
import Api from '../../../api'
import { conversationsChartFilterType } from '../../../types'

const ConversationsChartsTab = ({ filter }) => {
  const { datasource, ...otherFilters } = filter

  const [{
    loading: chartLoading,
    data: chartData,
    hasError: chartHasError,
  }] = useRequest(Api.getAllChartData, otherFilters, { datasource })
  const [{
    loading: tagChartLoading,
    data: tagChartData,
    hasError: tagChartHasError,
  }] = useRequest(Api.getAllTagChartData, otherFilters, { datasource })

  if (tagChartLoading || !tagChartData || chartLoading || !chartData) {
    return <LoadingSkeleton />
  }

  if (tagChartHasError || chartHasError) {
    return <Typography.Text>We&apos;ve encountered some problems, please try again in few minutes</Typography.Text>
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={4}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Card>
              <Statistic title='Count' value={chartData.count} />
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Statistic title='Avg. Tokens / Conv.' value={chartData.averageWordCount} />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={20}>
        <Card>
          <ConversationsChartsCountByMonth data={chartData.conversationCountByMonth} />
        </Card>
      </Col>
      <Col span={24}>
        <Card>
          <ConversationsChartsTags data={tagChartData.conversationTags} />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <ConversationsChartsTopTags data={tagChartData.conversationTopTags} />
        </Card>
      </Col>
      <Col span={16}>
        <Card>
          <ConversationsChartsTopTagsCountByMonth
            tags={tagChartData.conversationTopTags.map(({ name }) => { return name })}
            data={tagChartData.conversationTopTagsCountByMonth}
          />
        </Card>
      </Col>
    </Row>
  )
}

ConversationsChartsTab.propTypes = {
  filter: conversationsChartFilterType.isRequired,
}

export default ConversationsChartsTab
