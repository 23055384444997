import React from 'react'
import { Spin } from 'antd'

import { useLogDisplay } from './useLogDisplay'
import LogSetting from './LogSetting'
import LogContent from './LogContent'

const LogDisplay = () => {
  const { logs, isLoading, loadingText } = useLogDisplay()

  if (!logs && isLoading) {
    return (
      <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
      </div>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: '-30px', right: '10px' }}>
        {loadingText}
        {' '}
        {isLoading && (
          <Spin />
        )}
      </div>
      <LogSetting />
      <LogContent />
    </div>
  )
}

export default LogDisplay
