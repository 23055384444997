import React, { useState } from 'react'
import {
  Row,
  Col,
  Typography,
  Statistic,
  Table,
  Button,
} from 'antd'
import dayjs from 'dayjs'
import { downloadJsonFile } from '@core/helpers'
import useRequest from '@core/hooks/useRequest'
import Api from '../../api'
import DatasetExpandedRow from './DatasetExpandedRow'

const { Text } = Typography

const DatasetTab = () => {
  const [{
    loading,
    data: info,
    hasError,
  }] = useRequest(Api.getDatasetInfo)

  const [downloading, setDownloading] = useState(false)

  const renderDatasetCountCol = ({ title, value }) => {
    return (
      <Col span={6} key={title}>
        <Statistic
          title={title}
          value={value}
          loading={loading}
        />
      </Col>
    )
  }

  const renderDatasetCountCols = () => {
    const items = [
      { title: 'Domain Training Sets', value: info.domainTrainingSetsCount },
      { title: 'Intent Training Sets', value: info.intentTrainingSetsCount },
      { title: 'Domain Testing Sets', value: info.domainTestSetsCount },
      { title: 'Intent Testing Sets', value: info.intentTestSetsCount },
    ]

    return items.map((item) => { return renderDatasetCountCol(item) })
  }

  const renderDatasetTable = () => {
    const dataSource = []

    Object.keys(info.domains).forEach((domainName, domainIndex) => {
      const domain = info.domains[domainName]
      dataSource.push({
        key: domainIndex,
        domain: domainName,
        intent: '',
        training: domain.trainingSetsCount,
        test: domain.testSetsCount,
      })

      Object.keys(domain.intents).forEach((intentName, intentIndex) => {
        const intent = domain.intents[intentName]
        dataSource.push({
          key: `${domainIndex}-${intentIndex}`,
          domain: '',
          intent: intentName,
          training: intent.trainingSetsCount,
          test: intent.testSetsCount,
        })
      })
    })

    const columns = [
      {
        title: 'Domain',
        dataIndex: 'domain',
        key: 'domain',
        render: (text) => { return <strong>{text}</strong> },
      },
      {
        title: 'Intent',
        dataIndex: 'intent',
        key: 'intent',
        render: (text) => { return <strong>{text}</strong> },
      },
      {
        title: 'Training',
        dataIndex: 'training',
        key: 'training',
      },
      {
        title: 'Test',
        dataIndex: 'test',
        key: 'test',
      },
    ]

    return (
      <Col span={24}>
        <Table
          dataSource={dataSource}
          columns={columns}
          size='small'
          pagination={false}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <DatasetExpandedRow
                  domain={record.domain}
                  intent={record.intent}
                />
              )
            },
          }}
        />
      </Col>
    )
  }

  const downloadDataset = async () => {
    setDownloading(true)

    const { data } = await Api.getDatasetDownload()
    downloadJsonFile(data, `dataset-${dayjs().format('YYYYMMDDhhmmss')}`)

    setDownloading(false)
  }

  return (
    <Row gutter={[24, 16]}>
      {hasError && <Col span={24}><Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text></Col>}
      {info && (
        <>
          {renderDatasetCountCols()}
          <Col span={24}>
            <Button onClick={downloadDataset} loading={downloading}>
              Download Dataset
            </Button>
          </Col>
          {renderDatasetTable()}
        </>
      )}
    </Row>
  )
}

export default DatasetTab
