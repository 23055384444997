import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Form,
  Alert,
  Input,
  Typography,
  Space,
} from 'antd'
import style from './style.module.scss'
import Api from '../../../api'

const EditIntentModal = ({
  visible,
  selectedIntent,
  onUpdated,
  onDismissed,
  currentVersion,
  datasource,
}) => {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)

  useEffect(() => {
    form.resetFields()
  }, [form])

  if (!visible) return null

  const onOk = async () => {
    form.submit()
  }

  const onCancel = async () => {
    setSubmitting(false)
    onDismissed() // close from upper
  }

  const handleFlashMessageClose = () => {
    setFlashMessage(null)
  }

  const onFinish = async (values) => {
    setFlashMessage(null)
    setSubmitting(true)

    try {
      const { new_intent_name: name } = values
      await Api.Intents.updateIntent({
        version: currentVersion,
        datasource,
        oldName: selectedIntent.name,
        newName: name,
      })
      setSubmitting(false)
      await onUpdated({}) // close/refresh from upper
    } catch (error) {
      setFlashMessage({ type: 'error', message: error.message })
      setSubmitting(false)
    }
  }

  return (
    <Modal
      title='Renaming Intent'
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
    >
      {flashMessage && (
        <Alert
          message={flashMessage.message}
          type={flashMessage.type}
          showIcon
          closable
          onClose={handleFlashMessageClose}
          style={{ marginBottom: 8 }}
        />
      )}
      <Space className={style.RenameIntent} direction='vertical'>
        <Space>
          <Typography.Text>Renaming:</Typography.Text>
          <Typography.Text strong>{selectedIntent.name}</Typography.Text>
        </Space>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            new_intent_name: selectedIntent.name,
          }}
        >
          <Form.Item
            label='New Intent name'
            name='new_intent_name'
            rules={[{ required: true, message: 'Please enter new intent name' }]}
          >
            <Input disabled={submitting} />
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  )
}

EditIntentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  selectedIntent: PropTypes.shape({
    name: PropTypes.string,
    positive: PropTypes.shape({
      id: PropTypes.number,
    }),
    negative: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  onUpdated: PropTypes.func.isRequired,
  onDismissed: PropTypes.func.isRequired,
  currentVersion: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  datasource: PropTypes.string.isRequired,
}

EditIntentModal.defaultProps = {
  selectedIntent: null,
}

export default EditIntentModal
