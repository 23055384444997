import { useSelector, shallowEqual } from 'react-redux'
import { useVersionSelector } from './useVersions'

export const usePermittedFeatures = () => {
  const permittedFeatures = useSelector(({ core }) => { return (core.permittedFeatures.authorization) }, shallowEqual)

  return permittedFeatures
}

export const usePermittedFeaturesLookup = (feature) => {
  return (
    useSelector(({ core }) => {
      const topLevelTransformed = core.permittedFeatures.authorization.transformed
      return feature.split('.')
      .reduce((object, key) => { return (object && object[key] ? object[key] : null) }, topLevelTransformed)
    })
  )
}

// NOTE: This is not used anymore
export const usePermittedRoutes = () => {
  const { routes } = useSelector(({ core }) => { return (core.permittedFeatures.authorization) }, shallowEqual)

  return routes || []
}

export const useRoutesToBuildTopMenu = () => {
  const { raw, transformed } = useSelector(({ core }) => { return (core.permittedFeatures.authorization) }, shallowEqual)
  const { current } = useVersionSelector()

  if (!transformed) return []

  const i18nVersion = current?.i18n_version
  const { utterances } = transformed
  const {
    versions,
    utterancesData,
    utterancesHierarchies,
    utterancesAnalysis,
    conversations,
    conversationsTags,
    conversationsQuery,
    conversationsResponseText,
    experiments,
    information,
    versionsI18n,
    versionsFaqs,
    faqs,
    conversationanalysis,
    clientgroups,
    conversationmanagement,
    conversationmanagementlogs,
    emailautomations,
    userDebug,
  } = raw

  const routes = []

  if (versions?.viewTab) {
    const children = [{ to: '/versions/datasets', title: 'Dataset' }]

    if (versionsI18n?.viewTab) {
      children.push({ to: '/versions/i18n', title: 'i18n' })
    }

    if (versionsFaqs?.viewTab) {
      children.push({ to: '/versions/faqs', title: 'FAQs' })
    }

    routes.push({
      title: 'Versions',
      children,
    })
  }
  if (utterances?.viewTab && utterances && Object.keys(utterances).length > 0) {
    const utterancesRoutes = {
      title: 'Utterances',
      children: [],
    }
    if (utterancesData?.viewTab) { utterancesRoutes.children.push({ to: '/utterances', title: 'Sentences' }) }
    if (utterancesHierarchies?.viewTab) { utterancesRoutes.children.push({ to: '/hierarchies', title: 'Hierarchies' }) }
    if (utterancesAnalysis?.viewTab) { utterancesRoutes.children.push({ to: '/analysis', title: 'Analysis' }) }

    routes.push(utterancesRoutes)
  }

  if (conversations?.viewTab) {
    const children = []

    if (conversationsQuery?.viewTab) {
      children.push({ to: '/conversations/query', title: 'Query' })
    }

    if (conversationsResponseText?.viewTab) {
      children.push({ to: `/conversations/response-text/version/${i18nVersion}`, title: 'Response Text' })
    }

    if (conversationsTags?.viewTab) {
      children.push({ to: '/conversations/tags', title: 'Tags' })
    }

    children.push({ to: '/conversations/simulation', title: 'Simulation' })

    routes.push({
      title: 'Conversations',
      children,
    })
  }
  if (conversationmanagement?.viewTab) {
    const children = []
    const parentPath = 'conversation-management'

    children.push({ to: `/${parentPath}/ptc`, title: 'PTC' })

    if (conversationmanagementlogs?.viewTab) {
      children.push({ to: `/${parentPath}/logs`, title: 'Logs' })
    }

    // TODO: remove fallback to userDebug?.viewTab
    if (conversationmanagement?.user?.viewTab ?? userDebug?.viewTab) {
      children.push({ to: `/${parentPath}/user`, title: 'User' })
    }

    routes.push({
      title: 'Conversation Management',
      children,
    })
  }

  if (emailautomations?.viewTab) { routes.push({ to: '/email-automations', title: 'Email Automations' }) }
  if (clientgroups?.viewTab) {
    const children = [
      { to: '/client-groups', title: 'Client Groups' },
      { to: '/client-groups/deployment-groups', title: 'Deployment Groups' },
    ]
    routes.push({
      title: 'Client Groups',
      children,
    })
  }
  if (information?.viewTab) { routes.push({ to: '/information', title: 'Information' }) }
  if (experiments?.viewTab) { routes.push({ to: '/experiments', title: 'Models' }) }
  if (faqs?.viewTab) { routes.push({ to: '/faqs', title: 'FAQs' }) }
  if (conversationanalysis?.viewTab) { routes.push({ to: '/conversation-analysis', title: 'Conversation Analysis' }) }

  return routes || []
}

export const usePermittedSentenceListItemActions = () => {
  const { utterances } = useSelector(({ core }) => { return (core.permittedFeatures.authorization.transformed) }, shallowEqual)

  return {
    allowEditSentence: utterances.data.actionEditSentence,
    allowViewEntities: utterances.data.viewDynamicEntities,
    allowViewSentenceNotes: utterances.data.viewSentenceNotes,
    allowViewSentenceDetails: utterances.data.viewSentenceDetails,
  }
}
