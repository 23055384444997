import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  Typography,
  Row,
  Col,
  Pagination,
} from 'antd'
import ConversationListItem from './ConversationListItem'
import {
  conversationsType,
  metaType,
  handlePaginationChangeType,
  onItemClickType,
  onToggleExcludeFromTrainingType,
  querySourceType,
} from '../../../Conversations/types'

const { Title } = Typography

const ConversationList = ({
  conversations,
  meta,
  handlePaginationChange,
  onItemClick,
  onToggleExcludeFromTraining,
  querySource,
  showStats,
  showActions,
  selectedConversationId,
}) => {
  const conversationsLength = conversations.length

  const pagination = (
    <Pagination
      defaultCurrent={meta.page}
      defaultPageSize={meta.perPage}
      total={meta.totalCount}
      showTotal={() => { return `Showing ${conversationsLength} of ${meta.totalCount.toLocaleString()} conversations` }}
      onChange={handlePaginationChange}
    />
  )

  const handleItemClick = (conversation) => {
    if (conversation?.id) {
      onItemClick(conversation)
    }
  }

  return (
    <List
      size='small'
      bordered
      className='conversation-list__list'
      header={(
        <Row justify='space-between'>
          <Col>
            <Title level={5}>
              {`Total Results: ${meta.totalCount.toLocaleString()} conversation${meta.totalCount > 1 ? 's' : ''}`}
            </Title>
          </Col>
          <Col>
            {pagination}
          </Col>
        </Row>
      )}
      footer={(
        <Row justify='end'>
          <Col>
            {pagination}
          </Col>
        </Row>
      )}
      dataSource={conversations}
      renderItem={(conversation) => {
        return (
          <ConversationListItem
            selectedConversationId={selectedConversationId}
            conversation={conversation}
            onClick={handleItemClick}
            onToggleExcludeFromTraining={onToggleExcludeFromTraining}
            querySource={querySource}
            showStats={showStats}
            showActions={showActions}
          />
        )
      }}
    />
  )
}

ConversationList.propTypes = {
  conversations: conversationsType.isRequired,
  meta: metaType.isRequired,
  handlePaginationChange: handlePaginationChangeType.isRequired,
  onToggleExcludeFromTraining: onToggleExcludeFromTrainingType.isRequired,
  onItemClick: onItemClickType.isRequired,
  querySource: querySourceType,
  showStats: PropTypes.bool,
  showActions: PropTypes.bool,
  selectedConversationId: PropTypes.string,
}

ConversationList.defaultProps = {
  querySource: undefined,
  showStats: true,
  showActions: true,
  selectedConversationId: '',
}

export default ConversationList
