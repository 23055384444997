import React from 'react'
import { useSelector } from 'react-redux'
import AddBulkTestCaseModal from './AddBulkTestCaseModal'
import AddBulkTrainingCaseModal from './AddBulkTrainingCaseModal'
import BulkUnrecognizedModal from './BulkUnrecognizedModal'

const BulkModalOperations = () => {
  const { visibility, selectedOperation } = useSelector(({ data }) => { return (data.bulkOperationModal) })

  if (!visibility) return null

  return (
    <>
      {selectedOperation === 'test' && <AddBulkTestCaseModal />}
      {selectedOperation === 'training' && <AddBulkTrainingCaseModal />}
      {selectedOperation === 'unrecognized' && <BulkUnrecognizedModal />}
    </>
  )
}

export default BulkModalOperations
