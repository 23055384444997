import React, { useState } from 'react'
import { Form, Typography } from 'antd'
import dayjs from 'dayjs'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import ExtractorExpectedData from '../../ExtractorFormItems/ExtractorExpectedData'
import ExtractorEvaluationIntent from '../../ExtractorFormItems/ExtractorEvaluationIntent'
import ExtractorSkipTest from '../../ExtractorFormItems/ExtractorSkipTest'
import ExtractorTestNote from '../../ExtractorFormItems/ExtractorTestNote'
import ExtractorRelativeDateInput from '../../ExtractorFormItems/ExtractorRelativeDateInput'
import { formInstanceType, sentenceType, extractorDataType } from '../../../types/form'

const { Title } = Typography

const ExtractorForm = ({
  form,
  sentence,
  extractorData,
}) => {
  const { intentCascader } = useDomainIntentSelector()
  const [domain_id] = useState(() => {
    let id = -1
    intentCascader.forEach((domain) => {
      return domain.children.forEach((intent) => {
        if (intent.value === extractorData.test_intent_id) id = domain.value
      })
    })
    return id
  })
  const relative_date = dayjs(extractorData.relative_date)
  return (
    <Form
      form={form}
      layout='vertical'
      name='form__test_extractor'
      initialValues={{
        expected_data: JSON.stringify(extractorData.expected_data, null, 5),
        note: extractorData.note,
        skip: extractorData.skip,
        test_intent_id: [domain_id, extractorData.test_intent_id],
        relative_date,
      }}
    >
      <Title level={4}>{`Update extracted data for: ${sentence}`}</Title>
      <ExtractorExpectedData
        rules={[
          {
            required: true,
            message: 'Please this cannot be empty',
          },
          () => {
            return {
              validator(_, value) {
                try {
                  JSON.parse(value)
                  return Promise.resolve()
                } catch (e) {
                  return Promise.reject(e.message)
                }
              },
            }
          },
        ]}
      />
      <ExtractorEvaluationIntent
        intentCascader={intentCascader}
        rules={[
          {
            required: true,
            message: 'Please selected desired Domain/Intent',
          },
        ]}
      />
      <ExtractorSkipTest />
      <ExtractorTestNote />
      <ExtractorRelativeDateInput />
    </Form>
  )
}

ExtractorForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
  extractorData: extractorDataType.isRequired,
}

export default ExtractorForm
