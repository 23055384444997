// closure to export query maybe?
import { useEffect, useReducer } from 'react'
import StoreUtils from '@core/utils/store'
import { reHashIndex } from '@core/helpers'
import { useVersionSelector } from '@core/hooks/useVersions'
import Api from '@core/api'

// NOTE: There 'might be' a possible issue here
// when it's first mount, it assign variable
// but when hooks unmount, how are we clearing this variable, js is gc actually...
let responseTextActionInstance

const sortParams = {
  ascend: 'asc',
  descend: 'desc',
}

const INITIAL_STATE = {
  loading: false,
  hasError: false,
  data: [],
  meta: null,
  hashedIndex: {},
  tableMeta: {
    pagination: {},
    filters: {},
    sorter: {},
  },
  selectedResponseText: null,
  newResponseText: {
    loading: false,
    hasError: false,
  },
  editResponseText: {
    loading: false,
    hasError: false,
  },
  deleteResponseText: {
    loading: false,
    hasError: false,
  },
  error: null,
}

export const ACTION_REQUEST = 'REQUEST'
export const ACTION_SUCCESS = 'SUCCESS'
export const ACTION_FAILED = 'FAILED'

export const RESPONSE_TEXT = {
  FETCH: StoreUtils.createActionStatus('RESPONSE_TEXT/FETCH'),
  ADD: StoreUtils.createActionStatus('RESPONSE_TEXT/ADD'),
  EDIT: StoreUtils.createActionStatus('RESPONSE_TEXT/EDIT'),
  DELETE: StoreUtils.createActionStatus('RESPONSE_TEXT/DELETE'),
  SELECT: 'RESPONSE_TEXT/SELECT',
  DESELECT: 'RESPONSE_TEXT/DESELECT',
  TABLE_UI_SYNC: 'RESPONSE_TEXT/TABLE_UI_SYNC',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case RESPONSE_TEXT.TABLE_UI_SYNC: {
      return {
        ...state,
        tableMeta: {
          ...action.payload,
        },
      }
    }
    case RESPONSE_TEXT.SELECT: {
      return {
        ...state,
        selectedResponseText: action.payload,
      }
    }
    case RESPONSE_TEXT.DESELECT: {
      return {
        ...state,
        selectedResponseText: null,
      }
    }
    case RESPONSE_TEXT.FETCH.REQUEST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        data: [],
        meta: null,
        error: null,
      }
    }
    case RESPONSE_TEXT.FETCH.SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        hashedIndex: action.payload.hashedIndex,
        data: action.payload.data,
        meta: action.payload.meta,
        // NOTE: This might needs a special case on the reducer
        // this should trigger save data from the payload 'only' on the page load request
        // meaning initial mount
        tableMeta: action.payload.tableMeta ? action.payload.tableMeta : state.tableMeta,
      }
    }
    case RESPONSE_TEXT.FETCH.FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      }
    }
    case RESPONSE_TEXT.ADD.REQUEST: {
      return {
        ...state,
        newResponseText: {
          loading: true,
          hasError: false,
        },
      }
    }
    case RESPONSE_TEXT.ADD.SUCCESS: {
      return {
        ...state,
        newResponseText: {
          ...INITIAL_STATE.newResponseText,
        },
      }
    }
    case RESPONSE_TEXT.ADD.FAILED: {
      return {
        ...state,
        newResponseText: {
          loading: false,
          hasError: true,
        },
      }
    }
    case RESPONSE_TEXT.EDIT.REQUEST: {
      return {
        ...state,
        editResponseText: {
          loading: true,
          hasError: false,
        },
      }
    }
    case RESPONSE_TEXT.EDIT.SUCCESS: {
      return {
        ...state,
        data: action.payload.responseTexts,
        editResponseText: {
          ...INITIAL_STATE.editResponseText,
        },
      }
    }
    case RESPONSE_TEXT.EDIT.FAILED: {
      return {
        ...state,
        editResponseText: {
          loading: false,
          hasError: true,
        },
      }
    }
    case RESPONSE_TEXT.DELETE.REQUEST: {
      return {
        ...state,
        deleteResponseText: {
          loading: true,
          hasError: false,
        },
      }
    }
    case RESPONSE_TEXT.DELETE.SUCCESS: {
      return {
        ...state,
        deleteResponseText: {
          ...INITIAL_STATE.deleteResponseText,
        },
      }
    }
    case RESPONSE_TEXT.DELETE.FAILED: {
      return {
        ...state,
        deleteResponseText: {
          loading: false,
          hasError: true,
        },
      }
    }
    default: throw new Error('MISSING REDUCER EVENT')
  }
}

const ResponseTextActions = (dispatch, state, app, i18nVersion) => {
  return {
    fetch: async (params) => {
      dispatch({ type: RESPONSE_TEXT.FETCH.REQUEST })
      try {
        const payload = { ...params, app, version: i18nVersion }
        const response = await Api.Internationalize.Version.getResponseMessage(payload)

        if (response && response.status === 'success') {
          dispatch({
            type: RESPONSE_TEXT.FETCH.SUCCESS,
            payload: {
              data: response.data.items,
              meta: response.data.meta,
              hashedIndex: reHashIndex(response.data.items),
            },
          })

          return response.data
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: RESPONSE_TEXT.FETCH.FAILED, error })

        throw error
      }
    },
    add: async (data) => {
      dispatch({ type: RESPONSE_TEXT.ADD.REQUEST })
      try {
        const payload = { ...data, app, version: i18nVersion }
        const response = await Api.Internationalize.Version.addResponseMessage(payload)
        // A MUST HAVE FIELD
        // key, value, app : all of them are strings

        if (response && response.status === 'success') {
          dispatch({ type: RESPONSE_TEXT.ADD.SUCCESS })
          const { tableMeta } = state
          const { sorter } = tableMeta

          const fetchParams = {
            page: state.meta.page,
            per_page: state.meta.per_page,
            app,
            version: i18nVersion,
          }

          if (sorter?.field && sorter?.order) fetchParams.sort = `${sorter.field}.${sortParams[sorter.order]}`

          await responseTextActionInstance.fetch(fetchParams)

          return response.data
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: RESPONSE_TEXT.ADD.FAILED, error })

        throw error
      }
    },
    edit: async (data) => {
      dispatch({ type: RESPONSE_TEXT.EDIT.REQUEST })
      try {
        const { id } = data
        const payload = { ...data, app, version: i18nVersion }
        delete payload.id
        const response = await Api.Internationalize.Version.editResponseMessage(id, payload)
        // All fields are optional, but the add one require these
        // key, value, app : all of them are strings

        if (response && response.status === 'success') {
          const { hashedIndex } = state
          const responseTexts = [...state.data] // shallow copy
          const sentenceIndex = hashedIndex[response.data.id]
          responseTexts[sentenceIndex] = {
            ...responseTexts[sentenceIndex],
            ...response.data,
          }

          dispatch({
            type: RESPONSE_TEXT.EDIT.SUCCESS,
            payload: {
              responseTexts,
            },
          })

          return response.data
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: RESPONSE_TEXT.EDIT.FAILED, error })

        throw error
      }
    },
    delete: async (data) => {
      dispatch({ type: RESPONSE_TEXT.DELETE.REQUEST })
      try {
        const response = await Api.Internationalize.Version.delete(data.id)

        if (response && response.status === 'success') {
          dispatch({ type: RESPONSE_TEXT.DELETE.SUCCESS })
          const { tableMeta } = state
          const { sorter } = tableMeta

          const fetchParams = {
            page: state.meta.page,
            per_page: state.meta.per_page,
            app,
            version: i18nVersion,
          }

          if (sorter?.field && sorter?.order) fetchParams.sort = `${sorter.field}.${sortParams[sorter.order]}`

          // NOTE: Technically this should fetch a new results from the backend
          // so if the row was successfully deleted from backend we don't have to do anything here
          await responseTextActionInstance.fetch(fetchParams)

          return response
        }

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: RESPONSE_TEXT.DELETE.FAILED, error })

        throw error
      }
    },
    syncTableUiState: (pagination, filters, sorter) => {
      dispatch({
        type: RESPONSE_TEXT.TABLE_UI_SYNC,
        payload: {
          pagination,
          filters,
          sorter,
        },
      })
    },
    selectSentence: (sentence) => { return dispatch({ type: RESPONSE_TEXT.SELECT, payload: sentence }) },
    deselectSentence: () => { return dispatch({ type: RESPONSE_TEXT.DESELECT }) },
  }
}

export const useResponseText = (app, version) => {
  const { current } = useVersionSelector()
  const i18nVersion = version || current?.i18n_version
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  responseTextActionInstance = ResponseTextActions(dispatch, state, app, i18nVersion)

  // NOTE: Disable this eslint here to clarify hooks used for cleanup only
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      // is this the right way to cleanup?
      responseTextActionInstance = null
    }
  }, [])

  return [state, responseTextActionInstance]
}
