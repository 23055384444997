import React from 'react'
import PropTypes from 'prop-types'

import BaseAddOrEditTestCollectionButton from '../BaseAddOrEditTestCollectionButton'

import Api from '../../../../../api'

const { updateDescriptionByTestCollectionId } = Api.EndToEndTestCollection

const submitHandlerWrapper = (id) => { return (data) => { return updateDescriptionByTestCollectionId(id, data) } }

const EditTestCollectionButton = ({
  buttonProps,
  afterSuccessHandler,
  initialValues,
  modalTitle,
  testCollectionId,
}) => {
  return (
    <BaseAddOrEditTestCollectionButton
      buttonProps={buttonProps}
      submitHandler={submitHandlerWrapper(testCollectionId)}
      afterSuccessHandler={afterSuccessHandler}
      initialValues={initialValues}
      modalTitle={modalTitle}
    />
  )
}

EditTestCollectionButton.propTypes = {
  buttonProps: PropTypes.shape({
    size: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  afterSuccessHandler: PropTypes.func,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
  }),
  modalTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  testCollectionId: PropTypes.string.isRequired,
}

EditTestCollectionButton.defaultProps = {
  buttonProps: {
    size: 'small',
    type: 'secondary',
    text: 'Add New Collection',
  },
  afterSuccessHandler: () => { },
  initialValues: {
    description: null,
  },
  modalTitle: 'Edit',
}

export default EditTestCollectionButton
