import cloneDeep from 'lodash.clonedeep'
import {
  SENTENCE,
  SENTENCE_NOTES,
  DYNAMIC_ENTITIES,
  RIGHT_PANEL,
  DRAWER,
  SELECTED_SENTENCE,
} from '../constants'

const INITIAL_STATE = {
  isPending: false,
  hasError: false,
  filter: {},
  meta: {},
  listed: [],
  sentencesHash: {},
  selectedSentence: {
    id: null,
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SENTENCE.FETCH.REQUEST: {
      return {
        ...state,
        hasError: false,
        isPending: true,
        filter: action.payload.filter,
      }
    }
    case SENTENCE.FETCH.FAILED: {
      return {
        ...INITIAL_STATE,
        filter: state.filter,
        hasError: true,
      }
    }
    case SENTENCE.DELETE.SUCCESS:
    case SENTENCE.FETCH.SUCCESS: {
      return {
        ...state,
        isPending: false,
        hasError: false,
        meta: action.payload.meta || state.meta,
        listed: action.payload.listed,
        sentencesHash: action.payload.sentencesHash,
      }
    }
    case SENTENCE.DELETE.FAILED: {
      return {
        ...state,
        hasError: true,
        isPending: false,
      }
    }
    case SENTENCE.BULK_MODE.TOGGLE_UNRECOGNIZED.FAILED:
    case SENTENCE.BULK_MODE.APPLY_TRAINING.FAILED:
    case SENTENCE.BULK_MODE.APPLY_TEST.FAILED: {
      return {
        ...state,
        isPending: false,
      }
    }
    case SENTENCE.BULK_MODE.TOGGLE_UNRECOGNIZED.SUCCESS:
    case SENTENCE.BULK_MODE.APPLY_TEST.SUCCESS:
    case SENTENCE.BULK_MODE.APPLY_TRAINING.SUCCESS: {
      return {
        ...state,
        listed: action.payload.newSentencesSet,
      }
    }
    case SENTENCE.EDIT.REQUEST:
      return {
        ...state,
        hasError: false,
        isPending: true,
      }
    case SENTENCE.EDIT.SUCCESS:
      return {
        ...state,
        hasError: false,
        isPending: false,
        listed: action.payload,
      }
    case SENTENCE.EDIT.FAILED:
      return {
        ...state,
        hasError: true,
        isPending: false,
      }
    case SENTENCE.SELECTED:
      return {
        ...state,
        selectedSentence: action.payload.sentence,
      }
    case DRAWER.TOGGLE_VISIBILITY: {
      if (!action.payload.visible) {
        return {
          ...state,
          selectedSentence: {
            id: null,
          },
        }
      }
      return state
    }
    case RIGHT_PANEL.CLOSE:
      return {
        ...state,
        selectedSentence: {
          id: null,
        },
      }

    case SELECTED_SENTENCE.EDIT.SUCCESS: {
      // NOTE: In the case where it loads on the specific sentence page
      if (!state.listed.length) return state

      const { listed, sentencesHash } = state
      const newListed = cloneDeep(listed)

      newListed[sentencesHash[action.payload.id]] = {
        ...newListed[sentencesHash[action.payload.id]],
        ...action.payload,
      }

      return {
        ...state,
        listed: newListed,
      }
    }

    // Dynamic entities
    case DYNAMIC_ENTITIES.EDIT.SUCCESS:
      return {
        ...state,
        selectedSentence: {
          ...state.selectedSentence,
          data: action.payload.data,
        },
        listed: state.listed.map((sentence) => {
          if (sentence.id === action.payload.id) sentence = action.payload
          return sentence
        }),
      }

    // Sentences Notes
    case SENTENCE_NOTES.FETCH.FAILED:
      return {
        ...state,
        selectedSentence: null,
      }
    case SENTENCE_NOTES.ADD_NEW.SUCCESS:
      return {
        ...state,
        listed: state.listed.map((sentence) => {
          if (sentence.id === action.payload.sentenceId) sentence.notes_count++
          return sentence
        }),
      }
    case SENTENCE_NOTES.DELETE.SUCCESS:
      return {
        ...state,
        listed: state.listed.map((sentence) => {
          if (sentence.id === action.payload.sentenceId) sentence.notes_count--
          return sentence
        }),
      }
    // Sentences
    case SENTENCE.FETCH.RESET: return INITIAL_STATE
    case SENTENCE.FETCH.PENDING:
    default: return state
  }
}
