import React, { useEffect, useState } from 'react'
import { Layout, Tabs, Empty, Radio, Space } from 'antd'
import dayjs from 'dayjs'
import { filterEmailFromSearchParams } from '@core/helpers/searchParams'
import LeftSider from '../../components/PTC/PTCLeftSider'
import DataTab from '../../components/PTC/DataTab'
import IssueTab from '../../components/PTC/IssueTab'
import { UTC, BKK } from '../../constants'

const { Content } = Layout
const { TabPane } = Tabs

const DATA_TAB_KEY = 'data'
const ISSUE_TAB_KEY = 'issue'

const datasources = ['production', 'staging', 'canary']
const initialDataTabFilterValues = { datasource: 'production', page: 1, perPage: 10 }
const initialIssueTabFilterValues = { datasource: 'production' }
const disabledFilterValues = null

dayjs.extend(require('dayjs/plugin/timezone'))
dayjs.extend(require('dayjs/plugin/utc'))

const TIMEZONES = {
  UTC: {
    value: UTC,
    formatter: (value) => { return dayjs(value).utc().format('DD/MM/YYYY HH:mm:ss') },
  },
  BKK: {
    value: BKK,
    formatter: (value) => { return dayjs(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss') },
  },
}

const NotificationsPage = () => {
  const [timeZone, setTimeZone] = useState(TIMEZONES.UTC.value)
  const [tab, setTab] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : DATA_TAB_KEY,
  )
  const [filterDataTab, setFilterDataTab] = useState(null)
  const [filterIssueTab, setFilterIssueTab] = useState({ ...initialIssueTabFilterValues, _: Math.random() })

  const setInitialFilterFromSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filterAsString = searchParams.get('filter')
    if (!filterAsString) {
      setFilterDataTab(initialDataTabFilterValues)
    } else {
      try {
        const parsedFilter = JSON.parse(filterAsString)
        if (typeof parsedFilter === 'object' && parsedFilter !== null) {
          setFilterDataTab(Object.keys(parsedFilter).length ? parsedFilter : initialDataTabFilterValues)
        } else {
          throw new Error('parsedFilter is not an object, throw')
        }
      } catch (error) {
        setFilterDataTab(initialDataTabFilterValues)
      }
    }
  }

  const updateSearchParamsByFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filteredParams = filterEmailFromSearchParams(filterDataTab)
    searchParams.set('filter', JSON.stringify(filteredParams))

    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState(null, '', newRelativePathQuery)
  }

  useEffect(setInitialFilterFromSearchParams, [])

  useEffect(updateSearchParamsByFilter, [filterDataTab])

  const searchPTCLeftSider = (newFilter) => {
    newFilter.page = 1
    newFilter.perPage = 10
    setFilterDataTab({ ...newFilter, _: Math.random() })
  }

  const onReloadIssueTab = (newFilter) => {
    setFilterIssueTab({ ...newFilter, _: Math.random() })
  }

  const onSelectDatasource = (event) => {
    const { target: { value } } = event

    onReloadIssueTab({ datasource: value })
  }

  const handleTabChange = (newTab) => {
    window.location.hash = `#${newTab}`
    if (newTab === DATA_TAB_KEY) setFilterDataTab(initialDataTabFilterValues)
    else setFilterDataTab(disabledFilterValues)
    setTab(newTab)
  }

  const handlePaginationChange = (page, perPage) => {
    if (filterDataTab.perPage !== perPage) {
      setFilterDataTab((prevFilter) => { return { ...prevFilter, page: 1, perPage } })
    } else {
      setFilterDataTab((prevFilter) => { return { ...prevFilter, page, perPage } })
    }
  }

  const onTimeZoneChange = (e) => {
    setTimeZone(e.target.value)
  }

  return (
    <>
      {filterDataTab && <LeftSider initialFilterValues={filterDataTab} onSearch={searchPTCLeftSider} />}
      <Content>
        <Tabs
          activeKey={tab}
          onChange={handleTabChange}
          tabBarExtraContent={DATA_TAB_KEY && (
            <Radio.Group size='small' onChange={onTimeZoneChange} value={timeZone}>
              {
                Object.entries(TIMEZONES).map(([key, { value }]) => {
                  return (
                    <Radio.Button style={{ userSelect: 'none' }} key={key} value={value}>{value}</Radio.Button>
                  )
                })
              }
            </Radio.Group>
          )}
        >
          <TabPane key={DATA_TAB_KEY} tab='Data'>
            { filterDataTab ? (
              <DataTab
                filter={filterDataTab}
                timeFormatter={TIMEZONES[timeZone]?.formatter}
                handlePaginationChange={handlePaginationChange}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
          <TabPane key={ISSUE_TAB_KEY} tab='Issue'>
            {filterIssueTab ? (
              <>
                <Space>
                  <Radio.Group
                    size='small'
                    onChange={onSelectDatasource}
                    value={filterIssueTab.datasource}
                  >
                    {datasources.map((item, index) => {
                      return (
                        <Radio.Button key={index} value={item}>
                          {item.charAt(0).toUpperCase() + item.substring(1, item.length)}
                        </Radio.Button>
                      )
                    })}
                  </Radio.Group>
                </Space>
                <IssueTab
                  filter={filterIssueTab}
                  timeFormatter={TIMEZONES[timeZone]?.formatter}
                  onReload={onReloadIssueTab}
                />
              </>

            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Please make a search from the left side'
              />
            )}
          </TabPane>
        </Tabs>
      </Content>
    </>
  )
}

export default NotificationsPage
