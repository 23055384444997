import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ExperimentsIndex from './pages/ExperimentsIndex'

const ExperimentsModule = () => {
  return (
    <Switch>
      <Route path='/experiments' component={ExperimentsIndex} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/experiments',
    component: ExperimentsModule,
  },
  name: 'Experiment',
}

export default ExperimentsModule
