import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { filterEmailFromSearchParams } from '@core/helpers/searchParams'
import { EmailAutomationsContext } from '../../context'

import QueryParams from './QueryParams'
import SearchResult from './SearchResults'
import BulkEmailHistoryModal from './BulkEmailHistoryModal'
import style from './style.module.scss'

// As of Mon 19, Sept - don't sends any empty filter
// otherwise backend will throw Bad request
const INITIAL_FILTER = {
  page: 1,
  perPage: 50,
  language: ['en'],
}

const Emails = ({ clickToAnalyzeEmail }) => {
  const [filter, setFilter] = useState(null) // used on useRequest
  const [activeFilter, setActiveFilter] = useState(null) // used only for save search query present, can't use filter because it will trigger useRequest
  const [bulkHistoryModalVisible, setBulkHistoryModalVisible] = useState(false)
  const {
    datasource,
    permissions,
  } = useContext(EmailAutomationsContext)

  const { emails } = permissions ?? {}

  /* Modal State(s) related */
  const openBulkHistoryEmailModal = () => {
    setBulkHistoryModalVisible(true)
  }

  const closeBulkEmailHistoryModal = () => {
    setBulkHistoryModalVisible(false)
  }

  /* Non-Modal state(s) fn */
  const setInitialFilterFromSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filterAsString = searchParams.get('filter')
    const initialFilterValues = {
      emailEnvironment: datasource,
      ...INITIAL_FILTER,
    }

    if (!filterAsString) {
      setFilter(initialFilterValues)
      setActiveFilter(initialFilterValues)
    } else {
      try {
        const parsedFilter = JSON.parse(filterAsString)
        if (typeof parsedFilter === 'object' && parsedFilter !== null) {
          // forced assign emailEnvironment
          parsedFilter.emailEnvironment = datasource

          setFilter(Object.keys(parsedFilter).length ? parsedFilter : initialFilterValues)
          setActiveFilter(Object.keys(parsedFilter).length ? parsedFilter : initialFilterValues)
        } else {
          throw new Error('parsedFilter is not an object, throw')
        }
      } catch (error) {
        setFilter(initialFilterValues)
        setActiveFilter(initialFilterValues)
      }
    }
  }

  const updateSearchParamsByFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const filteredParams = filterEmailFromSearchParams(filter)
    searchParams.set('filter', JSON.stringify(filteredParams))
    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}#emails`
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const makeSearch = (newFilter) => {
    // makeSearch called from the left side filter, it should reset to page 1
    setFilter({ ...newFilter, perPage: 50, page: 1 })
    setActiveFilter({ ...newFilter, perPage: 50, page: 1 })
  }

  const onPaginationChange = (page, perPage) => {
    window.scrollTo(0, 0)
    // before we add the page, perPage to the initial filter, it does not have filter.perPage
    // so it resets to default first page
    if (filter.perPage !== perPage) {
      setFilter((prevFilter) => { return { ...prevFilter, page: 1, perPage } })
      setActiveFilter((prevFilter) => { return { ...prevFilter, page: 1, perPage } })
    } else {
      setFilter((prevFilter) => { return { ...prevFilter, page, perPage } })
      setActiveFilter((prevFilter) => { return { ...prevFilter, page, perPage } })
    }
  }

  useEffect(setInitialFilterFromSearchParams, [datasource])
  useEffect(updateSearchParamsByFilter, [filter])

  return (
    <div className={style.Emails}>
      {filter && (
        <QueryParams
          filter={filter}
          setFilter={setFilter}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          makeSearch={makeSearch}
        />
      )}
      {filter && (
        <SearchResult
          filter={filter}
          openBulkHistoryEmailModal={openBulkHistoryEmailModal}
          // pagination
          onPaginationChange={onPaginationChange}
          clickToAnalyzeEmail={clickToAnalyzeEmail}
        />
      )}
      {/* Antd Modal's visible is not unmounting state, but just UI */}
      {emails?.actionViewBulkHistory && (
        bulkHistoryModalVisible && (
          <BulkEmailHistoryModal
            visible={bulkHistoryModalVisible}
            closeBulkEmailHistoryModal={closeBulkEmailHistoryModal}
          />
        )
      )}
    </div>
  )
}

Emails.defaultProps = {
  clickToAnalyzeEmail: () => { },
}

Emails.propTypes = {
  clickToAnalyzeEmail: PropTypes.func,
}

export default Emails
