import React from 'react'
import { Typography, Space, Row, Col, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import style from './style.module.scss'

const PreprocessedSentence = ({
  selectedSentenceIndex,
  symbols,
  selectedPhrase,
  onSelectPhrase,
}) => {
  if (selectedSentenceIndex === null) return null // allows '0'

  const getClickableSymbolizedPhraseItem = (item) => {
    const shouldMarkSymbol = selectedPhrase && selectedPhrase.original === item.original && selectedPhrase.occurrenceIndex === item.occurrenceIndex
    const onSelectSymbol = () => { return onSelectPhrase(item) }

    if (item.sources && item.predictedSource) {
      return (
        <Tooltip
          arrowPointAtCenter
          color='white'
          title={(
            <Space direction='vertical'>
              <Typography.Text>{`Occurrence Index: ${item.occurrenceIndex}`}</Typography.Text>
              <Typography.Text>{`Entities: ${item.sources.join('|')}`}</Typography.Text>
              {item.predictedSource && <Typography.Text>{`Predicted: ${item.predictedSource}`}</Typography.Text>}
            </Space>
          )}
        >
          <Typography.Link
            className={style.Symbol}
            onClick={onSelectSymbol}
            mark={shouldMarkSymbol}
            code
          >
            {item.original}
          </Typography.Link>
        </Tooltip>
      )
    }

    return (
      <Typography.Link
        className={style.Symbol}
        onClick={onSelectSymbol}
        mark={shouldMarkSymbol}
      >
        {item.original}
      </Typography.Link>
    )
  }

  if (!Array.isArray(symbols) || !symbols.length) return null

  return (
    <Space direction='vertical' className={style.PreprocessedSentence}>
      <Space>
        <Tooltip
          color='black'
          title='Select the word or phrase to process, then select the "Tagger type" and finally choose the expected/desired value for it.'
        >
          <InfoCircleOutlined />
        </Tooltip>
        <Typography.Text strong>Processed Sentence:</Typography.Text>
      </Space>
      <Row gutter={[8, 0]}>
        {symbols.map((item) => {
          return (
            <Col key={JSON.stringify(item)}>
              {getClickableSymbolizedPhraseItem(item)}
            </Col>
          )
        })}
      </Row>
    </Space>
  )
}

PreprocessedSentence.defaultProps = {
  selectedSentenceIndex: null,
  symbols: [],
  selectedPhrase: null,
}

PreprocessedSentence.propTypes = {
  selectedSentenceIndex: PropTypes.number,
  symbols: PropTypes.arrayOf(PropTypes.shape({
    occurrenceIndex: PropTypes.number.isRequired,
    original: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(PropTypes.string),
    predictedSource: PropTypes.string,
  })),
  selectedPhrase: PropTypes.shape({
    occurrenceIndex: PropTypes.number.isRequired,
    original: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(PropTypes.string),
    predictedSource: PropTypes.string,
  }),
  onSelectPhrase: PropTypes.func.isRequired,
}

export default PreprocessedSentence
