import { useMemo, useReducer } from 'react'

const MOUSE_ENTER_ACTIONS = {
  POSITIVE: {
    ENTER: 'MOUSE_ENTER_ACTIONS.POSITIVE.ENTER',
    LEAVE: 'MOUSE_ENTER_ACTIONS.POSITIVE.LEAVE',
  },
  NEGATIVE: {
    ENTER: 'MOUSE_ENTER_ACTIONS.NEGATIVE.ENTER',
    LEAVE: 'MOUSE_ENTER_ACTIONS.NEGATIVE.LEAVE',
  },
}

const MOUSE_ENTER_INITIAL_STATE = {
  positive: false,
  negative: false,
}

const mouseEnterReducer = (state = MOUSE_ENTER_INITIAL_STATE, action) => {
  switch (action.type) {
    case MOUSE_ENTER_ACTIONS.POSITIVE.ENTER: {
      state.positive = true
      return state
    }
    case MOUSE_ENTER_ACTIONS.POSITIVE.LEAVE: {
      state.positive = false
      return state
    }
    case MOUSE_ENTER_ACTIONS.NEGATIVE.ENTER: {
      state.negative = true
      return state
    }
    case MOUSE_ENTER_ACTIONS.NEGATIVE.LEAVE: {
      state.negative = false
      return state
    }

    default: return state
  }
}

const useMouseEnter = () => {
  const [state, dispatch] = useReducer(mouseEnterReducer, MOUSE_ENTER_INITIAL_STATE)

  const {
    mouseEnterPositiveIntent,
    mouseLeavePositiveIntent,
    mouseEnterNegativeIntent,
    mouseLeaveNegativeIntent,
  } = useMemo(() => {
    return {
      mouseEnterPositiveIntent: () => {
        if (!state.positive) dispatch({ type: MOUSE_ENTER_ACTIONS.POSITIVE.ENTER })
      },
      mouseLeavePositiveIntent: () => {
        dispatch({ type: MOUSE_ENTER_ACTIONS.POSITIVE.LEAVE })
      },
      mouseEnterNegativeIntent: () => {
        if (!state.negative) dispatch({ type: MOUSE_ENTER_ACTIONS.NEGATIVE.ENTER })
      },
      mouseLeaveNegativeIntent: () => {
        dispatch({ type: MOUSE_ENTER_ACTIONS.NEGATIVE.LEAVE })
      },
    }
  }, [state.positive, state.negative])

  return [
    state,
    {
      mouseEnterPositiveIntent,
      mouseLeavePositiveIntent,
      mouseEnterNegativeIntent,
      mouseLeaveNegativeIntent,
    },
  ]
}

export default useMouseEnter
