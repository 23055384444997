import PropTypes from 'prop-types'

export const OnAuthenticationPropTypes = PropTypes.shape({
  status: PropTypes.bool,
  data: PropTypes.shape({
    sentAt: PropTypes.string,
    pairedAt: PropTypes.string,
  }),
})

export const OnConversationPropTypes = PropTypes.shape({
  status: PropTypes.bool,
  data: PropTypes.shape({
    conversationId: PropTypes.string,
    lastMessageSentAt: PropTypes.string,
  }),
})

export const OnCSATPropTypes = PropTypes.shape({
  status: PropTypes.bool,
  data: PropTypes.shape({
    csat: PropTypes.bool,
    extra: PropTypes.shape({}),
  }),
})

export const OnPTCPropTypes = PropTypes.shape({
  status: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})),
})

export const UserPropTypes = PropTypes.shape({
  userId: PropTypes.string.isRequired,
  email: PropTypes.string,
  metricsUserId: PropTypes.string,
  metricsUserEmail: PropTypes.string,
  channelUserId: PropTypes.string.isRequired,
  toChannel: PropTypes.string.isRequired,
  fromChannel: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  onAuthentication: OnAuthenticationPropTypes,
  onConversation: OnConversationPropTypes,
  onCSAT: OnCSATPropTypes,
  onPTC: OnPTCPropTypes,
})
