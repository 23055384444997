import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col, Skeleton, Result } from 'antd'
import useRequest from '@core/hooks/useRequest'
import { EmailAutomationsContext } from '../../../../../context'
import { SAMPLE_FORM_TYPE } from '../../../../../constants/extractors'
import Api from '../../../../../api'

import { ViewEmailModalContext, DataExtractionFormContext, EmailItemsUpdaterContext } from '../../context'

import SamplesList from './SamplesList'
import TrainingCasesForm from './Form'
import style from './style.module.scss'
import SelectableDesymbolizedEmailSentence from './SelectableDesymbolizedEmailSentence'

const SELECTED_SYMBOL_INITIAL_STATE = { sentenceIndex: null, symbolInfo: null }

const TrainingCases = ({ setIsFormDirty, activeKey }) => {
  const { updateSpecificEmailStatus } = useContext(EmailItemsUpdaterContext)
  const { emailId, emailStatus } = useContext(ViewEmailModalContext)
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const { setDataExtractionFormConfig } = useContext(DataExtractionFormContext)
  const dataSourceQueryParams = { version: currentVersion, datasource }
  const [formPanelOpen, setFormPanelOpen] = useState(false)
  const [caseType, setCaseType] = useState(null) // one of 'add' or 'edit'
  const [openingSample, setOpeningSample] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedSymbol, setSelectedSymbol] = useState(SELECTED_SYMBOL_INITIAL_STATE)

  const [
    { loading: extractionsLoading, data: extractionsInformation, hasError: extractionsHasError },
    { silentMakeRequest, makeRequest },
  ] = useRequest(Api.DataExtractions.getForOneEmail, dataSourceQueryParams, emailId)
  const [
    { loading: extractorTypesLoading, data: extractorsTypes },
  ] = useRequest(Api.DataExtractions.getExtractorTypes, dataSourceQueryParams)

  const onClickSymbol = (sentenceIndex, symbolInfo) => {
    setSelectedSymbol({ sentenceIndex, symbolInfo })

    setDataExtractionFormConfig((prevState) => {
      return {
        ...prevState,
        openExtractor: true,
      }
    })

    if (!caseType) setCaseType(SAMPLE_FORM_TYPE.NEW)
    if (!formPanelOpen) setFormPanelOpen(true)
  }

  const onSentenceChange = (sentenceIndex) => {
    setSelectedSymbol({ sentenceIndex, symbolInfo: null })
  }

  const onSymbolChange = (symbolInfo) => {
    setSelectedSymbol((prevState) => {
      return {
        sentenceIndex: prevState.sentenceIndex,
        symbolInfo,
      }
    })
  }

  const addNewCase = () => {
    setCaseType(SAMPLE_FORM_TYPE.NEW)
    setFormPanelOpen(true)
  }

  const editSample = (selectedSample) => {
    let selectedOption = null
    // Sentence index for the ui
    const sentenceIndex = extractionsInformation.sentences.findIndex(({ tokens }) => {
      const foundOption = tokens.find((token) => {
        return token.occurrenceIndex === selectedSample.occurrenceIndex && token.original === selectedSample.phrase
      })

      selectedOption = foundOption

      return Boolean(foundOption)
    })

    setSelectedSymbol({ sentenceIndex, symbolInfo: selectedOption })
    setOpeningSample(selectedSample)
    setCaseType(SAMPLE_FORM_TYPE.EDIT)
    setFormPanelOpen(true)
  }

  const closeFormPanel = () => {
    setSelectedSymbol(SELECTED_SYMBOL_INITIAL_STATE)
    setOpeningSample(null)
    setCaseType(null)
    setFormPanelOpen(false)
    setIsFormDirty(false)
  }

  const updateStatus = async (newStatus = 'TO_RECHECK') => {
    if (emailStatus === 'TO_RECHECK') return

    try {
      const response = await Api.Emails.upsert({
        id: emailId,
        status: newStatus,
        datasource,
        version: currentVersion,
      })

      if (response?.status === 'success') {
        updateSpecificEmailStatus(emailId, newStatus)
      }
    } catch (error) {
      console.error('DataExtraction::TrainingCase#updateStatus - error - ', error)
      console.error('DataExtraction::TrainingCase#updateStatus - error - could not update status')
      throw error
    }
  }

  const addNewTrainingCase = async (payload) => {
    if (!Array.isArray(payload.desiredLabels)) {
      throw new Error(`DataExtraction::TrainingCase#addNewTrainingCase - error - desiredLabels must be an array. Received ${typeof payload.desiredLabels}`)
    }
    let responses
    let results = { status: 'success', duplicatedDesiredValue: [] }
    const { desiredLabels, ...forSingleRequestPayload } = payload

    const nonDuplicateDesiredLabels = desiredLabels.filter((desiredLabel) => {
      const found = extractionsInformation.samples.findIndex((sample) => {
        return (
          (sample.phrase === payload.phrase)
          && (sample.extractorType === payload.extractorType)
          && (sample.occurrenceIndex === payload.occurrenceIndex)
          && (sample.desiredLabel === desiredLabel)
        )
      })

      if (found !== -1) {
        results.duplicatedDesiredValue.push(desiredLabel)
        return false
      }

      return true
    })

    if (!nonDuplicateDesiredLabels.length) {
      setIsFormDirty(false)
      return {
        status: 'allDuplicated',
        duplicatedDesiredValue: results.duplicatedDesiredValue,
      }
    }

    setIsSubmitting(true)

    try {
      responses = await Promise.allSettled(
        nonDuplicateDesiredLabels.map(async (desiredLabel) => {
          return Api.DataExtractions.upsertTrainingCase({
            version: currentVersion,
            datasource,
            emailId,
            data: {
              desiredLabel,
              ...forSingleRequestPayload,
            },
          })
        }),
      )

      const responseContainsRejectedRequest = responses.some((response) => { return response.status === 'rejected' })

      if (responseContainsRejectedRequest) {
        results.status = 'partial'
        results.failedItems = []
        responses.forEach((response, index) => {
          if (response.status === 'fulfilled') return
          results.failedItems.push(desiredLabels[index])
        })

        if (results.failedItems.length === responses.length) {
          results = {
            status: 'failed',
            reason: 'All requests were rejected',
          }
        }
      }

      if (emailStatus !== 'TO_RECHECK') await updateStatus('TO_RECHECK')
      await silentMakeRequest()

      setIsSubmitting(false)

      if (results.status === 'success') setSelectedSymbol(SELECTED_SYMBOL_INITIAL_STATE)

      setIsFormDirty(false)
      return results
    } catch (error) {
      console.error('DataExtraction::TrainingCase#addNewTrainingCase - error - ', error)
      setIsSubmitting(false)
      throw error
    }
  }

  const editExistingTrainingCase = async (payload) => {
    let response

    // check against all of them, prevent unnecessary update the same one
    const found = extractionsInformation?.samples.some((sample) => {
      return (
        (sample.phrase === payload.phrase)
        && (sample.extractorType === payload.extractorType)
        && (sample.occurrenceIndex === payload.occurrenceIndex)
        && (sample.desiredLabel === payload.desiredLabel)
      )
    })

    if (found) {
      return {
        status: 'duplicated',
      }
    }

    setIsSubmitting(true)
    try {
      response = await Api.DataExtractions.upsertTrainingCase({
        version: currentVersion,
        datasource,
        emailId,
        data: payload, // case id was inserted when it called this function already
      })

      if (response?.status === 'success') {
        // also update the status if it's not TO_RECHECK
        if (emailStatus !== 'TO_RECHECK') await updateStatus('TO_RECHECK')
        await silentMakeRequest()
      }

      setIsSubmitting(false)
      setIsFormDirty(false)

      return response
    } catch (error) {
      console.error('DataExtraction::TrainingCase#editExistingTrainingCase - error - ', error)
      setIsSubmitting(false)
      throw error
    }
  }

  const deleteSample = async (caseId) => {
    let response

    setIsSubmitting(true)

    try {
      response = await Api.DataExtractions.deleteTrainingCase({
        version: currentVersion,
        datasource,
        data: { ids: [caseId] },
      })

      if (response?.status === 'success') {
        await silentMakeRequest()
      }

      // if they're opening a same one, delete it
      // also: extractionsInformation here will be before update with silentMakeRequest()
      if (openingSample?.id === caseId || (extractionsInformation?.samples.length === 1 && formPanelOpen)) {
        setFormPanelOpen(false)
        setCaseType(null)
        setOpeningSample(null)
      }

      if (selectedSymbol) setSelectedSymbol(SELECTED_SYMBOL_INITIAL_STATE)
      setIsSubmitting(false)

      return response
    } catch (error) {
      console.error('DataExtraction::TrainingCase#deleteSample - error - ', error)
      setIsSubmitting(false)
      throw error
    }
  }

  useEffect(() => {
    setSelectedSymbol(SELECTED_SYMBOL_INITIAL_STATE)
    setFormPanelOpen(false)
    setCaseType(null)
    setOpeningSample(null)
  }, [emailId])

  useEffect(() => {
    closeFormPanel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  if (extractionsLoading || (extractionsLoading && !extractionsInformation)) return (<Skeleton active />)

  if (extractionsHasError) {
    return (
      <Result
        status='warning'
        title='There are some error when retrieving extraction information.'
        extra={(
          <Button type='primary' key='console' onClick={makeRequest}>
            Try again
          </Button>
        )}
      />
    )
  }

  return (
    <div className={style.TrainingCases}>
      <Row gutter={[16, 0]}>
        <Col span={formPanelOpen ? 16 : 24}>
          <SelectableDesymbolizedEmailSentence
            extractionsLoading={extractionsLoading}
            isSubmitting={isSubmitting}
            sentences={extractionsInformation?.sentences ?? []}
            onClickSymbol={onClickSymbol}
            selectedSymbol={selectedSymbol}
            emailId={emailId}
          />
          <SamplesList
            isInitializing={extractionsLoading}
            isSubmitting={isSubmitting}
            openingSampleId={openingSample?.id}
            formPanelOpen={formPanelOpen}
            addNewCase={addNewCase}
            editSample={editSample}
            deleteSample={deleteSample}
            samples={extractionsInformation?.samples}
            extractorsTypes={extractorsTypes
              ? Object.keys(extractorsTypes).map(
                (extractorTypeName) => {
                  return { text: extractorTypeName, value: extractorTypeName }
                },
              )
              : null}
          />
        </Col>
        <Col span={formPanelOpen ? 8 : 0}>
          {/* Force null render, antd usually 'kept' form state somewhere outside */}
          {
            formPanelOpen
              ? (
                <TrainingCasesForm
                  preprocessedEmailBodySentences={extractionsInformation?.preprocessedEmailBodySentences}
                  sentences={extractionsInformation?.sentences ?? []}
                  addNewTrainingCase={addNewTrainingCase}
                  editExistingTrainingCase={editExistingTrainingCase}
                  closeFormPanel={closeFormPanel}
                  onSentenceChange={onSentenceChange}
                  onSymbolChange={onSymbolChange}
                  extractorTypesLoading={extractorTypesLoading}
                  extractorsTypes={extractorsTypes}
                  isInitializing={extractionsLoading}
                  isSubmitting={isSubmitting}
                  selectedSymbol={selectedSymbol}
                  openingSample={openingSample}
                  caseType={caseType}
                  setIsFormDirty={setIsFormDirty}
                />
              )
              : null
          }
        </Col>
      </Row>
    </div>
  )
}

TrainingCases.defaultProps = {
  setIsFormDirty: () => { },
  activeKey: 'parentTabKey',
}

TrainingCases.propTypes = {
  setIsFormDirty: PropTypes.func,
  activeKey: PropTypes.string,
}

export default TrainingCases
