import { DRAWER, SENTENCE } from '../constants'

const INITIAL_STATE = {
  visible: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SENTENCE.SELECTED: {
      return { visible: true }
    }
    case DRAWER.TOGGLE_VISIBILITY: {
      return { visible: action.payload.visible }
    }
    default: return state
  }
}
