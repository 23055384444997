import React from 'react'
import dayjs from 'dayjs'
import { Form, Typography } from 'antd'
import { useDomainIntentSelector } from '@core/hooks/useDomainIntentReducer'
import ExtractorExpectedData from '../../ExtractorFormItems/ExtractorExpectedData'
import ExtractorEvaluationIntent from '../../ExtractorFormItems/ExtractorEvaluationIntent'
import ExtractorSkipTest from '../../ExtractorFormItems/ExtractorSkipTest'
import ExtractorTestNote from '../../ExtractorFormItems/ExtractorTestNote'
import ExtractorRelativeDateInput from '../../ExtractorFormItems/ExtractorRelativeDateInput'
import { formInstanceType, sentenceType } from '../../../types/form'

const { Title } = Typography

const ExtractorTestForm = ({ form, sentence }) => {
  const { intentCascader } = useDomainIntentSelector()
  const relative_date = dayjs()
  return (
    <Form
      initialValues={{ relative_date, expected_data: '{}' }}
      form={form}
      layout='vertical'
      name='form__test_extractor'
    >
      <Title level={4}>{`Update extracted data for: ${sentence}`}</Title>
      <ExtractorExpectedData
        rules={[
          {
            required: true,
            message: 'Please this cannot be empty',
          },
          () => {
            return {
              validator(_, value) {
                try {
                  JSON.parse(value)
                  return Promise.resolve()
                } catch (e) {
                  return Promise.reject(e.message)
                }
              },
            }
          },
        ]}
      />
      <ExtractorEvaluationIntent
        intentCascader={intentCascader}
        rules={[
          {
            required: true,
            message: 'Please selected desired Domain/Intent',
          },
        ]}
      />
      <ExtractorSkipTest />
      <ExtractorTestNote />
      <ExtractorRelativeDateInput />
    </Form>
  )
}

ExtractorTestForm.propTypes = {
  form: formInstanceType.isRequired,
  sentence: sentenceType.isRequired,
}

export default ExtractorTestForm
