import { useReducer, useMemo, useEffect } from 'react'

export const ACTION_RESET = 'reset'
export const ACTION_REQUEST = 'request'
export const ACTION_SUCCESS = 'success'
export const ACTION_FAILED = 'failed'

const initialState = {
  loading: false,
  data: null,
  hasError: false,
}

function reducer(state, action) {
  switch (action.type) {
    case ACTION_RESET:
      return {
        ...initialState,
      }
    case ACTION_REQUEST:
      return {
        ...state,
        loading: true,
        data: null,
        hasError: false,
      }
    case ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case ACTION_FAILED:
      return {
        ...state,
        loading: false,
        hasError: true,
      }
    default:
      throw new Error()
  }
}

export const useRequestReducer = () => { return useReducer(reducer, initialState) }

const useRequest = (requestFn, ...requestFnParams) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const stringRequestFnParams = JSON.stringify(requestFnParams)

  const memoizedRequestFn = useMemo(
    () => { return () => { return requestFn(...JSON.parse(stringRequestFnParams)) } },
    [requestFn, stringRequestFnParams],
  )

  const { dispatchActionRequest, dispatchActionSuccess, dispatchActionFailed } = useMemo(() => {
    return {
      dispatchActionRequest: () => { return dispatch({ type: ACTION_REQUEST }) },
      dispatchActionSuccess: (data) => {
        return dispatch({
          type: ACTION_SUCCESS,
          payload: {
            data,
          },
        })
      },

      dispatchActionFailed: () => { return dispatch({ type: ACTION_FAILED }) },
    }
  }, [])

  useEffect(() => {
    let isMounted = true

    const getData = async () => {
      dispatchActionRequest()

      try {
        const { data } = await memoizedRequestFn()
        if (isMounted) {
          dispatchActionSuccess(data)
        }
      } catch (error) {
        if (isMounted) {
          dispatchActionFailed()
        }
      }
    }

    getData()

    return () => {
      isMounted = false
    }
  }, [memoizedRequestFn, dispatchActionRequest, dispatchActionSuccess, dispatchActionFailed])

  const makeRequest = async () => {
    dispatchActionRequest()

    try {
      const { data } = await memoizedRequestFn()
      dispatchActionSuccess(data)
      return data
    } catch (error) {
      dispatchActionFailed()
      return null
    }
  }

  const silentMakeRequest = async () => {
    try {
      const { data } = await memoizedRequestFn()
      dispatchActionSuccess(data)
      return data
    } catch (error) {
      dispatchActionFailed()
      return null
    }
  }

  return [state, { makeRequest, silentMakeRequest, setData: dispatchActionSuccess }]
}

export default useRequest
