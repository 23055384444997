import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  PageHeader,
  Typography,
  Tabs,
  Radio,
  Space,
  notification,
} from 'antd'
import { MessageOutlined, SaveOutlined, CodeOutlined } from '@ant-design/icons'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'
import { useChatSimulator } from '../../hooks/useChatSimulator'
import {
  useSavedSimulation,
  useGetAllSavedSimulationEffect,
  saveChatSimulation,
  findOneSimulation,
} from '../../hooks/useSavedSimulation'
import useEndToEndTests, { loadToEndToEndReducer, findTestCasesFromExchangeSimulationId, updateTestCaseById, insert } from '../../hooks/useEndToEndTest'
import usePersistentDataSimulator, { setDate, impersonateUser } from '../../hooks/usePersistentDataSimulator'
import Simulator from '../../components/Simulation/Simulator'
import SavedSimulations from '../../components/Simulation/SavedSimulations'
import EndToEndTest from '../../components/Simulation/EndToEndTest'
import TestCaseManagement from '../../components/Simulation/TestCaseManagement'

import style from './index.module.scss'

const simulationTarget = ['Staging']

const TabPaneLabel = ({ title, isActive, icon }) => {
  return (
    <Typography.Text
      className={style.tabPaneLabel}
      strong={isActive}
    >
      {icon}
      {title}
    </Typography.Text>
  )
}

TabPaneLabel.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
}

const shouldUpdateControlReducer = (shouldUpdate, action) => {
  const { type, payload } = action
  switch (type) {
    case 'testCollections': {
      shouldUpdate.testCollections = payload.status
      return shouldUpdate
    }
    case 'collectionChoices': {
      shouldUpdate.collectionChoices = payload.status
      return shouldUpdate
    }
    case 'testCases': {
      shouldUpdate.testCases = payload.status
      return shouldUpdate
    }
    default: {
      throw Error(`Unknown action: ${type}`)
    }
  }
}

const ConversationSimulation = () => {
  const isProductionAllowed = usePermittedFeaturesLookup('conversations.simulation.actionSelectProduction')
  const isCanaryAllowed = usePermittedFeaturesLookup('conversations.simulation.actionSelectCanary')
  const isLocalAllowed = process.env.REACT_APP_ENV === 'development'
  const isLoadSimulationAllowed = usePermittedFeaturesLookup('conversations.simulation.viewLoadSimulationTab')
  const isEndToEndTestAllowed = usePermittedFeaturesLookup('conversations.simulation.viewEndToEndTestTab')
  const simulationTargetOptions = [...simulationTarget]
  const [shouldUpdate, shouldUpdateDispatcher] = useReducer(shouldUpdateControlReducer, {
    testCases: false,
    collectionChoices: false,
    testCollections: true,
  })
  const [
    {
      loading: savedSimulatorLoading,
      error: savedSimulatorError,
      data: { conversationSimulations },
    },
    dispatch,
  ] = useSavedSimulation()
  const [
    {
      conversationId,
      loading,
      data: chatSimulationData,
      data: {
        latestExchangeId,
        allowToUpdateStats,
        conversationData: {
          fromExchangeId = '',
          dynamicEntities,
          state,
          profile,
        },
        exchanges,
      },
      error: simulationError,
    },
    ChatSimulatorActions,
  ] = useChatSimulator()

  useEffect(() => {
    if (simulationError) {
      notification.error({
        message: simulationError.data.status,
        description: simulationError.original_stack,
        duration: 5,
      })
    }
  }, [simulationError])

  useEffect(() => {
    const htmlElem = document.getElementsByTagName('html')[0]
    const bodyElem = document.getElementsByTagName('body')[0]

    htmlElem.style.cssText = 'height:100%;'
    bodyElem.style.cssText = 'height:100%;'

    return () => {
      htmlElem.removeAttribute('style')
      bodyElem.removeAttribute('style')
    }
  }, [])

  // TODO: Maybe it is easier to store the whole raw data and transform here for rendering so that saving is easier
  const [persistentDataSimulator, persistentDataSimulatorDispatcher] = usePersistentDataSimulator()
  const [endToEndState, endToEndDispatcher] = useEndToEndTests()
  const [selectedSimulationTarget, setSelectedSimulationTarget] = useState(isLocalAllowed ? 'Local' : simulationTargetOptions[0])
  const [connectorMessage, setConnectorMessage] = useState(undefined)
  const [activeKey, setActiveKey] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : 'tab__simulator',
  )

  if (isProductionAllowed) {
    simulationTargetOptions.unshift('Production')
  }

  if (isCanaryAllowed) {
    simulationTargetOptions.push('Canary')
  }

  if (isLocalAllowed) {
    simulationTargetOptions.push('Local')
  }

  const onSelectedSimulationTargetChange = (event) => {
    const { target: { value } } = event
    setSelectedSimulationTarget(value)
  }

  const handleActiveKeyChange = (newActiveKey) => {
    window.location.hash = `#${newActiveKey}`
    setActiveKey(newActiveKey)
  }

  const switchTab = (tabKey) => {
    return () => {
      handleActiveKeyChange(tabKey)
    }
  }

  const saveSimulation = (saveInfomation) => {
    shouldUpdateDispatcher({ type: 'testCases', payload: { status: true } })
    return saveChatSimulation(
      dispatch,
      {
        saveInfomation,
        chatSimulationData,
      },
    )
  }

  const renderFetchError = (id) => {
    notification.error({
      message: 'Error',
      description: `We've run into the issue from retrieving test cases of conversation id: ${id}`,
      duration: 5,
    })
  }

  const loadToSimulator = (id) => {
    return async () => {
      const selectedSimulation = await findOneSimulation(dispatch, id)
      if (selectedSimulation) {
        const simulationLoaded = ChatSimulatorActions.load(selectedSimulation)

        if (simulationLoaded) {
          handleActiveKeyChange('tab__simulator')
        }
      } else {
        renderFetchError(id)
      }
    }
  }

  const loadToEndToEnd = (id, navigate = true) => {
    return async () => {
      const selectedSimulation = await findOneSimulation(dispatch, id)
      if (selectedSimulation) {
        const simulationLoaded = loadToEndToEndReducer(endToEndDispatcher, selectedSimulation)

        if (simulationLoaded) {
          // NOTE: the order of the id sends to query does not really matter
          const exchangeIds = simulationLoaded.rawExchanges.map((exchange) => { return (exchange.id) })
          const result = await findTestCasesFromExchangeSimulationId(endToEndDispatcher, exchangeIds)
          try {
            if (result && navigate) handleActiveKeyChange('tab__endToEnd')
          } catch (error) {
            renderFetchError(id)
          }
        }
      } else {
        renderFetchError(id)
      }
    }
  }

  const handleConnectorMessageChange = (event) => {
    const { target: { value } } = event
    setConnectorMessage(value)
  }

  // Persistent data
  const setMockDate = (momentDate) => {
    if (momentDate) setDate(persistentDataSimulatorDispatcher, momentDate.format('YYYY-MM-DD'))
    else {
      setDate(persistentDataSimulatorDispatcher, '')
    }
  }

  const setMockActiveUser = async (email) => {
    let user
    if (email) user = await impersonateUser(persistentDataSimulatorDispatcher, email)

    if (user) {
      ChatSimulatorActions.updateConversationData({
        profile: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        },
      })
    } else {
      notification.error({
        message: 'Error',
        description: `Could not find user with this email: ${email}`,
        duration: 5,
      })
    }
  }

  const resetSimulation = (trackConversationAnalytics) => {
    ChatSimulatorActions.reset(
      persistentDataSimulator.data,
      trackConversationAnalytics,
      selectedSimulationTarget.toLowerCase(),
    )
  }

  const clearActiveUser = () => {
    impersonateUser(persistentDataSimulatorDispatcher, null)
  }

  const simulateChat = (payload) => {
    if (!exchanges.length && persistentDataSimulator.data.date) {
      payload.chatDate = `${persistentDataSimulator.data.date}T00:00:00+00:00`
    }
    ChatSimulatorActions.simulate({ ...payload, connectorMessage }, selectedSimulationTarget.toLowerCase())
  }

  const isStagingEnv = selectedSimulationTarget.toLowerCase() === 'staging'

  useGetAllSavedSimulationEffect(dispatch) // Replace useEffect layers

  const updateExchangeTestCase = async (data) => {
    try {
      const response = await updateTestCaseById(endToEndDispatcher, endToEndState, data)
      return response // throw into the form underneath
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'We\'ve run into the issue from updating new test case',
        duration: 5,
      })

      throw error // throw into the form underneath
    }
  }

  const addExchangeTestCase = async (data) => {
    try {
      const response = await insert(endToEndDispatcher, endToEndState, data)
      return response // throw into the form underneath
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'We\'ve run into the issue from adding new test case',
        duration: 5,
      })

      throw error // throw into the form underneath
    }
  }

  return (
    <Layout.Content className='ConversationSimulation'>
      <PageHeader title='Conversation Simulator' />
      {/* If there is an exchange, disable env target */}
      <Space>
        <Typography.Text strong>Select environment:</Typography.Text>
        <Radio.Group disabled={exchanges.length > 0} size='small' onChange={onSelectedSimulationTargetChange} value={selectedSimulationTarget}>
          {simulationTargetOptions.map((item, index) => {
            return (
              <Radio.Button key={index} value={item}>{item}</Radio.Button>
            )
          })}
        </Radio.Group>
      </Space>
      <Tabs className='simulation_tabs' activeKey={activeKey} onChange={handleActiveKeyChange}>
        <Tabs.TabPane
          tab={(
            <TabPaneLabel
              title='Simulator'
              icon={<MessageOutlined />}
              isActive={activeKey === 'tab__simulator'}
            />
          )}
          key='tab__simulator'
          forceRender
        >
          <Simulator
            // simulation itself
            conversationId={conversationId}
            simulateChat={simulateChat}
            resetSimulation={resetSimulation}
            view={ChatSimulatorActions.view}
            revert={ChatSimulatorActions.revert}
            latestExchangeId={latestExchangeId}
            exchanges={exchanges}
            // simulation target
            selectedSimulationTarget={selectedSimulationTarget}
            // saving simulation
            saveSimulation={saveSimulation}
            savedSimulatorLoading={savedSimulatorLoading}
            savedSimulatorError={savedSimulatorError}
            // stats
            dynamicEntities={dynamicEntities}
            profile={profile}
            state={state}
            // flag
            loading={loading} // used for both stats and simulator
            allowToUpdateStats={allowToUpdateStats}
            updateConversationData={ChatSimulatorActions.updateConversationData}
            fromExchangeId={fromExchangeId}
            connectorMessage={connectorMessage}
            setConnectorMessage={handleConnectorMessageChange}
            // used for visiting the page via links
            loadToSimulator={loadToSimulator}
            // persistent data
            persistentDataSimulator={persistentDataSimulator}
            setMockDate={setMockDate}
            setMockActiveUser={setMockActiveUser}
            clearActiveUser={clearActiveUser}
          />
        </Tabs.TabPane>
        {isStagingEnv && isLoadSimulationAllowed ? (
          <Tabs.TabPane
            tab={(
              <TabPaneLabel
                title='Load Simulation'
                icon={<SaveOutlined />}
                isActive={activeKey === 'tab__loadSimulation'}
              />
            )}
            key='tab__loadSimulation'
            forceRender
          >
            <SavedSimulations
              loadToSimulator={loadToSimulator}
              loadToEndToEnd={loadToEndToEnd}
              savedSimulatorLoading={savedSimulatorLoading}
              conversationSimulations={conversationSimulations}
            />
          </Tabs.TabPane>
        ) : null}
        {isStagingEnv && isLoadSimulationAllowed ? (
          <Tabs.TabPane
            tab={(
              <TabPaneLabel
                title='Test case management'
                icon={<SaveOutlined />}
                isActive={activeKey === 'tab__testCaseManagement'}
              />
            )}
            key='tab__testCaseManagement'
            forceRender
          >
            <TestCaseManagement
              loadToEndToEnd={loadToEndToEnd}
              e2eState={endToEndState}
              updateExchangeTestCase={updateExchangeTestCase}
              addExchangeTestCase={addExchangeTestCase}
              parentTab={activeKey}
              shouldUpdate={shouldUpdate}
              shouldUpdateDispatcher={shouldUpdateDispatcher}
            />

          </Tabs.TabPane>
        ) : null}
        {isStagingEnv && isEndToEndTestAllowed ? (
          <Tabs.TabPane
            tab={(
              <TabPaneLabel
                title='End-to-End Test'
                icon={<CodeOutlined />}
                isActive={activeKey === 'tab__endToEnd'}
              />
            )}
            key='tab__endToEnd'
            forceRender
          >
            <EndToEndTest
              state={endToEndState}
              dispatch={endToEndDispatcher}
              switchToLoadSimulationTab={switchTab('tab__loadSimulation')}
              updateExchangeTestCase={updateExchangeTestCase}
              addExchangeTestCase={addExchangeTestCase}
            />
          </Tabs.TabPane>
        ) : null}
      </Tabs>
    </Layout.Content>
  )
}

export default ConversationSimulation
