import React from 'react'
import PropTypes from 'prop-types'
import style from './index.module.scss'
import autoSizeStyle from './autoSize.module.scss'

const JsonPreview = ({ content, autoSize }) => {
  let selectedStyle
  if (autoSize) selectedStyle = autoSizeStyle.JsonPreview
  else selectedStyle = style.JsonPreview

  return (
    <pre className={selectedStyle}>
      {content}
    </pre>
  )
}

JsonPreview.defaultProps = {
  content: '',
  autoSize: false,
}

JsonPreview.propTypes = {
  content: PropTypes.string,
  autoSize: PropTypes.bool,
}

export default JsonPreview
