import { useReducer } from 'react'

const initialState = {
  loading: false,
  data: null,
  hasError: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'request':
      return { loading: true, data: null, hasError: false }
    case 'success':
      return { loading: false, data: action.payload.data, hasError: false }
    case 'failed':
      return { loading: false, data: null, hasError: true }
    default:
      throw new Error()
  }
}

const useInformationCurrentReducer = () => { return useReducer(reducer, initialState) }

export default useInformationCurrentReducer
