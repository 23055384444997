import React, { useState, useImperativeHandle } from 'react'
import {
  Modal,
  Form,
  Input,
  Button,
  Result,
  Radio,
  Space,
  Typography,
} from 'antd'
import PropTypes from 'prop-types'

import AnalyzedResponse from './AnalyzedResponse'
import OnlyRulesBasedResponse from './OnlyRulesBasedResponse' // NOTE: This is temporary solution for not blocking comparison analyzer response

import style from './style.module.scss'

const AnalyzeEmailResponseModal = React.forwardRef(({
  visible,
  onCancel,
}, ref) => {
  const [analyzeEmail, setAnalyzeEmail] = useState({ body: null, countryOfEmployment: null, capId: null })
  const [activeTab, setActiveTab] = useState('apiResponse')
  const [analyzerType, setAnalyzerType] = useState('default') // NOTE: This is temporary solution for not blocking comparison analyzer response
  const [form] = Form.useForm()

  const onSubmit = (values) => {
    const { body, countryOfEmployment, capId } = values
    setAnalyzeEmail({ body, countryOfEmployment, capId })
  }

  // runs when modals close (clicks from outside area, clicked on close icon)
  const onCloseModal = () => {
    setAnalyzeEmail({ body: null, countryOfEmployment: null, capId: null })
    setActiveTab('apiResponse')
    setAnalyzerType('default') // NOTE: This is temporary solution for not blocking comparison analyzer response
    form.resetFields()
    onCancel()
  }

  // trigger useRequest underneath via props change by ref export
  useImperativeHandle(ref, () => {
    return {
      analyzeApiResponse: ({ body: emailBody }) => {
        form.setFieldsValue({ body: emailBody })
        const { body, countryOfEmployment, capId } = form.getFieldsValue(true)

        setActiveTab('apiResponse')
        setAnalyzeEmail({ body, countryOfEmployment: countryOfEmployment ?? 'th', capId: capId ?? 'test' })
      },
      analyzePreprocessed: ({ body: emailBody }) => {
        form.setFieldsValue({ body: emailBody })
        const { body, countryOfEmployment, capId } = form.getFieldsValue(true)

        setActiveTab('preprocessComparison')
        setAnalyzeEmail({ body, countryOfEmployment: countryOfEmployment ?? 'th', capId: capId ?? 'test' })
      },
      setBodyAndCountryOfEmploymentAndCapId: ({ body: emailBody, countryOfEmployment: emailCoe, capId: emailCapId }) => {
        // the snapshot when this is runs usually have "visible" set to false
        // but the actual component always rendered...
        form.setFieldsValue({ body: emailBody, countryOfEmployment: emailCoe, capId: emailCapId })
        const { body, countryOfEmployment, capId } = form.getFieldsValue(true)

        setAnalyzeEmail({ body, countryOfEmployment: countryOfEmployment ?? 'th', capId: capId ?? 'test' })
      },
    }
  })

  // NOTE: This is temporary solution for not blocking comparison analyzer response
  // new one relies on backend changes
  const getAnalyzerComponent = () => {
    if (analyzerType === 'default') return <OnlyRulesBasedResponse body={analyzeEmail.body} countryOfEmployment={analyzeEmail.countryOfEmployment} capId={analyzeEmail.capId} />

    return (
      <AnalyzedResponse
        body={analyzeEmail.body}
        countryOfEmployment={analyzeEmail.countryOfEmployment}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        capId={analyzeEmail.capId}
      />
    )
  }

  if (!visible) return null

  return (
    <Modal
      className={style.AnalyzeEmailResponseModal}
      width='90%'
      title='Analyze Email content'
      visible={visible}
      onCancel={onCloseModal}
      footer={null}
      destroyOnClose
    >
      <Form
        className={style.Form}
        form={form}
        onFinish={onSubmit}
        layout='vertical'
      >
        <Form.Item
          label='CAPID'
          name='capId'
          initialValue='TEST'
          rules={[
            {
              whitespace: true,
              required: true,
              message: 'Please input CAP ID',
            },
            {
              len: 4,
              message: 'CAP ID  should have 4 characters (ex. "AXBT")',
            },
          ]}
        >
          <Input maxLength={4} minLength={4} />
        </Form.Item>
        <Form.Item
          label='Country Of Employment'
          name='countryOfEmployment'
          initialValue='th'
          rules={[
            {
              whitespace: true,
              required: true,
              message: 'Please input Country Of Employment',
            },
            {
              len: 2,
              message: 'Country Of Employment should have 2 characters (ex. "th")',
            },
          ]}
        >
          <Input maxLength={2} />
        </Form.Item>
        <Form.Item
          required
          label='Email body'
          name='body'
          rules={[
            {
              whitespace: true,
              required: true,
              message: 'Please input the email content!',
            },
          ]}
        >
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 8 }}
            placeholder='Email content'
          />
        </Form.Item>
        <Form.Item className={style.Submit}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
      {/* NOTE: Temporary unblocker to show old one */}
      <Space gap={8}>
        <Typography.Text>Analyzer mode:</Typography.Text>
        <Radio.Group
          value={analyzerType}
          defaultValue='default'
          buttonStyle='solid'
          onChange={(event) => {
            const { target: { value } } = event

            setAnalyzerType(value)
          }}
        >
          <Radio.Button value='default'>Default</Radio.Button>
          <Radio.Button value='comparison'>Comparison</Radio.Button>
        </Radio.Group>
      </Space>
      {
        !analyzeEmail.body
          ? (<Result status='warning' title='Please enter email body to analyze' />)
          : (getAnalyzerComponent()) // NOTE: Temporary unblocker to show old one, it should be just a <AnalyzedResponse ... /> component
      }
    </Modal>
  )
})

AnalyzeEmailResponseModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default AnalyzeEmailResponseModal
