import { EDIT_SENTENCE_MODAL_UI } from '../constants'

const INITIAL_STATE = {
  visibility: false,
  data: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDIT_SENTENCE_MODAL_UI.OPEN: {
      return {
        ...state,
        visibility: true,
        data: action.payload.sentence,
      }
    }
    case EDIT_SENTENCE_MODAL_UI.CLOSE: {
      return {
        ...INITIAL_STATE,
      }
    }
    default: return state
  }
}
