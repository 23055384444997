import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Space } from 'antd'

import { EmailAutomationsContext } from '../../context'

import Emails from '../../components/Emails'
import Intents from '../../components/Intents'
import Models from '../../components/Models'
import DataExtraction from '../../components/DataExtraction'
import DataExtractionModels from '../../components/DataExtractionModels'
import Versions from '../../components/Versions'
import EmailPreprocessedItems from '../../components/EmailPreprocessedItems'

const VersionSelected = ({
  activeKey,
  setActiveKey,
  getTabTitleNode,
  renderSelectWorkingEnvironmentOptions,
  clickToAnalyzeEmail,
}) => {
  const { permissions } = useContext(EmailAutomationsContext)

  const {
    // realistically versions and emails should be always visible...
    versions,
    emails,
    intents,
    dataExtraction,
    intentModels,
    dataExtractionModels,
    preprocessed,
  } = permissions ?? {}

  return (
    <Tabs activeKey={activeKey} onChange={setActiveKey} type='card' destroyInactiveTabPane>
      {versions?.viewTab && (
        <Tabs.TabPane key='versions' tab={getTabTitleNode('versions', 'Versions')}>
          <Space direction='vertical'>
            {renderSelectWorkingEnvironmentOptions()}
            <Versions />
          </Space>
        </Tabs.TabPane>
      )}
      {emails?.viewTab && (
        <Tabs.TabPane key='emails' tab={getTabTitleNode('emails', 'Emails')}>
          <Emails clickToAnalyzeEmail={clickToAnalyzeEmail} />
        </Tabs.TabPane>
      )}
      {intents?.viewTab && (
        <Tabs.TabPane key='intents' tab={getTabTitleNode('intents', 'Intents')}>
          <Intents />
        </Tabs.TabPane>
      )}
      {dataExtraction?.viewTab && (
        <Tabs.TabPane key='dataExtraction' tab={getTabTitleNode('dataExtraction', 'Data Extraction')}>
          <DataExtraction />
        </Tabs.TabPane>
      )}
      {intentModels?.viewTab && (
        <Tabs.TabPane key='models' tab={getTabTitleNode('models', 'Intent Models')}>
          <Models />
        </Tabs.TabPane>
      )}
      {dataExtractionModels?.viewTab && (
        <Tabs.TabPane key='dataExtractionModels' tab={getTabTitleNode('dataExtractionModels', 'Data Extraction Models')}>
          <DataExtractionModels />
        </Tabs.TabPane>
      )}
      {preprocessed?.viewTab && (
        <Tabs.TabPane key='preprocessed' tab={getTabTitleNode('preprocessed', 'Preprocessed')}>
          <EmailPreprocessedItems />
        </Tabs.TabPane>
      )}
    </Tabs>
  )
}

VersionSelected.propTypes = {
  activeKey: PropTypes.string.isRequired,
  setActiveKey: PropTypes.func.isRequired,
  getTabTitleNode: PropTypes.func.isRequired,
  renderSelectWorkingEnvironmentOptions: PropTypes.func.isRequired,
  clickToAnalyzeEmail: PropTypes.func.isRequired,
}

const VersionUnselected = ({
  activeKey,
  setActiveKey,
  getTabTitleNode,
  renderSelectWorkingEnvironmentOptions,
}) => {
  const { permissions } = useContext(EmailAutomationsContext)

  const { versions } = permissions ?? {}

  return (
    <Tabs activeKey={activeKey} onChange={setActiveKey} type='card' destroyInactiveTabPane>
      {versions?.viewTab && (
        <Tabs.TabPane key='versions' tab={getTabTitleNode('versions', 'Versions')}>
          <Space direction='vertical'>
            {renderSelectWorkingEnvironmentOptions()}
            <Versions />
          </Space>
        </Tabs.TabPane>
      )}
    </Tabs>
  )
}

VersionUnselected.propTypes = {
  activeKey: PropTypes.string.isRequired,
  setActiveKey: PropTypes.func.isRequired,
  getTabTitleNode: PropTypes.func.isRequired,
  renderSelectWorkingEnvironmentOptions: PropTypes.func.isRequired,
}

const MainEmailAutomationTabPages = ({
  selectedWorkingVersion,
  activeKey,
  setActiveKey,
  getTabTitleNode,
  renderSelectWorkingEnvironmentOptions,
  clickToAnalyzeEmail,
}) => {
  if (selectedWorkingVersion) {
    return (
      <VersionSelected
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        getTabTitleNode={getTabTitleNode}
        renderSelectWorkingEnvironmentOptions={renderSelectWorkingEnvironmentOptions}
        clickToAnalyzeEmail={clickToAnalyzeEmail}
      />
    )
  }

  return (
    <VersionUnselected
      activeKey={activeKey}
      setActiveKey={setActiveKey}
      getTabTitleNode={getTabTitleNode}
      renderSelectWorkingEnvironmentOptions={renderSelectWorkingEnvironmentOptions}
      clickToAnalyzeEmail={clickToAnalyzeEmail}
    />
  )
}

MainEmailAutomationTabPages.defaultProps = {
  selectedWorkingVersion: null,
}

MainEmailAutomationTabPages.propTypes = {
  selectedWorkingVersion: PropTypes.string,
  activeKey: PropTypes.string.isRequired,
  setActiveKey: PropTypes.func.isRequired,
  getTabTitleNode: PropTypes.func.isRequired,
  renderSelectWorkingEnvironmentOptions: PropTypes.func.isRequired,
  clickToAnalyzeEmail: PropTypes.func.isRequired,
}

export default MainEmailAutomationTabPages
