import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

const ReactReduxProvider = ({ children, store }) => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

ReactReduxProvider.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/require-default-props
  store: PropTypes.shape({}),
}

export default ReactReduxProvider
