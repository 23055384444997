import React, { useState } from 'react'
import { Tabs, Typography } from 'antd'
import ModelsTable from './TrainedIntentModels'
import Training from './Training'
import Testing from './Testing'

const Models = () => {
  const [activeKey, setActiveKey] = useState('models')

  return (
    <>
      <Typography.Title level={4}>Email Automation Models</Typography.Title>
      <Tabs activeKey={activeKey} onChange={setActiveKey} destroyInactiveTabPane>
        <Tabs.TabPane key='models' tab='Models'>
          <ModelsTable />
        </Tabs.TabPane>
        <Tabs.TabPane key='training' tab='Training'>
          <Training />
        </Tabs.TabPane>
        <Tabs.TabPane key='testing' tab='Testing' disabled>
          <Testing />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default Models
