import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Layout,
  Form,
  Button,
  Alert,
  Table,
  Collapse,
  Input,
  Space,
} from 'antd'
import { EditOutlined, SearchOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import TaggerSelect from '@core/components/TaggerFormItems/TaggerSelect'
import TaggerTrainer from '../../../store/actions/taggers'

const { Panel } = Collapse
const { Sider, Content } = Layout

const DisplayTaggerTrainingsTab = () => {
  const history = useHistory()
  const {
    taggerTrainings: {
      trainingSets,
      isLoading,
      error,
    },
  } = useSelector((state) => { return state.experiments.taggers })

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
      /* eslint react/prop-types: 0 */
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => { return setSelectedKeys(e.target.value ? [e.target.value] : []) }}
              onPressEnter={() => { return handleSearch(selectedKeys, confirm, dataIndex) }}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type='primary'
                onClick={() => { return handleSearch(selectedKeys, confirm, dataIndex) }}
                icon={<SearchOutlined />}
                size='small'
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => { return handleReset(clearFilters) }} size='small' style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        )
      },
      filterIcon: (filtered) => { return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} /> },
      onFilter: (value, record) => {
        return (record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().match(new RegExp(`(?<!-)${value}`, 'gi'))
          : '')
      },
      render: (text) => { return (searchedColumn === dataIndex ? (text.replace(searchText, searchText.toUpperCase())) : text) },
    }
  }

  const columns = [
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      ...getColumnSearchProps('text'),
      sorter: (a, b) => { return a.text.localeCompare(b.text) },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Phrase',
      dataIndex: 'phrase',
      key: 'phrase',
      ...getColumnSearchProps('phrase'),
      sorter: (a, b) => { return a.phrase.localeCompare(b.phrase) },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Index',
      dataIndex: 'phrase_index',
      key: 'phrase_index',
      sorter: (a, b) => { return a.phrase_index - b.phrase_index },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Desired Result',
      key: 'desired_result',
      dataIndex: 'desired_result',
      ...getColumnSearchProps('desired_result'),
      sorter: (a, b) => { return a.desired_result.localeCompare(b.desired_result) },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <Button onClick={() => { return history.push(`/utterances/sentences/${record.sentence_id}`) }} icon={<EditOutlined />}>Edit</Button>
        )
      },
    },
  ]

  const [form] = Form.useForm()
  const handleOnFinish = async ({ tagger_type }) => { return TaggerTrainer.displayTaggerTrainings(tagger_type) }

  return (
    <Layout>
      <Sider>
        <Form layout='vertical' form={form} onFinish={handleOnFinish}>
          <TaggerSelect multiple rules={[{ required: true, message: 'Please selected Target tagger type' }]} />
          <Button loading={isLoading} type='primary' htmlType='submit' block>Display tagger trainings</Button>
        </Form>
      </Sider>
      <Content>
        {!trainingSets && null}
        {trainingSets && (
          <>
            {
              Object.keys(trainingSets).map((tagger_type, index) => {
                return (
                  <Collapse accordion>
                    <Panel header={`${tagger_type} [${trainingSets[tagger_type].trainings.length}]`} key={index}>
                      <Table columns={columns} dataSource={trainingSets[tagger_type].trainings} />
                    </Panel>
                  </Collapse>
                )
              })
            }
          </>
        )}
        {
          error ? <Alert banner closable message={error.message} type='error' /> : null
        }
      </Content>
    </Layout>
  )
}

export default DisplayTaggerTrainingsTab
