import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  Space,
  Divider,
} from 'antd'
import { SettingFilled } from '@ant-design/icons'

import { useInternationalizeVersionSelector } from '@core/hooks/useInternationalize'

const handleFalsyValue = (value) => { return value || '-' }

const ConfigPanel = ({
  i18nVersionId,
  connectorMessage,
  persistentDataSimulator,
  chatDate,
  toggleSettingPanel,
  shouldShowCurrentValue,
}) => {
  const i18nVersions = useInternationalizeVersionSelector().map((v) => { return { ...v, key: v.id } })
  const currentSelectedVersion = i18nVersions.find((item) => { return item.id === i18nVersionId })
  const { data: { user } } = persistentDataSimulator

  return (
    <Space>
      <Button onClick={toggleSettingPanel} icon={<SettingFilled />} />
      {shouldShowCurrentValue && (
        <>
          <Typography.Text strong>Connector message:&thinsp;&thinsp;</Typography.Text>
          <Typography.Text code mark>{handleFalsyValue(connectorMessage)}</Typography.Text>
          <Divider type='vertical' />
          <Typography.Text strong>Email:&thinsp;&thinsp;</Typography.Text>
          <Typography.Text code mark>{handleFalsyValue(user?.email)}</Typography.Text>
          <Divider type='vertical' />
          <Typography.Text strong>Chat date:&thinsp;&thinsp;</Typography.Text>
          <Typography.Text code mark>{handleFalsyValue(chatDate)}</Typography.Text>
          <Divider type='vertical' />
          <Typography.Text strong>i18n version:&thinsp;&thinsp;</Typography.Text>
          <Typography.Text code mark>{`[${handleFalsyValue(currentSelectedVersion?.id)}] ${handleFalsyValue(currentSelectedVersion?.note)}`}</Typography.Text>
        </>
      )}
    </Space>
  )
}

ConfigPanel.propTypes = {
  i18nVersionId: PropTypes.number.isRequired,
  connectorMessage: PropTypes.string,
  persistentDataSimulator: PropTypes.shape({
    email: PropTypes.string,
    loading: PropTypes.bool,
    data: PropTypes.shape({
      date: PropTypes.string,
      user: PropTypes.shape({
        email: PropTypes.string,
      }),
    }),
  }).isRequired,
  chatDate: PropTypes.string,
  toggleSettingPanel: PropTypes.func.isRequired,
  shouldShowCurrentValue: PropTypes.bool.isRequired,
}

ConfigPanel.defaultProps = {
  connectorMessage: undefined,
  chatDate: undefined,
}

export default ConfigPanel
