import PropTypes from 'prop-types'

const { arrayOf, objectOf, shape, string, number } = PropTypes

const sharedLabelTypes = {
  id: number,
  name: string,
  created_at: string,
  updated_at: string,
  deleted_at: string,
}

export const intentTreeType = arrayOf(
  shape({
    title: string,
    value: string,
    key: string,
    children: arrayOf(shape({
      id: string,
      title: string,
      label: string,
      value: string,
      key: string,
      domainId: string,
      intentId: number,
    })),
    domainId: string,
  }),
)

export const intentCascaderType = arrayOf(
  shape({
    value: number,
    label: string,
    children: arrayOf(shape({
      value: number,
      label: string,
    })),
  }),
)

export const domainListType = arrayOf(shape(sharedLabelTypes))

export const intentListType = arrayOf(
  shape({
    ...sharedLabelTypes,
    domain_id: number,
  }),
)

export const domainsLookupType = objectOf(shape(sharedLabelTypes))
export const intentsLookupType = objectOf(
  shape({
    ...sharedLabelTypes,
    domain_id: number,
  }),
)

export const rawLookupType = shape({
  lookup: objectOf(arrayOf(number)),
  domains: domainsLookupType,
  intents: intentsLookupType,
})

export const domainsIntentsTreeType = objectOf(arrayOf(string))
