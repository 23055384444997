import React, { useState } from 'react'
import { Popover, Form, DatePicker, Select } from 'antd'
import { SettingFilled } from '@ant-design/icons'
import { useLogDisplay } from './useLogDisplay'

const LogSetting = () => {
  const [open, setOpen] = useState(false)
  const hide = () => {
    setOpen(false)
  }
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  return (
    <Popover
      placement='topRight'
      content={<LogSettingContent hide={hide} />}
      title='Display Setting'
      trigger='click'
      open={open}
      onOpenChange={handleOpenChange}
    >
      <SettingFilled style={{ position: 'absolute', bottom: '10px', right: '10px' }} />
    </Popover>
  )
}

const LogSettingContent = () => {
  const { form, onFormChange } = useLogDisplay()

  return (
    <div>
      <Form
        onValuesChange={onFormChange}
        form={form}
      >
        <div>JSON Omit fields</div>
        <Form.Item name='omitFields'>
          <Select
            mode='tags'
            style={{ width: '100%' }}
            placeholder='Fields to omit in logs'
            options={[]}
          />
        </Form.Item>
        <div>Date Range</div>
        <Form.Item name='dateRange'>
          <DatePicker.RangePicker format='DD/MM/YYYY' />
        </Form.Item>
      </Form>
    </div>
  )
}

export default LogSetting
