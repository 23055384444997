import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Layout,
  PageHeader,
  Spin,
  Row,
  Col,
  Result,
  Typography,
  Empty,
} from 'antd'
import useQueryParams from '@core/hooks/useQueryParams'
import { getComputedConversationMessages } from '@core/helpers'
import API from '../../api'
import ConversationMessages from './ConversationMessages'
import ConversationDetails from './ConversationDetails'
import AnalyzedCustomerMessages from './AnalyzedCustomerMessages'

const ConversationDebug = () => {
  const history = useHistory()
  const { conversation_id } = useParams()
  const [conversation, setConversation] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const mounted = useRef()
  const queryParams = useQueryParams()
  const datasource = queryParams.get('datasource')

  useEffect(() => {
    mounted.current = true
    const getConversation = async () => {
      setIsLoading(true)
      setIsError(false)
      setIsEmpty(false)
      if (!conversation_id) {
        setConversation(null)
        setIsLoading(false)
        setIsError(false)
        return
      }

      try {
        const { data } = await API.getOne(conversation_id, { datasource })

        if (mounted.current && data) {
          setConversation({
            ...data,
            computedMessages: data && data.messages ? getComputedConversationMessages(data.messages) : [],
          })
          /*
            Put setIsLoading method here will not cause react warning about
            unmount update + mem-leak.
            Probably it's outside the 'if-else' statement so that execution makes a 'jump'?
          */
          setIsLoading(false)
        } else if (!data) {
          setIsEmpty(true)
          setIsLoading(false)
        }
        // Put the setIsLoading here
      } catch (error) {
        setIsError(true)
        setIsLoading(false)
        setIsEmpty(false)
      }
    }

    getConversation()

    return () => {
      mounted.current = false
    }
  }, [conversation_id, datasource])

  const renderContent = () => {
    if (isLoading) return (<Spin size='large' />)
    if (isError) {
      return (
        <Result
          status='error'
          title='There are some problems with your operation.'
        />
      )
    }

    return (
      <div className='ConversationDebug__content'>
        <Row gutter={[8, 0]}>
          <Col span={7}>
            {conversation && <ConversationDetails conversation={conversation} />}
          </Col>
          <Col span={10}>
            {conversation && <ConversationMessages messages={conversation.computedMessages} />}
          </Col>
          <Col span={7}>
            {conversation && <AnalyzedCustomerMessages messages={conversation.computedMessages} />}
          </Col>
        </Row>
      </div>
    )
  }

  if (isLoading) return (<Spin size='large' />)
  if (isError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (isEmpty) {
    return (
      <Layout.Content className='ConversationDebug'>
        <PageHeader
          onBack={() => { history.goBack() }}
          title={<Typography.Paragraph copyable={{ text: window.location.href }}>{`Inspecting Conversation: ${conversation_id}`}</Typography.Paragraph>}
        />
        <Empty description='Conversation not found' />
      </Layout.Content>
    )
  }

  return (
    <Layout.Content className='ConversationDebug'>
      <PageHeader
        onBack={() => { history.goBack() }}
        title={<Typography.Paragraph copyable={{ text: window.location.href }}>{`Inspecting Conversation: ${conversation_id}`}</Typography.Paragraph>}
      />
      {renderContent()}
    </Layout.Content>
  )
}

export default ConversationDebug
