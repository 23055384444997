import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { useInternationalizeVersionSelector } from '@core/hooks/useInternationalize'

const I18nVersions = ({ onSelectI18nVersion, selectedRowKeys }) => {
  const i18nVersions = useInternationalizeVersionSelector().map((v) => { return { ...v, key: v.id } })

  return (
    <Table
      rowSelection={{
        type: 'radio',
        onChange: onSelectI18nVersion,
        selectedRowKeys,
      }}
      columns={[
        {
          title: 'Version',
          dataIndex: 'id',
          render: (versionId) => { return (<Link to={`/versions/i18n/${versionId}`}>{versionId}</Link>) },
        },
        {
          title: 'Note',
          dataIndex: 'note',
        },
      ]}
      dataSource={i18nVersions}
      showHeader={false}
    />
  )
}

I18nVersions.defaultProps = {
  selectedRowKeys: [0],
}

I18nVersions.propTypes = {
  onSelectI18nVersion: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number),
}

export default I18nVersions
