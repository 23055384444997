import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const CollapsedButton = ({
  collapsed,
  onClick,
}) => {
  return (
    <Button
      size='small'
      icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
      onClick={onClick}
    >
      {collapsed ? 'Show Filters' : 'Hide Filters'}
    </Button>
  )
}

CollapsedButton.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default CollapsedButton
