import React from 'react'
import PropTypes from 'prop-types'
import {
  Result,
  Tabs,
  Descriptions,
} from 'antd'
import JSONPreview from '@core/components/JsonPreview'
import ConvesationStatistics from '@core/components/ConversationStatistics'

const ConversationDetails = ({ conversation }) => {
  if (!conversation) {
    return (<Result status='error' title='There are some problems with your operation.' />)
  }

  return (
    <Tabs type='card'>
      <Tabs.TabPane tab='Details' key='1'>
        <Descriptions title='Conversation Details' bordered column={1}>
          <Descriptions.Item label='ID'>{conversation.id}</Descriptions.Item>
          {conversation.le_id && <Descriptions.Item label='LiveEngage ID'>{conversation.le_id}</Descriptions.Item>}
          <Descriptions.Item label='Status'>{conversation.status}</Descriptions.Item>
        </Descriptions>
        <ConvesationStatistics stats={conversation.stats} column={1} />
      </Tabs.TabPane>
      <Tabs.TabPane tab='State' key='2'>
        <JSONPreview content={JSON.stringify(conversation.state, null, 2)} autoSize />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Entity' key='3'>
        <JSONPreview content={JSON.stringify(conversation.entity, null, 2)} autoSize />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Extra' key='4'>
        <JSONPreview content={JSON.stringify(conversation.extra, null, 2)} autoSize />
      </Tabs.TabPane>
    </Tabs>
  )
}

ConversationDetails.propTypes = {
  conversation: PropTypes.shape({
    id: PropTypes.string,
    le_id: PropTypes.string,
    status: PropTypes.string,
    state: PropTypes.objectOf(PropTypes.any),
    entity: PropTypes.objectOf(PropTypes.any),
    extra: PropTypes.objectOf(PropTypes.string),
    messages: PropTypes.arrayOf(PropTypes.object),
    computedMessages: PropTypes.arrayOf(PropTypes.object),
    stats: PropTypes.objectOf(PropTypes.number),
  }).isRequired,
}

export default ConversationDetails
