import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Layout, Typography, Menu, Radio, Button, Space, Row, Col, DatePicker } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { usePermittedFeaturesLookup } from '@core/hooks/usePermissions'

import ConversationAnalysisMenuTree from '../components/ConversationAnalysisMenuTree'
import ResultDisplay from '../components/ResultDisplay/index'
import ConversationAnalysisActions from '../store/actions/analytics'
import SyncAnalysis from '../components/GenericComponents/SyncAnalysis'

const { Content, Sider } = Layout
const { Title } = Typography
const { RangePicker } = DatePicker

const DEFAULT_KEY = 'conversation.1'

const ConversationAnalysisPage = () => {
  const { analysisKey, title, env, isPending } = useSelector((state) => { return state.conversationAnalysis.analytics })

  const isProductionAllowed = usePermittedFeaturesLookup('conversations.simulation.actionSelectProduction')
  const isCanaryAllowed = usePermittedFeaturesLookup('conversations.simulation.actionSelectCanary')
  const isLocalAllowed = process.env.REACT_APP_ENV === 'development'
  const simulationTarget = ['Staging']

  if (isProductionAllowed) {
    simulationTarget.unshift('Production')
  }
  if (isCanaryAllowed) {
    simulationTarget.push('Canary')
  }
  if (isLocalAllowed) {
    simulationTarget.push('Development')
  }

  const [selectedSimulationTarget, setSelectedSimulationTarget] = useState(() => {
    const value = isLocalAllowed ? 'Development' : simulationTarget[0]
    ConversationAnalysisActions.setEnvironment(value)
    return value
  })
  const [collapsed, setCollapsed] = useState(false)
  const setCollapseSidebar = () => { return setCollapsed(!collapsed) }
  useEffect(() => { ConversationAnalysisActions.selectForAnalyze(DEFAULT_KEY) }, [selectedSimulationTarget])
  // NOTE: This will also fixes too, watch changes from ConversationAnalysisActions
  // useEffect(() => {
  //   ConversationAnalysisActions.selectForAnalyze(DEFAULT_KEY)
  // }, [ConversationAnalysisActions])

  const onSelectedSimulationTargetChange = (event) => {
    const { target: { value } } = event
    setSelectedSimulationTarget(value)
    ConversationAnalysisActions.setEnvironment(value)
    ConversationAnalysisActions.selectForAnalyze(analysisKey || DEFAULT_KEY)
  }

  const onDateRangeChange = (dateRange) => {
    if (!dateRange) dateRange = [null, null]
    ConversationAnalysisActions.setDateRange(dateRange)
  }

  return (
    <Content>
      <Title level={3}>Conversations Analysis</Title>
      <Row justify='space-between'>
        <Col>
          <Space direction='vertical'>
            <Typography.Text strong>Select environment:</Typography.Text>
            <Radio.Group size='small' onChange={onSelectedSimulationTargetChange} value={selectedSimulationTarget}>
              {simulationTarget.map((item, index) => {
                return (
                  <Radio.Button key={index} value={item}>{item}</Radio.Button>
                )
              })}
            </Radio.Group>
          </Space>
        </Col>
        <Col>
          <Space size='large'>
            <Col><Typography.Title level={4}>{title}</Typography.Title></Col>
            <Col><Typography.Title level={4} code>{env}</Typography.Title></Col>
          </Space>
        </Col>
        <Col>
          <Space>
            <RangePicker
              bordered
              allowEmpty={[true, true]}
              disabled={isPending}
              onChange={onDateRangeChange}
            />
            <Typography.Text italic>Optional: Select By Date Range</Typography.Text>
          </Space>
        </Col>
        <Col>
          <SyncAnalysis analysisKey='a' />
        </Col>
      </Row>
      <Layout style={{ marginTop: 10 }}>
        <Button size='small' shape='circle' icon={collapsed ? <RightOutlined /> : <LeftOutlined />} onClick={setCollapseSidebar} />
        <Sider width={300} collapsedWidth={0} collapsed={collapsed}>
          <Space direction='vertical'>
            <Menu
              onClick={async (e) => { return ConversationAnalysisActions.selectForAnalyze(e.key) }}
              mode='inline'
              defaultSelectedKeys={[DEFAULT_KEY]}
            >
              <ConversationAnalysisMenuTree />
            </Menu>
          </Space>
        </Sider>
        <Content style={{ maxHeight: '800px' }}>
          <ResultDisplay />
        </Content>
      </Layout>
    </Content>
  )
}

export default ConversationAnalysisPage
