import React, { useState } from 'react'
import {
  Form,
  Input,
  Modal,
  Button,
  Typography,
  Cascader,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import SentencesActionCreators from '../../../store/actions/sentences'
import {
  modalVisibilityType,
  setModalVisibilityType,
} from '../../../types/addCaseModal'
import style from './index.module.scss'

const { Text } = Typography

const AddNewSentencesModal = ({ modalVisibility, setModalVisibility }) => {
  const { intentCascader } = useSelector(({ core }) => {
    return {
      intentCascader: core.domainIntent.intentCascader,
    }
  })

  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [newSentences, setNewSentences] = useState(null)
  const [successMessage, setSuccessMessage] = useState('')

  const closeModal = () => {
    setModalVisibility(false)
    setConfirmLoading(false)
    setHasError(false)
    setNewSentences(null)
    setSuccessMessage('')
    form.resetFields()
  }

  const handleSubmit = async () => {
    setHasError(false)
    setConfirmLoading(true)

    try {
      const values = await form.validateFields()
      const formData = {
        sentences: values.sentences,
      }
      if (values.train_domain_intent && values.train_domain_intent.length === 2) {
        [formData.train_domain_id, formData.train_intent_id] = values.train_domain_intent
      }
      if (values.test_domain_intent && values.test_domain_intent.length === 2) {
        [formData.test_domain_id, formData.test_intent_id] = values.test_domain_intent
      }
      const responseSentences = await SentencesActionCreators.addNewSentences(formData)
      const responseSentenceTexts = responseSentences.map(({ text }) => { return text })

      setSuccessMessage(`${responseSentences.length} of ${values.sentences.length} sentence(s) was added.`)

      setNewSentences(
        values.sentences.map((sentence) => {
          return {
            text: sentence,
            success: responseSentenceTexts.includes(sentence),
          }
        }),
      )
    } catch (error) {
      if (error.status && [500, 404, 400].includes(error.status)) {
        setHasError(true)
      }
    }
    setConfirmLoading(false)
  }

  const shouldDisabled = Boolean(newSentences)

  const renderAppendIconForSentenceInput = (fields, field, remove) => {
    if (newSentences) {
      return newSentences[field.name].success
        ? <CheckCircleOutlined className={style.check_button__add_new_sentences_modal} />
        : <CloseCircleOutlined className={style.close_button__add_new_sentences_modal} />
    }
    return fields.length > 1 ? (
      <MinusCircleOutlined
        className={style.delete_button__add_new_sentences_modal}
        onClick={() => { return remove(field.name) }}
      />
    ) : null
  }

  return (
    <Modal
      title='Add new Sentences'
      visible={modalVisibility}
      onOk={handleSubmit}
      closable={false}
      okText='Submit'
      confirmLoading={confirmLoading}
      maskClosable={false}
      onCancel={closeModal}
      footer={shouldDisabled ? [
        <Button key='back' onClick={closeModal}>
          OK
        </Button>,
      ] : [
        <Button key='back' onClick={closeModal}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={confirmLoading} onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Form name='form__new_sentences' form={form} initialValues={{ sentences: [''] }}>
        <Form.List
          name='sentences'
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map((field) => {
                  return (
                    <Form.Item
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input sentence or delete this field.',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder='sentence'
                          readOnly={shouldDisabled}
                          style={{ width: '90%' }}
                        />
                      </Form.Item>
                      {renderAppendIconForSentenceInput(fields, field, remove)}
                    </Form.Item>
                  )
                })}
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => { return add() }}
                    style={{ width: '90%' }}
                    icon={<PlusOutlined />}
                    disabled={shouldDisabled}
                  >
                    Add field
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )
          }}
        </Form.List>

        <Form.Item
          name='train_domain_intent'
          label='Train Domain/Intent'
          wrapperCol={{ span: 12 }}
        >
          <Cascader
            options={intentCascader}
            disabled={shouldDisabled}
            placeholder='Select Train Domain/Intent'
          />
        </Form.Item>

        <Form.Item
          name='test_domain_intent'
          label='Test Domain/Intent'
          wrapperCol={{ span: 12 }}
        >
          <Cascader
            options={intentCascader}
            disabled={shouldDisabled}
            placeholder='Select Test Domain/Intent'
          />
        </Form.Item>
      </Form>
      {successMessage && <Text type='success'>{successMessage}</Text>}
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
    </Modal>
  )
}

AddNewSentencesModal.propTypes = {
  modalVisibility: modalVisibilityType.isRequired,
  setModalVisibility: setModalVisibilityType.isRequired,
}

export default AddNewSentencesModal
