import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Select, Form } from 'antd'
import { rulesType, formType, taggerType } from '@core/types/formItems'

const TaggerDesiredResult = ({ form, rules, selectedTagger }) => {
  const { taggersExpectedResults } = useSelector((state) => { return state.experiments.taggers })
  useEffect(() => {
    const fields = form.getFieldsValue()
    form.setFieldsValue({ ...fields, desired_result: null })
  }, [selectedTagger, form])
  return (
    <Form.Item name='desired_result' label='Desired value' rules={rules}>
      <Select disabled={!selectedTagger} style={{ width: 200 }}>
        {selectedTagger && taggersExpectedResults[selectedTagger].map((option) => {
          return (
            <Select.Option value={option} key={option}>{option}</Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}
TaggerDesiredResult.propTypes = {
  rules: rulesType,
  form: formType,
  selectedTagger: taggerType,
}

TaggerDesiredResult.defaultProps = {
  rules: [],
  form: null,
  selectedTagger: null,
}

export default TaggerDesiredResult
