import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Space,
  Form,
  Divider,
  Button,
  Spin,
  Skeleton,
  Alert,
  Typography,
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import useTimeoutAlert from '@core/hooks/useTimeoutAlert'
import { SAMPLE_FORM_TYPE } from '../../../../../../constants/extractors' // omg
import { DataExtractionFormContext } from '../../../context'
import SentenceSelector from './SentenceSelector'
import PreprocessedSentence from './PreprocessedSentence'
import PhraseToMatch from './PhraseToMatch'
import ExtractorSelector from './ExtractorSelector'
import ExtractorDesiredValue from './ExtractorDesiredValue'
// import ForcedConfigOptions from './ForcedConfigOptions'

import style from './style.module.scss'

const getInitialDesiredLabelValue = (caseType) => {
  return caseType === SAMPLE_FORM_TYPE.NEW ? [] : null
}

const TrainingCasesForm = ({
  sentences,
  addNewTrainingCase,
  editExistingTrainingCase,
  closeFormPanel,
  onSentenceChange,
  onSymbolChange,
  extractorTypesLoading,
  extractorsTypes,
  isInitializing,
  isSubmitting,
  selectedSymbol,
  openingSample,
  caseType,
  setIsFormDirty,
}) => {
  const { formConfig, setDataExtractionFormConfig } = useContext(DataExtractionFormContext)
  const extractorRef = useRef(null)
  const [form] = Form.useForm()
  const [selectedSentenceIndex, setSelectedSentenceIndex] = useState(null)
  const [selectedPhrase, setSelectedPhrase] = useState(null)
  const [selectedExtractor, setSelectedExtractor] = useState(null)
  const [formFeedback, setFormFeedback] = useTimeoutAlert(null)

  const extractorsTypesList = extractorsTypes ? Object.keys(extractorsTypes) : []

  // Hide for now
  /*
  const onToggleForcedExtractor = (checked) => {
    // if it is true it needs to clear currently selected first...

    setDataExtractionFormConfig((prevState) => {
      return {
        selectedExtractor: checked ? prevState.selectedExtractor : null,
        forcedExtractor: checked,
      }
    })
  }

  const onForcedExtractorTypeChange = (forcedExtractorType) => {
    const fields = form.getFieldsValue()
    setSelectedExtractor(forcedExtractorType)
    setDataExtractionFormConfig((prevState) => {
      return {
        ...prevState,
        selectedExtractor: forcedExtractorType,
      }
    })
    form.setFieldsValue({ ...fields, extractorType: forcedExtractorType })
  }
  */

  const getPhrasesToMatchOption = () => {
    if (selectedSentenceIndex !== null && selectedSentenceIndex !== undefined) {
      return sentences[selectedSentenceIndex].tokens
    }

    return []
  }

  // NOTE: Specific Select element change will trigger after antd::Form#onValuesChange
  const onSelectSentence = (sentenceIndexValue) => {
    const fields = form.getFieldsValue()

    onSentenceChange(sentenceIndexValue)

    setSelectedSentenceIndex(sentenceIndexValue)
    setSelectedPhrase(null)
    form.setFieldsValue({ ...fields, phrase: null })
  }

  const onSelectPhrase = (item) => {
    const fields = form.getFieldsValue()

    setDataExtractionFormConfig((prevState) => {
      return {
        ...prevState,
        openExtractor: true,
      }
    })

    onSymbolChange(item)

    setSelectedPhrase(item)
    form.setFieldsValue({ ...fields, phrase: JSON.stringify(item) })
  }

  const onExtractorSelected = () => {
    setDataExtractionFormConfig((prevState) => {
      return {
        ...prevState,
        openExtractor: false,
      }
    })
  }

  const onExtractorBlur = () => {
    setDataExtractionFormConfig((prevState) => {
      return {
        ...prevState,
        openExtractor: false,
      }
    })
  }

  const onFormFieldValuesChange = (values) => {
    setIsFormDirty(true)
    if (values.phrase) {
      const jsonParsedPhrase = JSON.parse(values.phrase)

      setSelectedPhrase(jsonParsedPhrase)

      onSymbolChange(jsonParsedPhrase)
    }

    // this was moved from the component
    // otherwise useEffect fucking kill the initial value render
    if (values.extractorType) {
      setSelectedExtractor(values.extractorType)

      const fields = form.getFieldsValue()
      form.setFieldsValue({
        ...fields,
        desiredLabel: getInitialDesiredLabelValue(caseType),
      })
    }
  }

  const addSamples = async (values) => {
    const { phrase, extractorType, desiredLabel } = values
    const payload = {
      phrase: JSON.parse(phrase).original,
      desiredLabels: desiredLabel,
      extractorType,
      occurrenceIndex: JSON.parse(phrase).occurrenceIndex,
      trainingData: '"{}"',
      tag: JSON.parse(phrase).predictedSource ?? 'NONE',
    }

    let response

    try {
      let phraseValue
      let extractorTypeValue = null
      let desiredLabelValue = getInitialDesiredLabelValue(caseType)

      response = await addNewTrainingCase(payload)

      if (response.status === 'failed') {
        setFormFeedback({
          type: 'error',
          message: 'Error',
          description: 'Failed to add new sample(s)',
        })

        phraseValue = phrase
        extractorTypeValue = extractorType
        desiredLabelValue = desiredLabel
      } else if (response.status === 'partial') {
        setFormFeedback({
          type: 'success',
          message: 'Success',
          description: (
            <Space direction='vertical'>
              <Typography.Text>Successfully partially added new sample(s). Could not add:</Typography.Text>
              <ul>
                {response.failedItems.map((failedLabel) => {
                  return (<li key={failedLabel}>{failedLabel}</li>)
                })}
              </ul>
            </Space>
          ),
        })

        phraseValue = phrase
        extractorTypeValue = extractorType
        desiredLabelValue = response.failedItems
      } else if (response.status === 'success') {
        setFormFeedback({
          type: 'success',
          message: 'Success',
          description: response.duplicatedDesiredValue.length
            ? (
              <Space direction='vertical'>
                <Typography.Text>Successfully added new sample(s). But there are some labels that had been assigned already:</Typography.Text>
                <ul>
                  {response.duplicatedDesiredValue.map((duplicated) => {
                    return (<li key={duplicated}>{duplicated}</li>)
                  })}
                </ul>
              </Space>
            )
            : 'Successfully added new sample(s)',
        })

        setSelectedPhrase(null)
        setSelectedExtractor(null)
      } else if (response.status === 'allDuplicated') {
        setFormFeedback({
          type: 'warning',
          message: 'Warning',
          description: (
            <Space direction='vertical'>
              <Typography.Text>All types were already added with this Phrase and extractor/desired value:</Typography.Text>
              <ul>
                {response.duplicatedDesiredValue.map((duplicated) => {
                  return (<li key={duplicated}>{duplicated}</li>)
                })}
              </ul>
            </Space>
          ),
        })

        // don't clear form
        phraseValue = phrase
        extractorTypeValue = extractorType
        desiredLabelValue = desiredLabel
      }

      form.setFieldsValue({
        phrase: phraseValue,
        extractorType: extractorTypeValue,
        desiredLabel: desiredLabelValue,
      })
    } catch (error) {
      console.error('DataExtraction::Form#addSamples - error - ', error)
      const { data } = error
      setFormFeedback({
        type: 'error',
        message: 'Error',
        description: data?.status ?? 'We\'ve encountered a problem. Please try again in few minutes',
      })
    }
  }

  const editSample = async (values) => {
    const { phrase, extractorType, desiredLabel } = values
    const payload = {
      phrase: JSON.parse(phrase).original,
      extractorType,
      desiredLabel,
      occurrenceIndex: JSON.parse(phrase).occurrenceIndex,
      trainingData: '"{}"',
      tag: JSON.parse(phrase).predictedSource ?? 'NONE',
    }

    let response

    try {
      let phraseValue
      let extractorTypeValue = null
      let desiredLabelValue = getInitialDesiredLabelValue(caseType)
      const formFeedbackStatus = {
        success: {
          type: 'success',
          message: 'Success',
          description: 'Edit case success',
        },
        duplicated: {
          type: 'warning',
          message: 'Notice',
          description: `Duplicated: There is already a sample with these label ${payload.desiredLabel}`,
        },
      }

      response = await editExistingTrainingCase({ id: openingSample?.id, ...payload })

      if (response.status === 'success' || response.status === 'duplicated') {
        setFormFeedback(formFeedbackStatus[response.status])

        phraseValue = phrase
        extractorTypeValue = extractorType
        desiredLabelValue = desiredLabel
        setSelectedPhrase(JSON.parse(phrase))
        setSelectedExtractor(extractorType)
      }

      form.setFieldsValue({
        phrase: phraseValue,
        extractorType: extractorTypeValue,
        desiredLabel: desiredLabelValue,
      })
    } catch (error) {
      console.error('DataExtraction::Form#editSample - error - ', error)
      const { data } = error
      setFormFeedback({
        type: 'error',
        message: 'Error',
        description: data?.status ?? 'We\'ve encountered a problem. Please try again in few minutes',
      })
    }
  }

  const onSubmit = async (values) => {
    if (!caseType) {
      setFormFeedback({
        type: 'error',
        message: 'Error',
        description: 'Missing form submission type from props!',
      })

      return
    }

    if (caseType === SAMPLE_FORM_TYPE.NEW) {
      await addSamples(values)
    } else if (caseType === SAMPLE_FORM_TYPE.EDIT) {
      await editSample(values)
    } else {
      setFormFeedback({
        type: 'error',
        message: 'Error',
        description: 'Incompatible form submission type!',
      })
    }
  }

  useEffect(() => {
    return () => {
      setDataExtractionFormConfig((prevState) => {
        return {
          ...prevState,
          openExtractor: false,
        }
      })
    }
  }, [setDataExtractionFormConfig])

  // intentionally checking just object, react hook deps compare is '==='
  // formConfig would return a "new" 'spread' of previous one anyway
  useEffect(() => {
    if (formConfig.openExtractor) extractorRef.current.focusAndOpen()
  }, [formConfig])

  useEffect(() => {
    const fields = form.getFieldsValue()
    let fieldsValue = null
    if (formConfig.forcedExtractor) {
      // this should fit the add case
      if (!fieldsValue) fieldsValue = { ...fields }
      fieldsValue.extractorType = formConfig.selectedExtractor
    } else {
      // when disable the forced, probably clear the field
      fieldsValue = { ...fields }
    }

    if (fieldsValue) form.setFieldsValue(fieldsValue)
  }, [form, formConfig])

  // FIXME: there are now two redundant handing selectedSymbol...
  // but it works the same way
  useEffect(() => {
    const fields = form.getFieldsValue()
    let fieldsValue = null
    // originally before ForcedConfigOptions introduced
    // form was set inside this if (openingSample) block
    if (openingSample) {
      // the moment where user choose to edit a case
      // it should have sentenceIndex, because it was set inside same function of edit
      const { sentenceIndex } = selectedSymbol
      const { extractorType, desiredLabel } = openingSample

      setSelectedSentenceIndex(sentenceIndex)
      setSelectedPhrase(selectedSymbol.symbolInfo)
      setSelectedExtractor(extractorType)

      fieldsValue = {
        ...fields,
        sentenceIndex,
        extractorType,
        desiredLabel,
        phrase: selectedSymbol.symbolInfo ? JSON.stringify(selectedSymbol.symbolInfo) : null,
      }

      form.setFieldsValue(fieldsValue)
    }
  }, [form, sentences, openingSample, selectedSymbol])

  useEffect(() => {
    const fields = form.getFieldsValue()
    let fieldsValue = null

    if (selectedSymbol.symbolInfo) {
      const { sentenceIndex } = selectedSymbol
      setSelectedSentenceIndex(sentenceIndex)
      setSelectedPhrase({ ...selectedSymbol.symbolInfo })

      fieldsValue = {
        ...fields,
        sentenceIndex,
        phrase: JSON.stringify({ ...selectedSymbol.symbolInfo }),
      }
    }

    if (fieldsValue) form.setFieldsValue(fieldsValue)
  }, [form, selectedSymbol])

  if (isInitializing || extractorTypesLoading) return (<Skeleton active />)

  return (
    <Spin spinning={isSubmitting}>
      {/*
        "ForcedConfigOptions"
        This idea is from Rob, where linguistics can 'force' the options to be always the same across
        whole pagination.

        This fits the case where either James and Pat was working on different Extractor. And each one of them
        was making a search on their specific extrator. This would help them to 'not need to select extractor'
        again when they moved to different email.

        WIP. It needs to sit on top of modal (context), can't be state otherwise it's cleared on every email

        Maybe some other ideas would might be
        - forced sentence, so they don't have to change sentence too
      */}
      {/* <ForcedConfigOptions
        forcedExtractor={formConfig.forcedExtractor}
        extractorType={formConfig.selectedExtractor}
        extractorsTypesList={extractorsTypesList}
        onToggleForcedExtractor={onToggleForcedExtractor}
        onForcedExtractorTypeChange={onForcedExtractorTypeChange}
      /> */}
      <div className={style.Title}>
        <Typography.Title level={5}>
          {`${caseType ?? 'Set'} Training Case`}
        </Typography.Title>
        <Button
          type='link'
          icon={<CloseOutlined />}
          onClick={closeFormPanel}
          disabled={isSubmitting}
        />
      </div>
      <Divider />
      {formFeedback && (
        <Alert
          className={style.AlertFeedback}
          closable
          message={formFeedback?.message}
          description={formFeedback?.description ?? formFeedback?.type}
          type={formFeedback?.type}
        />
      )}
      <Form
        className={style.Form}
        form={form}
        layout='vertical'
        name='emailDataExtraction_training'
        onFinish={onSubmit}
        onValuesChange={onFormFieldValuesChange}
      >
        <SentenceSelector
          sentences={sentences}
          onSelectSentence={onSelectSentence}
        />
        <Divider />
        <PreprocessedSentence
          selectedSentenceIndex={selectedSentenceIndex}
          symbols={sentences[selectedSentenceIndex]?.tokens ?? []}
          selectedPhrase={selectedPhrase}
          onSelectPhrase={onSelectPhrase}
        />
        <PhraseToMatch
          symbols={getPhrasesToMatchOption()}
        />
        <ExtractorSelector
          ref={extractorRef}
          extractorsTypesList={extractorsTypesList}
          forcedExtractor={formConfig.forcedExtractor}
          onExtractorSelected={onExtractorSelected}
          onExtractorBlur={onExtractorBlur}
        />
        <ExtractorDesiredValue
          caseType={caseType}
          selectedExtractor={selectedExtractor}
          desiredLabels={extractorsTypes[selectedExtractor] ?? []}
        />
        <Form.Item>
          <Space wrap>
            <Button type='primary' htmlType='submit' disabled={isSubmitting}>
              Submit
            </Button>
            <Button htmlType='button' onClick={closeFormPanel} disabled={isSubmitting}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  )
}

TrainingCasesForm.defaultProps = {
  openingSample: null,
  extractorsTypes: {},
  selectedSymbol: { sentenceIndex: null, symbolInfo: null },
  setIsFormDirty: () => { },
}

TrainingCasesForm.propTypes = {
  sentences: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      tokens: PropTypes.arrayOf(
        PropTypes.shape({
          occurrenceIndex: PropTypes.number.isRequired,
          original: PropTypes.string.isRequired,
          sources: PropTypes.arrayOf(PropTypes.string),
          predictedSource: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  addNewTrainingCase: PropTypes.func.isRequired,
  editExistingTrainingCase: PropTypes.func.isRequired,
  closeFormPanel: PropTypes.func.isRequired,
  onSentenceChange: PropTypes.func.isRequired,
  onSymbolChange: PropTypes.func.isRequired,
  extractorTypesLoading: PropTypes.bool.isRequired,
  extractorsTypes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  isInitializing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  selectedSymbol: PropTypes.shape({
    sentenceIndex: PropTypes.number,
    symbolInfo: PropTypes.shape({
      occurrenceIndex: PropTypes.number.isRequired,
      original: PropTypes.string.isRequired,
      sources: PropTypes.arrayOf(PropTypes.string),
      predictedSource: PropTypes.string,
    }),
  }),
  openingSample: PropTypes.shape({
    id: PropTypes.string,
    phrase: PropTypes.string,
    extractorType: PropTypes.string,
    desiredLabel: PropTypes.string,
    occurrenceIndex: PropTypes.number.isRequired,
    trainingData: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }),
  caseType: PropTypes.oneOf([SAMPLE_FORM_TYPE.NEW, SAMPLE_FORM_TYPE.EDIT]).isRequired,
  setIsFormDirty: PropTypes.func,
}

export default TrainingCasesForm
