import { useState, useEffect } from 'react'

const useActiveHashTab = (initialTab) => {
  const [activeKey, setActiveKey] = useState(
    window.location.hash
      ? window.location.hash.substring(1)
      : initialTab,
  )

  const setNewActiveTab = (newActiveKey) => {
    // window.location.hash = `#${newActiveKey}`
    setActiveKey(newActiveKey)
  }

  useEffect(() => {
    window.location.hash = `#${activeKey}`
  })

  return [activeKey, setNewActiveTab]
}

export default useActiveHashTab
