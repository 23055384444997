import React, { useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import get from 'lodash.get'
import StorageUtils from '@core/utils/storage'
import AuthActions from '@core/store/actions/Auth'
import PublicPages from './public'
import PrivatePages from './private'

const getUser = () => {
  return Auth.currentAuthenticatedUser()
  .then((userData) => { return userData })
  .catch(() => {
    console.log('Not signed in')
  })
}

const extractToken = (userData) => {
  const access_token = get(userData, 'signInUserSession.accessToken.jwtToken', null)
  const refresh_token = get(userData, 'signInUserSession.refreshToken.token', null)

  return access_token && refresh_token ? { access_token, refresh_token } : null
}

const Entryway = () => {
  const history = useHistory()
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        // case 'cognitoHostedUI':
        case 'tokenRefresh':
        case 'signIn':
          // cognitoHostedUI and signIn were emitted together
          getUser()
          .then((userData) => {
            // This emits only when success, refresh will not make this happen
            const tokens = extractToken(userData)
            if (tokens) {
              AuthActions.synchronizeToken(tokens)
              if (event === 'signIn') {
                history.push('/okta-signin-success')
              }
            }
            // then redirect
          })
          break
        case 'signOut':
          AuthActions.logout()
          break
        // case 'cognitoHostedUI_failure':
        case 'signIn_failure':
          console.log('Sign in failure', data)
          break
      }
    })

    // actually this part could be removed?
    // getUser()
    // .then((userData) => {
    //   const tokens = extractToken(userData)
    //   if (!tokens) {
    //     AuthActions.hardLogout()
    //     history.push('/login')
    //   }
    // })
  }, [history])

  if (!StorageUtils.getItemFromSession('refresh_token') || !StorageUtils.getItemFromSession('access_token')) {
    return (<PublicPages />)
  }

  return <PrivatePages />
}

export default Entryway

