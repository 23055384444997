import { useReducer } from 'react'

// changable?
const EMAIL_LIST_PER_PAGE = 20

const INITIAL_STATE = {
  data: [],
  meta: {
    perPage: EMAIL_LIST_PER_PAGE,
  },
  statusList: ['INVALID', 'IN_PROGRESS', 'TO_RECHECK', 'SOLVED', 'INTENT_FIXED'],
  feedbackSentiments: ['negative', 'positive'],
}

// What if? results is "less than Per page"
// result in "that page"

export const EMAIL_SEARCH_RESULTS = {
  ADD_ONE: 'EMAIL_SEARCH_RESULTS/ADD_ONE',
  SELECT_MULTIPLE: 'EMAIL_SEARCH_RESULTS/SELECT_MULTIPLE',
  REMOVE_ONE: 'EMAIL_SEARCH_RESULTS/REMOVE_ONE',
  CLEAR_SELECTION: 'EMAIL_SEARCH_RESULTS/CLEAR_SELECTION',
  SET_SELECT_ALL: 'EMAIL_SEARCH_RESULTS/SET_SELECT_ALL',
  SET_SELECTS: 'EMAIL_SEARCH_RESULTS/SET_SELECTS',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case EMAIL_SEARCH_RESULTS.ADD_ONE: {
      const { payload: { item } } = action
      const newSelectedEmailsList = state.data.concat(item)

      return {
        ...state,
        data: newSelectedEmailsList,
      }
    }
    case EMAIL_SEARCH_RESULTS.REMOVE_ONE: {
      const { payload: { item: removedItem } } = action
      const withRemovedItem = state.data.filter((item) => { return item.id !== removedItem.id })

      return {
        ...state,
        data: withRemovedItem,
      }
    }
    case EMAIL_SEARCH_RESULTS.SELECT_MULTIPLE: {
      const { payload: { items: emails } } = action
      return {
        ...state,
        data: emails,
      }
    }
    case EMAIL_SEARCH_RESULTS.CLEAR_SELECTION: {
      return {
        ...state,
        data: [],
      }
    }
    case EMAIL_SEARCH_RESULTS.SET_SELECT_ALL: {
      const { checked } = action.payload

      return {
        ...state,
        data: checked ? action.payload.emails : [],
      }
    }
    case EMAIL_SEARCH_RESULTS.SET_SELECTS: {
      const { emails, ids } = action.payload
      const selectedEmails = emails.filter((email) => {
        return ids.some((id) => {
          return id === email.id
        })
      })
      return { ...state, data: [...selectedEmails] }
    }
    default: throw new Error('MISSING REDUCER EVENT')
  }
}

export const useEmailSearchResults = () => { return useReducer(reducer, INITIAL_STATE) }

export const selectEmail = (dispatch, item) => {
  dispatch({
    type: EMAIL_SEARCH_RESULTS.ADD_ONE,
    payload: { item },
  })
}

export const deselectEmail = (dispatch, item) => {
  dispatch({
    type: EMAIL_SEARCH_RESULTS.REMOVE_ONE,
    payload: { item },
  })
}

export const selectEmails = (dispatch, items) => {
  dispatch({
    type: EMAIL_SEARCH_RESULTS.SELECT_MULTIPLE,
    payload: { items },
  })
}

// uncheck
export const clearSelection = (dispatch) => {
  dispatch({ type: EMAIL_SEARCH_RESULTS.CLEAR_SELECTION })
}

export const setSelectAll = (dispatch, checked, emails) => {
  const payload = {
    checked,
  }

  if (checked) payload.emails = emails
  dispatch({ type: EMAIL_SEARCH_RESULTS.SET_SELECT_ALL, payload })
}

export const setSelectedEmails = (dispatch, { ids, emails }) => {
  dispatch({ type: EMAIL_SEARCH_RESULTS.SET_SELECTS, payload: { ids, emails } })
}

