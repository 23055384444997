import React, { useState } from 'react'
import {
  Dropdown,
  Menu,
} from 'antd'
import { PlusCircleTwoTone } from '@ant-design/icons'
import AddNewSentenceModal from './AddNewSentenceModal'
import AddNewSentencesModal from './AddNewSentencesModal'

import { collapsedType } from '../../types/addNewSentenceButton'

const MENU_KEY_ADD_NEW_SENTENCES = '1'

const AddNewSentenceButton = ({ collapsed }) => {
  const [addNewSentenceModalVisibility, setAddNewSentenceModalVisibility] = useState(false)
  const [addNewSentencesModalVisibility, setAddNewSentencesModalVisibility] = useState(false)

  const handleButtonClick = () => {
    setAddNewSentenceModalVisibility(true)
  }

  const handleMenuClick = (e) => {
    if (e.key === MENU_KEY_ADD_NEW_SENTENCES) {
      setAddNewSentencesModalVisibility(true)
    }
  }

  return (
    <div style={{ display: collapsed ? 'none' : 'block' }}>
      <Dropdown.Button
        type='primary'
        overlay={(
          <Menu onClick={handleMenuClick}>
            <Menu.Item key={MENU_KEY_ADD_NEW_SENTENCES}>
              Add multiple sentences
            </Menu.Item>
          </Menu>
        )}
        onClick={handleButtonClick}
      >
        <PlusCircleTwoTone />
        Add new sentence
      </Dropdown.Button>

      <AddNewSentenceModal
        modalVisibility={addNewSentenceModalVisibility}
        setModalVisibility={setAddNewSentenceModalVisibility}
      />

      <AddNewSentencesModal
        modalVisibility={addNewSentencesModalVisibility}
        setModalVisibility={setAddNewSentencesModalVisibility}
      />
    </div>
  )
}

AddNewSentenceButton.propTypes = {
  collapsed: collapsedType.isRequired,
}

export default AddNewSentenceButton
