import React from 'react'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import getOption from './getOption'

const ConversationsChartsTagCountByMonth = ({ tags, data }) => {
  return (
    <ReactECharts
      option={getOption(tags, data)}
    />
  )
}

ConversationsChartsTagCountByMonth.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      tags: PropTypes.objectOf(PropTypes.number),
    }),
  ).isRequired,
}

export default ConversationsChartsTagCountByMonth
