import React from 'react'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'
import { Result, notification } from 'antd'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import ConversationList from '@core/components/ConversationList'
import { conversationsDataFilterType } from '../../../types'
import Api from '../../../api'

const ConversationsDataTab = ({
  selectedConversationId,
  filter,
  onItemClick,
  handlePaginationChange,
}) => {
  const { datasource, ...query } = filter
  const [{ loading, data, hasError }, { setData }] = useRequest(Api.getAll, query, { datasource })

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }

  const onToggleExcludeFromTraining = async (item) => {
    try {
      const response = await Api.update(
        item.id,
        { exclude_from_training: !item.exclude_from_training },
        { datasource },
      )

      if (response && response.status === 'success') {
        notification.success({
          message: 'The conversation has updated successfully.',
          duration: 5,
        })

        setData({
          ...data,
          items: data.items.map((prevItem) => {
            if (item.id !== prevItem.id) {
              return prevItem
            }
            return {
              ...prevItem,
              exclude_from_training: !item.exclude_from_training,
            }
          }),
        })
      }
    } catch (error) {
      notification.error({
        message: 'Unable to update conversation',
        description: 'Encounter unexpected error, please try again',
        duration: 5,
      })
    }
  }
  const { items, meta } = data

  return (
    <ConversationList
      conversations={items}
      selectedConversationId={selectedConversationId}
      meta={meta}
      handlePaginationChange={handlePaginationChange}
      onItemClick={onItemClick}
      onToggleExcludeFromTraining={onToggleExcludeFromTraining}
      querySource={filter && filter.datasource ? filter.datasource : null}
    />
  )
}

ConversationsDataTab.propTypes = {
  selectedConversationId: PropTypes.string,
  filter: conversationsDataFilterType.isRequired,
  onItemClick: PropTypes.func.isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
}

ConversationsDataTab.defaultProps = {
  selectedConversationId: '',
}

export default ConversationsDataTab
