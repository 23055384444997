import React from 'react'
import { Switch, Route } from 'react-router-dom'

import FaqsHome from './pages/Home'

const FaqsModule = () => {
  return (
    <Switch>
      <Route path='/faqs' component={FaqsHome} exact />
    </Switch>
  )
}

export const ModuleConfig = {
  routeProps: {
    path: '/faqs',
    component: FaqsModule,
  },
  name: 'FAQs',
}

export default FaqsModule
