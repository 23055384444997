import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'
import {
  Spin,
  Typography,
  Modal,
  Form,
  Alert,
} from 'antd'
import KeywordPhrasesForm from '../KeywordPhrasesForm'
import Api from '../../../api'

const getCombination = async (id) => {
  if (!id) {
    return { data: null }
  }

  return Api.getCombination(id)
}

const KeywordPhrasesEditDialog = ({ id, onDismiss, onUpdated }) => {
  const [{
    loading,
    data,
    hasError,
  }] = useRequest(getCombination, id)
  const [form] = Form.useForm()
  const [updating, setUpdating] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)

  const visible = !!id

  useEffect(() => {
    if (!visible) setFlashMessage(null)
  }, [visible])

  if (!visible) return null

  const onOk = async () => {
    form.submit()
  }

  const onCancel = async () => {
    setUpdating(false)
    onDismiss()
  }

  const onFinish = async (values) => {
    setFlashMessage(null)
    setUpdating(true)
    try {
      const { data: responseData } = await Api.updateConbination(id, values)
      setUpdating(false)
      onUpdated(responseData)
    } catch (error) {
      setFlashMessage({ type: 'error', message: error.message })
      setUpdating(false)
    }
  }

  const handleFlashMessageClose = () => {
    setFlashMessage(null)
  }

  return (
    <Modal
      title='Edit Faq Keyword Phrases Combinations'
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      {flashMessage && (
        <Alert
          message={flashMessage.message}
          type={flashMessage.type}
          showIcon
          closable
          onClose={handleFlashMessageClose}
          style={{ marginBottom: 8 }}
        />
      )}
      {loading && <Spin />}
      {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      {visible && data && (
        <KeywordPhrasesForm
          form={form}
          combinations={data}
          loading={updating}
          onFinish={onFinish}
        />
      )}
    </Modal>
  )
}

KeywordPhrasesEditDialog.propTypes = {
  id: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
}

KeywordPhrasesEditDialog.defaultProps = {
  id: '',
}

export default KeywordPhrasesEditDialog
