import React, { useMemo, useState } from 'react'
import {
  Modal,
  Button,
  List,
  Row,
  Col,
  Typography,
} from 'antd'
import PropTypes from 'prop-types'

const { Title } = Typography
const ClassificationDetailModal = ({ classes, items }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const classifications = useMemo(
    () => {
      return items.reduce((data, item) => {
        const isPositive = item.predictedClass === classes[0] // The positive class comes first in usage
        const isTrue = item.predictedClass === item.trueClass

        if (isPositive) {
          if (isTrue) {
            data.truePositive.push(item)
          } else {
            data.falsePositive.push(item)
          }
        } else if (isTrue) {
          data.trueNegative.push(item)
        } else {
          data.falseNegative.push(item)
        }

        return data
      },
      {
        truePositive: [],
        falsePositive: [],
        trueNegative: [],
        falseNegative: [],
      })
    },
    [classes, items],
  )

  return (
    <>
      <Button onClick={showModal} size='small'>
        Show Details
      </Button>
      <Modal
        title={`${classes[0]}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1600}
      >
        <Row gutter={[24, 16]}>
          <Col span={6}>
            <List
              size='small'
              header={<Title level={5}>True Positive</Title>}
              bordered
              dataSource={classifications.truePositive}
              renderItem={(item) => {
                return (
                  <List.Item>
                    <List.Item.Meta
                      title={item.text}
                      description={item.generalized}
                    />
                  </List.Item>
                )
              }}
            />
          </Col>
          <Col span={6}>
            <List
              size='small'
              header={<Title level={5}>False Positive</Title>}
              bordered
              dataSource={classifications.falsePositive}
              renderItem={(item) => {
                return (
                  <List.Item>
                    <List.Item.Meta
                      title={item.text}
                      description={item.generalized}
                    />
                  </List.Item>
                )
              }}
            />
          </Col>
          <Col span={6}>
            <List
              size='small'
              header={<Title level={5}>True Negative</Title>}
              bordered
              dataSource={classifications.trueNegative}
              renderItem={(item) => {
                return (
                  <List.Item>
                    <List.Item.Meta
                      title={item.text}
                      description={item.generalized}
                    />
                  </List.Item>
                )
              }}
            />
          </Col>
          <Col span={6}>
            <List
              size='small'
              header={<Title level={5}>False Negative</Title>}
              bordered
              dataSource={classifications.falseNegative}
              renderItem={(item) => {
                return (
                  <List.Item>
                    <List.Item.Meta
                      title={item.text}
                      description={item.generalized}
                    />
                  </List.Item>
                )
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

ClassificationDetailModal.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      generalized: PropTypes.string.isRequired,
      trueClass: PropTypes.string.isRequired,
      predictedClass: PropTypes.string,
      confidence: PropTypes.number,
    }),
  ).isRequired,
}

export default ClassificationDetailModal
