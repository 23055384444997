import React, { useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import {
  Layout,
  Typography,
  Button,
  Row,
  Col,
} from 'antd'
import { useVersionSelector } from '@core/hooks/useVersions'
import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'

import VersionsActions from '../../../store/actions/Versions'
import VersionsList from '../../../components/VersionsList'

const { Content } = Layout
const { Title } = Typography

export default () => {
  const { current } = useVersionSelector()
  const history = useHistory()
  const { path } = useRouteMatch()
  const toAddNewVersion = () => { history.push(`${path}/new`) }

  useEffect(() => {
    const fetchVersion = async () => {
      await VersionsActions.get()
    }

    fetchVersion()
  }, [])

  return (
    <Content>
      <Row justify='space-between' align='top'>
        <Col span='12'><Title level={4}>Versions</Title></Col>
        <Col span='6' offset='6' style={{ textAlign: 'right' }}>
          {current && (
            <PermittedFeatureCheck featurePath='versions.actionInsert'>
              <Button type='primary' size='large' onClick={toAddNewVersion} icon={<PlusOutlined />}>Add new version</Button>
            </PermittedFeatureCheck>
          )}
        </Col>
      </Row>
      <VersionsList />
    </Content>
  )
}
