import React from 'react'
import { Space, Typography } from 'antd'

import style from './style.module.scss'

const SubmissionLegends = () => {
  return (
    <div className={style.SubmissionLegends}>
      <Typography.Text type='warning'>
        <sup>*&thinsp;</sup>
        Toggle remaining Negative buttons currently works with Level 1 intent
      </Typography.Text>
      <Space wrap>
        <Typography.Text strong>Submission Icon legends</Typography.Text>
        <Space wrap>
          <Typography.Text>⏱ : Submitted; wait for approval,</Typography.Text>
          <Typography.Text>✅ : Approved,</Typography.Text>
          <Typography.Text>❌ : Rejected,</Typography.Text>
          <Typography.Text>？ : Untagged</Typography.Text>
        </Space>
      </Space>
    </div>
  )
}

export default SubmissionLegends
