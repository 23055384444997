import React, { useState, useContext } from 'react'
import {
  Divider,
  Space,
  Button,
  Tooltip,
  Row,
  Col,
  notification,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import { downloadCsvFile } from '@core/helpers'
import { FaqVersionContext } from '../../helpers/context'
import { convertFaqToCsvString } from '../../helpers'
import Api from '../../api'
import {
  useManageFaqsReducer,
  useFetchFaqsEffect,
  setPagination,
  getFaqs,
} from '../../hooks/useManageFaqs'
import QueryFilters from './QueryFilters'
import QueryResult from './QueryResult'
import TestFaqModal from './TestFaqModal'
import CreateNewFaqModal from './CreateNewFaqModal'
import EditFaqModal from './EditFaqModal'

import style from './index.module.scss'

const ManageFaqs = () => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [{
    loading,
    data: { items },
    meta,
  }, manageFaqDispatcher] = useManageFaqsReducer()
  const [currentSearch, setCurrentSearch] = useState({})
  const [testFaqModalOpen, setTestFaqModalOpen] = useState(false)
  const [createNewFaqModalOpen, setCreateNewFaqModalOpen] = useState(false)
  const [editNewFaqModalOpen, setEditNewFaqModalOpen] = useState(false)
  const [selectedEditingFaq, setSelectedEditingFaq] = useState(null)
  const [faqExporting, setFaqExporting] = useState(false)

  const onTestButtonClicked = () => { return setTestFaqModalOpen(true) }
  const closeTestFaqlModal = () => { return setTestFaqModalOpen(false) }

  const onCreateNewFaqClicked = () => { return setCreateNewFaqModalOpen(true) }
  const closeCreateNewFaqlModal = () => { return setCreateNewFaqModalOpen(false) }

  const refreshResult = async () => {
    await getFaqs(manageFaqDispatcher, faqVersionId, currentSearch)
  }

  // NOTE: Reindexing should came from backend
  // the reason that to be on the backend because it should
  // only trigger reindexing at the moment it's immediately deleted

  const onEditFaqClicked = (selectedFaq) => {
    setSelectedEditingFaq(selectedFaq)
    setEditNewFaqModalOpen(true)
  }

  const closeEditFaqlModal = () => {
    setEditNewFaqModalOpen(false)
    setSelectedEditingFaq(null)
  }

  const filteredSearch = (values) => {
    const searchParams = {
      ...values,
      perPage: 50,
      page: 1,
    }

    setCurrentSearch(searchParams)
  }

  const fetchOnTableChange = (pagination) => {
    const searchParams = {
      ...currentSearch,
      perPage: pagination.pageSize,
      page: pagination.current,
    }

    setCurrentSearch(searchParams)
  }

  const setTablePagination = (pagination) => {
    setPagination(manageFaqDispatcher, pagination)
  }

  const downloadCsv = async () => {
    setFaqExporting(true)
    try {
      const [faqs, choiceResponse] = await Promise.all([
        Api.getFaqs({ faq_version_id: faqVersionId, ignore_pagination: true }),
        Api.getChoiceCategories({
          faq_version_id: faqVersionId,
          withChoices: true,
        }),
      ])

      if ((faqs.status === 'success' && faqs.data.items) && (choiceResponse.status === 'success' && choiceResponse.data.items)) {
        const csvString = convertFaqToCsvString(faqs.data.items, choiceResponse.data.items)
        downloadCsvFile(csvString, `faq_entities-${dayjs().format('YYYYMMDDhhmmss')}`)
      }
    } catch (error) {
      console.log('error', error)
      notification.error({
        message: 'Error',
        description: 'We\'ve run into the issue downloading FAQ(s)',
        duration: 5,
      })
    } finally {
      setFaqExporting(false)
    }
  }

  useFetchFaqsEffect(manageFaqDispatcher, faqVersionId, currentSearch)

  return (
    <>
      <div className='ManageFaqs'>
        <Row align='middle'>
          <Col flex={3}>
            <QueryFilters loading={loading} search={filteredSearch} />
          </Col>
          <Col flex={2}>
            <Space split={<Divider />}>
              <Button type='primary' onClick={onTestButtonClicked} className={style.button__Test}>Test FAQ</Button>
              <Button type='primary' onClick={onCreateNewFaqClicked} className={style.button__CreateNew}>Create New FAQ</Button>
              <Tooltip
                title={(
                  <Space direction='vertical'>
                    <span>Download all FAQs entities into CSV file format</span>
                    <Typography.Text type='danger'>Remarks, these field will be export as JSON:</Typography.Text>
                    <ul>
                      <li>Other similar FAQ Question</li>
                      <li>Fallback Response/Direct Response</li>
                      <li>Answer Variations</li>
                    </ul>
                  </Space>
                )}
              >
                <Button
                  type='primary'
                  onClick={downloadCsv}
                  className={style.button__ExportToCsv}
                  loading={faqExporting}
                >
                  Export to CSV
                </Button>
              </Tooltip>
            </Space>
          </Col>
        </Row>
        <Divider />
        <QueryResult
          data={{
            items,
            meta,
          }}
          loading={loading}
          openEditFaqModal={onEditFaqClicked}
          refreshResult={refreshResult}
          fetchOnTableChange={fetchOnTableChange}
          setTablePagination={setTablePagination}
        />
      </div>
      {testFaqModalOpen && (
        <TestFaqModal
          loading={loading}
          visible={testFaqModalOpen}
          closeModal={closeTestFaqlModal}
        />
      )}
      {createNewFaqModalOpen && (
        <CreateNewFaqModal
          dispatcher={manageFaqDispatcher}
          loading={loading}
          visible={createNewFaqModalOpen}
          closeModal={closeCreateNewFaqlModal}
          refreshResult={refreshResult}
        />
      )}
      {editNewFaqModalOpen && (
        <EditFaqModal
          dispatcher={manageFaqDispatcher}
          loading={loading}
          visible={editNewFaqModalOpen}
          closeModal={closeEditFaqlModal}
          selectedEditingFaq={selectedEditingFaq}
          refreshResult={refreshResult}
        />
      )}
    </>
  )
}

export default ManageFaqs
