import React from 'react'
import { Form, Select, Divider } from 'antd'

const datasetOptions = ['ANY', 'TRAINING', 'TEST', 'BOTH']

const ByDatasetType = () => {
  return (
    <>
      <Divider>
        <small><strong>By Dataset Types</strong></small>
      </Divider>
      <Form.Item name='datasetTypes'>
        <Select
          mode='multiple'
          placeholder='Select dataset types'
          allowClear
          options={datasetOptions.map((option) => {
            return { value: option, label: option }
          })}
        />
      </Form.Item>
    </>
  )
}

export default ByDatasetType
