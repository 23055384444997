import React from 'react'
import PropTypes from 'prop-types'
import { Drawer } from 'antd'
import { useSelector } from 'react-redux'
import DrawerActionCreators from '../../store/actions/Drawer'

import SentenceConversation from '../SentenceConversation'
import SentenceNotes from '../SentenceNotes'
import DynamicEntities from '../DynamicEntities'

const ConvertsationInspectorDrawer = ({ width }) => {
  const visible = useSelector(({ data }) => { return (data.drawer.visible) })
  const { enabled, type } = useSelector(({ data }) => { return { ...data.rightPanel } })

  if (!visible) return null

  // NOTE: need to do bodyStyle because the classname would affect the top one
  // cannot use normal css style since css-modules would not able to style

  return (
    <Drawer
      title='Conversation Debug'
      className='SentenceConvertsationInspectorDrawer'
      width={width}
      maskClosable={false}
      visible={visible}
      onClose={DrawerActionCreators.toggle}
      bodyStyle={{
        paddingRight: 16,
        paddingBottom: 0,
        paddingLeft: 16,
      }}
    >
      {enabled && type === 'Dynamic Entities' && <DynamicEntities />}
      {enabled && type === 'Sentence Notes' && <SentenceNotes />}
      {enabled && type === 'Conversation' && <SentenceConversation />}
    </Drawer>
  )
}

ConvertsationInspectorDrawer.defaultProps = {
  width: 512,
}

ConvertsationInspectorDrawer.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

export default ConvertsationInspectorDrawer
