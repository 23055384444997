import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Spin } from 'antd'
import { FaqVersionContext, FaqChoiceCategoriesContext } from '../../../../helpers/context'
import { useFetchFaqChoiceCategoriesFilter } from '../../../../hooks/useManageFaqs'
import ChoicesResponse from './ChoicesResponse'
import DirectChoiceResponseVariations from './DirectResponse'

const Answers = ({ form, choiceCategoryId }) => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [selectedChoiceCategoryId, setSelectedChoiceCategoryId] = useState(choiceCategoryId)
  const [selectedChoiceCategory, setSelectedChoiceCategory] = useState(undefined)
  const [{ loading }, choiceCategories, choiceCategoriesHash] = useFetchFaqChoiceCategoriesFilter(faqVersionId)

  useEffect(() => {
    setSelectedChoiceCategory(() => {
      return {
        ...choiceCategories[choiceCategoriesHash[selectedChoiceCategoryId]],
      }
    })
  }, [choiceCategories, choiceCategoriesHash, selectedChoiceCategoryId])

  if (!form) return null

  return (
    <>
      <Form.Item
        label='Choice Category'
        name='choice_category_id'
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        {loading
          ? <Spin />
          : (
            <Select
              placeholder='Choice Category'
              loading={loading}
              value={selectedChoiceCategoryId}
              onChange={setSelectedChoiceCategoryId}
              disabled
            >
              {choiceCategories.map(
                ({ id, name }, index) => {
                  return (
                    <Select.Option
                      key={index}
                      value={id}
                    >
                      {name}
                    </Select.Option>
                  )
                },
              )}
            </Select>
          )}
      </Form.Item>
      {/* NOTE: Check from props instead, don't know if would cause trouble or not? */}
      {choiceCategoryId ? (
        <FaqChoiceCategoriesContext.Provider value={selectedChoiceCategory}>
          <ChoicesResponse
            choiceCategoryId={selectedChoiceCategoryId}
            faqVersionId={faqVersionId}
            fallbackResponse={form.getFieldValue('fallback_response')}
            choices={form.getFieldValue('response')}
          />
        </FaqChoiceCategoriesContext.Provider>
      ) : <DirectChoiceResponseVariations choices={form.getFieldValue('response')} />}
    </>
  )
}

Answers.defaultProps = {
  form: null,
}

Answers.propTypes = {
  form: PropTypes.shape(PropTypes.object),
  choiceCategoryId: PropTypes.string.isRequired, // choiceCategoryId is force-render skip flag
}

export default Answers
