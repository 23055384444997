import { applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { AUTH } from '@core/store/constants'
import StorageUtils from '@core/utils/storage'

export const authenticationMiddleware = () => {
  return (next) => {
    return (action) => {
      const { type } = action

      if (type === AUTH.LOGIN.SUCCESS || type === AUTH.SYNCHRONIZE_TOKEN) {
        const {
          payload: {
            refresh_token,
            access_token,
          },
        } = action

        StorageUtils.setItem('refresh_token', refresh_token)
        StorageUtils.setItem('access_token', access_token)
      }

      if (type === AUTH.LOGOUT.SUCCESS) {
        // FIXME: amplify use localstorage, needs to find a way to use only session storage
        StorageUtils.purge()
      }

      return next(action)
    }
  }
}

const getMiddlewaresList = () => {
  const middlewaresList = [
    thunk,
    authenticationMiddleware,
  ]

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'canary') {
    middlewaresList.unshift(logger)
  }

  return middlewaresList
}

export const middlewareEnhancers = applyMiddleware(...getMiddlewaresList())
