import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { CopyOutlined, CheckOutlined } from '@ant-design/icons'

const CopySentenceButton = ({ raw }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
    }
    // backward compatible for old browsers
    document.execCommand('copy', true, text)
  }

  const handleCopyClick = async () => {
    try {
      await copyToClipboard(raw)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Button
      type='link'
      onClick={handleCopyClick}
      icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
      style={{ color: isCopied ? '#52c41a' : '#006FCF', transition: 'color 0.3s', cursor: 'pointer' }}
    />
  )
}

CopySentenceButton.propTypes = {
  raw: PropTypes.string,
}

CopySentenceButton.defaultProps = {
  raw: '',
}

export default CopySentenceButton
