import { wrapActionCreatorWithDispatch } from './dispatchInjection'
import { GENERALIZER } from '../constants'
import Api from '../../api'

export default wrapActionCreatorWithDispatch({
  fetch: () => {
    return async (dispatch) => {
      dispatch({ type: GENERALIZER.FETCH.REQUEST })

      try {
        const response = await Api.getGeneralizers()

        if (response && response.status === 'success') {
          dispatch({
            type: GENERALIZER.FETCH.SUCCESS,
            payload: response.data,
          })

          return response
        }

        dispatch({ type: GENERALIZER.FETCH.FAILED })

        throw new Error('Response incompatible')
      } catch (error) {
        dispatch({ type: GENERALIZER.FETCH.FAILED })

        throw error
      }
    }
  },
})
