import React from 'react'
import PropTypes from 'prop-types'
import {
  Spin,
  Typography,
  Table,
} from 'antd'
import { useParams } from 'react-router-dom'
import CoreApi from '@core/api'
import { useVersionDetail } from '@core/hooks/useVersions'
import useRequest from '@core/hooks/useRequest'

const FaqVersions = ({ onSelectFaqVersion, selectedRowKeys, disabled }) => {
  const { version_id } = useParams()
  const version = useVersionDetail(version_id)
  const [{ loading, data, hasError }] = useRequest(CoreApi.Faqs.getVersions)

  if (loading) return <Spin />

  return (
    <>
      {hasError && <Typography.Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Typography.Text>}
      {data && (
        <Table
          loading={loading}
          columns={[
            {
              title: 'Version',
              dataIndex: 'id',
              render: (id) => { return <Typography.Text strong>{version?.faq_version_id === id ? `${id} (Current)` : id}</Typography.Text> },
            },
            {
              title: 'Note',
              dataIndex: 'note',
            },
          ]}
          rowSelection={{
            type: 'radio',
            onChange: onSelectFaqVersion,
            getCheckboxProps: () => { return { disabled } },
            selectedRowKeys,
          }}
          dataSource={data.items.map((v) => { return { ...v, key: v.id } }) ?? []}
          showHeader={false}
        />
      )}
    </>
  )
}

FaqVersions.defaultProps = {
  selectedRowKeys: [0],
}

FaqVersions.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSelectFaqVersion: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number),
}

export default FaqVersions
