import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { SafetyOutlined } from '@ant-design/icons'
import { Auth } from 'aws-amplify'

const Signin = () => {
  const [isPending, setIsPending] = useState(false)
  const [isFederateSignIn, setIsFederateSignIn] = useState(false)

  const onFederateSignIn = () => {
    setIsPending(true)
    setIsFederateSignIn(true)
    Auth.federatedSignIn({ customProvider: process.env.REACT_APP_AWS_AMPLIFY_PROVIDER_NAME || 'AWSSSO' })
  }

  return (
    <Row gutter={[8, 32]} justify='center' align='middle'>
      <Col>
        <Button
          type='primary'
          htmlType='button'
          onClick={onFederateSignIn}
          disabled={isPending}
          aria-disabled={isPending}
          loading={isFederateSignIn}
          icon={<SafetyOutlined />}
          size='large'
        >
          Federated Sign In
        </Button>
      </Col>
    </Row>
  )
}

export default Signin
