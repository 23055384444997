import React from 'react'
import { Layout } from 'antd'
import SideFilter from '../../components/SideFilter'
import EditSentenceModal from '../../components/EditSentenceModal'
import ConversationInspector from '../../components/ConversationInspectorDrawer'
import SentenceQueryResultsTable from '../../components/SentencesQueryResultsTable'
import BulkModalOperations from '../../components/BulkModalOperations'

const { Content } = Layout

const SentencesQuery = () => {
  return (
    <>
      {/*
        NOTE: SideFilter is an antd's Sider component.
        It needs to be outside antd's Content component.
      */}
      <SideFilter />
      <Content>
        <SentenceQueryResultsTable />
        <EditSentenceModal />
        <BulkModalOperations />
      </Content>
      <ConversationInspector />
    </>
  )
}

export default SentencesQuery
