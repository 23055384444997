import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Typography,
  Spin,
  Progress,
  Descriptions,
} from 'antd'
import {
  useRequestReducer,
  ACTION_REQUEST,
  ACTION_SUCCESS,
  ACTION_FAILED,
} from '@core/hooks/useRequest'
import Api from '../../../../api'

const { Text } = Typography

const JobsTableExpandedRow = ({ id }) => {
  const [
    {
      loading,
      data: job,
      hasError,
    },
    dispatch,
  ] = useRequestReducer()

  useEffect(() => {
    let isMounted = true
    const maxRetry = 3
    let retryCount = 0

    const getData = async (noRequestDispatch = false) => {
      if (!noRequestDispatch) {
        dispatch({ type: ACTION_REQUEST })
      }

      try {
        const { data } = await Api.Jobs.getJobStatus({ id })
        if (isMounted) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: {
              data,
            },
          })
          if (data.status === 'RUNNING') {
            retryCount = 0
            setTimeout(() => {
              getData(true)
            }, 5000)
          }
        }
      } catch (error) {
        if (isMounted) {
          if (retryCount >= maxRetry) {
            dispatch({ type: ACTION_FAILED })
          } else {
            retryCount++
            setTimeout(() => {
              getData(true)
            }, 1000)
          }
        }
      }
    }
    getData()

    return () => {
      isMounted = false
    }
  }, [dispatch, id])

  const renderError = () => { return <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text> }

  const renderContent = () => {
    if (job.status === 'RUNNING') {
      if (!job.result) {
        return <Spin />
      }
      return (
        <Col span={16}>
          <Text>{job.result.step}</Text>
          {job.result.progress < 100 && <Spin style={{ paddingLeft: 10 }} />}
          {job.result.progress && <Progress strokeLinecap='square' percent={Math.round(job.result.progress * 100) / 100} />}
        </Col>
      )
    }
    console.log(job)
    if (!job.result || !job.query) {
      return renderError()
    }

    return (
      <Descriptions title='Meta' bordered extra={`Step Size: ${job.query.steps}`}>
        <Descriptions.Item label='Emails Processed'>{job.result.meta.count}</Descriptions.Item>
        <Descriptions.Item label='Iterations'>{job.result.meta.iterations}</Descriptions.Item>
        <Descriptions.Item label='Volume Reduced To'>{`${(job.result.meta.preprocessed_email_length / job.result.meta.raw_email_length).toFixed(2) * 100}%`}</Descriptions.Item>
        <Descriptions.Item label='# body'>{job.result.meta.structures.body}</Descriptions.Item>
        <Descriptions.Item label='# closing'>{job.result.meta.structures.closing}</Descriptions.Item>
        <Descriptions.Item label='# greetings'>{job.result.meta.structures.greetings}</Descriptions.Item>
        <Descriptions.Item label='# header'>{job.result.meta.structures.header}</Descriptions.Item>
        <Descriptions.Item label='# noise'>{job.result.meta.structures.noise}</Descriptions.Item>
        <Descriptions.Item label='# ps'>{job.result.meta.structures.ps}</Descriptions.Item>
      </Descriptions>
    )
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        {hasError && renderError()}
        {loading && <Spin />}
        {job && renderContent()}
      </Col>
    </Row>
  )
}

JobsTableExpandedRow.propTypes = {
  id: PropTypes.string.isRequired,
}

export default JobsTableExpandedRow
