import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Button,
  Divider,
  Form,
  Select,
} from 'antd'
import classnames from 'classnames'

import useRequest from '@core/hooks/useRequest'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import style from './index.module.scss'
import Api from '../../../api'

const { Sider } = Layout

const LeftSider = ({ initialFilterValues, onSearch }) => {
  const [{ data }] = useRequest(Api.ClientGroups.searchAvailableChannels)
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()
  const onFinish = (values) => {
    onSearch(values)
  }

  return (
    <Sider
      className={classnames('aside_filter', { collapsed })}
      width={260}
      collapsedWidth={42}
      collapsed={collapsed}
    >
      <div className={style.left_sider__collapse_button_container}>
        <Button
          size='small'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { return setCollapsed((prev) => { return !prev }) }}
        />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <Divider className={style.left_sider__divider}>
          <strong>Channel Query</strong>
        </Divider>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={initialFilterValues}
        >
          <Form.Item name='channels'>
            <Select
              mode='multiple'
              placeholder='Search by channel names'
              style={{ width: '100%' }}
              allowClear
            >
              {data?.map((item) => {
                return (
                  <Select.Option key={item.key} value={item.key}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Button
            className={style.left_sider__search_button}
            type='primary'
            htmlType='submit'
            block
          >
            Search
          </Button>
        </Form>
      </div>
    </Sider>
  )
}

LeftSider.propTypes = {
  onSearch: PropTypes.func.isRequired,
  initialFilterValues: PropTypes.shape({ }).isRequired,
}

export default LeftSider
