import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Spin,
} from 'antd'
import useRequest from '@core/hooks/useRequest'
import CoreApi from '@core/api'
import JobsTable from './JobsTable'

const { Text } = Typography

const TagsJobTab = ({ job }) => {
  const [spinTip] = useState('')
  const [
    {
      loading,
      data: jobs,
      hasError,
    },
    { makeRequest },
  ] = useRequest(CoreApi.getAllJobs, {
    path: 'providers.Conversations.runConversationsTagging',
  })

  useEffect(() => {
    makeRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job])

  return (
    <Spin spinning={!!spinTip} tip={spinTip}>
      {loading && <Spin />}
      {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
      {jobs && <JobsTable jobs={jobs} />}
    </Spin>
  )
}

TagsJobTab.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
  }),
}

TagsJobTab.defaultProps = {
  job: null,
}

export default TagsJobTab
