import React, { useState, useContext } from 'react'
import dayjs from 'dayjs'
import {
  Row,
  Col,
  Typography,
  Spin,
  Table,
  Button,
  Alert,
  Modal,
  Tooltip,
} from 'antd'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import useRequest from '@core/hooks/useRequest'
import { FaqVersionContext } from '../../helpers/context'
import Api from '../../api'
import PhrasesColumn from './PhrasesColumn'
import KeywordPhrasesAddDialog from './KeywordPhrasesAddDialog'
import KeywordPhrasesEditDialog from './KeywordPhrasesEditDialog'

const { Text } = Typography

const ManageKeywordPhrases = () => {
  const { faqVersionId } = useContext(FaqVersionContext)
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 50,
  })
  const [{
    loading,
    data,
    hasError,
  }, { makeRequest }] = useRequest(Api.getCombinations, {
    faq_version_id: faqVersionId,
    page: pagination.page,
    perPage: pagination.perPage,
  })

  const [editingId, setEditingId] = useState(null)
  const [addingVisible, setAddingVisible] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)

  const renderTable = () => {
    const { items: dataSource, meta } = data

    const columns = [
      {
        title: 'Keyword',
        dataIndex: 'keyword',
        key: 'keyword',
      },
      {
        title: 'Phrases',
        dataIndex: 'phrases',
        key: 'phrases',
        render: (value) => { return <PhrasesColumn phrases={value} /> },
      },
      {
        title: 'Language',
        dataIndex: 'language',
        key: 'language',
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value) => { return <>{dayjs(value).format('DD MMM YYYY@HH:mm')}</> },
      },
      {
        title: 'Updated At',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (value) => { return (value ? dayjs(value).format('DD MMM YYYY@HH:mm') : '') },
      },
      {
        title: 'Action',
        key: 'id',
        render: (combination) => {
          const handleEditClick = async () => {
            setEditingId(combination.id)
          }

          function showDeleteConfirm() {
            Modal.confirm({
              title: `Are you sure you want to delete "${combination.keyword}"?`,
              icon: <ExclamationCircleOutlined />,
              async onOk() {
                try {
                  await Api.deleteCombination(combination.id)
                  setFlashMessage({ type: 'success', message: `Deleted "${combination.keyword}" keyword phrases` })
                  await makeRequest()
                } catch (error) {
                  setFlashMessage({ type: 'error', message: `Cannot delete "${combination.keyword}" keyword phrases` })
                }
              },
            })
          }

          return (
            <>
              <Tooltip title='Edit'>
                <Button
                  type='link'
                  loading={loading}
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              </Tooltip>
              <Tooltip title='Delete'>
                <Button
                  type='link'
                  danger
                  loading={loading}
                  onClick={showDeleteConfirm}
                >
                  Delete
                </Button>
              </Tooltip>
            </>
          )
        },
      },
    ]

    return (
      <Table
        rowKey='id'
        dataSource={dataSource}
        columns={columns}
        size='small'
        pagination={{
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          showTotal: (total, range) => { return `${range[0]} - ${range[1]} of ${total} items` },
          current: meta.page,
          defaultPageSize: meta.perPage,
          total: meta.totalCount,
          onChange(page, perPage) {
            setPagination({
              page: pagination.perPage !== perPage ? 1 : page,
              perPage,
            })
          },
        }}
      />
    )
  }

  const handleAddClick = async () => {
    setAddingVisible(true)
  }

  const onAddDismiss = async () => {
    setAddingVisible(false)
  }

  const onEditDismiss = async () => {
    setEditingId(null)
  }

  const onCreated = async ({ keyword }) => {
    setAddingVisible(false)
    setFlashMessage({ type: 'success', message: `Created "${keyword}" keyword phrases` })
    await makeRequest()
  }

  const onUpdated = async ({ keyword }) => {
    setEditingId(null)
    setFlashMessage({ type: 'success', message: `Updated "${keyword}" keyword phrases` })
    await makeRequest()
  }

  const handleFlashMessageClose = () => {
    setFlashMessage(null)
  }

  return (
    <Row gutter={[24, 16]}>
      {addingVisible && (
        <KeywordPhrasesAddDialog
          faqVersionId={faqVersionId}
          visible={addingVisible}
          onDismiss={onAddDismiss}
          onCreated={onCreated}
        />
      )}
      <KeywordPhrasesEditDialog
        id={editingId}
        onDismiss={onEditDismiss}
        onUpdated={onUpdated}
      />
      <Col span={24}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, paddingRight: 8 }}>
            {flashMessage && (
              <Alert
                message={flashMessage.message}
                type={flashMessage.type}
                showIcon
                closable
                onClose={handleFlashMessageClose}
                style={{ marginBottom: 8 }}
              />
            )}
          </div>
          <Button type='primary' onClick={handleAddClick} icon={<PlusOutlined />}>Create New Keyword Phrases</Button>
        </div>
      </Col>
      <Col span={24}>
        {loading && <Spin />}
        {hasError && <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text>}
        {data && renderTable()}
      </Col>
    </Row>
  )
}

export default ManageKeywordPhrases
