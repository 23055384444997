import React from 'react'
import { Typography, Popover, Space, Button, Tag, List, Badge } from 'antd'
import { FilterOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { splitGeneralizedSentenceByHighlighted } from '@core/helpers/sentence'
import { generalizedSentenceType, onHighlightedFilterClickType, tagsType } from '../../types/generalizedSentence'

const { Text } = Typography

const selectColor = (value) => {
  if (value < 0.5) return 'danger'
  if (value >= 0.5 && value < 0.9) return 'warning'
  return 'success'
}

const GeneralizedSentence = React.memo(({ generalizedVer, onHighlightedFilterClick, tags }) => {
  const sentencePhrases = splitGeneralizedSentenceByHighlighted(generalizedVer)
  const tagPopOver = (text, backgroundColor, tagMetas) => {
    if (!tagMetas) return null
    const { sources: sourceList, scores, originalSynonyms } = tagMetas
    const sources = sourceList.join(', ')

    return (
      <Space direction='vertical'>
        {
          tagMetas
        && (
          <>
            <Space>
              <Tag>{tagMetas.phraseIndex}</Tag>
              <Text>{tagMetas.original}</Text>
              <ArrowRightOutlined />
              <Text>{tagMetas.predictedSource}</Text>
            </Space>
            { scores && scores.length > 0
            && (
              <List
                bordered
                size='small'
                dataSource={scores}
                renderItem={({ label, value, tag }) => {
                  return (
                    <List.Item>
                      <Space direction='horizontal'>
                        <Text>{label || tag}</Text>
                        <Text type={selectColor(value)}>{value}</Text>
                      </Space>
                    </List.Item>
                  )
                }}
              />
            )}
            <div>{`Sources: [${sources}]`}</div>
            {originalSynonyms && (
              <List
                header={(<b>Original Synonyms</b>)}
                bordered
                size='small'
                dataSource={Object.entries(originalSynonyms)}
                renderItem={([entity, original]) => {
                  return (
                    <List.Item key={`${entity}-${original}`}>{`${entity}: ${original}`}</List.Item>
                  )
                }}
              />
            )}
          </>
        )
        }
        <Button
          type='text'
          icon={<FilterOutlined />}
          onClick={(e) => {
            e.stopPropagation()
            onHighlightedFilterClick({ text, backgroundColor })
          }}
        >
          {`Filter by ${text}`}
        </Button>
      </Space>
    )
  }
  let symbolIndex = -1
  return (
    <Text style={{ lineHeight: 1.8, wordSpacing: '2px' }}>
      {sentencePhrases.map(({ text, isTag, backgroundColor, color }, index) => {
        if (!isTag) {
          return (
            <>
              <span key={index}>{text}</span>
              {' '}
            </>
          )
        }
        const markStyle = {
          backgroundColor, cursor: 'pointer', borderRadius: '6px', color, padding: '2px 6px',
        }
        symbolIndex++
        if (!onHighlightedFilterClick) {
          return (
            <>
              <mark key={index} style={markStyle}>{text}</mark>
              {' '}
            </>
          )
        }
        let currentTag
        let status
        if (isTag && tags.length > 0) {
          [currentTag] = tags.filter((item, i) => { return i === symbolIndex })
          if (currentTag.scores && currentTag.scores.length > 0) {
            status = selectColor(currentTag.scores[0].value)
          }
        }
        return (
          <Popover placement='bottom' key={index} content={() => { return tagPopOver(text, backgroundColor, currentTag) }}>
            {
              status && currentTag.scores.length > 1
                ? (
                  <span>
                    <Badge status={status || ''} dot>
                      <mark style={markStyle}>{text}</mark>
                    </Badge>
                    {' '}
                  </span>
                )
                : (
                  <span>
                    <mark style={markStyle}>{text}</mark>
                    {' '}
                  </span>
                )
            }
          </Popover>
        )
      })}
    </Text>
  )
})

GeneralizedSentence.propTypes = {
  generalizedVer: generalizedSentenceType.isRequired,
  onHighlightedFilterClick: onHighlightedFilterClickType,
  tags: tagsType,
}

GeneralizedSentence.defaultProps = {
  onHighlightedFilterClick: null,
  tags: [],
}

export default GeneralizedSentence
