import { wrapActionCreatorWithDispatch } from '@core/store/actions/dispatchInjection'
import { BULK_OPERATION_MODAL_DATA } from '../constants'

// TODO: UtteranceTableRefactor - remove this reducer, possibly this will be replaced with Context + useReducer

// This object accepts both functions that returns obj or object for dispatch
export default wrapActionCreatorWithDispatch({
  // based on antd table onChange, which returns whole selection
  select: (sentenceIds) => {
    return async (dispatch, getState) => {
      const { listed } = getState().data.sentences

      dispatch({
        type: BULK_OPERATION_MODAL_DATA.SELECT,
        payload: {
          // NOTE:
          // we can just pass the sentenceIds "but"
          // antd does not preserve order like table shown in UI
          // when row has been select
          // it just put a new one, either push to a stack or popped out
          // previously it has order preserved
          selectedSentences: listed.reduce((arr, sentence) => {
            if (sentenceIds.indexOf(sentence.id) !== -1) arr.push(sentence.id)
            return arr
          }, []),
          sentencesListCheck: listed.reduce((acc, current) => {
            acc[current.id] = sentenceIds.indexOf(current.id) !== -1
            return acc
          }, {}),
        },
      })
    }
  },
  dismiss: () => {
    return async (dispatch) => {
      dispatch({ type: BULK_OPERATION_MODAL_DATA.DISMISS })
    }
  },
})
