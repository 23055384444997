import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Typography,
  Spin,
  Tag,
  Alert,
  Divider,
  Space,
  Statistic,
  Progress,
  Descriptions,
  Badge,
  Tooltip,
} from 'antd'
import { useSelector } from 'react-redux'
import {
  useRequestReducer,
  ACTION_REQUEST,
  ACTION_SUCCESS,
  ACTION_FAILED,
} from '@core/hooks/useRequest'
import Api from '@core/api'
import ConfusionMatrix from '../../ConfusionMatrix'
import ClassificationDetailModal from '../../ClassificationDetailModal'
import TaggerEvaluationDetailModal from '../../TaggerEvaluationDetailModal'
import DataEvaluationDetailModal from '../../DataEvaluationDetailModal'

const { Text, Title } = Typography

const JobsTableExpandedRow = ({ id }) => {
  const [
    {
      loading,
      data: job,
      hasError,
    },
    dispatch,
  ] = useRequestReducer()

  useEffect(() => {
    let isMounted = true
    const maxRetry = 3
    let retryCount = 0

    const getData = async (noRequestDispatch = false) => {
      if (!noRequestDispatch) {
        dispatch({ type: ACTION_REQUEST })
      }

      try {
        const { data } = await Api.getJobStatus({ id })
        if (isMounted) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: {
              data,
            },
          })
          if (data.status === 'RUNNING') {
            retryCount = 0
            setTimeout(() => {
              getData(true)
            }, 5000)
          }
        }
      } catch (error) {
        if (isMounted) {
          if (retryCount >= maxRetry) {
            dispatch({ type: ACTION_FAILED })
          } else {
            retryCount++
            setTimeout(() => {
              getData(true)
            }, 1000)
          }
        }
      }
    }
    getData()

    return () => {
      isMounted = false
    }
  }, [dispatch, id])

  const { intentCascader, entityTaggers } = useSelector(({ core, experiments }) => {
    return {
      intentCascader: core.domainIntent.intentCascader,
      entityTaggers: experiments.taggers.entityTaggers,
    }
  })

  const classes = useMemo(() => {
    if (!job || !job.result) {
      return []
    }

    if (job.result.type === 'intent') {
      return intentCascader.map((item) => { return item.children.map(({ label }) => { return label }) }).flat()
    }

    return intentCascader.map(({ label }) => { return label })
  }, [intentCascader, job])

  if (!classes.includes('null')) classes.push('null')

  const renderError = () => { return <Text type='danger'>We&#39;ve encountered some problems, please try again in few minutes</Text> }

  const renderContent = () => {
    if (job.status === 'RUNNING') {
      if (!job.result) {
        return <Spin />
      }
      return (
        <Col span={16}>
          <Text>{job.result.step}</Text>
          {job.result.progress < 100 && <Spin style={{ paddingLeft: 10 }} />}
          {job.result.progress && <Progress strokeLinecap='square' percent={Math.round(job.result.progress * 100) / 100} />}
        </Col>
      )
    }

    if (!job.result || !job.result.evaluationResult || !job.result.evaluationResult.results || !job.query) {
      return renderError()
    }

    const taggerResults = {}
    if (job.query.type === 'tagger') {
      return (
        <>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Descriptions title='Details' bordered>
                <Descriptions.Item label='Type'>{job.result.type || '-'}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row gutter={[16, 8]} style={{ marginTop: 24 }}>
            <Col span={12}>
              <Alert
                showIcon
                message={(<Text strong>Time results (ms)</Text>)}
                description={(
                  <Space size='large' split={<Divider type='vertical' />}>
                    <Statistic title='Evaluate Time' value={job.result.time.evaluateTime} />
                  </Space>
                )}
                type='info'
              />
            </Col>
            <Col span={12}>
              <Alert
                showIcon
                message={(<Text strong>Evaluation results</Text>)}
                description={(
                  <Space size='large' split={<Divider type='vertical' />}>
                    <Statistic title='Fail' value={job.result.evaluationResult.metrics.fail} />
                    <Statistic title='Pass' value={job.result.evaluationResult.metrics.pass} />
                    <Statistic title='Total' value={job.result.evaluationResult.metrics.total} />
                    <Statistic title='Error' value={job.result.evaluationResult.metrics.error} />
                    <Statistic title='Incomplete' value={job.result.evaluationResult.metrics.incomplete} />
                  </Space>
                )}
                type='success'
              />
            </Col>
            {entityTaggers.map((taggerClass) => {
              taggerResults[taggerClass] = { total: 0, passed: 0, failed: 0 }
              const items = job.result.evaluationResult.results.map((result) => {
                if (taggerClass !== result.tagger_type) return result
                taggerResults[taggerClass].total++
                if (result.testPassed) taggerResults[taggerClass].passed++
                else taggerResults[taggerClass].failed++

                return result
              })

              return (
                <Col span={8} key={taggerClass}>
                  <Row span={3}>
                    <Title level={4} style={{ paddingRight: 10 }}>{taggerClass}</Title>
                  </Row>
                  <Row span={3}>
                    <Text type={taggerResults[taggerClass].passed === taggerResults[taggerClass].total ? 'success' : 'warning'}>{`${taggerResults[taggerClass].passed} / ${taggerResults[taggerClass].total} evaluations passed`}</Text>
                  </Row>
                  <Row span={3}>
                    <TaggerEvaluationDetailModal tagger={taggerClass} items={items} />
                  </Row>
                </Col>
              )
            })}
          </Row>
        </>
      )
    }

    const dataResults = { }
    if (job.query.type === 'data') {
      const extractionKeys = []
      job.result.evaluationResult.results.forEach((result) => {
        if (!extractionKeys.includes(result.intent)) extractionKeys.push(result.intent)
      })
      return (
        <>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Descriptions title='Details' bordered>
                <Descriptions.Item label='Type'>{job.result.type || '-'}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row gutter={[16, 8]} style={{ marginTop: 24 }}>
            <Col span={12}>
              <Alert
                showIcon
                message={(<Text strong>Time results (ms)</Text>)}
                description={(
                  <Space size='large' split={<Divider type='vertical' />}>
                    <Statistic title='Evaluate Time' value={job.result.time.evaluateTime} />
                  </Space>
                )}
                type='info'
              />
            </Col>
            <Col span={12}>
              <Alert
                showIcon
                message={(<Text strong>Evaluation results</Text>)}
                description={(
                  <Space size='large' split={<Divider type='vertical' />}>
                    <Statistic title='Fail' value={job.result.evaluationResult.metrics.fail} />
                    <Statistic title='Pass' value={job.result.evaluationResult.metrics.pass} />
                    <Statistic title='Total' value={job.result.evaluationResult.metrics.total} />
                    <Statistic title='Error' value={job.result.evaluationResult.metrics.error} />
                    <Statistic title='Skipped' value={job.result.evaluationResult.metrics.skipped} />
                    <Statistic title='Incomplete' value={job.result.evaluationResult.metrics.incomplete} />
                  </Space>
                )}
                type='success'
              />
            </Col>
            <Col span={24}>
              <Alert
                message={(
                  <Space>
                    <Badge status='success' text='Passed' />
                    <Badge status='warning' text='Failed' />
                    <Badge status='error' text='Error' />
                    <Badge status='default' text='Skipped' />
                    <Divider type='vertical' />
                    <Tooltip title='Does not count for the total amount'>
                      <Badge status='processing' text='Incomplete' />
                    </Tooltip>
                  </Space>
                )}
              />
            </Col>
            {[...extractionKeys].map((intent) => {
              dataResults[intent] = {
                total: 0, passed: 0, failed: 0, skipped: 0, error: 0, incomplete: 0,
              }
              const items = job.result.evaluationResult.results.map((result) => {
                if (!result.intent || result.intent !== intent) return result
                if (result.incomplete) dataResults[intent].incomplete++
                if (result.error) {
                  dataResults[intent].error++
                  return result
                }
                if (result.skip) {
                  dataResults[intent].skipped++
                  return result
                }
                dataResults[intent].total++
                if (result.testPassed) dataResults[intent].passed++
                else dataResults[intent].failed++
                return result
              })

              return (
                <Col span={8} key={intent}>
                  <Row span={3}>
                    <Title
                      level={5}
                      type={dataResults[intent].failed > 0 ? 'warning' : ''}
                      style={{ paddingRight: 10 }}
                    >
                      <Space>
                        <Tag color={dataResults[intent].failed > 0 || dataResults[intent].error ? 'warning' : 'success'}>{intent}</Tag>
                        <Badge status='success' text={dataResults[intent].passed} />
                        <Badge status='warning' text={dataResults[intent].failed} />
                        <Badge status='error' text={dataResults[intent].error} />
                        <Badge status='default' text={dataResults[intent].skipped} />
                        <Divider type='vertical' />
                        <Badge status='processing' text={dataResults[intent].incomplete} />
                      </Space>
                    </Title>
                  </Row>
                  <Row span={3}>
                    <DataEvaluationDetailModal intent={intent} items={items} />
                  </Row>
                </Col>
              )
            })}
          </Row>
        </>
      )
    }
    return (
      <>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Descriptions title='Details' bordered>
              <Descriptions.Item label='Type'>{job.result.type || '-'}</Descriptions.Item>
              <Descriptions.Item label='Strategy'>{job.result.strategyName || '-'}</Descriptions.Item>
              <Descriptions.Item label='Epochs'>{job.result.epochs || '-'}</Descriptions.Item>
              <Descriptions.Item label='Window Size'>{job.result.windowSize || '-'}</Descriptions.Item>
              <Descriptions.Item label='Pattern Window Size'>{job.result.patternWindowSize || '-'}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row gutter={[16, 8]} style={{ marginTop: 24 }}>
          <Col span={12}>
            <Alert
              showIcon
              message={(<Text strong>Time results (ms)</Text>)}
              description={(
                <Space size='large' split={<Divider type='vertical' />}>
                  <Statistic title='Evaluate Time' value={job.result.time.evaluateTime} />
                </Space>
              )}
              type='info'
            />
          </Col>
          <Col span={12}>
            <Alert
              showIcon
              message={(<Text strong>Evaluation results</Text>)}
              description={(
                <Space size='large' split={<Divider type='vertical' />}>
                  <Statistic title='Fail' value={job.result.evaluationResult.metrics.fail} />
                  <Statistic title='Pass' value={job.result.evaluationResult.metrics.pass} />
                  <Statistic title='Total' value={job.result.evaluationResult.metrics.total} />
                </Space>
              )}
              type='success'
            />
          </Col>
          <Col span={24}>
            <h3>
              Confusion Matrix
            </h3>
            <span>Y-Axis: Predicted Class, X-Axis: True Class</span>
            <ConfusionMatrix classes={classes} items={job.result.evaluationResult.results} withNotRecognized />
          </Col>
          {classes.map((theClass) => {
            const items = job.result.evaluationResult.results.map((result) => {
              const item = { ...result }
              if (theClass === 'null') theClass = null
              if (result.trueClass !== theClass) {
                item.trueClass = 'other'
              }
              if (item.predictedClass !== theClass) {
                item.predictedClass = 'other'
              }
              return item
            })

            return (
              <Col span={6} key={theClass || 'null'}>
                <div style={{ display: 'flex' }}>
                  <h3 style={{ paddingRight: 10 }}>{theClass || 'NULL'}</h3>
                  <ClassificationDetailModal classes={[theClass, 'other']} items={items} />
                </div>
                <ConfusionMatrix classes={[theClass || 'null', 'other']} items={items} />
              </Col>
            )
          })}
        </Row>
      </>
    )
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        {hasError && renderError()}
        {loading && <Spin />}
        {job && renderContent()}
      </Col>
    </Row>
  )
}

JobsTableExpandedRow.propTypes = {
  id: PropTypes.string.isRequired,
}

export default JobsTableExpandedRow
