import StoreUtils from '@core/utils/store'

const EXTRACT_INFORMATION = StoreUtils.createActionStatus('EXTRACT_INFORMATION')
const ADD_TEST_SET = StoreUtils.createActionStatus('ADD_TEST_SET')
const OPEN_MODAL = 'OPEN_MODAL'
const DISMISS_MODAL = 'DISMISS_MODAL'
const SET_SENTENCE_ANALYSIS_DATA = 'SET_SENTENCE_ANALYSIS_DATA'

export {
  EXTRACT_INFORMATION,
  ADD_TEST_SET,
  OPEN_MODAL,
  DISMISS_MODAL,
  SET_SENTENCE_ANALYSIS_DATA,
}
