import React from 'react'
import PropTypes from 'prop-types'
import {
  Descriptions,
  Spin,
  Typography,
  Row,
  Col,
  Divider,
  Switch,
} from 'antd'
import useAnalysis from '@core/hooks/useAnalysis'
import JSONPreview from '@core/components/JsonPreview'

const AnalyzedSentence = ({
  sentence,
  unrecognized,
  unrecognizedValueChanging,
  onUnrecognizedToggle,
}) => {
  const [{ loading, isError }, analysis] = useAnalysis(sentence)

  if (loading) return (<Spin size='small' />)

  if (isError) {
    return (
      <>
        <Typography.Title level={4}>Analyze/Classification</Typography.Title>
        <Row gutter={[12, 12]}>
          <Col flex={1}>
            <Typography.Text type='danger'>Analyze Error</Typography.Text>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Typography.Title level={4}>Analyze/Classification</Typography.Title>
      <Row gutter={[12, 12]}>
        <Col span='12'>
          <Descriptions bordered column={1}>
            <Descriptions.Item label='Domain'>{analysis.domain}</Descriptions.Item>
            <Descriptions.Item label='Domain Confidence'>{analysis.domainConfidence}</Descriptions.Item>
            <Descriptions.Item label='Intent'>{analysis.intent}</Descriptions.Item>
            <Descriptions.Item label='Intent Confidence'>{analysis.intentConfidence}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <Typography.Title level={4}>Actions</Typography.Title>
          <>
            <Switch onChange={onUnrecognizedToggle} defaultChecked={unrecognized} loading={unrecognizedValueChanging} />
            &thinsp;
            Marked Unrecognized
          </>
        </Col>
        <Col span='12'>
          <JSONPreview content={JSON.stringify(analysis.data, null, 2)} autoSize />
        </Col>
      </Row>
    </>
  )
}

AnalyzedSentence.propTypes = {
  sentence: PropTypes.string.isRequired,
  unrecognized: PropTypes.bool.isRequired,
  unrecognizedValueChanging: PropTypes.bool.isRequired,
  onUnrecognizedToggle: PropTypes.func.isRequired,
}

export default AnalyzedSentence
