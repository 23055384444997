import PropTypes from 'prop-types'

const {
  func,
  number,
  string,
} = PropTypes

export const onChangeType = func
export const placeholderType = string
export const trainDomainIdType = number
