import React from 'react'
import { Modal, Typography } from 'antd'

const openDegradedTextVariationInformationModal = () => {
  Modal.info({
    title: 'Degraded Text Variations',
    content: (
      <>
        <Typography.Paragraph>
          Each text variations handles a different scenarios depends on data parameters
          coming from the conversation/external thrid-party services/different services.
          It could be a PNR numbers, type of bookings.
          Indicated by
          <Typography.Text strong>{' placeholder (Curly Brackets) '}</Typography.Text>
          like this:
        </Typography.Paragraph>
        <Typography.Paragraph code>
          {'{{parameters}}'}
        </Typography.Paragraph>
        <Typography.Paragraph>
          Whereas
          {' '}
          <Typography.Text code>parameters</Typography.Text>
          {' '}
          could be:
          {' '}
          <Typography.Text italic>pnr, links, actions</Typography.Text>
          , et al.
        </Typography.Paragraph>
        <Typography.Paragraph>
          When there are insufficient data/parameters,
          it will gradually look at another variations that has one or more matching
          <Typography.Text strong>{' placeholder (Curly Brackets).'}</Typography.Text>
        </Typography.Paragraph>
        <Typography.Paragraph>
          In worst case there is no data/error,
          you must provide the variations that
          <Typography.Text italic type='danger'>{' DOES NOT CONTAINS ANY PLACEHOLDER '}</Typography.Text>
          as a default/fallback error message,
          letting user know that there is an error on our side.
          It could be only one message, it is not necessary to be a split line message.
        </Typography.Paragraph>
      </>
    ),
  })
}

export default openDegradedTextVariationInformationModal

