import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Space,
  Divider,
  Typography,
  Statistic,
  Button,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { BulkModeStorageInterface } from '../../../helpers'
import { EmailAutomationsContext } from '../../../context'
import RecentlyAddedIntents from '../RecentlyAddedIntents'

const BulkEmailHistoryModal = ({ visible, closeBulkEmailHistoryModal }) => {
  // instead of using context, retrieve it and save to useState
  // context is snapshot of first render, otherwise it needs to change to state from the top
  const { datasource } = useContext(EmailAutomationsContext)
  const [bulkModeChangeHistory, setBulkModeChangeHistory] = useState(() => {
    return (
      BulkModeStorageInterface.retrieveRecentlyChanged()[datasource]
    )
  })

  if (!visible) return null
  const confirmDeleteAllHistoryInLocalStorage = () => {
    Modal.confirm({
      title: (
        <Typography.Text>
          This will remove all bulk mode operation history from your user.
          {' '}
          <strong>Are you sure about this?</strong>
        </Typography.Text>
      ),
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      content: 'This is IRREVERSIBLE',
      onOk: () => {
        BulkModeStorageInterface.purgeRecentlyUpdateLog()
        setBulkModeChangeHistory(() => {
          return (
            BulkModeStorageInterface.retrieveRecentlyChanged()[datasource]
          )
        })
      },
      okType: 'danger',
    })
  }

  return (
    <Modal
      title='View bulk intent history Details'
      visible={visible}
      footer={null}
      onCancel={closeBulkEmailHistoryModal}
      destroyOnClose
      width='80%'
    >
      <Space>
        <Typography.Text>
          Current datasource:
          {' '}
          <Typography.Text strong>{datasource}</Typography.Text>
        </Typography.Text>
        <Typography.Text>
          Datasource name:
          {' '}
          <Typography.Text strong>{bulkModeChangeHistory.datasource}</Typography.Text>
        </Typography.Text>
      </Space>
      <Divider />
      <>
        <Statistic title='Training Samples' value={bulkModeChangeHistory.trainingCases.length} />
        <RecentlyAddedIntents recentlyAddedIntents={bulkModeChangeHistory.trainingCases} />
      </>
      <Divider />
      <Button onClick={confirmDeleteAllHistoryInLocalStorage} danger>
        Clear history
      </Button>
    </Modal>
  )
}

BulkEmailHistoryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeBulkEmailHistoryModal: PropTypes.func.isRequired,
}

export default BulkEmailHistoryModal
