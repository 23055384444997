import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Space } from 'antd'

import style from './style.module.scss'

const ClientInformation = ({ capId, countryOfEmployment }) => {
  return (
    <div className={style.ClientInformation}>
      <Space size={4}>
        <Typography.Text strong>CAPID:</Typography.Text>
        <Typography.Text>{capId ?? 'N/A'}</Typography.Text>
      </Space>
      <Space size={4}>
        <Typography.Text strong>COE:</Typography.Text>
        <Typography.Text>{countryOfEmployment ?? 'N/A'}</Typography.Text>
      </Space>
    </div>
  )
}

ClientInformation.propTypes = {
  capId: PropTypes.string.isRequired,
  countryOfEmployment: PropTypes.string.isRequired,
}

export default ClientInformation
