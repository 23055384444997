import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Space, Typography, Row, Col, Divider, Button, Tabs, Result } from 'antd'
import { CopyOutlined, CheckOutlined } from '@ant-design/icons'
import useCopyTextWithTimeout from '@core/hooks/useCopyTextWithTimeout'
import JsonPreview from '@core/components/JsonPreview'

import style from './style.module.scss'

const EmailContentPreview = ({ selectedEmail }) => {
  const [activeEmailPreviewTab, setActiveEmailPreviewTab] = useState('compare')
  const [isBodyCopied, copyBody] = useCopyTextWithTimeout(selectedEmail.body)
  const [isProcessedBodyCopied, copyPreprocessedBody] = useCopyTextWithTimeout(selectedEmail.preprocessed_body)

  if (!selectedEmail) {
    return (
      <Result
        status='warning'
        title='Email items malformed or not a correct data type'
      />
    )
  }

  // FIXME: style.EmailContentPreview doesn't work becase it was copied from
  // the Email Checking which has different html/css structure
  return (
    <Tabs activeKey={activeEmailPreviewTab} onChange={setActiveEmailPreviewTab}>
      <Tabs.TabPane key='compare' tab='Compare Pre-processed'>
        <div className={style.EmailContentPreview}>
          <Row className={style.Header} gutter={[8, 0]}>
            <Col span={12}>
              <Space align='baseline'>
                <Typography.Text strong>Raw</Typography.Text>
                <Button
                  type='text'
                  size='small'
                  className={isBodyCopied ? style.Copied : style.Copy}
                  icon={isBodyCopied ? <CheckOutlined /> : <CopyOutlined />}
                  onClick={copyBody}
                />
              </Space>
              <Divider />
            </Col>
            <Col span={12}>
              <Space align='baseline'>
                <Typography.Text strong>Pre-processed</Typography.Text>
                <Button
                  type='text'
                  size='small'
                  className={isProcessedBodyCopied ? style.Copied : style.Copy}
                  icon={isProcessedBodyCopied ? <CheckOutlined /> : <CopyOutlined />}
                  onClick={copyPreprocessedBody}
                />
              </Space>
              <Divider />
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col span={12}>
              <Typography.Paragraph className={style.EmailContent}>
                {selectedEmail?.body}
              </Typography.Paragraph>
            </Col>
            <Col span={12}>
              <Typography.Paragraph className={style.EmailContent}>
                {selectedEmail?.cleaned_email_body}
              </Typography.Paragraph>
            </Col>
          </Row>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane key='anatomy' tab='Anatomy'>
        <JsonPreview content={JSON.stringify(selectedEmail.email_anatomy, null, 2)} autoSize />
      </Tabs.TabPane>
      <Tabs.TabPane key='source' tab='Source'>
        <JsonPreview content={JSON.stringify(selectedEmail, null, 2)} autoSize />
      </Tabs.TabPane>
    </Tabs>
  )
}

EmailContentPreview.propTypes = {
  selectedEmail: PropTypes.shape({
    id: PropTypes.string,
    from_address: PropTypes.string,
    to_address: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    cleaned_email_subject: PropTypes.string,
    cleaned_email_body: PropTypes.string,
    source: PropTypes.string,
    language: PropTypes.string,
    email_sent_at: PropTypes.string,
    record_created_at: PropTypes.string,
    status: PropTypes.string,
    datasetType: PropTypes.string,
    feedback: PropTypes.string,
    intentSamples: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
    preprocessed_body: PropTypes.string,
    email_anatomy: PropTypes.shape({
      header: PropTypes.arrayOf(PropTypes.string),
      greetings: PropTypes.arrayOf(PropTypes.string),
      body: PropTypes.arrayOf(PropTypes.string),
      closing: PropTypes.arrayOf(PropTypes.string),
      ps: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
}

export default EmailContentPreview
