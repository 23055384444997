const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const getOption = (data) => {
  const years = []
  const countByYear = {}
  const options = {}

  data.forEach(({
    year,
    month,
    value,
  }) => {
    if (!options[year]) {
      years.push(year)
      countByYear[year] = 0
      options[year] = {
        title: {
          text: year,
        },
        series: [
          {
            data: months.map((name) => {
              return {
                name,
                value: 0,
              }
            }),
          },
        ],
      }
    }
    countByYear[year] += value
    for (let i = 0; i < options[year].series[0].data.length; i++) {
      const dataItem = options[year].series[0].data[i]

      if (dataItem.name === month) {
        dataItem.value = value
        break
      }
    }
  })

  years.forEach((year) => {
    options[year].title.subtext = `${countByYear[year]} conv.`
  })

  return {
    baseOption: {
      timeline: {
        axisType: 'category',
        data: years,
        currentIndex: years.length - 1,
        label: {},
      },
      tooltip: {},
      legend: {
        left: 'right',
        data: [
          'Conv. Count',
        ],
        selected: {
          'Conv. Count': true,
        },
      },
      calculable: true,
      grid: {
        top: 80,
        bottom: 100,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true,
            },
          },
        },
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            interval: 0,
          },
          data: months,
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Count',
        },
      ],
      series: [
        {
          name: 'Conv. Count',
          type: 'bar',
        },
      ],
    },
    options: Object.values(options),
  }
}

export default getOption
