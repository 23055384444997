import {
  SENTENCE_LIST_UI,
  SENTENCE,
} from '../constants'

const INITIAL_STATE = {
  bulkMode: true,
  sentenceMode: 'generalized',
  noGeneralizedMode: false,
}

// TODO: UtteranceTableRefactor - remove this reducer, we don't need UI state anymore since antd manage it

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SENTENCE_LIST_UI.TOGGLE_BULK_MODE: {
      return {
        ...state,
        bulkMode: action.payload.bulkMode,
      }
    }
    case SENTENCE_LIST_UI.TOGGLE_NO_GENERALIZED: {
      return {
        ...state,
        noGeneralizedMode: action.payload.noGeneralizedMode,
      }
    }
    case SENTENCE_LIST_UI.SET_SENTENCE_MODE: {
      return {
        ...state,
        sentenceMode: action.payload.sentenceMode,
      }
    }
    case SENTENCE.BULK_MODE.APPLY_TRAINING.SUCCESS:
    case SENTENCE.BULK_MODE.APPLY_TEST.SUCCESS:
    case SENTENCE.BULK_MODE.TOGGLE_UNRECOGNIZED.SUCCESS:
    case SENTENCE.DELETE.SUCCESS:
    default: return state
  }
}
