import React from 'react'
import PropTypes from 'prop-types'
import { Result, Typography, notification } from 'antd'
import { shallowCleanFalsyObject } from '@core/helpers'
import { LoadingSkeleton } from '@core/components/LoadingSkeleton'
import useRequest from '@core/hooks/useRequest'
import Api from '../../../api'
import IssueTable from './IssueTable'

const ACTION_DELETE = 'delete'
const ACTION_RELEASE = 'release'

const PTCIssueTab = ({ timeFormatter, filter, onReload }) => {
  const { datasource, ...rest } = filter
  const onProcessQueue = async (action, email) => {
    let response = null
    let message = null
    try {
      if (action === ACTION_DELETE) {
        response = await Api.deleteNotifications({ email }, { datasource })
        message = 'Pending notification(s) is deleted from'
      } else if (action === ACTION_RELEASE) {
        response = await Api.releaseNotifications({ email }, { datasource })
        message = 'Notification is sent to'
      }
      if (response?.status === 'success') {
        notification.success({
          description: (
            <>
              <Typography.Text>{ message }</Typography.Text>
              <Typography.Text strong>
                    &nbsp;
                {`'${email}'`}
                    &nbsp;
              </Typography.Text>
            </>
          ),
        })
        onReload({ datasource })
      }
    } catch (error) {
      console.error('error: ', error)
      notification.error({
        duration: null,
        message: 'Error',
        description: 'Something went wrong',
      })
    }
  }

  const onCloseConversation = async (channel, conversationId) => {
    let response = null
    try {
      response = await Api.closePTCNotifications({}, { channel, conversationId, datasource })
      if (response?.status === 'success') {
        notification.success({
          description: (
            <>
              <Typography.Text>Notification with Conversation ID</Typography.Text>
              <Typography.Text strong>
                    &nbsp;
                {`'${conversationId}'`}
                    &nbsp;
              </Typography.Text>
              <Typography.Text>is successfully closed</Typography.Text>
            </>
          ),
        })
        onReload({ datasource })
      }
    } catch (error) {
      console.error('error: ', error)
      notification.error({
        duration: null,
        message: 'Error',
        description: 'Something went wrong',
      })
    }
  }

  const [{ loading, data, hasError }] = useRequest(Api.getIssue, shallowCleanFalsyObject(rest), { datasource })

  if (hasError) {
    return (
      <Result
        status='error'
        title='There are some problems with your operation.'
      />
    )
  }

  if (loading || !data) {
    return <LoadingSkeleton />
  }

  const { pendingList, activeList } = data

  return (
    <IssueTable
      onProcessQueue={onProcessQueue}
      onCloseConversation={onCloseConversation}
      pendingNotificationsData={pendingList}
      activeNotificationsData={activeList}
      timeFormatter={timeFormatter}
    />
  )
}

PTCIssueTab.propTypes = {
  filter: PropTypes.shape({
    _: PropTypes.number,
    datasource: PropTypes.string,
  }).isRequired,
  timeFormatter: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
}

export default PTCIssueTab
